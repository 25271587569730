/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { map } from 'lodash';

// Preloader
import Preloader from './../../../Components/Common/Preloader';

// Utils
import Scrollbar from './../../../Components/Utils/Scrollbar';

// jQuery
const $ = window.$;

/* ----------  Scripts  ---------- */

const EASING_SWIFT_OUT = [ 0.35,0,0.25,1 ];

class FilterDropdown extends React.Component {
	constructor(props) {
		super(props);

		this.filterRef = React.createRef();
	}

	componentDidMount() {
		this.load();
		this.handleBody();
	}

	// Body

	handleBody = () => {
		$('body').off('click.filterDropdown').on('click.filterDropdown', e => {
			const $filter = $(e.target).closest('[data-tree-filter]');

			if(!$filter.length) this.reset();
		});

		$('body').off('keyup.filterDropdown').on('keyup.filterDropdown', e => {
			if(e.keyCode === 27) this.reset();
		});
	}

	// Load

	load = () => {
		const filter = this.filterRef.current;
		const toggler = filter.querySelector('[data-filter-toggler]');
		const content = filter.querySelector('[data-filter-content]');

		$(toggler).off('click.filterToggle').on('click.filterToggle', e => {
			e.preventDefault();
			e.stopPropagation();

			const $content = $(content);

			if(!$content.hasClass('filter-open')) {
				this.reset();
				this.showFilter($content);
			} else {
				this.hideFilter($content);
			}
		});
	}

	// Filter

	showFilter = els => {
		map(els, el => {
			$(el).velocity('slideDown', {
				duration: 200,
				easing: EASING_SWIFT_OUT,

				complete:() => {
					const $el = $(el);
					const $parent = $el.closest('[data-cnt-tree]');

					$el.addClass('filter-open');
					$parent.addClass('filter-dropdown-active');
				},
			});
		});
	}

	hideFilter = els => {
		map(els, el => {
			if($(el).hasClass('filter-open')) {
				$(el).velocity('slideUp', {
					duration: 200,
					easing: EASING_SWIFT_OUT,

					complete:() => {
						const $el = $(el);
						const $parent = $('[data-cnt-tree]');

						$el.removeClass('filter-open');
						$parent.removeClass('filter-dropdown-active');
					},
				});
			}
		});
	}

	// Reset

	reset = () => {
		const $filters = $('[data-tree-filter]');
		const $content = $('[data-filter-content]', $filters);
		
		this.hideFilter($content);
	}

	render() {
		const { title, id, loading, theme } = this.props;

		return (
			<div className={ `filter-dropdown filter-dropdown-theme-${ theme } ${ loading ? 'disabled' : '' }` } id={ id } ref={ this.filterRef } data-tree-filter>
				<Link to="#filter" className="filter-title" data-filter-toggler>
					<span className="title-text">{ title }</span>
					<span className={ `title-arrow ${ loading ? 'd-none' : 'd-block' }` }>
						<span className="fas fa-chevron-down"/>
					</span>
					<Preloader loading={ loading } size={ 20 } color="#ffffff" center/>
				</Link>
				<div className="filter-content" data-filter-content>
					<Scrollbar>
						<div className="content-inner">
							{ this.props.children }
						</div>
					</Scrollbar>
				</div>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

FilterDropdown.defaultProps = {
	loading: false,
	theme: 'light'
}

FilterDropdown.propTypes = {
	loading: PropTypes.bool,

	theme: PropTypes.string,
	id: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	
	children: PropTypes.node.isRequired
}

/* ----------  Exports  ---------- */

export default FilterDropdown;