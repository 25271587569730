/* ----------  Scripts  ---------- */

const LEGS_INITIAL_STATE = [];

const ReducerMarketplaceLegs = (state = LEGS_INITIAL_STATE, action) => {
	if(action.type === 'MARKETPLACE_GET_LEGS') {
		return [ ...state, ...action.payload.results ];
	} else if(action.type === 'MARKETPLACE_REMOVE_LEGS') {
		return LEGS_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMarketplaceLegs;