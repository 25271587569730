/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// PropTypes
import PropTypes from 'prop-types';

// Paginate Item
import PaginateItem from './PaginateItem';

/* ----------  Scripts  ---------- */

class Pagination extends React.Component {
	componentDidMount() {}

	changePage = (e, page) => {
		e.preventDefault();

		this.props.changePage(page);
	}

	renderPagination = () => {
		const { page, pages } = this.props;

		if(pages > 1) {
			if(pages <= 3) {
				const paginates = [];

				for(let i = 0; i < pages; i += 1) {
					paginates.push(<PaginateItem key={ i } index={ i } active={ i === page } onClick={ e => this.changePage(e, i) }/>);
				}

				return paginates;
			} else if(pages > 3) {
				const paginates = [];
				const firstPage = 0;
				const lastPage = pages - 1;
				const currentPage = page;
				const prevPage = currentPage > 1 ? currentPage - 1 : null;
				const nextPage = currentPage < (lastPage - 1) ? currentPage + 1 : null;

				const firstEllipsis = prevPage ? (prevPage - firstPage) : false;
				const lastEllipsis = nextPage ? (lastPage - nextPage) : false;

				paginates.push(<PaginateItem key={ firstPage } index={ firstPage } active={ firstPage === currentPage } onClick={ e => this.changePage(e, firstPage) }/>);

				if(firstEllipsis && (firstEllipsis > 1)) paginates.push(<li key="fe">...</li>);

				if(prevPage) paginates.push(<PaginateItem key={ prevPage } index={ prevPage } active={ prevPage === currentPage } onClick={ e => this.changePage(e, prevPage) }/>);
				if(currentPage && (currentPage !== firstPage) && (currentPage !== lastPage)) paginates.push(<PaginateItem key={ currentPage } index={ currentPage } active onClick={ e => this.changePage(e, currentPage) }/>);
				if(nextPage) paginates.push(<PaginateItem key={ nextPage } index={ nextPage } active={ nextPage === currentPage } onClick={ e => this.changePage(e, nextPage) }/>);
				
				if(lastEllipsis && (lastEllipsis > 1)) paginates.push(<li key="le">...</li>);

				paginates.push(<PaginateItem key={ lastPage } index={ lastPage } active={ lastPage === currentPage } onClick={ e => this.changePage(e, lastPage) }/>);

				return paginates;
			}
		}

		return <PaginateItem index={ 0 } active onClick={ e => this.changePage(e, 0) }/>;
	}

	render() {
		const { id, className, page, pages } = this.props;

		return (
			<ul className={ `pagination bs-pagination list-inline ${ className }` } id={ id }>
				<li className={ `previous ${ page === 0 ? 'disabled' : '' }` }>
					<Link to="#results" aria-label="Previous" onClick={ (e) => this.changePage(e, page - 1) }>
						<i className="arrow_left"/>
					</Link>
				</li>
				{ this.renderPagination() }
				<li className={ `next ${ page === (pages - 1) ? 'disabled' : '' }` }>
					<Link to="#results" aria-label="Next" onClick={ (e) => this.changePage(e, page + 1) }>
						<i className="arrow_right"/>
					</Link>
				</li>
			</ul>
		);
	}
}

/* ----------  Prop Types  ---------- */

Pagination.defaultProps = {
	page: 0,
	pages: 1,

	className: '',
}

Pagination.propTypes = {
	page: PropTypes.number,
	pages: PropTypes.number,

	className: PropTypes.string,
	id: PropTypes.string.isRequired,

	changePage: PropTypes.func.isRequired,
}

/* ----------  Exports  ---------- */

export default Pagination;