/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// MD Spinner
import MDSpinner from 'react-md-spinner';

/* ----------  Scripts  ---------- */

class Preloader extends React.Component {
	constructor(props) {
		super(props);

		this.preloader = React.createRef();
	}

	componentDidMount() {
		if(this.props.loading) this.show();
	}

	componentWillUnmount() {
		this.hide();
	}

	getPreloader = () => ( this.preloader.current )

	hide = () => {
		this.getPreloader().classList.remove('loading');
	}

	show = () => {
		this.getPreloader().classList.add('loading');
	}

	renderSpinner = () => {
		const { loading, size, color } = this.props;

		if(!loading) return <div/>;

		if(color) {
			return <MDSpinner size={ size } singleColor={ color }/>;
		}

		return <MDSpinner size={ size }/>;
	}

	render() {
		const { loading, center, minimal, relative, className } = this.props;

		return (
			<div className={ `preloader ${ loading ? 'loading' : '' } ${ center ? 'center' : '' } ${ minimal ? 'minimal' : '' } ${ relative ? 'relative' : '' } ${ className }` } ref={ this.preloader }>
				<div className="preloader-icon">
					{ this.renderSpinner() }
				</div>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

Preloader.defaultProps = {
	size: 30,
	
	center: false,
	loading: false,
	minimal: true,
	color: false,
	relative: false,

	className: '',
}

Preloader.propTypes = {
	size: PropTypes.number,
	
	className: PropTypes.string,

	center: PropTypes.bool,
	loading: PropTypes.bool,
	minimal: PropTypes.bool,
	relative: PropTypes.bool,
	color: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
}

/* ----------  Exports  ---------- */

export default Preloader;
