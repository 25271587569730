/* ----------  Imports  ---------- */

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { map, get, isEmpty, isEqual, find, remove } from 'lodash';

// Slugify
import slugify from 'slugify';

// jQuery
import $ from 'jquery';

// Google Map
import GoogleMap from './../../Google/GoogleMap';

// Global
import Global from './../../../Constants/Global';

// Helpers
import Ctx from './../../../Helpers/Ctx';

/* ----------  Scripts  ---------- */

class LegMap extends GoogleMap {
	constructor(props) {
		super(props);

		this.bounds  = null;

		this.markers = [];
		this.markerClusterer = null;

		this.markersLoaded = false;

		this.lastDeleted = '';

		this.state = {
			loaded: false
		}
	}

	componentDidMount() {
		this.loaded = true;

		this.load(() => {
			this.renderClusterers();

			this.handleMapEvents();
			this.handleMarkerEvents();
			this.handleMarkerActions();

			this.setState({ loaded: true });
		});
	}

	componentWillMount() {
		this.getGeoLocation();
	}

	componentWillUnmount() {
		this.loaded = false;

		this.removeClusterers();
		this.removeMarkers();
	}

	componentDidUpdate(prevProps) {
		const { data, mapOptions } = this.props;
		const isDataEqual = isEqual(prevProps.data, data);
		const isMapOptionsEqual = isEqual(prevProps.mapOptions, mapOptions);

		if(this.state.loaded) {
			if(!isEmpty(data)) {
				if(!this.markersLoaded) {
					this.markersLoaded = true;
					// this.renderMarkers();
				}

				if(!isDataEqual) {
					this.setVisibleMarkers();
				}

				if(!isDataEqual || !isMapOptionsEqual) {
					this.checkMarkers(prevProps);
				}
			}
		}
	}

	getCategoryClass = legs => {
		const { primaryCategory } = legs;
		const { categories } = this.props;

		if(isEmpty(primaryCategory)) return 'default';

		const category = find(categories, ['categoryId', primaryCategory]);
		if(isEmpty(category)) return 'default';

		return category.name ? slugify(category.name) : '';
	}

	getId = marker => marker.data('leg')

	getMap = () => $(`#${ this.props.id }`)

	getMarkerContent = leg => {
		switch(this.props.type) {
			case 'default': return this.getDefaultMarkerContent(leg);
			case 'marketplace': return this.getMarketplaceDefaultMarkerContent(leg);
			case 'marketplaceLicensed': return this.getMarketplaceLicensedMarkerContent(leg);
			case 'marketplaceLicensing': return this.getMarketplaceLicensingMarkerContent(leg);
			default: return this.getDefaultMarkerContent(leg);
		}
	}

	getDefaultMarkerContent = leg => (`
		<div class="marker marker-${ this.getCategoryClass(leg) }" data-leg="${ leg.legId }" data-index="${ leg.autoId }" data-marker style="z-index: 99;">
			<div class="title">${ leg.title }</div>
			<div class="marker-wrapper">
				<div class="tag ${ leg.featured ? 'd-block' : 'd-none' }"><i class="fas fa-check"></i></div>
				<div class="pin">
					<div class="image" style="background-image: url('${ leg.markerImage }');"></div>
				</div>
				<div class="lang-flag">
					<span class="flag-icon flag-icon-squared flag-icon-${ this.getMarkerFlag(leg.language) }"></span>
				</div>
				<div class="marker-actions">
					<ul class="list-unstyled">
						<li><a href="#" data-playback><img src="${ Global.ASSETS_BASE_URL }/icons/map/flag-map-marker.svg" class="img-responsive" alt=""/></a></li>
						<li><a href="#" data-related><img src="${ Global.ASSETS_BASE_URL }/icons/map/link.svg" class="img-responsive" alt=""/></a></li>
						<li><a href="#" data-share><img src="${ Global.ASSETS_BASE_URL }/icons/map/share.svg" class="img-responsive" alt=""/></a></li>
					</ul>
				</div>
			</div>
		</div>
	`)

	getMarketplaceDefaultMarkerContent = leg => (`
		<div class="marker marker-marketplace marker-${ this.getCategoryClass(leg) }" data-leg="${ leg.legId }" data-index="${ leg.autoId }" data-marker style="z-index: 99;">
			<div class="title">${ leg.title }</div>
			<div class="marker-wrapper">
				<div class="tag ${ leg.featured ? 'd-block' : 'd-none' }"><i class="fas fa-check"></i></div>
				<div class="marker-subscription">
					<span class="material-icons">subscriptions</span>
				</div>
				<div class="pin">
					<div class="image" style="background-image: url('${ leg.markerImage }');"></div>
				</div>
				<div class="lang-flag">
					<span class="flag-icon flag-icon-squared flag-icon-${ this.getMarkerFlag(leg.language) }"></span>
				</div>
				<div class="marker-actions">
					<ul class="list-unstyled">
						<li><a href="#" data-playback><img src="${ Global.ASSETS_BASE_URL }/icons/map/flag-map-marker.svg" class="img-responsive" alt=""/></a></li>
						<li><a href="#" data-share><img src="${ Global.ASSETS_BASE_URL }/icons/map/share.svg" class="img-responsive" alt=""/></a></li>
					</ul>
				</div>
			</div>
		</div>
	`)

	getMarketplaceLicensedMarkerContent = leg => (`
		<div class="marker marker-${ this.getCategoryClass(leg) }" data-leg="${ leg.legId }" data-index="${ leg.autoId }" data-marker style="z-index: 99;">
			<div class="title">${ leg.title }</div>
			<div class="marker-wrapper">
				<div class="tag ${ leg.featured ? 'd-block' : 'd-none' }"><i class="fas fa-check"></i></div>
				<div class="pin">
					<div class="image" style="background-image: url('${ leg.markerImage }');"></div>
				</div>
				<div class="lang-flag">
					<span class="flag-icon flag-icon-squared flag-icon-${ this.getMarkerFlag(leg.language) }"></span>
				</div>
			</div>
		</div>
	`)

	getMarketplaceLicensingMarkerContent = leg => (`
		<div class="marker marker-licensing marker-${ this.getCategoryClass(leg) }" data-leg="${ leg.legId }" data-index="${ leg.autoId }" data-marker style="z-index: 99;">
			<div class="title">${ leg.title }</div>
			<div class="marker-wrapper">
				<div class="tag ${ leg.featured ? 'd-block' : 'd-none' }"><i class="fas fa-check"></i></div>
				<div class="pin">
					<div class="image" style="background-image: url('${ leg.markerImage }');"></div>
				</div>
				<span class="atn-publish" data-publish>
					<i class="material-icons">publish</i>
				</span>
				<div class="marker-price" data-price>
					<span>$${ leg.price }</span>
				</div>
				<div class="lang-flag">
					<span class="flag-icon flag-icon-squared flag-icon-${ this.getMarkerFlag(leg.language) }"></span>
				</div>
				<div class="marker-actions">
					<ul class="list-unstyled">
						<li><a href="#" data-playback><img src="${ Global.ASSETS_BASE_URL }/icons/map/flag-map-marker.svg" class="img-responsive" alt=""/></a></li>
					</ul>
				</div>
			</div>
		</div>
	`)

	getMarkerFlag = lang => {
		switch(lang) {
			case 'en': return 'us';
			default: return lang;
		}
	}

	getVisibleMarkers = () => {
		const visibleMarkers = [];
		const mapBounds = this.map.getBounds();

		if(mapBounds && this.markers.length) {
			map(this.markers, marker => {
				const position = marker.getPosition();
				const isVisible = mapBounds.contains(position);

				if(isVisible) {
					marker.setVisible(true);
					visibleMarkers.push(marker.legId);
				} else {
					marker.setVisible(false);
				}
			});
		}

		return visibleMarkers;
	}

	setLegActive = legId => {
		this.props.setLegActive(legId);
	}

	setVisibleMarkers = () => {
		const visibleMarkers = this.getVisibleMarkers();

		this.props.setLegsVisible(visibleMarkers);
	}

	callIdle = () => {
		this.google.event.trigger(this.map, 'idle');
	}

	checkMarkers = () => {
		const { data } = this.props;

		map(data, leg => this.checkMarker(leg));
	}

	checkMarker = leg => {
		const { mapOptions }  = this.props;

		const $map = this.getMap();
		const $marker = $(`[data-leg="${ leg.legId }"]`, $map);

		if(leg.active) {
			$marker.addClass('active').css('zIndex', 111);
		} else {
			$marker.removeClass('active').css('zIndex', 99);
		}

		if(leg.hovered) {
			$marker.addClass('hover-state').css('zIndex', 112);
		} else {
			$marker.removeClass('hover-state').css('zIndex', 99);
		}

		if(leg.isSubscribed) {
			$marker.addClass('marker-subscribed');
		} else {
			$marker.removeClass('marker-subscribed');
		}

		if(leg.published) {
			$marker.addClass('marker-published');
		} else {
			$marker.removeClass('marker-published');
		}

		if(mapOptions) {
			if(mapOptions.priceTag) {
				$('[data-price]', $marker).show();
			} else {
				$('[data-price]', $marker).hide();
			}

			if(mapOptions.publishToggle) {
				$('[data-publish]', $marker).show();
			} else {
				$('[data-publish]', $marker).hide();
			}
		}
	}

	handleAutoZoom = () => {
		if(this.markers.length) {
			this.bounds  = new this.google.LatLngBounds();

			map(this.markers, marker => {
				this.bounds.extend(marker.getPosition());
			});

			this.map.fitBounds(this.bounds);
		}
	}

	handleMarkerEvents = () => {
		const $map = this.getMap();

		$map.off('click.marker').on('click.marker', '[data-marker]', e => {
			e.preventDefault();
			e.stopPropagation();

			const $marker = $(e.currentTarget);
			const legId = this.getId($marker);

			this.props.handleLeg(legId);
		});

		$map.off('mouseenter.marker').on('mouseenter.marker', '[data-marker]', e => {
			const $marker = $(e.currentTarget);
			const legId = this.getId($marker);

			this.props.setLegHovered(legId, 'marker');
		});

		$map.off('mouseleave.marker').on('mouseleave.marker', '[data-marker]', () => {
			this.props.setLegsUnHovered();
		});
	}

	handleMarkerActions = () => {
		const $map = this.getMap();
		const getMarker = atn => atn.closest('[data-marker]');

		$map.off('click.playback').on('click.playback', '[data-playback]', e => {
			e.preventDefault();
			e.stopPropagation();

			const $atn = $(e.currentTarget);
			const $marker = getMarker($atn);
			const legId = this.getId($marker);

			this.playLeg(legId, 50);
		});

		$map.off('click.related').on('click.related', '[data-related]', e => {
			e.preventDefault();
			e.stopPropagation();

			const { showRelated } = this.props;

			const $atn = $(e.currentTarget);
			const $marker = getMarker($atn);
			const legId = this.getId($marker);

			this.setLegActive(legId);
			
			if(showRelated) showRelated(legId);
		});

		$map.off('click.share').on('click.share', '[data-share]', e => {
			e.preventDefault();
			e.stopPropagation();

			// const $atn = $(e.currentTarget);
			// const $marker = getMarker($atn);
			// const legId = this.getId($marker);
		});

		$map.off('click.publish').on('click.publish', '[data-publish]', e => {
			e.preventDefault();
			e.stopPropagation();

			const { data, publish } = this.props;

			const $atn = $(e.currentTarget);
			const $marker = getMarker($atn);
			const legId = this.getId($marker);
			const leg = get(data, legId);

			if(publish && leg) publish([legId], !leg.published);
		});
	}

	handleMapEvents = () => {
		const { reset, mapIdle } = this.props;

		this.google.event.addListener(this.map, 'click', e => {
			e.stop();
			e.cancelBubble = true;
			
			if (e.stopPropagation) {
				e.stopPropagation();
			}
			
			if (e.preventDefault) {
				e.preventDefault(); 
			} else {
				e.returnValue = false;  
			}

			Ctx.close();

			reset();
		});

		this.map.addListener('idle', () => {
			Ctx.close();

			this.setVisibleMarkers();

			if(mapIdle) mapIdle(this.getCenter());
		});
	}

	playLeg = legId => {
		const { playLeg } = this.props;

		if(playLeg) {
			playLeg(legId, 50);
			this.setLegActive(legId);
		}
	}

	reset = () => {
		this.props.reset();
	}

	removeMarker = legId => {
		const marker = find(this.markers, ['legId', legId]);

		if(marker) {
			marker.setMap(null);
			remove(this.markers, marker);
		}
	}

	removeMarkers = () => {
		if(this.markers.length) {
			map(this.markers, marker => {
				marker.setMap(null);
			});

			this.markers = [];
		}
	}

	removeClusterers = () => {
		if(this.markerClusterer && this.markerClusterer.getMarkers().length) {
			this.markerClusterer.clearMarkers();
		}
	}

	renderClusterers = () => {
		const MarkerClusterer = window.MarkerClusterer;

		const clusterStyles = [{
			url: `${ Global.ASSETS_BASE_URL }/images/themes/location/cluster.png`,
			height: 36,
			width: 36
		}];

		this.markerClusterer = new MarkerClusterer(this.map, this.markers, { styles: clusterStyles, maxZoom: 14, ignoreHidden: true });
	}

	renderMarkers = () => {
		this.removeMarkers();
		this.removeClusterers();

		const { data } = this.props;
		const RichMarker = window.RichMarker;

		map(data, leg => {
			if(leg.position.length) {
				const isValid = this.validateLatLng(leg.position[0], leg.position[1]);

				if(isValid.lat && isValid.lng) {
					const marker = new RichMarker({
						position: new this.google.LatLng(leg.position[0], leg.position[1]),
						map: this.map,
						flat: true,
						legId: leg.legId,
						draggable: false,
						content: this.getMarkerContent(leg),
					});

					this.markers.push(marker);
					this.markerClusterer.addMarker(marker);

					setTimeout(() => {
						this.checkMarker(leg);
					}, 300);
				}
			}
		});

		if(this.props.autozoom) this.handleAutoZoom();
	}
}

/* ----------  Prop Types  ---------- */

LegMap.defaultProps = {
	type: 'default',
	
	autozoom: false,
	
	mapIdle: false,
	publish: false,
	playLeg: false,
	mapOptions: false,
	showRelated: false,
}

LegMap.propTypes = {
	autozoom: PropTypes.bool,

	type: PropTypes.string.isRequired,
	
	reset: PropTypes.func.isRequired,
	handleLeg: PropTypes.func.isRequired,
	setLegActive: PropTypes.func.isRequired,
	setLegHovered: PropTypes.func.isRequired,
	setLegsVisible: PropTypes.func.isRequired,
	setLegsUnHovered: PropTypes.func.isRequired,
	
	mapIdle: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	publish: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	playLeg: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	showRelated: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	
	mapOptions: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
	
	data: PropTypes.objectOf(PropTypes.object).isRequired,
}

/* ----------  Exports  ---------- */

export default LegMap;
