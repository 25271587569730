/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Redux
import { connect } from 'react-redux';

// Redux
import { bindActionCreators } from 'redux';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { debounce, isEmpty, map } from 'lodash';

// Fuse Components
import Input from './../../../Components/Fuse/Input';
import Alerts from './../../../Components/Fuse/Alerts';

// Preloader
import Preloader from './../../../Components/Common/Preloader';

// Utils
import Toggle from './../../../Components/Utils/Toggle';

// Helpers
import InputHelper from './../../../Helpers/InputHelper';
import StripeHelper from '../../../Helpers/StripeHelper';

// Actions
import LoginUser from './../../../Redux/Actions/User/Auth/LoginUser';
import GetUserProfile from './../../../Redux/Actions/User/GetUserProfile';

/* ----------  Scripts  ---------- */

class LoginForm extends React.Component {
	constructor(props) {
		super(props);

		this.isLoaded = false;

		this.form = React.createRef();
		this.rememberCheckboxRef = React.createRef();

		this.initialFormState = {
			email: '',
			password: '',
			remember: true,
		}

		this.prefilledFormState = {
			email: 'azizk.araneux@gmail.com',
			password: 'secret',
			remember: true,
		}

		this.state = {
			busy: false,
			formErrors: [],
			form: this.initialFormState,
		}

		this.requestLoginUser = debounce(this.doLoginUser, 500);
		this.requestProfile = debounce(this.doRequestProfile, 500);
	}

	componentDidMount() {
		this.isLoaded = true;
	}

	componentWillUnmount() {
		this.isLoaded = false;
	}

	onChange = e => {
		const { name, value } = e.currentTarget;

		this.updateForm({ [name]: value });
	}

	onSubmit = e => {
		e.preventDefault();

		const isValid = this.validateForm();

		if(isValid) this.login();
	}

	onCreateAccount = e => {
		e.preventDefault();

		this.props.openRegisterForm();
	}

	onForgotPassword = e => {
		e.preventDefault();
		this.props.changeForm('FORGOT_PASSWORD');
	}

	getProfile = (success, fail) => {
		this.requestProfile(success, fail);
	}

	doLoginUser = (data, success, fail) => {
		this.props.loginUser(data, success, fail);
	}

	doRequestProfile = (success, fail) => {
		this.props.getUserProfile(success, fail);
	}

	handleTerms = (value, checked) => {
		this.updateForm({ remember: checked });
	}

	login = () => {
		const { form } = this.state;
		const { fcmToken } = this.props;
		const termCheckbox = this.rememberCheckboxRef.current;

		const data = {
			...form,
			fcmToken,
		}

		if(this.isLoaded) this.setState({ busy: true });

		this.requestLoginUser(data, (status, result) => {
			if(result.registrationStripeSessionId && !isEmpty(result.registrationStripeSessionId)) {
				const { stripe } = this.props;
				if(!stripe || isEmpty(stripe)) return;
					
				setTimeout(() => {
					console.log(result.registrationStripeSessionId);
					StripeHelper.checkoutViaSessionId(stripe, result.registrationStripeSessionId);
				}, 1500);
				return;
			}

			this.getProfile(() => {
				if(this.isLoaded) {
					this.setState({
						busy: false,
						formErrors: [],
						form: this.initialFormState,
					}, termCheckbox.updateUI);
				}
			});
		}, (status, errors) => {
			if(this.isLoaded) {
				const errorMessages = map(errors, reason => reason.message);

				this.setState({
					busy: false,
					formErrors: errorMessages,
				});
			}
		});
	}

	updateForm = (updates, callback) => {
		if(this.isLoaded) {
			const { form } = this.state;
			
			this.setState({
				form: {
					...form,
					...updates
				}
			}, () => {
				if(callback) callback();
			});
		}
	}

	validateForm = () => {
		let isValid = true;

		const errors = [];
		const { form: { email, password } } = this.state;

		if(InputHelper.isEmpty(email) || InputHelper.isEmpty(password)) {
			isValid = false;
			errors.push('Email & password cannot be blank.');
		}

		if(!InputHelper.isEmpty(email) && !InputHelper.isEmail(email)) {
			isValid = false;
			errors.push('Please enter a valid email.');
		}

		if(this.isLoaded) this.setState({ formErrors: errors });

		return isValid;
	}

	render() {
		const { form, formErrors, busy } = this.state;

		return (
			<div className="form-wrapper p-8">
				<div className="title mt-4 mb-8">Log in to your account</div>
				<form onSubmit={ this.onSubmit } noValidate>
					<Alerts show={ formErrors.length > 0 } data={ formErrors }/>
					<Input
						id="txtLoginEmail"
						label="Email Address"
						groupClass="mb-4"
						name="email"
						type="email"
						value={ form.email }
						onChange={ this.onChange }/>
					<Input
						id="txtLoginPassword"
						label="Password"
						groupClass="mb-4"
						name="password"
						type="password"
						value={ form.password }
						onChange={ this.onChange }/>
					<div className="remember-forgot-password row no-gutters align-items-center justify-content-between pt-4">
						<div className="form-check remember-me mb-4">
							<label htmlFor="txtLoginRemember" className="form-check-label">
								<Toggle
									type="checkbox"
									value="remember"
									name="remember"
									id="txtLoginRemember"
									checked={ form.remember }
									onChange={ this.handleTerms }
									checkboxClass="icheckbox"
									increaseArea="0%"
									ref={ this.rememberCheckboxRef }/>
								{/* <span className="checkbox-icon"/> */}
								<span className="form-check-text">Remember Me</span>
							</label>
						</div>
					</div>
					<button type="submit" className="submit-button btn btn-block btn-secondary my-4 mx-auto" disabled={ busy } aria-label="LOG IN">
						<Preloader loading={ busy } size={ 15 } color="#ffffff" minimal center/>
						{ !busy ? 'LOG IN' : '' }
					</button>
				</form>
				<div className="login d-flex flex-column align-items-center justify-content-center mt-6 mb-6 mx-auto">
					<span className="text mb-2">Unable to login?</span>
					<Link to="#auth" className="link text-success" onClick={ this.onForgotPassword }>Forgot Password?</Link>
				</div>
				<div className="login d-flex flex-column align-items-center justify-content-center mt-8 mx-auto">
					<span className="text mb-2">Don&#x27;t have an account?</span>
					<Link to="#auth" className="link text-secondary" onClick={ this.onCreateAccount }>Create an account</Link>
				</div>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

LoginForm.defaultProps = {
	fcmToken: null,
	stripe: {},
}

LoginForm.propTypes = {
	fcmToken: PropTypes.string,

	loginUser: PropTypes.func.isRequired,
	getUserProfile: PropTypes.func.isRequired,
	
	changeForm: PropTypes.func.isRequired,
	openRegisterForm: PropTypes.func.isRequired,

	stripe: PropTypes.shape(),
}

/* ----------  Redux Scripts  ---------- */

const mapStateToProps = state => ({
	fcmToken: state.user.fcmToken
});

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		loginUser: LoginUser,
		getUserProfile: GetUserProfile,
	}, dispatch)
);

/* ----------  Exports  ---------- */

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
