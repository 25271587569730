/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// UIkit
import UIkit from 'uikit';

/* ----------  Scripts  ---------- */

class Modal extends React.Component {
	constructor(props) {
		super(props);

		this.modal = null;

		this.modalRef = React.createRef();

		this.state = {}
	}

	componentDidMount() {
		this.load();
	}

	load = () => {
		const modal = this.modalRef.current;
		const { onShow, onHide, bgClose, keyboardClose } = this.props;

		this.modal = UIkit.modal(modal, {
			// center: true,
			bgclose: bgClose,
			keyboard: keyboardClose,
		});

		this.modal.on({
			'show.uk.modal': () => {
				document.body.classList.add('uk-modal-open');
				if(onShow) onShow();
			},

			'hide.uk.modal': () => {
				document.body.classList.remove('uk-modal-open');
				if(onHide) onHide();
			}
		});
	}

	show = () => {
		this.modal.show();
	}

	hide = () => {
		this.modal.hide();
	}

	render() {
		const { popup, children, className } = this.props;

		return (
			<div className={ `uk-modal ${ className }` } id={ `ukmodal_${ popup }` } data-popup={ popup } ref={ this.modalRef } data-uk-modal>
				{ children }
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

Modal.defaultProps = {
	show: false,
	onShow: false,
	onHide: false,
	bgClose: true,
	keyboardClose: true,
	
	className: '',
}

Modal.propTypes = {
	onShow: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	onHide: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	
	className: PropTypes.string,

	bgClose: PropTypes.bool,
	keyboardClose: PropTypes.bool,

	popup: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
}

/* ----------  Exports  ---------- */

export default Modal;
