/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { isEmpty } from 'lodash';

// React Ink
import Ink from 'react-ink';

// Slugify
import slugify from 'slugify';

// Tour Components
import MoreControls from './MoreControls';

// Category Label
import CategoryLabel from './../Categories/CategoryLabel';

// Utils
import StarRating from './../Utils/StarRating';

// Helpers
import TextHelper from './../../Helpers/TextHelper';

/* ----------  Scripts  ---------- */

class Tour extends React.Component {
	onClick = e => {
		e.preventDefault();

		const { tour } = this.props;

		this.props.handleTour(tour.tourId);
		this.props.changeMapPosition(tour.position);
	}

	onMouseEnter = () => {
		this.props.setTourHovered(this.props.tour.tourId, 'tour');
	}

	onMouseLeave = () => {
		this.props.setToursUnHovered(this.props.tour.tourId);
	}

	getCategoryClass = () => {
		const { primaryCategory } = this.props;
		if(isEmpty(primaryCategory)) return 'default';

		return primaryCategory.name ? slugify(primaryCategory.name) : '';
	}

	getBackgroundImage = thumbnail => ({
		backgroundImage: `url(${ thumbnail })`
	})

	getAdditionalInfo = info => {
		if(!info) return null;

		return (
			<div className="result-additional-info">
				<p>{ info }</p>
			</div>
		);
	}

	renderCategories = () => {
		const { primaryCategory } = this.props;
		if(isEmpty(primaryCategory)) return <div className="d-none"/>;

		return <CategoryLabel category={ primaryCategory }/>
	}

	render() {
		const { tour, authUserId } = this.props;

		return (
			<div className={ `result-item item-category-${ this.getCategoryClass() } ${ tour.active ? 'active' : '' }` } data-tour={ tour.tourId } data-index={ tour.autoId }>
				<Link 
					to="#tour"
					className={ `tour ${ tour.active ? 'active' : '' } ${ tour.hovered ? 'hover-state' : '' }` }
					data-ripple="#dedede"
					onClick={ this.onClick }
					onMouseEnter={ this.onMouseEnter }
					onMouseLeave={ this.onMouseLeave }>

					<Ink/>

					<div className="result-item-detail">
						<div className="result-title">
							<h3>{ tour.title }</h3>
						</div>
						<div className="result-media">
							<div className="result-image" style={ this.getBackgroundImage(tour.thumbnail) }/>
							{ this.getAdditionalInfo(tour.additionalInfo) }
						</div>
						<div className="result-content">
							<div className="result-title">
								<h3>{ tour.title }</h3>
							</div>
							<div className="result-description">
								<p>{ TextHelper.trim(tour.about, 70) }</p>
							</div>
							<div className="result-meta">
								<div className="result-label">
									{ this.renderCategories() }
								</div>
								<div className="result-ratings ratings">
									<StarRating rating={ tour.rating } ratingCount={ tour.ratingCount }/>
								</div>
							</div>
						</div>
					</div>
				</Link>
				<MoreControls
					tourId={ tour.tourId }
					userId={ tour.user.userId }
					authUserId={ authUserId }
					favorite={ tour.favorite }
					isSubscribed={ tour.isSubscribed }
					subscribe={ this.props.subscribe }
					unsubscribe={ this.props.unsubscribe }
					setFavorite={ this.props.setFavorite }
					setUnfavorite={ this.props.setUnfavorite }/>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

Tour.defaultProps = {
	tour: {},
	authUserId: '',
	primaryCategory: {}
}

Tour.propTypes = {
	handleTour: PropTypes.func.isRequired,
	changeMapPosition: PropTypes.func.isRequired,
	
	setTourHovered: PropTypes.func.isRequired,
	setToursUnHovered: PropTypes.func.isRequired,

	subscribe: PropTypes.func.isRequired,
	unsubscribe: PropTypes.func.isRequired,

	setFavorite: PropTypes.func.isRequired,
	setUnfavorite: PropTypes.func.isRequired,

	authUserId: PropTypes.string,

	tour: PropTypes.shape(),
	primaryCategory: PropTypes.shape(),
}

/* ----------  Exports  ---------- */

export default Tour;
