/* ----------  Scripts  ---------- */

const RemoveFavoriteLegs = () => dispatch => (
	dispatch({
		type: 'MARKETPLACE_REMOVE_FAVORITE_LEGS',
	})
);

/* ----------  Exports  ---------- */

export default RemoveFavoriteLegs;