/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Helpers
import ConfigHelper from '../../../Helpers/ConfigHelper';

// Nav Link
import AdminNavLink from './AdminNavLink';

/* ----------  Scripts  ---------- */

class AdminNavigation extends React.Component {
	constructor(props) {
		super(props);

		this.configs = ConfigHelper.getAppConfigs();;
	}

	toggleNav = e => {
		e.preventDefault();
		
		document.body.classList.toggle('sidebar-minimized')
	}

	render() {
		return (
			<aside id="sidebar_main" className="main-sidebar">
				<div className="sidebar_main_header">
					<ul className="list-unstyled">
						<li className="sidebar_logo">
							<Link to="/home">
								<img src={ this.configs.appLogo } className="img-responsive" alt=""/>
							</Link>
						</li>
						<li className="sidebar_action">
							<Link to="#nav" id="btnSidebarToggleSize" onClick={ this.toggleNav }>
								<i className="material-icons icon-open">&#xE5D2;</i>
								<i className="material-icons icon-close">&#xE5C4;</i>
							</Link>
						</li>
					</ul>
				</div>
				
				<div className="menu_section">
					<ul>
						<AdminNavLink to="/mytours" activeClassName="current_section">
							<span className="menu_icon"><i className="material-icons">&#xE879;</i></span>
							<span className="menu_title">My Tours</span>
							<span className="menu-tooltip" data-uk-tooltip="{'pos': 'right'}" title="My Tours"/>
						</AdminNavLink>
						<AdminNavLink to="/marketplace" activeClassName="current_section">
							<span className="menu_icon"><i className="material-icons">shopping_cart</i></span>
							<span className="menu_title">Marketplace</span>
							<span className="menu-tooltip" data-uk-tooltip="{'pos': 'right'}" title="Marketplace"/>
						</AdminNavLink>
					</ul>
				</div>
			</aside>
		);
	}
}

/* ----------  Exports  ---------- */

export default AdminNavigation;
