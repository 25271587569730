/* ----------  Scripts  ---------- */

const STATUS = {
	type: {
		online: 10,
		offline: 20,
		away: 30,
		dnd: 40,
		blocked: 50,
	},

	class: {
		'online': 'online',
		'offline': 'offline',
		'away': 'away',
		'dnd': 'dnd',
		'blocked': 'blocked',
	},

	text: {
		'online': 'Online',
		'offline': 'Offline',
		'away': 'Away',
		'dnd': 'Dnd',
		'blocked': 'Blocked',
	},

	icon: {
		'online': 'check',
		'offline': 'radio_button_unchecked',
		'away': 'access_time',
		'dnd': 'remove',
		'blocked': 'block',
	}
}

/* ----------  Exports  ---------- */

export default STATUS;