/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { map, get, split, isEmpty, indexOf, filter, mapValues } from 'lodash';

// UUID
import uuid from 'uuid/v4';

// jQuery
import $ from 'jquery';

// UIkit
import UIkit from 'uikit';

// Helpers
import Notify from './../../../Helpers/Notify';

/* ----------  Scripts  ---------- */

class Tree extends React.Component {
	constructor(props) {
		super(props);

		this.api = {};
		this.tree = React.createRef();
	}

	// componentDidUpdate(prevProps) {
	// 	const { nodesData } = this.props;
	// 	const isNodesEqual = isEqual(prevProps.nodesData, nodesData);
	// 	const isLoaded = this.checkIsLoaded();

	// 	if(isLoaded && !isNodesEqual) {
			
	// 	};
	// }

	componentWillUnmount() {
		const { removeNodes } = this.props;

		if(removeNodes) removeNodes();
	}

	getNodes = (params, nodeId, callback) => {
		const { getNodes } = this.props;
		const node = this.getNode(nodeId);

		if(node) {
			this.updateBusyNode(nodeId);
			this.setState({ loaded: false });
			this.removeTreeNodes(node.children_d);

			getNodes(params, meta => {
				this.setState({ loaded: true });
				this.updateBusyNode(nodeId, true);
				this.renderNodes();

				if(callback) callback(meta);
			});
		}

		return true;
	}

	getData = () => {
		const data = this.api.get_json('#', { flat: true });
		return map(data, node => this.getNode(node.id));
	}

	getCollectionId = node => {
		const $collection = node.closest('.jstreeNodeCollection');
		const collection = this.getNode($collection);

		if(collection) return collection.li_attr['data-id'];

		return false;
	}

	getNode = selector => {
		const node = this.api.get_node(selector);
		return node;
	}

	getNodeSiblings = nodeId => {
		let children = [];
		const node = this.getNode(nodeId);
		
		if(node) {
			const parentNode = this.getNode(node.parent);

			if(parentNode) {
				children = filter(parentNode.children, childId => {
					const childNode = this.getNode(childId);

					return childNode.parent === node.parent;
				});
			}
		}

		return children;
	}

	getParents = nodeId => {
		let parents = [];
		
		const node = this.getNode(nodeId);

		if(node) {
			parents = node.parents;
		}

		return parents;
	}

	getHierarchy = nodeId => {
		let hierarchy = [];
		const node = this.getNode(nodeId);
		
		if(node) {
			const { parents } = node;
			const validNodes = filter(parents, parent => parent !== '#');

			validNodes.push(nodeId);

			hierarchy = map(validNodes, parent => {
				const parentNode = this.getNode(parent);
				const obj = {
					nodeId: parentNode.li_attr['data-id'],
					nodeType: parentNode.li_attr.type,
					title: parentNode.li_attr.text,
				}

				return obj;
			});
		}

		return hierarchy;
	}

	getCtxProps = ($atn) => {
		const { rootDraggable } = this.props;

		const $parent = $atn.closest('li');
		const nodeId = $parent.attr('id');
		const node = this.getNode(nodeId);
		const parentNode = this.getNode(node.parent);
		const sqPanel = document.querySelector('[data-panel="sequence"]');
		const inLimit = this.checkLimit(node.id);
		const hierarchy = this.getHierarchy(nodeId);

		const { type, licensed, root, text, objectid } = node.li_attr;

		const isRoot = root && !rootDraggable;

		let props = {
			refId: node.id,
			nodeId: node.li_attr['data-id'],
			objectId: objectid,
			root: isRoot || false,
			licensed: licensed || false,
			tree: this.props.type,
			type: parseInt(type, 10),
			title: text,
			src: node,
			hierarchy,
			parentNodeId: parentNode.li_attr ? parentNode.li_attr['data-id'] : '#',
			parentNodeType: parentNode.li_attr ? parentNode.li_attr.type : '-1',
		}

		if(type === 60) {
			props = {
				...props,
				sqPanelActive: sqPanel !== null,
				playTime: node.li_attr ? node.li_attr.playtime : 0,
				isPurchased: node.li_attr ? node.li_attr.ispurchased : false,
			}
		}

		if((type === 10) || (type === 20)) {
			props = {
				...props,
				inLimit,
			}
		}

		return props;
	}

	getTree = nodeId => {
		const node = document.getElementById(nodeId);

		if(node) {
			const tree = node.closest('[data-tree]');
			if(!tree) return null;
			
			const type = tree.getAttribute('data-tree');
			const prefix = tree.getAttribute('data-prefix');
			const allowed = split(tree.getAttribute('data-allowed'), ',');

			return { tree, type, prefix, allowed };
		}

		return null;
	}

	getPrefixedId = id => {
		const { prefix } = this.props;
		return `${ prefix }${ id }`;
	}

	getNodeTypeTitle = type => {
		switch(type) {
			case 10: return 'Collection';
			case 20: return 'Folder';
			case 30: return 'Group';
			case 40: return 'Ungroup';
			case 50: return 'Tour';
			case 60: return 'Leg';
			default: return 'Undefined';
		}
	}

	getNodeClass = type => {
		switch(type) {
			case 10: return 'jstreeNodeCollection jstree-node-collection';
			case 20: return 'jstreeNodeFolder jstree-node-folder';
			case 30: return 'jstreeNodeGroup jstree-node-group';
			case 40: return 'jstreeNodeUngroup jstree-node-ungroup';
			case 50: return 'jstreeNodeTour jstree-node-tour';
			case 60: return 'jstreeNodeLeg jstree-node-leg';
			default: return 'jstreeNodeUndefined jstree-node-undefined';
		}
	}

	getAnchorClass = type => {
		switch(type) {
			case 10: return 'jstree-anchor jsTreeAnchor jstree-anchor-collection';
			case 20: return 'jstree-anchor jsTreeAnchor jstree-anchor-folder';
			case 30: return 'jstree-anchor jsTreeAnchor jstree-anchor-group';
			case 40: return 'jstree-anchor jsTreeAnchor jstree-anchor-ungroup';
			case 50: return 'jstree-anchor jsTreeAnchor jstree-anchor-tour';
			case 60: return 'jstree-anchor jsTreeAnchor jstree-anchor-leg';
			default: return 'jstree-anchor jsTreeAnchor jstree-anchor-undefined';
		}
	}

	getTemplate = (node, tree) => {
		switch(node.nodeType) {
			case 10: return this.getCollectionTemplate(node, tree);
			case 20: return this.getFolderTemplate(node, tree);
			case 30: return this.getGroupedToursTemplate(node, tree);
			case 40: return this.getUngroupedToursTemplate(node, tree);
			case 50: return this.getTourTemplate(node, tree);
			case 60: return this.getLegTemplate(node, tree);
			default: return node.title;
		}
	}

	getTheme = (licensed = false, isPurchased = false) => {
		let theme = '#666666';
		
		if(licensed) theme = '#4caf50';
		if(isPurchased) theme = '#ff9800';

		return theme;
	}

	getCollectionTemplate = node => (`
		<span class="node-loader nodeLoader"><span class="loader-inner"><span class="fas fa-sync fa-spin"></span></span></span>
		<span class="node-notif"><i class="fas fa-circle"></i></span>
		<span class="node-icon nodeIcon" style="color: ${ node.theme }"><i class="material-icons">&#xE3B6;</i></span>
		<span class="node-title nodeTitle">${ node.title }</span>
		<span class="node-actions">
			<span class="atn atn-context" data-context="collection" data-uk-tooltip="{pos: 'top'}" title="More Options"><i class="material-icons">&#xE5D4;</i></span>
		</span>
	`)

	getRelativeIndex = nodeId => {
		const node = this.getNode(nodeId);
		const parentNode = this.getNode(node.parent);

		const { children } = parentNode;
		
		const nodeIndex = indexOf(children, nodeId);

		return nodeIndex;
	}

	getNextUpIndex = nodeId => this.getRelativeIndex(nodeId) - 1

	getNextDownIndex = nodeId => this.getRelativeIndex(nodeId) + 2

	getFolderTemplate = node => (`
		<span class="node-expander nodeExpander" data-expander>
			<span class="fas fa-caret-right"></span>
		</span>
		<span class="node-loader nodeLoader">
			<span class="loader-inner">
				<span class="fas fa-sync fa-spin"></span>
			</span>
		</span>
		<span class="node-notif"><i class="fas fa-circle"></i></span>
		<span class="node-icon nodeIcon has-states" style="color: ${ node.theme }">
			<i class="icon-static material-icons">&#xE2C7;</i>
			<i class="icon-active material-icons">&#xE2C8;</i>
		</span>
		<span class="node-title nodeTitle">${ node.title }</span>
		<span class="node-actions">
			<span class="atn atn-context" data-context="folder" data-uk-tooltip="{pos: 'top'}" title="More Options"><i class="material-icons">&#xE5D4;</i></span>
		</span>
	`)

	getGroupedToursTemplate = node => (`
		<span class="node-loader nodeLoader"><span class="loader-inner"><span class="fas fa-sync fa-spin"></span></span></span>
		<span class="node-notif"><i class="fas fa-circle"></i></span>
		<span class="node-icon nodeIcon atn-publish ${ node.published ? 'published' : '' }" data-publish><i class="material-icons">&#xE255;</i></span>
		<span class="node-icon nodeIcon node-icon-img has-states node-icon-grouped">
			<img src="assets/icons/tree/icon-grouped-close.png" alt="" class="img-responsive icon-static"/>
			<img src="assets/icons/tree/icon-grouped-open.png" alt="" class="img-responsive icon-active"/>
		</span>
		<span class="node-title nodeTitle">${ node.title }</span>
		<span class="node-actions">
			<span class="atn atn-context" data-context="groupedTours" data-uk-tooltip="{pos: 'top'}" title="More Options"><i class="material-icons">&#xE5D4;</i></span>
		</span>
	`)

	getUngroupedToursTemplate = node => (`
		<span class="node-loader nodeLoader"><span class="loader-inner"><span class="fas fa-sync fa-spin"></span></span></span>
		<span class="node-notif"><i class="fas fa-circle"></i></span>
		<span class="node-icon nodeIcon atn-publish ${ node.published ? 'published' : '' }" data-publish><i class="material-icons">&#xE255;</i></span>
		<span class="node-icon nodeIcon node-icon-img node-icon-ungrouped d-block">
			<img src="assets/icons/tree/icon-ungrouped-open.png" alt="" class="img-responsive"/>
		</span>
		<span class="node-title nodeTitle">${ node.title }</span>
		<span class="node-actions">
			<span class="atn atn-context" data-context="ungroupedTours" data-uk-tooltip="{pos: 'top'}" title="More Options"><i class="material-icons">&#xE5D4;</i></span>
		</span>
	`)

	getTourTemplate = node => (`
		<span class="node-loader nodeLoader ${ node.viaUrl ? 'active-viaurl' : '' }"><span class="loader-inner"><span class="fas fa-sync fa-spin"></span></span></span>
		<span class="node-icon nodeIcon atn-publish ${ node.published ? 'published' : '' }" style="display: none;" data-publish><i class="material-icons">&#xE255;</i></span>
		<span class="node-notif"><i class="fas fa-circle"></i></span>
		<span class="node-icon nodeIcon node-icon-img">
			<img src="assets/icons/tree/icon-tour.png" class="img-responsive" alt=""/>
		</span>
		<span class="node-title nodeTitle">${ node.title }</span>
		<span class="node-actions">
			<span class="atn atn-context" data-context="tour" data-uk-tooltip="{pos: 'top'}" title="More Options"><i class="material-icons">&#xE5D4;</i></span>
		</span>
	`)

	getLegTemplate = node => (`
		<span class="node-loader nodeLoader"><span class="loader-inner"><span class="fas fa-sync fa-spin"></span></span></span>
		<span class="node-notif"><i class="fas fa-circle"></i></span>
		<span class="node-icon nodeIcon" style="color: ${ node.theme }"><i class="material-icons">&#xE87B;</i></span>
		<span class="node-title nodeTitle">${ node.title }</span>
		<span class="node-actions">
			<span class="atn atn-context" data-context="leg" data-uk-tooltip="{pos: 'top'}" title="More Options"><i class="material-icons">&#xE5D4;</i></span>
		</span>
	`)

	setTree = nodeId => {
		const node = this.getNode(nodeId);
		const parentTree = this.getTree(node.parent);

		if(parentTree) {
			node.li_attr.tree = parentTree.type;
		}

		return node;
	}

	createNode = (data, position) => {
		const { type } = this.props;
		const node = this.makeNode(data, type);
		const posByType = node.nodeType === 20 ? 'first' : 'last';
		const pos = position || posByType;

		this.api.create_node(node.parent, node, pos, false, false);
	}

	copyNode = (node, tree, dParentNode, callback) => {
		const nodeId = this.getPrefixedId(node.nodeId);
		const treeNode = this.getNode(nodeId);
		const $tree = $(`.jstree[data-tree=${ tree }]`);

		if(treeNode && $tree.length) {
			const parentNodeId = this.getPrefixedId(node.parentNodeId);
			const parentNode = this.getNode(parentNodeId);

			if(parentNode) {
				$tree.jstree(true).copy_node(treeNode, parentNodeId, 'last', () => {
					if(callback && typeof callback === 'function') {
						callback(treeNode, $tree);
					}
				});
			} else {
				this.handleReplication(uuid(), treeNode, dParentNode, 0);
			}
		}
	}

	createNodeData = (action, tNodeId, node, parentNode, position, callback, busy = false) => {
		const nodes = [...this.state.nodes];

		const parentAttrs = parentNode ? parentNode.li_attr : {};
		const parentNodeType = parentAttrs ? parentAttrs.type : '';
		const parentNodeId = parentAttrs ? parentAttrs['data-id'] : '';

		const nodeAttrs = node ? node.li_attr : {};
		const nodeType = nodeAttrs ? nodeAttrs.type : '';
		const nodeId = nodeAttrs ? nodeAttrs['data-id'] : '';

		const nodeObj = {
			node,
			nodeId,
			tNodeId,
			position,
			nodeType,
			parentNode,
			parentNodeId,
			parentNodeType,

			parent: parentNode.id,
			nodeTree: this.getTree(node.id, true),
		}

		nodes.push(nodeObj);

		this.setState({
			nodes,
			action
		}, () => {
			if(!busy) {
				if(callback) callback();

				this.expandNode(parentNode.id);
				this.setState({
					nodes: [],
					action: '',
					removeOriginal: false
				});
			}
		});

		return true;
	}

	checkLimit = nodeId => {
		const parents = this.getParents(nodeId);

		return parents.length < 20;
	}

	deleteNode = node => {
		const nodeId = this.getPrefixedId(node.nodeId);
		const treeNode = this.getNode(nodeId);

		if(!treeNode) {
			Notify.error('Invalid node found. Please try again');
			return false;
		}

		UIkit.modal.confirm(`Are you sure you want to delete this <strong>${ this.getNodeTypeTitle(node.nodeType) }</strong>?`, () => {
			this.setState({
				action: 'delete',
				nodes: [node]
			}, () => {
				const id = this.getPrefixedId(node.nodeId);

				this.handleTree();
				this.api.delete_node(id);

				Notify.success(`${ this.getNodeTypeTitle(node.nodeType) } deleted successfully!`);
			});
		});

		return true;
	}

	handleReplication = (tNodeId, node, parentNode, position, busy = false) => {
		const srcTree = this.getTree(node.id);
		const tree = srcTree ? srcTree.type : node.li_attr.tree;

		this.setTree(node.id);

		let atn = this.state.action || 'copy';
		
		if(atn === 'copy') {
			switch(tree) {
				case 'legs': atn = 'copy'; break;
				case 'tours': atn = 'copy'; break;
				case 'publishTree': atn = 'move'; break;
				default: atn = 'copy'; break;
			}
		}

		this.createNodeData(atn, tNodeId, node, parentNode, position, () => {
			if(!busy) this.handleTree();
		}, busy);
	}

	handleTree = () => {
		// if(!isEmpty(this.state)) return;

		const { handleTree, type } = this.props;
		const { action, nodes, removeOriginal } = this.state;
		let reqNodes = [];
		
		const noCheckActions = ['delete'];
		const noCheck = indexOf(noCheckActions, action) >= 0;

		let isValid = false;

		if(!noCheck) {
			reqNodes = map(nodes, item => {
				const id = this.getPrefixedId(item.nodeId);
				const node = this.getNode(item.tNodeId) || this.getNode(id);
				const parent = this.getNode(item.parent) || item.parentNode;
	
				const nodeAttrs = node.li_attr;
				const parentAttrs = parent.li_attr;

				if(nodeAttrs && parentAttrs) {
					const nodeProps = JSON.parse(nodeAttrs.props);
					const parentProps = JSON.parse(parentAttrs.props);
	
					isValid = indexOf(parentProps.allowed, nodeProps.type) >= 0;
				}
	
				const reqNode = {
					position: item.position || 0
				};
	
				if(item.nodeId) reqNode.nodeId = item.nodeId;
				if(item.tNodeId) reqNode.tNodeId = item.tNodeId;
				if(item.nodeType) reqNode.nodeType = item.nodeType;
				if(item.parentNodeId) reqNode.parentNodeId = item.parentNodeId;
				if(item.parentNodeType) reqNode.parentNodeType = item.parentNodeType;
				
				if(removeOriginal) this.api.delete_node(item.tNodeId);

				return reqNode;
			});
		} else {
			reqNodes = map(nodes, node => ({ nodeId: node.nodeId, nodeType: node.nodeType }));
		}

		if(handleTree && (isValid || noCheck)) {
			handleTree(action, reqNodes, type, () => {
				this.setState({
					nodes: [],
					action: '',
					removeOriginal: false
				});
			});
		}
	}

	handleTreeMarker = (nodeId, parentId, position) => {
		const $marker = $('#jstree-marker');
		
		if(!$marker.length) return;

		if(!position) {
			$marker.addClass('d-none');
		} else {
			$marker.removeClass('d-none');
		}
		
		const $node = $(`#${ nodeId }`);
		const $anchor = $('.jsTreeAnchor', $node);

		const width = $anchor.innerWidth();
		const left = $anchor.offset().left + 6;
		const right = $(document).innerWidth() - (left + width) + 10;

		$marker.css({ width: 'auto', left, right, marginTop: -1 });
	}

	handleChild = node => {
		const $tree = $(this.tree.current);
		const hasChild = node.children.length;

		if(hasChild) {
			const $node = $(`#${ node.id }`, $tree);
			$node.addClass('jstree-node-has-child');
		}
	}

	makeNode = (node, tree) => {
		const { prefix } = this.props;

		let obj = {};

		const parent = node.parentId || node.parentNodeId;

		const { allowed, type } = this.props;

		const props = {
			type,
			allowed,
		}

		const newNode = (data, attrs) => ({
			...data,
			li_attr: {
				...data.li_attr,
				...attrs
			}
		});

		const theme = this.getTheme(node.licensed, node.isPurchased) || node.theme;

		node.theme = theme;

		const invalidNodeId = isEmpty(node.nodeId);
		const nodeId = !invalidNodeId ? node.nodeId : uuid();

		let objectId = node.objectId;

		if(!objectId) {
			switch(node.nodeType) {
				case 20: objectId = node.folderId; break;
				case 30: objectId = node.groupId; break;
				case 40: objectId = node.ungroupId; break;
				case 50: objectId = node.tourId; break;
				case 60: objectId = node.legId; break;
				default: objectId = false; break;
			}
		}

		const classes = this.getNodeClass(node.nodeType);
		const notifClass = (node.unread && ((node.nodeType === 50) || (node.nodeType === 60))) ? 'jstree-has-notif' : '';

		const defaultAttrs = {
			theme,
			tree,

			'data-parent': parent,
			'data-ctxtoggle-parent': true,
			'data-id': nodeId,
			'data-item-status': !invalidNodeId ? 'completed' : 'processing',

			props: JSON.stringify(props),
			
			id: `${ prefix }${ nodeId }${ invalidNodeId ? '_TEMP' : '' }`,
			text: node.title,
			objectid: objectId,
			type: node.nodeType,

			root: node.root || false,
			unread: node.unread || false,
			licensed: node.licensed || false,
			ispurchased: node.isPurchased || false,
			
			class: `${ classes } ${ notifClass } ${ invalidNodeId ? 'jstree-item-processing' : '' }`,
		}

		const defaultObj = {
			id: `${ prefix }${ nodeId }`,
			parent: (parent && parent !== '#') ? `${ prefix }${ parent }` : '#',
			text: this.getTemplate(node, tree),
			a_attr: {
				class: this.getAnchorClass(node.nodeType)
			},
			li_attr: {
				...defaultAttrs,
			},
			state: {
				opened: false,
				disabled: false,
				selected: false,
			}
		}

		obj = defaultObj;

		if((node.nodeType === 10) || (node.nodeType === 20) || (node.nodeType === 60)) {
			obj = newNode(defaultObj, {
				licensed: node.licensed
			});
		}

		if(node.nodeType === 50) {
			obj = newNode(defaultObj, {
				published: node.published || false,
			});
		}
		
		if(node.nodeType === 60) {
			obj = newNode(defaultObj, {
				'data-draggable': true,
				playtime: node.playTime || 0
			});
		}

		return obj;
	}

	makeData = () => {
		const { nodes, nodesData, type } = this.props;

		return map(nodes, item => {
			const node = get(nodesData, item.nodeId);
			return this.makeNode(node, type);
		});
	}

	moveNode = (node, tree, dParentNode, callback) => {
		const nodeId = this.getPrefixedId(node.nodeId);
		const treeNode = this.getNode(nodeId);
		const $tree = $(`.jstree[data-tree=${ tree }]`);

		if(treeNode && $tree.length) {
			const parentNodeId = this.getPrefixedId(node.parentNodeId);
			const parentNode = this.getNode(parentNodeId);

			if(parentNode) {
				$tree.jstree(true).move_node(treeNode, parentNodeId, 'last', () => {
					if(callback && typeof callback === 'function') {
						callback(treeNode, $tree);
					}
				});
			} else {
				this.setState({
					removeOriginal: true
				}, () => {
					this.createNodeData('move', treeNode.id, treeNode, dParentNode, node.position, this.handleTree);
				});
			}
		}
	}

	expandNode = nodeId => {
		if(!isEmpty(this.api)) this.api.open_node(nodeId);
	}

	contractNode = nodeId => {
		if(!isEmpty(this.api)) this.api.close_node(nodeId);
	}

	updateBusyNode = (nodeId, remove = false) => {
		setTimeout(() => {
			const el = $(`#${ nodeId }`);

			if(remove) {
				el.removeClass('jstree-node-loading');
			} else {
				el.addClass('jstree-node-loading');
			}
		}, 100);
	}

	removeNode = id => {
		this.api.delete_node(id);
	}

	removeTreeNodes = ids => {
		if(ids.length) {
			this.api.delete_node(ids);
		}
	}

	renameNode = (data, silent = false) => {
		const id = this.getPrefixedId(data.nodeId);
		const treeNode = this.getNode(id);
		
		if(treeNode) {
			treeNode.li_attr.id = id;
			treeNode.li_attr.text = data.title;

			this.updateNode(treeNode, () => {
				if(!silent) {
					Notify.success(`${ this.getNodeTypeTitle(treeNode.li_attr.type) } renamed successfully!`);
				}
			});
		}
	}

	updateNode = (treeNode, callback) => {
		const tree = this.getTree(treeNode.id);

		const node = {
			nodeType: treeNode.li_attr.type,
			title: treeNode.li_attr.text,
			theme: this.getTheme(treeNode.li_attr.licensed, treeNode.li_attr.ispurchased)
		}

		const template = this.getTemplate(node, tree.type);
		const rename = this.api.rename_node(treeNode, template);

		this.updateHTML(treeNode);

		if(rename) {
			if(callback) callback(treeNode);
		}
	}

	updateHTML = node => {
		setTimeout(() => {
			const $node = document.getElementById(node.id);
			
			if($node) {
				const $nodeAnchor = $node.querySelector('.jsTreeAnchor');
				const liAttrs = node.li_attr;
				const aAttrs = node.a_attr;
				const excludedAttrs = ['class'];

				mapValues(liAttrs, (value, name) => {
					if($node) {
						if(indexOf(excludedAttrs, name) === -1) {
							$node.setAttribute(name, value);
						}
					}
				});

				mapValues(aAttrs, (value, name) => {
					if($nodeAnchor) {
						if(indexOf(excludedAttrs, name) === -1) {
							$nodeAnchor.setAttribute(name, value);
						}
					}
				});
			}
		}, 100);
	}

	renderNodes = () => {
		const data = this.makeData();
		const validNodeType = [20, 30, 40, 50, 60];

		if(!isEmpty(this.api)) {
			map(data, node => {
				const existingNode = this.getNode(node.id);
				if(existingNode ||(node.li_attr && (indexOf(validNodeType, node.li_attr.type) < 0))) return;

				this.api.create_node(node.parent, node, 'last', item => {
					this.expandNode(item.parent);
				});
			}); 
		}
	}

	render() {
		const { id, className, type, allowed, prefix } = this.props;

		return <div id={ id } className={ `md-tree jstree-ready ${ className }` } data-tree={ type } data-allowed={ allowed } data-prefix={ prefix } ref={ this.tree }/>
	}
}

/* ----------  Prop Types  ---------- */

Tree.defaultProps = {
	rootDraggable: false,

	prefix: '',
	className: 'default-tree',
	
	removeNodes: false,

	allowed: [],

	handleTree: false,
}

Tree.propTypes = {
	rootDraggable: PropTypes.bool,

	prefix: PropTypes.string,
	className: PropTypes.string,
	// urlTourId: PropTypes.string,
	id: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	
	allowed: PropTypes.arrayOf(PropTypes.string),
	nodes: PropTypes.arrayOf(PropTypes.object).isRequired,
	nodesData: PropTypes.objectOf(PropTypes.object).isRequired,
	
	getNodes: PropTypes.func.isRequired,
	
	handleTree: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	
	removeNodes: PropTypes.func,
}

/* ----------  Exports  ---------- */

export default Tree;
