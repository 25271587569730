/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Map Helper
import MapHelper from './../../Helpers/MapHelper';

/* ----------  Scripts  ---------- */

let LAST_POSITION = null;
let LAST_POV = null;

class GoogleStreetview extends React.Component {
	constructor(props) {
		super(props);

		this.map = null;
		this.streetview = null;

		this.google = window.google.maps;
		
		this.streetviewCanvas = React.createRef();
	}

	componentWillUnmount() {
		LAST_POSITION = null;
		LAST_POV = null;
	}

	getPosition = (flat = true) => {
		const position = this.streetview.getPosition();

		if(flat) {
			return {
				lat: position.lat(),
				lng: position.lng(),
			}
		}
		
		return position;
	}

	getPov = () => this.streetview.getPov()
	
	getVisible = () => this.streetview.getVisible()
	
	getPano = () => this.streetview.getPano()
	
	getZoom = () => this.streetview.getZoom()

	setOptions = options => {
		this.streetview.setOptions(options)
	}

	setPosition = (position, callback) => {
		this.streetview.setPosition(position);

		this.google.event.addListenerOnce(this.streetview, 'position_changed', () => {
			if(callback) callback(this.getPosition());
		});
	}

	setPov = (pov, callback) => {
		this.streetview.setPov(pov);

		this.google.event.addListenerOnce(this.streetview, 'pov_changed', () => {
			if(callback) callback(this.getPov());
		});
	}
	
	setZoom = zoom => this.streetview.setZoom(zoom)
	
	setVisible = visible => this.streetview.setVisible(visible)

	bindMap = map => map.setStreetView(this.streetview)

	load = callback => {
		const { zoom, center, pov, zoomControl, rotateControlOptions } = this.props;
		const streetviewCanvas = this.streetviewCanvas.current;

		this.map = new this.google.Map(streetviewCanvas, {
			zoom,
			center: this.makeCenter(center.lat, center.lng),
			disableDefaultUI: true,
		});

		this.streetview = new this.google.StreetViewPanorama(streetviewCanvas, {
			pov,
			visible: true,
			fullscreenControl: false,
			position: this.makeCenter(center.lat, center.lng),
			zoomControl,
			rotateControlOptions: {
				position: rotateControlOptions.position
			},

		});

		this.streetview.addListener('position_changed', () => {
			MapHelper.checkPanorama(this.getPosition(), () => {
				LAST_POSITION = this.getPosition();
				LAST_POV = this.getPov();

				this.setVisible(true);
			}, () => {
				this.setVisible(false);
			});

			if(callback) callback();
		});

		this.streetview.addListener('pov_changed', () => {
			LAST_POSITION = this.getPosition();
			LAST_POV = this.getPov();
		});

		this.streetview.addListener('visible_changed', () => {
			if(!this.getVisible()) {
				this.setPosition(LAST_POSITION);
				this.setPov(LAST_POV);
			}
		});

		this.bindMap(this.map);
	}

	makeCenter = (lat, lng) => new this.google.LatLng(lat, lng)

	render() {
		const { className, id } = this.props;

		return <div className={ `google-map-streetview googleMapStreetview googleComponent ${ className }` } id={ id } ref={ this.streetviewCanvas } onMouseMove={ this.onMouseMove }/>;
	}
}

/* ----------  Prop Types  ---------- */

GoogleStreetview.defaultProps = {
	zoom: 14,
	className: '',
	
	zoomControl: true,

	center: {
		lat: 40.785196,
		lng: -73.969454
	},

	pov: {
		heading: 0,
		pitch: 0,
		zoom: 1
	},

	rotateControlOptions: {}
}

GoogleStreetview.propTypes = {
	zoom: PropTypes.number,

	className: PropTypes.string,
	id: PropTypes.string.isRequired,
	
	zoomControl: PropTypes.bool,
	
	center: PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number
	}),

	pov: PropTypes.shape({
		heading: PropTypes.number,
		pitch: PropTypes.number,
		zoom: PropTypes.number
	}),

	rotateControlOptions: PropTypes.shape()
}

/* ----------  Exports  ---------- */

export default GoogleStreetview;
