/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Helpers
import ConfigHelper from '../../../Helpers/ConfigHelper';

// Layout Components
// import HomeNavigation from './HomeNavigation';
import HomeSidebarNavigation from './HomeSidebarNavigation';
// import HomeNavigationDropdown from './HomeNavigationDropdown';

// Search
// import Search from './../../Marketplace/Common/Search';

/* ----------  Scripts  ---------- */

class HomeHeader extends React.Component {
	constructor(props) {
		super(props);

		this.navbar = React.createRef();
		this.navbarPlaceholder = React.createRef();

		this.configs = ConfigHelper.getAppConfigs();
	}

	componentDidMount() {
		// this.updatePlaceholderHeight();
	}

	updatePlaceholderHeight = () => {
		const navbar = this.navbar.current;
		const placeholder = this.navbarPlaceholder.current;

		placeholder.style.height = `${ navbar.clientHeight }px`;
	}

	render() {
		const supStyles = {
			fontSize: '50%',
			top: '-1em',
		}

		return (
			<header id="page-header" className="main-navbar-container" data-fullheight-offset>
				{/* <div className="navbar-placeholder" ref={ this.navbarPlaceholder }/> */}
				<nav id="cntMainNavbar" ref={ this.navbar }>
					<div className="navbar-inner">
						<div className="navbar-header">
							<Link to="/home" className="brand">
								<img src={ this.configs.appLogo } alt=""/>
							</Link>
							<div className="nav-btn">
								<i/>
								<i/>
								<i/>
							</div>
						</div>
						<div className="navbar-contents" style={{ alignItems: 'center' }}>
							{/* <HomeNavigation/> */}
							{/* { this.props.search } */}
							<div className="navbar-brand-meta" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1, justifyContent: 'space-between' }}>
								<h1 className="brand-name text-dark" style={{ marginRight: '10px' }}>{ this.configs.appName }<sup style={ supStyles }>TM</sup></h1>
								<h2 className="brand-tagline text-dark" style={{ margin: 0 }}>{ this.configs.appTagline }<sup style={ supStyles }>TM</sup></h2>
							</div>
						</div>
						<div className="sidebar-actions-container sidebarActions">
							<HomeSidebarNavigation user={ this.props.user }/>
						</div>
					</div>
					{/* <HomeNavigationDropdown/> */}
				</nav>
			</header>
		);
	}
}

/* ----------  Exports  ---------- */

export default HomeHeader;
