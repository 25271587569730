/* ----------  Imports  ---------- */

// Axios
import axios from 'axios';

// Global
import Global from './../../../../Constants/Global';

// Services
import Storage from './../../../../Services/Storage';
import FirebaseService from './../../../../Services/FirebaseService';

/* ----------  Scripts  ---------- */

const LogoutUser = (success, fail) => (dispatch, getState) => {
	const url = `${ Global.API_BASE_URL }/users/logout`;

	const state = getState();
	const { user: { fcmToken } } = state;

	const logout = (token = null) => {
		dispatch({
			type: 'USER_FCM_TOKEN_UPDATE',
			payload: token,
		});

		dispatch({ type: 'USER_TOKEN_REMOVE' });
		dispatch({ type: 'USER_REMOVE_PROFILE' });
	}
	
	axios.post(url, { fcmToken }).then(response => {
		const { result, status } = response.data;

		if(status) {
			if(fcmToken) {
				FirebaseService.getToken(true).then(token => {
					logout(token);
				}).catch(err => {
					console.log(err);
					logout(null);
				});
			} else {
				logout(null);
			}
		}

		Storage.removeTree();

		if(success) success(status, result);
	}).catch(error => {
		logout(null);
		Storage.removeTree();

		const { response } = error;
		if(fail) {
			if(response) {
				fail(response.data.status, response.data.error.reasons);
			} else {
				fail(false, []);
			}
		}
	});
}

/* ----------  Exports  ---------- */

export default LogoutUser;
