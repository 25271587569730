/* ----------  Scripts  ---------- */

const CONVERSATIONS_INITIAL_STATE = [];

const ReducerConversation = (state = CONVERSATIONS_INITIAL_STATE, action) => {
	if(action.type === 'GET_CONVERSATIONS') {
		return [ ...state, ...action.payload.results ];
	} else if(action.type === 'REMOVE_CONVERSATIONS') {
		return CONVERSATIONS_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerConversation;