/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router
import { withRouter } from 'react-router';

// Prop Types
import PropTypes from 'prop-types';

// React Router DOM
import { Link } from 'react-router-dom';

// Lodash
import { map } from 'lodash';

/* ----------  Scripts  ---------- */

class AdminNavLink extends React.Component {
	constructor(props) {
		super(props);

		this.navItem = React.createRef();
	}

	componentDidMount() {
		this.checkChildForActive();
	}

	getComponent = node => {
		const { children } = this.props;

		if(children && children.length) {
			if((typeof children === 'string') && (node === 'string')) {
				return children;
			}

			return map(children, child => {
				if(child.type === node) return child;
				return null;
			});
		}

		return null;
	}

	getParentClasses = () => {
		const { parentClassName, activeClassName, location, to } = this.props;
		const classes = parentClassName.split(' ');
		const path = location.pathname;

		if(to === path) {
			classes.push(activeClassName);
		}

		return classes.join(' ');
	}

	checkChildForActive = () => {
		const navItem = this.navItem.current;
		const isActive = navItem.classList.contains('act_item');

		if(isActive) {
			const parent = navItem.closest('ul').closest('li');

			parent.classList.add('current_section');
		}
	}

	render() {
		return (
			<li className={ this.getParentClasses() } ref={ this.navItem }>
				<Link to={ this.props.to } className={ this.props.className } data-nav-item>
					{ this.getComponent('span') }
					{ this.getComponent('string') }
				</Link>
				{ this.getComponent('ul') }
			</li>
		);
	}
}

AdminNavLink.defaultProps = {
	className: '',
	parentClassName: '',
	activeClassName: 'active',
	location: {
		pathname: ''
	}
}

AdminNavLink.propTypes = {
	className: PropTypes.string,
	to: PropTypes.string.isRequired,
	activeClassName: PropTypes.string,
	parentClassName: PropTypes.string,
	children: PropTypes.node.isRequired,
	location: PropTypes.shape({
		pathname: PropTypes.string
	})
}

/* ----------  Exports  ---------- */

export default withRouter(AdminNavLink);