/* ----------  Scripts  ---------- */

const ACTION_PANEL_NODES_INITIAL_STATE = [];

const ReducerMyToursTreesActionPanelNodes = (state = ACTION_PANEL_NODES_INITIAL_STATE, action) => {
	if(action.type === 'MYTOURS_TREES_GET_ACTION_PANEL_NODES') {
		return action.payload.results;
	} else if(action.type === 'MYTOURS_TREES_REMOVE_ACTION_PANEL_NODES') {
		return ACTION_PANEL_NODES_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMyToursTreesActionPanelNodes;