/* ----------  Imports  ---------- */

// React
import React from 'react';

// React DOM
import ReactDOM from 'react-dom';

// React Redux
import { Provider } from 'react-redux';

// Store
import Store from './Redux/Store';

// App
import App from './App';

// Services
import registerServiceWorker from './registerServiceWorker';

/* ----------  Render  ---------- */

ReactDOM.render(
	<Provider store={ Store }>
		<App/>
	</Provider>, document.getElementById('appRoot')
);

/* ----------  Service Workers  ---------- */

registerServiceWorker();