
/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Redux
import { connect } from 'react-redux';

// Redux
import { bindActionCreators } from 'redux';

// Prop Types
import PropTypes from 'prop-types';

// React Router DOM
import { withRouter, Link } from 'react-router-dom';

// Lodash
import { debounce, map, isEmpty, get } from 'lodash';

// Star Rating
// import StarRating from 'react-star-rating';

// Constants
import Global from './../Constants/Global';

// Layouts
import AdminLayout from './../Layouts/AdminLayout';

// Containers
import Panel from './../Containers/Marketplace/';

// Actions
import GetLanguages from './../Redux/Actions/Languages/GetLanguages';
import GetCategories from './../Redux/Actions/Categories/GetCategories';

// Cart Actions
import AddCartItem from './../Redux/Actions/Cart/AddCartItem';
import RemoveCartItem from './../Redux/Actions/Cart/RemoveCartItem';

// Conversation
import StartConversation from './../Redux/Actions/Conversation/StartConversation';

// Graphic Panel
import ToursPlayer from './../Containers/ToursPanel/ToursPlayer';

// UIKit
import Modal from './../Components/UIkit/Modal';
import Input from './../Components/UIkit/Input';
import Alerts from './../Components/UIkit/Alerts';

// Preloader
import Preloader from './../Components/Common/Preloader';

// Utils
import FullHeight from './../Components/Utils/FullHeight';
import Scrollbar from './../Components/Utils/Scrollbar';

// Context Menus
import ContextMenu from './../Components/ContextMenu/';

// Helpers
import Ctx from './../Helpers/Ctx';
import Notify from './../Helpers/Notify';
import InputHelper from './../Helpers/InputHelper';
import InputUI from './../Helpers/InputUI';

/* ----------  Scripts  ---------- */

class ViewMarketplace extends React.Component  {
	constructor(props) {
		super(props);

		this.panelRef = React.createRef();
		this.toursPanelRef = React.createRef();
		
		this.reportModalRef = React.createRef();

		this.rateModalRef = React.createRef();
		this.rateFormRef = React.createRef();
		
		this.conversationModalRef = React.createRef();

		this.reportFormInitialState = {
			legId: '',
			message: '',
		}

		this.conversationFormInitialState = {
			nodeId: '',
			nodeType: 60,
			message: '',
			origin: 'MARKETPLACE',
		}

		this.rateFormInitialState = {
			legId: '',
			comment: '',
			rating: '',
		}

		this.state = {
			reporting: false,
			reportFormErrors: [],
			reportForm: this.reportFormInitialState,

			startingConversation: false,
			conversationFormErrors: [],
			conversationForm: this.conversationFormInitialState,

			rating: false,
			rateLoading: false,
			rateFormErrors: [],
			rateForm: this.rateFormInitialState,

			player: {
				active: false,
				isRecording: false,
				isPlaying: false,
				isSaving: false
			},

			ctx: {
				legOptions: {
					refId: '',
					legId: '',
					nodeId: '',
					leg: null,
					cart: false,
					favorite: false,
					isPurchased: false,
					isSubscribed: false,
				},
			}
		}

		this.requestLanguages = debounce(this.doRequestLanguages, 100);
		this.requestCategories = debounce(this.doRequestCategories, 100);

		this.addCartItem = debounce(this.doAddCartItem, 100);
		this.removeCartItem = debounce(this.doRemoveCartItem, 100);
		
		this.startConversation = debounce(this.doStartConversation, 100);
	}

	componentWillMount() {
		this.getLanguages();
		this.getCategories();
		
		document.title = `${ Global.APP_NAME } :: Marketplace`;
	}

	componentDidMount() {
		Ctx.init();
	}

	onReportModalHide = () => {
		this.setState({
			reporting: false,
			reportFormErrors: [],
			reportForm: this.reportFormInitialState
		});
	}

	onConversationModalHide = () => {
		this.setState({
			startingConversation: false,
			conversationFormErrors: [],
			conversationForm: this.conversationFormInitialState,
		});
	}

	onRateModalShow = () => {
		this.getLegRating();
	}

	onRateModalHide = () => {
		this.setState({
			rating: false,
			rateLoading: false,
			rateFormErrors: [],
			rateForm: this.rateFormInitialState
		});
	}

	getLanguages = () => {
		this.requestLanguages();
	}

	getCategories = () => {
		this.requestCategories();
	}

	getLegRating = () => {
		const { rateForm } = this.state;
		const modal = this.rateModalRef.current;
		const form = this.rateFormRef.current;
		const panel = this.panelRef.current.wrappedInstance;

		this.setState({ rateLoading: true });

		panel.getLegRating(rateForm.legId, response => {
			this.setState({ rateLoading: false });
			this.updateRateState({
				comment: response.comment,
				rating: response.rating ? response.rating.userRating : 3,
			});

			InputUI.update(form);
		}, () => {
			modal.hide();
		});
	}

	setLegRating = () => {
		const { rateForm } = this.state;
		const modal = this.rateModalRef.current;
		const panel = this.panelRef.current.wrappedInstance;

		this.setState({ rating: true });

		panel.setLegRating(rateForm, () => {
			modal.hide();
			Notify.success('Thank you :)');
		}, reasons => {
			Notify.error('Please try again :(');
			const errors = map(reasons, reason => reason.message);
			
			this.setState({
				rating: false,
				rateFormErrors: errors,
			});
		});
	}

	doRequestLanguages = () => {
		this.props.getLanguages();
	}

	doRequestCategories = () => {
		this.props.getCategories();
	}

	doAddCartItem = legs => {
		const legIds = map(legs, leg => leg.legId);

		const data = {
			legIds,
			legs
		}
		
		this.props.addCartItem(data, () => {
			Notify.success('Leg added to cart successfully!');
		}, () => {
			Notify.success('Oops! Something went wrong :(');
		});
	}

	doRemoveCartItem = legs => {
		const legIds = map(legs, leg => leg.legId);

		const data = {
			legIds,
			legs
		}
		
		this.props.removeCartItem(data);
	}

	doStartConversation = () => {
		const { conversationForm } = this.state;

		this.setState({ startingConversation: true });

		this.props.startConversation(conversationForm, () => {
			const modal = this.conversationModalRef.current;

			modal.hide();

			Notify.success('Message sent.');
			this.setState({ startingConversation: false });
		}, (status, reasons) => {
			Notify.error('Please try again :(');
			const errors = map(reasons, reason => reason.message);
			
			this.setState({
				startingConversation: false,
				conversationFormErrors: errors,
			});
		});
	}

	// Ctx Actions

	ctxPlay = (e, options) => {
		e.preventDefault();

		this.playTour(options.legId);

		Ctx.close();
	}

	ctxCart = (e, options) => {
		e.preventDefault();

		this.updateCart(options.cart, options.leg);

		Ctx.close();
	}

	ctxLocate = (e, options) => {
		e.preventDefault();

		// this.locateLegs(options.legId);
		this.locate(options.leg);

		Ctx.close();
	}

	ctxMessage = (e, options) => {
		e.preventDefault();

		this.handleStartConversation(options.nodeId);

		Ctx.close();
	}

	ctxSubscribe = (e, options) => {
		e.preventDefault();

		this.updateSubscribe(options.isSubscribed, options.legId);

		Ctx.close();
	}

	ctxRating = (e, options) => {
		e.preventDefault();

		this.handleRateLeg(options.legId);

		Ctx.close();
	}

	ctxFavorite = (e, options) => {
		e.preventDefault();

		this.updateFavorite(options.favorite, options.legId);

		Ctx.close();
	}

	ctxReport = (e, options) => {
		e.preventDefault();

		this.handleReportLeg(options.legId);

		Ctx.close();
	}

	checkInCart = legId => {
		const { cart } = this.props;

		return !isEmpty(get(cart, legId));
	}

	isAuthUser = leg => {
		if(!leg || !leg.user) return false;

		const { user } = this.props;

		return leg.user.userId === user.profile.userId;
	}

	playTour = legId => {
		const toursPanel = this.toursPanelRef.current.wrappedInstance;
		
		toursPanel.initializePlay(null, legId, 60, null, false);
	}

	handleContext = (type, leg, target, event) => {
		const options = {
			leg,
			legId: leg.legId,
			refId: leg.nodeId,
			nodeId: leg.nodeId,
			favorite: leg.favorite,
			cart: this.checkInCart(leg.legId),
			isPurchased: leg.isPurchased || false,
			isSubscribed: leg.isSubscribed || false,
		}

		this.updateCtxContent(type, options, () => {
			Ctx.open(type, target, event);
		});
	}

	handleReportTxt = e => {
		const { name, value } = e.currentTarget;

		this.updateReportState({ [name]: value });
	}

	handleConversationTxt = e => {
		const { name, value } = e.currentTarget;

		this.updateConversationState({ [name]: value });
	}

	handleRateTxt = e => {
		const { name, value } = e.currentTarget;

		this.updateRateState({ [name]: value });
	}

	handleRateRating = e => {
		const { name, value } = e.currentTarget;

		this.updateRateState({ [name]: value });
	}

	handleReportLeg = legId => {
		const modal = this.reportModalRef.current;

		this.updateReportState({ legId }, modal.show);
	}

	handleStartConversation = nodeId => {
		const modal = this.conversationModalRef.current;

		this.updateConversationState({ nodeId }, modal.show);
	}

	handleRateLeg = legId => {
		const modal = this.rateModalRef.current;

		this.updateRateState({ legId }, modal.show);
	}

	locate = leg => {
		const panel = this.panelRef.current.wrappedInstance;
		if(panel && leg.position.length) panel.changeMapPosition(leg.position);
	}

	locateLegs = legId => {
		const { host, protocol } = window.location;
		const homeUrl = `${ protocol }//${ host }/home?legId=${ legId }`;

		window.open(homeUrl, '_blank');
	}

	submitConversation = e => {
		e.preventDefault();

		const isValid = this.validateConversationForm();

		if(isValid) {
			this.startConversation();
		}
	}

	reportLeg = e => {
		e.preventDefault();

		const isValid = this.validateReportForm();

		if(isValid) {
			const { reportForm } = this.state;
			const modal = this.reportModalRef.current;
			const panel = this.panelRef.current.wrappedInstance;

			this.setState({ reporting: true });

			panel.reportLeg(reportForm, () => {
				Notify.success('Leg reported successfully');
				this.setState({ reporting: false }, modal.hide);
			}, reasons => {
				Notify.error('Please try again :(');
				const errors = map(reasons, reason => reason.message);
				
				this.setState({
					reporting: false,
					reportFormErrors: errors,
				});
			});
		}
	}

	rateLeg = e => {
		e.preventDefault();

		const isValid = this.validateRateForm();

		if(isValid) this.setLegRating();
	}

	validateReportForm = () => {
		let isValid = true;

		const errors = [];
		const { reportForm: { message } } = this.state;

		if(InputHelper.isEmpty(message)) {
			isValid = false;
			errors.push('Please enter a valid message.');
		}

		this.setState({ reportFormErrors: errors });

		return isValid;
	}

	validateConversationForm = () => {
		let isValid = true;

		const errors = [];
		const { conversationForm: { message } } = this.state;

		if(InputHelper.isEmpty(message)) {
			isValid = false;
			errors.push('Please enter a valid message.');
		}

		this.setState({ conversationFormErrors: errors });

		return isValid;
	}

	validateRateForm = () => {
		let isValid = true;

		const errors = [];
		const { rateForm: { rating, comment } } = this.state;

		if(!rating) {
			isValid = false;
			errors.push('Invalid rating.');
		}

		if(InputHelper.isEmpty(comment)) {
			isValid = false;
			errors.push('Please enter a valid comment.');
		}

		this.setState({ rateFormErrors: errors });

		return isValid;
	}

	updateReportState = (updates, callback) => {
		const { reportForm } = this.state;

		this.setState({
			reportForm: {
				...reportForm,
				...updates,
			}
		}, () => {
			if(callback) callback();
		});
	}

	updateRateState = (updates, callback) => {
		const { rateForm } = this.state;

		this.setState({
			rateForm: {
				...rateForm,
				...updates,
			}
		}, () => {
			if(callback) callback();
		});
	}

	updateConversationState = (updates, callback) => {
		const { conversationForm } = this.state;

		this.setState({
			conversationForm: {
				...conversationForm,
				...updates,
			}
		}, () => {
			if(callback) callback();
		});
	}

	updateCart = (cart, leg) => {
		if(!cart) {
			this.addCartItem([leg]);
		} else {
			this.removeCartItem([leg]);
		}
	}

	updateFavorite = (favorite, legId) => {
		const panel = this.panelRef.current.wrappedInstance;

		if(!favorite) {
			panel.setLegFavorite(legId);
		} else {
			panel.setLegUnfavorite(legId);
		}
	}

	updateSubscribe = (isSubscribed, legId) => {
		const panel = this.panelRef.current.wrappedInstance;

		if(!isSubscribed) {
			panel.subscribeLeg(legId);
		} else {
			panel.unsubscribeLeg(legId);
		}
	}

	updateCtxContent = (type, props, callback) => {
		const { ctx } = this.state;

		this.setState({
			ctx: {
				...ctx,
				[type]: {
					...props
				}
			}
		}, callback ? callback() : null);
	}

	renderTourOptionsCtx = () => {
		const { legOptions } = this.state.ctx;

		return (
			<ContextMenu type="legOptions" refId={ legOptions.refId }>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxPlay(e, legOptions) }>
						<i className="item-icon material-icons">beenhere</i>
						PreTour
					</Link>
				</li>
				<li className={ this.isAuthUser(legOptions.leg) || legOptions.isPurchased ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxCart(e, legOptions) }>
						<i className="item-icon material-icons">{ legOptions.cart ? 'remove_shopping_cart' : 'add_shopping_cart' }</i>
						{ legOptions.cart ? 'Remove License' : 'License' }
					</Link>
				</li>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxLocate(e, legOptions) }>
						<i className="item-icon material-icons">explore</i>
						Location
					</Link>
				</li>
				<li className={ this.isAuthUser(legOptions.leg) ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxMessage(e, legOptions) }>
						<i className="item-icon material-icons">message</i>
						Message
					</Link>
				</li>
				<li className={ this.isAuthUser(legOptions.leg) ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxSubscribe(e, legOptions) }>
						<i className="item-icon material-icons">{ legOptions.isSubscribed ? 'not_interested' : 'subscriptions' }</i>
						{ legOptions.isSubscribed ? 'Unsubscribe' : 'Subscribe' }
					</Link>
				</li>
				<li className={ this.isAuthUser(legOptions.leg) ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxRating(e, legOptions) }>
						<i className="item-icon material-icons">star</i>
						Rating
					</Link>
				</li>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxFavorite(e, legOptions) }>
						<i className="item-icon material-icons">{ legOptions.favorite ? 'favorite' : 'favorite_bordered' }</i>
						{ legOptions.favorite ? 'Unfavorite' : 'Favorite' }
					</Link>
				</li>
				<li className={ this.isAuthUser(legOptions.leg) ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxReport(e, legOptions) }>
						<i className="item-icon material-icons">warning</i>
						Report
					</Link>
				</li>
			</ContextMenu>
		);
	}

	renderRateModalContent = () => {
		const { rateLoading } = this.state;

		if(rateLoading) return <Preloader loading={ rateLoading } minimal center/>;

		const { rateForm, rating, rateFormErrors } = this.state;

		return (
			<React.Fragment>
				<div className="uk-modal-header">
					<h3 className="uk-modal-title">Rate Leg:</h3>
				</div>
				<div className="uk-modal-body">
					<div className="md-form-group">
						<select name="rating" className="form-control" value={ rateForm.rating } onChange={ this.handleRateRating }>
							<option value="0">0 Star</option>
							<option value="1">1 Star</option>
							<option value="2">2 Stars</option>
							<option value="3">3 Stars</option>
							<option value="4">4 Stars</option>
							<option value="5">5 Stars</option>
						</select>
					</div>
					<Input
						title="Comment"
						name="comment"
						id="txtRateComment"
						value={ rateForm.comment }
						onChange={ this.handleRateTxt }/>

					<Alerts show={ rateFormErrors.length > 0 } data={ rateFormErrors }/>
				</div>
				<div className="uk-modal-footer">
					<div className="footer-preloader m-r-10">
						<Preloader size={ 20 } loading={ rating } relative minimal/>
					</div>
					<Link to="#closeModal" className={ `md-btn md-btn-flat md-btn-flat-default md-btn-wave waves-effect waves-button uk-modal-close ${ rating ? 'disabled' : '' }` }>
						Cancel
					</Link>
					<button type="submit" className={ `md-btn md-btn-flat md-btn-flat-success md-btn-wave waves-effect waves-button ${ rating ? 'disabled' : '' }` } onClick={ this.rateLeg }>
						Save
					</button>
				</div>
			</React.Fragment>
		);
	}

	render() {
		const { languages, categories, location } = this.props;

		const { player } = this.state;
		const { reportForm, reporting, reportFormErrors } = this.state;
		const { conversationForm, startingConversation, conversationFormErrors } = this.state;

		return (
			<AdminLayout view="marketplace">
				<div className="uk-grid uk-grid-small">
					<div className="uk-width-large-1-2">
						<FullHeight className="marketplace-container default-marketplace" id="marketplace" data-type="marketplace">
							<Scrollbar className="marketplace-inner">
								<Panel 
									location={ location }
									ref={ this.panelRef }
									languages={ languages }
									categories={ categories }
									playTour={ this.playTour }
									handleContext={ this.handleContext }/>
							</Scrollbar>
						</FullHeight>
					</div>
					<div className="uk-width-large-1-2">
						<div className="md-card md-graphic-panel-card" id="mdGraphicPanelCard">
							<ToursPlayer active={ player.active } ref={ this.toursPanelRef }/>
						</div>
					</div>
				</div>
				<div className="contexts-container">
					{ this.renderTourOptionsCtx() }
				</div>
				<div className="modals-container" data-modals-holder>
					<Modal
						popup="report"
						bgClose={ false }
						keyboardClose={ false } 
						onHide={ this.onReportModalHide }
						ref={ this.reportModalRef }>
						<form>
							<div className="uk-modal-dialog">
								<div className="uk-modal-header">
									<h3 className="uk-modal-title">Report</h3>
								</div>
								<div className="uk-modal-body">
									<Input
										title="Report Message"
										name="message"
										id="txtReportMessage"
										value={ reportForm.message }
										onChange={ this.handleReportTxt }/>

									<Alerts show={ reportFormErrors.length > 0 } data={ reportFormErrors }/>
								</div>
								<div className="uk-modal-footer">
									<div className="footer-preloader m-r-10">
										<Preloader size={ 20 } loading={ reporting } relative minimal/>
									</div>
									<Link to="#closeModal" className={ `md-btn md-btn-flat md-btn-flat-default md-btn-wave waves-effect waves-button uk-modal-close ${ reporting ? 'disabled' : '' }` }>
										Cancel
									</Link>
									<button type="submit" className={ `md-btn md-btn-flat md-btn-flat-success md-btn-wave waves-effect waves-button ${ reporting ? 'disabled' : '' }` } onClick={ this.reportLeg }>
										Save
									</button>
								</div>
							</div>
						</form>
					</Modal>
					<Modal
						popup="startConversation"
						bgClose={ false }
						keyboardClose={ false }
						onHide={ this.onConversationModalHide }
						ref={ this.conversationModalRef }>
						<form>
							<div className="uk-modal-dialog">
								<div className="uk-modal-header">
									<h3 className="uk-modal-title">Conversation</h3>
								</div>
								<div className="uk-modal-body">
									<Input
										title="Message"
										name="message"
										id="txtConversationMessage"
										value={ conversationForm.message }
										onChange={ this.handleConversationTxt }/>

									<Alerts show={ conversationFormErrors.length > 0 } data={ conversationFormErrors }/>
								</div>
								<div className="uk-modal-footer">
									<div className="footer-preloader m-r-10">
										<Preloader size={ 20 } loading={ startingConversation } relative minimal/>
									</div>
									<Link to="#closeModal" className={ `md-btn md-btn-flat md-btn-flat-default md-btn-wave waves-effect waves-button uk-modal-close ${ startingConversation ? 'disabled' : '' }` }>
										Cancel
									</Link>
									<button type="submit" className={ `md-btn md-btn-flat md-btn-flat-success md-btn-wave waves-effect waves-button ${ startingConversation ? 'disabled' : '' }` } onClick={ this.submitConversation }>
										Save
									</button>
								</div>
							</div>
						</form>
					</Modal>
					<Modal
						popup="rate"
						bgClose={ false }
						keyboardClose={ false }
						onShow={ this.onRateModalShow }
						onHide={ this.onRateModalHide }
						ref={ this.rateModalRef }>
						<form ref={ this.rateFormRef }>
							<div className="uk-modal-dialog">
								{ this.renderRateModalContent() }
							</div>
						</form>
					</Modal>
				</div>
			</AdminLayout>
		)
	}
}

/* ----------  Prop Types  ---------- */

ViewMarketplace.defaultProps = {
	location: {}
}

ViewMarketplace.propTypes = {
	getLanguages: PropTypes.func.isRequired,
	getCategories: PropTypes.func.isRequired,
	
	addCartItem: PropTypes.func.isRequired,
	removeCartItem: PropTypes.func.isRequired,
	
	startConversation: PropTypes.func.isRequired,

	cart: PropTypes.objectOf(PropTypes.object).isRequired,
	languages: PropTypes.objectOf(PropTypes.object).isRequired,
	categories: PropTypes.objectOf(PropTypes.object).isRequired,
	
	location: PropTypes.shape(),
	user: PropTypes.shape().isRequired,
}

/* ----------  Redux Scripts  ---------- */

const mapStateToProps = state => ({
	cart: state.cart.itemsData,
	languages: state.languages,
	categories: state.categories,
	user: state.user,
});

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		getLanguages: GetLanguages,
		getCategories: GetCategories,

		addCartItem: AddCartItem,
		removeCartItem: RemoveCartItem,
		
		startConversation: StartConversation,
	}, dispatch)
);

/* ----------  Exports  ---------- */

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewMarketplace));
