/* ----------  Imports  ---------- */
// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Helpers
import ToggleHelper from './../../Helpers/ToggleHelper';

// jQuery
const $ = window.$;

/* ----------  Scripts  ---------- */

class Toggle extends React.Component {
	constructor(props) {
		super(props);
			
		this.input = React.createRef();		
	}

	componentDidMount() {
		this.load();
	}

	onInputChange = e => {
		const { onChange, onChecked } = this.props;
		const { value, checked } = e.currentTarget;

		if(onChange) onChange(value, checked);
		if(onChecked) onChecked(value);
	}

	load = () => {
		const input = this.input.current;
		const $input = $(input);
		const { onChecked, onChange, checkboxClass, radioClass, increaseArea } = this.props;
		
		$input.iCheck({
			checkboxClass: `icheckbox_flat-blue ${ checkboxClass }_no icheck-input`,
			radioClass: `iradio_flat-blue ${ radioClass }_no icheck-input`,
			increaseArea
		}).on('ifChecked', () => {
			if(onChecked) onChecked(input.value);
		}).on('ifChanged', () => {
			const checked = $input.iCheck('update')[0].checked;
			if(onChange) onChange(input.value, checked);
		});
	}

	updateUI = () => {
		const input = this.input.current;
		ToggleHelper.update(input);
	}

	render() {
		const { type, name, value, className, checked, id, disabled, readOnly, indeterminate } = this.props;

		return (
			<input
				type={ type }
				name={ name }
				className={ `react-icheck ${ className }` }
				id={ id }
				value={ value }
				ref={ this.input }
				checked={ checked }
				disabled={ disabled }
				readOnly={ readOnly }
				indeterminate={ indeterminate.toString() }
				// onChange={ this.onInputChange }
				onChange={ () => {} }
				data-md-toggle/>
		);
	}
}

/* ----------  Prop Types  ---------- */

Toggle.defaultProps = {
	id: '',
	className: '',
	
	checked: false,
	disabled: false,
	readOnly: false,
	indeterminate: false,

	increaseArea: '20%',
	radioClass: 'iradio_md',
	checkboxClass: 'icheckbox_md',

	onChecked: false,
	onChange: false,
}

Toggle.propTypes = {
	checked: PropTypes.bool,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	indeterminate: PropTypes.bool,

	id: PropTypes.string,
	className: PropTypes.string,
	radioClass: PropTypes.string,
	increaseArea: PropTypes.string,
	checkboxClass: PropTypes.string,
	type: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

	onChecked: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
}

/* ----------  Exports  ---------- */

export default Toggle;
