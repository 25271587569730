/* ----------  Scripts  ---------- */

const RemoveSubscriptionPlans = () => dispatch => {
	dispatch({
		type: 'REMOVE_SUBSCRIPTION_PLANS',
	});
};

/* ----------  Exports  ---------- */

export default RemoveSubscriptionPlans;
