/* ----------  Imports  ---------- */

// moment
import moment from 'moment';

/* ----------  Scripts  ---------- */

const NewMessage = {
	create: (messageId, data, user, lastMessage) => {
		const newMessage = {
			autoId: lastMessage ? (lastMessage.autoId + 1) : 0,
			messageId,
			read: true,
			message: data.message,
			time: moment().valueOf(),
			contact: {
				contactId: user.profile.userId,
				name: user.profile.name,
				photo: user.profile.photo
			},
		}

		return newMessage;
	}
}

/* ----------  Exports  ---------- */

export default NewMessage;