/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

/* ----------  Scripts  ---------- */

class Tab extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			active: false,
		}

		this.tab = React.createRef();
	}

	componentDidMount() {
		this.onLoad();
	}

	onClick = e => {
		this.props.onClick(e.currentTarget, this.props.target);
	}

	onLoad = () => {
		this.props.onLoad();
	}

	render() {
		const { className, children, ripple, tooltip, target } = this.props;
		const rippleClass = ripple ? 'ripple' : '';

		return (
			<li className={ `bs-tab ${ className } ${ rippleClass }` } data-uk-tooltip="{pos:'top'}" data-ripple={ ripple } title={ tooltip } onLoad={ this.onLoad }>
				<Link to={ `#${ target }` } data-toggle="tab" role="tab" onClick={ this.onClick }>
					{ children }
				</Link>
			</li>
		);
	}
}

/* ----------  Prop Types  ---------- */

Tab.defaultProps = {
	onLoad: () => {},
	onClick: () => {},

	tooltip: '',
	ripple: 'ripple',
	className: '',
}

Tab.propTypes = {
	onLoad: PropTypes.func,
	onClick: PropTypes.func,

	ripple: PropTypes.string,
	tooltip: PropTypes.string,
	className: PropTypes.string,
	target: PropTypes.string.isRequired,

	children: PropTypes.node.isRequired,
}

/* ----------  Exports  ---------- */

export default Tab;