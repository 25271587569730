/* ----------  Imports  ---------- */

// Prop Types
import PropTypes from 'prop-types';

// Google Map
import GoogleMap from './../Google/GoogleMap';

// Map Helper
import MapHelper from './../../Helpers/MapHelper';

/* ----------  Scripts  ---------- */

class PlaybackMap extends GoogleMap {
	componentDidMount() {
		this.load(() => {
			this.handleActions();
		});
	}

	handleActions = () => {
		this.map.addListener('click', e => {
			const { streetview } = this.props;
			
			const streetviewApi = streetview.current;
			const { lat, lng } = e.latLng;
			const center = {
				lat: lat(),
				lng: lng()
			}

			MapHelper.checkPanorama(center, () => {
				this.setCenter(center);
				if(streetviewApi) streetviewApi.setPosition(center);
			});
		});
	}
}

/* ----------  PropTypes  ---------- */

PlaybackMap.propTypes = {
	updateCenter: PropTypes.func.isRequired,
	streetview: PropTypes.shape().isRequired,
}

/* ----------  Exports  ---------- */

export default PlaybackMap;
