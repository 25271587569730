/* ----------  Scripts  ---------- */

const RemoveLegs = () => dispatch => (
	dispatch({
		type: 'MARKETPLACE_REMOVE_LEGS',
	})
);

/* ----------  Exports  ---------- */

export default RemoveLegs;