/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Redux
import { connect } from 'react-redux';

// Redux
import { bindActionCreators } from 'redux';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { debounce } from 'lodash';

// Axios
import axios from 'axios';

// Tree
import DataTree from './../../../Components/MyTours/Tree/DataTree';

// Preloader
import Preloader from './../../../Components/Common/Preloader';

// Tree Actions
import GetNodes from './../../../Redux/Actions/MyTours/Trees/LegsTree/GetNodes';
import RemoveNodes from './../../../Redux/Actions/MyTours/Trees/LegsTree/RemoveNodes';
import GetLegLicense from './../../../Redux/Actions/MyTours/Trees/LegsTree/GetLegLicense';
import SetLegLicense from './../../../Redux/Actions/MyTours/Trees/LegsTree/SetLegLicense';
import RemoveLegLicense from './../../../Redux/Actions/MyTours/Trees/LegsTree/RemoveLegLicense';

// Action Panel Actions
import GetActionPanelNodes from './../../../Redux/Actions/MyTours/Trees/LegsTree/GetActionPanelNodes';
import RemoveActionPanelNodes from './../../../Redux/Actions/MyTours/Trees/LegsTree/RemoveActionPanelNodes';

// Node Details
import GetNodeDetails from './../../../Redux/Actions/MyTours/Trees/GetNodeDetails';
import RemoveNodeDetails from './../../../Redux/Actions/MyTours/Trees/RemoveNodeDetails';

// Last Leg Info
import GetLastLegInfo from './../../../Redux/Actions/MyTours/Trees/LegsTree/GetLastLegInfo';

// Leg Price Calculation
import GetLegPriceCalculation from './../../../Redux/Actions/MyTours/Trees/LegsTree/GetLegPriceCalculation';

// Util Actions
import CopyNodes from './../../../Redux/Actions/MyTours/Trees/Utils/CopyNodes';
import MoveNodes from './../../../Redux/Actions/MyTours/Trees/Utils/MoveNodes';
import DeleteNode from './../../../Redux/Actions/MyTours/Trees/Utils/DeleteNode';
import RenameNode from './../../../Redux/Actions/MyTours/Trees/Utils/RenameNode';

// Create Actions
import CreateLeg from './../../../Redux/Actions/MyTours/Trees/Utils/CreateLeg';
import CreateFolder from './../../../Redux/Actions/MyTours/Trees/Utils/CreateFolder';

// Components
import NodesTree from './../../../Components/MyTours/Tree/NodesTree';
import Global from '../../../Constants/Global';

// jQuery
const $ = window.$;

/* ----------  Scripts  ---------- */

class LegsTree extends DataTree {
	constructor(props) {
		super(props);

		this.treeRef = React.createRef();

		this.requestGetLegLicense = debounce(this.doGetLegLicense, 100);
		this.requestSetLegLicense = debounce(this.doSetLegLicense, 100);
		this.requestRemoveLegLicense = debounce(this.doRemoveLegLicense, 100);
		
		this.requestLastLegInfo = debounce(this.doRequestLastLegInfo, 100);
		
		this.requestLegPriceCalculation = debounce(this.doRequestLegPriceCalculation, 2000);
		
		this.requestCreateLeg = debounce(this.doCreateLeg, 100);
	}

	getLegLicense = (nodeId, success, fail) => {
		const params = {
			nodeId
		}

		this.requestGetLegLicense(params, success, fail);
	}

	getLastLegInfo = (success, fail)  => {
		this.requestLastLegInfo(success, fail);
	}

	getLegPriceCalculation = (params, success, fail)  => {
		this.requestLegPriceCalculation(params, success, fail);
	}

	getLegStatus = (legId, success, fail) => {
		axios.get(`${ Global.API_BASE_URL }/legs/status?legId=${ legId }`).then(res => {
			const { status, result } = res.data;
			if(success) success(status, result);
		}).catch(err => {
			if(fail) fail();
		});
	}
	
	setLegLicense = (data, success, fail) => {
		this.requestSetLegLicense(data, success, fail);
	}

	doCreateLeg = (data, success, fail) => {
		this.props.createLeg(data, success, fail);
	}

	doGetLegLicense = (data, success, fail) => {
		this.props.getLegLicense(data, () => {
			const { license } = this.props;
			if(success) success(license);
		}, fail);
	}

	doSetLegLicense = (data, success, fail) => {
		const tree = this.treeRef.current;

		this.props.setLegLicense(data, () => {
			tree.updateNodeLicense(data.nodeId, true);
			if(success) success();
		}, fail);
	}

	doRemoveLegLicense = (data, callback) => {
		this.props.removeLegLicense(data, callback);
	}

	doRequestLastLegInfo = (success, fail) => {
		this.props.getLastLegInfo((status, result) => {
			if(success) success(result);
		}, fail);
	}

	doRequestLegPriceCalculation = (params, success, fail) => {
		this.props.getLegPriceCalculation(params, (status, result) => {
			if(success) success(result);
		}, (status, reasons) => {
			if(fail) fail(reasons);
		});
	}

	createLeg = (opts, success, fail) => {
		const tree = this.treeRef.current;
		const { baseNodeType } = this.props;

		const data = {
			baseNodeType,
			...opts
		}

		this.requestCreateLeg(data, (status, response) => {
			const nodeData = {
				parentNodeId: response.parentNodeId || data.parentNodeId,
				nodeType: 60,
				tLegId: response.parentNodeId || data.tLegId,
				...response,
			}

			tree.createNode(nodeData);
			
			this.handleNewLegStatus(data, response);
			if(success) success();
		}, (status, reasons) => {
			if(fail) fail(reasons);
		});
	}

	handleNewLegAddress = data => this.props.handleNewLegAddress(data)

	handleNewLegStatus = (data, response) => {
		const statusInterval = setInterval(() => {
			this.getLegStatus(response.legId, (status, result) => {
				if(result.status.toLowerCase() === 'completed') {
					clearInterval(statusInterval);

					axios.get(`${ Global.API_BASE_URL }/legs/single`, {
						params: {
							legId: response.legId
						}
					}).then(res => {
						const tree = this.treeRef.current;

						const $tree = $('#legsTree');
						const $node = $(`[objectid=${ response.legId }]`, $tree);

						const nodeId = $node.attr('id');
						tree.removeNode(nodeId);

						const { result } = res.data;

						const nodeData = {
							parentNodeId: result.parentNodeId,
							legId: result.legId,
							nodeId: result.nodeId,

							nodeType: 60,
							published: result.published,
							root: result.root,
							title: result.title,
							licensed: result.licensed,
							theme: result.theme,
						}

						tree.createNode(nodeData);
					}).catch(err => console.log(err));
				}
			})
		}, 10000);
	}

	removeLegLicense = (id, callback) => {
		const tree = this.treeRef.current;

		const data = {
			nodeId: id
		}

		tree.updateNodeLicense(id, false);
		this.requestRemoveLegLicense(data, () => {
			if(callback) callback();
		});
	}

	renderTree = () => {
		const { loading } = this.state;
		const { nodes, nodesData } = this.props;

		if(loading) return <Preloader loading={ this.state.loading } center/>;

		// if(!loading && !nodes.length) return <span className="no-records d-block uk-text-small text-center font-italic text-muted p-15 m-0">No nodes found.</span>;

		return (
			<NodesTree
				type="legs"
				id="legsTree"
				rootDraggable
				nodes={ nodes }
				prefix='legsTree_'
				allowed={ ['legs'] }
				ref={ this.treeRef }
				nodesData={ nodesData }
				playTour={ this.playTour }
				getNodes={ this.requestNodes }
				handleTree={ this.handleTree }
				renameNode={ this.renameNode }
				removeNodes={ this.removeNodes }
				className="legs-tree siblingTree"
				showPreloader={ this.showPreloader }
				hidePreloader={ this.hidePreloader }
				handleContext={ this.handleContext }
				handleNewFolder={ this.handleNewFolder }
				handleNewLegAddress={ this.handleNewLegAddress }/>
		);
	}

	render() {
		return (
			<div className="tour-list md-tree-container">
				{ this.renderTree() }
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

LegsTree.defaultProps = {
	nodes: [],
	nodesData: {},
}

LegsTree.propTypes = {
	getLegLicense: PropTypes.func.isRequired,
	setLegLicense: PropTypes.func.isRequired,
	removeLegLicense: PropTypes.func.isRequired,

	createLeg: PropTypes.func.isRequired,

	handleNewLegAddress: PropTypes.func.isRequired,	
	
	nodes: PropTypes.arrayOf(PropTypes.object),
	nodesData: PropTypes.objectOf(PropTypes.object),

	license: PropTypes.shape().isRequired,
}

/* ----------  Redux Scripts  ---------- */

const mapStateToProps = state => ({
	nodes: state.mytours.trees.legs.nodes,
	nodesData: state.mytours.trees.legs.nodesData,

	license: state.mytours.trees.legs.license,

	nodeDetails: state.mytours.trees.nodeDetails,
});

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		getNodes: GetNodes,
		removeNodes: RemoveNodes,

		getActionPanelNodes: GetActionPanelNodes,
		removeActionPanelNodes: RemoveActionPanelNodes,

		getNodeDetails: GetNodeDetails,
		removeNodeDetails: RemoveNodeDetails,
		
		getLegLicense: GetLegLicense,
		removeLegLicense: RemoveLegLicense,

		getLastLegInfo: GetLastLegInfo,
		
		getLegPriceCalculation: GetLegPriceCalculation,

		setLegLicense: SetLegLicense,
		
		createLeg: CreateLeg,
		createFolder: CreateFolder,
		
		moveNodes: MoveNodes,
		copyNodes: CopyNodes,
		deleteNode: DeleteNode,
		renameNode: RenameNode,
	}, dispatch)
);

/* ----------  Exports  ---------- */

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(LegsTree);
