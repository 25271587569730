/* ----------  Scripts  ---------- */

const RemoveRelatedTours = () => dispatch => (
	dispatch({
		type: 'HOME_REMOVE_RELATED_TOURS',
	})
);

/* ----------  Exports  ---------- */

export default RemoveRelatedTours;