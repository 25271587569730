/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// moment
import moment from 'moment';

// Profile Picture
import ProfilePicture from './../Utils/ProfilePicture';

/* ----------  Scripts  ---------- */

class Message extends React.Component {
	componentDidMount() {}

	render() {
		const { data, type } = this.props;
		const timezone = moment.parseZone(moment().format()).format('Z');
		const mTime = moment(data.time).utcOffset(timezone);

		return (
			<div className={`row flex-nowrap message-row ${ type } p-4`} data-message-id={ data.messageId }>
				<ProfilePicture title={ data.contact.name } className="avatar mr-4" src={ data.contact.photo }/>
				<div className="bubble">
					<div className="message">
						{ data.message }
					</div>
					<div className="time text-muted text-right mt-2">
						{ mTime.format('DD MMM YY kk:mm') }
					</div>
				</div>
			</div>
		);
	}
}


Message.propTypes = {
	type: PropTypes.string.isRequired,

	data: PropTypes.shape({
		messageId: PropTypes.string.isRequired
	}).isRequired,
}

/* ----------  Exports  ---------- */

export default Message;