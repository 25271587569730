/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// QR Code
import QRCode from 'qrcode.react';

// Helpers
import ConfigHelper from '../Helpers/ConfigHelper';

// Home Components
import HomeHeader from './../Components/Layouts/Home/HomeHeader';

/* ----------  Scripts  ---------- */

class HomeLayout extends React.Component {
	constructor(props) {
		super(props);

		this.configs = ConfigHelper.getAppConfigs();
	}

	componentWillMount() {
		document.querySelector('#appStylesheet').href = `${ process.env.PUBLIC_URL }/assets/css/app.css`;
		document.body.classList.add('homepage', 'p-0', 'body-app');
	}

	componentWillUnmount() {
		document.body.classList.remove('view-app', 'homepage', 'p-0', 'body-app');
	}

	render() {
		return (
			<div className="site-wrapper">
				<HomeHeader search={ this.props.search } user={ this.props.user }/>
				<div className="header-actions-container">
					<Link to="#hdr" className="btn-nav-toggler" id="btnNavToggler">
						<i className="btn-icon icon-open material-icons">&#xE5D2;</i>
						<i className="btn-icon icon-close material-icons">&#xE5CD;</i>
					</Link>
					<Link to="#hdr" className="btn-results-toggler" id="btnResultsToggler">
						<i className="btn-icon material-icons">&#xE5D2;</i>
					</Link>
				</div>
				{ this.props.children }
				<div className="footer-container footer-homepage" id="cntMainFooter" data-fullheight-offset>
					<div className="container-fluid">
						<div className="footer-inner">
							<div className="copyright-container">
								<p>&copy; Copyright - { this.configs.appName } - { new Date().getFullYear() }</p>
							</div>
							<div className="contact-container">
								<ul className="list-inline">
									<li><a href={ `mailto:${ this.configs.appContactEmail }` } target="blank">{ this.configs.appContactEmail }</a></li>
									<li><a href="#modalDownloadApp" data-uk-modal>Download Android App</a></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="uk-modal text-center" id="modalDownloadApp">
					<div className="uk-modal-dialog">
						<a className="uk-modal-close uk-close"></a>
						<div class="uk-modal-header">
							<h2 className="text-dark"><strong>Download Mobile App</strong></h2>
						</div>
						<div className="uk-modal-body">
							<p className="m-b-20">Open your phone's camera and scan the below QR code:</p>
							<QRCode value={ `${ this.configs.appBaseURL }/assets/apps/releases/street_tour.apk` } size="300"/>
							<div className="btn-download m-t-20">
								<a href="/assets/apps/releases/street_tour.apk" download="street_tour.apk" className="md-btn md-btn-large md-btn-flat md-btn-primary p-h-40 text-white">Download Directly</a>
							</div>
						</div>
						<div class="uk-modal-footer">
							<button className="md-btn md-btn-flat md-btn-flat-default uk-modal-close">Close</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

HomeLayout.defaultProps = {}

HomeLayout.propTypes = {
	user: PropTypes.shape().isRequired,
	children: PropTypes.node.isRequired,
	search: PropTypes.element.isRequired,
}

/* ----------  Scripts  ---------- */

export default HomeLayout;
