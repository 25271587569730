/* ----------  Imports  ---------- */

// React
import React from "react";

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from "prop-types";

// UUID
import uuid from 'uuid/v4';

/* ----------  Scripts  ---------- */

class TreeTitle extends React.Component {
	componentDidMount() {}

	onCtxClick = e => {
		e.preventDefault();

		const { title, tree, type, baseNodeType } = this.props;

		const props = {
			tree,
			title,
			baseNodeType,

			type: 20,
			root: true,
			nodeId: '#',
			refId: uuid(),
			licensed: false,
			treeTitleType: type,
		}

		this.props.handleContext('treeTitle', props, e.currentTarget);
	}

	render() {
		const { icon, title } = this.props;

		return (
			<div className="tour-title" data-ctxtoggle-parent>
				<div className="title-inner">
					<div className="title-contents">
						<div className="title-icon">
							<i className="material-icons">{ icon }</i>
						</div>
						<div className="title-text">
							<h3>{ title }</h3>
						</div>
					</div>
					<div className="title-actions">
						<ul className="list-inline">
							<li><Link to="#atn" onClick={ this.onCtxClick }><i className="material-icons">more_vert</i></Link></li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

TreeTitle.defaultProps = {
	icon: 'edit'
}

TreeTitle.propTypes = {
	icon: PropTypes.string,
	type: PropTypes.string.isRequired,
	tree: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	
	baseNodeType: PropTypes.number.isRequired,
	
	handleContext: PropTypes.func.isRequired,
}

/* ----------  Exports  ---------- */

export default TreeTitle;
