/* ----------  Scripts  ---------- */

const SetToursVisible = (tourIds, success) => dispatch => {
	dispatch({
		type: 'HOME_SET_TOURS_VISIBLE',
		payload: tourIds
	});

	if(success) success();
};

/* ----------  Exports  ---------- */

export default SetToursVisible;