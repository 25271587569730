/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
// import { isEmpty } from 'lodash';

// Preloader
import Preloader from './../../Common/Preloader';

// Google
import LegMap from './LegMap';
import LegStreetview from './LegStreetview';

// Language Dropdown
import LanguageDropdown from '../../../Containers/Common/LanguageDropdown';

// UIkit
import Modal from './../../UIkit/Modal';
import Input from './../../UIkit/Input';
import Alerts from './../../UIkit/Alerts';

// Utils
import Toggle from '../../Utils/Toggle';

// Select Categories
import SelectCategories from './../Categories/SelectCategories';

// Helpers
import Notify from '../../../Helpers/Notify';
import InputUI from '../../../Helpers/InputUI';
import MapHelper from '../../../Helpers/MapHelper';
import InputHelper from '../../../Helpers/InputHelper';
import ToggleHelper from '../../../Helpers/ToggleHelper';

/* ----------  Scripts  ---------- */

class NewLegModal extends React.Component {
	constructor(props) {
		super(props);

		this.modal = React.createRef();
		this.mapRef = React.createRef();
		this.modalForm = React.createRef();
		this.streetviewRef = React.createRef();
		this.legCategoriesRef = React.createRef();

		this.txtAutocompleteLocation = React.createRef();
		this.txtManualLocation = React.createRef();

		this.initialFormState = {
			title: '',
			categoryIds: [],
			primaryCategoryId: '',
		}

		this.initialLocationState = {
			pov: {},
			address: '',
			position: [],
			locationAutocomplete: '',
			locationManual: 'Select Location on Map',
		}

		this.initialState = {
			center: {
				lat: 40.785196,
				lng: -73.969454
			},

			errors: [],
			active: false,
			language: 'en',
			loading: false,
			form: this.initialFormState,
			locationType: 'autocomplete',
			location: this.initialLocationState,
		}

		this.state = this.initialState;
	}

	componentDidMount() {}

	onShow = () => {
		this.props.onShow();
		this.setState({ active: true, loading: true }, this.getLastLegInfo);
	}
	
	onHide = () => {
		this.props.onHide();
		this.setState(this.initialState, () => {
			const modalForm = this.modalForm.current;
			if(modalForm) ToggleHelper.update(modalForm);
		});
	}

	onCancel = e => {
		e.preventDefault();

		this.hide();
		this.props.cancelNewLeg();
		this.setState(this.initialState);
	}

	onRecord = e => {
		e.preventDefault();

		const isValid = this.validateForm();

		if(isValid) {
			const { form, language, location } = this.state;

			const data = {
				language,
				
				title: form.title,
				pov: location.pov,
				location: location.address,
				position: location.position,
				categoryIds: form.categoryIds,
				primaryCategoryId: form.primaryCategoryId,
			}

			this.props.updateLegDetails(data, () => {
				this.hide();
				this.props.recordTour();
			});
		}
	}

	getLastLegInfo = () => {
		this.setState({ loading: true });

		this.props.getLastLegInfo(result => {
			const { center } = this.state;

			let type = 'autocomplete';
			const coords = center;

			if(result) {
				const { position } = result;

				if(position && position.length) {
					coords.lat = position[0];
					coords.lng = position[1];

					type = 'manual';
				}
			}

			this.setState({
				active: true,
				loading: false,
				center: coords,
				locationType: type
			}, () => {
				this.handleLocationType(this.state.locationType);
			});
		}, () => {
			this.setState({
				active: true,
				loading: false,
				locationType: 'autocomplete'
			}, () => {
				this.handleLocationType('autocomplete');
			});
		});
	}

	setPrimaryCategory = categoryId => {
		this.updateForm({ primaryCategoryId: categoryId });
	}

	bindGoogleComponents = () => {
		this.mapApi = this.mapRef.current;
		this.streetviewApi = this.streetviewRef.current;

		if(this.mapApi) this.streetviewApi.bindMap(this.mapApi.map);
	}

	show = () => {
		const modal = this.modal.current;

		modal.show();
	}

	hide = () => {
		const modal = this.modal.current;

		modal.hide();
	}

	initAutocomplete = () => {
		const modalForm = this.modalForm.current;
		const txt = this.txtAutocompleteLocation.current;
		const input = txt.input.current;
		
		setTimeout(() => {
			input.focus();
			InputUI.update(modalForm);
		}, 300);

		const autocomplete = new window.google.maps.places.Autocomplete(input);
		const autocompleteLsr = window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
			const place = autocomplete.getPlace();
			const location = place.geometry.location;

			const center = {
				lat: location.lat(),
				lng: location.lng()
			}

			MapHelper.checkPanorama(center, () => {
				this.updateLocation({
					pov: {},
					position: [center.lat, center.lng],

					address: place.formatted_address,
					locationAutocomplete: place.formatted_address,
				});
			}, () => {
				this.updateLocation({
					pov: {},
					position: [],

					address: '',
					locationAutocomplete: '',
				});
				Notify.error('Oops! Streetview not available at this location.')
			});
		});
		
		return autocompleteLsr;
	}

	initManualMap = () => {
		const { center } = this.state;

		MapHelper.getLocationFromLatLng(center, address => {
			this.updateLocation({ locationManual: address, address });
		});
	}

	handleTxtAutocomplete = e => {
		const { value } = e.currentTarget;
		this.updateLocation({ locationAutocomplete: value });
	}

	handleLocationType = value => {
		this.setState({ locationType: value }, () => {
			this.setState({
				errors: [],
				location: this.initialLocationState,
			}, () => {
				switch(value) {
					case 'autocomplete': this.initAutocomplete(); break;
					case 'manual': this.bindGoogleComponents(); break;
					default: break;
				}

				this.forceUpdate();
			});
		});
	}

	handleLanguage = (name, value) => {
		this.setState({ language: value });
	}

	handleTitle = e => {
		const { value } = e.currentTarget;

		this.updateForm({ title: value });
	}

	handleCategories = (name, value) => {
		this.updateForm({ categoryIds: value }, () => {
			const { form } = this.state;
			const legCategoriesRef = this.legCategoriesRef.current;

			if(form.categoryIds.length === 1) {
				const primaryCategoryId = form.categoryIds[0];

				this.updateForm({ primaryCategoryId });

				legCategoriesRef.setSelected(primaryCategoryId);
			}
		});
	}

	updateMapPosition = (center, pov, callback) => {
		this.setState({ center }, () => {
			this.initManualMap();
			this.updateLocation({ position: [center.lat, center.lng], pov });

			if(callback) callback();
		});
	}

	validateForm = () => {
		let isValid = true;

		const errors = [];
		const { location: { position }, form: { title, primaryCategoryId, categoryIds } } = this.state;

		if(InputHelper.isEmpty(title)) {
			isValid = false;
			errors.push('Please enter a valid title.');
		}

		if(InputHelper.isEmpty(primaryCategoryId)) {
			isValid = false;
			errors.push('Please select a primary category.');
		}

		if(!categoryIds.length) {
			isValid = false;
			errors.push('Please select at least one category.');
		}

		if(!position.length) {
			isValid = false;
			errors.push('Please select a valid location.');
		}

		this.setState({ errors });

		return isValid;
	}

	updateForm = (updates, callback) => {
		const { form } = this.state;

		this.setState({
			form: {
				...form,
				...updates
			}
		}, () => {
			if(callback) callback();
		});
	}

	updateLocation = (updates, callback) => {
		const { location } = this.state;

		this.setState({
			location: {
				...location,
				...updates
			}
		}, () => {
			if(callback) callback();
		});
	}

	renderMap = () => {
		const { active, locationType, center } = this.state;

		if(!active || (locationType !== 'manual')) return <div className="d-none"/>;

		return (
			<div className="google-services">
				<LegMap
					id="legModalMap"
					streetViewControl
					center={ center }
					ref={ this.mapRef }
					className="manual-location-map"
					streetview={ this.streetviewRef }
					updatePosition={ this.updateMapPosition }/>
				<LegStreetview
					center={ center }
					map={ this.mapRef }
					ref={ this.streetviewRef }
					className="manual-location-streetview"
					updatePosition={ this.updateMapPosition }/>
			</div>
		);
	}

	renderAudioError = () => {
		const { audioDevice } = this.props;

		if(audioDevice) return <div className="d-none"/>;

		return (
			<div className="uk-alert uk-alert-danger">
				<p className="uk-text-small">Audio will not be recorded due to inefficient permissions or lack of microphone device.</p>
			</div>
		);
	}

	renderCategories = () => {
		const { active, form } = this.state;
		const { categories } = this.props;

		if(!active) return <div className="d-none"/>;

		return (
			<SelectCategories 
				categories={ categories }
				maxItems={ 3 }
				name="categoryIds"
				id="legCategories"
				setPrimaryCategory={ this.setPrimaryCategory }
				value={ form.categoryIds }
				onChange={ this.handleCategories }
				ref={ this.legCategoriesRef }/>
		);
	}

	renderForm = () => {
		const { loading } = this.state;

		if(loading) return <Preloader loading={ loading } minimal center/>;
		
		const { form, errors, language, locationType, location } = this.state;

		return (
			<form ref={ this.modalForm }>
				<div className="uk-modal-header">
					<div className="header-title">
						<h3 className="uk-modal-title">New Leg</h3>
					</div>
					<div className="header-action">
						<div className="md-select-wrapper md-language-wrapper">
							<LanguageDropdown id="legLanguageDropdown" name="language" value={ language } onChange={ this.handleLanguage }/>
						</div>
					</div>
				</div>
				<div className="uk-modal-body">
					{ this.renderAudioError() }
					<Input
						title="New Leg Name"
						name="title"
						id="txtLegTitle"
						value={ form.title }
						onChange={ this.handleTitle }/>

					<div className="md-section m-t-20">
						<div className="section-title m-t-0 m-b-5">
							<h3 className="m-0 uk-text-large text-dark">Select Categories:</h3>
						</div>
						<div className="section-content">
							{ this.renderCategories() }
						</div>
					</div>

					<div className="md-point-selection-section locationSelection m-t-20">
						<div className="section-title m-b-20">
							<h3 className="m-0 heading_a text-dark">Choose Starting Location</h3>
						</div>
						<div className="section-fields">
							<input type="hidden" className="txtLocation" name="mapLocation" id="txtLocation" autoComplete="off"/>
							<div className="md-form-group hasValidation">
								<div className="uk-input-group">
									<span className="uk-input-group-addon">
										<Toggle
											type="radio"
											name="locationType"
											id="toggleLocationType_Autocomplete"
											value="autocomplete"
											checked={ locationType === 'autocomplete' }
											onChange={ this.handleLocationType }/>
									</span>
									<Input
										title=""
										name="autocomplete"
										placeholder="Enter Location"
										id="txtLegAutocompleteLocation"
										value={ location.locationAutocomplete }
										onChange={ this.handleTxtAutocomplete }
										disabled={ locationType !== 'autocomplete' }
										ref={ this.txtAutocompleteLocation }/>
								</div>
							</div>
							<div className="md-form-group">
								<div className="uk-input-group">
									<span className="uk-input-group-addon">
										<Toggle
											type="radio"
											name="locationType"
											id="toggleLocationType_Manual"
											value="manual"
											checked={ locationType === 'manual' }
											onChange={ this.handleLocationType }/>
									</span>
									<Input
										title={ location.locationManual }
										name="legManualLocation"
										id="txtLegManualLocation"
										labelClass="text-truncate"
										value=""
										onChange={ () => {} }
										disabled
										ref={ this.txtManualLocation }/>
								</div>
								{ this.renderMap() }
							</div>
						</div>
					</div>

					<Alerts show={ errors.length > 0 } data={ errors }/>
				</div>
				<div className="uk-modal-footer">
					<Link to="#closeModal" className="md-btn md-btn-flat md-btn-flat-default uk-modal-close">
						Cancel
					</Link>
					<button className={ `md-btn md-btn-flat md-btn-flat-default` } onClick={ this.onRecord } disabled={ !location.position.length }>
						Start Recording!
					</button>
				</div>
			</form>
		);
	}

	render() {
		return (
			<Modal
				popup="newNewLegModal"
				onShow={ this.onShow }
				onHide={ this.onHide }
				ref={ this.modal }
				bgClose={ false }
				keyboardClose={ false }>
				<div className="uk-modal-dialog">
					{ this.renderForm() }
				</div>
			</Modal>
		);
	}
}

/* ----------  Prop Types  ---------- */

NewLegModal.defaultProps = {
	loading: false
}

NewLegModal.propTypes = {
	// loading: PropTypes.bool,
	audioDevice: PropTypes.bool.isRequired,

	onShow: PropTypes.func.isRequired,
	onHide: PropTypes.func.isRequired,
	recordTour: PropTypes.func.isRequired,
	cancelNewLeg: PropTypes.func.isRequired,
	getLastLegInfo: PropTypes.func.isRequired,
	updateLegDetails: PropTypes.func.isRequired,

	categories: PropTypes.objectOf(PropTypes.object).isRequired,
	// loading: PropTypes.bool,
}

/* ----------  Exports  ---------- */

export default NewLegModal;
