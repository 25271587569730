/* ----------  Imports  ---------- */

// React
import React, { Fragment } from 'react';

// React Redux
import { connect } from 'react-redux';

// Redux
import { bindActionCreators } from 'redux';

// Prop Types
import PropTypes from 'prop-types';

// React Router DOM
import { Link, withRouter } from 'react-router-dom';

// Lodash
import { debounce, isEmpty, map, startCase, sum, get, find, remove, indexOf, size, split } from 'lodash';

// UUID
import uuid from 'uuid/v4';

// jQuery
import jQuery from 'jquery';

// Uikit
import UIkit from 'uikit';

// Constants
import Global from './../Constants/Global';
import Velocity from './../Constants/Velocity';

// Layouts
import AdminLayout from './../Layouts/AdminLayout';

// Graphic Panel
import ToursPlayer from './../Containers/ToursPanel/ToursPlayer';

// Tour Media Player
import TourMediaPlayer from '../Components/TourMediaPlayer/';

// Context Menus
import ContextMenu from './../Components/ContextMenu/';

// Tree Components
import TreeTitle from './../Components/MyTours/Tree/TreeTitle';

// Tree Filters
import LegsFilter from './../Containers/MyTours/TreeFilters/LegsFilter';
import ToursFilter from './../Containers/MyTours/TreeFilters/ToursFilter';
// import GroupsFilter from './../Containers/MyTours/TreeFilters/GroupsFilter';

// Tree Containers
import LegsTree from './../Containers/MyTours/Trees/LegsTree';
import ToursTree from './../Containers/MyTours/Trees/ToursTree';
// import GroupedToursTree from './../Containers/MyTours/Trees/GroupedToursTree';
// import LegsLicensedTree from './../Containers/MyTours/Trees/LegsLicensedTree';
// import UngroupedToursTree from './../Containers/MyTours/Trees/UngroupedToursTree';

// Modals
import ModalTour from './../Components/MyTours/Modals/ModalTour';
import ModalComments from './../Components/MyTours/Modals/ModalComments';
import ModalLegMusic from './../Components/MyTours/Modals/ModalLegMusic';
import ModalTourStats from './../Components/MyTours/Modals/ModalTourStats';
import ModalNodeDetails from './../Components/MyTours/Modals/ModalNodeDetails';
import ModalBgMusicMixer from './../Components/MyTours/Modals/ModalBgMusicMixer';

// Modal Containers
import ModalHyperlink from './../Containers/MyTours/Modals/ModalHyperlink';

// Leg
import NewLegModal from './../Components/MyTours/Leg/NewLegModal';
// import LegModalDetails from './../Components/MyTours/Leg/LegModalDetails';

// Panels
import ActionPanel from './../Components/MyTours/Panels/ActionPanel';

// Sequence Panel
import SequencePanel from './../Containers/MyTours/Panels/SequencePanel';

// Group Tour Panel
import PublishTreePanel from  './../Containers/MyTours/Panels/PublishTreePanel';

// Utils
import Scrollbar from './../Components/Utils/Scrollbar';
import Selectize from './../Components/Utils/Selectize';
import FullHeight from './../Components/Utils/FullHeight';

// UIkit
import Modal from './../Components/UIkit/Modal';
import Input from './../Components/UIkit/Input';
import Alerts from './../Components/UIkit/Alerts';

// Preloader
import Preloader from './../Components/Common/Preloader';

// Helpers
import Ctx from './../Helpers/Ctx';
import Notify from './../Helpers/Notify';
import InputHelper from './../Helpers/InputHelper';
import AudioHelper from './../Helpers/AudioHelper';

// Common Actions
import GetLanguages from './../Redux/Actions/Languages/GetLanguages';
import GetCategories from './../Redux/Actions/Categories/GetCategories';
import AddAttachment from './../Redux/Actions/Attachment/AddAttachment';
import GetCurrencies from './../Redux/Actions/Currencies/GetCurrencies';

// Tour Actions
import GetTourSummary from './../Redux/Actions/TourPlayback/GetTourSummary';

// User Actions
import GetUserSettings from './../Redux/Actions/User/Settings/GetUserSettings';
import SetUserSetting from './../Redux/Actions/User/Settings/SetUserSetting';

// Leg Music Actions
import GetLegMusic from '../Redux/Actions/MyTours/Trees/LegMusic/GetLegMusic';
import SetLegMusic from '../Redux/Actions/MyTours/Trees/LegMusic/SetLegMusic';

// Conversation Actions
import GetUnreadConversationsCount from './../Redux/Actions/Conversation/Read/GetUnreadConversationsCount';
import MarkAllConversationsRead from './../Redux/Actions/Conversation/Read/MarkAllConversationsRead';
import AddMedia from '../Redux/Actions/Media/AddMedia';
import Utils from '../Helpers/Utils';
import Toggle from '../Components/Utils/Toggle';

// jQuery
const $ = window.$;

/* ----------  Scripts  ---------- */

class ViewMyTours extends React.Component  {
	constructor(props) {
		super(props);

		this.adminLayoutRef = React.createRef();

		// Trees
		this.legsTreeRef = React.createRef();
		this.toursTreeRef = React.createRef();
		this.publishTreeRef = React.createRef();

		this.toursPanelRef = React.createRef();
		this.mediaPlayerRef = React.createRef();
		
		this.tourModalRef = React.createRef();
		this.statsModalRef = React.createRef();
		this.newLegModalRef = React.createRef();
		this.legMusicModalRef = React.createRef();
		this.newGroupModalRef = React.createRef();
		this.newFolderModalRef = React.createRef();
		this.hyperlinkModalRef = React.createRef();
		this.licensingModalRef = React.createRef();
		this.tourStatsModalRef = React.createRef();
		this.renameNodeFormRef = React.createRef();
		this.renameNodeModalRef = React.createRef();
		this.nodeDetailsModalRef = React.createRef();
		this.accountNotifModalRef = React.createRef();
		this.nodeCommentsModalRef = React.createRef();
		this.bgMusicMixerModalRef = React.createRef();
		this.newLegModalDetailsRef = React.createRef();
		
		this.legsFilterRef = React.createRef();
		this.groupsFilterRef = React.createRef();
		this.toursFilterRef = React.createRef();
		
		this.sequencePanelRef = React.createRef();
		
		this.globalPhotoUploaderRef = React.createRef();
		this.globalAudioUploaderRef = React.createRef();
		
		this.toursTreesOuterRef = React.createRef();
		
		this.overlayRef = React.createRef();
		
		this.licensingDescriptionMinWords = 0;
		this.licensingDescriptionMaxWords = 200;

		this.musicTimer = null;
		this.musicPlayer = null;

		this.initialNewFolderFormState = {
			title: '',
			tree: null,
			nodeType: 20,
			tFolderId: '',
			licensed: false,
			parentNodeId: '',
			parentNodeType: '',
		}

		this.initialMoveNodeState = {
			data: {},
			srcTree: '',
			parentNode: {},
		}

		this.initialNewGroupFormState = {
			title: '',
		}

		this.initialNewLegFormState = {
			parentNodeId: '',
			parentNodeType: '',
			title: '',
			licensed: '',
			location: '',
			hierarchy: [],
			position: [],
			pov: {},
			language: '',
			audio: {},
			recording: {},
			categoryIds: [],
			primaryCategoryId: ''
		}

		this.initialRenameNodeFormState = {
			id: '',
			type: '',
			title: '',
			tree: '',
			nodeId: '',
			existingTitle: '',
		}

		this.initialLicensingFormState = {
			id: '',
			objectId: '',
			currency: 'USD',
			price: '',
			description: '',
			exclusive: false,
			calculation: {
				earning: 0,
				platformCommission: 0,
				paymentGatewayFees: 0
			}
		}

		this.initialNodeDetailsState = {
			id: '',
			type: 0,
			tree: '',
			title: 'N/A',
			loading: true,
			baseNodeType: '',
			data: [],
		}

		this.initialNodeCommentsState = {
			id: '',
		}

		this.initialTourStatsState = {
			id: '',
		}

		this.initialActionPanelState = {
			tree: '',
			refId: '',
			nodeId: '',
			objectId: '',
			nodeType: '',
			type: 'copy',
			active: false,
			allowAll: false,
		}

		this.initialPublishTreePanelState = {
			tree: null,
			refId: null,
			title: null,
			nodeId: null,
			active: false,
			nodeType: null,
			tourId: null,
		}

		this.initialBgMusicState = {
			src: null,
			offset: 0,
			name: null,
			size: null,
			loop: true,
			volume: 0.1,
		}
		
		this.initialAudioState = {
			volume: 1,
		}

		this.initialLegMusicState = {
			legId: null,
			nodeId: null,
			tourId: null,
			loading: true,
			audio: this.initialAudioState,
			bgMusic: this.initialBgMusicState,
		}
		
		this.bgMusicMixerInitialState = {
			title: '',
			tourId: null,
		}

		this.initialMusicPlayerState = {
			musicId: null,
			active: false,
			playing: false,
			paused: false,
			loaded: false,
		}

		this.state = {
			player: {
				type: 'default',
				busy: false,
				active: false,
				render: false,
			},

			urlTourId: '',

			treePanelDisabled: false,

			sequencePanel: {
				active: false,
				tour: {},
				busy: false
			},

			audioDevice: false,

			musicPlayer: this.initialMusicPlayerState,

			addingNewFolder: false,
			newFolderFormErrors: [],
			newFolderForm: this.initialNewFolderFormState,

			addingNewGroup: false,
			newGroupFormErrors: [],
			newGroupForm: this.initialNewGroupFormState,

			addingNewLeg: false,
			newLegFormStep: 0,
			newLegFormErrors: [],
			newLegForm: this.initialNewLegFormState,

			renamingNode: false,
			renameNodeFormErrors: [],
			renameNodeForm: this.initialRenameNodeFormState,

			loadingLicense: true,
			updatingLicense: false,
			updatingLicensePrice: false,
			licensingFormErrors: [],
			licensingForm: this.initialLicensingFormState,

			nodeDetails: this.initialNodeDetailsState,
			nodeComments: this.initialNodeCommentsState,

			actionPanel: this.initialActionPanelState,
			
			publishTreePanel: this.initialPublishTreePanelState,

			fetchingTourProfile: false,

			tourStats: this.initialTourStatsState,

			legMusic: this.initialLegMusicState,
			
			hyperlink: {},
			fetchingTourHyperlink: false,

			accountRedirect: false,

			notif: {
				legs: true,
				tours: true,
			},

			loadingBgMusicMixer: true,
			updatingBgMusicMixer: false,
			bgMusicMixer: this.bgMusicMixerInitialState,

			moveNode: this.initialMoveNodeState,

			ctx: {
				collection: {
					refId: '',
					nodId: '',
					objectId: '',
					root: false,
					licensed: false,
					tree: '',
					type: '',
					hierarchy: [],
					parentNodeId: '',
					parentNodeType: '',
					title: '',
					src: '',
				},

				ungroupedTours: {
					refId: '',
					nodId: '',
					objectId: '',
					root: false,
					licensed: false,
					tree: '',
					type: '',
					hierarchy: [],
					parentNodeId: '',
					parentNodeType: '',
					title: '',
					src: '',
				},

				groupedTours: {
					refId: '',
					nodId: '',
					objectId: '',
					root: false,
					licensed: false,
					tree: '',
					type: '',
					hierarchy: [],
					parentNodeId: '',
					parentNodeType: '',
					title: '',
					src: '',
				},

				folder: {
					refId: '',
					nodId: '',
					objectId: '',
					root: false,
					licensed: false,
					tree: '',
					type: '',
					hierarchy: [],
					parentNodeId: '',
					parentNodeType: '',
					title: '',
					src: '',
					inLimit: true,
				},

				tour: {
					src: '',
					type: '',
					tree: '',
					refId: '',
					title: '',
					nodId: '',
					groups: [],
					root: false,
					objectId: '',
					hierarchy: [],
					licensed: false,
					parentNodeId: '',
					parentNodeType: '',
				},

				leg: {
					refId: '',
					nodId: '',
					objectId: '',
					root: false,
					licensed: false,
					tree: '',
					type: '',
					playTime: 0,
					hierarchy: [],
					parentNodeId: '',
					parentNodeType: '',
					isPurchased: false,
					title: '',
					src: '',
					sqPanelActive: false,
				},

				sqTour: {
					refId: '',
					nodId: '',
					objectId: '',
					type: '',
					tree: '',
					legIds: [],
					fromIndex: -1,
					toIndex: -1,
					mixerActive: false,
					headerMediaId: null,
					footerMediaId: null,
				},
				
				sqLeg: {
					refId: '',
					tourId: '',
					nodId: '',
					objectId: '',
					legIds: '',
					type: '',
					tree: '',
					nextLegs: [],
					mixerActive: false,
				},

				sqNextLegs: {
					refId: ''
				},

				publishTree: {
					refId: '',
					nodeId: '',
				},

				treeTitle: {
					tree: '',
					type: 20,
					root: true,
					nodeId: '#',
					title: 'N/A',
					refId: uuid(),
					licensed: false,
					baseNodeType: 0,
					treeTitleType: '',
				},

				tourGroups: {
					refId: ''
				},

				sqMixerPanel: {
					refId: null,
					objectId: null,
					type: 50,
					mixerActive: false,
				},

				sqMixerImageTitle: {
					type: 50,
					title: '',
					refId: '',
					nodeId: '',
					legIds: [],
					imageIds: [],
					objectId: '',
					tree: 'tours',
					mixerActive: false,
				},

				sqImageItem: {
					refId: '',
					media: {},
					imageId: '',
					playTime: 0,
					startTime: 0,
					endTime: 0,
					locked: false,
					originalPlaytime: 0,
				},

				bgMusicMixer: {
					type: 50,
					title: '',
					refId: '',
					nodeId: '',
					legIds: [],
					musicIds: [],
					objectId: '',
					tree: 'tours',
					mixerActive: false,
				},

				sqMusicItem: {
					refId: '',
					media: {},
					musicId: '',
					playTime: 0,
					startTime: 0,
					endTime: 0,
					locked: false,
					originalPlaytime: 0,
				},

				sqGroupItem: {
					refId: null,
					groupId: null,
					tourId: null,
					legIds: [],
					fromIndex: -1,
					toIndex: -1,
					mixerActive: false,
				},
				
				sqMixerHeader: {
					refId: null,
					headerId: null,
					mediaId: null,
					media: null,
				},
				
				sqMixerFooter: {
					refId: null,
					footerId: null,
					mediaId: null,
					media: null,
				},
			}
		}

		this.getLanguages = debounce(this.doRequestLanguages, 100);
		this.getCategories = debounce(this.doRequestCategories, 100);
		this.getCurrencies = debounce(this.doRequestCurrencies, 100);
		
		this.getUserSettings = debounce(this.doRequestGetUserSettings, 100);
		this.setUserSetting = debounce(this.doRequestSetUserSetting, 100);
		
		this.requestAddAttachment = debounce(this.doRequestAddAttachment, 100);
		
		this.requestGetLegMusic = debounce(this.doRequestGetLegMusic, 100);
		this.requestSetLegMusic = debounce(this.doRequestSetLegMusic, 100);
		
		this.addMedia = debounce(this.doAddMedia, 0);

		this.getUnreadCount = debounce(this.doRequestUnreadCount, 100);
		this.markAllConversationsRead = debounce(this.doMarkAllConversationsRead, 100);
	}

	componentWillMount() {
		this.getLanguages();
		this.getCurrencies();
		this.getCategories();
		
		this.getUserSettings();

		document.title = `${ Global.APP_NAME } :: My Tours`;
	}

	componentDidMount() {
		this.getTourIdFromUrl();
		this.initTreeResizable();
		
		this.handleSequencePanelViaUrl();
		this.handleDnd();

		Ctx.init();
	}

	onCancel = e => {
		e.preventDefault();

		this.disableToursPanel();
		this.disableMediaPanel();
	}

	onNewFolderModalHide = () => {
		this.setState({
			addingNewFolder: false,
			newFolderFormErrors: [],
			newFolderForm: this.initialNewFolderFormState,
		});
	}

	onNewGroupModalHide = () => {
		this.setState({
			addingNewGroup: false,
			newGroupFormErrors: [],
			newGroupForm: this.initialNewGroupFormState,
		});
	}

	onNewLegModalDetailsShow = () => {}

	onNewLegModalDetailsHide = () => {}
	
	onLegMusicModalShow = () => {
		this.getLegMusic();
	}

	onLegMusicModalHide = () => {
		this.updateLegMusicState(this.initialLegMusicState);
	}

	onBgMusicMixerModalShow = () => {
		setTimeout(() => {
			this.setState({
				loadingBgMusicMixer: false
			});
		}, 3000);
	}

	onBgMusicMixerModalHide = () => {
		this.setState({
			loadingBgMusicMixer: true,
			updatingBgMusicMixer: false,
			bgMusicMixer: this.bgMusicMixerInitialState
		});
	}
	
	onTourModalHide = () => {
		const treeRef = this.getTreeRef('tours');
		treeRef.removeTourProfileDetails();
	}

	onNewLegModalShow = () => {
		this.disableToursPanel();
	}

	onNewLegModalHide = () => {}

	onAccountModalShow = () => {
		this.setState({ accountRedirect: false });
	}
	
	onAccountModalHide = () => {
		const { accountRedirect } = this.state;

		if(accountRedirect) {
			const { history } = this.props;
			history.push('/profile/edit');
		}
	}

	onAccountSetupClick = (e, redirect = false) => {
		e.preventDefault();

		const modal = this.accountNotifModalRef.current;
		if(modal) {
			modal.hide();
			this.setState({ accountRedirect: redirect });
		}
	}

	onLicensingModalShow = () => {
		const { licensingForm } = this.state;
		const { id } = licensingForm;

		this.fetchLicense(id);
	}

	onLicensingModalHide = () => {
		this.setState({
			loadingLicense: true,
			updatingLicense: false,
			licensingFormErrors: [],
			updatingLicensePrice: false,
			licensingForm: this.initialLicensingFormState,
		});
	}

	onNodeDetailsModalShow = () => {
		const { nodeDetails } = this.state;
		const { id, type, tree, baseNodeType, title } = nodeDetails;

		const params = {
			id,
			type,
			tree
		}

		if(baseNodeType) params.baseNodeType = baseNodeType;
		if(title) params.title = title;

		this.getNodeDetails(params);
	}

	onNodeDetailsModalHide = () => {
		this.setState({
			nodeDetails: this.initialNodeDetailsState,
		});
	}

	onRenameNodeModalShow = () => {
		const form = this.renameNodeFormRef.current;
		const input = form.querySelector('#txtRenameTitle');

		input.focus();
		input.select();
	}

	onRenameNodeModalHide = () => {
		this.setState({
			renamingNode: false,
			renameNodeFormErrors: [],
			renameNodeForm: this.initialRenameNodeFormState,
		});
	}

	// Getters

	getCurrencyOptions = () => {
		const data = [];
		const { currencies } = this.props;

		if(!isEmpty(currencies)) {
			map(currencies, currency => {
				const option = {
					label: currency.currency,
					help: currency.country,
					value: currency.currency,
				}

				data.push(option);
			});
		}

		return data;
	}

	getNodes = (filters, trees = []) => {
		if(trees.length) {
			map(trees, tree => {
				const treeRef = this.getTreeRef(tree);

				treeRef.removeNodes();
				treeRef.getNodes(filters);
			});
		}
	}

	getTreeRef = tree => {
		switch(tree) {
			case 'legs':  return this.legsTreeRef.current.wrappedInstance;
			case 'tours':  return this.toursTreeRef.current.wrappedInstance;
			case 'publishTree':  return this.publishTreeRef.current.wrappedInstance;
			default:  return null;
		}
	}

	getTourIdFromUrl = () => {
		const url = new URL(window.location.href);
		const tourId = url.searchParams.get('tourId');

		if(tourId) {
			this.setState({
				urlTourId: tourId
			});
		}
	}

	getNodeDetails = params => {
		const treeRef = this.getTreeRef(params.tree);
		const modal = this.nodeDetailsModalRef.current;
		
		if(treeRef) {
			treeRef.getNodeDetails(params, data => {
				const exchangers = {
					language: 'languageName'
				};

				const durations = ['legDuration', 'tourDuration'];

				const details = map(data, (content, title) => {
					const exchange = get(exchangers, title);
					let value = exchange ? data[exchange] : content;

					if(indexOf(durations, title) !== -1) {
						value = Utils.getReadableDuration(value);
					}

					return {
						tId: title,
						title: startCase(title),
						content: value || 'N/A',
					};
				});

				map(exchangers, title => {
					const item = find(details, ['tId', title]);

					if(item) remove(details, item);
				});

				this.updateNodeDetails({
					loading: false,
					data: details
				});
			}, () => {
				this.updateNodeDetails({
					loading: false,
					data: []
				}, modal.hide);

				Notify.error('Something went wrong. Please try again. :(');
			});
		} else {
			this.updateNodeDetails({
				loading: false,
				data: []
			}, modal.hide);

			Notify.error('Something went wrong. Please try again. :(');
		}
	}

	getActionPanelNodes = (tree, params, success, fail) => {
		const treeRef = this.getTreeRef(tree);

		if(treeRef) {
			treeRef.getActionPanelNodes(params, success, fail);
		}
	}

	getLastLegInfo = (success, fail) => {
		const treeRef = this.getTreeRef('legs');

		treeRef.getLastLegInfo(success, fail);
	}

	getLegPriceCalculation = () => {
		const treeRef = this.getTreeRef('legs');
		const { licensingForm: { id, objectId, price, currency } } = this.state;

		if(!treeRef || !currency || !price) return false;
		
		const params = {
			price,
			currency,

			nodeId: id,
			legId: objectId,
		}

		this.setState({
			licensingFormErrors: [],
			updatingLicensePrice: true,
		});

		treeRef.getLegPriceCalculation(params, result => {
			const { earning, platformCommission, paymentGatewayFees } = result;

			this.updateLicensingForm({
				calculation: {
					earning,
					platformCommission,
					paymentGatewayFees,
				}
			}, () => {
				this.setState({ updatingLicensePrice: false });
			});
		}, reasons => {
			const errors = map(reasons, reason => reason.message);

			this.setState({
				licensingFormErrors: errors,
				updatingLicensePrice: false,
			});
		});

		return true
	}

	getLegMusic = () => {
		this.requestGetLegMusic();
	}

	setLegMusic = (success, fail) => {
		this.requestSetLegMusic(success, fail);
	}

	doRequestLanguages = () => {
		this.props.getLanguages();
	}

	doRequestCurrencies = () => {
		this.props.getCurrencies();
	}

	doRequestCategories = () => {
		this.props.getCategories();
	}

	doRequestGetUserSettings = (success, fail) => {
		this.props.getUserSettings(success, fail);
	}
	
	doRequestSetUserSetting = (data, success, fail) => {
		this.props.setUserSetting(data, success, fail);
	}

	doRequestGetLegMusic = () => {
		const { legMusic: { legId, tourId } } = this.state;
		const modal = this.legMusicModalRef.current;

		const params = {
			legId,
			tourId
		}

		this.updateLegMusicState({ loading: true });

		this.props.getLegMusic(params, (status, response) => {
			const bgMusic = {
				...this.initialBgMusicState,
				...response ? response.bgMusic : null
			}

			const audio = {
				...this.initialAudioState,
				...response ? response.audio : null
			}

			this.updateLegMusicState({ loading: false, bgMusic, audio });
		}, () => {
			this.updateLegMusicState({ loading: false }, modal.hide);
		});
	}

	doRequestSetLegMusic = (success, fail) => {
		const { legMusic: { tourId, legId, bgMusic, audio } } = this.state;
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		const modal = this.legMusicModalRef.current;

		const data = {
			legId,
			audio,
			tourId,
			bgMusic,
		}

		sequencePanel.saveLegsToTour(() => {
			this.props.setLegMusic(data, () => {
				sequencePanel.updateBgMusicState(legId, bgMusic.src);
				if(success) success();
			}, fail);
		}, () => {
			modal.hide();
			Notify.error('Something went wrong, please try again later.');
		});
	}

	doAddMedia = (data, success, fail) => {
		this.props.addMedia(data, success, fail);
	}

	doRequestUnreadCount = () => {
		this.props.getUnreadCount();
	}

	doMarkAllConversationsRead = (nodeType, success, fail) => {
		this.props.markAllConversationsRead({ nodeType }, () => {
			this.getUnreadCount();
			if(success) success();
		}, fail);
	}

	disableToursPanel = () => {
		if(!this.toursPanelRef.current) return;
		
		const toursPanel = this.toursPanelRef.current.wrappedInstance;
		if(toursPanel) toursPanel.disablePanel();

		this.setState({ treePanelDisabled: false });
	}

	handleContext = (type, props, target, event = null) => {
		this.updateCtxContent(type, props, () => Ctx.open(type, target, event));
	}

	updateGroupsFilters = () => {
		const groupsFilter = this.groupsFilterRef.current.wrappedInstance;

		groupsFilter.getGroups();
	}

	// CTX Actions

	ctxCopy = (e, data) => {
		e.preventDefault();
		e.stopPropagation();

		this.handleActionPanel(data, 'copy');

		Ctx.close();
	}

	ctxMove = (e, data) => {
		e.preventDefault();
		e.stopPropagation();

		this.handleActionPanel(data, 'move');

		Ctx.close();
	}

	ctxMoveTo = e => {
		e.preventDefault();
		e.stopPropagation();

		const params = {
			refId: uuid(),
		}

		this.handleContext('tourGroups', params, e.currentTarget);
	}

	ctxDirectMove = (e, src, dest) => {
		e.preventDefault();
		e.stopPropagation();

		const data = {
			nodeId: src.nodeId,
			nodeType: src.type,
			tNodeId: src.refId,
			parentNodeId: dest.parentNodeId,
			parentNodeType: dest.parentNodeType,
		}

		this.treeMoveNode(data, src.tree, dest.node);
		Ctx.close();
	}

	ctxMoveNodeUp = (e, data) => {
		e.preventDefault();
		e.stopPropagation();

		this.treeMoveNodeUp(data, data.tree);

		Ctx.close();
	}
	
	ctxMoveNodeDown = (e, data) => {
		e.preventDefault();
		e.stopPropagation();

		this.treeMoveNodeDown(data, data.tree);

		Ctx.close();
	}

	ctxDelete = (e, data) => {
		e.preventDefault();
		e.stopPropagation();

		const treeRef = this.getTreeRef(data.tree);

		const params = {
			nodeId: data.nodeId,
			nodeType: data.type
		}

		if(treeRef) treeRef.deleteNode(params);

		Ctx.close();
	}

	ctxRenameNode = (e, data) => {
		e.preventDefault();
		e.stopPropagation();

		this.handleRenameNode(data);

		Ctx.close();
	}

	ctxNewFolder = (e, data) => {
		e.preventDefault();
		e.stopPropagation();

		const params = {
			parent: data.refId,
			parentNodeId: data.nodeId,
			parentNodeType: data.type,
			licensed: data.licensed,
			tree: data.tree,
		}

		this.handleNewFolder(params);

		Ctx.close();
	}

	ctxNewTour = (e, data) => {
		e.preventDefault();
		e.stopPropagation();

		const params = {
			parent: data.refId,
			parentNodeId: data.nodeId,
			parentNodeType: data.type,
		}

		this.handleTour(params);

		Ctx.close();
	}

	ctxNewLeg = (e, data) => {
		e.preventDefault();
		e.stopPropagation();

		const params = {
			parent: data.refId,
			parentNodeId: data.nodeId,
			parentNodeType: data.type,
			licensed: data.licensed,
			tree: data.tree,
			hierarchy: data.hierarchy,
		}

		this.handleNewLegAddress(params);

		Ctx.close();
	}

	ctxNewGroup = (e, src, autoMove = false) => {
		e.preventDefault();
		e.stopPropagation();

		const data = {
			tree: src.tree,
			nodeId: src.nodeId,
			nodeType: src.type,
			tNodeId: src.refId,
			parentNodeId: '',
			parentNodeType: '',
		}

		this.handleNewGroup(data, autoMove);
		Ctx.close();
	}

	ctxLicense = (e, data) => {
		e.preventDefault();
		e.stopPropagation();

		this.handleLicensing(data);

		Ctx.close();
	}

	ctxNodeDetails = (e, data) => {
		e.preventDefault();
		e.stopPropagation();

		this.handleNodeDetails(data);

		Ctx.close();
	}

	ctxNodeComments = (e, data) => {
		e.preventDefault();
		e.stopPropagation();

		this.handleNodeComments(data.nodeId);

		Ctx.close();
	}

	ctxAddToPublish = (e, data) => {
		e.preventDefault();
		e.stopPropagation();

		this.handlePublishTreePanel(data);

		Ctx.close();
	}

	ctxUnpublishTours = (e, data) => {
		e.preventDefault();
		e.stopPropagation();

		this.handleUnpublishTours(data);

		Ctx.close();
	}

	ctxHyperlink = (e, data) => {
		e.preventDefault();
		e.stopPropagation();

		this.handleHyperlink(data.nodeId);

		Ctx.close();
	}

	ctxPlayTour = (e, data, autoplay = false, fromIndex = -1) => {
		e.preventDefault();
		e.stopPropagation();

		this.playTour(data.objectId, null, data.type, null, autoplay, fromIndex);
		Ctx.close();
	}

	ctxPlayLeg = (e, data, autoplay = false, fromIndex = -1) => {
		e.preventDefault();
		e.stopPropagation();

		this.playTour(data.tourId, data.objectId, data.type, null, autoplay, fromIndex);
		Ctx.close();
	}

	ctxOpenNextLegs = e => {
		e.preventDefault();
		e.stopPropagation();

		const params = {
			refId: uuid(),
		}

		this.handleContext('sqNextLegs', params, e.currentTarget);
	}
	
	ctxPlayFromHere = (e, data, fromIndex, toIndex) => {
		e.preventDefault();
		e.stopPropagation();

		const mixerActive = data.mixerActive || false;
		const type = mixerActive ? 'mixer' : 'playback';

		this.checkSqPanelIsUpdated(() => {
			this.playTour(data.tourId, data.objectId, 50, 50, true, fromIndex, toIndex, type);
		});

		Ctx.close();
	}

	ctxViewTour = (e, data) => {
		e.preventDefault();
		e.stopPropagation();

		this.openTour(data.objectId)

		Ctx.close();
	}

	ctxPlaySqTour = (e, data) => {
		e.preventDefault();
		e.stopPropagation();

		this.playTour(data.objectId, null, data.type, null);
		Ctx.close();
	}

	ctxAddToTour = (e, data) => {
		e.preventDefault();

		this.addLegTour(data);

		Ctx.close();
	}

	ctxEditTourProfile = (e, data) => {
		e.preventDefault();
		e.stopPropagation();

		this.handleUpdateTour(data);

		Ctx.close();
	}

	ctxEditTour = (e, data) => {
		e.preventDefault();

		const tour = {
			refId: data.refId,
			title: data.title,
			nodeId: data.nodeId,
			tourId: data.objectId,
			parentNodeId: data.src.parent,
		}

		this.showSequencePanel(tour);
		Ctx.close();
	}

	ctxRenameSqListItem = (e, itemId) => {
		e.preventDefault();
		this.renameSqListItem(itemId);

		Ctx.close();
	}

	ctxRemoveSqListItem = (e, itemId) => {
		e.preventDefault();

		this.removeSqListItem(itemId);

		Ctx.close();
	}

	ctxInsertMusic = (e, data) => {
		e.preventDefault();

		// this.handleInsertMusic(itemId);
		this.handleLegMusic(data);

		Ctx.close();
	}

	ctxBgMusicMixer = (e, data) => {
		e.preventDefault();

		this.handleBgMusicMixer(data);

		Ctx.close();
	}

	ctxTourStats = (e, data) => {
		e.preventDefault();
		
		this.handleTourStats(data.objectId);
		Ctx.close();
	}

	ctxTourConversations = (e, data) => {
		e.preventDefault();

		this.handleTourConversations(data.nodeId);

		Ctx.close();
	}

	ctxSort = (e, sortBy, data, root) => {
		e.preventDefault();

		const params = {
			sortBy,
			tree: data.tree,
			parentNodeId: data.nodeId,
			parentNodeType: data.type,
		}

		this.handleSort(params, root);
		Ctx.close();
	}

	ctxPlayMixer = (e, data) => {
		e.preventDefault();
		e.stopPropagation();

		this.playMixer(data);

		Ctx.close();
	}

	ctxPlayGroupMixer = (e, data) => {
		e.preventDefault();

		const mixerActive = data.mixerActive || false;
		const type = mixerActive ? 'mixer' : 'playback';

		this.checkSqPanelIsUpdated(() => {
			this.playTour(data.tourId, data.legIds, 50, null, true, data.fromIndex, data.toIndex, type);
		});

		Ctx.close();
	}

	ctxOpenTourMixer = e => {
		e.preventDefault();

		this.openTourMixer();
		Ctx.close();
	}
	
	ctxCloseTourMixer = e => {
		e.preventDefault();

		this.closeTourMixer();
		Ctx.close();
	}

	ctxZoomInMixer = e => {
		e.preventDefault();

		this.zoomInMixer();

		Ctx.close();
	}
	
	ctxZoomOutMixer = e => {
		e.preventDefault();

		this.zoomOutMixer();

		Ctx.close();
	}

	ctxDeleteAllMixerData = e => {
		e.preventDefault();

		this.deleteAllMixerData();

		Ctx.close();
	}

	// Sq Group

	ctxSqSaveGroup = (e, data) => {
		e.preventDefault();

		const { groupId } = data;

		this.sqSaveGroup(groupId || null);

		Ctx.close();
	}
	
	ctxSqRemoveGroup = (e, data) => {
		e.preventDefault();

		const { groupId } = data;

		this.sqDeleteGroup(groupId || null, false);

		Ctx.close();
	}

	ctxSqDeleteGroup = (e, data) => {
		e.preventDefault();

		const { groupId } = data;

		this.sqDeleteGroup(groupId || null);

		Ctx.close();
	}

	// Panel Image

	ctxUploadImageAtBeginning = e => {
		e.preventDefault();
		
		const data = {
			onBeginning: true,
			onEnd: false
		}

		this.openImageUploader(data);
		Ctx.close();
	}
	
	ctxUploadImageAtEnd = e => {
		e.preventDefault();
		
		const data = {
			onBeginning: false,
			onEnd: true
		}

		this.openImageUploader(data);
		Ctx.close();
	}

	ctxResetImageUpload = (e, data) => {
		e.preventDefault();

		this.resetImageUpload(data.imageId);
		Ctx.close();
	}

	ctxViewImage = (e, data) => {
		e.preventDefault();

		this.sqViewImage(data.imageId);
		Ctx.close();
	}

	ctxImageUploadToggleLock = (e, data) => {
		e.preventDefault();

		this.sqImageUploadToggleLock(data.imageId);

		Ctx.close();
	}

	ctxImageUploadBringFront = (e, data) => {
		e.preventDefault();

		this.imageUploadBringFront(data.imageId);
		Ctx.close();
	}
	
	ctxImageUploadFlipBack = (e, data) => {
		e.preventDefault();

		this.imageUploadFlipBack(data.imageId);

		Ctx.close();
	}
	
	ctxImageUploadAlignToPreviousLeg = (e, data) => {
		e.preventDefault();

		this.imageUploadAlignToPreviousLeg(data.imageId);

		Ctx.close();
	}
	
	ctxImageUploadAlignToNextLeg = (e, data) => {
		e.preventDefault();

		this.imageUploadAlignToNextLeg(data.imageId);

		Ctx.close();
	}

	ctxUploadImageBefore = (e, data) => {
		e.preventDefault();

		const props = {
			imageId: data.imageId,
			// startTime: data.startTime,
			playTime: data.startTime,
			onEnd: true,
			before: true,
		}

		this.openImageUploader(props);

		Ctx.close();
	}
	
	ctxUploadImageAfter = (e, data) => {
		e.preventDefault();

		const props = {
			imageId: data.imageId,
			after: true,
			startTime: data.endTime
		}

		this.openImageUploader(props);

		Ctx.close();
	}

	ctxChangeImageUploadColor = (e, data) => {
		e.preventDefault();

		this.changeImageUploadColor(data.imageId);
		Ctx.close();
	}

	ctxSqImageOverlappingSettings = (e, data) => {
		e.preventDefault();

		this.sqImageOverlappingSettings(data.imageId);
		Ctx.close();
	}

	ctxRenameImageUpload = (e, data) => {
		e.preventDefault();
		this.sqImageInlineRename(data.imageId);
		Ctx.close();
	}

	ctxDeleteImageUpload = (e, data) => {
		e.preventDefault();

		const { imageId } = data;
		this.deleteImageUpload(imageId);

		Ctx.close();
	}

	ctxDeleteAllImageUploads = (e, data) => {
		e.preventDefault();

		const { imageId } = data;
		this.deleteAllImageUploads(imageId);

		Ctx.close();
	}

	ctxSqHeaderPreviewVideo = (e, data) => {
		e.preventDefault();

		this.mixerPreviewVideo('header', data.headerId);
		Ctx.close();
	}

	ctxSqHeaderUploadVideo = (e, data) => {
		e.preventDefault();

		this.mixerHeaderUploadVideo(data.headerId);
		Ctx.close();
	}

	ctxSqRenameHeaderVideo = (e, data) => {
		e.preventDefault();

		this.mixerRenameHeaderVideo(data.headerId);
		Ctx.close();
	}

	ctxSqDeleteHeaderVideo = (e, data) => {
		e.preventDefault();

		this.mixerDeleteHeaderVideo(data.headerId);
		Ctx.close();
	}
	
	ctxSqFooterUploadVideo = (e, data) => {
		e.preventDefault();

		this.mixerFooterUploadVideo(data.headerId);
		Ctx.close();
	}
	
	ctxSqRenameFooterVideo = (e, data) => {
		e.preventDefault();

		this.mixerRenameFooterVideo(data.headerId);
		Ctx.close();
	}

	ctxSqDeleteFooterVideo = (e, data) => {
		e.preventDefault();

		this.mixerDeleteFooterVideo(data.headerId);
		Ctx.close();
	}

	ctxSqFooterPreviewVideo = (e, data) => {
		e.preventDefault();

		this.mixerPreviewVideo('footer', data.footerId);
		Ctx.close();
	}

	// Panel Music

	ctxPlayMusic = (e, data) => {
		e.preventDefault();

		const { media, musicId, playTime, originalPlaytime } = data;
		const loop = playTime > originalPlaytime;

		this.sqPanelPlayMusic(musicId, media.src, playTime, loop);

		Ctx.close();
	}
	
	ctxStopMusic = (e, data) => {
		e.preventDefault();

		this.sqPanelStopMusic(data.musicId);
		Ctx.close();
	}
	
	ctxPlayMusicMixer = (e, data) => {
		e.preventDefault();

		this.playMusicMixer(data);
		Ctx.close();
	}
	
	ctxPlayImageMixer = (e, data) => {
		e.preventDefault();

		this.playImageMixer(data);
		Ctx.close();
	}

	ctxUploadMusicAtBeginning = e => {
		e.preventDefault();
		
		const data = {
			onBeginning: true,
			onEnd: false
		}

		this.openMusicUploader(data);
		Ctx.close();
	}
	
	ctxUploadMusicAtEnd = e => {
		e.preventDefault();
		
		const data = {
			onBeginning: false,
			onEnd: true
		}

		this.openMusicUploader(data);
		Ctx.close();
	}

	ctxResetUpload = (e, data) => {
		e.preventDefault();

		this.resetUpload(data.musicId);
		Ctx.close();
	}

	ctxUploadBringFront = (e, data) => {
		e.preventDefault();

		this.uploadBringFront(data.musicId);
		Ctx.close();
	}
	
	ctxUploadFlipBack = (e, data) => {
		e.preventDefault();

		this.uploadFlipBack(data.musicId);

		Ctx.close();
	}
	
	ctxUploadAlignToPreviousLeg = (e, data) => {
		e.preventDefault();

		this.uploadAlignToPreviousLeg(data.musicId);

		Ctx.close();
	}
	
	ctxUploadAlignToNextLeg = (e, data) => {
		e.preventDefault();

		this.uploadAlignToNextLeg(data.musicId);

		Ctx.close();
	}

	ctxUploadMusicBefore = (e, data) => {
		e.preventDefault();

		const props = {
			musicId: data.musicId,
			// startTime: data.startTime,
			playTime: data.startTime,
			onEnd: true,
			before: true,
		}

		this.openMusicUploader(props);

		Ctx.close();
	}
	
	ctxUploadMusicAfter = (e, data) => {
		e.preventDefault();

		const props = {
			musicId: data.musicId,
			after: true,
			startTime: data.endTime
		}

		this.openMusicUploader(props);

		Ctx.close();
	}

	ctxChangeUploadColor = (e, data) => {
		e.preventDefault();

		this.changeUploadColor(data.musicId);
		Ctx.close();
	}

	ctxRenameUpload = (e, data) => {
		e.preventDefault();
		this.uploadInlineRename(data.musicId);

		Ctx.close();
	}

	ctxDeleteUpload = (e, data) => {
		e.preventDefault();

		const { musicId } = data;
		this.deleteUpload(musicId);

		Ctx.close();
	}
	
	ctxDeleteAllMusicUploads = (e, data) => {
		e.preventDefault();

		const { musicId } = data;
		this.deleteAllUploads(musicId);

		Ctx.close();
	}

	ctxMusicUploadToggleLock = (e, data) => {
		e.preventDefault();

		this.sqMusicUploadToggleLock(data.musicId);

		Ctx.close();
	}

	// Add Attachment

	addAttachment = (data, success, fail) => {
		this.requestAddAttachment(data, success, fail);
	}

	doRequestAddAttachment = (data, success, fail) => {
		this.props.addAttachment(data, success, fail)
	}

	// New Folder

	addNewFolder = e => {
		e.preventDefault();

		const isValid = this.validateNewFolderForm();

		if(isValid) {
			const { newFolderForm } = this.state;
			const modal = this.newFolderModalRef.current;
			const treeRef = this.getTreeRef(newFolderForm.tree);

			const data = {
				...newFolderForm,
				tFolderId: uuid(),
			}

			this.setState({ addingNewFolder: true });
			
			treeRef.createFolder(data, () => {
				this.setState({ addingNewFolder: false });
				modal.hide();
			}, reasons => {
				const errors = map(reasons, reason => reason.message);
				
				this.setState({
					addingNewFolder: false,
					newFolderFormErrors: errors,
				});
			});
		}
	}

	handleTxtNewFolder = e => {
		const { newFolderForm } = this.state;
		const { value } = e.currentTarget;

		this.setState({
			newFolderForm: {
				...newFolderForm,
				title: value,
			}
		});
	}

	handleNewFolder = data => {
		const { newFolderForm } = this.state;
		const modal = this.newFolderModalRef.current;
		const { parentNodeId, parentNodeType, licensed, tree } = data;

		this.setState({
			newFolderForm: {
				...newFolderForm,
				parentNodeId,
				parentNodeType,
				licensed,
				tree
			}
		}, modal.show);
	}

	validateNewFolderForm = () => {
		let isValid = true;
		const errors = [];
		const { newFolderForm: { title } } = this.state;

		if(isEmpty(title)) {
			isValid = false;
			errors.push('Please enter a valid title.')
		}

		this.setState({
			newFolderFormErrors: errors,
		});

		return isValid;
	}

	// New Group

	addNewGroup = e => {
		e.preventDefault();

		const isValid = this.validateNewGroupForm();

		if(isValid) {
			const { newGroupForm } = this.state;
			const treeRef = this.getTreeRef('publishTree');
			const modal = this.newGroupModalRef.current;

			const data = {
				...newGroupForm,
				tGroupId: uuid(),
			}
			
			this.setState({ addingNewGroup: true });
			
			treeRef.createGroup(data, (status, response) => {
				const moveNodeData = this.state.moveNode;

				this.setState({
					addingNewGroup: false,
					moveNode: this.initialMoveNodeState
				}, () => {
					if(!isEmpty(moveNodeData.src)) {
						const src = {
							...moveNodeData.src,
							parentNodeId: response.nodeId,
							parentNodeType: response.nodeType,
						}
						
						this.treeMoveNode(src, moveNodeData.srcTree);
					}
				});
				modal.hide();
			}, reasons => {
				const errors = map(reasons, reason => reason.message);

				this.setState({
					addingNewGroup: false,
					newGroupFormErrors: errors,
				});
			});
		}
	}

	handleTxtNewGroup = e => {
		const { newGroupForm } = this.state;
		const { value } = e.currentTarget;

		this.setState({
			newGroupForm: {
				...newGroupForm,
				title: value,
			}
		});
	}

	handleNewGroup = (data, autoMove) => {
		const modal = this.newGroupModalRef.current;
		modal.show();

		if(autoMove) {
			this.setState({
				moveNode: {
					src: data,
					srcTree: data.tree,
					parentNode: null
				}
			});
		}
	}

	validateNewGroupForm = () => {
		let isValid = true;
		const errors = [];
		const { newGroupForm: { title } } = this.state;

		if(isEmpty(title)) {
			isValid = false;
			errors.push('Please enter a valid title.')
		}

		this.setState({
			newGroupFormErrors: errors,
		});

		return isValid;
	}

	// Tour

	handleTour = data => {
		const modal = this.tourModalRef.current;

		modal.show(data);
	}

	handleUpdateTour = data => {
		const modal = this.tourModalRef.current;
		const treeRef = this.getTreeRef('tours');

		const params = {
			tourId: data.objectId,
		}

		const parent = {
			parentNodeId: data.parentNodeId,
			parentNodeType: data.parentNodeType,
		}

		this.setState({ fetchingTourProfile: true }, () => {
			modal.show(parent);
		});

		treeRef.getTourProfileDetails(params, (status, response) => {
			this.setState({ fetchingTourProfile: false });
			modal.setData(response);
		}, () => {
			this.setState({ fetchingTourProfile: false });
			modal.hide();
		});
	}

	saveTour = (data, type, success, fail) => {
		const treeRef = this.getTreeRef('tours');

		switch(type) {
			case 'create': treeRef.createTour(data, success, fail); break;
			case 'update': treeRef.updateTour(data, success, fail); break;
			default: break;
		}
	}

	// New Leg

	cancelNewLeg = () => {
		this.disableToursPanel();
		this.setState({
			addingNewLeg: false,
			newLegForm: this.initialNewLegFormState
		});
	}

	handleNewLegAddress = data => {
		const modal = this.newLegModalRef.current;

		AudioHelper.getPermission(() => {
			this.setState({ audioDevice: true });
		}, () => {
			this.setState({ audioDevice: false });
		});

		this.updateLegDetails({
			hierarchy: data.hierarchy,
			parentNodeId: data.parentNodeId,
			parentNodeType: data.parentNodeType,
			licensed: data.licensed,
			tree: data.tree,
		}, modal.show);
	}

	handleNewLeg = () => {
		const modal = this.newLegModalDetailsRef.current;

		modal.show();
	}

	saveLeg = (success, fail) => {
		const { newLegForm } = this.state;
		const treeRef = this.getTreeRef(newLegForm.tree);

		const data = {
			tLegId: uuid(),
			audio: newLegForm.audio,
			position: newLegForm.position,
			parentNodeId: newLegForm.parentNodeId,
			parentNodeType: newLegForm.parentNodeType,
			title: newLegForm.title,
			language: newLegForm.language,
			location: newLegForm.location,
			categoryIds: newLegForm.categoryIds,
			recording: newLegForm.recording,
			primaryCategoryId: newLegForm.primaryCategoryId,
		}

		this.setState({ addingNewLeg: true });

		treeRef.createLeg(data, () => {
			this.disableToursPanel();
			this.setState({
				addingNewLeg: false,
				newLegForm: this.initialNewLegFormState
			});

			Notify.success('Your leg will be ready within few minutes. Thank you!');

			if(success) success();
		}, reasons => {
			const errors = map(reasons, reason => {
				Notify.error(reason.message);
				return reason.message;
			});
			
			this.disableToursPanel();
			this.setState({ addingNewLeg: false });
			
			if(fail) fail(errors);
		}); 
	}

	// Rename Node

	renameNode = e => {
		e.preventDefault();

		const isValid = this.validateRenameNodeForm();

		if(isValid) {
			const { renameNodeForm } = this.state;
			const treeRef = this.getTreeRef(renameNodeForm.tree);
			const modal = this.renameNodeModalRef.current;

			const data = {
				nodeId: renameNodeForm.nodeId,
				nodeType: renameNodeForm.type,
				title: renameNodeForm.title,
			}

			this.setState({ renamingNode: true });
			
			treeRef.renameNode(data, () => {
				this.setState({ renamingNode: false });
				modal.hide();
			}, reasons => {
				const errors = map(reasons, reason => reason.message);
				
				this.setState({
					renamingNode: false,
					renameNodeFormErrors: errors,
				});
			});
		}
	}

	handleTxtRenameNode = e => {
		const { renameNodeForm } = this.state;
		const { value } = e.currentTarget;

		this.setState({
			renameNodeForm: {
				...renameNodeForm,
				title: value,
			}
		});
	}

	handleRenameNode = data => {
		const { refId, nodeId, type, title, tree } = data;
		const { renameNodeForm } = this.state;
		const modal = this.renameNodeModalRef.current;

		this.setState({
			renameNodeForm: {
				...renameNodeForm,
				type,
				tree,
				title,
				nodeId,
				id: refId,
				existingTitle: title,
			}
		}, modal.show);
	}

	validateRenameNodeForm = () => {
		let isValid = true;
		const errors = [];
		const { renameNodeForm: { title } } = this.state;

		if(isEmpty(title)) {
			isValid = false;
			errors.push('Please enter a valid title.')
		}

		this.setState({
			renameNodeFormErrors: errors,
		});

		return isValid;
	}

	// User Payments Account

	checkAccount = () => {
		const { profile } = this.props;
		return profile.is_attach_connected_account;
	}

	showAddAccountNotif = () => {
		const modal = this.accountNotifModalRef.current;
		modal.show();
	}

	// Licensing

	fetchLicense = id => {
		const treeRef = this.getTreeRef('legs');

		this.setState({ loadingLicense: true });

		treeRef.getLegLicense(id, result => {
			const currency = result.currency || this.props.profile.currency || '';

			this.updateLicensingForm({
				currency,
				price: result.price,
				description: result.description,
			}, () => {
				this.setState({ loadingLicense: false });
				// this.getLegPriceCalculation();
			});
		}, () => this.setState({ loadingLicense: false }));
	}

	updateLicense = e => {
		e.preventDefault();

		const isValid = this.validateLicensingForm();

		if(isValid) {
			const { licensingForm } = this.state;
			const treeRef = this.getTreeRef('legs');
			const modal = this.licensingModalRef.current;

			const data = {
				legId: licensingForm.objectId,
				nodeId: licensingForm.id,
				currency: licensingForm.currency,
				price: licensingForm.price,
				exclusive: licensingForm.exclusive,
				description: licensingForm.description,
			}

			this.setState({
				updatingLicense: true,
				updatingLicensePrice: false, 
			});
			
			treeRef.setLegLicense(data, () => {
				this.setState({
					updatingLicense: false,
					updatingLicensePrice: false,
				}, modal.hide);
			}, (status, reasons) => {
				const errors = map(reasons, reason => reason.message);

				this.setState({
					updatingLicense: false,
					licensingFormErrors: errors,
					updatingLicensePrice: false,
				});
			});
		}
	}

	handleLicensingPrice = e => {
		const { value } = e.currentTarget;

		this.updateLicensingForm({
			price: value || 0
		}, () => {
			// this.getLegPriceCalculation();
		});
	}
	
	handleLicensingExclusiveToggle = (value, checked) => {
		this.updateLicensingForm({
			exclusive: checked || false
		}, () => {
			console.log(JSON.stringify(this.state.licensingForm));
		});
	}

	handleLicensingDescription = e => {
		const { value } = e.currentTarget;

		this.updateLicensingForm({
			description: value
		});
	}

	handleLicensingCurrency = (name, value) => {
		this.updateLicensingForm({
			currency: value
		}, () => {
			// this.getLegPriceCalculation();
		});
	}

	handleLicensing = data => {
		const { nodeId, licensed, objectId } = data;
		// const isValid = this.checkAccount();
		const isValid = true;

		if(!isValid) {
			this.showAddAccountNotif();
			return false;
		}

		if(!licensed) {
			const modal = this.licensingModalRef.current;

			this.updateLicensingForm({
				id: nodeId,
				objectId,
			}, modal.show);
		} else {
			const treeRef = this.getTreeRef('legs');

			treeRef.removeLegLicense(nodeId);
		}

		return true;
	}

	updateLicensingForm = (updates, callback) => {
		const { licensingForm } = this.state;

		this.setState({
			licensingForm: {
				...licensingForm,
				...updates
			}
		}, () => callback ? callback() : null);
	}

	validateLicensingForm = () => {
		let isValid = true;
		const errors = [];
		const { licensingForm: { currency, price, description } } = this.state;

		if(isEmpty(currency) || InputHelper.equalTo(currency, -1)) {
			isValid = false;
			errors.push('Please select a currency.');
		}

		if(price <= 0 || !InputHelper.isFloat(price)) {
			isValid = false;
			errors.push('Please enter a valid price.');
		}

		if(InputHelper.isEmpty(description)) {
			isValid = false;
			errors.push('Please describe your leg in few words.');
		}

		if(!InputHelper.isEmpty(description) && !InputHelper.min(description, this.licensingDescriptionMinWords)) {
			isValid = false;
			errors.push(`Description should be more than ${ this.licensingDescriptionMinWords } words.`);
		}

		if(!InputHelper.isEmpty(description) && !InputHelper.max(description, this.licensingDescriptionMaxWords)) {
			isValid = false;
			errors.push('Description too long.');
		}

		this.setState({
			licensingFormErrors: errors,
		});

		return isValid;
	}

	// Open Tour

	openTour = tourId => {
		const url = `/home?tourId=${ tourId }`;

		window.open(url, '_blank');
	}

	// Node Details

	handleNodeDetails = data => {
		const modal = this.nodeDetailsModalRef.current;
		const { nodeId, type, tree, baseNodeType, title } = data;

		const params = {
			id: nodeId,
			type,
			tree,
		}

		if(baseNodeType) params.baseNodeType = baseNodeType;
		if(title) params.title = title;

		this.updateNodeDetails(params, modal.show);
	}

	// Node Comments

	handleNodeComments = refId => {
		const modal = this.nodeCommentsModalRef.current;

		this.setState({
			nodeComments: {
				id: refId
			}
		}, modal.show);
	}

	// Hyperlink

	handleHyperlink = tourId => {
		const modal = this.hyperlinkModalRef.current;
		const treeRef = this.getTreeRef('tours');

		this.setState({ fetchingTourHyperlink: true }, () => {
			modal.handleHyperlink(tourId);
		});

		treeRef.getHyperlink(tourId, response => {
			this.setState({
				hyperlink: response,
				fetchingTourHyperlink: false,
			}, modal.onSuccess);
		}, () => {
			this.setState({ fetchingTourHyperlink: true });
		});
	}

	sendHyperlink = (form, success, fail) => {
		const treeRef = this.getTreeRef('tours');

		treeRef.sendHyperlink(form, success, fail)
	}

	// Tour Stats

	handleTourStats = tourId => {
		const modal = this.tourStatsModalRef.current;

		this.setState({
			tourStats: {
				tourId,
			}
		}, modal.show);
	}

	// Sequence Panel

	checkSqPanelIsUpdated = callback => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		const isUpdated = sequencePanel.getIsUpdated();

		if(isUpdated) {
			UIkit.modal.confirm('Please save your changes before previewing.', () => {
				sequencePanel.saveTourMixer(callback);
			});
		} else {
			if(callback) callback(); // eslint-disable-line no-lonely-if
		}
		
		return isUpdated;
	}

	checkSqPanelIsBusy = (busy, callback) => {
		this.updateSqPanelState({ busy }, () => {
			const { sequencePanel } = this.state;

			if(sequencePanel.active && sequencePanel.busy) {
				this.showOverlay(callback);
			} else {
				this.hideOverlay(callback);
			}
		});
	}

	playMixer = data => {
		const mixerActive = data.mixerActive || false;
		const type = mixerActive ? 'mixer' : 'playback';
		const from = data.fromIndex >= 0 ? data.fromIndex : -1;
		const to = data.toIndex >= 0 ? data.toIndex : -1;

		this.checkSqPanelIsUpdated(() => {
			this.playTour(data.objectId, null, data.type, null, true, from, to, type);
		});
	}

	openTourMixer = () => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.openTourMixer();
	}
	
	closeTourMixer = () => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.closeTourMixer();
	}

	zoomInMixer = () => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.zoomInMixer();
	}
	
	zoomOutMixer = () => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.zoomOutMixer();
	}
	
	deleteAllMixerData = () => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.deleteAllData();
	}
	
	// openTourMixer = musicId => {
	// 	const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
	// 	sequencePanel.openTourMixer(musicId);
	// }

	// sq Add Group

	sqSaveGroup = groupId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;

		if(!groupId) {
			sequencePanel.openGroupModal(groupId);
		} else {
			sequencePanel.handleGroupInlineRename(groupId);
		}
	}
	
	sqDeleteGroup = (groupId, removeLegs = true) => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.deleteGroup(groupId, removeLegs);
	}

	// Image
	
	openImageUploader = data => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.openImageUploader(data);
	}

	resetImageUpload = imageId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.resetImageUpload(imageId);
	}
	
	sqViewImage = imageId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.viewImage(imageId);
	}
	
	sqImageUploadToggleLock = imageId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.handleImageUploadToggleLock(imageId);
	}

	imageUploadBringFront = imageId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.imageUploadBringFront(imageId);
	}
	
	imageUploadFlipBack = imageId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.imageUploadFlipBack(imageId);
	}
	
	imageUploadAlignToPreviousLeg = imageId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.imageUploadAlignToPreviousLeg(imageId);
	}
	
	imageUploadAlignToNextLeg = imageId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.imageUploadAlignToNextLeg(imageId);
	}

	changeImageUploadColor = imageId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.changeImageUploadColor(imageId);
	}
	
	sqImageOverlappingSettings = imageId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.handleImageSettings(imageId);
	}
	
	sqImageInlineRename = imageId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.handleImageInlineRename(imageId);
	}

	deleteImageUpload = imageId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.deleteImageUpload(imageId);
	}

	deleteAllImageUploads = imageId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.deleteAllImageUploads(imageId);
	}

	// Music
	
	openMusicUploader = data => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.openMusicUploader(data);
	}

	resetUpload = musicId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.resetUpload(musicId);
	}

	uploadBringFront = musicId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.uploadBringFront(musicId);
	}
	
	uploadFlipBack = musicId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.uploadFlipBack(musicId);
	}
	
	uploadAlignToPreviousLeg = musicId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.uploadAlignToPreviousLeg(musicId);
	}
	
	uploadAlignToNextLeg = musicId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.uploadAlignToNextLeg(musicId);
	}
	
	changeUploadColor = musicId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.changeUploadColor(musicId);
	}
	
	deleteUpload = musicId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.deleteUpload(musicId);
	}
	
	uploadAddClass = (musicId, className) => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.uploadAddClass(musicId, className);
	}
	
	uploadRemoveClass = (musicId, className) => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.uploadRemoveClass(musicId, className);
	}

	uploadInlineRename = musicId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.handleMusicInlineRename(musicId);
	}
	
	deleteAllUploads = musicId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.deleteAllUploads(musicId);
	}

	// Header / Footer Videos

	mixerHeaderUploadVideo = () => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.initHeaderUploadVideo();
	}
	
	mixerFooterUploadVideo = () => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.initFooterUploadVideo();
	}

	mixerDeleteHeaderVideo = headerId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.deleteHeaderVideo(headerId);		
	}
	
	mixerRenameHeaderVideo = headerId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.handleHeaderVideoInlineRename(headerId);		
	}
	
	mixerRenameFooterVideo = footerId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.handleFooterVideoInlineRename(footerId);		
	}

	mixerDeleteFooterVideo = headerId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.deleteFooterVideo(headerId);		
	}
	
	mixerPreviewVideo = entity => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.previewVideo(entity);		
	}

	sqMusicUploadToggleLock = musicId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.handleMusicUploadToggleLock(musicId);
	}
	
	sqPanelPlayMusic = musicId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.previewMusic(musicId);
	}
	
	sqPanelPlayMusicMixer = () => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.previewMusicMixer();
	}
	
	sqPanelStopMusic = musicId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;
		sequencePanel.stopMusic(musicId);
	}

	addLegTour = data => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;

		const obj = {
			nodeId: data.nodeId,
			objectId: data.objectId,
			title: data.title,
			licensed: data.licensed || false,
			playTime: parseInt(data.playTime, 10),
			isPurchased: data.isPurchased || false,
		}

		sequencePanel.addItem(obj);
	}

	handleInsertMusic = itemId => {
		const sequencePanel = this.sequencePanelRef.current.wrappedInstance;

		sequencePanel.initBgMusicUpload(itemId);
	}

	handleSequencePanelViaUrl = () => {
		const url = new URL(window.location);
		const tourId = url.searchParams.get('sqPanelTourId');
		if(!tourId) return;

		this.props.getTourSummary({ tourId }, (status, response) => {
			const tour = {
				refId: uuid(),
				title: response.title,
				nodeId: response.nodeId,
				tourId: response.tourId || tourId,
				parentNodeId: response.parentNodeId,
			}

			this.handleSequencePanel(tour);
		});
	}

	handleSequencePanel = tour => {
		this.showSequencePanel(tour);
	}

	showSequencePanel = tour => {
		this.updateSqPanelState({
			active: true,
			tour
		});
	}

	hideSequencePanel = () => {
		this.updateSqPanelState({
			active: false,
			tour: {},
			busy: false,
		}, () => {
			console.log('Closed Hide Sequence Panel');
			this.setState({ bgMusicMixer: this.bgMusicMixerInitialState });
		});
	}

	// Preview Media

	previewMedia = (data, type) => {
		this.updatePlayerState({ type: 'default', render: true }, () => {
			this.updatePlayerState({ type: 'media' }, () => {
				const mediaPlayer = this.mediaPlayerRef.current;
				if(mediaPlayer) mediaPlayer.preview(data, type);
			});
		})
	}
	
	disableMediaPanel = () => {
		const mediaPlayer = this.mediaPlayerRef.current;
		if(mediaPlayer) mediaPlayer.disablePlayer();
	}

	// Upload Music

	handleLegMusic = data => {
		const { objectId, nodeId, tourId } = data;
		const modal = this.legMusicModalRef.current;

		this.updateLegMusicState({
			nodeId,
			tourId,

			legId: objectId,
		}, modal.show);
	}

	// Music Mixer

	handleBgMusicMixer = (data, callback) => {
		this.setState({
			bgMusicMixer: {
				title: data.title,
				tourId: data.objectId,
			}
		}, callback);
	}

	// Action Panel

	handleActionPanel = (data, type = 'copy', allowAll = false) => {
		const trees = [];

		switch(data.tree) {
			case 'legs': trees.push('legs'); break;
			case 'tours': trees.push('tours'); break;
			case 'publishTree': trees.push('publishTree'); break;
			default: trees.push(); break;
		}

		this.resetActionPanel();
		this.updateActionPanel({
			type,
			allowAll,
			tree: data.tree,
			refId: data.refId,
			nodeId: data.nodeId,
			nodeType: data.type,
			objectId: data.objectId,
		}, this.showActionPanel);
	}

	showActionPanel = () => {
		this.updateActionPanel({ active: true });
	}

	hideActionPanel = () => {
		this.updateActionPanel({ active: false });
	}

	resetActionPanel = () => {
		this.updateActionPanel(this.initialActionPanelState);
	}

	// Group Tours Panel

	handleUnpublishTours = data => {
		const treeRef = this.getTreeRef('publishTree');

		treeRef.unpublishTours(data.refId, data.objectId);
	}

	handlePublishTreePanel = data => {
		this.updatePublishTreePanel({
			tree: data.tree,
			refId: data.refId,
			title: data.title,
			nodeType: data.type,
			nodeId: data.nodeId,
			tourId: data.objectId,
		}, this.showPublishTreePanel);
	}

	showPublishTreePanel = () => this.updatePublishTreePanel({ active: true })
	
	hidePublishTreePanel = () => this.updatePublishTreePanel({ active: false }, this.resetPublishTreePanel)
	
	resetPublishTreePanel = () => this.updatePublishTreePanel(this.initialPublishTreePanelState)

	// Tour Player

	playTour = (tourId, legIds, nodeType, srcType, autoplay, fromIndex, toIndex, type, options = {}) => {
		this.updatePlayerState({ type: 'default', render: true }, () => {
			const toursPanel = this.toursPanelRef.current.wrappedInstance;
			toursPanel.initializePlay(tourId, legIds, nodeType, srcType, autoplay, fromIndex, toIndex, type, options);
		});
	}

	recordTour = () => {
		this.updatePlayerState({ type: 'default', render: true }, () => {
			const { newLegForm } = this.state;
			
			const toursPanel = this.toursPanelRef.current.wrappedInstance;
			toursPanel.initializeRecord(newLegForm);
		});
	}

	playMusicMixer = data => {
		if(!data.musicIds.length) {
			Notify.error('Please add some music.');
			return;
		}

		const type = 'musicMixer';

		this.checkSqPanelIsUpdated(() => {
			this.playTour(data.objectId, null, data.type, null, false, -1, -1, type);
		});
	}
	
	playImageMixer = data => {
		if(!data.imageIds.length) {
			Notify.error('Please add some images.');
			return;
		}

		const type = 'imageMixer';

		this.checkSqPanelIsUpdated(() => {
			this.playTour(data.objectId, null, data.type, null, false, -1, -1, type);
		});
	}

	// Tree Actions

	enableTreeOverlay = el => {
		const currentTree = el.closest('[data-tree]');

		if(!currentTree) return;

		const treeAllowed = split(currentTree.getAttribute('data-allowed'), ',');
		const treeType = currentTree.getAttribute('data-tree');
		const trees = document.querySelectorAll('[data-tree]');

		map(trees, tree => {
			const type = tree.getAttribute('data-tree');
			const parent = tree.closest('.cntTour');
			const childTrees = parent.querySelectorAll('[data-tree]');
			const siblings = map(childTrees, childTree => childTree.getAttribute('data-tree'));

			if(indexOf(treeAllowed, type) === -1) {
				if(indexOf(siblings, treeType) !== -1) {
					tree.classList.add('dnd-disabled');
				} else {
					parent.classList.add('dnd-disabled');
				}
			}
		});
	}

	disableTreeOverlay = () => {
		const trees = document.querySelectorAll('[data-tree]');

		map(trees, tree => {
			const parent = tree.closest('.cntTour');

			tree.classList.remove('dnd-disabled');
			parent.classList.remove('dnd-disabled');
		});
	}

	handleDnd = () => {
		jQuery(document).on('dnd_start.vakata', (e, data) => {
			// setTimeout(() => {
			// 	const html = $('<div/>').append($('#jstree-marker').clone()).html();
			// }, 1000);

			// <div id="vakata-dnd" style="display: block; margin: 0px; padding: 0px; position: absolute; top: -2000px; line-height: 16px; z-index: 10000;"><div id="jstree-dnd" class="jstree-default jstree-default-false "><i class="jstree-icon jstree-er"></i>Testing<ins class="jstree-copy" style="display:none;">+</ins></div></div>

			const tree = data.data.origin.element;
			if($(tree).hasClass('sqTree')) return;

			const { element, data: { nodes } } = data;
			this.enableTreeOverlay(element);
			Ctx.close();

			tree.data('nodes', nodes.length);
			$(tree).addClass('jstree-dnd-active');
		});

		jQuery(document).on('dnd_stop.vakata', (e, data) => {
			const tree = data.data.origin.element;
			if($(tree).hasClass('sqTree')) return;
			
			this.disableTreeOverlay();
			$(tree).removeClass('jstree-dnd-active');
		});
	}

	handleTourConversations = nodeId => {
		const adminLayoutRef = this.adminLayoutRef.current;

		if(adminLayoutRef) adminLayoutRef.showConversations(nodeId, 50);
	}

	treeCopyNode = (data, tree, parentNode) => {
		const treeRef = this.getTreeRef(tree);
		treeRef.copyNode(data, tree, parentNode);
	}

	treeMoveNode = (data, tree, parentNode) => {
		const treeRef = this.getTreeRef(tree);
		treeRef.moveNode(data, tree, parentNode);
	}

	treeMoveNodeUp = (data, tree) => {
		const treeRef = this.getTreeRef(tree);
		treeRef.moveNodeUp(data);
	}

	treeMoveNodeDown = (data, tree) => {
		const treeRef = this.getTreeRef(tree);
		treeRef.moveNodeDown(data);
	}

	publishNodesToGroup = (data, srcTree, destTree, parentNode) => {
		const treeRef = this.getTreeRef(srcTree);
		treeRef.publishNodesToGroup(data, srcTree, destTree, parentNode);
	}

	publishAllTours = () => {
		const treeRef = this.getTreeRef('groupedTours');
		treeRef.publishAllTours();
	}

	unpublishAllTours = () => {
		const treeRef = this.getTreeRef('groupedTours');
		treeRef.unpublishAllTours();
	}

	initTreeResizable = () => {
		const $parent = $('#treeResizableParent');
		const $rPanels = $('[data-resizable]', $parent);

		if(!$rPanels.length) return false;

		const handle = () => {
			const total = map($rPanels, panel => panel.offsetWidth);
			const toursTreesOuterRef = this.toursTreesOuterRef.current;
			const width = sum(total);

			$parent.css({ minWidth: width, width });
			if(toursTreesOuterRef) toursTreesOuterRef.update();
		}

		map($rPanels, rPanel => {
			const $this = $(rPanel);
			const handles = $this.data('resizable-handle') || 'e';
			const $sibls = $parent.find('[data-resizable]');

			$this.resizable({
				minWidth: 200,
				maxWidth: 700,
				handles,

				create: () => {
					const width = parseInt($parent.width() / $sibls.length, 10);

					$sibls.width(width);

					handle();
				},

				start: () => {
					handle();
				},

				resize: () => {
					handle();
				},

				stop: () => {
					handle();
				}
			});
		});

		handle();

		return true;
	}

	// Sort

	handleSort = (data, root) => {
		const { parentNodeId, parentNodeType, tree, sortBy } = data;

		const treeRef = this.getTreeRef(tree);

		if(treeRef) {
			const params = {
				sortBy,
				parentNodeId,
				parentNodeType,
			}

			treeRef.sortNodes(params, root);
		}
	}

	// Overlays

	showOverlay = callback => {
		const overlayRef = this.overlayRef.current;
		if(!overlayRef) return;

		$(overlayRef).velocity('fadeIn', {
			duration: Velocity.DURATION,
			easing: Velocity.EASING,

			complete: () => {
				if(callback) callback();
			}
		});		
	}

	hideOverlay = callback => {
		const overlayRef = this.overlayRef.current;
		if(!overlayRef) return;

		$(overlayRef).velocity('fadeOut', {
			duration: Velocity.DURATION,
			easing: Velocity.EASING,

			complete: () => {
				if(callback) callback();
			}
		});
	}

	// Updates

	updateNodeDetails = (updates, callback) => {
		const { nodeDetails } = this.state;

		this.setState({
			nodeDetails: {
				...nodeDetails,
				...updates
			}
		}, () => {
			if(callback) callback();
		});
	}

	updatePlayerState = (updates, callback) => {
		const { player } = this.state;

		this.setState({
			player: { ...player, ...updates }
		}, callback);
	}

	updateMusicPlayerState = (updates, callback) => {
		const { musicPlayer } = this.state;

		this.setState({
			musicPlayer: { ...musicPlayer, ...updates }
		}, callback);
	}

	updateCtxContent = (type, props, callback) => {
		const { ctx } = this.state;

		this.setState({
			ctx: {
				...ctx,
				[type]: {
					...props
				}
			}
		}, callback ? callback() : null);
	}

	updateActionPanel = (updates, callback) => {
		const { actionPanel } = this.state;

		this.setState({
			actionPanel: {
				...actionPanel,
				...updates
			}
		}, () => {
			if(callback) callback();
		});
	}

	updatePublishTreePanel = (updates, callback) => {
		const { publishTreePanel } = this.state;

		this.setState({
			publishTreePanel: {
				...publishTreePanel,
				...updates
			}
		}, () => {
			if(callback) callback();
		});
	}

	updateSqPanelState = (updates, callback) => {
		const { sequencePanel } = this.state;

		this.setState({
			sequencePanel: {
				...sequencePanel,
				...updates
			}
		}, callback);
	}

	updateLegDetails = (updates, callback) => {
		const { newLegForm } = this.state;

		this.setState({
			newLegForm: {
				...newLegForm,
				...updates
			}
		}, () => {
			if(callback) callback(this.state.newLegForm);
		});
	}

	updateLegMusicState = (updates, callback) => {
		const { legMusic } = this.state;

		this.setState({
			legMusic: {
				...legMusic,
				...updates
			}
		}, () => {
			if(callback) callback();
		});
	}

	updateNotifState = (updates, callback) => {
		const { notif } = this.state;

		this.setState({
			notif: {
				...notif,
				...updates
			}
		}, () => {
			if(callback) callback();
		});
	}

	renameSqListItem = itemId => {
		const panel = this.sequencePanelRef.current.wrappedInstance;
		panel.handleLegInlineRename(itemId);
	}

	removeSqListItem = itemId => {
		const panel = this.sequencePanelRef.current.wrappedInstance;
		panel.removeItem(itemId);
	}

	removeActionPanelNodes = tree => {
		const treeRef = this.getTreeRef(tree);

		if(treeRef) {
			treeRef.removeActionPanelNodes();
		}
	}

	// Renders

	renderFolderContext = () => {
		const { folder } = this.state.ctx;

		return (
			<ContextMenu type="folder" refId={ folder.refId }>
				<li className={ !folder.inLimit ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxNewFolder(e, folder) }>
						<i className="item-icon material-icons">create_new_folder</i>
						{ folder.licensed ? 'New Licensed Folder' : 'New Folder' }
					</Link>
				</li>
				<li className={ (folder.tree === 'tours') ? 'd-none' : 'd-block' }>
					<Link to="#ctx" onClick={ e => this.ctxNewLeg(e, folder) }>
						<i className="item-icon material-icons">extension</i>
						{ folder.licensed ? 'New Licensed Leg' : 'New Leg' }
					</Link>
				</li>
				<li className={ (folder.tree !== 'tours') ? 'd-none' : 'd-block' }>
					<Link to="#ctx" onClick={ e => this.ctxNewTour(e, folder) }>
						<span className="item-icon item-icon-img">
							<img src="assets/icons/tree/icon-tour.png" className="img-responsive" alt=""/>
						</span>
						New Tour
					</Link>
				</li>
				<li className="divider"/>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxNodeDetails(e, folder) }>
						<i className="item-icon material-icons">info</i> View Details
					</Link>
				</li>
				<li className="divider"/>
				<li className={ folder.root ? 'd-none' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxCopy(e, folder) }>
						<i className="item-icon item-icon-fa fas fa-clone"/> Copy
					</Link>
				</li>
				<li className={ folder.root ? 'd-none' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxMove(e, folder) }>
						<i className="item-icon material-icons">move_to_inbox</i> Move
					</Link>
				</li>
				<li className={ folder.root ? 'd-none' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxRenameNode(e, folder) }>
						<i className="item-icon item-icon-fa fas fa-redo"/> Rename
					</Link>
				</li>
				<li className={ folder.root ? 'd-none' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxDelete(e, folder) }>
						<i className="item-icon material-icons">delete</i> Delete
					</Link>
				</li>
				<li className={ `divider ${ folder.root ? 'd-none' : '' }` }/>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxSort(e, 'TITLE', folder) }>
						<i className="item-icon material-icons">sort_by_alpha</i> Sort By Name
					</Link>
				</li>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxSort(e, 'TYPE', folder) }>
						<i className="item-icon material-icons">sort</i> Sort By Type
					</Link>
				</li>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxSort(e, 'DATE', folder) }>
						<i className="item-icon material-icons">date_range</i> Sort By Date
					</Link>
				</li>
			</ContextMenu>
		);
	}

	renderUngroupedTourContext = () => {
		const { ungroupedTours } = this.state.ctx;

		return (
			<ContextMenu type="ungroupedTours" refId={ ungroupedTours.refId }>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxNodeDetails(e, ungroupedTours) }>
						<i className="item-icon material-icons">info</i> View Details
					</Link>
				</li>
				<li className="divider"/>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxSort(e, 'TITLE', ungroupedTours) }>
						<i className="item-icon material-icons">sort_by_alpha</i> Sort By Name
					</Link>
				</li>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxSort(e, 'DATE', ungroupedTours) }>
						<i className="item-icon material-icons">date_range</i> Sort By Date
					</Link>
				</li>
			</ContextMenu>
		);
	}

	renderGroupedTourContext = () => {
		const { groupedTours } = this.state.ctx;

		return (
			<ContextMenu type="groupedTours" refId={ groupedTours.refId }>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxNodeDetails(e, groupedTours) }>
						<i className="item-icon material-icons">info</i> View Details
					</Link>
				</li>
				<li className="divider"/>
				<li className={ groupedTours.tree === 'publishTree' ? 'd-block' : 'd-none' }>
					<Link to="#ctx" onClick={ e => this.ctxMoveNodeUp(e, groupedTours) }>
						<i className="item-icon material-icons">arrow_upward</i> Move Up
					</Link>
				</li>
				<li className={ groupedTours.tree === 'publishTree' ? 'd-block' : 'd-none' }>
					<Link to="#ctx" onClick={ e => this.ctxMoveNodeDown(e, groupedTours) }>
						<i className="item-icon material-icons">arrow_downward</i> Move Down
					</Link>
				</li>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxRenameNode(e, groupedTours) }>
						<i className="item-icon item-icon-fa fas fa-redo"/> Rename
					</Link>
				</li>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxDelete(e, groupedTours) }>
						<i className="item-icon material-icons">delete</i> Delete
					</Link>
				</li>
				<li className="divider"/>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxSort(e, 'TITLE', groupedTours) }>
						<i className="item-icon material-icons">sort_by_alpha</i> Sort By Name
					</Link>
				</li>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxSort(e, 'DATE', groupedTours) }>
						<i className="item-icon material-icons">date_range</i> Sort By Date
					</Link>
				</li>
			</ContextMenu>
		);
	}

	renderCtxTourGroups = () => {
		const { tour } = this.state.ctx;

		if(tour.groups && !tour.groups.length) return <div className="d-none"/>;

		return map(tour.groups, group => {
			const dest = {
				node: group,
				tree: group.li_attr.tree,
				parentNodeId: group.li_attr['data-id'],
				parentNodeType: group.li_attr.type,
			}

			return (
				<li key={ group.id }>
					<Link to="#ctx" onClick={ e => this.ctxDirectMove(e, tour, dest) }>
						<i className="item-icon material-icons">arrow_forward</i> { group.li_attr.text }
					</Link>
				</li>
			);
		});
	}

	renderTourContext = () => {
		const { tour, tourGroups } = this.state.ctx;

		return (
			<ContextMenu type="tour" refId={ tour.refId }>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxPlayTour(e, tour) }>
						<i className="item-icon material-icons">play_circle_filled</i> Preview
					</Link>
				</li>
				<li className={ tour.tree === 'tours' ? 'd-block' : 'd-none' }>
					<Link to="#ctx" onClick={ e => this.ctxEditTourProfile(e, tour) }>
						<i className="item-icon item-icon-fa fas fa-edit"/> Edit Profile
					</Link>
				</li>
				<li className={ tour.tree === 'tours' ? 'd-block' : 'd-none' }>
					<Link to="#ctx" onClick={ e => this.ctxEditTour(e, tour) }>
						<i className="item-icon item-icon-fa fas fa-pen-square"/> Edit Tour
					</Link>
				</li>
				<li className={ tour.tree === 'tours' ? 'd-block' : 'd-none' }>
					<Link to="#ctx" onClick={ e => this.ctxAddToPublish(e, tour) }>
						<i className="item-icon material-icons">add_circle</i> Add to Publish
					</Link>
				</li>
				<li className={ tour.tree !== 'tours' ? 'd-block' : 'd-none' }>
					<Link to="#ctx" onClick={ e => this.ctxViewTour(e, tour) }>
						<i className="item-icon item-icon-fa fas fa-external-link-square-alt"/> Page
					</Link>
				</li>
				<li className={ tour.tree !== 'tours' ? 'd-block' : 'd-none' }>
					<Link to="#ctx" onClick={ e => this.ctxHyperlink(e, tour) }>
						<i className="item-icon material-icons">link</i> Hyperlink
					</Link>
				</li>
				<li className={ tour.tree === 'publishTree' ? 'd-block' : 'd-none' }>
					<Link to="#ctx" onClick={ e => this.ctxUnpublishTours(e, tour) }>
						<i className="item-icon material-icons">cloud_off</i> Unpublish
					</Link>
				</li>
				<li className="divider"/>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxNodeDetails(e, tour) }>
						<i className="item-icon material-icons">info</i> View Details
					</Link>
				</li>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxNodeComments(e, tour) }>
						<i className="item-icon item-icon-fa fas fa-comments"/> Comments
					</Link>
				</li>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxTourStats(e, tour) }>
						<i className="item-icon item-icon-fa fas fa-chart-pie"/> Stats
					</Link>
				</li>
				<li className={ tour.tree === 'publishTree' ? 'd-block' : 'd-none' }>
					<Link to="#ctx" onClick={ e => this.ctxTourConversations(e, tour) }>
						<i className="item-icon material-icons">message</i> Messages
					</Link>
				</li>
				<li className="divider"/>
				<li className={ tour.tree === 'tours' ? 'd-block' : 'd-none' }>
					<Link to="#ctx" onClick={ e => this.ctxCopy(e, tour) }>
						<i className="item-icon item-icon-fa fas fa-clone"/> Copy
					</Link>
				</li>
				<li className={ tour.tree !== 'publishTree' ? 'd-block' : 'd-none' }>
					<Link to="#ctx" onClick={ e => this.ctxMove(e, tour) }>
						<i className="item-icon material-icons">move_to_inbox</i> Move
					</Link>
				</li>
				<li className={ tour.tree === 'tours' ? 'd-block' : 'd-none' }>
					<Link to="#ctx" onClick={ e => this.ctxRenameNode(e, tour) }>
						<i className="item-icon item-icon-fa fas fa-redo"/> Rename
					</Link>
				</li>
				<li className={ tour.tree !== 'publishTree' ? 'd-block' : 'd-none' }>
					<Link to="#ctx" onClick={ e => this.ctxDelete(e, tour) }>
						<i className="item-icon material-icons">delete</i> Delete
					</Link>
				</li>
				<li className={ `${ tour.tree === 'publishTree' ? 'd-block' : 'd-none' }` }>
					<Link to="#ctx" onClick={ e => this.ctxMoveTo(e, tour) }>
						<i className="item-icon material-icons">move_to_inbox</i> Move To
					</Link>
					<ContextMenu type="tourGroups" refId={ tourGroups.nodeId } sub>
						<li>
							<Link to="#ctx" onClick={ e => this.ctxNewGroup(e, tour, true) }>
								<i className="item-icon material-icons">add_circle</i>
								Move to New Group
							</Link>
						</li>
						<li className={ `divider ${ tour.groups && !tour.groups.length ? 'd-none' : '' }` }/>
						{ this.renderCtxTourGroups() }
					</ContextMenu>
				</li>
				<li className={ tour.tree === 'publishTree' ? 'd-block' : 'd-none' }>
					<Link to="#ctx" onClick={ e => this.ctxMoveNodeUp(e, tour) }>
						<i className="item-icon material-icons">arrow_upward</i> Move Up
					</Link>
				</li>
				<li className={ tour.tree === 'publishTree' ? 'd-block' : 'd-none' }>
					<Link to="#ctx" onClick={ e => this.ctxMoveNodeDown(e, tour) }>
						<i className="item-icon material-icons">arrow_downward</i> Move Down
					</Link>
				</li>
			</ContextMenu>
		);
	}

	renderLegContext = () => {
		const { leg } = this.state.ctx;

		return (
			<ContextMenu type="leg" refId={ leg.refId }>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxPlayLeg(e, leg) }>
						<i className="item-icon material-icons">play_circle_filled</i> Play Leg
					</Link>
				</li>
				<li className={ !leg.sqPanelActive ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxAddToTour(e, leg) }>
						<i className="item-icon material-icons">add_circle</i> Add to Tour
					</Link>
				</li>
				<li className={ leg.isPurchased ? 'd-none' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxLicense(e, leg) }>
						<i className="item-icon material-icons">{ leg.licensed ? 'remove_circle' : 'check_circle' }</i>
						{ leg.licensed ? 'Turn License Off' : 'Turn License On' }
					</Link>
				</li>
				<li className="divider"/>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxNodeDetails(e, leg) }>
						<i className="item-icon material-icons">info</i> View Details
					</Link>
				</li>
				<li className="divider"/>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxCopy(e, leg) }>
						<i className="item-icon item-icon-fa fas fa-clone"/> Copy
					</Link>
				</li>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxMove(e, leg) }>
						<i className="item-icon material-icons">move_to_inbox</i> Move
					</Link>
				</li>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxRenameNode(e, leg) }>
						<i className="item-icon item-icon-fa fas fa-redo"/> Rename
					</Link>
				</li>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxDelete(e, leg) }>
						<i className="item-icon material-icons">delete</i> Delete
					</Link>
				</li>
			</ContextMenu>
		);
	}

	renderSqMixerPanelContext = () => {
		const { sqMixerPanel } = this.state.ctx;

		return (
			<ContextMenu type="sqMixerPanel" refId={ sqMixerPanel.refId }>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxPlayMixer(e, sqMixerPanel) }>
						<i className="item-icon material-icons">play_circle_filled</i> PreTour All Layers
					</Link>
				</li>
				<li className={ !sqMixerPanel.mixerActive ? 'd-none' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxCloseTourMixer(e, sqMixerPanel) }>
						<i className="item-icon material-icons">close</i> Close Music Editor
					</Link>
				</li>
				<li className="divider"/>
				<li className={ !sqMixerPanel.mixerActive ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxZoomInMixer(e) }>
						<i className="item-icon material-icons">zoom_in</i> Zoom In
					</Link>
				</li>
				<li className={ !sqMixerPanel.mixerActive ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxZoomOutMixer(e) }>
						<i className="item-icon material-icons">zoom_out</i> Zoom Out
					</Link>
				</li>
				<li className="divider"/>
				<li className={ !sqMixerPanel.mixerActive ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxDeleteAllMixerData(e, sqMixerPanel) }>
						<i className="item-icon material-icons">delete</i> Delete All Layers
					</Link>
				</li>
			</ContextMenu>
		);
	}

	renderSqMixerImageTitleContext = () => {
		const { sqMixerImageTitle } = this.state.ctx;

		return (
			<ContextMenu type="sqMixerImageTitle" refId={ sqMixerImageTitle.refId }>
				<li className={ !sqMixerImageTitle.mixerActive ? 'd-none' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxPlayImageMixer(e, sqMixerImageTitle) }>
						<i className="item-icon material-icons">play_circle_filled</i> Play All Images
					</Link>
				</li>
				<li className="divider"/>
				<li className={ !sqMixerImageTitle.mixerActive ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxUploadImageAtBeginning(e) }>
						<i className="item-icon material-icons">border_left</i> Insert Image At Beginning
					</Link>
				</li>
				<li className={ !sqMixerImageTitle.mixerActive ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxUploadImageAtEnd(e) }>
						<i className="item-icon material-icons">border_right</i> Insert Image At End
					</Link>
				</li>
				<li className="divider"/>
				<li className={ !sqMixerImageTitle.mixerActive ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxDeleteAllImageUploads(e, sqMixerImageTitle) }>
						<i className="item-icon material-icons">delete</i> Delete All Images
					</Link>
				</li>
			</ContextMenu>
		);
	}

	renderSqImageItemContext = () => {
		const { sqImageItem } = this.state.ctx;

		return (
			<ContextMenu type="sqImageItem" refId={ sqImageItem.refId }>
				{/* <li>
					<Link to="#ctx" onClick={ e => this.ctxViewImage(e, sqImageItem) }>
						<i className="item-icon material-icons">photo</i> Open Image
					</Link>
				</li> */}
				<li>
					<Link to="#ctx" onClick={ e => this.ctxImageUploadToggleLock(e, sqImageItem) }>
						<i className="item-icon material-icons">
							{ sqImageItem.locked ? 'lock_open' : 'lock' }
						</i>
						{ sqImageItem.locked ? 'Unlock' : 'Lock' } to Current Leg
					</Link>
				</li>
				{/* <li className={ sqImageItem.locked ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxImageUploadBringFront(e, sqImageItem) }>
						<i className="item-icon material-icons">flip_to_front</i> Bring To Front
					</Link>
				</li>
				<li className={ sqImageItem.locked ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxImageUploadFlipBack(e, sqImageItem) }>
						<i className="item-icon material-icons">flip_to_back</i> Flip To Back
					</Link>
				</li> */}
				<li className="divider"/>
				<li className={ sqImageItem.locked ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxImageUploadAlignToPreviousLeg(e, sqImageItem) }>
						<i className="item-icon material-icons icon-rotate-270">vertical_align_top</i> Align to Preceding Leg
					</Link>
				</li>
				<li className={ sqImageItem.locked ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxImageUploadAlignToNextLeg(e, sqImageItem) }>
						<i className="item-icon material-icons icon-rotate-90">vertical_align_top</i> Align to Subsequent Leg
					</Link>
				</li>
				<li className="divider"/>
				<li className={ sqImageItem.locked ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxUploadImageBefore(e, sqImageItem) }>
						<i className="item-icon material-icons">subdirectory_arrow_left</i> Insert Image Before
					</Link>
				</li>
				<li className={ sqImageItem.locked ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxUploadImageAfter(e, sqImageItem) }>
						<i className="item-icon material-icons">subdirectory_arrow_right</i> Insert Image After
					</Link>
				</li>
				<li className="divider"/>
				<li className={ sqImageItem.locked ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxChangeImageUploadColor(e, sqImageItem) }>
						<i className="item-icon material-icons">color_lens</i> Change Color
					</Link>
				</li>
				<li className={ sqImageItem.locked ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxSqImageOverlappingSettings(e, sqImageItem) }>
						<i className="item-icon material-icons">layers</i> Overlapping Settings
					</Link>
				</li>
				<li className="divider"/>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxRenameImageUpload(e, sqImageItem) }>
						<i className="item-icon material-icons">edit</i> Rename Image
					</Link>
				</li>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxDeleteImageUpload(e, sqImageItem) }>
						<i className="item-icon material-icons">delete</i> Delete Image
					</Link>
				</li>
			</ContextMenu>
		);
	}

	renderBgMusicMixerContext = () => {
		const { bgMusicMixer } = this.state.ctx;

		return (
			<ContextMenu type="bgMusicMixer" refId={ bgMusicMixer.refId }>
				<li className={ !bgMusicMixer.mixerActive ? 'd-none' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxPlayMusicMixer(e, bgMusicMixer) }>
						<i className="item-icon material-icons">play_circle_filled</i> Play All Music
					</Link>
				</li>
				<li className="divider"/>
				<li className={ !bgMusicMixer.mixerActive ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxUploadMusicAtBeginning(e) }>
						<i className="item-icon material-icons">border_left</i> Insert Music At Beginning
					</Link>
				</li>
				<li className={ !bgMusicMixer.mixerActive ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxUploadMusicAtEnd(e) }>
						<i className="item-icon material-icons">border_right</i> Insert Music At End
					</Link>
				</li>
				<li className="divider"/>
				<li className={ !bgMusicMixer.mixerActive ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxDeleteAllMusicUploads(e, bgMusicMixer) }>
						<i className="item-icon material-icons">delete</i> Delete All Music
					</Link>
				</li>
			</ContextMenu>
		);
	}

	renderSqMusicItemContext = () => {
		const { musicPlayer } = this.state;
		const { sqMusicItem } = this.state.ctx;

		return (
			<ContextMenu type="sqMusicItem" refId={ sqMusicItem.refId }>
				<li className={ musicPlayer.playing && (musicPlayer.musicId === sqMusicItem.musicId) ? 'd-none' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxPlayMusic(e, sqMusicItem) }>
						<i className="item-icon material-icons">music_note</i> Play Music
					</Link>
				</li>
				<li className={ !musicPlayer.playing || (musicPlayer.musicId !== sqMusicItem.musicId) ? 'd-none' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxStopMusic(e, sqMusicItem) }>
						<i className="item-icon material-icons">cancel</i> Stop Music
					</Link>
				</li>
				<li className="divider"/>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxMusicUploadToggleLock(e, sqMusicItem) }>
						<i className="item-icon material-icons">
							{ sqMusicItem.locked ? 'lock_open' : 'lock' }
						</i>
						{ sqMusicItem.locked ? 'Unlock' : 'Lock' } to Current Leg
					</Link>
				</li>
				{/* <li className="divider"/> */}
				<li className={ sqMusicItem.locked ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxResetUpload(e, sqMusicItem) }>
						<i className="item-icon material-icons">settings_ethernet</i> Reset Music
					</Link>
				</li>
				{/* <li className={ sqMusicItem.locked ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxUploadBringFront(e, sqMusicItem) }>
						<i className="item-icon material-icons">flip_to_front</i> Bring To Front
					</Link>
				</li>
				<li className={ sqMusicItem.locked ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxUploadFlipBack(e, sqMusicItem) }>
						<i className="item-icon material-icons">flip_to_back</i> Flip To Back
					</Link>
				</li> */}
				<li className="divider"/>
				<li className={ sqMusicItem.locked ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxUploadAlignToPreviousLeg(e, sqMusicItem) }>
						<i className="item-icon material-icons icon-rotate-270">vertical_align_top</i> Align to Preceding Leg
					</Link>
				</li>
				<li className={ sqMusicItem.locked ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxUploadAlignToNextLeg(e, sqMusicItem) }>
						<i className="item-icon material-icons icon-rotate-90">vertical_align_top</i> Align to Subsequent Leg
					</Link>
				</li>
				<li className="divider"/>
				<li className={ sqMusicItem.locked ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxUploadMusicBefore(e, sqMusicItem) }>
						<i className="item-icon material-icons">subdirectory_arrow_left</i> Insert Music Before
					</Link>
				</li>
				<li className={ sqMusicItem.locked ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxUploadMusicAfter(e, sqMusicItem) }>
						<i className="item-icon material-icons">subdirectory_arrow_right</i> Insert Music After
					</Link>
				</li>
				<li className="divider"/>
				<li className={ sqMusicItem.locked ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxChangeUploadColor(e, sqMusicItem) }>
						<i className="item-icon material-icons">color_lens</i> Change Color
					</Link>
				</li>
				<li className={ sqMusicItem.locked ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ () => {} }>
						<i className="item-icon material-icons">info</i> Music Info
					</Link>
				</li>
				<li className="divider"/>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxRenameUpload(e, sqMusicItem) }>
						<i className="item-icon material-icons">edit</i> Rename Music
					</Link>
				</li>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxDeleteUpload(e, sqMusicItem) }>
						<i className="item-icon material-icons">delete</i> Delete Music
					</Link>
				</li>
			</ContextMenu>
		);
	}

	renderSqTourContext = () => {
		const { sqTour } = this.state.ctx;

		return (
			<ContextMenu type="sqTour" refId={ sqTour.refId }>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxPlayMixer(e, sqTour) }>
						<i className="item-icon material-icons">play_circle_filled</i> { sqTour.mixerActive ? 'Audio Mixer' : 'PreTour' }
					</Link>
				</li>
				<li className="divider"/>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxSqSaveGroup(e, sqTour) }>
						<i className="item-icon material-icons">folder</i> Add New Group
					</Link>
				</li>
				<li className="divider"/>
				<li className={ !sqTour.mixerActive ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxSqHeaderUploadVideo(e, sqTour) }>
						<i className="item-icon material-icons">delete</i> Upload Header Video
					</Link>
				</li>
				<li className={ (!sqTour.mixerActive || !sqTour.headerMediaId) ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxSqDeleteHeaderVideo(e, sqTour) }>
						<i className="item-icon material-icons">publish</i> Delete Header Video
					</Link>
				</li>
				<li className={ !sqTour.mixerActive ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxSqFooterUploadVideo(e, sqTour) }>
						<i className="item-icon material-icons">delete</i> Upload Footer Video
					</Link>
				</li>
				<li className={ (!sqTour.mixerActive || !sqTour.footerMediaId) ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxSqDeleteFooterVideo(e, sqTour) }>
						<i className="item-icon material-icons">publish</i> Delete Footer Video
					</Link>
				</li>
				<li className="divider"/>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxNodeDetails(e, sqTour) }>
						<i className="item-icon material-icons">info</i> View Details
					</Link>
				</li>
			</ContextMenu>
		);
	}

	renderSqLegContext = () => {
		const { sqLeg } = this.state.ctx;

		return (
			<ContextMenu type="sqLeg" refId={ sqLeg.refId }>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxOpenNextLegs(e, sqLeg) }>
						<i className="item-icon material-icons">play_circle_filled</i> { sqLeg.mixerActive ? 'Mixer From Here' : 'PreTour From Here' }
					</Link>
					{ this.renderCtxNextLegs() }
				</li>
				<li className="divider"/>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxNodeDetails(e, sqLeg) }>
						<i className="item-icon material-icons">info</i> View Details
					</Link>
				</li>
				<li className="divider"/>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxRenameSqListItem(e, sqLeg.refId) }>
						<i className="item-icon material-icons">edit</i> Rename
					</Link>
				</li>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxRemoveSqListItem(e, sqLeg.refId) }>
						<i className="item-icon material-icons">delete</i> Remove
					</Link>
				</li>
			</ContextMenu>
		);
	}

	renderCtxNextLegs = () => {
		const { sqLeg, sqNextLegs } = this.state.ctx;

		if(!sqLeg.nextLegs.length) return <div className="d-none"/>;

		return (
			<ContextMenu type="sqNextLegs" refId={ sqNextLegs.refId } sub>
				<li className="disabled">
					<Link to="#ctx">To</Link>
				</li>
				{ this.renderCtxNextLegItems() }
			</ContextMenu>
		);
	}

	renderCtxNextLegItems = () => {
		const { sqLeg } = this.state.ctx;

		return map(sqLeg.nextLegs, leg => (
			<li key={ leg.objectId }>
				<Link to="#ctx" onClick={ e => this.ctxPlayFromHere(e, sqLeg, sqLeg.index, leg.index) }>
					<i className="item-icon material-icons">play_circle_filled</i> { leg.text }
				</Link>
			</li>
		));
	}

	renderSqMixerHeaderContext = () => {
		const { sqMixerHeader } = this.state.ctx;

		return (
			<ContextMenu type="sqMixerHeader" refId={ sqMixerHeader.refId }>
				<li className={ !sqMixerHeader.mediaId ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxSqHeaderPreviewVideo(e, sqMixerHeader) }>
						<i className="item-icon material-icons">play_arrow</i> Header Preview
					</Link>
				</li>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxSqHeaderUploadVideo(e, sqMixerHeader) }>
						<i className="item-icon material-icons">delete</i> Upload Header Video
					</Link>
				</li>
				<li className="divider"/>
				<li className={ !sqMixerHeader.mediaId ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ () => {} }>
						<i className="item-icon material-icons">info_circle</i> View Details
					</Link>
				</li>
				<li className="divider"/>
				<li className={ !sqMixerHeader.mediaId ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxSqRenameHeaderVideo(e, sqMixerHeader) }>
						<i className="item-icon material-icons">edit</i> Rename Header Video
					</Link>
				</li>
				<li className={ !sqMixerHeader.mediaId ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxSqDeleteHeaderVideo(e, sqMixerHeader) }>
						<i className="item-icon material-icons">publish</i> Delete Header Video
					</Link>
				</li>
			</ContextMenu>
		);
	}
	
	renderSqMixerFooterContext = () => {
		const { sqMixerFooter } = this.state.ctx;

		return (
			<ContextMenu type="sqMixerFooter" refId={ sqMixerFooter.refId }>
				<li className={ !sqMixerFooter.mediaId ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxSqFooterPreviewVideo(e, sqMixerFooter) }>
						<i className="item-icon material-icons">play_arrow</i> Footer Preview
					</Link>
				</li>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxSqFooterUploadVideo(e, sqMixerFooter) }>
						<i className="item-icon material-icons">delete</i> Upload Footer Video
					</Link>
				</li>
				<li className="divider"/>
				<li className={ !sqMixerFooter.mediaId ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ () => {} }>
						<i className="item-icon material-icons">info_circle</i> View Details
					</Link>
				</li>
				<li className="divider"/>
				<li className={ !sqMixerFooter.mediaId ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxSqRenameFooterVideo(e, sqMixerFooter) }>
						<i className="item-icon material-icons">edit</i> Rename Footer Video
					</Link>
				</li>
				<li className={ !sqMixerFooter.mediaId ? 'disabled' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxSqDeleteFooterVideo(e, sqMixerFooter) }>
						<i className="item-icon material-icons">publish</i> Delete Footer Video
					</Link>
				</li>
			</ContextMenu>
		);
	}
	
	renderSqGroupContext = () => {
		const { sqGroupItem } = this.state.ctx;

		return (
			<ContextMenu type="sqGroupItem" refId={ sqGroupItem.refId }>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxPlayGroupMixer(e, sqGroupItem) }>
						<i className="item-icon material-icons">play_circle_filled</i> Audio Mixer
					</Link>
				</li>
				<li className="divider"/>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxSqSaveGroup(e, sqGroupItem) }>
						<i className="item-icon item-icon-fa fas fa-redo"/> Rename Group
					</Link>
				</li>
				<li className="divider"/>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxSqRemoveGroup(e, sqGroupItem) }>
						<i className="item-icon material-icons">close</i> Remove Group
					</Link>
				</li>
				<li className="divider"/>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxSqDeleteGroup(e, sqGroupItem) }>
						<i className="item-icon material-icons">delete</i> Delete Group
					</Link>
				</li>
			</ContextMenu>
		);
	}

	renderPublishTreeContext = () => {
		const { publishTree } = this.state.ctx;

		return (
			<ContextMenu type="publishTree" refId={ publishTree.refId }>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxNewGroup(e, publishTree) }>
						<i className="item-icon material-icons">add_circle</i> New Group
					</Link>
				</li>
			</ContextMenu>
		);
	}
	
	renderTreeTitleContext = () => {
		const { treeTitle } = this.state.ctx;

		return (
			<ContextMenu type="treeTitle" refId={ treeTitle.refId }>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxNewFolder(e, treeTitle) }>
						<i className="item-icon material-icons">create_new_folder</i>
						New Folder
					</Link>
				</li>
				<li className={ treeTitle.tree !== 'legs' ? 'd-none' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxNewLeg(e, treeTitle) }>
						<i className="item-icon material-icons">extension</i>
						New Leg
					</Link>
				</li>
				<li className={ treeTitle.tree !== 'tours' ? 'd-none' : '' }>
					<Link to="#ctx" onClick={ e => this.ctxNewTour(e, treeTitle) }>
						<span className="item-icon item-icon-img">
							<img src="assets/icons/tree/icon-tour.png" className="img-responsive" alt=""/>
						</span>
						New Tour
					</Link>
				</li>
				<li className="divider"/>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxNodeDetails(e, treeTitle) }>
						<i className="item-icon material-icons">info</i> View Details
					</Link>
				</li>
				<li className="divider"/>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxSort(e, 'TITLE', treeTitle, true) }>
						<i className="item-icon material-icons">sort_by_alpha</i> Sort By Name
					</Link>
				</li>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxSort(e, 'TYPE', treeTitle, true) }>
						<i className="item-icon material-icons">sort</i> Sort By Type
					</Link>
				</li>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxSort(e, 'DATE', treeTitle, true) }>
						<i className="item-icon material-icons">date_range</i> Sort By Date
					</Link>
				</li>
			</ContextMenu>
		);
	}

	renderLegsTreeActionPanel = (excludeNodeTypes, rootNodeTypes, allowed) => {
		const { actionPanel } = this.state;

		if(!actionPanel.active || actionPanel.tree !== 'legs') return <div className="d-none"/>;

		return this.renderActionPanel(excludeNodeTypes, rootNodeTypes, allowed);
	}

	renderToursTreeActionPanel = (excludeNodeTypes, rootNodeTypes, allowed) => {
		const { actionPanel } = this.state;

		if(!actionPanel.active || actionPanel.tree !== 'tours') return <div className="d-none"/>;

		return this.renderActionPanel(excludeNodeTypes, rootNodeTypes, allowed);
	}

	renderPublishTreeActionPanel = (excludeNodeTypes, rootNodeTypes, allowed) => {
		const { actionPanel } = this.state;

		if(!actionPanel.active || actionPanel.tree !== 'publishTree') return <div className="d-none"/>;

		return this.renderActionPanel(excludeNodeTypes, rootNodeTypes, allowed);
	}

	renderActionPanel = (excludeNodeTypes, rootNodeTypes, allowed) => {
		const { actionPanel } = this.state;
		const { actionPanelNodes, actionPanelNodesData } = this.props;

		return (
			<ActionPanel
				allowed={ allowed }
				tree={ actionPanel.tree }
				type={ actionPanel.type }
				nodes={ actionPanelNodes }
				refId={ actionPanel.refId }
				nodeId={ actionPanel.nodeId }
				active={ actionPanel.active }
				copyNode={ this.treeCopyNode }
				moveNode={ this.treeMoveNode }
				rootNodeTypes={ rootNodeTypes }
				nodeType={ actionPanel.nodeType }
				hidePanel={ this.hideActionPanel }
				nodesData={ actionPanelNodesData }
				excludeNodeTypes={ excludeNodeTypes }
				getNodes={ this.getActionPanelNodes }
				publishNodes={ this.publishNodesToGroup }
				removeNodes={ this.removeActionPanelNodes }/>
		);
	}

	renderTourSequencePanel = () => {
		const { sequencePanel } = this.state;

		if(!sequencePanel.active) return <div className="d-none"/>;

		return (
			<SequencePanel
				playTour={ this.playTour }
				addMedia={ this.addMedia }
				tour={ sequencePanel.tour }
				ref={ this.sequencePanelRef }
				active={ sequencePanel.active }
				previewMedia={ this.previewMedia }
				handleContext={ this.handleContext }
				hidePanel={ this.hideSequencePanel }
				addAttachment={ this.addAttachment }
				disableMediaPanel={ this.disableMediaPanel }
				updatePlayerState={ this.updatePlayerState }
				handleBgMusicMixer={ this.handleBgMusicMixer }
				checkSqPanelIsBusy={ this.checkSqPanelIsBusy }
				checkSqPanelIsUpdated={ this.checkSqPanelIsUpdated }
				updateMusicPlayerState={ this.updateMusicPlayerState }/>
		);
	}

	renderPublishTreePanel = () => {
		const { publishTreePanel: { active, refId, nodeId, tourId, title, tree } } = this.state;

		if(!active) return <div className="d-none"/>;

		return (
			<PublishTreePanel
				type='publish'
				tree={ tree }
				title={ title }
				refId={ refId }
				nodeId={ nodeId }
				active={ active }
				tourId={ tourId }
				baseNodeType={ 30 }
				rootNodeTypes={ [30, 40] }
				ref={ this.publishTreeRef }
				handleContext={ this.handleContext }
				hidePanel={ this.hidePublishTreePanel }/>
		);
	}

	renderLegLicenseForm = () => {
		const priceInputStyle = {
			height: '47px'
		}

		const { loadingLicense, updatingLicense, updatingLicensePrice, licensingForm, licensingFormErrors } = this.state;

		if(loadingLicense) return <Preloader loading={ loadingLicense } center/>;

		// const { calculation } = licensingForm;

		const currencies = this.getCurrencyOptions();
		const limitClass = size(licensingForm.description) > this.licensingDescriptionMaxWords ? 'danger' : 'muted';

		return (
			<Fragment>
				<div className="uk-modal-header">
					<h3 className="uk-modal-title modalTitle">Leg Licensing</h3>
				</div>
				<div className="uk-modal-body">
					<div className="md-price-group m-b-20 hasValidation">
						<div className="uk-grid uk-grid-small">
							<div className="uk-width-1-2">
								<div className="md-input-wrapper">
									<Selectize
										name="currency"
										data={ currencies }
										id="txtLicenseCurrency"
										value={ licensingForm.currency }
										onChange={ this.handleLicensingCurrency }/>
								</div>
							</div>
							<div className="uk-width-1-2">
								<Input
									name="price"
									title="Price"
									type="number"
									id="txtLicensePrice"
									style={ priceInputStyle }
									value={ licensingForm.price }
									onChange={ this.handleLicensingPrice }/>
							</div>
						</div>
						{/* <div className="uk-grid uk-grid-small">
							<div className="uk-width-1-3">
								<Input
									disabled
									type="text"
									name="earning"
									title="Earning"
									id="txtLicensePriceEarning"
									value={ !updatingLicensePrice ? calculation.earning : '...' }
									onChange={ () => {} }/>
							</div>
							<div className="uk-width-1-3">
								<Input
									disabled
									type="text"
									name="platformCommission"
									title="Platform Commission"
									id="txtLicensePricePlatformCommission"
									value={ !updatingLicensePrice ? calculation.platformCommission : '...' }
									onChange={ () => {} }/>
							</div>
							<div className="uk-width-1-3">
								<Input
									disabled
									type="text"
									name="paymentGatewayFees"
									title="Payment Gateway Fees"
									id="txtLicensePricePaymentGatewayFees"
									value={ !updatingLicensePrice ? calculation.paymentGatewayFees : '...' }
									onChange={ () => {} }/>
							</div>
						</div> */}
					</div>
					
					<div className="form-group">
						<Input
							textarea
							title="Description"
							name="description"
							id="txtLicenseDescription"
							value={ licensingForm.description }
							onChange={ this.handleLicensingDescription }/>
						<div className="text-right">
							<p className="uk-text-small text-muted"><span className={ `text-${ limitClass }` }>{ licensingForm.description.length }</span>/{ this.licensingDescriptionMaxWords }</p>
						</div>
					</div>

					<div className="form-group">
						<label htmlFor="txtLicensingExclusive" className="form-check-label">
							<Toggle
								type="checkbox"
								value="exclusive"
								name="exclusive"
								id="txtLicensingExclusive"
								checked={ licensingForm.exclusive }
								onChange={ this.handleLicensingExclusiveToggle }
								checkboxClass="icheckbox"
								increaseArea="0%"/>
							{/* <span className="checkbox-icon"/> */}
							<span className="form-check-text m-l-5">Exclusive License</span>
						</label>
					</div>

					<Alerts show={ licensingForm.exclusive } data={['This leg is marked exclusive and can only be sold once.']} type="info"/>
					<Alerts show={ licensingFormErrors.length > 0 } data={ licensingFormErrors }/>
				</div>
				<div className="uk-modal-footer">
					<div className="footer-preloader m-r-10">
						<Preloader size={ 20 } loading={ updatingLicense } relative minimal/>
					</div>
					<Link to="#closeModal" className={ `md-btn md-btn-flat md-btn-flat-default md-btn-wave waves-effect waves-button uk-modal-close ${ updatingLicense || updatingLicensePrice ? 'disabled' : '' }` }>
						Cancel
					</Link>
					<button type="submit" className={ `md-btn md-btn-flat md-btn-flat-success md-btn-wave waves-effect waves-button ${ updatingLicense || updatingLicensePrice ? 'disabled' : '' }` } onClick={ this.updateLicense }>
						Save
					</button>
				</div>
			</Fragment>
		);
	}

	renderPlayer = () => {
		const { player, audioDevice } = this.state;

		if(!player.render) return '';

		if(player.type === 'media') {
			return (
				<TourMediaPlayer
					active={ player.active }
					ref={ this.mediaPlayerRef }
					updatePlayerState={ this.updatePlayerState }/>
			);
		}

		return (
			<ToursPlayer
				layout="default"
				active={ player.active }
				saveLeg={ this.saveLeg }
				ref={ this.toursPanelRef }
				audioDevice={ audioDevice }
				showOverlay={ this.showOverlay }
				hideOverlay={ this.hideOverlay }
				handleNewLeg={ this.handleNewLeg }
				updateLegDetails={ this.updateLegDetails }
				updatePlayerState={ this.updatePlayerState }/>
		);
	}

	renderOverlayContents = () => {
		const { sequencePanel, player } = this.state;

		if(sequencePanel.active && sequencePanel.busy) {
			return <Preloader loading={ sequencePanel.busy } center minimal/>;
		} else if(player.active && player.busy) {
			return (
				<div className="md-btn-cancel">
					<Link to="#mytours" className="btnCancel" onClick={ this.onCancel }>
						<i className="material-icons">close</i>
					</Link>
				</div>
			);
		}

		return '';
	}

	render() {
		const { categories, userSettings } = this.props;

		const { urlTourId, audioDevice } = this.state;
		
		const { hyperlink, fetchingTourHyperlink } = this.state;

		const { sequencePanel, actionPanel, fetchingTourProfile } = this.state;
		
		const { addingNewFolder, newFolderForm, newFolderFormErrors } = this.state;
		const { addingNewGroup, newGroupForm, newGroupFormErrors } = this.state;
		const { addingNewLeg } = this.state;
		
		const { renamingNode, renameNodeForm, renameNodeFormErrors } = this.state;
		
		const { nodeDetails, nodeComments } = this.state;
		
		const { legMusic } = this.state;
		
		const { tourStats } = this.state;
		
		const { notif } = this.state;
		
		const { loadingBgMusicMixer, updatingBgMusicMixer, bgMusicMixer } = this.state;
		
		const legTreesActionPanelActive = (((actionPanel.tree === 'legsLicensed') || (actionPanel.tree === 'legs')) && actionPanel.active) ? 'panel-active panel-active-action' : '';
		const tourTreeActionPanelActive = (actionPanel.tree === 'tours' && actionPanel.active) ? 'panel-active panel-active-action' : '';
		// const groupTreesActionPanelActive = (((actionPanel.tree === 'ungroupedTours') || (actionPanel.tree === 'groupedTours')) && actionPanel.active) ? 'panel-active panel-active-action' : '';
		
		const tourTreeSequencePanelActive = sequencePanel.active ? 'panel-active panel-active-sequence' : '';

		return (
			<AdminLayout view="mytours" ref={ this.adminLayoutRef }>
				<FullHeight className="uk-grid uk-grid-small" id="myTours">
					<div className="uk-width-large-1-2">
						<div className={ `tours-container` } id="cntTours" data-tours-panel>
							<div className="md-overlay active" ref={ this.overlayRef }>
								{ this.renderOverlayContents() }
							</div>
							<div className="md-card">
								<Scrollbar className="md-card-content" id="toursTreeCardContent">
									<Scrollbar className="card-content-outer" ref={ this.toursTreesOuterRef }>
										<div className="card-content-inner" id="treeResizableParent">
											<div className={ `tour-container cntTour ${ legTreesActionPanelActive }` } data-cnt-tree data-resizable>
												<div className="tour-inner">
													<div className="tour-header header-loaded tourHeader">
														<LegsFilter 
															trees={ ['legs'] }
															ref={ this.legsFilterRef }
															filterNodes={ this.getNodes }
															userSettings={ userSettings }
															newFolder={ this.handleNewFolder }
															setUserSetting={ this.setUserSetting }
															updateNotifState={ this.updateNotifState }
															markAllRead={ this.markAllConversationsRead }/>
													</div>
													<div className={ `tour-content tourContent ${ !notif.legs ? 'notif-disable' : '' }` }>
														<TreeTitle
															tree="legs"
															type="legsTitle"
															title="My Library"
															baseNodeType={ 10 }
															handleContext={ this.handleContext }/>
														<Scrollbar className="tour-lists">
															<LegsTree
																baseNodeType={ 10 }
																rootNodeTypes={ [20] }
																ref={ this.legsTreeRef }
																playTour={ this.playTour }
																handleContext={ this.handleContext }
																handleNewFolder={ this.handleNewFolder }
																handleNewLegAddress={ this.handleNewLegAddress }/>
														</Scrollbar>
													</div>
													{ this.renderLegsTreeActionPanel([60], [20], ['legs']) }
												</div>
											</div>
											<div className={ `tour-container cntTour ${ tourTreeActionPanelActive } ${ tourTreeSequencePanelActive }` } data-cnt-tree data-resizable>
												<div className="tour-inner">
													<div className="tour-header header-loaded tourHeader">
														<ToursFilter
															trees={ ['tours'] }
															ref={ this.toursFilterRef }
															filterNodes={ this.getNodes }
															newFolder={ this.handleNewFolder }
															showPublishTreePanel={ this.showPublishTreePanel }/>
													</div>
													<div className="tour-content tourContent">
														<TreeTitle
															icon="tab"
															tree="tours"
															title="My Tours"
															type="toursTitle"
															baseNodeType={ 20 }
															handleContext={ this.handleContext }/>
														<Scrollbar className="tour-lists">
															<ToursTree
																baseNodeType={ 20 }
																rootNodeTypes={ [20] }
																urlTourId={ urlTourId }
																playTour={ this.playTour }
																handleTour={ this.handleTour }
																handleContext={ this.handleContext }
																handleSequencePanel={ this.handleSequencePanel }
																ref={ this.toursTreeRef }/>
														</Scrollbar>
													</div>

													{ this.renderPublishTreePanel() }
													{ this.renderToursTreeActionPanel([50], [20], ['tours']) }
													{ this.renderPublishTreeActionPanel([50], [30, 40], ['publishTree']) }
												</div>
											</div>
											{ this.renderTourSequencePanel() }
										</div>
									</Scrollbar>
								</Scrollbar>
							</div>
						</div>
					</div>
					<div className="uk-width-large-1-2 uk-position-relative">
						<div className="md-card md-graphic-panel-card" id="mdGraphicPanelCard">
							{ this.renderPlayer() }
						</div>
					</div>
				</FullHeight>
				<div className="contexts-container">
					{ this.renderFolderContext() }
					{ this.renderUngroupedTourContext() }
					{ this.renderGroupedTourContext() }
					{ this.renderTourContext() }
					{ this.renderLegContext() }

					{ this.renderSqMixerPanelContext() }
					
					{ this.renderSqMixerImageTitleContext() }
					{ this.renderSqImageItemContext() }

					{ this.renderBgMusicMixerContext() }
					{ this.renderSqMusicItemContext() }
					
					{ this.renderSqTourContext() }
					{ this.renderSqLegContext() }
					
					{ this.renderSqMixerHeaderContext() }
					{ this.renderSqMixerFooterContext() }
					
					{ this.renderSqGroupContext() }
					
					{ this.renderPublishTreeContext() }
					
					{ this.renderTreeTitleContext() }
				</div>
				<div className="modals-container" data-modals-holder>
					<Modal
						popup="newFolder"
						ref={ this.newFolderModalRef }
						onHide={ this.onNewFolderModalHide }>
						<form>
							<div className="uk-modal-dialog">
								<div className="uk-modal-header">
									<h3 className="uk-modal-title">
										{ newFolderForm.licensed ? 'New Licensed Folder' : 'New Folder' }
									</h3>
								</div>
								<div className="uk-modal-body">
									<Input
										title="Folder Name"
										name="newFolderName"
										id="txtNewFolderName"
										value={ newFolderForm.title }
										onChange={ this.handleTxtNewFolder }/>

									<Alerts show={ newFolderFormErrors.length > 0 } data={ newFolderFormErrors }/>
								</div>
								<div className="uk-modal-footer">
									<div className="footer-preloader m-r-10">
										<Preloader size={ 20 } loading={ addingNewFolder } relative minimal/>
									</div>
									<Link to="#closeModal" className={ `md-btn md-btn-flat md-btn-flat-default md-btn-wave waves-effect waves-button uk-modal-close ${ addingNewFolder ? 'disabled' : '' }` }>
										Cancel
									</Link>
									<button type="submit" className={ `md-btn md-btn-flat md-btn-flat-success md-btn-wave waves-effect waves-button ${ addingNewFolder ? 'disabled' : '' }` } onClick={ this.addNewFolder }>
										Save
									</button>
								</div>
							</div>
						</form>
					</Modal>
					
					<Modal
						popup="newGroup"
						ref={ this.newGroupModalRef }
						onHide={ this.onNewGroupModalHide }>
						<form>
							<div className="uk-modal-dialog">
								<div className="uk-modal-header">
									<h3 className="uk-modal-title">New Group</h3>
								</div>
								<div className="uk-modal-body">
									<Input
										title="Group Name"
										name="newGroupName"
										id="txtNewGroupName"
										value={ newGroupForm.title }
										onChange={ this.handleTxtNewGroup }/>

									<Alerts show={ newGroupFormErrors.length > 0 } data={ newGroupFormErrors }/>
								</div>
								<div className="uk-modal-footer">
									<div className="footer-preloader m-r-10">
										<Preloader size={ 20 } loading={ addingNewGroup } relative minimal/>
									</div>
									<Link to="#closeModal" className={ `md-btn md-btn-flat md-btn-flat-default md-btn-wave waves-effect waves-button uk-modal-close ${ addingNewGroup ? 'disabled' : '' }` }>
										Cancel
									</Link>
									<button type="submit" className={ `md-btn md-btn-flat md-btn-flat-success md-btn-wave waves-effect waves-button ${ addingNewGroup ? 'disabled' : '' }` } onClick={ this.addNewGroup }>
										Save
									</button>
								</div>
							</div>
						</form>
					</Modal>

					<Modal
						popup="rename" 
						ref={ this.renameNodeModalRef }
						onShow={ this.onRenameNodeModalShow }
						onHide={ this.onRenameNodeModalHide }>
						<form ref={ this.renameNodeFormRef }>
							<div className="uk-modal-dialog">
								<div className="uk-modal-header">
									<h3 className="uk-modal-title">Rename <span className="text-primary">{ renameNodeForm.existingTitle }</span> to:</h3>
								</div>
								<div className="uk-modal-body">
									<Input
										title="Rename"
										name="title"
										id="txtRenameTitle"
										value={ renameNodeForm.title }
										onChange={ this.handleTxtRenameNode }/>

									<Alerts show={ renameNodeFormErrors.length > 0 } data={ renameNodeFormErrors }/>
								</div>
								<div className="uk-modal-footer">
									<div className="footer-preloader m-r-10">
										<Preloader size={ 20 } loading={ renamingNode } relative minimal/>
									</div>
									<Link to="#closeModal" className={ `md-btn md-btn-flat md-btn-flat-default md-btn-wave waves-effect waves-button uk-modal-close ${ renamingNode ? 'disabled' : '' }` }>
										Cancel
									</Link>
									<button type="submit" className={ `md-btn md-btn-flat md-btn-flat-success md-btn-wave waves-effect waves-button ${ renamingNode ? 'disabled' : '' }` } onClick={ this.renameNode }>
										Save
									</button>
								</div>
							</div>
						</form>
					</Modal>
					
					<Modal
						popup="accountNotif"
						onShow={ this.onAccountModalShow }
						onHide={ this.onAccountModalHide }
						ref={ this.accountNotifModalRef }>
						<div className="uk-modal-dialog">
							<div className="uk-modal-header">
								<h3 className="uk-modal-title">Connect Bank Account</h3>
							</div>
							<div className="uk-modal-body">
								<p>Adding license requires banking details. Please first add banking details before proceeding further.</p>
							</div>
							<div className="uk-modal-footer">
								<Link to="#closeModal" className={ `md-btn md-btn-flat md-btn-flat-default md-btn-wave waves-effect waves-button` } onClick={ e => this.onAccountSetupClick(e) }>
									Close
								</Link>
								<Link to="/profile/edit" className={ `md-btn md-btn-flat md-btn-flat-success md-btn-wave waves-effect waves-button` } onClick={ e => this.onAccountSetupClick(e, true) }>
									Setup
								</Link>
							</div>
						</div>
					</Modal>

					<Modal
						popup="leg-licensing"
						ref={ this.licensingModalRef }
						onShow={ this.onLicensingModalShow }
						onHide={ this.onLicensingModalHide }>
						<form>
							<div className="uk-modal-dialog">
								{ this.renderLegLicenseForm() }
							</div>
						</form>
					</Modal>

					<ModalHyperlink
						hyperlink={ hyperlink }
						ref={ this.hyperlinkModalRef }
						loading={ fetchingTourHyperlink }
						sendHyperlink={ this.sendHyperlink }/>
					
					<ModalTour
						ref={ this.tourModalRef }
						categories={ categories }
						saveTour={ this.saveTour }
						loading={ fetchingTourProfile }
						onHide={ this.onTourModalHide }
						uploader={ this.globalPhotoUploaderRef }
						addAttachment={ this.addAttachment }
						handleSequencePanel={ this.handleSequencePanel }/>
					
					<ModalNodeDetails
						id={ nodeDetails.id }
						type={ nodeDetails.type }
						data={ nodeDetails.data }
						tree={ nodeDetails.tree }
						loading={ nodeDetails.loading }
						ref={ this.nodeDetailsModalRef }
						onShow={ this.onNodeDetailsModalShow }
						onHide={ this.onNodeDetailsModalHide }/>

					<ModalTourStats
						onShow={ () => {} }
						onHide={ () => {} }
						id={ tourStats.tourId }
						ref={ this.tourStatsModalRef }/>
					
					<ModalComments 
						onShow={ () => {} }
						onHide={ () => {} }
						id={ nodeComments.id }
						ref={ this.nodeCommentsModalRef }/>

					<NewLegModal
						loading={ addingNewLeg }
						categories={ categories }
						audioDevice={ audioDevice }
						ref={ this.newLegModalRef }
						recordTour={ this.recordTour }
						onShow={ this.onNewLegModalShow }
						onHide={ this.onNewLegModalHide }
						cancelNewLeg={ this.cancelNewLeg }
						uploader={ this.globalPhotoUploaderRef }
						addAttachment={ this.addAttachment }
						getLastLegInfo={ this.getLastLegInfo }
						updateLegDetails={ this.updateLegDetails }/>

					<ModalLegMusic 
						data={ legMusic }
						save={ this.setLegMusic }
						ref={ this.legMusicModalRef }
						onShow={ this.onLegMusicModalShow }
						onHide={ this.onLegMusicModalHide }
						uploader={ this.globalAudioUploaderRef }
						updateLegMusicState={ this.updateLegMusicState }/>

					<ModalBgMusicMixer
						data={ bgMusicMixer }
						busy={ updatingBgMusicMixer }
						loading={ loadingBgMusicMixer }
						onShow={ this.onBgMusicMixerModalShow }
						onHide={ this.onBgMusicMixerModalHide }
						ref={ this.bgMusicMixerModalRef }/>
				</div>
				
				<div className="md-jstree-draggable" id="mdJstreeDraggable"/>
				
				<div className="global-uploaders d-none">
					<input type="file" accept="image/*" name={ `global_photo_uploader_${ uuid() }` } ref={ this.globalPhotoUploaderRef }/>
					<input type="file" accept="audio/*" name={ `global_audio_uploader_${ uuid() }` } ref={ this.globalAudioUploaderRef }/>
				</div>

				<div className="jstree-renamers-container" id="cntInlineRename" style={{ display: 'none' }}>
					<div className="tree-renamers-inner">
						<form className="formRename">
							<input type="text" className="form-control txtTitle"/>
						</form>
					</div>
				</div>
			</AdminLayout>
		);
	}
};

/* ----------  Prop Types  ---------- */

ViewMyTours.defaultProps = {
	actionPanelNodes: [],
	actionPanelNodesData: {},

	userSettings: {},
}

ViewMyTours.propTypes = {
	getLanguages: PropTypes.func.isRequired,
	getCategories: PropTypes.func.isRequired,
	getCurrencies: PropTypes.func.isRequired,
	
	getTourSummary: PropTypes.func.isRequired,
	
	getUserSettings: PropTypes.func.isRequired,
	setUserSetting: PropTypes.func.isRequired,
	
	addAttachment: PropTypes.func.isRequired,
	
	addMedia: PropTypes.func.isRequired,

	getLegMusic: PropTypes.func.isRequired,
	setLegMusic: PropTypes.func.isRequired,

	profile: PropTypes.shape().isRequired,
	history: PropTypes.shape().isRequired,

	categories: PropTypes.objectOf(PropTypes.object).isRequired,
	currencies: PropTypes.objectOf(PropTypes.object).isRequired,

	actionPanelNodes: PropTypes.arrayOf(PropTypes.object),
	actionPanelNodesData: PropTypes.objectOf(PropTypes.object),
	
	userSettings: PropTypes.shape(),
	
	getUnreadCount: PropTypes.func.isRequired,
	markAllConversationsRead: PropTypes.func.isRequired,
}

/* ----------  Redux Scripts  ---------- */

const mapStateToProps = state => ({
	
	profile: state.user.profile,
	userSettings: state.user.settings,

	categories: state.categories,
	currencies: state.currencies,

	actionPanelNodes: state.mytours.trees.actionPanel.nodes,
	actionPanelNodesData: state.mytours.trees.actionPanel.nodesData,
});

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		getLanguages: GetLanguages,
		getCategories: GetCategories,
		getCurrencies: GetCurrencies,
		
		getTourSummary: GetTourSummary,
		
		addAttachment: AddAttachment,
		
		getLegMusic: GetLegMusic,
		setLegMusic: SetLegMusic,
		
		getUserSettings: GetUserSettings,
		setUserSetting: SetUserSetting,
		
		addMedia: AddMedia,

		getUnreadCount: GetUnreadConversationsCount,
		markAllConversationsRead: MarkAllConversationsRead,
	}, dispatch)
);

/* ----------  Exports  ---------- */

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewMyTours));
