/* ----------  Imports  ---------- */

// Axios
import axios from 'axios';

// Lodash
import { isEmpty } from 'lodash';

// Services
import Storage from '../../../../Services/Storage';

// Global
import Global from './../../../../Constants/Global';

/* ----------  Scripts  ---------- */

const LoginUser = (data, success, fail) => dispatch => {
	const url = `${ Global.API_BASE_URL }/users/login`;
	
	axios.post(url, data).then(response => {
		const { result, status } = response.data;
		Storage.setUserId(result.userId);

		console.log(window.localStorage.getItem('userId'));

		if(!result.registrationStripeSessionId || isEmpty(result.registrationStripeSessionId)) {
			dispatch({
				type: 'USER_LOGIN',
				payload: result
			});

			if(status) {
				dispatch({
					type: 'USER_TOKEN_UPDATE',
					payload: result.token
				});

				dispatch({ type: 'USER_FCM_TOKEN_SYNC' });
			}
		}

		if(success) success(status, result);
	}).catch(error => {
		const { response } = error;

		dispatch({ type: 'USER_TOKEN_REMOVE' });
		dispatch({ type: 'USER_REMOVE_PROFILE' });

		if(fail) {
			if(response) {
				fail(response.data.status, response.data.error.reasons);
			} else {
				fail(false, []);
			}
		}
	});
};

/* ----------  Exports  ---------- */

export default LoginUser;
