/* ----------  Scripts  ---------- */

const SetToursUnActive = () => dispatch => {
	dispatch({
		type: 'HOME_SET_TOURS_UNACTIVE'
	})
};

/* ----------  Exports  ---------- */

export default SetToursUnActive;