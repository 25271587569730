/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { map } from 'lodash';

// Highlight JS
import hljs from 'highlight.js/lib/highlight';
import javascript from 'highlight.js/lib/languages/javascript';

// Preloader
import Preloader from './../../../Components/Common/Preloader';

// Utils
import Selectize from './../../../Components/Utils/Selectize';

// UIkit
import Modal from './../../../Components/UIkit/Modal';
import Input from './../../../Components/UIkit/Input';
import Alerts from './../../../Components/UIkit/Alerts';

// Helpers
import Notify from './../../../Helpers/Notify';
import InputHelper from './../../../Helpers/InputHelper';

/* ----------  Scrips  ---------- */

class ModalHyperlink extends React.Component {
	constructor(props) {
		super(props);

		this.modal = React.createRef();
		this.code = React.createRef();

		this.initialFormState = {
			tourId: '',
			to: '',
			cc: [],
			bcc: [],
			message: '',
			code: '',
			preview: '',
		}
		
		this.initialState = {
			errors: [],
			busy: false,
			loading: true,
			ccActive: false,
			bccActive: false,
			form: this.initialFormState,
		}

		this.state = this.initialState;
	}

	componentDidMount() {}

	onShow = () => {}

	onHide = () => {
		this.setState(this.initialState);
	}

	onSuccess = () => {
		const { hyperlink } = this.props;
		const { form } = this.state;

		this.setState({
			form: {
				...form,
				code: hyperlink.code,
				preview: hyperlink.preview,
			}
		});

		this.highlight();
	}

	onCCClick = e => {
		e.preventDefault();

		const { ccActive } = this.state;

		this.setState({
			ccActive: !ccActive,
		}, () => this.resetExtrasTo('cc'));
	}

	onBCCClick = e => {
		e.preventDefault();

		const { bccActive } = this.state;

		this.setState({
			bccActive: !bccActive,
		}, () => this.resetExtrasTo('bcc'));
	}

	highlight = () => {
		const code = this.code.current;

		hljs.registerLanguage('javascript', javascript);
		hljs.highlightBlock(code);
	}

	handleForm = e => {
		const { form } = this.state;
		const { name, value } = e.currentTarget;

		this.setState({
			form: {
				...form,
				[name]: value,
			}
		});
	}

	handleHyperlink = tourId => {
		const { form } = this.state;
		const modal = this.modal.current;

		this.setState({
			form: {
				...form,
				tourId,
			}
		}, modal.show);
	}

	handleExtrasTo = (name, value) => {
		const { form } = this.state;

		this.setState({
			form: {
				...form,
				[name]: value
			}
		});
	}

	resetExtrasTo = type => {
		const { form } = this.state;

		this.setState({
			form: {
				...form,
				[type]: []
			}
		});
	}

	sendHyperlink = e => {
		e.preventDefault();

		const isValid = this.validateForm();

		if(isValid) {
			const { form } = this.state;
			const modal = this.modal.current;
			
			this.setState({ busy: true });

			this.props.sendHyperlink(form, () => {
				this.setState({ busy: false });
				modal.hide();
				Notify.success('Hyperlink sent successfully!');
			});
		}
	}

	validateForm = () => {
		let isValid = true;
		const errors = [];
		const { form: { to, cc, bcc }, ccActive, bccActive } = this.state;

		if(InputHelper.isEmpty(to) || !InputHelper.isEmail(to)) {
			isValid = false;
			errors.push('Please enter a valid recipient.');
		}

		if(ccActive && cc.length) {
			map(cc, email => {
				if(!InputHelper.isEmail(email)) {
					isValid = false;
					errors.push('Please enter a valid CC recipient email.');
				}
			});
		}

		if(bccActive && bcc.length) {
			map(bcc, email => {
				if(!InputHelper.isEmail(email)) {
					isValid = false;
					errors.push('Please enter a valid BCC recipient email.');
				}
			});
		}

		this.setState({
			errors,
		});

		return isValid;
	}

	renderCC = () => {
		const { form, ccActive } = this.state;

		if(!ccActive) return <div className="d-none"/>;

		return (
			<div className="md-form-group m-b-20">
				<p className="m-b-10">CC:</p>
				<Selectize
					tags
					multiple
					id="hyperlinkTxtCC"
					name="cc"
					value={ form.cc }
					onChange={ this.handleExtrasTo }
					ref={ this.hyperlinkCCRef }/>
			</div>
		);
	}

	renderBCC = () => {
		const { form, bccActive } = this.state;

		if(!bccActive) return <div className="d-none"/>;

		return (
			<div className="md-form-group m-b-20">
				<p className="m-b-10">BCC:</p>
				<Selectize
					tags
					multiple
					id="hyperlinkTxtBCC"
					name="bcc"
					value={ form.bcc }
					onChange={ this.handleExtrasTo }
					ref={ this.hyperlinkCCRef }/>
			</div>
		);
	}

	renderContent = () => {
		const { hyperlink, loading } = this.props;
		const { form, errors, ccActive, bccActive, busy } = this.state;

		if(loading) return <Preloader loading={ loading } center minimal/>;

		return (
			<form>
				<div className="uk-modal-header">
					<h3 className="uk-modal-title modalTitle">Copy the HTML code and paste it into your website</h3>
				</div>
				<div className="uk-modal-body">
					<div className="code-container m-b-30">
						<div className="uk-grid uk-grid-small">
							<div className="uk-width-3-5">
								<div className="block-title m-b-15">
									<h5>Code:</h5>
								</div>
								<div className="block-content m-b-20">
									<pre className="p-5 m-0"><code className="javascript m-0" ref={ this.code }>
										{ hyperlink.code }
									</code></pre>
								</div>
								<div className="block-btn">
									<span className="md-btn md-btn-flat md-btn-flat-success">
										<i className="material-icons">&#xE14D;</i>
										Copy to Clipboard
									</span>
								</div>
							</div>
							<div className="uk-width-2-5">
								<div className="block-title uk-text-center m-b-15">
									<h5>Preview:</h5>
								</div>
								<div className="block-content uk-text-center">
									<img src={ hyperlink.preview } className="img-responsive" alt=""/>
								</div>
							</div>
						</div>
					</div>
					<div className="mail-form-container">
						<div className="md-form-group m-b-20 has-cc-bcc">
							<Input
								title="To"
								name="to"
								id="txtHyperlinkTo"
								value={ form.to }
								onChange={ this.handleForm }/>
							<div className="md-input-actions">
								<ul>
									<li className={ ccActive ? 'active' : '' }><Link to="#cc" onClick={ this.onCCClick }>CC</Link></li>
									<li className={ bccActive ? 'active' : '' }><Link to="#bcc" onClick={ this.onBCCClick }>BCC</Link></li>
								</ul>
							</div>
						</div>
						{ this.renderCC() }
						{ this.renderBCC() }
						<div className="md-form-group">
							<Input
								title="Message"
								name="message"
								id="txtHyperlinkMessage"
								value={ form.message }
								onChange={ this.handleForm }
								textarea/>
						</div>
					</div>
					<Alerts show={ errors.length > 0 } data={ errors }/>
				</div>
				<div className="uk-modal-footer">
					<div className="footer-preloader m-r-10">
						<Preloader size={ 20 } loading={ busy } relative minimal/>
					</div>
					<Link to="#closeModal" className={ `md-btn md-btn-flat md-btn-flat-default md-btn-wave waves-effect waves-button uk-modal-close ${ busy ? 'disabled' : '' }` }>
						Cancel
					</Link>
					<button type="submit" className={ `md-btn md-btn-flat md-btn-flat-success md-btn-wave waves-effect waves-button ${ busy ? 'disabled' : '' }` } onClick={ this.sendHyperlink }>
						Send
					</button>
				</div>
			</form>
		);
	}

	render() {
		const style = {
			minHeight: 200
		}

		return (
			<Modal
				popup="tour-hyperlink"
				className="modal-tour-hyperlink"
				onShow={ this.onShow }
				onHide={ this.onHide }
				bgClose={ false }
				keyboardClose={ false }
				ref={ this.modal }>
				<div className="uk-modal-dialog" style={ style }>
					{ this.renderContent() }
				</div>
			</Modal>
		);
	}
}

/* ----------  Prop Types  ---------- */

ModalHyperlink.propTypes = {
	loading: PropTypes.bool.isRequired,
	
	hyperlink: PropTypes.shape().isRequired,
	
	sendHyperlink: PropTypes.func.isRequired,
}

export default ModalHyperlink;