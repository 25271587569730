/* ----------  Imports  ---------- */

// Axios
import axios from 'axios';

// Globals
import Global from './../../../../Constants/Global';

/* ----------  Scripts  ---------- */

const GetContactsList = (data, success, fail) => dispatch => {
	const url = `${ Global.API_BASE_URL }/messages/contact/search`;

	axios.get(url, {
		params: data
	}).then(response => {
		const { status, result } = response.data;

		dispatch({
			type: 'MESSAGES_GET_CONTACTS_LIST',
			payload: result
		});

		if(success) success(status, result.meta);
	}).catch(error => {
		const { response } = error;

		if(fail) {
			if(response) {
				fail(response.data.status, response.data.error.reasons);
			} else {
				fail(false, []);
			}
		}
	});
}

/* ----------  Exports  ---------- */

export default GetContactsList;