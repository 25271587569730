/* ----------  Scripts  ---------- */

const RemoveSponsoredLegs = () => dispatch => (
	dispatch({
		type: 'MARKETPLACE_REMOVE_SPONSORED_LEGS',
	})
);

/* ----------  Exports  ---------- */

export default RemoveSponsoredLegs;