/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Stripe Elements
import { StripeProvider, Elements } from 'react-stripe-elements';

// Checkout Form
import CheckoutForm from './CheckoutForm';

// Constants
import Global from './../../../Constants/Global';

/* ----------  Class  ---------- */

class AddDebitCard extends React.Component {
	addDebitCard = (token, success, fail) => this.props.addDebitCard(token, success, fail)

	renderCheckoutForm = () => {
		const { active } = this.props;

		if(!active) return <div className="d-none"/>;

		const { data, countries, currencies } = this.props;

		return (
			<CheckoutForm
				data={ data }
				active={ active }
				countries={ countries }
				currencies={ currencies }
				addDebitCard={ this.addDebitCard }/>
		);
	}
	
	render() {
		return (
			<StripeProvider apiKey={ Global.STRIPE_KEY }>
				<Elements>
					{ this.renderCheckoutForm() }
				</Elements>
			</StripeProvider>
		);
	}
}

/* ----------  Prop Types  ---------- */

AddDebitCard.defaultProps = {
	data: {},
	countries: [],
	currencies: [],
	
	active: false,
}

AddDebitCard.propTypes = {
	active: PropTypes.bool,

	data: PropTypes.shape(),
	
	countries: PropTypes.arrayOf(PropTypes.object),
	currencies: PropTypes.arrayOf(PropTypes.object),
	
	addDebitCard: PropTypes.func.isRequired,
}

/* ----------  Exports  ---------- */

export default AddDebitCard;
