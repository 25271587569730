/* ----------  Imports  ---------- */

// Axios
import axios from "axios";

// Globals
import Global from './../../../Constants/Global';

/* ----------  Scripts  ---------- */

const AddMedia = (data, success, fail) => dispatch => {
    const url = `${ Global.API_BASE_URL }/media`;

    axios.post(url, data).then(response => {
        const { status, result } = response.data;

        dispatch({ type: 'ADD_MEDIA' });

        if (success) success(status, result);
    }).catch(error => {
		const { response } = error;
		
        if (fail) {
            if (response) {
                fail(response.data.status, response.data.error.reasons);
            } else {
                fail(false, []);
            }
        }
    });
}

/* ----------  Exports  ---------- */

export default AddMedia;
