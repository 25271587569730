/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types'

/* ----------  Scripts  ---------- */

class NoAnimationAlert extends React.Component {
	constructor(props) {
		super(props);
		this.matchMedia = window.matchMedia('(prefers-reduced-motion)') || {};
	}

	render() {
		const { spacing } = this.props;
		const isDisabled = this.matchMedia.matches || false;
		if(!isDisabled) return '';

		return (
			<div className={ `uk-alert uk-alert-danger ${ !spacing ? 'm-0' : '' }` }>
				<p className="m-0">Hey! It seems that you have animations disabled on your OS. Please <Link to="//developer.paciellogroup.com/blog/2019/05/short-note-on-prefers-reduced-motion-and-puzzled-windows-users/" className="link-white" target="_blank">visit here <i className="fas fa-external-link-alt"/></Link> to enable system animations.</p>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

NoAnimationAlert.defaultProps = {
	spacing: true
}

NoAnimationAlert.propTypes = {
	spacing: PropTypes.bool
}

/* ----------  Exports  ---------- */

export default NoAnimationAlert;
