/* ----------  Imports  ---------- */

// Axios
import axios from 'axios';

// Global
import Global from './../../../Constants/Global';

/* ----------  Scripts  ---------- */

const SubscribeLeg = (data, success, fail) => dispatch => {
	const url = `${ Global.API_BASE_URL }/subscriptionsnode/leg/subscribe`;

	axios.post(url, { legId: data.legId }).then(response => {
		const { status, result } = response.data;

		dispatch({
			type: 'MARKETPLACE_LEG_SUBSCRIBE',
			payload: result
		});

		if(success) success(status);
	}).catch(error => {
		const { response } = error;

		if (fail) {
			if (response) {
				fail(response.data.status, response.data.error.reasons);
			} else {
				fail(false, []);
			}
		}
	});
};

/* ----------  Exports  ---------- */

export default SubscribeLeg;