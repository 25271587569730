/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { isEqual } from 'lodash';

// UUID
import uuid from 'uuid/v4';

// Perfect Scrollbar
import PerfectScrollbar from 'perfect-scrollbar';

// jQuery
import $ from 'jquery';

/* ----------  Scripts  ---------- */

class Scrollbar extends React.Component {
	constructor(props) {
		super(props);

		this.scrollbar = React.createRef();
		this.scrollbarApi = null;
	}

	componentDidMount() {
		this.load(this.scrollbar.current);
	}

	componentDidUpdate(prevProps) {
		const { position } = this.props;
		const isInitialPositionEqual = isEqual(prevProps.position, position);

		if(!isInitialPositionEqual) this.update(position);
	}

	getY = () => this.scrollbar.current.scrollTop
	
	getX = () => this.scrollbar.current.scrollLeft

	getApi = () => this.scrollbarApi

	load = node => {
		if(this.props.noPs) return;

		const { 
			position,
			disableAxisX,
			disableAxisY,
			onYScroll,
			onYScrollStart,
			onYScrollEnd,
			onXScroll,
			onXScrollStart,
			onXScrollEnd
		 } = this.props;

		this.scrollbarApi = new PerfectScrollbar(node, {
			suppressScrollX: disableAxisX,
			suppressScrollY: disableAxisY
		});

		this.scrollbarApi.element.parentElement.classList.add('ps-parent');

		node.addEventListener('ps-scroll-y', () => {
			if(onYScroll) onYScroll();
		});

		node.addEventListener('ps-y-reach-start', () => {
			if(onYScrollStart) onYScrollStart();
		});

		node.addEventListener('ps-y-reach-end', () => {
			if(onYScrollEnd) onYScrollEnd();
		});
		
		node.addEventListener('ps-scroll-x', () => {
			if(onXScroll) onXScroll();
		});

		node.addEventListener('ps-x-reach-start', () => {
			if(onXScrollStart) onXScrollStart();
		});

		node.addEventListener('ps-x-reach-end', () => {
			if(onXScrollEnd) onXScrollEnd();
		});

		this.update(position);

		$('.googleMap').on('mousewheel', e => {
			e.preventDefault();
			e.stopPropagation();
		});
	}

	scrollLeft = (position = 0) => {
		this.scrollbar.current.scrollLeft = position;
		if(this.scrollbarApi) this.scrollbarApi.update();
	}
	
	update = scrollTop => {
		if(scrollTop !== undefined) this.scrollbar.current.scrollTop = scrollTop;
		if(this.scrollbarApi) this.scrollbarApi.update();
	}

	render() {
		const { disableAxisX, disableAxisY } = this.props;

		const scrollXClass = disableAxisX ? 'no-scroll-x' : '';
		const scrollYClass = disableAxisY ? 'no-scroll-y' : '';

		return (
			<div className={ `${ this.props.className } ${ scrollXClass } ${ scrollYClass }` } id={ this.props.id } ref={ this.scrollbar } data-ps>
				{ this.props.children }
			</div>
		);
	}
}

Scrollbar.defaultProps = {
	id: `ps_${ uuid() }`,
	className: '',
	
	noPs: false,
	disableAxisX: false,
	disableAxisY: false,
	
	onYScroll: false,
	onYScrollEnd: false,
	onYScrollStart: false,
	
	onXScroll: false,
	onXScrollEnd: false,
	onXScrollStart: false,

	position: 0,
}

Scrollbar.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	
	noPs: PropTypes.bool,
	disableAxisX: PropTypes.bool,
	disableAxisY: PropTypes.bool,
	children: PropTypes.node.isRequired,
	
	position: PropTypes.number,

	onYScroll: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	onYScrollStart: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	onYScrollEnd: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	
	onXScroll: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	onXScrollStart: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	onXScrollEnd: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
}

/* ----------  Exports  ---------- */

export default Scrollbar;
