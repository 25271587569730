/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

/* ----------  Scripts  ---------- */

class RelatedLegItem extends React.Component {
	panTo = e => {
		e.preventDefault();
		e.stopPropagation();

		const { leg, panRelatedLeg } = this.props;

		panRelatedLeg(leg);
	}

	handleLeg = e => {
		e.preventDefault();
		e.stopPropagation();

		const { leg, type, handleLeg } = this.props;

		const data = {
			legId: leg.legId,
			type
		}

		if(!leg.active) handleLeg(data, leg);
	}

	handleContext = e => {
		e.preventDefault();
		e.stopPropagation();

		const { leg } = this.props;

		this.props.handleContext('legOptions', leg, e.currentTarget);
	}

	render() {
		const { leg } = this.props;
		const categoryTheme = leg.primaryCategory ? leg.primaryCategory.theme : 'default';

		return (
			<div className={ `market-item item-category-${ categoryTheme } marketItem item-loaded ${ leg.active ? 'active' : '' }` }
				data-leg={ leg.legId }
				ref={ this.leg }
				onClick={ this.handleLeg }
				tabIndex="-1"
				role="button">
				<div className="item-inner">
					<div className="item-loader">
						<span className="loader-icon">
							<span className="fas fa-refresh fa-spin"/>
						</span>
					</div>
					<div className="item-options">
						<Link to="#marketplace" className="atnItemContext" onClick={ this.handleContext }><i className="material-icons">more_vert</i></Link>
					</div>
					<div className="item-content">
						<div className="item-title" data-mh>
							<h5>{ leg.title }</h5>
						</div>
					</div>
					<div className="item-footer">
						<div className="item-price">
							<p>{ leg.price || 0.00 }</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

RelatedLegItem.propTypes = {
	handleLeg: PropTypes.func.isRequired,
	handleContext: PropTypes.func.isRequired,
	panRelatedLeg: PropTypes.func.isRequired,
	
	type: PropTypes.string.isRequired,
	
	leg: PropTypes.shape({}).isRequired
}

/* ----------  Exports  ---------- */

export default RelatedLegItem;
