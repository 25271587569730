/* ----------  Imports  ---------- */

// React
import React from 'react';

// Redux
import { bindActionCreators } from 'redux';

// React Redux
import { connect } from 'react-redux';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { debounce, map, findIndex, slice, filter, get, isEqual, join } from 'lodash';

// jQuery
import $ from 'jquery';

// Components
import Search from './../../Components/Marketplace/Common/Search';

// Map
import LegsMap from './../../Components/Marketplace/Common/LegsMap';

// Components
import LegItem from './../../Components/Marketplace/LegItem';
import RelatedLegSection from './../../Components/Marketplace/Related/RelatedLegSection';

// Legs Actions
import GetLegs from './../../Redux/Actions/Marketplace/GetLegs';
import RemoveLegs from './../../Redux/Actions/Marketplace/RemoveLegs';

// Leg Actions
import ReportLeg from './../../Redux/Actions/Marketplace/ReportLeg';

import SetLegsVisible from './../../Redux/Actions/Marketplace/SetLegsVisible';

import SetLegActive from './../../Redux/Actions/Marketplace/SetLegActive';
import SetLegsUnActive from './../../Redux/Actions/Marketplace/SetLegsUnActive';

import SetLegFavorite from './../../Redux/Actions/Marketplace/SetLegFavorite';
import SetLegUnfavorite from './../../Redux/Actions/Marketplace/SetLegUnfavorite';

import SetLegHovered from './../../Redux/Actions/Marketplace/SetLegHovered';
import SetLegsUnHovered from './../../Redux/Actions/Marketplace/SetLegsUnHovered';

import SubscribeLeg from './../../Redux/Actions/Marketplace/SubscribeLeg';
import UnsubscribeLeg from './../../Redux/Actions/Marketplace/UnsubscribeLeg';

// Sponsored Actions
import GetSponsoredLegs from './../../Redux/Actions/Marketplace/GetSponsoredLegs';
import RemoveSponsoredLegs from './../../Redux/Actions/Marketplace/RemoveSponsoredLegs';

// Other Actions
import GetOtherLegs from './../../Redux/Actions/Marketplace/GetOtherLegs';
import RemoveOtherLegs from './../../Redux/Actions/Marketplace/RemoveOtherLegs';

// Favorite Actions
import GetFavoriteLegs from './../../Redux/Actions/Marketplace/GetFavoriteLegs';
import RemoveFavoriteLegs from './../../Redux/Actions/Marketplace/RemoveFavoriteLegs';

// Leg Rating
import GetLegRating from './../../Redux/Actions/Marketplace/GetLegRating';
import SetLegRating from './../../Redux/Actions/Marketplace/SetLegRating';

// Utils
import Scrollbar from './../../Components/Utils/Scrollbar';

// Helpers
import Notify from './../../Helpers/Notify';

// Commons
import Preloader from './../../Components/Common/Preloader';

// Pagination
import Pagination from './../../Components/Pagination/';

/* ----------  Scripts  ---------- */

class Panel extends React.Component {
	constructor(props) {
		super(props);

		this.mapRef = React.createRef();
		this.legsRef = React.createRef();
		this.resultsRef = React.createRef();
		this.resultScrollbar = React.createRef();

		this.initTimer = null;

		this.initialFiltersState = {
			title: '',
			location: '',
			userId: '',
			position: [],
			languages: [],
			price: [],
			licensed: 0,
			type: 'byLocation',
			categoryIds: [],
			tags: [],
			radius: 2000,
		}

		this.state = {
			loading: true,
			loaded: false,
			
			related: false,

			visibleLegs: [],

			googleMap: {
				zoom: 14,
				center: {
					lat: 40.785196,
					lng: -73.969454
				}
			},

			filters: this.initialFiltersState,

			meta: {
				page: 0,
				pageSize: 5,
				total: 0,
				pages: 1,
				startIndex: -1,
				count: 10,
			},

			prices: {
				min: 0,
				max: 100
			},

			sponsoredLegs: {
				loaded: false,
				loading: false,
			},

			otherLegs: {
				loaded: false,
				loading: false,
			},

			favoriteLegs: {
				loaded: false,
				loading: false,
			}
		}

		this.requestLegs = debounce(this.doRequestLegs, 100);
		
		this.requestSponsoredLegs = debounce(this.doRequestSponsoredLegs, 100);
		this.requestOtherLegs = debounce(this.doRequestOtherLegs, 100);
		this.requestFavoriteLegs = debounce(this.doRequestFavoriteLegs, 100);
		
		this.requestReportLeg = debounce(this.doRequestReportLeg, 100);

		this.setLegFavorite = debounce(this.doSetLegFavorite, 100);
		this.setLegUnfavorite = debounce(this.doSetLegUnfavorite, 100);

		this.subscribeLeg = debounce(this.doSubscribeLeg, 100);
		this.unsubscribeLeg = debounce(this.doUnsubscribeLeg, 100);
		
		this.getLegRating = debounce(this.doGetLegRating, 100);
		this.setLegRating = debounce(this.doSetLegRating, 100);
	}

	componentDidMount() {
		this.init();
	}

	componentWillReceiveProps(nextProps) {
		const { location } = this.props;
		const isLocationEqual = isEqual(nextProps.location.search, location.search);

		if(!isLocationEqual) {
			this.init(nextProps.location);
		}
	}

	getVisibleLegs = () => {
		const visibleLegs = [];
		const { legsData } = this.props;

		map(legsData, item => {
			if(item.visible) {
				visibleLegs.push(item);
			}
		});

		return visibleLegs;
	}

	getLegs = (filters, callback) => {
		this.setState({
			loading: true,
			loaded: false,

			filters: {
				...this.state.filters,
				...filters
			}
		}, this.requestLegs(callback));
	}

	getSponsoredLegs = legId => {
		this.requestSponsoredLegs(legId);
	}

	getOtherLegs = legId => {
		this.requestOtherLegs(legId);
	}

	getFavoriteLegs = legId => {
		if(this.state.related) {
			this.requestFavoriteLegs(legId);
		}
	}

	setLegActive = legId => {
		this.removeActiveLegs();
		this.props.setLegActive(legId);
	}

	setLegsVisible = legIds => {
		setTimeout(() => {
			this.props.setLegsVisible(legIds, () => {
				const { meta } = this.state;
				
				this.updateMeta({
					total: legIds.length,
					pages: Math.ceil(legIds.length / meta.pageSize)
				});
			});
		}, 300);
	}

	setLegHovered = (legId, type) => {
		this.setLegsUnHovered();
		this.props.setLegHovered({
			legId,
			hovered: true
		});

		if(type === 'marker') this.goTo(legId);
	}

	setLegsUnHovered = () => {
		this.props.setLegsUnHovered();
	}

	doRequestLegs = (success, fail) => {
		this.removeLegs();

		const { filters: { userId, position, title, tags, price, radius, languages, categoryIds, location } } = this.state;
		const { meta: { count, startIndex } } = this.state;

		const params = {
			count,
			radius,
			startIndex,
		}

		if(title) params.title = title;
		if(userId) params.userId = userId;
		if(location) params.location = location;

		if(tags && tags.length) params.tags = join(tags);
		if(price && price.length) params.price = join(price);
		if(position && position.length) params.position = join(position);
		if(languages && languages.length) params.languages = join(languages);
		if(categoryIds && categoryIds.length) params.categoryIds = join(categoryIds);

		this.props.getLegs(params, () => {
			// const { legs } = this.props;

			this.setState({
				loading: false,
				loaded: true
			});

			// const prices = map(legs, leg => leg.price);
			// const minPrice = min(prices);
			// const maxPrice = max(prices);

			this.renderMarkers();
			
			if(position && position.length) this.changeMapPosition(position);

			if(!price.length) {
				this.setState({
					prices: {
						// min: Math.ceil(minPrice),
						// max: Math.ceil(maxPrice),
						min: 0,
						max: 1000,
					}
				});
			}

			this.updateFilters(this.initialFiltersState, () => {
				if(success) success();
			});
		}, (status, reasons) => {
			this.setState({
				loading: false,
				loaded: false
			}, this.removeMarkers);

			map(reasons, reason => Notify.error(reason.message));

			if(fail) fail(reasons);
		});
	}

	doRequestSponsoredLegs = legId => {
		this.removeSponsoredLegs();

		const params = {
			legId
		}

		this.setState({
			sponsoredLegs: {
				loading: true,
				loaded: false,
			}
		});

		this.props.getSponsoredLegs(params, () => {
			this.setState({
				sponsoredLegs: {
					loaded: true,
					loading: false,
				}
			});
		}, () => {
			this.setState({
				sponsoredLegs: {
					loaded: false,
					loading: false,
				}
			});
		});
	}

	doRequestOtherLegs = legId => {
		this.removeOtherLegs();

		const params = {
			legId
		}

		this.setState({
			otherLegs: {
				loading: true,
				loaded: false,
			}
		});

		this.props.getOtherLegs(params, () => {
			this.setState({
				otherLegs: {
					loaded: true,
					loading: false,
				}
			});
		}, () => {
			this.setState({
				otherLegs: {
					loaded: false,
					loading: false,
				}
			});
		});
	}

	doRequestFavoriteLegs = legId => {
		this.removeFavoriteLegs();

		const params = {
			legId
		}

		this.setState({
			favoriteLegs: {
				loading: true,
				loaded: false,
			}
		});

		this.props.getFavoriteLegs(params, () => {
			this.setState({
				favoriteLegs: {
					loaded: true,
					loading: false,
				}
			});
		}, () => {
			this.setState({
				favoriteLegs: {
					loaded: false,
					loading: false,
				}
			});
		});
	}

	doRequestReportLeg = (legId, success, fail) => {
		const data = {
			legId
		}

		this.props.reportLeg(data, () => {
			if(success) success();
		}, (status, reasons) => {
			if(fail) fail(reasons);
		});
	}

	doSetLegFavorite = legId => {
		const data = {
			legId,
		}

		this.props.setLegFavorite(data, () => {
			this.getFavoriteLegs(legId);
			Notify.success('Leg set favorite successfully!');
		}, () => {
			Notify.error('Something went wrong.');
		});
	}

	doSetLegUnfavorite = legId => {
		const data = {
			legId,
		}

		this.props.setLegUnfavorite(data, () => {
			this.getFavoriteLegs(legId);
			Notify.success('Leg set un-favorite successfully!');
		}, () => {
			Notify.error('Something went wrong.');
		});
	}

	doSubscribeLeg = legId => {
		const data = {
			legId,
		}

		this.props.subscribeLeg(data, () => {
			Notify.success('Subscribed successfully!');
		}, () => {
			Notify.error('Something went wrong.');
		});
	}

	doUnsubscribeLeg = legId => {
		const data = {
			legId,
		}

		this.props.unsubscribeLeg(data, () => {
			Notify.success('Unsubscribed successfully!');
		}, () => {
			Notify.error('Something went wrong.');
		});
	}

	doGetLegRating = (legId, success, fail) => {
		const params = {
			legId,
		}

		this.props.getLegRating(params, (status, result) => {
			if(success) success(result);
		}, (status, reasons) => {
			if(fail) fail(reasons);
		});
	}

	doSetLegRating = (data, success, fail) => {
		this.props.setLegRating(data, () => {
			if(success) success();
		}, (status, reasons) => {
			if(fail) fail(reasons);
		});
	}

	changePage = page => {
		const { meta } = this.state;

		if((page === meta.pages) || (page < 0) || (page > meta.pages)) return false;
		
		this.scrollListing(0);
		this.updateMeta({ page });

		return true;
	}

	changeMapPosition = (position, zoom = 16) => {
		const gmap = this.mapRef.current;
		const center = {
			lat: position[0],
			lng: position[1],
		}
		
		gmap.setZoom(zoom);
		gmap.setCenter(center);
	}

	mapIdle = () => {
		// const position = [center.lat, center.lng];

		this.updateMeta({ page: 0 }, () => {
			const activeLeg = filter(this.props.legsData, { active: true });

			// this.updateFilters({ position });

			if(activeLeg.length) this.goTo(activeLeg[0].legId);
		});
	}

	goTo = legId => {
		const { meta } = this.state;
		const visibleLegs = this.getVisibleLegs();
		const legIndex = findIndex(visibleLegs, ['legId', legId]);

		if(legIndex !== -1) {
			const page = Math.floor(legIndex / meta.pageSize);

			this.changePage(page);

			const $listing = $(this.resultsRef.current);
			const $leg = $(`[data-leg=${ legId }]`, $listing);

			if($leg.length) {
				const offset = $leg.innerHeight() * 1.5;
				const position = $leg.position().top
				const top = position > offset ? (position - offset) : 0;
				
				this.scrollListing(top);
			}
		}
	}

	handleLeg = legId => {
		this.setState({
			related: true,
		}, () => {
			this.playTour(legId);
			this.setLegActive(legId);
			
			this.getSponsoredLegs(legId);
			this.getOtherLegs(legId);
			this.getFavoriteLegs(legId);
		});
	}

	handleRelatedLeg = (data, leg) => {
		this.playTour(leg.legId);
		this.setLegActive(leg.legId);
		this.changeMapPosition(leg.position);
	}

	handlePageSize = e => {
		const { meta } = this.state;
		const pageSize = parseInt(e.currentTarget.value, 10);

		this.scrollListing(0);
		this.updateMeta({
			pageSize,
			page: 0,
			pages: meta.total / pageSize
		});
	}

	handleContext = (type, leg, target, event) => {
		this.props.handleContext(type, leg, target, event);
	}

	init = (location = this.props.location) => {
		const params = new URLSearchParams(location.search);
		const userId = params.get('userId');

		this.updateFilters({ userId }, () => {
			this.getLegs();
		});
	}

	playTour = legId => {
		this.props.playTour(legId);
	}

	updateMeta = (updates, callback) => {
		const { meta } = this.state;

		this.setState({
			meta: {
				...meta,
				...updates
			}
		}, () => callback ? callback() : null);
	}

	updateFilters = (updates, callback) => {
		const { filters } = this.state;

		this.setState({
			filters: {
				...filters,
				...updates
			}
		}, () => {
			if(callback) callback();
		});
	}

	panRelatedLeg = leg => {
		this.setLegActive(leg.legId);
		this.changeMapPosition(leg.position);
	}

	reportLeg = (legId, success, fail) => {
		this.requestReportLeg(legId, success, fail);
	}

	removeActiveLegs = () => {
		this.props.setLegsUnActive();
	}

	removeLegs = () => {
		this.props.removeLegs();
	}

	removeSponsoredLegs = () => {
		this.setState({
			sponsoredLegs: {
				loaded: false,
				loading: false,
			}
		});

		this.props.removeSponsoredLegs();
	}

	removeOtherLegs = () => {
		this.setState({
			otherLegs: {
				loaded: false,
				loading: false,
			}
		});

		this.props.removeOtherLegs();
	}

	removeFavoriteLegs = () => {
		this.setState({
			favoriteLegs: {
				loaded: false,
				loading: false,
			}
		});

		this.props.removeFavoriteLegs();
	}

	reset = () => {
		this.setState({
			related: false,
		}, () => {
			this.removeActiveLegs();
			this.removeSponsoredLegs();
			this.removeFavoriteLegs();
			this.removeOtherLegs();
		});
	}

	scrollListing = position => {
		const resultScrollbar = this.resultScrollbar.current;
		resultScrollbar.update(position);
	}

	removeMarkers = () => {
		const gmap = this.mapRef.current;
		if(gmap) {
			gmap.removeClusterers();
			gmap.removeMarkers();
			gmap.callIdle();
		}
	}

	renderMarkers = () => {
		const gmap = this.mapRef.current;
		if(gmap) {
			gmap.renderMarkers();
			gmap.callIdle();
		}
	}

	renderLegs = () => {
		const { languages, categories } = this.props;
		const { meta, loading } = this.state;

		const startIndex = meta.page * meta.pageSize;
		const endIndex = startIndex + meta.pageSize;
		const data = this.getVisibleLegs();
		const legItems = slice(data, startIndex, endIndex);

		if(loading) return <Preloader loading center minimal/>;

		if(!data.length) return <span className="no-records d-block text-center p-15"><em>No legs found.</em></span>;
		
		return map(legItems, leg => {
			const language = get(languages, leg.language);

			return (
				<LegItem
					key={ leg.legId }
					leg={ leg }
					handleLeg={ this.handleLeg }
					language={ language }
					categories={ categories }
					handleContext={ this.handleContext }
					setLegHovered={ this.setLegHovered }
					setLegsUnHovered={ this.setLegsUnHovered }/>
			);
		});
	}

	render() {
		const { legsData, languages, categories, sponsored, others, favorites } = this.props;
		
		const { googleMap, loaded, meta, prices }  = this.state;
		const { sponsoredLegs, favoriteLegs, otherLegs } = this.state;

		const data = this.getVisibleLegs();

		return (
			<div className="panel">
				<div className="panel-search">
					<Search languages={ languages } categories={ categories } prices={ prices } submitForm={ this.getLegs }/>
				</div>
				<div className="panel-header">
					<div className="header-title">
						<h1>Marketplace</h1>
					</div>
				</div>
				<div className="panel-body">
					<div className="panel-map-view">
						<div className="map-results-toggler">
							<Link to="#panel" className="btnMapResultToggler ripple">
								<i className="material-icons icon-list">format_list_bulleted</i>
								<i className="material-icons icon-close">close</i>
							</Link>
						</div>
						<div className="map-wrapper" id="mapWrapper">
							<div className="map-options" id="mapOptions">
								<ul>
									<li data-uk-dropdown="{mode:'click',pos:'left-top'}">
										<Link to="#panel"><i className="material-icons">settings</i></Link>
										<div className="uk-dropdown uk-dropdown-checklist">
											<ul>
												<li>
													<label htmlFor="testOption">
														<input type="checkbox" id="testOption" value="test" data-md-icheck/>
														<span className="inline-label">Test Option</span>
													</label>
												</li>
											</ul>
										</div>
									</li>
								</ul>
							</div>
							<LegsMap
								autozoom
								type="marketplace"
								zoom={ googleMap.zoom }
								center={ googleMap.center }
								data={ legsData }
								reset={ this.reset }
								loaded={ loaded }
								categories={ categories }
								streetViewControl={ false }
								mapIdle={ this.mapIdle }
								handleLeg={ this.handleLeg }
								setLegActive={ this.setLegActive }
								setLegHovered={ this.setLegHovered }
								setLegsVisible={ this.setLegsVisible }
								setLegsUnHovered={ this.setLegsUnHovered }
								className="map-canvas licensing-map-canvas map bg-grayish-light"
								id="map-homepage"
								ref={ this.mapRef }/>
						</div>
						<div className="results-wrapper" id="mapResults">
							<div className="results">
								<div className="section-title">
									<h2>Search Results (<span className="results-number">{ data.length }</span>)</h2>
								</div>
								<Scrollbar className="results-content-wrapper" ref={ this.resultScrollbar }>
									<div className="results-content" ref={ this.resultsRef }>
										{ this.renderLegs() }
									</div>
								</Scrollbar>
								<div className="results-footer resultsFooter text-center">
									<div className="results-pagination p-l-10 p-r-10">
										<Pagination
											id="marketplacePagination"
											changePage={ this.changePage }
											page={ meta.page }
											pages={ meta.pages }/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="panel-content-view panel-related-legs">
						<RelatedLegSection
							title="Sponsor&apos;s Content"
							type="sponsors"
							loaded={ sponsoredLegs.loaded }
							loading={ sponsoredLegs.loading }
							legs={ sponsored.legs }
							legsData={ sponsored.legsData }
							languages={ languages }
							handleLeg={ this.handleRelatedLeg }
							handleContext={ this.handleContext }
							panRelatedLeg={ this.panRelatedLeg }/>

						<RelatedLegSection
							title="Leg Guide&apos;s Other Legs"
							type="others"
							loaded={ otherLegs.loaded }
							loading={ otherLegs.loading }
							legs={ others.legs }
							legsData={ others.legsData }
							languages={ languages }
							handleLeg={ this.handleRelatedLeg }
							handleContext={ this.handleContext }
							panRelatedLeg={ this.panRelatedLeg }/>

						<RelatedLegSection
							title="My Favorite Legs"
							type="favorites"
							loaded={ favoriteLegs.loaded }
							loading={ favoriteLegs.loading }
							legs={ favorites.legs }
							legsData={ favorites.legsData }
							languages={ languages }
							handleLeg={ this.handleRelatedLeg }
							handleContext={ this.handleContext }
							panRelatedLeg={ this.panRelatedLeg }/>
					</div>
				</div>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

Panel.defaultProps = {
	sponsored: {
		legs: [],
		legsData: {},
	},

	others: {
		legs: [],
		legsData: {},
	},

	favorites: {
		legs: [],
		legsData: {},
	},

	location: {}
}

Panel.propTypes = {
	handleContext: PropTypes.func.isRequired,

	playTour: PropTypes.func.isRequired,

	getLegs: PropTypes.func.isRequired,
	removeLegs: PropTypes.func.isRequired,
	
	getSponsoredLegs: PropTypes.func.isRequired,
	removeSponsoredLegs: PropTypes.func.isRequired,

	getOtherLegs: PropTypes.func.isRequired,
	removeOtherLegs: PropTypes.func.isRequired,

	getFavoriteLegs: PropTypes.func.isRequired,
	removeFavoriteLegs: PropTypes.func.isRequired,

	setLegsVisible: PropTypes.func.isRequired,

	setLegActive: PropTypes.func.isRequired,
	setLegsUnActive: PropTypes.func.isRequired,

	setLegHovered: PropTypes.func.isRequired,
	setLegsUnHovered: PropTypes.func.isRequired,

	setLegFavorite: PropTypes.func.isRequired,
	setLegUnfavorite: PropTypes.func.isRequired,

	subscribeLeg: PropTypes.func.isRequired,
	unsubscribeLeg: PropTypes.func.isRequired,

	getLegRating: PropTypes.func.isRequired,
	setLegRating: PropTypes.func.isRequired,
	
	reportLeg: PropTypes.func.isRequired,

	// legs: PropTypes.arrayOf(PropTypes.object).isRequired,
	legsData: PropTypes.objectOf(PropTypes.object).isRequired,
	
	languages: PropTypes.objectOf(PropTypes.object).isRequired,
	
	categories: PropTypes.objectOf(PropTypes.object).isRequired,
	
	sponsored: PropTypes.shape(),
	others: PropTypes.shape(),
	favorites: PropTypes.shape(),
	
	location: PropTypes.shape(),
}

/* ----------  Redux  ---------- */

const mapStateToProps = (state) => ({
	legs: state.marketplace.legs,
	legsData: state.marketplace.legsData,
	
	sponsored: state.marketplace.sponsored,
	others: state.marketplace.others,
	favorites: state.marketplace.favorites,
});

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		getLegs: GetLegs,
		removeLegs: RemoveLegs,
		
		getSponsoredLegs: GetSponsoredLegs,
		removeSponsoredLegs: RemoveSponsoredLegs,

		getFavoriteLegs: GetFavoriteLegs,
		removeFavoriteLegs: RemoveFavoriteLegs,

		getOtherLegs: GetOtherLegs,
		removeOtherLegs: RemoveOtherLegs,

		setLegsVisible: SetLegsVisible,
		
		setLegHovered: SetLegHovered,
		setLegsUnHovered: SetLegsUnHovered,
		
		setLegActive: SetLegActive,
		setLegsUnActive: SetLegsUnActive,
		
		setLegFavorite: SetLegFavorite,
		setLegUnfavorite: SetLegUnfavorite,
		
		reportLeg: ReportLeg,

		subscribeLeg: SubscribeLeg,
		unsubscribeLeg: UnsubscribeLeg,

		getLegRating: GetLegRating,
		setLegRating: SetLegRating,
	}, dispatch)
);

/* ----------  Exports  ---------- */

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Panel);
