/* ----------  Imports  ---------- */

// Lodash
import { mapKeys } from 'lodash';

/* ----------  Scripts  ---------- */

const ACTION_PANEL_NODES_DATA_INITIAL_STATE = {};

const ReducerMyToursTreesActionPanelNodesData = (state = ACTION_PANEL_NODES_DATA_INITIAL_STATE, action) => {
	if(action.type === 'MYTOURS_TREES_GET_ACTION_PANEL_NODES') {
		const response = mapKeys(action.payload.results, 'nodeId');
		return response;
	} else if(action.type === 'MYTOURS_TREES_REMOVE_ACTION_PANEL_NODES') {
		return ACTION_PANEL_NODES_DATA_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMyToursTreesActionPanelNodesData;