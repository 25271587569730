/* ----------  Scripts  ---------- */

const RemoveTour = () => dispatch => (
	dispatch({
		type: 'HOME_REMOVE_TOUR',
	})
);

/* ----------  Exports  ---------- */

export default RemoveTour;