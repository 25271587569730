/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Redux
import { connect } from 'react-redux';

// Redux
import { bindActionCreators } from 'redux';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Actions
import LogoutUser from './../../../Redux/Actions/User/Auth/LogoutUser';

/* ----------  Scripts  ---------- */

class Logout extends React.Component {
	componentDidMount() {}

	onLogout = e => {
		e.preventDefault();

		this.logout();
	}

	logout = (success, fail) => {
		this.props.logoutUser(success, fail);
	}

	render() {
		return (
			<Link to="#logout" data-uk-tooltip="{pos:'bottom'}" title="Logout" onClick={ this.onLogout }>
				<span className="fas fa-sign-out-alt"/>
			</Link>
		);
	}
}

/* ----------  Prop Types  ---------- */

Logout.propTypes = {
	logoutUser: PropTypes.func.isRequired
}

/* ----------  Redux Scripts  ---------- */

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		logoutUser: LogoutUser,
	}, dispatch)
);

/* ----------  Exports  ---------- */

export default connect(mapStateToProps, mapDispatchToProps)(Logout);