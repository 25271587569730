/* ----------  Imports  ---------- */

// React
import React, { Fragment } from 'react';

// React Redux
import { connect } from 'react-redux';

// Redux
import { bindActionCreators } from 'redux';

// React Router DOM
import { Link, withRouter } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { debounce, map, isEmpty } from 'lodash';

// UIkit
import UIkit from 'uikit';

// Fuse Components
import Input from './../../../Components/Fuse/Input';
import Alerts from './../../../Components/Fuse/Alerts';

// Preloader
import Preloader from './../../../Components/Common/Preloader';

// Utils
import Toggle from './../../../Components/Utils/Toggle';
import Selectize from '../../../Components/Utils/Selectize';

// Helpers
import InputHelper from './../../../Helpers/InputHelper';
import StripeHelper from '../../../Helpers/StripeHelper';
import ConfigHelper from '../../../Helpers/ConfigHelper';

// Actions
import RegisterUser from './../../../Redux/Actions/User/Auth/RegisterUser';
import GetUserProfile from './../../../Redux/Actions/User/GetUserProfile';

/* ----------  Scripts  ---------- */

class RegisterForm extends React.Component {
	constructor(props) {
		super(props);

		this.isLoaded = false;

		this.form = React.createRef();
		this.termsCheckboxRef = React.createRef();

		this.initialFormState = {
			name: '',
			username: '',
			email: '',
			password: '',
			country: '',
			passwordConfirmation: '',
			terms: false,
		}

		this.prefilledFormState = {
			name: 'Aziz K',
			email: 'azizk.araneux@gmail.com',
			password: 'secret',
			passwordConfirmation: 'secret',
			terms: true,
		}

		this.state = {
			busy: false,
			formErrors: [],
			form: this.initialFormState,
		}

		this.requestRegisterUser = debounce(this.doRegisterUser, 500);
		this.requestProfile = debounce(this.doRequestProfile, 500);
	}

	componentDidMount() {
		this.isLoaded = true;
	}

	componentWillUnmount() {
		this.isLoaded = false;
	}

	onChange = e => {
		const { name, value } = e.currentTarget;

		this.updateForm({ [name]: value });
	}
	
	onCountryChange = (name, value) => {
		this.updateForm({ [name]: value });
	}

	onSubmit = e => {
		e.preventDefault();

		const isValid = this.validateForm();

		if(isValid) this.register();
	}

	onLogin = e => {
		e.preventDefault();

		this.props.openLoginForm();
	}
	
	onTermsClick = e => {
		e.preventDefault();

		const modal = UIkit.modal('.ukModalTermsConditions');
		modal.show();
	}

	getProfile = (success, fail) => {
		this.requestProfile(success, fail);
	}

	getCountriesOptions = () => {
		let data = [];
		const { countries } = this.props;

		if(!isEmpty(countries)) {
			data = map(countries, country => ({
				label: country.name,
				value: country.code
			}))
		}

		return data;
	}

	doRequestProfile = (success, fail) => {
		this.props.getUserProfile(success, fail);
	}

	doRegisterUser = (data, success, fail) => {
		this.props.registerUser(data, success, fail);
	}

	handleTerms = (value, checked) => {
		this.updateForm({ terms: checked });
	}

	register = () => {
		const { form } = this.state;
		const { fcmToken } = this.props;
		const termCheckbox = this.termsCheckboxRef.current;

		const configs = ConfigHelper.getAppConfigs();

		const data = {
			...form,
			fcmToken,
			stripeSuccessCallbackUrl: `${ configs.appBaseURL }?rsubscription=success`,
			stripeFailCallbackUrl: `${ configs.appBaseURL }?rsubscription=fail`,
		}

		if(this.isLoaded) this.setState({ busy: true });

		this.requestRegisterUser(data, (status, result) => {
			if(result.registrationStripeSessionId && !isEmpty(result.registrationStripeSessionId)) {
				const { stripe } = this.props;
				if(!stripe || isEmpty(stripe)) return;

				setTimeout(() => {
					StripeHelper.checkoutViaSessionId(stripe, result.registrationStripeSessionId);
				}, 1500);
				return;
			}

			this.getProfile(() => {
				if(this.isLoaded) {
					this.setState({
						busy: false,
						formErrors: [],
						form: this.initialFormState,
					}, termCheckbox.updateUI);
				}
			});
		}, (status, errors) => {
			if(this.isLoaded) {
				const errorMessages = map(errors, reason => reason.message);

				this.setState({
					busy: false,
					formErrors: errorMessages,
				});
			}
		});
	}

	updateForm = (updates, callback) => {
		if(this.isLoaded) {
			const { form } = this.state;
			
			this.setState({
				form: {
					...form,
					...updates
				}
			}, () => {
				if(callback) callback();
			});
		}
	}

	validateForm = () => {
		let isValid = true;

		const errors = [];
		const { form: { name, username, email, password, passwordConfirmation, terms } } = this.state;

		if(InputHelper.isEmpty(name) || InputHelper.isEmpty(username) || InputHelper.isEmpty(email) || InputHelper.isEmpty(password) || InputHelper.isEmpty(passwordConfirmation)) {
			isValid = false;
			errors.push('All fields are mandatory.');
		}

		if(!InputHelper.isEmpty(email) && !InputHelper.isEmail(email)) {
			isValid = false;
			errors.push('Please enter a valid email.');
		}
		
		// if(!InputHelper.isEmpty(username) && !InputHelper.isUsername(username)) {
		// 	isValid = false;
		// 	errors.push('Please enter a valid username.');
		// }

		// if(!InputHelper.isEqual(password, passwordConfirmation)) {
		// 	isValid = false;
		// 	errors.push('Password not matched.');
		// }

		if(!terms) {
			isValid = false;
			errors.push('Please accept terms & conditions.');
		}

		if(this.isLoaded) this.setState({ formErrors: errors });

		return isValid;
	}

	renderCountrySelect = () => {
		const countries = this.getCountriesOptions();
		
		if(!countries.length) return <div className="d-none"/>;
		
		const { form } = this.state;
		
		return (
			<Selectize
				name="country"
				data={ countries }
				value={ form.country }
				id="txtRegisterCountries"
				placeholder="Select Country"
				onChange={ this.onCountryChange }/>
		);
	}

	render() {
		const { form, formErrors, busy } = this.state;

		return (
			<Fragment>
				<div className="tpl-fuse">
					<div className="auth-register">
						<div className="form-wrapper p-8">
							<div className="logo bg-secondary d-none">
								<span>F</span>
							</div>
							<div className="title mt-4 mb-8">Create an account</div>
							<form onSubmit={ this.onSubmit } noValidate>
								<Alerts show={ formErrors.length > 0 } data={ formErrors }/>
								<Input
									id="txtRegisterName"
									label="Name"
									groupClass="mb-4"
									name="name"
									value={ form.name }
									onChange={ this.onChange }/>
								<Input
									id="txtRegisterUsername"
									label="Username"
									groupClass="mb-4"
									name="username"
									value={ form.username }
									onChange={ this.onChange }/>
								<Input
									id="txtRegisterEmail"
									label="Email Address"
									groupClass="mb-4"
									name="email"
									type="email"
									value={ form.email }
									onChange={ this.onChange }/>
								<Input
									id="txtRegisterPassword"
									label="Password"
									groupClass="mb-4"
									name="password"
									type="password"
									value={ form.password }
									onChange={ this.onChange }/>
								<Input
									id="txtRegisterPasswordConfirm"
									label="Password (Confirm)"
									groupClass="mb-4"
									name="passwordConfirmation"
									type="password"
									value={ form.passwordConfirmation }
									onChange={ this.onChange }/>
								{ this.renderCountrySelect() }
								<div className="terms-conditions row align-items-center justify-content-center pt-4 mb-8">
									<div className="form-check mr-1 mb-1">
										<label htmlFor="txtRegisterTerms" className="form-check-label">
											<Toggle
												type="checkbox"
												value="terms"
												name="terms"
												id="txtRegisterTerms"
												checked={ form.terms }
												onChange={ this.handleTerms }
												checkboxClass="icheckbox"
												increaseArea="0%"
												ref={ this.termsCheckboxRef }/>
											{/* <span className="checkbox-icon"/> */}
											<span className="form-check-text">I accept <Link to="#auth" className="text-secondary mb-1" onClick={ this.onTermsClick }>terms and conditions</Link></span>
										</label>
									</div>
								</div>
								<button type="submit" className="submit-button btn btn-block btn-secondary my-4 mx-auto" disabled={ busy } aria-label="CREATE">
									<Preloader loading={ busy } size={ 15 } color="#ffffff" minimal center/>
									{ !busy ? 'CREATE MY ACCOUNT' : '' }
								</button>
							</form>
							<div className="login d-flex flex-column flex-sm-row align-items-center justify-content-center mt-8 mb-6 mx-auto">
								<span className="text mr-sm-2">Already have an account?</span>
								<Link to="#auth" className="link text-secondary" onClick={ this.onLogin }>Log in</Link>
							</div>
						</div>
					</div>
				</div>
			</Fragment>
		);
	}
}

/* ----------  Prop Types  ---------- */

RegisterForm.defaultProps = {
	fcmToken: null,
	countries: [],

	stripe: {}
}

RegisterForm.propTypes = {
	fcmToken: PropTypes.string,
	countries: PropTypes.objectOf(PropTypes.object),

	registerUser: PropTypes.func.isRequired,
	getUserProfile: PropTypes.func.isRequired,
	
	openLoginForm: PropTypes.func.isRequired,

	// user: PropTypes.shape().isRequired

	// history: PropTypes.shape().isRequired,

	stripe: PropTypes.shape()
}

/* ----------  Redux Scripts  ---------- */

const mapStateToProps = state => ({
	fcmToken: state.user.fcmToken,
	countries: state.countries,

	user: state.user
});

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		registerUser: RegisterUser,
		getUserProfile: GetUserProfile,
	}, dispatch)
);

/* ----------  Exports  ---------- */

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RegisterForm));
