/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
// import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// UIKit
import UIkit from 'uikit';

/* ----------  Scripts  ---------- */

class Tab extends React.Component {
	constructor(props) {
		super(props);

		this.tabRef = React.createRef();
	}

	componentDidMount() {
		this.load();
	}

	load = () => {
		const { connect, animation, onChange } = this.props;
		const tabRef = this.tabRef.current;

		const tab = UIkit.tab(tabRef, {
			connect,
			animation,
			swiping: false
		});

		tab.on({
			'change.uk.tab': (e, currentTab, lastTab) => {
				if(onChange) onChange(currentTab, lastTab);
			},
		});
	}

	render() {
		const { className } = this.props;
		return (
			<ul className={ `uk-tab ${ className }` } ref={ this.tabRef }>
				{ this.props.children }
			</ul>
		);
	}
}

/* ----------  Prop Types  ---------- */

Tab.defaultProps = {
	className: '',
	animation: 'slide-horizontal',

	onChange: false,
}

Tab.propTypes = {
	children: PropTypes.node.isRequired,
	
	animation: PropTypes.string,
	className: PropTypes.string,
	connect: PropTypes.string.isRequired,
	
	onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
}

/* ----------  Exports  ---------- */

export default Tab;
