/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Plyr
import Plyr from 'plyr';

// Wave Surfer
import WaveSurfer from 'wavesurfer.js';

// Constants
import Global from '../../Constants/Global';

// Utils
import Slider from '../Utils/Slider';

/* ----------  Leg Video Player  ---------- */

class LegVideoPlayer extends React.Component {
	constructor(props) {
		super(props);

		this.plyr = null;
		this.sliderInit = false;

		this.sliderRef = React.createRef();
		this.waveSurferRef = React.createRef();
		this.volumeSliderRef = React.createRef();
		this.stvVideoPlayerRef = React.createRef();
		
		this.state = {
			type: 'default',
			
			duration: 0,
			currentTime: 0,
			
			sync: true,
			paused: true,
			loaded: false,
			playing: false,
			autoplay: false,
			wavesLoaded: true,
		}
	}

	componentDidMount() {
		this.load();
		
		const { type } = this.state;
		console.log('MOUNTED: VIDEO PLAYER', `TYPE: ${ type }`);
	}

	componentWillUnmount() {
		const { type } = this.state;
		console.log('UNMOUNTED: VIDEO PLAYER', `TYPE: ${ type }`);
	}

	onPlay = e => {
		e.preventDefault();
		
		if(this.plyr) this.plyr.play();
	}
	
	onPause = e => {
		e.preventDefault();
		
		if(this.plyr) this.plyr.pause();
	}

	onClose = e => {
		e.preventDefault();

		this.props.hidePanel();
	}

	onSliderStart = (e, ui) => {
		this.setState({ sync: false });
		this.pause();
		this.seekPlayer(ui.value);
	}
	
	onSliderStop = (e, ui) => {
		this.seekPlayer(ui.value);
		this.setState({ sync: true });
	}

	onVolumeChange = (e, ui) => {
		this.changeVolume(ui.value);
	}

	// Video

	play = () => this.plyr.play()

	pause = () => this.plyr.pause()

	stop = () => {
		this.plyr.currentTime = 0;
		this.plyr.stop();
	}

	load = () => {
		const { callbacks: { onReady, onFirstPlay, onPlay, onPause, onEnded } } = this.props;
		const { autoplay } = this.state;

		const plyr = new Plyr('#stvVideoPlayer', {
			autoplay: autoplay || false,

			volume: 1,
			muted: false,
			clickToPlay: false,
			
			settings: [],
			controls: [],
			
			fullscreen: {
				enabled: false
			}
		});

		this.plyr = plyr;

		plyr.on('ready', () => {
			if(plyr) {
				plyr.volume = 1;
				plyr.muted = false;

				this.setState({ loaded: true }, () => {
					if(this.props.waves) this.createWave();
					this.seekVolumeSlider(plyr.volume);
					if(onReady) onReady(plyr);
				});
			}
		});

		plyr.once('play', () => {
			this.setState({ duration: plyr.duration || this.props.duration }, () => {
				if(this.sliderActions().setOption) {
					this.sliderActions().setOption('max', this.state.duration);
				}

				if(plyr && onFirstPlay) onFirstPlay(plyr);
			});
		});
		
		plyr.on('play', () => {
			if(plyr && onPlay) onPlay(plyr);
			this.setState({ playing: true, paused: false });
		});
		
		plyr.on('timeupdate', () => {
			if(this.state.sync) this.runSlider(plyr.currentTime);
		});
		
		plyr.on('pause', () => {
			if(plyr && onPause) onPause(plyr);
			this.setState({ playing: false, paused: true });
		});
		
		plyr.on('ended', () => {
			if(plyr && onEnded) onEnded(plyr);
			this.setState({ playing: false, paused: true }, this.stop);
		});
	}

	seekPlayer = value => {
		this.plyr.currentTime = value;
		this.play();
	}

	runSlider = value => {
		this.sliderActions().setValue(value);
	}

	sliderActions = () => {
		const slider = this.sliderRef.current;

		return {
			getValue: () => {
				if(slider) return slider.getValue();
				return 0;
			},

			setValue: value => {
				if(slider) slider.setValue(value);
			},

			setOption: (option, value) => {
				if(slider) slider.setOption(option, value);
			},
		};
	}

	// Volume
	
	seekVolumeSlider = volume => {
		const slider = this.volumeSliderRef.current;
		if(!slider) return;

		const value = volume * 100;
		slider.setValue(value);
	}

	changeVolume = value => {
		if(this.plyr) {
			this.plyr.volume = value / 100;
		}
	}

	// Waves

	createWave = () => {
		const wave = WaveSurfer.create({
			container: this.waveSurferRef.current,
			backend: 'MediaElement',

			barWidth: 2,
			barHeight: 1,
			barGap: null,

			waveColor: '#444444',
			progressColor: '#1976d2',
		});

		wave.on('ready', () => {
			this.setState({ wavesLoaded: true });
		});

		wave.on('error', () => {
			this.setState({ wavesLoaded: false });
		});

		this.wave = wave;

		this.loadWave();
	}

	loadWave = () => {
		if(this.wave) {
			const video = this.stvVideoPlayerRef.current;
			this.wave.load(video);
		}
	}

	renderVideoPlayer() {
		const { src, type } = this.props;

		return (
			<div className="stv-video-container">
				<video className="stv-player" src={ src } id="stvVideoPlayer" ref={ this.stvVideoPlayerRef }>
					<track kind="captions"/>
					<source src={ src } type={ type }/>
				</video>
			</div>
		);
	}

	renderSlider = () => {
		const { loaded, duration } = this.state;
		if(!loaded || !duration) return '';
		return <Slider active={ loaded } className="leg-progress" ref={ this.sliderRef } onStart={ this.onSliderStart } onStop={ this.onSliderStop }/>
	}

	renderWaves = () => {
		const { waves } = this.props;
		const { loaded, wavesLoaded } = this.state;

		if(!loaded || !waves || !wavesLoaded) return '';

		return <div className={ `audio-visualizer ${ !wavesLoaded ? 'd-none' : '' }` } ref={ this.waveSurferRef }/>;
	}

	renderVolumeControls = () => {
		const { loaded } = this.state;
		const { playerType, playbackType } = this.props;

		if(!loaded || (playbackType !== 'playback')) return '';

		return (
			<div className={ `leg-progress-components global-volume-controls ${ loaded ? 'active' : '' }` } data-player-type={ playerType }>
				<Slider active={ loaded } orientation="vertical" className="leg-progress progress-solid" ref={ this.volumeSliderRef } onSlide={ this.onVolumeChange }/>
			</div>
		);
	}

	renderDefaultLayout = () => {
		const { playerType } = this.props;
		const { loaded, playing, paused } = this.state;

		return (
			<div className={ `leg-player-container leg-video-player ${ playerType }-player` } data-player="video" data-player-type={ playerType }>
				<div className="google-streetview-container">
					<div className="streetview-inner">
						{ this.renderVideoPlayer() }
					</div>
				</div>
				<div className="leg-content-container" id="cntLegContent">
					<div className="leg-content-inner">
						<div className="leg-actions-details">
							<div className="leg-actions">
								{ this.renderVolumeControls() }
								<div className="leg-controls legControls">
									<ul>
										<li className={ (!playing || paused) ? 'd-block' : 'd-none' } data-uk-tooltip="{'pos': 'top'}" title="Play">
											<Link to="#graphicPanel" className="btn-play btnPlay" onClick={ this.onPlay } data-atn>
												<i className="material-icons">&#xE037;</i>
											</Link>
										</li>
										<li className={ (playing || !paused) ? 'd-block' : 'd-none' } data-uk-tooltip="{'pos': 'top'}" title="Pause">
											<Link to="#graphicPanel" className="btn-pause btnPause bg-primary text-white" onClick={ this.onPause } data-atn>
												<i className="material-icons">pause</i>
											</Link>
										</li>
									</ul>
								</div>
								<div className="leg-sliders-controls">
									<div className="leg-volume-controls"/>
									<div className={ `leg-progress-components ${ loaded ? 'active' : '' }` }>
										{ this.renderSlider() }
									</div>
								</div>
							</div>
							<div className="leg-details legDetails"/>
						</div>
						<div className="leg-map legMap">
							{ this.renderWaves() }
						</div>
					</div>
				</div>
			</div>
		);
	}
	
	renderHomepageLayout = () => {
		const { playerType } = this.props;
		const { loaded, playing, paused } = this.state;

		return (
			<div className={ `streetview active video-player ${ playerType }-player` } data-player="video" data-player-type={ playerType }>
				<div className="streetview-pano" id="streetviewPano" data-rpane ref={ this.cntStreetviewPano }>
					{ this.renderVideoPlayer() }
					<div className={ `leg-progress-components ${ loaded ? 'active' : '' }` }>
						{ this.renderSlider() }
					</div>
					<div className="controls-container">
						<div className="streetview-pano-controls" id="streetviewPanoControls">
							<ul className="primary-controls">
								<li className={ (!playing || paused) ? 'd-block' : 'd-none' }>
									<Link to="#link" className="_msdc atn-play atnPlay lg" onClick={ this.onPlay } data-atn>
										<img src={ `${ Global.ASSETS_BASE_URL }/icons/streetview/play.svg` } className="img-responsive" alt=""/>
									</Link>
								</li>
								<li className={ (playing && !paused) ? 'd-block' : 'd-none' }>
									<Link to="#link" className="_msdc atn-play atnPlay lg" onClick={ this.onPause } data-atn>
										<img src={ `${ Global.ASSETS_BASE_URL }/icons/streetview/pause.svg` } className="img-responsive" alt=""/>
									</Link>
								</li>
							</ul>
							<ul className="secondary-controls">
								<li>
									<Link to="#link" className="_msdc atn-close atnClose" onClick={ this.onClose }>
										<img src={ `${ Global.ASSETS_BASE_URL }/icons/streetview/close.svg` } className="img-responsive" alt=""/>
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		const { layout } = this.props;

		switch(layout) {
			case 'default': return this.renderDefaultLayout();
			case 'homepage': return this.renderHomepageLayout();
			default: return <div className="d-none"/>;
		}
	}
}

/* ----------  Prop Types  ---------- */

LegVideoPlayer.defaultProps = {
	waves: false,

	playerType: 'header',
	playbackType: '',

	duration: 0,

	callbacks: {
		onReady: false,
		onFirstPlay: false,
		onPlay: false,
		onPause: false,
		onEnded: false,
	}
}

LegVideoPlayer.propTypes = {
	waves: PropTypes.bool,

	src: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	layout: PropTypes.string.isRequired,
	playerType: PropTypes.string,
	playbackType: PropTypes.string,

	hidePanel: PropTypes.func.isRequired,
	
	duration: PropTypes.number,

	callbacks: PropTypes.shape({
		onReady: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
		onFirstPlay: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
		onPlay: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
		onPause: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
		onEnded: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	})
}

/* ----------  Exports  ---------- */

export default LegVideoPlayer;
