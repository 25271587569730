/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
// import { map } from 'lodash';

// Modal
import Modal from './../../UIkit/Modal';
import Input from './../../UIkit/Input';

// Utils
import Selectize from './../../Utils/Selectize';

// Filters
import FilterItem from './../../MyTours/Filters/FilterItem';
import FilterDropdown from './../../MyTours/Filters/FilterDropdown';

// Preloader
// import Preloader from './../../Common/Preloader';

/* ----------  Scripts  ---------- */

class ModalTourStats extends React.Component {
	constructor(props) {
		super(props);

		this.modal = React.createRef();
		this.filterDropdownRef = React.createRef();

		this.state = {
			loading: false,

			filters: {
				type: '',
				startDate: '',
				endDate: '',
			},

			typeOptions: [{
				label: 'Click Through',
				value: 10,
			},{
				label: 'Impressions',
				value: 20,
			}, {
				label: 'Subsribers',
				value: 30,
			}],

			all: {
				value: 'all',
				checked: false,
				type: 'checkbox',
				name: 'countries',
				indeterminate: false,
			}
		}
	}

	componentDidMount() {}

	onShow = () => {
		this.props.onShow();
	}
	
	onHide = () => {
		this.props.onHide();
	}

	// handleType = value => {}

	show = () => {
		const modal = this.modal.current;

		modal.show();
	}

	hide = () => {
		const modal = this.modal.current;

		modal.hide();
	}

	render() {
		const { id } = this.props;
		const { typeOptions, loading, all, filters } = this.state;

		return (
			<Modal
				popup="nodeDetails" 
				onShow={ this.onShow }
				onHide={ this.onHide }
				data-node-id={ id }
				ref={ this.modal }>
				<div className="uk-modal-dialog uk-modal-dialog-large">
					<div className="uk-modal-header">
						<h3 className="uk-modal-title">Tour Stats</h3>
					</div>
					<div className="uk-modal-body">
						<div className="stats-filters m-b-30">
							<div className="filters-title d-none">
								<h4 className="uk-modal-title">Filters:</h4>
							</div>
							<div className="filters-content">
								<div className="uk-grid uk-grid-small">
									<div className="uk-width-medium-2-4">
										<div className="uk-grid uk-grid-small">
											<div className="uk-width-small-1-2">
												<div className="md-form-group">
													<Selectize name="type" id="txtStatType" data={ typeOptions } value={ filters.type } onChange={ this.handleType }/>
												</div>
											</div>
											<div className="uk-width-small-1-2">
												<div className="md-form-group">
													<FilterDropdown
														theme="dark"
														title="All Nations"
														id="tourStatsCountries"
														loading={ loading }
														ref={ this.filterDropdownRef }>
														<div className="default-filters filter-list">
															<FilterItem
																id="tourStatsAllCountries"
																label="All Nations"
																toggle={ all }
																onChange={ () => {} }/>
														</div>
														<div className="item-divider"/>
													</FilterDropdown>
												</div>
											</div>
										</div>
									</div>
									<div className="uk-width-medium-2-4">
										<div className="uk-grid uk-grid-small">
											<div className="uk-width-small-1-2">
												<div className="md-form-group">
													<div className="uk-input-group">
														<span className="uk-input-group-addon">
															<i className="uk-input-group-icon uk-icon-calendar"/>
														</span>
														<Input
															readOnly
															disabled
															name="startDate"
															title="Start Date"
															id="txtStatStartDate"
															autoComplete={ false }
															value={ filters.startDate }
															onChange={ () => {} }/>
													</div>
												</div>
											</div>
											<div className="uk-width-small-1-2">
												<div className="md-form-group">
													<div className="uk-input-group">
														<span className="uk-input-group-addon">
															<i className="uk-input-group-icon uk-icon-calendar"/>
														</span>
														<Input
															readOnly
															disabled
															name="endDate"
															title="End Date"
															id="txtStatEndDate"
															autoComplete={ false }
															value={ filters.endDate }
															onChange={ () => {} }/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="uk-modal-footer">
						<Link to="#closeModal" className="md-btn md-btn-flat md-btn-flat-default md-btn-wave waves-effect waves-button uk-modal-close">
							Close
						</Link>
					</div>
				</div>
			</Modal>
		);
	}
}

/* ----------  Prop Types  ---------- */

ModalTourStats.defaultProps = {
	id: '',

	loading: true
}

ModalTourStats.propTypes = {
	id: PropTypes.string,
	
	// loading: PropTypes.bool,

	onShow: PropTypes.func.isRequired,
	onHide: PropTypes.func.isRequired,
}

/* ----------  Exports  ---------- */

export default ModalTourStats;
