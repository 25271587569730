/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Status
import Status from './Status';

// Status Constants
import STATUS from './../../Constants/Status';

// Utils
import ProfilePicture from './../../Components/Utils/ProfilePicture';

/* ----------  Scripts  ---------- */

class ContactListItem extends React.Component {
	componentDidMount() {}

	handleItem = e => {
		e.preventDefault();
		e.stopPropagation();

		const { data } = this.props;

		if(!data.blocked) {
			this.props.handleItem([ data.contactId ]);
		}
	}

	handleContext = e => {
		e.preventDefault();
		e.stopPropagation();

		const { data, handleContext } = this.props;

		const props = {
			refId: data.contactId,
			block: data.blocked
		}

		handleContext('contact', props, e.currentTarget)
	}

	render() {
		const { data } = this.props;

		return (
			<Link 
				to={ `#contact_${ data.contactId }` }
				className={ `contact ripple row no-gutters align-items-center pl-4 ${ data.blocked ? 'contact-blocked' : '' }` }
				data-contact-item
				data-contact-item-id={ data.contactId }
				onClick={ this.handleItem }>
				<div className="contact-context">
					<span className="atn-context atnContext btnChatContext" role="button" tabIndex="-1" onClick={ this.handleContext }>
						<i className="material-icons">more_vert</i>
					</span>
				</div>
				<div className="avatar-wrapper">
					<ProfilePicture title={ data.name } className="avatar" src={ data.photo }/>
					<Status type={ data.status }/>
					<Status visible={ data.blocked } type={ STATUS.type.blocked }/>
				</div>
				<div className="col pl-4">
					<div className="h6 name">
						{ data.name }
					</div>
					<div className="mood text-muted">
						{ data.mood }
					</div>
				</div>
			</Link>
		);
	}
}

/* ----------  Prop Types  ---------- */

ContactListItem.propTypes = {
	handleItem: PropTypes.func.isRequired,
	handleContext: PropTypes.func.isRequired,

	data: PropTypes.shape({
		contactId: PropTypes.string.isRequired
	}).isRequired
}

/* ----------  Exports  ---------- */

export default ContactListItem;