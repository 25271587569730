/* ----------  Imports  ---------- */

// Redux
import { compose, createStore, applyMiddleware } from 'redux';

// Redux Thunk
import thunk from 'redux-thunk';

// Redux Logger
import { createLogger } from 'redux-logger';

// Reducer
import RootReducer from './Reducers';

/* ----------  Scripts  ---------- */

const logger = createLogger({
	diff: true,
	collapsed: true,
	predicate: () => false
});

const middleware = [thunk, logger];

const enhancers = compose(
	applyMiddleware(...middleware),
	window.devToolsExtension ? window.devToolsExtension() : f => f
);

const Store = createStore(
	RootReducer,
	{},
	enhancers
);

/* ----------  Exports  ---------- */

export default Store;