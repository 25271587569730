/* ----------  Scripts  ---------- */

const RemoveCart = () => dispatch => (
	dispatch({
		type: 'REMOVE_CART',
	})
);

/* ----------  Exports  ---------- */

export default RemoveCart;