/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { map } from 'lodash';
// import { map, isEqual, isEmpty } from 'lodash';

// Selectize
import 'selectize';

// jQuery
const $ = window.$;

/* ----------  Scripts  ---------- */

const EASING_SWIFT_OUT = [0.35, 0, 0.25, 1];

class Selectize extends React.Component {
	constructor(props) {
		super(props);

		this.api = {};
		
		this.select = React.createRef();
		this.cntSelect = React.createRef();
	}

	componentDidMount() {
		this.load();
	}
	
	componentWillUnmount() {
		this.destroy();
	}

	onChange = e => {
		const { name, value } = e.currentTarget;
		const { onChange } = this.props;
		
		if(onChange) onChange(name, value);
	}

	getValue = () => this.api.getValue()

	getItem = value => {
		const $cntSelect = $(this.cntSelect.current);
		const $item = $(`[data-value="${ value }"]`, $cntSelect);

		return $item;
	}

	// Item Templates

	getItemTemplate = (item, escape) => {
		const { type } = this.props;

		switch(type) {
			case 'toggle': return this.getToggleItemTemplate(item, escape);
			case 'language': return this.getLanguageItemTemplate(item, escape);
			default: return this.getDefaultItemTemplate(item, escape);
		}
	}

	getDefaultItemTemplate = (item, escape) => {
		if(item.help) return `<div class="item"><strong>${ escape(item.label) }</strong> <span class="text-muted">- ${ item.help }</span></div>`;
		return `<div class="item">${ escape(item.label) }</div>`;
	}

	getToggleItemTemplate = (item, escape) => (`
		<div class="item has-toggle itemLabel">
			<span class="item-toggle">
				<i class="far fa-dot-circle toggle-icon icon-static"></i>
				<i class="fas fa-check-circle toggle-icon icon-active"></i>
			</span>
			<span class="item-text">${ escape(item.label) }</span>
		</div>
	`)

	getLanguageItemTemplate = (item, escape) => (`
		<div class="item item-language">
			<i class="item-icon flag-icon flag-icon-${ escape(item.flag) }"></i>
		</div>
	`)

	// Option Templates

	getOptionTemplate = (item, escape) => {
		const { type } = this.props;

		switch(type) {
			case 'language': return this.getLanguageOptionTemplate(item, escape);
			default: return this.getDefaultOptionTemplate(item, escape);
		}
	}

	getDefaultOptionTemplate = (item, escape) => {
		if(item.help) return `<div class="option"><strong>${ escape(item.label) }</strong> <span class="text-muted">- ${ item.help }</span></div>`;
		return `<div class="option">${ escape(item.label) }</div>`;
	}

	getLanguageOptionTemplate = (item, escape) => (`
		<div class="option">
			<i class="item-icon flag-icon flag-icon-${ escape(item.flag) }"></i>
			<span>${ escape(item.label) }</span>
		</div>
	`)

	setValue = value => this.api.setValue(value)
	
	setSelected = value => {
		const $item = this.getItem(value);

		if($item.length) $item.addClass('selected');
	}

	setPrimary = value => {
		const { setPrimary } = this.props;

		if(setPrimary) setPrimary(value);

		this.setSelected(value);
	}

	addTag = value => {
		const option = {
			value,
			text: value,
		}

		this.api.addOption(option);
	}

	changeValue = value => {
		this.api.setValue(value);
	}

	enable = () => {
		if(this.api) this.api.enable();
	}

	disable = () => {
		if(this.api) this.api.disable();
	}

	destroy = () => {
		if(this.api) this.api.destroy();
	}

	handleItemClick = () => {
		const cntSelect = this.cntSelect.current;
		const $cntSelect = $(cntSelect);

		$cntSelect.off('click.item').on('click.item', '.itemLabel', e => {
			e.preventDefault();

			const $items = $('.itemLabel', $cntSelect);
			const $item = $(e.currentTarget);
			const itemId = $item.data('value');

			$items.removeClass('selected');
			$item.addClass('selected');

			this.setPrimary(itemId);
		});
	}

	loadDefault = () => {
		const select = this.select.current;
		const { data, value, maxItems, name, onChange, dropdownParent, multiple, placeholder, search } = this.props;

		$(select).selectize({
			maxItems,
			placeholder,
			dropdownParent,

			options: data,
			create: false,
			hideSelected: true,
			valueField: 'value',
			labelField: 'label',
			searchField: ['label'],
			closeAfterSelect: true,
			
			plugins: {
				'remove_button': { label: '' },
			},

			render: {
				item: (item, escape) => this.getItemTemplate(item, escape),
				option: (item, escape) => this.getOptionTemplate(item, escape),
			},

			onChange: val => {
				if(onChange) onChange(name, val);
			},

			onItemAdd: val => {
				this.removeSelected(val);
			},

			onItemRemove: val => {
				this.unsetPrimary(val);
			},

			onDropdownOpen: ($dropdown) => {
				$dropdown.hide().velocity('slideDown', {
					duration: 200,
					easing: EASING_SWIFT_OUT,

					begin: () => {
						const margin = multiple ? 0 : -33

						$dropdown.css({ 'margin-top': margin });
					},
				});
			},

			onDropdownClose: ($dropdown) => {
				$dropdown.show().velocity('slideUp', {
					duration: 200,
					easing: EASING_SWIFT_OUT,
					
					complete: () => {
						$dropdown.css({ 'margin-top': '' })
					},
				});
			}
		});

		if(!search) $(select).next().children('.selectize-input').find('input').attr('readonly', true);

		this.api = select.selectize;

		this.setValue(value);
	}

	loadTags = () => {
		const select = this.select.current;
		const { name, value, onChange } = this.props;

		$(select).selectize({
			delimiter: ',',
			persist: false,

			create: input => ({
				value: input,
				text: input
			}),

			onDropdownOpen: ($dropdown) => {
				$dropdown.hide().velocity('slideDown', {
					begin: () => {
						$dropdown.css({'margin-top': 0})
					},
					duration: 200,
					easing: EASING_SWIFT_OUT
				});
			},

			onChange: val => {
				if(onChange) onChange(name, val);
			},
		});

		this.api = select.selectize;

		this.api.clearOptions();
		this.api.clearCache();
		this.api.clear();

		if(value.length) {
			this.addTag(value);
			this.setValue(value);
		}
	}

	load = () => {
		const { tags } = this.props;

		if(tags) {
			this.loadTags();
		} else {
			this.loadDefault();
		}

		this.handleItemClick();
	}

	removeSelected = value => {
		const $item = this.getItem(value);

		if($item.length) $item.removeClass('selected');
	}

	unsetPrimary = value => {
		const { unsetPrimary } = this.props;

		if(unsetPrimary) {
			unsetPrimary(value);
			this.removeSelected(value);
		}
	}

	updateData = () => {
		const { data } = this.props;

		this.api.clearOptions();

		map(data, item => {
			this.api.addOption(item);
			this.api.addItem(item.value);
		});
	}

	renderOptions = () => {
		const { data } = this.props;

		if(!data.length) return <option value={ -1 } disabled>-- No Languages --</option>;

		return map(data, (item, i) => <option value={ item.value } key={ `${ item.value }_${ i }` }>{ item.label }</option>);
	}

	render() {
		const { type, className, name, id, value, multiple } = this.props;

		return (
			<div className={ `md-selectize ${ type }-selectize` } ref={ this.cntSelect }>
				<select
					id={ id }
					name={ name }
					value={ value }
					ref={ this.select }
					multiple={ multiple }
					className={ className }
					onChange={ this.onChange }/>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

Selectize.defaultProps = {
	data: [],
	search: false,

	maxItems: 1,
	value: -1,

	className: '',
	type: 'default',
	dropdownParent: 'body',
	placeholder: 'Select',

	tags: false,
	toggles: false,
	multiple: false,
	onChange: false,
	setPrimary: false,
	unsetPrimary: false,
}

Selectize.propTypes = {
	tags: PropTypes.bool,
	multiple: PropTypes.bool,
	search: PropTypes.bool,

	maxItems: PropTypes.number,
	
	type: PropTypes.string,
	className: PropTypes.string,
	placeholder: PropTypes.string,
	id: PropTypes.string.isRequired,
	dropdownParent: PropTypes.string,
	name: PropTypes.string.isRequired,

	data: PropTypes.arrayOf(PropTypes.object),
	
	onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	setPrimary: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	unsetPrimary: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),

	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
}

/* ----------  Exports  ---------- */

export default Selectize;
