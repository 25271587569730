/* ----------  Imports  ---------- */

// Imports
import React from 'react';

// React Redux
import { connect } from 'react-redux';

// Redux
import { bindActionCreators } from 'redux';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { map, debounce } from 'lodash';

// Tree
import DataTree from './../../../Components/MyTours/Tree/DataTree';

// Preloader
import Preloader from './../../../Components/Common/Preloader';

// Node Actions
import GetNodes from './../../../Redux/Actions/MyTours/Trees/GroupedToursTree/GetNodes';
import RemoveNodes from './../../../Redux/Actions/MyTours/Trees/GroupedToursTree/RemoveNodes';

// Node Details
import GetNodeDetails from './../../../Redux/Actions/MyTours/Trees/GetNodeDetails';
import RemoveNodeDetails from './../../../Redux/Actions/MyTours/Trees/RemoveNodeDetails';

// Action Panel Actions
import GetActionPanelNodes from './../../../Redux/Actions/MyTours/Trees/GroupedToursTree/GetActionPanelNodes';
import RemoveActionPanelNodes from './../../../Redux/Actions/MyTours/Trees/GroupedToursTree/RemoveActionPanelNodes';

// Create Actions
import CreateGroup from './../../../Redux/Actions/MyTours/Trees/Utils/CreateGroup';

// Add Tour To Group
import AddToursToGroup from './../../../Redux/Actions/MyTours/Trees/Utils/AddToursToGroup';

// Publish Actions
import PublishTours from './../../../Redux/Actions/MyTours/Trees/Publish/PublishTours';

// Node Utils
import MoveNodes from './../../../Redux/Actions/MyTours/Trees/Utils/MoveNodes';
import MoveNodeUp from './../../../Redux/Actions/MyTours/Trees/Utils/MoveNodeUp';
import DeleteNode from './../../../Redux/Actions/MyTours/Trees/Utils/DeleteNode';
import RenameNode from './../../../Redux/Actions/MyTours/Trees/Utils/RenameNode';
import MoveNodeDown from './../../../Redux/Actions/MyTours/Trees/Utils/MoveNodeDown';

// Tree
import PublishTree from './../../../Components/MyTours/Tree/PublishTree';

// Helpers
import Notify from './../../../Helpers/Notify';

/* ----------  Scripts  ---------- */

class PublishTreePanel extends DataTree {
	constructor(props) {
		super(props);

		this.treeRef = React.createRef();

		this.state = {
			tourIds: [],
			groupId: null,
			saving: false,
		}

		this.requestCreateGroup = debounce(this.doCreateGroup, 100);
		this.requestPublishTours = debounce(this.doPublishTours, 100);
		this.requestAddToursToGroup = debounce(this.doAddToursToGroup, 100);
	}

	componentWillMount() {
		this.getTreeNodes();
	}

	onSave = e => {
		e.preventDefault();

		this.requestAddToursToGroup()
	}

	onCancel = e => {
		e.preventDefault();

		this.hidePanel();
	}

	onPanelCtxClick = e => {
		e.preventDefault();

		const { refId, nodeId } = this.props;

		const props = {
			refId,
			nodeId,
		}

		this.handleContext('publishTree', props, e.currentTarget);
	}

	getTreeNodes = () => {
		const params = {
			recursive: true
		}

		this.getNodes(params);
	}

	getData = () => {
		const treeRef = this.treeRef.current;

		if(treeRef) return treeRef.getData();

		return [];
	}

	doCreateGroup = (data, success, fail) => {
		this.props.createGroup(data, success, fail);
	}

	doAddToursToGroup = (success, fail) => {
		const { title } = this.props;
		const { groupId, tourIds } = this.state;

		const data = {
			groupId,
			tourIds,
			publish: true
		}

		this.setState({ saving: true });

		this.props.addToursToGroup(data, () => {
			this.setState({ saving: false }, this.hidePanel);

			Notify.success(`${ title } published to group successfully!`);

			if(success) success();
		}, (status, reasons) => {
			this.setState({ saving: false });

			map(reasons, reason => Notify.error(reason.message));

			if(fail) fail();
		});
	}

	doPublishTours = (data, success, fail) => {
		this.props.publishTours(data, () => {
			Notify.success(`Tour unpublished successfully!`);

			if(success) success();
		}, (status, reasons) => {
			map(reasons, reason => Notify.error(reason.message));

			if(fail) fail();
		});
	}

	createGroup = (opts, success, fail) => {
		const tree = this.treeRef.current;
		const { baseNodeType } = this.props;

		const data = {
			baseNodeType,
			...opts
		}

		this.requestCreateGroup(data, (status, response) => {
			tree.createNode(response);
			
			if(success) success(status, response);
		}, (status, reasons) => {
			if(fail) fail(reasons);
		});
	}

	handleContext = (type, props, target, event) => this.props.handleContext(type, props, target, event)

	hidePanel = () => {
		this.props.hidePanel();
	}

	handleNode = groupId => {
		const { tourId } = this.props;

		this.setState({
			groupId,
			tourIds: [tourId],
		});
	}

	moveNodeUp = data => {
		const tree = this.treeRef.current;
		const index = tree.getNextUpIndex(data.refId, -1);
		const min = data.type === 30 ? 1 : 0;

		if(index >= min) {
			tree.moveNodeDir(data.refId, index, () => {
				console.log(data.refId);
				this.updateSelected();

				const reqData = {
					nodeId: data.nodeId
				}

				this.requestMoveNodeUp(reqData);
			});
		}
	}

	moveNodeDown = data => {
		const tree = this.treeRef.current;
		const index = tree.getNextDownIndex(data.refId, +1);

		if(index > 0) {
			tree.moveNodeDir(data.refId, index, () => {
				this.updateSelected();

				const reqData = {
					nodeId: data.nodeId
				}

				this.requestMoveNodeDown(reqData);
			});
		}
	}

	updateSelected = () => {
		const treeRef = this.treeRef.current;

		treeRef.updateSelected(this.state.groupId);
	}

	unpublishTours = (refId, objectId) => {
		const tree = this.treeRef.current;

		const data = {
			tourIds: [objectId],
			publish: false,
		}

		tree.unpublishTour(refId);
		this.requestPublishTours(data);
	}

	renderTree = () => {
		const { loading } = this.state;
		const { active } = this.props;

		if(!active || loading) return <Preloader loading={ loading } center minimal/>;

		const { tourId, nodes, nodesData, getNodes } = this.props;

		return (
			<PublishTree
				nodes={ nodes }
				tourId={ tourId }
				type="publishTree"
				ref={ this.treeRef }
				prefix="publishTree_"
				getNodes={ getNodes }
				nodesData={ nodesData }
				allowed={["publishTree"]}
				handleTree={ this.handleTree }
				handleNode={ this.handleNode }
				handleContext={ this.handleContext }/>
		);
	}

	renderActionItems = () => {
		const { saving, groupId, loading } = this.state;
		const { tourId } = this.props;

		if(saving) return <li className="atn-saving disabled"><Link to="#atp">Saving...</Link></li>;
		if(!tourId) return <li className="atn-cancel"><Link to="#atp" className="btnClose" onClick={ this.onCancel }>Close</Link></li>;

		return (
			<React.Fragment>
				<li className={ `atn-cancel ${ loading ? 'disabled' : '' }` }><Link to="#atp" className="btnClose" onClick={ this.onCancel }>Close</Link></li>
				<li className={ `atn-save ${ !groupId || loading ? 'disabled' : '' }` }><Link to="#atp" className="btnSave" onClick={ this.onSave }>Save</Link></li>
			</React.Fragment>
		);
	}

	render() {
		const { refId, tree, active, type } = this.props;

		return (
			<div className={ `tour-panel tour-action-panel panel-type-${ type } ${ active ? 'panel-active' : '' }` } id={ `${ tree }_actionPanel` } data-ref-id={ refId } data-panel data-panel-tree={ tree }>
				<div className="panel-inner">
					<div className="panel-title">
						<h3 className="panelTitle">Publish To:</h3>
						<ul className="sequenceTitleActions">
							<li>
								<Link
									to="#publishPanel"
									className="atn atn-context atnContext"
									data-uk-tooltip="{pos: top}"
									title="More Options"
									onClick={ this.onPanelCtxClick }>
									<i className="material-icons">&#xE5D4;</i>
								</Link>
							</li>
						</ul>
					</div>
					<div className="panel-list uk-position-relative panelList p-0">
						{ this.renderTree() }
					</div>
					<div className="panel-actions">
						<ul>
							{ this.renderActionItems() }
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

PublishTreePanel.defaultProps = {
	refId: '',
	tree: 'group',
	active: true,
}

PublishTreePanel.propTypes = {
	refId: PropTypes.string,
	tree: PropTypes.string,
	
	active: PropTypes.bool,
	
	hidePanel: PropTypes.func.isRequired,

	addToursToGroup: PropTypes.func.isRequired,
	
	publishTours: PropTypes.func.isRequired,
}

/* ----------  Redux Scripts  ---------- */

const mapStateToProps = state => ({
	nodes: state.mytours.trees.groupedTours.nodes,
	nodesData: state.mytours.trees.groupedTours.nodesData,
	
	nodeDetails: state.mytours.trees.nodeDetails
});

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		getNodes: GetNodes,
		removeNodes: RemoveNodes,
		
		getNodeDetails: GetNodeDetails,
		removeNodeDetails: RemoveNodeDetails,

		getActionPanelNodes: GetActionPanelNodes,
		removeActionPanelNodes: RemoveActionPanelNodes,

		moveNodeUp: MoveNodeUp,
		moveNodeDown: MoveNodeDown,
		
		moveNodes: MoveNodes,
		renameNode: RenameNode,
		deleteNode: DeleteNode,
		createGroup: CreateGroup,

		addToursToGroup: AddToursToGroup,
		
		publishTours: PublishTours,
	}, dispatch)
);

/* ----------  Exports  ---------- */

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(PublishTreePanel);
