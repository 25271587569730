/* ----------  Imports  ---------- */

// Axios
import axios from 'axios';

// Constants
import Global from './../../../Constants/Global';

/* ----------  Scripts  ---------- */

const GetTour = (params, success, fail) => dispatch => {
	// const url = `${ Global.DUMMY_API_BASE_URL }/EJ3KLR28r`;
	const url = `${ Global.API_BASE_URL }/tours`;

	axios.get(url, { params }).then(response => {
		const { status, result } = response.data;

		dispatch({
			type: 'HOME_GET_TOUR',
			payload: result
		});

		if(success) success(status);
	}).catch(error => {
		const { response } = error;
		
		if(fail) {
			if(response) {
				fail(response.data.status, response.data.error.reasons);
			} else {
				fail(false, []);
			}
		}
	});
}

/* ----------  Exports  ---------- */

export default GetTour;
