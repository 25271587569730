/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Redux
import { connect } from 'react-redux';

// Redux
import { bindActionCreators } from 'redux';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { debounce, map, get, isEmpty, first } from 'lodash';

// Constants
// import Global from './../../Constants/Global';

// Tabs
import Tabs from './../../Components/UIkit/Tabs';

// Preloader
import Preloader from './../../Components/Common/Preloader';

// Components
import Card from '../../Components/Profile/Card';

// Utils
import ProfilePicture from './../../Components/Utils/ProfilePicture';

// Languages Actions
import GetLanguages from './../../Redux/Actions/Languages/GetLanguages';

// User Actions
import GetUserProfile from './../../Redux/Actions/User/GetUserProfile';

// Payment Actions
import RemoveCreditCard from './../../Redux/Actions/User/CreditCard/RemoveCreditCard';

// Debit Card Actions
import GetDebitCards from './../../Redux/Actions/User/DebitCard/GetDebitCards';
import RemoveDebitCard from './../../Redux/Actions/User/DebitCard/RemoveDebitCard';
import RemoveDebitCards from './../../Redux/Actions/User/DebitCard/RemoveDebitCards';

// Currencies Actions
import GetCurrencies from './../../Redux/Actions/Currencies/GetCurrencies';

// Helpers
import Notify from './../../Helpers/Notify';

/* ----------  Scripts  ---------- */

class ProfileView extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			paymentsLoading: false,

			tabs: {
				about: {
					loading: true
				},

				languages: {
					loading: true
				},

				payments: {
					loading: true
				}
			}
		}

		this.requestProfile = debounce(this.doRequestProfile, 500);
		this.requestCurrencies = debounce(this.doRequestCurrencies, 500);
		
		this.getDebitCards = debounce(this.doRequestDebitCards, 500);
	}

	componentWillMount() {
		this.getLanguages();
		this.getCurrencies();

		this.getProfile();
		this.getDebitCards();
	}

	componentDidMount() {}

	getLanguages = () => {
		this.props.getLanguages();
	}

	getCurrencies = () => {
		this.requestCurrencies();
	}

	getProfile = () => {
		// this.updateTabsState('about', { loading: true }, this.requestProfile);
		this.setState({ loading: true }, this.requestProfile);
	}

	getLanguageClass = lang => {
		switch(lang) {
			case 'en': return 'gb';
			default: return lang;
		}
	}

	getSubscribers = () => {
		const { user: { profile: { legs, tours } } } = this.props;

		if(!legs || !tours) return 0;

		return legs.subscribers + tours.subscribers;
	}

	getSubscriptions = () => {
		const { user: { profile: { legs, tours } } } = this.props;

		if(!legs || !tours) return 0;

		return legs.subscriptions + tours.subscriptions;
	}

	getSuperscribers = () => {
		const { user: { profile: { legs, tours } } } = this.props;

		if(!legs || !tours) return 0;

		return legs.superscribers + tours.superscribers;
	}

	doRequestCurrencies = () => {
		this.props.getCurrencies();
	}

	doRequestProfile = () => {
		this.props.getUserProfile(() => {
			this.setState({ loading: false });
		});
	}
	
	doRequestDebitCards = () => {
		this.setState({ debitCardsLoading: true });
		this.removeDebitCards();

		this.props.getDebitCards(() => {
			this.setState({ debitCardsLoading: false });
		}, () => {
			this.setState({ debitCardsLoading: false });
		});
	}

	updateTabsState = (tab, state, callback) => {
		const { tabs } = this.state;

		this.setState({
			tabs: {
				...tabs,
				[tab]: {
					...tabs[tab],
					...state
				}
			}
		}, callback ? callback() : null);
	}

	removeCreditCard = cardId => {
		this.setState({ paymentsLoading: true });

		this.props.removeCreditCard(cardId, status => {
			this.setState({ paymentsLoading: false });

			if(status) {
				Notify.success('Payment method deleted!');
			} else {
				Notify.error('Oops! Try again.');
			}
		});
	}
	
	removeDebitCard = cardId => {
		this.setState({ debitCardsLoading: true });

		this.props.removeDebitCard(cardId, status => {
			this.setState({ debitCardsLoading: false });

			if(status) {
				Notify.success('Debit card method deleted!');
			} else {
				Notify.error('Oops! Try again.');
			}
		});
	}

	removeDebitCards = () => {
		this.props.removeDebitCards();
	}

	renderAboutTabContent = () => {
		const { user: { profile } } = this.props;

		return (
			<div className="uk-switcher-content about-content">
				<p>{ profile.about }</p>
				<div className="uk-grid uk-margin-medium-top uk-margin-medium-bottom" data-uk-grid-margin>
					<div className="uk-width-large-1-2">
						<h4 className="heading_c uk-margin-small-bottom">Contact Info</h4>
						<ul className="md-list md-list-addon">
							<li>
								<div className="md-list-addon-element">
									<i className="md-list-addon-icon material-icons">&#xE158;</i>
								</div>
								<div className="md-list-content">
									<span className="md-list-heading">{ profile.email }</span>
									<span className="uk-text-small uk-text-muted">Email</span>
								</div>
							</li>
							<li>
								<div className="md-list-addon-element">
									<i className="md-list-addon-icon material-icons">&#xE88A;</i>
								</div>
								<div className="md-list-content">
									<span className="md-list-heading">{ profile.address }</span>
									<span className="uk-text-small uk-text-muted">Address</span>
								</div>
							</li>
							<li>
								<div className="md-list-addon-element">
									<i className="md-list-addon-icon material-icons">&#xE0CD;</i>
								</div>
								<div className="md-list-content">
									<span className="md-list-heading">{ profile.phone }</span>
									<span className="uk-text-small uk-text-muted">Phone</span>
								</div>
							</li>
							<li>
								<div className="md-list-addon-element">
									<i className="md-list-addon-icon material-icons">&#xE0CD;</i>
								</div>
								<div className="md-list-content">
									<span className="md-list-heading">{ profile.phoneAlt }</span>
									<span className="uk-text-small uk-text-muted">Phone</span>
								</div>
							</li>
							<li>
								<div className="md-list-addon-element">
									<i className="md-list-addon-icon fas fa-money-bill-wave"/>
								</div>
								<div className="md-list-content">
									{ this.renderCurrency() }
									<span className="uk-text-small uk-text-muted">Currency</span>
								</div>
							</li>
						</ul>
					</div>
					<div className="uk-width-large-1-2">
						<h4 className="heading_c uk-margin-small-bottom">Social Info</h4>
						<ul className="md-list md-list-addon">
							<li>
								<div className="md-list-addon-element">
									<i className="md-list-addon-icon uk-icon-facebook-official"/>
								</div>
								<div className="md-list-content">
									<span className="md-list-heading">{ profile.social ? profile.social.facebook : 'N/A' }</span>
									<span className="uk-text-small uk-text-muted">Facebook</span>
								</div>
							</li>
							<li>
								<div className="md-list-addon-element">
									<i className="md-list-addon-icon uk-icon-twitter"/>
								</div>
								<div className="md-list-content">
									<span className="md-list-heading">{ profile.social ? profile.social.twitter : 'N/A' }</span>
									<span className="uk-text-small uk-text-muted">Twitter</span>
								</div>
							</li>
							<li>
								<div className="md-list-addon-element">
									<i className="md-list-addon-icon uk-icon-linkedin"/>
								</div>
								<div className="md-list-content">
									<span className="md-list-heading">{ profile.social ? profile.social.linkedin : 'N/A' }</span>
									<span className="uk-text-small uk-text-muted">Linkedin</span>
								</div>
							</li>
							<li>
								<div className="md-list-addon-element">
									<i className="md-list-addon-icon uk-icon-google-plus"/>
								</div>
								<div className="md-list-content">
									<span className="md-list-heading">{ profile.social ? profile.social.googlePlus : 'N/A' }</span>
									<span className="uk-text-small uk-text-muted">Google Plus</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		);
	}

	renderCurrency = () => {
		const { currencies, user: { profile } } = this.props;

		if(isEmpty(currencies)) return <span className="md-list-heading">N/A</span>;

		const currency = currencies[profile.currency];
		
		if(isEmpty(currency)) return <span className="md-list-heading">N/A</span>;

		return (
			<span className="md-list-heading"><strong>{ currency.currency }</strong> <span className="text-muted">- { currency.country }</span></span>
		);
	}

	renderLanguageItems = langs => {
		const { languages } = this.props;

		if(!langs.length || isEmpty(languages)) return <div/>;

		return map(langs, lang => {
			const language = get(languages, lang);

			if(!language) return <li key={ Math.random() } className="d-none"/>;

			return (
				<li key={ language.code }>
					<div className="md-list-icon">
						<i className={ `item-icon flag-icon flag-icon-${ this.getLanguageClass(language.code) }` }/>
					</div>
					<div className="md-list-content">
						<span className="md-list-heading">{ language.name }</span>
					</div>
				</li>
			);
		});
	}

	renderSponsorTabContent = () => {
		const { user: { profile: { isSubscribed } } } = this.props;

		if(isSubscribed) {
			return (
				<div className="subscribers-info">
					<h4 className="heading_c uk-margin-small-bottom">Want to cancel your subscription?</h4>
					<Link to="#sponsor" className="md-btn md-btn-flat md-btn-primary">Cancel Subscription</Link>
				</div>
			);
		}

		return (
			<div className="subscribers-info">
				<h4 className="heading_c uk-margin-small-bottom">Want to get more subscribers?</h4>
				<Link to="#sponsor" className="md-btn md-btn-flat md-btn-primary">Be a Sponsor!</Link>
			</div>
		);
	}

	renderLanguageTabContent = () => {
		const { user: { profile: { languages } } } = this.props;

		return (
			<div className="user-languages-container">
				<div className="language-block m-b-20">
					<h4 className="heading_c uk-margin-small-bottom">My Primary Languages</h4>
					<ul className="md-language-list">
						{ this.renderLanguageItems(languages ? [languages.primary] : []) }
					</ul>
				</div>
				<div className="language-block">
					<h4 className="heading_c uk-margin-small-bottom">My Additional Languages</h4>
					<ul className="md-language-list">
						{ this.renderLanguageItems(languages ? languages.secondary : []) }
					</ul>
				</div>
			</div>
		);
	}

	renderCreditCards = () => {
		const { user: { profile: { payments } } } = this.props;

		return map(payments, payment => (
			<div className="uk-width-medium-1-2" key={ payment.cardId }>
				<Card
					card={ payment }
					removeCard={ this.removeCreditCard }/>
			</div>
		));
	}
	
	renderDebitCards = () => {
		const { user: { debitCards } } = this.props;

		return map(debitCards, card => (
			<div className="uk-width-medium-1-2" key={ card.cardId }>
				<Card
					card={ card }
					removeCard={ this.removeCreditCard }/>
			</div>
		));
	}

	renderCreditCardTabContent = () => {
		const { paymentsLoading } = this.state;
		const { user: { profile: { payments } } } = this.props;

		if(payments && !payments.length) return <div className="text-center p-t-30"><span className="text-muted">No payment methods found!</span></div>;

		return (
			<div className="uk-grid uk-grid-small" data-uk-grid-margin>
				<Preloader center loading={ paymentsLoading }/>
				{ this.renderCreditCards() }
			</div>
		);
	}
	
	renderDebitCardTabContent = () => {
		const { debitCardsLoading } = this.state;
		const { user: { debitCards } } = this.props;

		if(!debitCards.length) return <div className="text-center p-t-30"><span className="text-muted">No debit cards found!</span></div>;

		return (
			<div className="uk-grid uk-grid-small" data-uk-grid-margin>
				<Preloader center loading={ debitCardsLoading }/>
				{ this.renderDebitCards() }
			</div>
		);
	}

	renderActivePlanDetails = () => {
		const { user: { profile } } = this.props;
		if(isEmpty(profile.activePlan)) return <p>No Active plan found!</p>;

		const plan = first(profile.activePlan);

		return (
			<ul className="list-inline">
				<li className="m-r-20">
					<span className="fas fa-crown m-r-5"/>
					<span className="uk-text-bold m-r-10">Active Plan:</span>
					<span>{ plan.name }</span>
				</li>
				<li className="m-r-20">
					<span className="fas fa-credit-card m-r-5"/>
					<span className="uk-text-bold m-r-10">Plan Price:</span>
					<span>{ plan.currency }</span>
				</li>
				<li className="m-r-20">
					<span className="fas fa-calendar-alt m-r-5"/>
					<span className="uk-text-bold m-r-10">Next Billing Date:</span>
					<span>{ plan.next_billing_date || 'N/A' }</span>
				</li>
				<li>
					<span className="fas fa-redo m-r-5"/>
					<span className="uk-text-bold m-r-10">Next Billing Price:</span>
					<span>{ plan.next_billing_price || 'N/A' }</span>
				</li>
			</ul>
		);
	}

	render() {
		const { user: { profile } } = this.props;
		const { loading } = this.state;

		return (
			<div className="md-card">
				<div className="user_heading bg-primary">
					<div className="user_heading_menu hidden-print">
						<div className="uk-display-inline-block" data-uk-dropdown="{pos:'left-top'}">
							<i className="md-icon material-icons md-icon-light">&#xE5D4;</i>
							<div className="uk-dropdown uk-dropdown-small">
								<ul className="uk-nav">
									<li>
										<Link to="#profile">Action 1</Link>
									</li>
									<li>
										<Link to="#profile">Action 2</Link>
									</li>
								</ul>
							</div>
						</div>
						<div className="uk-display-inline-block">
							<i className="md-icon md-icon-light material-icons" id="page_print">&#xE8ad;</i>
						</div>
					</div>
					<div className="user_heading_avatar">
						<div className="thumbnail">
							<ProfilePicture title={ profile.name } className="img-circle" src={ profile.photo }/>
						</div>
					</div>
					<div className="user_heading_content">
						<h2 className="heading_b uk-margin-bottom">
							<span className="uk-text-truncate">{ profile.name }</span>
							<span className="sub-heading">{ profile.slogan }</span>
						</h2>
						<ul className="user_stats">
							<li>
								<h4 className="heading_a">{ profile.tours ? profile.tours.total : 0 } <span className="sub-heading">Tours</span></h4>
							</li>
							<li>
								<h4 className="heading_a">{ profile.legs ? profile.legs.total : 0 } <span className="sub-heading">Legs</span></h4>
							</li>
							<li>
								<h4 className="heading_a">{ this.getSubscribers() } <span className="sub-heading">Subscribers</span></h4>
							</li>
							<li>
								<h4 className="heading_a">{ this.getSubscriptions() } <span className="sub-heading">Subscriptions</span></h4>
							</li>
							<li className="m-0">
								<h4 className="heading_a">{ this.getSuperscribers() } <span className="sub-heading">Superscribers</span></h4>
							</li>
						</ul>
					</div>
					<Link to="/profile/edit" className="md-fab md-fab-medium md-fab-accent hidden-print" disabled={ loading }>
						<Preloader color="#4caf50" minimal center loading={ loading }/>
						<i className={ `material-icons ${ loading ? 'd-none' : 'd-block' }` }>&#xE150;</i>
					</Link>
				</div>
				<div className="user_content">
					<div className="subscription-details">
						<h4 className="heading_b m-b-15">Active Subscription Details:</h4>
						{ this.renderActivePlanDetails() }
					</div>
					<hr/>
					<Tabs connect="#userProfileTabContents">
						<li className="uk-active">
							<Link to="#profile">About</Link>
						</li>
						<li>
							<Link to="#profile">Languages</Link>
						</li>
						<li className="d-none">
							<Link to="#profile">Sponsor</Link>
						</li>
						<li>
							<Link to="#profile">Payment Method</Link>
						</li>
						<li>
							<Link to="#profile">Debit Cards</Link>
						</li>
					</Tabs>
					<ul className="uk-switcher uk-margin" id="userProfileTabContents">
						<li>
							{ this.renderAboutTabContent() }
						</li>
						<li>
							{ this.renderLanguageTabContent() }
						</li>
						<li className="d-none">
							{ this.renderSponsorTabContent() }
						</li>
						<li>
							{ this.renderCreditCardTabContent() }
						</li>
						<li>
							{ this.renderDebitCardTabContent() }
						</li>
					</ul>
				</div>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

ProfileView.defaultProps = {
	user: {}
}

ProfileView.propTypes = {
	getLanguages: PropTypes.func.isRequired,
	getCurrencies: PropTypes.func.isRequired,
	getUserProfile: PropTypes.func.isRequired,

	removeCreditCard: PropTypes.func.isRequired,

	getDebitCards: PropTypes.func.isRequired,
	removeDebitCard: PropTypes.func.isRequired,
	removeDebitCards: PropTypes.func.isRequired,
	
	languages: PropTypes.objectOf(PropTypes.object).isRequired,
	currencies: PropTypes.objectOf(PropTypes.object).isRequired,

	user: PropTypes.shape()
}

/* ----------  Redux  ---------- */

const mapStateToProps = state => ({
	user: state.user,
	languages: state.languages,
	currencies: state.currencies,
});

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		getLanguages: GetLanguages,
		getUserProfile: GetUserProfile,
		removeCreditCard: RemoveCreditCard,

		getCurrencies: GetCurrencies,
		
		getDebitCards: GetDebitCards,
		removeDebitCard: RemoveDebitCard,
		removeDebitCards: RemoveDebitCards,
	}, dispatch)
);

/* ----------  Exports  ---------- */

export default connect(mapStateToProps, mapDispatchToProps)(ProfileView);
