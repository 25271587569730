/* ----------  Scripts  ---------- */

const GROUPED_TOURS_NODES_INITIAL_STATE = [];

const ReducerMyToursTreesGroupedToursNodes = (state = GROUPED_TOURS_NODES_INITIAL_STATE, action) => {
	if(action.type === 'MYTOURS_TREES_GET_GROUPED_TOURS_NODES') {
		return action.payload.results;
	} else if(action.type === 'MYTOURS_TREES_REMOVE_GROUPED_TOURS_NODES') {
		return GROUPED_TOURS_NODES_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMyToursTreesGroupedToursNodes;