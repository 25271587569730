/* ----------  Imports  ---------- */

// React
import React from 'react';

/* ----------  Scripts  ---------- */

const AdminFooter = () => (
	<footer/>
);

/* ----------  Exports  ---------- */

export default AdminFooter;