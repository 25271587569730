/* ----------  Imports  ---------- */

// Redux
import { combineReducers } from 'redux';

// User
import ReducerUserToken from './User/ReducerUserToken';
import ReducerDebitCards from './User/ReducerDebitCard';
import ReducerUserStatus from './User/ReducerUserStatus';
import ReducerCreditCard from './User/ReducerCreditCard';
import ReducerUserProfile from './User/ReducerUserProfile';
import ReducerUserFCMToken from './User/ReducerUserFCMToken';
import ReducerUserSettings from './User/ReducerUserSettings';
import ReducerSubscriptionPlans from './User/ReducerSubscriptionPlans';

// Home Tours
import ReducerHomeTours from './Home/ReducerHomeTours';
import ReducerHomeToursData from './Home/ReducerHomeToursData';
import ReducerHomeToursMeta from './Home/ReducerHomeToursMeta';
import ReducerHomeActiveTour from './Home/ReducerHomeActiveTour';

// Home Related Tours
import ReducerHomeRelatedTours from './Home/Related/ReducerHomeRelatedTours';
import ReducerHomeRelatedActiveTour from './Home/Related/ReducerHomeRelatedActiveTour';

// Leg Licensed Tree Nodes
import ReducerMyToursTreesLegsLicensedNodes from './MyTours/Trees/LegsLicensedTree/ReducerMyToursTreesLegsLicensedNodes';
import ReducerMyToursTreesLegsLicensedNodesData from './MyTours/Trees/LegsLicensedTree/ReducerMyToursTreesLegsLicensedNodesData';

// Legs Tree Nodes
import ReducerMyToursTreesLegsNodes from './MyTours/Trees/LegsTree/ReducerMyToursTreesLegsNodes';
import ReducerMyToursTreesLegsNodesData from './MyTours/Trees/LegsTree/ReducerMyToursTreesLegsNodesData';
import ReducerMyToursTreesLegsNodeLicense from './MyTours/Trees/LegsTree/ReducerMyToursTreesLegsNodeLicense';

// Tours Tree Nodes
import ReducerMyToursTreesToursNodes from './MyTours/Trees/ToursTree/ReducerMyToursTreesToursNodes';
import ReducerMyToursTreesToursNodesData from './MyTours/Trees/ToursTree/ReducerMyToursTreesToursNodesData';
import ReducerMyToursTreesToursFilterNodes from './MyTours/Trees/ToursTree/ReducerMyToursTreesToursFilterNodes';
import ReducerMyToursTreesTourProfileDetails from './MyTours/Trees/ToursTree/ReducerMyToursTreesTourProfileDetails';
import ReducerMyToursTreesToursFilterNodesData from './MyTours/Trees/ToursTree/ReducerMyToursTreesToursFilterNodesData';

// Ungrouped Tree Nodes
import ReducerMyToursTreesUngroupedToursNodes from './MyTours/Trees/UngroupedToursTree/ReducerMyToursTreesUngroupedToursNodes';
import ReducerMyToursTreesUngroupedToursNodesData from './MyTours/Trees/UngroupedToursTree/ReducerMyToursTreesUngroupedToursNodesData';

// Grouped Tree Nodes
import ReducerMyToursTreesGroupedToursNodes from './MyTours/Trees/GroupedToursTree/ReducerMyToursTreesGroupedToursNodes';
import ReducerMyToursTreesGroupedToursNodesData from './MyTours/Trees/GroupedToursTree/ReducerMyToursTreesGroupedToursNodesData';

// Collections
import ReducerMyToursTreesCollections from './MyTours/Trees/Collections/ReducerMyToursTreesCollections';

// Groups
import ReducerMyToursTreesGroups from './MyTours/Trees/Groups/ReducerMyToursTreesGroups';

// Node Details
import ReducerMyToursTreesNodeDetails from './MyTours/Trees/ReducerMyToursTreesNodeDetails';

// Node Hyperlink
import ReducerMyToursTreesHyperlink from './MyTours/Trees/ReducerMyToursTreesHyperlink';

// Action Panel
import ReducerMyToursTreesActionPanelNodes from './MyTours/Trees/ActionPanel/ReducerMyToursTreesActionPanelNodes';
import ReducerMyToursTreesActionPanelNodesData from './MyTours/Trees/ActionPanel/ReducerMyToursTreesActionPanelNodesData';

// Marketplace Legs
import ReducerMarketplaceLegs from './Marketplace/ReducerMarketplaceLegs';
import ReducerMarketplaceLegsData from './Marketplace/ReducerMarketplaceLegsData';

import ReducerMarketplaceOtherLegs from './Marketplace/ReducerMarketplaceOtherLegs';
import ReducerMarketplaceOtherLegsData from './Marketplace/ReducerMarketplaceOtherLegsData';

import ReducerMarketplaceFavoriteLegs from './Marketplace/ReducerMarketplaceFavoriteLegs';
import ReducerMarketplaceFavoriteLegsData from './Marketplace/ReducerMarketplaceFavoriteLegsData';

import ReducerMarketplaceSponsoredLegs from './Marketplace/ReducerMarketplaceSponsoredLegs';
import ReducerMarketplaceSponsoredLegsData from './Marketplace/ReducerMarketplaceSponsoredLegsData';

// Messages Conversations List
import ReducerMessagesConversationsList from './Messages/ConversationsList/ReducerMessagesConversationsList';
import ReducerMessagesConversationsListData from './Messages/ConversationsList/ReducerMessagesConversationsListData';

// Messages Contacts List
import ReducerMessagesContactsList from './Messages/ContactsList/ReducerMessagesContactsList';
import ReducerMessagesContactsListData from './Messages/ContactsList/ReducerMessagesContactsListData';

// Messages Conversation Theme
import ReducerMessagesConversationTheme from './Messages/Conversation/ReducerMessagesConversationTheme';

// Messages Conversation Messages
import ReducerMessagesConversationMessages from './Messages/Conversation/ConversationMessages/ReducerMessagesConversationMessages';
import ReducerMessagesConversationMessagesData from './Messages/Conversation/ConversationMessages/ReducerMessagesConversationMessagesData';

// Tour Panel
import ReducerToursPanel from './ToursPanel/ReducerToursPanel';
// import ReducerToursPanelData from './ToursPanel/ReducerToursPanelData';

// Tour Playback
import ReducerTourSummary from './TourPlayback/ReducerTourSummary';
import ReducerTourPlaybackLegs from './TourPlayback/ReducerTourPlaybackLegs';
import ReducerTourPlaybackDetails from './TourPlayback/ReducerTourPlaybackDetails';

// Sequence Panel
import ReducerSequencePanelHistory from './MyTours/SequencePanel/ReducerSequencePanelHistory';

// Cart
import ReducerCart from './Cart/ReducerCart';
import ReducerCartData from './Cart/ReducerCartData';

// Languages Reducer
import ReducerLanguages from './Languages/ReducerLanguages';

// Currencies Reducer
import ReducerCurrencies from './Currencies/ReducerCurrencies';

// Countries Reducer
import ReducerCountries from './Countries/ReducerCountries';

// Categories Reducer
import ReducerCategories from './Categories/ReducerCategories';

// Subscriptions
import ReducerSubscriptions from './Subscriptions/ReducerSubscriptions';
import ReducerSubscriptionsData from './Subscriptions/ReducerSubscriptionsData';
import ReducerUnreadSubscriptionsCount from './Subscriptions/ReducerUnreadSubscriptionsCount';

// Conversations Reducer
import ReducerConversations from './Conversation/ReducerConversations';
import ReducerConversationsData from './Conversation/ReducerConversationsData';
import ReducerConversationMessages from './Conversation/Messages/ReducerConversationMessages';
import ReducerConversationMessagesData from './Conversation/Messages/ReducerConversationMessagesData';
import ReducerUnreadConversationsCount from './Conversation/Read/ReducerUnreadConversationsCount';

/* ----------  Scripts  ---------- */

const RootReducer = combineReducers({
	user: combineReducers({
		status: ReducerUserStatus,
		profile: ReducerUserProfile,
		fcmToken: ReducerUserFCMToken,
		settings: ReducerUserSettings,
		debitCards: ReducerDebitCards,
		creditCards: ReducerCreditCard,
		subscriptionPlans: ReducerSubscriptionPlans,

		auth: combineReducers({
			token: ReducerUserToken
		}),
	}),

	languages: ReducerLanguages,
	
	currencies: ReducerCurrencies,
	
	countries: ReducerCountries,
	
	categories: ReducerCategories,
	
	cart: combineReducers({
		items: ReducerCart,
		itemsData: ReducerCartData,
	}),

	subscriptions: combineReducers({
		list: ReducerSubscriptions,
		listData: ReducerSubscriptionsData,
		unreadCount: ReducerUnreadSubscriptionsCount,
	}),

	mytours: combineReducers({
		trees: combineReducers({
			licensedLegs: combineReducers({
				nodes: ReducerMyToursTreesLegsLicensedNodes,
				nodesData: ReducerMyToursTreesLegsLicensedNodesData,
			}),
			
			legs: combineReducers({
				nodes: ReducerMyToursTreesLegsNodes,
				nodesData: ReducerMyToursTreesLegsNodesData,
				license: ReducerMyToursTreesLegsNodeLicense
			}),

			tours: combineReducers({
				nodes: ReducerMyToursTreesToursNodes,
				nodesData: ReducerMyToursTreesToursNodesData,
				filterNodes: ReducerMyToursTreesToursFilterNodes,
				profileDetails: ReducerMyToursTreesTourProfileDetails,
				filterNodesData: ReducerMyToursTreesToursFilterNodesData,
			}),

			ungroupedTours: combineReducers({
				nodes: ReducerMyToursTreesUngroupedToursNodes,
				nodesData: ReducerMyToursTreesUngroupedToursNodesData,
			}),

			groupedTours: combineReducers({
				nodes: ReducerMyToursTreesGroupedToursNodes,
				nodesData: ReducerMyToursTreesGroupedToursNodesData,
			}),

			nodeDetails: ReducerMyToursTreesNodeDetails,

			collections: ReducerMyToursTreesCollections,
			
			groups: ReducerMyToursTreesGroups,

			hyperlink: ReducerMyToursTreesHyperlink,

			actionPanel: combineReducers({
				nodes: ReducerMyToursTreesActionPanelNodes,
				nodesData: ReducerMyToursTreesActionPanelNodesData,
			}),
		})
	}),

	home: combineReducers({
		tours: ReducerHomeTours,
		toursData: ReducerHomeToursData,
		toursMeta: ReducerHomeToursMeta,
		activeTour: ReducerHomeActiveTour,
		relatedTours: ReducerHomeRelatedTours,
		relatedActiveTour: ReducerHomeRelatedActiveTour,
	}),
	
	marketplace: combineReducers({
		legs: ReducerMarketplaceLegs,
		legsData: ReducerMarketplaceLegsData,

		sponsored: combineReducers({
			legs: ReducerMarketplaceSponsoredLegs,
			legsData: ReducerMarketplaceSponsoredLegsData,
		}),

		others: combineReducers({
			legs: ReducerMarketplaceOtherLegs,
			legsData: ReducerMarketplaceOtherLegsData,
		}),

		favorites: combineReducers({
			legs: ReducerMarketplaceFavoriteLegs,
			legsData: ReducerMarketplaceFavoriteLegsData,
		})
	}),

	toursPanel: ReducerToursPanel,

	tourPlayback: combineReducers({
		summary: ReducerTourSummary,
		details: ReducerTourPlaybackDetails,
		legs: ReducerTourPlaybackLegs,
	}),

	conversation: combineReducers({
		list: ReducerConversations,
		listData: ReducerConversationsData,

		unread: ReducerUnreadConversationsCount,

		messages: combineReducers({
			list: ReducerConversationMessages,
			listData: ReducerConversationMessagesData
		})
	}),

	messages: combineReducers({
		conversationsList: combineReducers({
			list: ReducerMessagesConversationsList,
			listData: ReducerMessagesConversationsListData
		}),

		contactsList: combineReducers({
			list: ReducerMessagesContactsList,
			listData: ReducerMessagesContactsListData
		}),

		conversation: combineReducers({
			theme: ReducerMessagesConversationTheme,
			conversationMessages: combineReducers({
				messages: ReducerMessagesConversationMessages,
				messagesData: ReducerMessagesConversationMessagesData
			})
		}),
	}),

	sequencePanel: combineReducers({
		history: ReducerSequencePanelHistory
	})
});

/* ----------  Exports  ---------- */

export default RootReducer;
