/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Redux
import { connect } from 'react-redux';

// Redux
import { bindActionCreators } from 'redux';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { map, get, sum, debounce } from 'lodash';

// jQuery
import $ from "jquery";

// Utils
import Scrollbar from './../../Components/Utils/Scrollbar';

// Helpers
import Notify from './../../Helpers/Notify';

// Preloader
import Preloader from './../../Components/Common/Preloader';

// Cart Actions
import GetCart from './../../Redux/Actions/Cart/GetCart';
import Checkout from './../../Redux/Actions/Cart/Checkout';
import RemoveCart from './../../Redux/Actions/Cart/RemoveCart';
import RemoveCartItem from './../../Redux/Actions/Cart/RemoveCartItem';

/* ----------  Scripts  ---------- */

class Cart extends React.Component {
	constructor(props) {
		super(props);

		this.cntCartRef = React.createRef();

		this.state = {
			checkoutInProgress: false,
		}

		this.requestCart = debounce(this.doRequestCart, 500);
		this.requestCheckout = debounce(this.doRequestCheckout, 500);
		this.removeCartItem = debounce(this.doRemoveCartItem, 500);
	}

	componentWillMount() {
		this.getCart();
	}

	componentWillUnmount() {
		this.removeCart();
	}

	onCheckout = e => {
		e.preventDefault();

		this.checkout();
	}

	getCart = (success, fail) => {
		this.removeCart();
		this.requestCart(success, fail);
	}

	doRequestCart = (success, fail) => {
		this.props.getCart(success, fail);
	}

	doRequestCheckout = (success, fail) => {
		const cntCartRef = this.cntCartRef.current;

		this.setState({ checkoutInProgress: true });

		this.props.checkout(() => {
			this.setState({ checkoutInProgress: false });
			Notify.success('Thank you for purchasing legs');

			this.removeCart();

			$(cntCartRef).trigger('click');

			if(success) success();
		}, () => {
			this.setState({ checkoutInProgress: false });
			Notify.error('Please try again.');

			if(fail) fail();
		});
	}

	doRemoveCartItem = legs => {
		const legIds = map(legs, leg => leg.legId);

		const data = {
			legIds,
			legs
		}
		
		this.props.removeCartItem(data);
	}

	checkout = () => {
		this.requestCheckout();
	}

	handleDelete = (e, legId) => {
		e.preventDefault();
		e.stopPropagation();

		this.removeCartItem([legId]);
	}

	handleDeleteAll = e => {
		e.preventDefault();
		e.stopPropagation();

		const { items } = this.props;

		this.removeCartItem(items);
	}

	removeCart = () => {
		this.props.removeCart();
	}

	renderCartItems = () => {
		const { items, itemsData } = this.props;

		return map(items, item => {
			const leg = get(itemsData, item.legId);

			return (
				<li className="invoice-item invoiceItem" key={ leg.legId }>
					<span className="item-title">{ leg.title }</span>
					<span className="item-rate">{ leg.price || 0 }</span>
					<span className="item-actions">
						<ul>
							<li>
								<Link to="#cartDrp" className="atn-preview atnPreview" onClick={ this.handlePlayback }>
									<img src="assets/icons/tree/icon-tour.png" className="img-responsive" alt=""/>
								</Link>
							</li>
							<li>
								<Link to="#cartDrp" className="atn-delete atnDelete" onClick={ e => this.handleDelete(e, leg) }>
									<i className="material-icons">delete</i>
								</Link>
							</li>
						</ul>
					</span>
				</li>
			);
		});
	}

	renderInvoiceContent = () => {
		const { items } = this.props;

		if(!items.length) {
			return (
				<div className="md-cart-content content-state content-state-empty contentEmpty d-block">
					<div className="state-content">
						<div className="state-icon">
							<i className="material-icons text-warning">add_shopping_cart</i>
						</div>
						<div className="state-message">
							<p>Please add legs to your cart.</p>
						</div>
					</div>
				</div>
			);
		}

		const prices = map(items, item => {
			if(typeof item.price === 'string') return Number(item.price.replace(/[^0-9.]+/g,""));
			return item.price;
		});
		const total = sum(prices);

		return (
			<div className="md-cart-content content-state content-state-default contentDefault d-block">
				<Scrollbar className="invoice-list invoiceList">
					<ul className="invoice-list-items invoiceListItems">
						{ this.renderCartItems() }
					</ul>
				</Scrollbar>
				<div className="invoice-total">
					<span className="total-text">Total Amount</span>
					<span className="total-bill txtTotal">${ total || 0 }</span>
				</div>
			</div>
		);
	}

	renderInvoice = () => {
		const { items  } = this.props;
		const { checkoutInProgress } = this.state;

		return (
			<div className="md-cart-invoice md-cart-area cartInvoice">
				<div className="md-cart-header">
					<div className="header-title">
						<h3 className="areaTitle">My Cart</h3>
					</div>
					<div className="header-action">
						<ul>
							<li className={ `${ items.length ? 'd-block' : 'd-none' }` }>
								<Link to="#cartDrp" className="atnDeleteAll" onClick={ this.handleDeleteAll }><i className="material-icons">delete</i></Link>
							</li>
						</ul>
					</div>
				</div>
				{ this.renderInvoiceContent() }
				<div className={ `md-cart-footer ${ items.length ? 'd-block' : 'd-none' }` }>
					<Link to="#cartDrp" data-ripple="#fff" className={ `btn-cart btn-checkout uk-position btnCheckout ${ checkoutInProgress ? 'disabled' : '' }` } onClick={ this.onCheckout }>
						<Preloader loading={ checkoutInProgress } center color="#ffffff"/>
						{!checkoutInProgress ? 'Checkout' : '' }
					</Link>
				</div>
				<div className="area-mask invoiceMask"/>
				<div className="area-loader areaLoader">
					<span className="loader-icon">
						<span className="fas fa-refresh fa-spin"/>
					</span>
				</div>
			</div>
		);
	}

	renderCheckout = () => (
		<div className="md-cart-checkout md-cart-area cartCheckout">
			<div className="md-cart-header">
				<div className="header-atn">
					<Link to="#cartDrp" className="atnBack" data-uk-tooltip="{'pos': 'top'}" title="Go Back"><i className="material-icons">arrow_back</i></Link>
				</div>
				<div className="header-title">
					<h3 className="areaTitle">Payment</h3>
				</div>
				<div className="header-atn">
					<Link to="#cartDrp" data-uk-tooltip="{'pos': 'top'}" title="Settings"><i className="material-icons">settings</i></Link>
				</div>
			</div>
			<div className="md-cart-content content-state content-state-default contentDefault">
				<form className="global-form">
					<div className="md-form-group">
						<div className="group-title">
							<label className="title-text" htmlFor="txtCartCardIssuer">Card Name</label>
						</div>
						<div className="group-field">
							<select className="txtCartCards" id="txtCartCardIssuer">
								<option value="Visa - 1024" data-config='{"logo": "https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png", "fullText": "Visa - 1024", "number": "1024"}'>Visa - 1024</option>
								<option value="PayPal - 9332" data-config='{"logo": "http://pngimg.com/uploads/paypal/paypal_PNG17.png", "fullText": "PayPal - 9332", "number": "9332"}'>PayPal - 9332</option>
								<option value="Stripe - 4679" data-config='{"logo": "https://stripe.com/img/about/logos/logos/blue.png", "fullText": "Stripe - 4679", "number": "4679"}'>Stripe - 4679</option>
								<option value="Alipay - 1234" data-config='{"logo": "https://www.concardis.com/fileadmin/redakteur/images/Markenlogos/Alipay.png", "fullText": "Alipay - 1234", "number": "1234"}'>Alipay - 1234</option>
							</select>
						</div>
					</div>
					<div className="md-form-group">
						<div className="group-title">
							<label className="title-text" htmlFor="txtCartCardNumber">Credit Card Number</label>
						</div>
						<div className="group-field">
							<input type="text" className="md-input" id="txtCartCardNumber" name="number" placeholder="Credit Card Number" autoComplete="off" value="4614724179777946" data-mask="0000 - 0000 - 0000 - 0000" readOnly/>
							<div className="group-field-addon">
								<img src="https://upload.wikimedia.org/wikipedia/commons/4/41/Visa_Logo.png" alt="Visa"/>
							</div>
						</div>
					</div>
					<div className="md-form-group">
						<div className="group-title">
							<label className="title-text" htmlFor="cardName">Name on card</label>
						</div>
						<div className="group-field">
							<input type="text" className="md-input" id="cardName" name="name" placeholder="Name" autoComplete="off" readOnly/>
						</div>
					</div>
					<div className="md-form-group">
						<div className="group-title">
							<label className="title-text" htmlFor="txtCartCardExpiryDate">Expiry Date</label>
						</div>
						<div className="group-field">
							<input type="text" className="md-input txtExpiryDate" id="txtCartCardExpiryDate" name="expiryDate" autoComplete="off" placeholder="MM / YY" data-mask="00 / 00" readOnly/>
						</div>
					</div>
					<div className="md-form-group">
						<div className="group-title">
							<label className="title-text" htmlFor="txtCartCardCCV">CCV</label>
							<span className="title-icon" data-uk-tooltip="{'pos': 'top', 'cls': 'long-text'}" title="CCV number can be found on back of your credit/debit card.">
								<i className="material-icons">help</i>
							</span>
						</div>
						<div className="group-field">
							<span className="field-value" id="txtCartCardCCV">123</span>
						</div>
					</div>
				</form>
			</div>
			<div className="md-cart-content content-state content-state-success contentSuccess">
				<div className="state-content">
					<div className="state-icon">
						<i className="material-icons text-success">check</i>
					</div>
					<div className="state-message">
						<p>Your order has been added to <span><Link to="#cartDrp">My Licensed Collection</Link></span></p>
					</div>
				</div>
			</div>
			<div className="md-cart-content content-state content-state-error contentError">
				<div className="state-content">
					<div className="state-icon">
						<i className="material-icons text-danger">close</i>
					</div>
					<div className="state-message">
						<p>Your payment didn&pos;t go through, please check your payment method and try again.</p>
					</div>
				</div>
			</div>
			<div className="md-cart-footer">
				<Link to="#cartDrp" data-ripple="#fff" className="btn-cart btn-order btnOrder">Place Order</Link>
				<Link to="#cartDrp" data-ripple="#fff" className="btn-cart btn-close btnClose">Close</Link>
			</div>
			<div className="area-mask checkoutMask"/>
			<div className="area-loader areaLoader">
				<span className="loader-icon">
					<span className="fas fa-refresh fa-spin"/>
				</span>
			</div>
		</div>
	)

	render() {
		const { items } = this.props;

		return (
			<li className="shopping-cart" data-uk-dropdown="{mode:'click', pos:'bottom-right'}" ref={ this.cntCartRef }>
				<Link to="#div" className="user_action_icon" id="btnCart">
					<i className="material-icons md-24 md-light">shopping_cart</i>
					<span className={ `uk-badge bg-warning shoppingBadge ${ !items.length ? 'd-none' : '' }` }>{ items.length }</span>
				</Link>
				<div className="uk-dropdown uk-dropdown-xlarge uk-dropdown-shopping-cart md-shopping-cart" id="shoppingCart" data-items="4">
					<div className="md-card-content">
						<div className="md-card-content-inner">
							{ this.renderInvoice() }							
							{ this.renderCheckout() }							
						</div>
					</div>
				</div>
			</li>
		);
	}
}

/* ----------  Prop Types  ---------- */

Cart.propTypes = {
	getCart: PropTypes.func.isRequired,
	checkout: PropTypes.func.isRequired,
	removeCart: PropTypes.func.isRequired,
	removeCartItem: PropTypes.func.isRequired,
	
	items: PropTypes.arrayOf(PropTypes.object).isRequired,

	itemsData: PropTypes.objectOf(PropTypes.object).isRequired,
}

/* ----------  Redux Scripts  ---------- */

const mapStateToProps = state => ({
	items: state.cart.items,
	itemsData: state.cart.itemsData,
});

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		getCart: GetCart,
		checkout: Checkout,
		removeCart: RemoveCart,
		removeCartItem: RemoveCartItem,
	}, dispatch)
);

/* ----------  Exports  ---------- */

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
