/* ----------  Imports  ---------- */

// Lodash
import { filter, map } from 'lodash';

/* ----------  Scripts  ---------- */

const SEQUENCE_PANEL_HISTORY_INITIAL_STATE = [];

const ReducerSequencePanelHistory = (state = SEQUENCE_PANEL_HISTORY_INITIAL_STATE, action) => {
	if(action.type === 'ADD_HISTORY') {
		const history = [ ...state ];
		history.push(action.payload);

		return history;
	} else if(action.type === 'UPDATE_HISTORY') {
		const history = [ ...state ];
		const { historyId, updates } = action.payload;
		
		const updatedHistory = map(history, item => {
			if(item.historyId === historyId) {
				return {
					...item,
					...updates
				};
			}

			return item;
		});
		
		return updatedHistory;
	} else if(action.type === 'SELECT_HISTORY') {
		const historyId = action.payload;
		const history = [ ...state ];

		const updatedHistory = map(history, item => {
			if(item.historyId === historyId) {
				item.selected = true;
			} else {
				item.selected = false;
			}

			return item;
		});

		return updatedHistory;
	} else if(action.type === 'ENABLE_HISTORY') {
		const history = [ ...state ];
		const updatedHistory = map(history, item => {
			if(item.disabled) {
				item.disabled = false;
			}

			return item;
		});

		return updatedHistory;
	} else if(action.type === 'DISABLE_HISTORY') {
		const { fromIndex, toIndex } = action.payload;
		if(!Number.isInteger(fromIndex) && !Number.isInteger(toIndex)) return state;

		const history = [ ...state ];
		const updatedHistory = map(history, (item, i) => {
			if((i >= fromIndex) && (i <= toIndex)) {
				item.disabled = true;
			}

			return item;
		});

		return updatedHistory;
	} else if(action.type === 'REMOVE_HISTORY') {
		const { fromIndex, toIndex } = action.payload;
		if(!Number.isInteger(fromIndex) && !Number.isInteger(toIndex)) return state;

		const history = [ ...state ];
		const filteredHistory = filter(history, (item, i) => (fromIndex > i) || (i > toIndex));

		return filteredHistory;
	} else if(action.type === 'RESET_HISTORY') {
		return SEQUENCE_PANEL_HISTORY_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerSequencePanelHistory;
