/* ----------  Imports  ---------- */

// React
import React from 'react';

// Constants
import Global from './../Constants/Global';

/* ----------  Scripts  ---------- */

class Error404 extends React.Component  {
	componentWillMount() {
		document.title = `${ Global.APP_NAME } :: Oops!`;
	}

	render() {
		return (
			<div className="uk-alert uk-alert-danger uk-alert-large">
				<small>Error 404!</small>
				<h2>Oops! You&apos;re lost!</h2>
			</div>
		);
	}
};

/* ----------  Exports  ---------- */

export default Error404;
