/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Stripe Elements
import { CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe } from 'react-stripe-elements';

// UIKits
import Alerts from './../../UIkit/Alerts';

// Utils
import Selectize from './../../Utils/Selectize';

// Preloader
import Preloader from './../../Common/Preloader';

// Helpers
import InputHelper from './../../../Helpers/InputHelper';

/* ----------  Class  ---------- */

class CheckoutForm extends React.Component {
	constructor(props) {
		super(props);

		const { data } = this.props;

		this.state = {
			form: {
				type: 'card',
				name: data.name,
				address_line1: '',
				address_line2: '',
				address_country: 'US',
			},

			errors: [],
			loading: false,
		}
	}

	onInputChange = e => {
		const { name, value } = e.currentTarget;
		this.updateFormState({ [name]: value });
	}

	onCountryChange = (name, value) => {
		this.updateFormState({ country: value });
	}

	onSubmit = e => {
		e.preventDefault();

		const isValid = this.validateForm();

		if(isValid) {
			const { form } = this.state;
			const { stripe, addCreditCard } = this.props;

			this.setState({ loading: true });

			stripe.createToken(form).then(result => {
				if(result.error) {
					this.setState({ loading: false, errors: [result.error.message] });
				} else {
					addCreditCard(result.token.id, () => {
						console.log('success');
						this.setState({ loading: false });
					}, errors => {
						console.log(errors);
						this.setState({
							errors,
							loading: false,
						});
					});
				}
			});
		}
	}

	createOptions = () => ({
		style: {
			base: {
				fontSize: '15px',
				color: '#000',
				fontFamily: 'Roboto, sans-serif',
				'::placeholder': {
					color: '#727272'
				}
			},
			invalid: {
				color: '#ff5722',
			}
		}
	})

	updateFormState = (updates, callback) => {
		const { form } = this.state;

		this.setState({
			form: {
				...form,
				...updates,
			}
		}, () => {
			if(callback) callback();
		});
	}

	validateForm = () => {
		const errors = [];
		let isValid = true;
		const { form } = this.state;

		if(InputHelper.isEmpty(form.name)) {
			isValid = false;
			errors.push('Please enter a valid name.');
		}
		
		if(InputHelper.isEmpty(form.address_line1)) {
			isValid = false;
			errors.push('Please enter a valid address line 1.');
		}

		if(InputHelper.isEmpty(form.address_line2)) {
			isValid = false;
			errors.push('Please enter a valid address line 2.');
		}

		if(InputHelper.equalTo(form.address_country, '-1')) {
			isValid = false;
			errors.push('Please select a valid country.');
		}

		this.setState({ errors });

		return isValid;
	}

	render() {
		const { countries } = this.props;
		const { form, loading, errors } = this.state;

		return (
			<form className="stripe-form" onSubmit={ this.onSubmit } ref={ this.formRef }>
				<div className="uk-modal-dialog">
					<div className="uk-modal-header">
						<h3 className="uk-modal-title">Add Payment </h3>
					</div>
					<div className="uk-modal-body">
						<div className="md-form-group">
							<label htmlFor="txtCardName">Card Name</label>
							<input type="text" name="name" id="txtCardName" value={ form.name } placeholder="Name" onChange={ this.onInputChange }/>
						</div>
						<div className="md-form-group">
							<label htmlFor="sCardElement">Card Number</label>
							<CardNumberElement { ...this.createOptions() }/>
						</div>
						<div className="uk-grid">
							<div className="uk-width-1-2">
								<div className="md-form-group">
									<label htmlFor="sCardExpiry">Card Expiry</label>
									<CardExpiryElement { ...this.createOptions() }/>
								</div>
							</div>
							<div className="uk-width-1-2">
								<div className="md-form-group">
									<label htmlFor="sCardCvc">Card CVC</label>
									<CardCVCElement { ...this.createOptions() }/>
								</div>
							</div>
						</div>
						<div className="md-form-group">
							<label htmlFor="txtCardAddressLine1">Address (Line 01)</label>
							<input type="text" name="address_line1" id="txtCardAddressLine1" value={ form.address_line1 } placeholder="Address Line 1" onChange={ this.onInputChange }/>
						</div>
						<div className="md-form-group">
							<label htmlFor="txtCardAddressLine2">Address (Line 02)</label>
							<input type="text" name="address_line2" id="txtCardAddressLine2" value={ form.address_line2 } placeholder="Address Line 2" onChange={ this.onInputChange }/>
						</div>
						<div className="md-form-group">
							<label htmlFor="txtCardCountry">Country</label>
							<Selectize name="address_country" id="txtCardCountry" data={ countries } value={ form.address_country } onChange={ this.onCountryChange }/>
						</div>
						<Alerts show={ errors.length > 0 } data={ errors }/>
					</div>
					<div className="uk-modal-footer">
						<div className="footer-preloader m-r-10">
							<Preloader size={ 20 } loading={ loading } relative minimal/>
						</div>
						<Link to="#modal" className="md-btn md-btn-flat md-btn-flat-default md-btn-wave waves-effect waves-button uk-modal-close" disabled={ loading }>Cancel</Link>
						<button type="submit" className="md-btn md-btn-flat md-btn-flat-success uk-position-relative" disabled={ loading } onClick={ this.onSubmit }>
							Save
						</button>
					</div>
				</div>
			</form>
		);
	}
}

/* ----------  Prop Types  ---------- */

CheckoutForm.defaultProps = {
	data: {},
	countries: [],
}

CheckoutForm.propTypes = {
	stripe: PropTypes.shape().isRequired,
	
	data: PropTypes.shape(),

	countries: PropTypes.arrayOf(PropTypes.object),
	
	addCreditCard: PropTypes.func.isRequired
}

/* ----------  Exports  ---------- */

export default injectStripe(CheckoutForm);
