/* ----------  Imports  ---------- */

/* ----------  Scripts  ---------- */

const UNREAD_SUBSCRIPTIONS_COUNT_INITIAL_STATE = {
	tours: 0,
	legs: 0,
	total: 0
};

const ReducerUnreadSubscriptionsCount = (state = UNREAD_SUBSCRIPTIONS_COUNT_INITIAL_STATE, action) => {
	if(action.type === 'GET_UNREAD_SUBSCRIPTIONS_COUNT') {
		return action.payload;
	} else if(action.type === 'REMOVE_UNREAD_SUBSCRIPTIONS_COUNT') {
		return UNREAD_SUBSCRIPTIONS_COUNT_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerUnreadSubscriptionsCount;