/* ----------  Scripts  ---------- */

const TOURS_INITIAL_STATE = [];

const ReducerHomeTours = (state = TOURS_INITIAL_STATE, action) => {
	if(action.type === 'HOME_GET_TOURS') {
		return [ ...state, ...action.payload.results ];
	} else if(action.type === 'HOME_REMOVE_TOURS') {
		return TOURS_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerHomeTours;