/* ----------  Imports  ---------- */

// Lodash
import { find, remove } from 'lodash';

/* ----------  Scripts  ---------- */

const CONVERSATIONS_LIST_INITIAL_STATE = [];

const ReducerMessagesConversationsList = (state = CONVERSATIONS_LIST_INITIAL_STATE, action) => {
	if(action.type === 'MESSAGES_GET_CONVERSATIONS_LIST') {
		return [ ...state, ...action.payload.results ];
	} else if(action.type === 'MESSAGES_ADD_CONVERSATION_ITEM') {
		const updatedState = [ ...state ]
		updatedState.unshift(action.payload);
		
		return updatedState;
	} else if(action.type === 'MESSAGES_REMOVE_CONVERSATION_ITEM') {
		const conversation = find(state, ['conversationId', action.payload]);
		const updatedState = [ ...state ];
		remove(updatedState, conversation);

		return updatedState;
	} else if(action.type === 'MESSAGES_REMOVE_CONVERSATIONS_LIST') {
		return CONVERSATIONS_LIST_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMessagesConversationsList;