/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { map } from 'lodash';

// Modal
import Modal from './../../UIkit/Modal';

// Preloader
import Preloader from './../../Common/Preloader';

/* ----------  Scripts  ---------- */

class ModalNodeDetails extends React.Component {
	constructor(props) {
		super(props);

		this.modal = React.createRef();
	}

	componentDidMount() {}

	onShow = () => {
		this.props.onShow();
	}
	
	onHide = () => {
		this.props.onHide();
	}

	show = () => {
		const modal = this.modal.current;

		modal.show();
	}

	hide = () => {
		const modal = this.modal.current;

		modal.hide();
	}

	renderListItems = () => {
		const { data } = this.props;

		if(!data.length) return <li>No details found!</li>;

		return map(data, (item, i) => (
			<li key={ `item_${ i }` }>
				<div className="md-list-content">
					<span className="uk-text-small md-content-title uk-text-muted">{ item.title }</span>
					<span className="md-list-heading">{ item.content }</span>
				</div>
			</li>
		));
	}

	renderList = () => {
		const { loading } = this.props;

		if(loading) return <Preloader center minimal loading/>;

		return (
			<ul className="md-list md-details-list modalList">
				{ this.renderListItems() }
			</ul>
		);
	}

	render() {
		const { id, type } = this.props;

		return (
			<Modal
				popup="nodeDetails" 
				onShow={ this.onShow }
				onHide={ this.onHide }
				data-node-id={ id }
				data-node-type={ type }
				ref={ this.modal }>
				<div className="uk-modal-dialog">
					<div className="uk-modal-header">
						<h3 className="uk-modal-title">Node Details</h3>
					</div>
					<div className="uk-modal-body">
						<div className="uk-modal-list">
							{ this.renderList() }
						</div>
					</div>
					<div className="uk-modal-footer">
						<Link to="#closeModal" className="md-btn md-btn-flat md-btn-flat-default md-btn-wave waves-effect waves-button uk-modal-close">
							Close
						</Link>
					</div>
				</div>
			</Modal>
		);
	}
}

/* ----------  Prop Types  ---------- */

ModalNodeDetails.defaultProps = {
	id: '',
	type: 0,
	loading: true
}

ModalNodeDetails.propTypes = {
	onShow: PropTypes.func.isRequired,
	onHide: PropTypes.func.isRequired,

	id: PropTypes.string,
	
	loading: PropTypes.bool,

	type: PropTypes.number,
	
	data: PropTypes.arrayOf(PropTypes.object).isRequired,
}

/* ----------  Exports  ---------- */

export default ModalNodeDetails;