/* ----------  Scripts  ---------- */

const UNREAD_CONVERSATIONS_COUNT_INITIAL_STATE = {
	tours: 0,
	legs: 0,
	total: 0
};

const ReducerUnreadConversationsCount = (state = UNREAD_CONVERSATIONS_COUNT_INITIAL_STATE, action) => {
	if(action.type === 'GET_UNREAD_CONVERSATIONS_COUNT') {
		return action.payload;
	} else if(action.type === 'REMOVE_UNREAD_CONVERSATIONS_COUNT') {
		return UNREAD_CONVERSATIONS_COUNT_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerUnreadConversationsCount;