/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { map } from 'lodash';

// UUID
import uuid from 'uuid/v4';

// UIKit
import Input from './../../../Components/UIkit/Input';

// jQuery
const $ = window.$;

/* ----------  Scripts  ---------- */

class FilterSearch extends React.Component {
	constructor(props) {
		super(props);

		this.searchRef = React.createRef();

		this.lastTerm = '';
	}

	componentDidMount() {
		this.handleBody();
	}

	componentDidUpdate(prevProps) {
		this.lastTerm = prevProps.term;
	}

	// Input Events

	onBlur = () => {
		const form = this.searchRef.current;
		const $form = $(form);

		if(this.lastTerm && !this.props.term) {
			this.props.search();
			this.props.updateTerm('');
			this.hideSearch($form);
		}
	}

	onSearch = e => {
		e.preventDefault();

		const form = this.searchRef.current;
		const $form = $(form);

		if(!$form.hasClass('search-active')) {
			this.showSearch($form);
		} else if(!this.props.term) {
			this.props.updateTerm('');
			this.hideSearch($form);
		}
		
		if($form.hasClass('search-active') && (this.lastTerm || this.props.term)) {
			this.props.search();
		}
	}

	// Search

	handleSearch = e => {
		const { value } = e.currentTarget;
		const { updateTerm } = this.props;

		if(updateTerm) updateTerm(value);
	}

	// Body

	handleBody = () => {
		// $('body').off('click.filterSearch').on('click.filterSearch', e => {
		// 	const $form = $(e.target).closest('[data-tree-search]');

		// 	if(!$form.length) this.reset();
		// });

		// $('body').off('keyup.filterSearch').on('keyup.filterSearch', e => {
		// 	if(e.keyCode === 27) {
		// 		if(!this.props.term) {
		// 			this.reset();
		// 		}
		// 	}
		// });
	}

	// Search

	showSearch = els => {
		map(els, el => {
			const $el = $(el);
			const $input = $('[data-md-input]', $el);
			const $parent = $el.closest('[data-cnt-tree]');
			
			if(!$el.hasClass('search-active')) {
				$el.addClass('search-active');
				$parent.addClass('filter-search-active');

				setTimeout(() => $input.focus(), 300);
			}
		});
	}

	hideSearch = els => {
		map(els, el => {
			const $el = $(el);
			const $parent = $('[data-cnt-tree]');

			if($el.hasClass('search-active')) {
				$el.removeClass('search-active');
				$parent.removeClass('filter-search-active');
			}
		});
	}

	// Reset

	reset = () => {
		const $forms = $('[data-tree-search]');

		this.props.updateTerm('');
		this.hideSearch($forms);
	}

	render() {
		const { term, placeholder } = this.props;

		return (
			<form onSubmit={ this.onSearch } data-tree-search ref={ this.searchRef }>
				<div className="search-input">
					<Input
						title={ placeholder }
						name="term"
						id={ `txtTreeSearch_${ uuid() }` }
						value={ term }
						onBlur={ this.onBlur }
						onChange={ this.handleSearch }
						clearable/>
				</div>
				<div className="search-btn">
					<button className="btn-search btnSearch">
						<i className="material-icons">&#xE8B6;</i>
					</button>
				</div>
			</form>
		);
	}
}

/* ----------  Prop Types  ---------- */

FilterSearch.defaultProps = {
	placeholder: 'Search',
}

FilterSearch.propTypes = {
	term: PropTypes.string.isRequired,
	placeholder: PropTypes.string,

	search: PropTypes.func.isRequired,
	updateTerm: PropTypes.func.isRequired,
}

/* ----------  Exports  ---------- */

export default FilterSearch;
