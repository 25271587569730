/* ----------  Imports  ---------- */

// Lodash
import { mapKeys, values, maxBy } from 'lodash';

// New Message
import NewMessage from './../../../../Helpers/NewMessage';

/* ----------  Scripts  ---------- */

const CONVERSATION_MESSAGES_DATA_INITIAL_STATE = {};

const ReducerConversationMessagesData = (state = CONVERSATION_MESSAGES_DATA_INITIAL_STATE, action) => {
	if(action.type === 'GET_CONVERSATION_MESSAGES') {
		return mapKeys(action.payload.results, 'messageId');
	} else if(action.type === 'SEND_CONVERSATION_MESSAGE') {
		const { data, user, messageId } = action.payload;
		const lastMessage = maxBy(values(state), 'autoId');
		const message = NewMessage.create(messageId, data, user, lastMessage);
		const updatedState = { ...state, [messageId]: message };

		return updatedState;
	} else if(action.type === 'REMOVE_CONVERSATION_MESSAGES') {
		return CONVERSATION_MESSAGES_DATA_INITIAL_STATE;
	}

	return state;
}

/* ----------  Exports  ---------- */

export default ReducerConversationMessagesData;