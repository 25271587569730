/* ----------  Scripts  ---------- */

const RemoveConversationMessages = () => dispatch => (
	dispatch({
		type: 'REMOVE_CONVERSATION_MESSAGES',
	})
);

/* ----------  Exports  ---------- */

export default RemoveConversationMessages;