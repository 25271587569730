/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

/* ----------  Scripts  ---------- */

const ContactsLoader = ({ loading }) => (
	<div className={ `chat-list-loader list-loader ${ loading ? 'loading' : '' }` }>
		<span className="loader-icon">
			<span className="fas fa-refresh fa-spin"/>
		</span>
		<span className="loader-text">Loading...</span>
	</div>
);

/* ----------  Prop Types  ---------- */

ContactsLoader.propTypes = {
	loading: PropTypes.bool.isRequired
}

/* ----------  Exports  ---------- */

export default ContactsLoader;