/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// slugify
import slugify from 'slugify';

// UIkit
import UIkit from 'uikit';

// Constants
import Global from '../../Constants/Global';

/* ----------  Scripts  ---------- */

class Card extends React.Component {
	componentDidMount() {}

	getCardIcon = brand => {
		let icon = '';
		
		const network = slugify(brand, {
			lower: true
		});

		switch(network) {
			case 'alipay': icon = 'icon-alipay.svg'; break;
			case 'american-express': icon = 'icon-american-express.svg'; break;
			case 'amex': icon = 'icon-amex.svg'; break;
			case 'diners': icon = 'icon-diners.svg'; break;
			case 'discover': icon = 'icon-discover.svg'; break;
			case 'elo': icon = 'icon-elo.svg'; break;
			case 'hipercard': icon = 'icon-hipercard.svg'; break;
			case 'jcb': icon = 'icon-jcb.svg'; break;
			case 'maestro': icon = 'icon-maestro.svg'; break;
			case 'mastercard': icon = 'icon-mastercard.svg'; break;
			case 'paypal': icon = 'icon-paypal.svg'; break;
			case 'unionpay': icon = 'icon-unionpay.svg'; break;
			case 'visa': icon = 'icon-visa.svg'; break;
			default: icon = 'undefined'; break;
		}

		return `${ Global.ASSETS_BASE_URL }/icons/payment-methods/${ icon }`;
	}

	getCardTitle = brand => {
		const network = slugify(brand, {
			lower: true
		});

		switch(network) {
			case 'alipay':  return 'Alipay';
			case 'american-express':  return 'American Express';
			case 'amex':  return 'AMEX';
			case 'diners':  return 'Diners';
			case 'discover':  return 'Discover';
			case 'elo':  return 'Elo';
			case 'hipercard':  return 'Hipercard';
			case 'jcb':  return 'JCB';
			case 'maestro':  return 'Maestro';
			case 'mastercard':  return 'Mastercard';
			case 'paypal':  return 'PayPal';
			case 'unionpay':  return 'UnionPay';
			case 'visa':  return 'Visa';
			default:  return 'Undefined';
		}
	}

	setDefault = e => {
		e.preventDefault();
		e.stopPropagation();

		const { card, setDefault } = this.props;
		if(setDefault) setDefault(card.cardId);
	}

	removeCard = e => {
		e.preventDefault();
		e.stopPropagation();

		UIkit.modal.confirm('Are you sure?', () => {
			const { card, removeCard } = this.props;
			if(removeCard) removeCard(card.cardId);
		});
	}

	renderAtnDefault = () => {
		const { card, setDefault } = this.props;

		if(!setDefault) return <div className="d-none"/>;
		if(card.default) return <span className="m-r-10 text-success uk-text-uppercase"><i className="material-icons text-success">check_circle</i> <span className="uk-text-medium">Default</span></span>;

		return <Link to="#profile" className="md-btn md-btn-flat md-btn-flat-success md-btn-wave text-success" data-card-button onClick={ this.setDefault }>Set Default</Link>;
	}

	renderAtnRemove = () => {
		const { card, removeCard } = this.props;

		if(card.default || !removeCard) return <div className="d-none"/>;
		
		return <Link to="#profile" className="md-btn md-btn-flat md-btn-flat-danger md-btn-wave text-danger" data-card-button onClick={ this.removeCard }>Remove</Link>;
	}

	render() {
		const { card } = this.props;
		// const cardNumber = card.cardNumber.substr(card.cardNumber.length - 4);
		const network = card.issuingNetwork || 'visa';

		return (
			<div className="payment-method-card mhPaymentCard" data-card={ card.cardId }>
				<div className="card-inner mhContent">
					<div className="card-content">
						<div className="card-icon">
							<img src={ this.getCardIcon(network) } className="img-responsive" alt={ this.getCardTitle(network) }/>
						</div>
						<div className="card-details">
							<div className="card-title m-b-10">
								<h4 className="uk-text-bold">{ card.name }</h4>
							</div>
							<div className="card-number">
								<h4 className="heading_c">{ card.cardNumber }</h4>
							</div>
							<div className="card-meta">
								<span className="uk-text-small uk-text-muted">Expires { card.expiry }</span>
							</div>
						</div>
					</div>
					<div className="card-actions">
						{ this.renderAtnDefault() }
						{ this.renderAtnRemove() }
					</div>
				</div>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

Card.defaultProps = {
	removeCard: false,
	setDefault: false,
}

Card.propTypes = {
	removeCard: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	setDefault: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),

	card: PropTypes.shape({
		cardId: PropTypes.string.isRequired
	}).isRequired
}

/* ----------  Exports  ---------- */

export default Card;
