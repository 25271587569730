/* ----------  Disable History  ---------- */

const DisableHistory = (fromIndex, toIndex, callback) => dispatch => {
	dispatch({
		type: 'DISABLE_HISTORY',
		payload: {
			fromIndex,
			toIndex: toIndex || fromIndex
		}
	});

	if(callback) callback();
}

/* ----------  Exports  ---------- */

export default DisableHistory;
