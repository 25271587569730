/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Preloader
import Preloader from './../Common/Preloader';

/* ----------  Scripts  ---------- */

class MessageForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			message: '',
			sending: false,
		}
	}

	componentDidMount() {}

	onChange = e => {
		const message = e.currentTarget.value;

		this.setState({ message });
	}

	onKeyUp = e => {
		if((e.keyCode === 10 || e.keyCode === 13) && e.ctrlKey) {
			this.submit();
		}
	}

	onSubmit = e => {
		e.preventDefault();

		this.submit()
	}

	submit = () => {
		this.setState({ sending: true });

		this.props.sendMessage(this.state.message, () => {});
		
		this.setState({ 
			message: '',
			sending: false,
		});
	}

	render() {
		const { message, sending } = this.state;
		const { loading, blocked } = this.props;

		const placeholder = blocked ? 'You cannot send message to this conversation' : 'Type and send message';
		const isInputDisabled = loading || sending || blocked;
		const isButtonDisabled = (!message || sending || blocked) || false;

		return (
			<form className="reply-form row no-gutters align-items-center w-100 chatForm" onSubmit={ this.onSubmit }>
				<div className="form-group col mr-4">
					<textarea className="form-control" name="message" id="txtMessage" placeholder={ placeholder } value={ message } onChange={ this.onChange } onKeyUp={ this.onKeyUp } disabled={ isInputDisabled }/>
				</div>
				<button aria-label="Send message" className="btn btn-fab btn-secondary" disabled={ isButtonDisabled }>
					<Preloader loading={ sending } center minimal color="#ffffff"/>
					<i className={ `material-icons ${ sending || blocked ? 'd-none' : 'd-block' }` }>send</i>
					<i className={ `material-icons ${ blocked ? 'd-block' : 'd-none' }` }>warning</i>
				</button>
			</form>
		);
	}
}

/* ----------  Prop Types  ---------- */

MessageForm.propTypes = {
	loading: PropTypes.bool.isRequired,
	blocked: PropTypes.bool.isRequired,

	sendMessage: PropTypes.func.isRequired,
}

/* ----------  Exports  ---------- */

export default MessageForm;