/* ----------  Imports  ---------- */

// React
import React, { Fragment } from 'react';

// React Redux
import { connect } from 'react-redux';

// Redux
import { bindActionCreators } from 'redux';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Axios
import axios from 'axios';

// Lodash
import { get, size, map, mapValues, debounce, find, findIndex, remove, indexOf, isEmpty, sum, omit, max, mapKeys, pick, orderBy, clone, filter, uniq, first, last, cloneDeep, keys } from 'lodash';

// Deep Diff
// import { observableDiff } from 'deep-diff';

// UUID
import uuid from 'uuid/v4';

// Tiny Color
import tinycolor from 'tinycolor2';

// UIkit
import UIKit from 'uikit';

// Lity
// import Lity from 'lity';

// Moment
import moment from 'moment';

// Sortable JS
import Sortable from 'sortablejs';

// Constants
import History from '../../../Constants/History';
import Velocity from '../../../Constants/Velocity';

// Tree
import SequencePanelTree from '../../../Components/MyTours/SequencePanel/SequencePanelTree';

// Tours Player
import ToursPlayer from '../../ToursPanel/ToursPlayer';

// Preloader
import Preloader from './../../../Components/Common/Preloader';

// Mixer Actions
import GetTourMixer from './../../../Redux/Actions/MyTours/Trees/TourMixer/GetTourMixer';
import SaveTourMixer from '../../../Redux/Actions/MyTours/Trees/TourMixer/SaveTourMixer';

// History Actions
import AddHistory from '../../../Redux/Actions/MyTours/SequencePanel/AddHistory';
import SelectHistory from '../../../Redux/Actions/MyTours/SequencePanel/SelectHistory';
import UpdateHistory from '../../../Redux/Actions/MyTours/SequencePanel/UpdateHistory';
import RemoveHistory from '../../../Redux/Actions/MyTours/SequencePanel/RemoveHistory';
import ResetHistory from '../../../Redux/Actions/MyTours/SequencePanel/ResetHistory';
import EnableHistory from '../../../Redux/Actions/MyTours/SequencePanel/EnableHistory';
import DisableHistory from '../../../Redux/Actions/MyTours/SequencePanel/DisableHistory';

// Helper
import Ctx from '../../../Helpers/Ctx';
import Utils from '../../../Helpers/Utils';
import Notify from './../../../Helpers/Notify';
import InlineRenameHelper from '../../../Helpers/InlineRenameHelper';

// UIKits
// import Tab from '../../../Components/UIkit/Tabs';
import Modal from '../../../Components/UIkit/Modal';
import Input from '../../../Components/UIkit/Input';

// Preview Components
import ImageItem from '../../../Components/Player/ImageItem';

// Utils
// import Toggle from '../../../Components/Utils/Toggle';
import Scrollbar from '../../../Components/Utils/Scrollbar';
import IonSlider from '../../../Components/Utils/IonSlider';
import TransitionSelect from '../../../Components/Utils/TransitionSelect';
import HistoryPanel from '../../../Components/MyTours/SequencePanel/HistoryPanel';
import NoAnimationAlert from '../../../Components/Utils/NoAnimationAlert';

// jQuery
const $ = window.$;

/* ----------  Scripts  ---------- */

let LAST_STATE = null;

class SequencePanel extends React.Component {
	constructor(props) {
		super(props);

		this.panelRef = React.createRef();
		this.sqTreeRef = React.createRef();
		this.sqListRef = React.createRef();
		this.cntEditorRef = React.createRef();
		this.musicListRef = React.createRef();
		this.imageListRef = React.createRef();
		this.panelTitleRef = React.createRef();
		this.editorPanelRef = React.createRef();
		this.editorMixerRef = React.createRef();
		this.musicPlayerRef = React.createRef();
		this.snapLineEndRef = React.createRef();
		this.snapLineStartRef = React.createRef();
		this.cntMixerMusicRef = React.createRef();
		this.cntMixerImageRef = React.createRef();
		this.cntEditorMixerRef = React.createRef();
		this.bgMusicUploaderRef = React.createRef();
		this.editorMixerWidthRef = React.createRef();
		this.miscMediaUploaderRef = React.createRef();
		this.mixerMusicUploaderRef = React.createRef();
		this.mixerImageUploaderRef = React.createRef();
		
		this.headerVideoUploaderRef = React.createRef();
		this.footerVideoUploaderRef = React.createRef();
		
		this.imageSettingsModalRef = React.createRef();
		this.groupModalRef = React.createRef();

		this.imageItemRef = React.createRef();
		
		this.cntAlignmentCrosshairRef = React.createRef();
		this.alignmentCrosshairRef = React.createRef();
		
		this.imageTourPreviewPlayerRef = React.createRef();
		
		this.transitionEntranceSelect = React.createRef();
		this.transitionExitSelect = React.createRef();

		this.zoomScale = 500;

		this.musicPlayer = null;

		this.alignmentDraggable = null;
		this.uploadDraggable = null;
		this.uploadResizeble = null;

		this.geoThumbnailTimer = null;

		this.alignmentDOM = {
			width: 500,
			height: 200,
			center: {
				x: 500 / 2,
				y: 200 / 2
			}
		}

		this.initialOverlaySettingsModalFormState = {
			active: false,

			alignmentBoardGrid: 10,
			alignmentBoardActive: false,

			percentageCoordsPosition: this.getImagePercentageCoordsPosition(0, 0),
			
			image: {
				imageId: null,
				wRatio: 1,
				
				position: 'top-center',
				positionX: 0,
				positionY: 0,
				
				coords: {
					x: 0,
					y: 0,
					parent: {
						width: 0,
						height: 0
					}
				},

				transition: {
					entrance: 'fadeIn',
					exit: 'fadeOut',
					duration: 1,
				}
			},

			media: {},
		}

		this.initialUploaderState = {
			entityId: '',
			entityType: 'music',
			
			after: false,
			before: false,
			
			onEnd: false,
			onBeginning: false,

			playTime: 0,
			startTime: 0,
		}

		this.initialMixerState = {
			width: 0,
			active: false,
			totalPlayTime: 0,
		}

		this.initialMusicPlayerState = {
			active: false,
			musicId: null,
			playing: false,
			paused: false,
			loaded: false,
		}

		this.initialGroupFormState = {
			name: '',
			legIds: [],
			errors: [],
			groupId: null,
			active: false,
		}

		this.initialDataState = {
			musicList: {},
			imageList: {},

			header: {
				headerId: uuid(),
				mediaId: null,
				name: '',
				originalName: '',
			},
			
			footer: {
				footerId: uuid(),
				mediaId: null,
				name: '',
				originalName: '',
			},
		}
		
		this.initialHistorySnapshotState = {
			bulk: false,
			active: true,
			restrict: false,
			snapshotId: null,
		}

		this.initialPlayerState = {
			type: 'default',
			busy: false,
			active: false,
			render: false,
		}

		this.state = {
			...this.initialDataState,

			mediaList: {},

			legs: [],
			groups: {},

			uploading: 0,
			loading: true,
			fetching: true,
			isUpdated: false,
			
			mixer: this.initialMixerState,
			uploader: this.initialUploaderState,
			musicPlayer: this.initialMusicPlayerState,
			imageOverlaySettings: this.initialOverlaySettingsModalFormState,
			
			panel: {
				minimize: false,
			},

			imagePanel: {
				minimize: true
			},

			musicPanel: {
				minimize: true
			},

			groupForm: this.initialGroupFormState,

			historyPanel: {
				active: false
			},

			historySnapshot: this.initialHistorySnapshotState,

			imageCoords: {},

			player: this.initialPlayerState,
		}

		this.getTourMixer = debounce(this.doRequestTourMixer, 100);
		this.saveTourMixer = debounce(this.doRequestSaveTourMixer, 100);
	}

	componentDidMount() {
		this.setUrl();
		this.generateImageCoords();
		this.getTourMixer();

		this.props.checkSqPanelIsBusy(true);
	}

	componentWillUnmount() {
		this.unsetUrl();
		this.unbindKeyboardShortcuts();
	}

	// Events
	
	onImageSettingsModalShow = () => {
		this.disableMediaPanel();
		this.updateImageOverlaySettingsState({ active: true }, () => {
			setTimeout(() => {
				const { imageOverlaySettings: { image: { positionX, positionY } } } = this.state;
				this.initCustomAlignmentInteract(() => {
					this.handleCrosshairPosition(positionX, positionY, true);
				});
			}, 300);

			const { imageOverlaySettings: { image } } = this.state;

			const endTime = image.startTime + image.playTime;
			const exitTransition = image.transition ? image.transition.exit : 'fadeOut';
			const entranceTransition = image.transition ? image.transition.entrance : 'fadeIn';

			let imageEntranceCompleted = false;
			let imageExitCompleted = false;

			
			$(document).off('legplayer.mounted').on('legplayer.mounted', () => {
				imageEntranceCompleted = false;
				imageExitCompleted = false;
			});
			
			$(document).off('legplayer.playing').on('legplayer.playing', (e, time) => {
				if(((time >= image.startTime) && (time < endTime)) && !imageEntranceCompleted) {
					imageEntranceCompleted = true;
					this.animateImage(entranceTransition, false);
				}

				if((time >= endTime) && !imageExitCompleted) {
					imageExitCompleted = true;
					this.animateImage(exitTransition, false);
				}
			});

			$(document).off('legplayer.unmounted').on('legplayer.unmounted', () => {
				imageEntranceCompleted = false;
				imageExitCompleted = false;
				
				setTimeout(this.animateImage, 500, entranceTransition);
				
				const entranceSelect = this.transitionEntranceSelect.current;
				const exitSelect = this.transitionExitSelect.current;

				if(entranceSelect) entranceSelect.enable();
				if(exitSelect) exitSelect.enable();
			});
		});
	}
	
	onImageSettingsModalHide = () => {
		this.alignmentDraggable = null;
		this.updateImageOverlaySettingsState(this.initialOverlaySettingsModalFormState, () => {
			$(document).off('legplayer.mounted');
			$(document).off('legplayer.playing');
			$(document).off('legplayer.unmounted');
		});
	}

	onImageSettingsTabChange = currentTab => {
		const type = currentTab[0].getAttribute('data-tab');

		if(type === 'customAlignment') {
			setTimeout(() => {
				const { imageOverlaySettings: { image: { positionX, positionY } } } = this.state;
				this.initCustomAlignmentInteract(() => {
					this.handleCrosshairPosition(positionX, positionY, true);
				});
			}, 300);
		}
	}

	onImageEntranceChange = (name, value) => {
		const { imageOverlaySettings } = this.state;

		this.updateImageOverlaySettingsState({
			image: {
				...imageOverlaySettings.image,
				transition: {
					...imageOverlaySettings.image.transition,
					entrance: value
				}
			}
		}, () => {
			this.animateImage(value);
		});		
	}
	
	onImageExitChange = (name, value) => {
		const { imageOverlaySettings } = this.state;

		this.updateImageOverlaySettingsState({
			image: {
				...imageOverlaySettings.image,
				transition: {
					...imageOverlaySettings.image.transition,
					exit: value
				}
			}
		}, () => {
			this.animateImage(value);
		});			
	}

	onImageTransitionDurationChange = ion => {
		const { from } = ion;
		const { imageOverlaySettings } = this.state;

		this.updateImageOverlaySettingsState({
			image: {
				...imageOverlaySettings.image,
				transition: {
					...imageOverlaySettings.image.transition,
					duration: from
				}
			}
		});
	}

	onImageTourPreviewClick = e => {
		e.preventDefault();

		const { player } = this.state;

		if(!player.active) {
			this.previewImageTour();
		} else {
			this.stopImageTourPreview();
		}
	}
	
	onGroupModalShow = () => {
		this.updateGroupFormState({ active: true });
	}
	
	onGroupModalHide = () => {
		this.updateGroupFormState(this.initialGroupFormState);
	}
	
	onCrosshairToggleClick = (e, data) => {
		e.preventDefault();
		e.stopPropagation();

		const { player } = this.state;
		if(player.active) return;

		const $atn = $(e.currentTarget);
		$atn.closest('.toggleGroup').addClass('ui-notouch');

		this.handleImagePositionSettings(data.position.left, data.position.top, () => {
			this.handleCrosshairPosition(data.position.left, data.position.top, true);
		});
	}

	onAlignmentClick = e => {
		e.stopPropagation();
	}

	onAlignmentBoardGridChange = e => {
		const { value } = e.currentTarget;
		const gridSize = !isEmpty(value) ? parseFloat(value) : 0.1;

		this.updateImageOverlaySettingsState({
			alignmentBoardGrid: gridSize
		}, () => {
			this.handleCustomAlignmentGrid();
		});
	}

	onImageXPercentageCoordsChange = e => {
		const { value } = e.currentTarget;
		const { imageOverlaySettings: { percentageCoordsPosition } } = this.state;

		const isValid = (value <= 100) && (value >= -100);
		const positionX = isValid ? value : percentageCoordsPosition.x;
		const positionY = percentageCoordsPosition.y;

		this.handleImagePositionInputs(positionX, positionY);
	}

	onImageYPercentageCoordsChange = e => {
		const { value } = e.currentTarget;
		const { imageOverlaySettings: { percentageCoordsPosition } } = this.state;

		const isValid = (value <= 100) && (value >= -100);
		const positionX = percentageCoordsPosition.x;
		const positionY = isValid ? value : percentageCoordsPosition.y;

		this.handleImagePositionInputs(positionX, positionY);
	}

	onImageWidthSliderChange = ion => {
		const { from } = ion;
		const { imageOverlaySettings } = this.state;

		this.updateImageOverlaySettingsState({
			image: {
				...imageOverlaySettings.image,
				wRatio: from
			}
		}, () => {
			const imageItemRef = this.imageItemRef.current;
			if(imageItemRef) imageItemRef.calculate();
		});
	}

	onLayersTitleClick = e => {
		e.preventDefault();
		
		this.handleMusicMixer();
	}

	onLayersTitleCtxClick = e => {
		e.preventDefault();

		const { mixer } = this.state;
		const { tour } = this.props;

		const params = {
			refId: uuid(),
			objectId: tour.tourId,
			type: 50,
			mixerActive: mixer.active
		}

		this.handleContext('sqMixerPanel', params, e.currentTarget);
	}

	onTitleClick = e => {
		e.preventDefault();

		const { mixer } = this.state;

		if(mixer.active) {
			this.handleLegList();
		}
	}
	
	onImageTitleClick = e => {
		e.preventDefault();

		const { mixer } = this.state;

		if(mixer.active) {
			this.handleImageList();
		}
	}

	onImageTitleCtxClick = e => {
		e.preventDefault();
		e.stopPropagation();

		const { tour } = this.props;
		const { mixer, imageList } = this.state;

		const params = {
			refId: tour.refId,
			nodeId: tour.nodeId,
			objectId: tour.tourId,
			type: 50,
			tree: 'tours',
			title: tour.title,
			imageIds: map(imageList, image => image.imageId),
			legIds: this.getLegIds(),
			mixerActive: mixer.active,
		}

		this.handleContext('sqMixerImageTitle', params, e.currentTarget);
	}

	onMusicTitleClick = e => {
		e.preventDefault();

		const { mixer } = this.state;

		if(mixer.active) {
			this.handleMusicList();
		}
	}

	onMusicTitleCtxClick = e => {
		e.preventDefault();
		e.stopPropagation();

		const { tour } = this.props;
		const { mixer, musicList } = this.state;

		const params = {
			refId: tour.refId,
			nodeId: tour.nodeId,
			objectId: tour.tourId,
			type: 50,
			tree: 'tours',
			title: tour.title,
			legIds: this.getLegIds(),
			musicIds: map(musicList, music => music.musicId),
			mixerActive: mixer.active,
		}

		this.handleContext('bgMusicMixer', params, e.currentTarget);
	}

	onImageItemCtxClick = (e, image) => {
		e.preventDefault();

		const { mediaList } = this.state;
		const media = get(mediaList, image.mediaId);
		
		const props = {
			media,

			refId: image.imageId,
			imageId: image.imageId,
			playTime: image.playTime,
			startTime: image.startTime,
			endTime: image.endTime,
			locked: image.locked,
			originalPlaytime: image.originalPlaytime
		}

		this.handleContext('sqImageItem', props, e.currentTarget);
	}

	onMusicItemCtxClick = (e, music) => {
		e.preventDefault();

		const { mediaList } = this.state;

		const media = get(mediaList, music.mediaId);
		
		const props = {
			media,

			refId: music.musicId,
			musicId: music.musicId,
			playTime: music.playTime,
			startTime: music.startTime,
			endTime: music.endTime,
			locked: music.locked,
			originalPlaytime: music.originalPlaytime
		}

		this.handleContext('sqMusicItem', props, e.currentTarget);
	}

	onHeaderCtxClick = e => {
		e.preventDefault();

		const { header } = this.state;
		const props = {
			refId: header.headerId,
			headerId: header.headerId,
			mediaId: header.mediaId,
			media: header.mediaId ? this.getMedia(header.mediaId) : null
		}
		
		this.handleContext('sqMixerHeader', props, e.currentTarget);
	}
	
	onFooterCtxClick = e => {
		e.preventDefault();

		const { footer } = this.state;
		const props = {
			refId: footer.footerId,
			footerId: footer.footerId,
			mediaId: footer.mediaId,
			media: this.getMedia(footer.mediaId) || null
		}

		this.handleContext('sqMixerFooter', props, e.currentTarget);
	}

	onGroupCtxClick = (e, group) => {
		e.preventDefault();

		const { tour } = this.props;
		const { mixer } = this.state;

		const firstLegId = first(group.legIds);
		const lastLegId = last(group.legIds);

		const fromIndex = firstLegId ? this.getLegIndex(firstLegId) : -1;
		const toIndex = lastLegId ? this.getLegIndex(lastLegId) : -1;

		const props = {
			refId: group.groupId,
			groupId: group.groupId,
			tourId: tour.tourId,
			legIds: group.legIds,
			mixerActive: mixer.active,

			fromIndex,
			toIndex
		}

		this.handleContext('sqGroupItem', props, e.currentTarget);
	}

	onImageUploaderClick = e => {
		const rect = e.currentTarget.getBoundingClientRect();
		const x = e.clientX - rect.left;
		const startTime = this.getMilliseconds(x);

		this.updateUploaderState({ startTime });
	}
	
	onMusicUploaderClick = e => {
		const rect = e.currentTarget.getBoundingClientRect();
		const x = e.clientX - rect.left;
		const startTime = this.getMilliseconds(x);

		this.updateUploaderState({ startTime });
	}

	onMusicUpload = e => {
		const { files } = e.currentTarget;
		
		if(files.length) {
			this.setState({ uploading: files.length }, () => {
				const musics = Array.from(files);
				this.uploadMusics(musics);
			});
		}
	}

	onImageUpload = e => {
		const { files } = e.currentTarget;
		
		if(files.length) {
			this.setState({ uploading: files.length }, () => {
				const images = Array.from(files);
				this.uploadImages(images);
			});
		}
	}

	onMixerVideoUpload = (e, type) => {
		const { files } = e.currentTarget;

		if(!files.length || !type) return;

		this.mixerUploadVideo(files[0], type);
		e.currentTarget.files = null;
	}

	onSave = e => {
		e.preventDefault();
		this.saveTourMixer();
	}

	onTourCtxClick = e => {
		e.preventDefault();
		e.stopPropagation();

		const { tour } = this.props;
		const { mixer, header, footer } = this.state;

		const legIds = this.getLegIds();
		const fromIndex = legIds.length ? 0 : -1;
		const toIndex = legIds.length ? (legIds.length - 1) : fromIndex;

		const params = {
			legIds,
			toIndex,
			fromIndex,

			refId: tour.refId,
			nodeId: tour.nodeId,
			objectId: tour.tourId,
			type: 50,
			tree: 'tours',
			mixerActive: mixer.active,
			headerMediaId: header.mediaId,
			footerMediaId: footer.mediaId,
		}

		this.handleContext('sqTour', params, e.currentTarget);
	}

	onTourPlay = e => {
		e.preventDefault();
		e.stopPropagation();

		const legIds = this.getLegIds();
		const { tour: { tourId } } = this.props;

		this.playTour(tourId, legIds, 50, 60);
	}

	onLegPlay = (e, i) => {
		e.preventDefault();

		const legIds = this.getLegIds();
		const { tour: { tourId } } = this.props;

		this.playTour(tourId, legIds, 60, 50, true, i, i);
	}

	onLegCtxClick = (e, leg) => {
		e.preventDefault();

		const nextLegs = this.getNextLegs(leg.objectId);
		const params = this.getLegCtxParams(leg);
		params.nextLegs = nextLegs;

		this.handleContext('sqLeg', params, e.currentTarget);
	}

	onRemove = (e, nodeId) => {
		e.preventDefault();
		this.removeItem(nodeId);
	}

	onHistoryClick = e => {
		e.preventDefault();

		this.showHistoryPanel();
	}

	onUndoHistoryClick = e => {
		e.preventDefault();

		this.undoHistory();
	}
	
	onRedoHistoryClick = e => {
		e.preventDefault();

		this.redoHistory();
	}

	onCancel = e => {
		e.preventDefault();
		this.hidePanel();
	}

	onStopMusic = e => {
		e.preventDefault();
		this.stopMusic();
	}

	onTitleCloseClick = e => {
		e.preventDefault();

		const { mixer } = this.state;

		if(!mixer.active) this.hidePanel();
	}

	onCloseMixer = e => {
		e.preventDefault();

		this.closeTourMixer();
	}

	onImageSettingsFormSubmit = e => {
		e.preventDefault();
		this.updateImageSettings();
	}

	onGroupFormSubmit = e => {
		e.preventDefault();
		
		this.saveGroup(null, () => {
			const modal = this.groupModalRef.current;
			modal.hide();
		});
	}

	onImagePositionChecked = coordId => {
		this.handleCoordsToggle(coordId);
	}

	// Getters

	getItemAttrs = item => {
		const data = {};
		const attrs = item.attributes;

		mapValues(attrs, attr => {
			if(attr.specified) {
				data[attr.name] = attr.value
			}
		});

		return data;
	}

	getLegIds = () => {
		const { legs } = this.state;
		return map(legs, 'objectId');
	}

	getLegsMeta = (legId = null) => {
		const { legs } = this.state;

		const list = map(legs, (leg, i) => {
			const startTime = this.getLegStartTime(i);
			const endTime = startTime + leg.playTime;

			return {
				startTime,
				endTime,

				legId: leg.objectId,
				title: leg.text,
				playTime: leg.playTime,
			}
		});

		if(legId) return find(list, { legId });
		return list;
	}

	getInRangeLegs = (startTime, endTime) => {
		const { legs, mixer } = this.state;
		const inRangeLegs = {};

		for(let i = 0; i < legs.length; i += 1) {
			const leg = legs[i];
			const legEndTime = leg.startTime + leg.playTime;
			
			if((startTime >= leg.startTime) && (startTime <= legEndTime)) {
				inRangeLegs.startLeg = leg;
				break;
			}
		}

		for(let i = 0; i < legs.length; i += 1) {
			const leg = legs[i];
			const legEndTime = leg.startTime + leg.playTime;
			
			if((endTime >= leg.startTime) && (endTime <= legEndTime)) {
				inRangeLegs.endLeg = leg;
				break;
			}
		}

		if((endTime > mixer.totalPlayTime) && isEmpty(inRangeLegs.endLeg)) {
			inRangeLegs.endLeg = last(legs);
		}

		return inRangeLegs;
	}

	getInRangeLegRecords = (startTime, endTime, startLeg, endLeg) => {
		const startLegRecords = startLeg.recording ? startLeg.recording.records : {};
		const startLegRecordKeys = keys(startLegRecords);
		const startDiff = Math.ceil(startTime - startLeg.startTime);
		
		const endLegRecords = endLeg.recording ? endLeg.recording.records : {};
		const endLegRecordKeys = keys(endLegRecords);
		const endDiff = Math.ceil(endTime - endLeg.startTime);

		const startFrame = Math.ceil(startDiff / startLeg.recording.frequency);
		const endFrame = Math.ceil(endDiff / endLeg.recording.frequency);

		let iStartFrame = startFrame;
		let iEndFrame = endFrame;

		map(startLegRecordKeys, recordKey => {
			const i = parseInt(recordKey, 10);
			if(i <= startFrame) {
				iStartFrame = i;
			}
		});
		
		map(endLegRecordKeys, recordKey => {
			const i = parseInt(recordKey, 10);
			if(i <= endFrame) {
				iEndFrame = i;
			}
		});

		const startRecord = startLegRecords[iStartFrame];
		const endRecord = endLegRecords[iEndFrame];

		return { startRecord, endRecord };
	}

	getTheme = (licensed = false, isPurchased = false) => {
		let theme = '#666666';

		if(licensed) {
			theme = '#4caf50';
			if(isPurchased) theme = '#ff9800';
		} else {
			theme = '#666666';
		}

		return theme;
	}

	getLegIndex = legId => {
		const legIds = this.getLegIds();
		return indexOf(legIds, legId);
	}

	getLegCtxParams = leg => {
		const { tour: { tourId } } = this.props;
		const { mixer } = this.state;
		
		const legIds = this.getLegIds();
		const index = this.getLegIndex(leg.objectId);

		const params = {
			index,
			legIds,
			tourId,
			
			type: 60,
			tree: 'tours',
			refId: leg.nodeId,
			nodeId: leg.nodeId,
			objectId: leg.objectId,
			mixerActive: mixer.active,
		}

		return params
	}

	getNextLegs = legId => {
		const nextLegs = [];
		const { legs } = this.state;
		const legIndex = this.getLegIndex(legId);

		const currentLeg = find(legs, ['objectId', legId]);
		const currentLegObj = this.getLegCtxParams(currentLeg);
		currentLegObj.text = currentLeg.text;
		nextLegs.push(currentLegObj);

		for(let i = (legIndex + 1); i < legs.length; i += 1) {
			const leg = legs[i];
			const obj = this.getLegCtxParams(leg);
			obj.text = leg.text;
			nextLegs.push(obj);
		}

		return nextLegs;
	}

	getMsppx = () => {
		const { mixer } = this.state;

		if(!mixer.totalPlayTime) return 50;
		
		const ref = this.editorMixerWidthRef.current;
		if(!ref) return 50;

		const { width } = ref.getBoundingClientRect();
		return Utils.getMsppx(mixer.totalPlayTime, width);
	}

	getPixels = milliseconds => {
		const msppx = this.getMsppx();
		const pixels = (milliseconds / msppx).toFixed(2)
		return parseFloat(pixels);
	}
	
	getMilliseconds = pixels => {
		const msppx = this.getMsppx();
		const milliseconds = (pixels * msppx).toFixed(2);
		return parseFloat(milliseconds);
	}

	getLegStartTime = i => {
		const { legs } = this.state;

		let left = 0;
		for(let x = 0; x < i; x += 1) {
			left += legs[x].playTime;
		}

		return left;
	}

	getMusicSequence = musics => {
		const { musicList, mediaList } = this.state;

		const data = musics || musicList;

		const music = map(data, legMusic => {
			const media = get(mediaList, legMusic.mediaId);

			return {
				musicId: legMusic.musicId || uuid(),
				mediaId: media.mediaId,
				zIndex: legMusic.zIndex,
				playTime: Math.ceil(legMusic.playTime),
				startTime: Math.ceil(legMusic.startTime),
				originalPlaytime: legMusic.originalPlaytime,
				endTime: Math.ceil(legMusic.endTime) || (legMusic.startTime + legMusic.playTime),
			}
		});

		let sortedMusic = orderBy(clone(music), ['startTime', 'zIndex'], ['asc', 'desc']);
		
		const list = [];
		
		const handleList = musicObj => {
			const musicOrig = find(music, { musicId: musicObj.musicId });
			const offset = musicObj.startTime - musicOrig.startTime;
			musicObj.offset = offset;
			
			list.push(musicObj);
		}

		while(sortedMusic.length) {
			sortedMusic = orderBy(clone(sortedMusic), ['startTime', 'zIndex'], ['asc', 'desc']);

			for(let i = 0; i < sortedMusic.length; i += 1) {
				const musicI = sortedMusic[i];
				
				if(musicI.playTime <= 0) {
					sortedMusic.splice(i, 1);
					i -= 1;
				} else {
					for(let j = (i + 1); j < sortedMusic.length; j += 1) {
						const musicJ = sortedMusic[j];
	
						if((musicI.startTime <= musicJ.startTime) && (musicJ.endTime <= musicI.endTime) && (musicJ.zIndex < musicI.zIndex)) {
							sortedMusic.splice(j, 1);
						}
					}
				}
			}
			
			sortedMusic = orderBy(clone(sortedMusic), ['startTime', 'zIndex'], ['asc', 'desc']);

			for(let i = 0; i < sortedMusic.length; i += 1) {
				const musicI = clone(sortedMusic[i]);
				let pushed = false;

				for(let j = (i + 1); j < sortedMusic.length; j += 1) {
					const musicJ = clone(sortedMusic[j]);
					
					if((musicI.startTime <= musicJ.startTime) && (musicJ.startTime <= musicI.endTime) && (musicI.endTime < musicJ.endTime) && (musicI.zIndex < musicJ.zIndex)) {
						handleList({
							musicId: musicI.musicId,
							mediaId: musicI.mediaId,
							startTime: musicI.startTime,
							playTime: (musicJ.startTime - musicI.startTime)
						});

						musicI.startTime = musicJ.startTime;
						musicI.playTime = (musicI.endTime - musicI.startTime);
						sortedMusic[i] = musicI;

						pushed = true;
						break;
					} 
					else if((musicI.startTime <= musicJ.startTime) && (musicJ.startTime <= musicI.endTime) && (musicI.endTime < musicJ.endTime) && (musicI.zIndex > musicJ.zIndex)) {
						handleList({
							musicId: musicI.musicId,
							mediaId: musicI.mediaId,
							startTime: musicI.startTime,
							playTime: musicI.playTime
						});

						musicJ.startTime = musicI.endTime;
						musicJ.playTime = (musicJ.endTime - musicJ.startTime);
						sortedMusic[j] = musicJ;

						sortedMusic.splice(i, 1);

						pushed = true;
						break;
					}
					else if((musicI.startTime <= musicJ.startTime) && (musicJ.endTime <= musicI.endTime)) {
						handleList({
							musicId: musicI.musicId,
							mediaId: musicI.mediaId,
							startTime: musicI.startTime,
							playTime: (musicJ.startTime - musicI.startTime)
						});

						musicI.startTime = musicJ.startTime;
						musicI.playTime = (musicI.endTime - musicI.startTime);
						sortedMusic[i] = musicI;

						pushed = true;
						break;
					}
				}

				if(pushed) break;

				handleList({
					musicId: musicI.musicId,
					mediaId: musicI.mediaId,
					startTime: musicI.startTime,
					playTime: musicI.playTime
				});

				sortedMusic.splice(i, 1);
				break;
			}
		}

		return mapKeys(list, 'startTime');
	}

	getMusicSequenceObj = (item, currentItem, isEnd = false) => {
		const music = item;
		const currentMusic = currentItem || item;

		const obj = {
			musicId: music.musicId,
			mediaId: music.mediaId,
			startTime: music.startTime,
		}

		if(currentItem) {
			if(isEnd) {
				obj.playTime = (music.endTime - currentMusic.endTime);
			} else {
				obj.playTime = (currentMusic.startTime - music.startTime);
			}
		} else {
			obj.playTime = music.playTime;
		}

		return obj;
	}

	getImageEl = imageId => {
		const uploadsRef = this.cntMixerImageRef.current;
		const upload = uploadsRef.querySelector(`#mediaImageUpload_${ imageId }`);
		return upload;
	}

	getMusicEl = musicId => {
		const uploadsRef = this.cntMixerMusicRef.current;
		const upload = uploadsRef.querySelector(`#mediaMusicUpload_${ musicId }`);
		return upload;
	}

	getMedia = mediaId => {
		if(!mediaId) return null;
		const { mediaList } = this.state;
		return get(mediaList, mediaId);
	}

	getCoordsToPixel = (x, y) => {
		const { width, height } = this.alignmentDOM;

		const stvWidth = width / 2;
		const stvHeight = height / 2;

		let top = 0;
		let left = 0;

		if(x >= 0) {
			left = stvWidth + x;
		} else {
			left = stvWidth - Math.abs(x);
		}

		if(top >= 0) {
			top = stvHeight - y;
		} else {
			top = stvWidth + Math.abs(y);
		}

		return { top, left };
	}

	getImagePercentagePosition = (x, y) => {
		const { width, height } = this.alignmentDOM;

		const top = parseFloat((y / height) * 100);
		const left = parseFloat((x / width) * 100);

		return { left, top };
	}
	
	getImagePixelPosition = (x, y) => {
		const { width, height } = this.alignmentDOM;

		const left = parseFloat((width / 100) * x);
		const top = parseFloat((height / 100) * y);

		return { left, top };
	}

	getImagePercentageCoordsPosition = (x, y) => {
		const { height, width } = this.alignmentDOM;

		let positionX = ((100 / (width / 2)) * x);
		positionX = Math.round(positionX * 100) / 100;
		let positionY = ((100 / (height / 2)) * y);
		positionY = Math.round(positionY * 100) / 100;

		return {
			x: positionX,
			y: positionY,
		}
	}

	getImageCoords = (x, y, coords = {}, percentPosition = false) => {
		const parentDOM = this.alignmentDOM;
		
		let coordX = x;
		let coordY = y;

		let width = parentDOM.width;
		let height = parentDOM.height;

		if(percentPosition) {
			const position = this.getImagePixelPosition(x, y);

			coordX = position.left;
			coordY = position.top;
		}

		if(!isEmpty(coords.parent)) {
			width = coords.parent.width || width;
			height = coords.parent.height || height;
		}

		width /= 2;
		height /= 2;

		coordY = (coordY - height) * -1;

		if(coordX >= width) {
			coordX -= width;
		} else {
			coordX = (width - coordX) * -1;
		}

		const data = {
			x: Math.round(coordX * 100) / 100,
			y: Math.round(coordY * 100) / 100,
			parent: {
				width: parentDOM.width,
				height: parentDOM.height,
			}
		};

		return data;
	}

	getAlignmentGrids = () => {
		const parent = this.cntAlignmentCrosshairRef.current;
		if(!parent) return null;

		const { imageOverlaySettings: { alignmentBoardGrid } } = this.state;
		
		const alignmentBoardRef = parent.querySelector('.alignmentBoardRef');
		const rect = alignmentBoardRef.getBoundingClientRect();

		const x = Math.floor((rect.width / 100) * alignmentBoardGrid);
		const y = Math.floor((rect.height / 100) * alignmentBoardGrid);

		const gridsX = Math.floor(100 / x);
		const gridsY = Math.floor(100 / y);

		return { x, y, gridsX, gridsY };
	}

	getIsUpdated = () => this.state.isUpdated

	getStateSnapshotData = () => {
		const state = cloneDeep(this.state);

		const legs = state.legs;
		const groups = state.groups;
		
		const imageList = state.imageList;
		const musicList = state.musicList;
		
		const header = state.header;
		const footer = state.footer;

		return { legs, groups, imageList, musicList, header, footer, time: moment.now() };
	}

	getFromToHistoryIndex = historyId => {
		const { history } = this.props;
		const historyIndex = findIndex(history, { historyId });
		const fromIndex = Number.isInteger(historyIndex) ? historyIndex + 1 : null;
		const toIndex = history.length - 1;

		return { fromIndex, toIndex }
	}

	// Templates

	getGroupTemplate = item => (
		<span className="item-group">
			<span className="item-inner">
				<span className="item-icon"><i className="material-icons">folder_open</i></span>
				<span className="item-title itemTitle">{ item.name }</span>
				<span className="item-actions">
					<span
						className="atn atn-context atnContext"
						data-uk-tooltip="{pos: top}"
						title="More Options"
						onClick={ e => this.onGroupCtxClick(e, item) }
						role="button"
						tabIndex="-1">
						<i className="material-icons">&#xE5D4;</i>
					</span>
				</span>
			</span>
		</span>
	)

	getLegTemplate = (item, i) => {
		const theme = this.getTheme(item.licensed, item.isPurchased);
		const iconStyle = {
			color: theme
		}

		return (
			<li
				className="dd-item dd-nochildren sq-item"
				key={ item.nodeId }
				data-id={ item.autoId }
				data-node-type={ item.nodeType }
				data-node-id={ item.nodeId }
				data-leg-id={ item.objectId }
				data-index={ item.index }
				data-ctxtoggle-parent>
				<span className="item-inner">
					{/* <span className="item-handle sq-handle dd-handle sqHandle"><i className="material-icons">&#xE5D2;</i></span> */}
					<span className="item-icon" style={ iconStyle }><i className="material-icons">&#xE87B;</i></span>
					<span className="item-title itemTitle">{ item.text }</span>
					<span className={ `item-music ${ !item.bgMusic ? 'd-none' : '' }` }>
						<i className="material-icons uk-text-muted">music_note</i>
					</span>
					<span className="item-actions">
						<span
							className="atn atn-add atnPlayLeg"
							data-uk-tooltip="{pos: top}"
							title="Play Leg"
							onClick={ e => this.onLegPlay(e, i) }
							role="button"
							tabIndex="-1">
							<i className="material-icons">&#xE038;</i>
						</span>
						<span
							className="atn atn-context atnContext"
							data-uk-tooltip="{pos: top}"
							title="More Options"
							onClick={ e => this.onLegCtxClick(e, item) }
							role="button"
							tabIndex="-1">
							<i className="material-icons">&#xE5D4;</i>
						</span>
					</span>
				</span>
			</li>
		);
	}

	// Setters

	setUrl = expanded => {
		const { tour } = this.props;
		// const isExpanded = this.checkIsExpandedViaUrl();
		expanded = expanded || false;

		console.log(expanded);

		window.history.pushState({}, document.title, `?sqPanelTourId=${ tour.tourId }&expanded=${ expanded }`);
	}

	setItem = (obj, type, callback) => {
		const { legs } = this.state;
		const existingItem = find(legs, ['nodeId', obj.nodeId]);

		if(!existingItem) {
			if(type !== 'initial') {
				this.takeHistorySnapshot(obj.text, History.action.CREATE_LEG);
				this.setState({ isUpdated: true });
			}

			this.setState({
				legs: [ ...legs, obj ]
			}, () => {
				if(obj.group) this.addGroup(obj.group);
				this.saveTourMixer(this.getTourMixer);
				if(callback) callback();
			});
		} else {
			Notify.error(`${ obj.text } already exists in tour.`);
		}
	}

	// Generators

	generateImageCoords = () => {
		const imageCoords = [];
		const { width, height } = this.alignmentDOM;
		const coords = [-100, -50, 0, 50, 100];

		for(let i = 0; i < coords.length; i += 1) {
			const ix = coords[i];
			const x = ((width / 2) / 100) * ix;
			
			for(let j = coords.length - 1; j >= 0; j -= 1) {
				const jy = coords[j];
				const y = ((height / 2) / 100) * jy;
				const pxPos = this.getCoordsToPixel(x, y);

				const coord = {
					coordId: uuid(),
					x,
					y,
					positionPixel: {
						top: pxPos.top,
						left: pxPos.left
					},
					position: this.getImagePercentagePosition(pxPos.left, pxPos.top),
					selected: false,
				}

				imageCoords.push(coord);
			}
		}

		this.updateImageCoordsState(mapKeys(imageCoords, 'coordId'));
	}

	generateList = data => {
		const { groups, legs } = this.state;

		const list = [];
		data = data || legs;

		const evaluateGroup = group => {
			const firstLegId = group.legIds[0] || false;
			const firstLegMeta = firstLegId ? this.getLegsMeta(firstLegId) : false;
			const groupLegs = filter(legs, leg => indexOf(group.legIds, leg.objectId) >= 0);

			group.listId = group.groupId;
			group.nodeType = 'group';
			group.text = group.name;
			group.playTime = sum(map(groupLegs, 'playTime'));
			group.startTime = firstLegMeta ? firstLegMeta.startTime : 0;

			return group;
		}

		map(data, (leg, i) => {
			const group = find(groups, { name: leg.group });
			const meta = this.getLegsMeta(leg.objectId);
			
			leg.index = i;
			leg.nodeType = 'leg';
			leg.listId = leg.nodeId;
			leg.startTime = meta ? meta.startTime : 0;

			if(leg.group && group) {
				const groupObj = evaluateGroup(group);
				groupObj.index = i;

				const isExisting = find(list, { listId: groupObj.listId });
				if(!isExisting) list.push(groupObj);
			}

			list.push(leg);
		});

		map(groups, group => {
			const isExisting = get(list, group.groupId);

			if(!isExisting && !group.legIds.length) {
				const groupObj = evaluateGroup(group);

				if(group.position >= 0) {
					list.splice(group.position, 0, groupObj);
				} else {
					list.push(groupObj);
				}
			}
		});

		const generatedList = mapKeys(list, 'listId');
		return generatedList;
	}

	generateMixerMediaSnaps = () => {
		const snaps = [];

		const legs = this.getLegsMeta();

		if(legs.length) {
			map(legs, leg => {
				const snap = {
					snapId: `leg_${ uuid() }`,
					itemId: `leg_${ leg.legId }`,
					type: 'leg',
					position: this.getPixels(leg.startTime),
				}
	
				snaps.push(snap);
			});
	
			const lastLeg = last(legs);
			const lastLegSnap = {
				snapId: `leg_${ uuid() }`,
				itemId: `leg_${ lastLeg.legId }`,
				type: 'leg',
				position: this.getPixels(lastLeg.endTime)
			}
			
			snaps.push(lastLegSnap);
		}

		const { imageList, musicList } = this.state;

		if(size(imageList)) {
			map(imageList, image => {
				const snapStart = {
					snapId: `image_${ uuid() }`,
					itemId: `mediaImageUpload_${ image.imageId }`,
					type: 'image',
					position: this.getPixels(image.startTime)
				}
	
				snaps.push(snapStart);
				
				const snapEnd = {
					snapId: `image_${ uuid() }`,
					itemId: `mediaImageUpload_${ image.imageId }`,
					type: 'image',
					position: this.getPixels(image.startTime + image.playTime)
				}
	
				snaps.push(snapEnd);
			});
		}

		if(size(musicList)) {
			map(musicList, music => {
				const snapStart = {
					snapId: `music_${ uuid() }`,
					itemId: `mediaMusicUpload_${ music.musicId }`,
					type: 'music',
					position: this.getPixels(music.startTime)
				}
	
				snaps.push(snapStart);
				
				const snapEnd = {
					snapId: `music_${ uuid() }`,
					itemId: `mediaMusicUpload_${ music.musicId }`,
					type: 'music',
					position: this.getPixels(music.startTime + music.playTime)
				}
	
				snaps.push(snapEnd);
			});
		}
		
		return snaps;
	}

	// Group

	addGroup = (name, callback) => {
		const { groups } = this.state;

		const group = find(groups, { name });
		if(!group) return;

		group.added = true;

		if(group.legIds.length) {
			const meta = this.getLegsMeta(group.legIds[0]);
			if(meta) group.startTime = meta.startTime;
		}

		this.updateGroupsState({ [group.groupId]: group }, () => {
			if(callback) callback();
		});
	}

	saveGroup = (name = null, callback) => {
		const { groupForm } = this.state;

		const groupId = groupForm.groupId;
		const groupName = groupForm.name || name;

		if(!groupName) {
			UIKit.modal.alert('Group name cannot be empty!');
			return;
		}

		if(!this.checkGroupNames()) return;

		if(groupId) {
			this.renameGroup(groupId, groupName);
		} else {
			this.takeHistorySnapshot(groupName, History.action.CREATE_GROUP);

			const group = {
				name: groupName,
	
				groupId: uuid(),
				legIds: [],
				added: false,
				startTime: 0,
				playTime: 0,
				position: -1,
			}
			
			this.updateGroupsState({ [group.groupId]: group }, () => {
				this.setState({ isUpdated: true });
				if(!groupId) this.addGroup(name);
				if(callback) callback();
			});
		}
	}

	checkGroupNames = (groupId, title) => {
		const { groups } = this.state;

		const names = filter(groups, group => {
			if(!groupId) return group.name === title;
			return (group.name === title) && (groupId !== group.groupId);
		});

		if(size(names)) {
			UIKit.modal.alert('Group already exists.');
			return false;
		}

		return true;
	}

	renameGroup = (groupId, title, callback) => {
		const { groups } = this.state;

		const group = get(groups, groupId);
		if(!group || isEmpty(group)) {
			Notify.error('Group not found!');
			return;
		}

		this.takeHistorySnapshot(group.name, History.action.RENAME_GROUP);

		group.name = title;

		const data = {
			nodeId: group.groupId,
			text: group.name,
		}

		const tree = this.sqTreeRef.current;
		if(tree) tree.renameNode(data);

		if(group.legIds.length) {
			const { legs } = this.state;
			map(group.legIds, legId => {
				const leg = find(legs, { objectId: legId });
				if(leg) {
					leg.group = title;
					this.setState({ legs: [...legs, ...leg] });
				}
			});
		}

		this.updateGroupsState({ [group.groupId]: group }, () => {
			this.setState({ isUpdated: true });
			if(!groupId) this.addGroup(group.name);
			if(callback) callback();
		});
	}

	removeGroup = (groupId, removeLegs) => {
		const data = this.getStateSnapshotData();
		this.updateHistorySnapshotState({ active: false });

		const { groups, legs } = this.state;
		const group = get(groups, groupId);
		const tree = this.sqTreeRef.current;

		if(group.legIds) {
			map(group.legIds, legId => {
				const leg = find(legs, { objectId: legId });
				if(leg) {
					if(removeLegs) {
						this.removeItem(leg.nodeId);
					} else {
						tree.moveLeg(leg.nodeId, '#');
					}
				}
			});
		}

		if(tree) tree.deleteNode(group.groupId);

		this.updateHistorySnapshotState({
			active: true
		}, () => {
			this.takeHistorySnapshot(group.name, History.action.DELETE_GROUP, null, data);
		});

		const updatedGroups = omit(groups, group.groupId);
		this.setState({
			groups: updatedGroups,
			isUpdated: true
		}, () => {
			this.updateMixerTotalPlayTime();
		});
	}

	deleteGroup = (groupId, removeLegs = true) => {
		if(!groupId) return;

		const { groups } = this.state;
		const group = get(groups, groupId);
		if(!group) return;

		if(removeLegs) {
			UIKit.modal.confirm('Deleting group will also delete its legs. Are you sure?', () => {
				this.removeGroup(groupId, removeLegs);
			});
		} else {
			UIKit.modal.confirm('Are you sure?', () => {
				this.removeGroup(groupId, removeLegs);
			});
		}
	}

	openGroupModal = (groupId = null, name = '') => {
		const { groups } = this.state;
		let group = {};

		if(groupId) {
			group = get(groups, groupId);
		}

		const groupName = group ? group.name : '';
		name = name || groupName;

		this.updateGroupFormState({
			name,
			groupId: group ? group.groupId : null
		}, () => {
			const modal = this.groupModalRef.current;
			modal.show();
		});
	}

	handleGroupFormInputs = e => {
		const { name, value } = e.currentTarget;
		this.updateGroupFormState({ [name]: value });
	}

	// Keyboard Shortcuts

	bindKeyboardShortcuts = () => {
		$(document).off('keydown.undo').on('keydown.undo', e => {
			const key = e.key.toLowerCase();
			const isEscape = (key === 'escape') || (key === 'esc');
			
			if(isEscape) this.undoHistory();
		});
	}
	
	unbindKeyboardShortcuts = () => {
		$(document).off('keydown.undo');
	}

	// Add Item

	addItem = (data, type = 'regular') => {
		const obj = this.makeItem(data);
		this.setItem(obj, type);
	}

	makeItem = data => {
		const { legs } = this.state;

		const obj = {
			text: data.title,
			nodeId: data.nodeId,
			bgMusic: data.bgMusic,
			autoId: legs.length + 1,
			objectId: data.objectId,
			licensed: data.licensed,
			playTime: data.playTime,
			group: data.group || null,
			isPurchased: data.isPurchased,
		}

		return obj;
	}

	// Checks

	checkUpload = file => {
		let isValid = true;
		const errors = [];

		const limitMB = 6;
		const limit = limitMB * (1024 ** 2);

		if(file.size > limit) {
			isValid = false;
			errors.push(`Upload limit exceeded. Allowed upload limit is ~${ limitMB }MB.`);
		}

		if(!isValid && errors.length) {
			let errorsHTML = '';
			map(errors, (error, i) => {
				const marginClass = i !== (errors.length - 1) ? 'm-b-10' : '';
				errorsHTML += `<p class="text-muted ${ marginClass }">${ error }</p>`;
			});

			UIKit.modal.alert(errorsHTML);
		}

		return isValid;
	}

	// Requests

	doRequestTourMixer = (success, fail) => {
		console.log('GET TOUR MIXER');

		const { tour: { tourId } } = this.props;

		const params = {
			tourId,
			details: true,
		}

		this.setState({ loading: true, fetching: true });

		this.props.getTourMixer(params, (status, response) => {
			this.initDroppable();
			this.initNodeDraggable();

			const { legs, music, media, header, footer, images } = response;

			if(media && !isEmpty(media)) {
				this.setState({ mediaList: media });
			}

			if(header && !isEmpty(header)) {
				const headerMedia = get(this.state.mediaList, header.media) || {};
				this.setState({
					header: {
						...header,
						headerId: header.headerId || uuid(),
						mediaId: header.mediaId || '',
						name: header.name || headerMedia.name || '',
						originalName: header.originalName || headerMedia.name || '',
					}
				});
			}
			
			if(footer && !isEmpty(footer)) {
				const footerMedia = get(this.state.mediaList, footer.media) || {};
				this.setState({
					footer: {
						...footer,
						footerId: footer.footerId || uuid(),
						mediaId: footer.mediaId,
						name: footer.name || footerMedia.name || '',
						originalName: footer.originalName || footerMedia.name || '',
					}
				});
			}

			if(legs && legs.length) {
				const tourLegs = legs;

				const groupsNames = uniq(map(filter(tourLegs, leg => !isEmpty(leg.group)), 'group'));

				let groups = map(groupsNames, name => ({
					name,

					groupId: uuid(),
					legIds: [],
					added: false,
					startTime: 0,
					playTime: 0,
					position: -1,
				}));

				if(groups.length) {
					groups = map(groups, group => {
						const groupLegs = filter(tourLegs, leg => leg.group === group.name);

						group.legIds = map(groupLegs, 'legId');
						group.playTime = sum(map(groupLegs, 'playTime'));

						return group;
					});

					this.setState({
						groups: mapKeys(groups, 'groupId')
					});
				}

				const items = map(tourLegs, (node, i) => {
					const item = {
						autoId: i,
						group: node.group,
						text: node.meta && node.meta.title ? node.meta.title : node.title,
						nodeId: node.nodeId,
						objectId: node.legId,
						position: -1,
						recording: node.recording,
						licensed: node.licensed || false,
						playTime: parseInt(node.playTime, 10),
						isPurchased: node.isPurchased || false,
						bgMusic: !isEmpty(node.bgMusic) ? node.bgMusic.src !== null : false,
					}

					return item;
				});

				this.setState({ legs: items });
			}

			if(music && music.length) {
				const sortedMusic = orderBy(music, 'zIndex', 'desc');
				const musicList = mapKeys(map(sortedMusic, (item, i) => {
					const mediaItem = get(this.state.mediaList, item.mediaId);

					return {
						...item,
						zIndex: item.zIndex || (i + 1),
						musicId: item.musicId || uuid(),
						playTime: Math.ceil(item.playTime),
						endTime: Math.ceil(item.endTime),
						startTime: Math.ceil(item.startTime),
						volume: parseInt(item.volume, 10) || 100,
						background: item.background || tinycolor.random().toHexString(),
						name: item.name || mediaItem.name || '',
						originalName: item.originalName || mediaItem.name || '',
					};
				}), 'musicId');
				
				this.setState({ musicList });
			}
			
			if(images && images.length) {
				const aCenter = this.alignmentDOM.center;
				const coords = this.getImageCoords(aCenter.x, aCenter.y);

				const sortedImages = orderBy(images, 'zIndex', 'desc');
				const imageList = mapKeys(map(sortedImages, (item, i) => {
					const mediaItem = get(this.state.mediaList, item.mediaId);

					return {
						...item,
						width: item.width,
						height: item.height,
						offset: item.offset || 0,
						zIndex: item.zIndex || (i + 1),
						imageId: item.imageId || uuid(),
						playTime: Math.ceil(item.playTime),
						endTime: Math.ceil(item.endTime) || Math.ceil(item.startTime + item.playTime),
						startTime: Math.ceil(item.startTime),
						theme: item.theme || tinycolor.random().toHexString(),
						coords: item.coords || coords,
						wRatio: item.wRatio || 1,
						name: item.name || mediaItem.name || '',
						originalName: item.originalName || mediaItem.name || '',
						transition: {
							entrance: 'fadeIn',
							exit: 'fadeOut',
							duration: 1,
							...item.transition
						}
					};
				}), 'imageId');
				
				this.setState({ imageList });
			}

			this.setState({ loading: false, fetching: false }, () => {
				this.watchNodes();
				this.closeHandler();

				this.captureStartHistory();

				this.bindKeyboardShortcuts();
				this.handlePanelViaUrl();

				this.props.checkSqPanelIsBusy(false);

				if(success) success();
			});

		}, () => {
			this.setState({ loading: false, fetching: false });
			if(fail) fail();
		});
	}

	doRequestSaveTourMixer = (success, fail) => {
		console.log('SAVE TOUR MIXER');

		const { tour } = this.props;
		const { legs, musicList, imageList, header, footer } = this.state;
		const objectIds = uniq(map(legs, 'objectId'));

		const images = map(imageList, item => pick(item, ['imageId', 'mediaId', 'legId', 'playTime', 'startTime', 'endTime', 'zIndex', 'position', 'positionX', 'positionY', 'width', 'height', 'theme', 'locked', 'offset', 'coords', 'wRatio', 'transition', 'name', 'originalName']));
		const music = map(musicList, item => pick(item, ['musicId', 'mediaId', 'legId', 'playTime', 'originalPlaytime', 'startTime', 'endTime', 'volume', 'zIndex', 'background', 'locked', 'offset', 'name', 'originalName']));
		const updatedLegs = filter(legs, leg => !isEmpty(leg.group) || !isEmpty(leg.meta));
		const mixerLegs = map(updatedLegs, leg => ({
			legId: leg.objectId,
			group: leg.group,
			meta: leg.meta
		}));

		const data = {
			music,
			images: orderBy(images, 'startTime', 'asc'),
			
			tourId: tour.tourId,

			legs: mixerLegs,
			legIds: objectIds,

			musicSequence: this.getMusicSequence(),
		}

		if(header.mediaId) data.header = header;
		if(footer.mediaId) data.footer = footer;

		this.setState({ loading: true });

		if(this.state.loading) {
			this.props.saveTourMixer(data, () => {
				this.resetHistory();
				this.setState({ loading: false, isUpdated: false }, this.captureStartHistory);
				if(success) success();
			}, (status, errors) => {
				this.setState({ loading: false, isUpdated: true });
				if(fail) fail();

				if(errors.length) map(errors, error => Notify.error(error.message));
			});
		}
	}

	// Play

	playTour = (tourId, legIds, nodeType, srcType, autoplay, fromIndex, toIndex, type, options = {}) => {
		this.props.checkSqPanelIsUpdated(() => {
			this.props.playTour(tourId, legIds, nodeType, srcType, autoplay, fromIndex, toIndex, type, options);
		});
	}

	playImageTourPreview = (options = {}) => {
		this.updatePlayerState({ type: 'default', render: true }, () => {
			const toursPanel = this.imageTourPreviewPlayerRef.current.wrappedInstance;
			const { tour: { tourId } } = this.props;

			toursPanel.initializePlay(tourId, null, 50, null, true, -1, -1, 'mini', options);
		});
	}

	stopImageTourPreview = () => {
		if(!this.imageTourPreviewPlayerRef.current) return;
		const toursPanel = this.imageTourPreviewPlayerRef.current.wrappedInstance;
		if(toursPanel) toursPanel.disablePanel();
	}

	playLeg = nodeId => {
		const { legs } = this.state;
		const legIndex = findIndex(legs, { nodeId });

		const { tour: { tourId } } = this.props;

		this.playTour(tourId, null, 50, null, true, legIndex);
	}

	// Sortable
	
	initMediaSortables = () => {
		const panel = this.panelRef.current;
		const sortables = panel.querySelectorAll('[data-sortable-list]');

		map(sortables, sortable => {
			const api = new Sortable(sortable, {
				animation: 150,
				ghostClass: 'sq-placeholder',
				
				onUpdate: e => {
					const { to, item } = e;
					const type = to.getAttribute('data-sortable-list');

					let list = null;
					let keyBy = null;

					switch (type) {
						case 'images': 
							list = { ...this.state.imageList };
							keyBy = 'imageId';
							break;
						case 'musics':
							list = { ...this.state.musicList };
							keyBy = 'musicId';
							break;
						default:
							list = null;
							keyBy = null;
							break;
					}

					const id = item.getAttribute('data-id');
					const obj = get(list, id);
					const title = obj.name;

					switch(type) {
						case 'images':
							this.takeHistorySnapshot(title || 'Image', History.action.SORT_IMAGE);
							break;
						case 'musics':
							this.takeHistorySnapshot(title || 'Music', History.action.SORT_MUSIC);
							break;
						default: break;
					}

					const items = to.querySelectorAll('.sqMediaItem');
					const ids = map(items, sqMediaItem => sqMediaItem.getAttribute('data-id'));

					const updatedList = mapKeys(map(ids, (itemId, i) => {
						const sqMediaItem = get(list, itemId);
						const zIndex = (ids.length - i) + 1;

						sqMediaItem.zIndex = zIndex;

						return sqMediaItem;
					}), keyBy);

					switch (type) {
						case 'images':
							this.updateImageListState(updatedList);
							break;
						case 'musics':
							this.updateMusicListState(updatedList);
							break;
						default: break;
					}

					this.setState({ isUpdated: true });
				}
			});

			api.option('animation', 0);
		});
	}

	// Node Draggable

	initNodeDraggable = node => {
		const $draggables = node || $('[data-draggable]');

		map($draggables, draggable => {
			if(!draggable.classList.contains('ui-draggable')) {
				$(draggable).draggable({
					zIndex: 9999,
					scope: 'legs',
					helper: 'clone',
					revert: 'invalid',
					appendTo: '#mdJstreeDraggable',
				});
			}
		});
	}

	destroyNodeDraggable = () => {
		const $draggables = $('[data-draggable]');

		map($draggables, draggable => {
			if(draggable.classList.contains('ui-draggable')) {
				$(draggable).draggable('destroy');
			}
		});

		this.stopWatchNodes();
	}

	// Droppable

	initDroppable = () => {
		const panel = this.panelRef.current;

		$(panel).droppable({
			scope: 'legs',
			accept: '[data-draggable]',
			drop: (event, ui) => {
				const item = ui.helper[0];
				const nodeData = this.getItemAttrs(item);

				const data = {
					bgMusic: false,
					title: nodeData.text,
					objectId: nodeData.objectid,
					nodeId: nodeData['data-id'],
					playTime: parseInt(nodeData.playtime, 10),
					licensed: nodeData.licensed === 'true' || false,
					isPurchased: nodeData.ispurchased === 'true' || false,
				}

				this.addItem(data);
			}
		});
	}
	
	destroyDroppable = () => {
		const panel = this.panelRef.current;
		if(panel.classList.contains('ui-droppable')) {
			$(panel).droppable('destroy');
		}
	}

	// Mixer

	initMixerInteract = () => {
		this.initMusicInteracts();
		this.initImageInteracts();
	}

	initMusicInteracts = () => {
		const mixerMusicUploads = this.cntMixerMusicRef.current;
		const musicUploads = mixerMusicUploads.querySelectorAll('.mediaMusicUpload');
		
		if(!musicUploads.length) return;

		map(musicUploads, upload => {
			this.initMusicInteract(upload);
		});
	}

	initMusicInteract = target => this.initInteraction(target, 'music')

	initImageInteracts = () => {
		const mixerImageUploads = this.cntMixerImageRef.current;
		const imageUploads = mixerImageUploads.querySelectorAll('.mediaImageUpload');
		
		if(!imageUploads.length) return;

		map(imageUploads, upload => {
			this.initImageInteract(upload);
		});
	}
	
	initImageInteract = target => this.initInteraction(target, 'image')

	initInteraction = (el, type) => {
		if(!el || !type) return;

		const isLocked = el.getAttribute('data-locked') === 'true';
		if(isLocked) return;

		this.unsetInteraction(el);

		const snapLineStart = $(this.snapLineStartRef.current);
		const snapLineEnd = $(this.snapLineEndRef.current);

		const Action = {
			DRAG: 10,
			RESIZE: 20,
		}
		
		let snapData = this.generateMixerMediaSnaps();

		const Events = {
			getSnappedElement: () => {},

			start: action => {
				this.destroyGeoThumbnails();
				if(this.geoThumbnailTimer) clearTimeout(this.geoThumbnailTimer);

				el.style.zIndex = 9999;
				$('.uk-tooltip').css('opacity', 0);

				if(action === Action.DRAG) {
					$(snapLineStart).show();
					$(snapLineEnd).show();
				} else if(action === Action.RESIZE) {
					$(snapLineEnd).show();
				}
				
				snapData = this.generateMixerMediaSnaps();

				Ctx.close();
			},

			stop: action => {
				this.destroyGeoThumbnails();
				if(this.geoThumbnailTimer) clearTimeout(this.geoThumbnailTimer);

				$(snapLineStart).hide();
				$(snapLineEnd).hide();

				let list = null;

				switch (type) {
					case 'music': list = this.state.musicList; break;
					case 'image': list = this.state.imageList; break;
					default: list = null; break;
				}

				if(!list) return;

				const zIndex = el.getAttribute('data-zindex');

				const left = parseFloat(el.style.left);
				const width = parseFloat(el.style.width);

				const startTime = Math.ceil(this.getMilliseconds(left));
				const itemId = el.getAttribute('data-id');
				const item = get(list, itemId);
				const title = item.name;

				if(type === 'music') {
					switch (action) {
						case Action.DRAG: this.takeHistorySnapshot(title, History.action.UPDATE_MUSIC_POSITION); break;
						case Action.RESIZE: this.takeHistorySnapshot(title, History.action.UPDATE_MUSIC_SIZE); break;
						default: break;
					}
				} else if(type === 'image') {
					switch (action) {
						case Action.DRAG: this.takeHistorySnapshot(title, History.action.UPDATE_IMAGE_POSITION); break;
						case Action.RESIZE: this.takeHistorySnapshot(title, History.action.UPDATE_IMAGE_SIZE); break;
						default: break;
					}
				}

				if(!item) return;

				const endTime = startTime + item.playTime;

				item.playTime = Math.ceil(this.getMilliseconds(width));
				item.startTime = Math.ceil(startTime);
				item.endTime = endTime;

				el.style.zIndex = parseInt(zIndex, 10);

				switch (type) {
					case 'music': this.updateMusicListState({ [itemId]: item }); break;
					case 'image': this.updateImageListState({ [itemId]: item }); break;
					default: list = null; break;
				}

				this.setState({ isUpdated: true });

				switch(action) {
					case Action.DRAG: this.uploadDraggable = $(el).data('uiDraggable'); break;
					case Action.RESIZE: this.uploadResizeble = $(el).data('uiResizable'); break;
					default: break;
				}
			},

			snap: () => {
				const itemId = $(el).attr('id');
				const filteredSnapData = filter(snapData, item => item.itemId !== itemId);
				const snapPoints = map(filteredSnapData, 'position');

				// const musicSnapPoints = filter(snapData, item => item.type !== 'music');
				// const imageSnapPoints = filter(snapData, item => item.type !== 'image');

				// switch(type) {
				// 	case 'music': snapPoints = map(musicSnapPoints, 'position'); break;
				// 	case 'image': snapPoints = map(imageSnapPoints, 'position'); break;
				// 	default: break;
				// }

				if(!snapPoints.length) return;

				const elWidth = $(el).innerWidth();
				const elLeft = $(el).position().left;
				const elRight = elLeft + elWidth;

				for(let i = 0; i < snapPoints.length; i += 1) {
					const position = parseFloat(snapPoints[i]);

					if((position >= (elLeft - 5)) && (position <= (elLeft + 5))) {
						$(snapLineStart).addClass('snapped');
					}
					
					if((position >= (elRight - 5)) && (position <= (elRight + 5))) {
						$(snapLineEnd).addClass('snapped');
					}
				}
			},

			interact: (action, left, width = $(el).innerWidth()) => {
				const offset = 25;
				const posLeft = left + offset;
				
				$(snapLineStart).removeClass('snapped').css('left', posLeft);
				$(snapLineEnd).removeClass('snapped').css('left', (posLeft + width));

				this.destroyGeoThumbnails();

				if(this.geoThumbnailTimer) clearTimeout(this.geoThumbnailTimer);
				this.geoThumbnailTimer = setTimeout(() => {
					this.handleGeoThumbnails(left, left + width, el);
				}, 500);
				
				Events.snap(action);
				// setTimeout(Events.snap, 50, action);
			}
		}

		const snapTo = '.mediaSnap';

		// switch(type) {
		// 	case 'music': snapTo = '.legSnap, .imageSnap'; break;
		// 	case 'image': snapTo = '.legSnap, .musicSnap'; break;
		// 	default: snapTo = '.legSnap'; break;
		// }

		$(el).draggable({
			axis: 'x',
			cancel: '.uploadCtx',
			containment: 'parent',

			snap: snapTo,
			snapMode: 'both',
			snapTolerance: 5,

			start: () => Events.start(Action.DRAG),
			stop: () => Events.stop(Action.DRAG),
			drag: (e, ui) => Events.interact(Action.DRAG, ui.position.left)
		});
		
		$(el).resizable({
			snap: snapTo,
			snapMode: 'both',
			snapTolerance: 5,
			containment: 'parent',
			
			handles: 'e',
			
			start: () => Events.start(Action.RESIZE),
			stop: () => Events.stop(Action.RESIZE),

			resize: (e, ui) => {
				ui.size.height = ui.originalSize.height;
				Events.interact(Action.RESIZE, ui.position.left, ui.size.width);
			},
		});

		this.uploadDraggable = $(el).data('uiDraggable');
		this.uploadResizeble = $(el).data('uiResizable');
	}

	unsetInteraction = el => {
		if(!el) return;

		const elClasses = el.classList;

		if(elClasses.contains('ui-draggable')) $(el).draggable('destroy');
		if(elClasses.contains('ui-resizable')) $(el).resizable('destroy');
	}

	initCustomAlignmentInteract = callback => {
		const { imageCoords } = this.state;
		const parent = this.cntAlignmentCrosshairRef.current;
		
		if(!parent || this.alignmentDraggable) return;
		
		const crosshair = parent.querySelector('.alignmentCrosshair');
		const alignmentBoard = parent.querySelector('.alignmentBoard');
		const alignmentGrids = parent.querySelector('.alignmentGrids');
		const alignmentBoardRef = parent.querySelector('.alignmentBoardRef');
		const alignmentSnapBoard = parent.querySelector('.alignmentSnapBoard');
		const alignmentSnapGuideX = parent.querySelector('.alignmentSnapGuideX');
		const alignmentSnapGuideY = parent.querySelector('.alignmentSnapGuideY');
		const alignmentToggleGroups = parent.querySelector('.alignmentToggleGroups');
		
		const $alignmentToggleGroups = $(alignmentToggleGroups);

		const height = alignmentBoard.clientHeight + crosshair.clientHeight;
		const width = alignmentBoard.clientWidth + crosshair.clientWidth;

		const crosshairWidth = crosshair.clientWidth;
		const crosshairHeight = crosshair.clientHeight;

		const styles = {
			height,
			width,
		}

		$(alignmentBoardRef).css(styles);
		$(alignmentSnapBoard).css(styles);
		$(alignmentGrids).css({
			width: alignmentBoard.clientWidth,
			height: alignmentBoard.clientHeight,
		});

		const getSnappedElement = () => {
			if(!this.alignmentDraggable) return null;
			const snappingElements = filter(this.alignmentDraggable.snapElements, 'snapping');
			if(!snappingElements.length) return null;
			return snappingElements[0].item;
		}

		const handlePosition = e => {
			const target = e.currentTarget;
			const rect = target.getBoundingClientRect();

			const xPosition = e.clientX - rect.left;
			const yPosition = e.clientY - rect.top;

			this.handleCrosshairPosition(xPosition, yPosition);

			e.type = "mousedown.draggable";
			e.target = crosshair;
			$(crosshair).trigger(e);

			const position = this.getImagePercentagePosition(xPosition, yPosition);
			this.handleImagePositionSettings(position.left, position.top);

			$('.alignmentSnap').removeClass('snapped');
			$('.toggleGroup', $alignmentToggleGroups).removeClass('ui-notouch');

			// this.handleCoordInput(null, false);
		}

		$(alignmentBoard).off('mousedown.interact').on('mousedown.interact', e => {
			e.preventDefault();

			const { player } = this.state;
			if(!player.active) handlePosition(e);
		});

		const positionsLeft = uniq(map(imageCoords, coord => coord.positionPixel.left));
		const positionsTop = uniq(map(imageCoords, coord => coord.positionPixel.top));

		map(imageCoords, coord => {
			const { positionPixel, coordId } = coord;

			const $snap = $(`<span class="alignment-snap alignmentSnap" data-id="${ coordId }"/>`);
			$snap.css({
				display: 'block',
				top: positionPixel.top,
				left: positionPixel.left,
			});

			$(alignmentSnapBoard).append($snap);
		});

		map(positionsLeft, position => {
			const $snap = $(`<span class="alignment-line alignmentSnapAxis" data-position="left"/>`);
			$snap.css({
				position: 'absolute',
				width: crosshairWidth,
				top: 0,
				left: position,
				zIndeX: -1,
				height: '100%',
				// background: 'blue'
			});

			$(alignmentSnapBoard).append($snap);
		});
		
		map(positionsTop, position => {
			const $snap = $(`<span class="alignment-line alignmentSnapAxis" data-position="top"/>`);
			$snap.css({
				position: 'absolute',
				height: crosshairWidth,
				top: position,
				left: 0,
				zIndeX: -1,
				width: '100%',
				// background: 'yellow'
			});

			$(alignmentSnapBoard).append($snap);
		});

		$(crosshair).draggable({
			containment: alignmentBoardRef,

			cursorAt: {
				top: crosshairHeight / 2,
				left: crosshairWidth / 2,
			},
			
			refreshPositions: true,

			snapTolerance: 10,
			snapMode: 'inner',
			snap: '.alignmentSnap, .alignmentSnapAxis',
			
			// grid: [7.5, 7.5],

			create: () => {
				const { imageOverlaySettings: { alignmentBoardActive } } = this.state;
				this.alignmentDraggable = $(crosshair).data('uiDraggable');

				$('.alignmentSnap').removeClass('snapped');
				$('.alignmentSnapGuide').hide().removeClass('active');

				// this.handleCustomAlignmentGrid();

				if(!alignmentBoardActive) {
					this.updateImageOverlaySettingsState({ alignmentBoardActive: true }, callback);
				}
			},

			start: () => {
				$('.alignmentSnap').removeClass('snapped');
				$('.alignmentSnapGuide').hide().removeClass('active');
				
				$('.toggleGroup', $alignmentToggleGroups).addClass('ui-notouch');

				// this.handleCoordInput(null, false);
			},

			drag: (e, ui) => {
				$('.alignmentSnap').removeClass('snapped');
				$('.alignmentSnapGuide').hide().removeClass('active');

				const left = ui.position.left + (crosshairWidth / 2);
				const top = ui.position.top + (crosshairHeight / 2);

				const position = this.getImagePercentagePosition(left, top);
				this.handleImagePositionSettings(position.left, position.top, false);

				const snappedElement = getSnappedElement();

				if(snappedElement) {
					const elPosition = $(snappedElement).position();
					
					const guideXPos = elPosition.top + (crosshairHeight / 2);
					const guideYPos = elPosition.left + (crosshairWidth / 2);
					
					$(snappedElement).addClass('snapped');

					$(alignmentSnapGuideX).css('top', guideXPos);
					$(alignmentSnapGuideY).css('left', guideYPos);

					if($(snappedElement).hasClass('alignmentSnapAxis')) {
						const sePosition = $(snappedElement).data('position');
						if(sePosition === 'left') $(alignmentSnapGuideY).show().addClass('active');
						if(sePosition === 'top') $(alignmentSnapGuideX).show().addClass('active');
					} else {
						$('.alignmentSnapGuide').show().addClass('active');
					}
				}
			},

			stop: () => {
				$('.alignmentSnap').removeClass('snapped');
				$('.alignmentSnapGuide').hide().removeClass('active');

				$('.toggleGroup', $alignmentToggleGroups).removeClass('ui-notouch');

				const snappedElement = getSnappedElement();
				if(snappedElement) {
					const coordId = snappedElement.getAttribute('data-id');
					if(coordId) {
						$(`.toggleGroup[data-id=${ coordId }]`, $alignmentToggleGroups).addClass('ui-notouch');
					}
				}

				this.alignmentDraggable = $(crosshair).data('uiDraggable');
			}
		});

		this.alignmentDraggable = $(crosshair).data('uiDraggable');
	}

	closeHandler = () => {}

	handleCustomAlignmentGrid = () => {
		const grid = this.getAlignmentGrids();
		this.alignmentDraggable.option('grid', [grid.x, grid.y]);
	}

	hidePanel = () => {
		this.resetHistory();
		this.setState({
			legs: [],
			isUpdated: false,
			mixer: this.initialMixerState,
			player: this.initialPlayerState,
			uploader: this.initialUploaderState,
		}, () => {
			this.props.hidePanel();
			
			this.destroyDroppable();
			this.destroyNodeDraggable();
		});
	}

	handleContext = (type, props, target, event) => {
		this.props.handleContext(type, props, target, event);
	}

	handleInlineRenameActions = () => {
		const panel = this.panelRef.current;
		const $panel = $(panel);

		$panel.off('dblclick.imageInlineRename').on('dblclick.imageInlineRename', '.sqImageMediaItem', e => {
			e.preventDefault();

			const $this = $(e.currentTarget);
			const imageId = $this.data('id');

			this.handleImageInlineRename(imageId);
		});

		$panel.off('dblclick.musicInlineRename').on('dblclick.musicInlineRename', '.sqMusicMediaItem', e => {
			e.preventDefault();

			const $this = $(e.currentTarget);
			const musicId = $this.data('id');

			this.handleMusicInlineRename(musicId);
		});
		
		$panel.off('dblclick.treeNodeInlineRename').on('dblclick.treeNodeInlineRename', '.sqTree .jsTreeAnchor', e => {
			e.preventDefault();

			const $this = $(e.currentTarget);
			const $parent = $this.parent('li');
			const nodeId = $parent.data('id');
			const nodeType = $parent.attr('type');

			if(nodeType === 'group') {
				this.handleGroupInlineRename(nodeId);
			} else if(nodeType === 'leg') {
				this.handleLegInlineRename(nodeId);
			}
		});
	}

	handleImageInlineRename = imageId => {
		const { imagePanel } = this.state;
		if(imagePanel.minimize) this.handleImageList();

		setTimeout(() => {
			const $target = $(`.sqImageMediaItem[data-id="${ imageId }"]`);
			const title = $('.itemTitle', $target).text();
	
			const inlineRenameHelper = InlineRenameHelper($target, title, {
				width: 200,
				offset: {
					left: 10
				}
			});
	
			inlineRenameHelper.init();
			inlineRenameHelper.open();
			
			inlineRenameHelper.onFormSubmit((form, value) => {
				this.takeHistorySnapshot(title, History.action.RENAME_IMAGE);

				const { imageList } = this.state;
				const $txtTitle = $('.txtTitle', $(form));
				
				const image = get(imageList, imageId);
				image.name = value;
				
				this.updateImageListState({ [imageId]: image }, () => {
					$txtTitle.trigger('blur');
				});
	
				this.setState({ isUpdated: true });
			});
		}, imagePanel.minimize ? 200 : 0);
	}
	
	handleMusicInlineRename = musicId => {
		const { musicPanel } = this.state;
		if(musicPanel.minimize) this.handleMusicList();

		setTimeout(() => {
			const $target = $(`.sqMusicMediaItem[data-id="${ musicId }"]`);
			const title = $('.itemTitle', $target).text();

			const inlineRenameHelper = InlineRenameHelper($target, title, {
				width: 200,
				offset: {
					left: 10
				}
			});

			inlineRenameHelper.init();
			inlineRenameHelper.open();
			
			inlineRenameHelper.onFormSubmit((form, value) => {
				this.takeHistorySnapshot(title, History.action.RENAME_MUSIC);

				const { musicList } = this.state;
				const $txtTitle = $('.txtTitle', $(form));
				
				const music = get(musicList, musicId);
				music.name = value;
				
				this.updateMusicListState({ [musicId]: music }, () => {
					$txtTitle.trigger('blur');
				});

				this.setState({ isUpdated: true });
			});
		}, musicPanel.minimize ? 200 : 0);
	}

	handleGroupInlineRename = groupId => {
		const { panel } = this.state;
		if(panel.minimize) this.handleLegList();
		
		setTimeout(() => {
			const tree = this.sqTreeRef.current;
			const treeNodeId = tree.getPrefixedId(groupId);
			const node = tree.getNode(treeNodeId);
			if(!node) return;

			const $target = $(`#${ treeNodeId }`);
			const title = node.li_attr.text;

			const inlineRenameHelper = InlineRenameHelper($target, title, {
				width: 200,
				offset: {
					left: 10
				}
			});

			inlineRenameHelper.init();
			inlineRenameHelper.open();
			
			inlineRenameHelper.onFormSubmit((form, value) => {
				// this.takeHistorySnapshot(title, History.action.RENAME_GROUP);

				this.renameGroup(groupId, value, () => {
					const $txtTitle = $('.txtTitle', $(form));
					$txtTitle.trigger('blur');
				})
			});
		}, panel.minimize ? 200 : 0);
	}
	
	handleLegInlineRename = nodeId => {
		const { panel } = this.state;
		if(panel.minimize) this.handleLegList();

		setTimeout(() => {
			const tree = this.sqTreeRef.current;
			const treeNodeId = tree.getPrefixedId(nodeId);
			const node = tree.getNode(treeNodeId);
			if(!node) return;

			const $target = $(`#${ treeNodeId }`);
			const title = node.li_attr.text;

			const inlineRenameHelper = InlineRenameHelper($target, title, {
				width: 200,
				offset: {
					left: 10
				}
			});

			inlineRenameHelper.init();
			inlineRenameHelper.open();
			
			inlineRenameHelper.onFormSubmit((form, value) => {
				this.takeHistorySnapshot(title, History.action.RENAME_LEG);

				const $txtTitle = $('.txtTitle', $(form));

				const leg = find(this.state.legs, { nodeId });
				if(!leg) {
					$txtTitle.trigger('blur');
					return;
				}

				leg.meta = {
					...leg.meta,
					title: value
				}

				this.setState({
					legs: [ ...this.state.legs, leg ]
				}, () => {
					const data = {
						nodeId,
						text: value
					}
		
					tree.renameNode(data);
					$txtTitle.trigger('blur');

					this.setState({ isUpdated: true });
				})
			});
		}, panel.minimize ? 200 : 0);
	}

	// Actions

	initMixer = callback => {
		this.forceUpdate(() => {
			const mixer = this.editorMixerWidthRef.current;
			const { width } = mixer.getBoundingClientRect();

			this.updateMixerState({ width }, () => {
				this.updateScrollbar();
				this.initMediaSortables();
				setTimeout(this.initMixerInteract, 200);

				if(callback) callback();
			});
		});
	}

	initColorPicker = (el, itemId, type) => {
		let list = null;

		switch (type) {
			case 'music': list = this.state.musicList; break;
			case 'image': list = this.state.imageList; break;
			default: break;
		}

		if(!list) return;

		const item = get(list, itemId);
		if(!item) return;
		
		const picker = new window.CP(el);
		picker.enter();

		let pickerTimer = null;

		picker.on('change', (r, g, b) => {
			if(pickerTimer) clearTimeout(pickerTimer);

			const color = window.CP.HEX([r, g, b]);
			el.style.background = color.toString();
		});
		
		picker.on('stop', () => {
			pickerTimer = setTimeout(() => {
				picker.exit();
				picker.pop();
			}, 500);
		});

		picker.on('exit', (r, g, b) => {
			const color = window.CP.HEX([r, g, b]);

			switch (type) {
				case 'music': this.takeHistorySnapshot(item.name, History.action.UPDATE_MUSIC_COLOR); break;
				case 'image': this.takeHistorySnapshot(item.name, History.action.UPDATE_IMAGE_COLOR); break;
				default: break;
			}

			switch (type) {
				case 'music': 
					item.background = `#${ color }`;
					this.updateMusicListState({ [itemId]: item });
					break;
				case 'image':
					item.theme = `#${ color }`;
					this.updateImageListState({ [itemId]: item });
					break;
				default: break;
			}
			
			this.setState({ isUpdated: true });
		});
	}

	initImageColorPicker = (el, imageId) => this.initColorPicker(el, imageId, 'image')
	
	initMusicColorPicker = (el, musicId) => this.initColorPicker(el, musicId, 'music')

	changeImageUploadColor = imageId => {
		const upload = this.getImageEl(imageId);
		this.initImageColorPicker(upload, imageId);
	}

	changeUploadColor = musicId => {
		const upload = this.getMusicEl(musicId);
		this.initMusicColorPicker(upload, musicId);
	}
	
	openTourMixer = callback => {
		const { legs } = this.state;
		if(!legs.length) {
			Notify.error('Please add some legs first.');
			return;
		}

		this.toggleTourMixer(true, () => {
			this.updateUploaderPlayTime();
			this.updateMixerTotalPlayTime();
			this.initMixer();
			this.handleInlineRenameActions();

			if(callback) callback();
		});
	}

	closeTourMixer = callback => {
		this.toggleTourMixer(false, callback);
	}

	toggleTourMixer = (open, callback) => {
		this.updateMixerState({ active: open }, () => {
			this.setUrl(open);
			if(callback) callback();
		});
	}

	zoomInMixer = () => {
		const mixerWidthRef = this.editorMixerWidthRef.current;
		const mixerRef = this.editorMixerRef.current;
		const { width } = mixerWidthRef.getBoundingClientRect();
		const scaledWidth = width + this.zoomScale;

		mixerRef.style.width = `${ scaledWidth }px`;

		this.forceUpdate(() => {
			this.updateScrollbar();
			this.initMixerInteract();
		});
	}

	zoomOutMixer = () => {
		const { mixer } = this.state;
		// const mixerWidthRef = this.editorMixerWidthRef.current;
		const mixerRef = this.editorMixerRef.current;
		const { width } = mixerRef.getBoundingClientRect();
		const scaledWidth = (width - this.zoomScale) + 50;

		if(scaledWidth >= mixer.width) {
			mixerRef.style.width = `${ scaledWidth }px`;

			this.forceUpdate(() => {
				this.updateScrollbar();
				this.initMixerInteract();
			});
		}
	}

	handleLockedUploads = () => {
		const { legs } = this.state;
		
		map(legs, leg => {
			this.handleLockedImages(leg.objectId);
			this.handleLockedMusics(leg.objectId);
		});
	}

	deleteAllData = () => {
		UIKit.modal.confirm('Are you sure you want to empty mixer?', () => {
			this.setState({
				...this.initialDataState,
				isUpdated: true,
			});
		});
	}

	// Image

	openImageUploader = (data) => {
		const uploader = this.mixerImageUploaderRef.current;
		uploader.click();

		this.updateUploaderState(this.initialUploaderState, () => {
			this.updateUploaderPlayTime();
		});
		
		uploader.onchange = () => {
			this.updateUploaderState(data);
			uploader.onchange = null;
		}
	}

	resetImageUpload = imageId => {
		const { imageList, mixer } = this.state;
		const image = get(imageList, imageId);

		if(image.originalPlaytime > mixer.totalPlayTime) {
			image.startTime = 0;
			image.playTime = mixer.totalPlayTime;
		} else {
			image.playTime = image.originalPlaytime;
		}

		this.updateImageListState({ [image.imageId]: image });
		this.setState({ isUpdated: true });
	}

	viewImage = imageId => {
		// const { tour } = this.props;
		const { imageList } = this.state;

		const image = get(imageList, imageId);
		if(!image) return;

		// const images = orderBy(imageList, 'startTime', 'asc');
		// const imageIndex = findIndex(images, image);

		// const options = {
		// 	imageFromIndex: imageIndex
		// }

		// this.playTour(tour.tourId, null, 50, null, true, -1, -1, 'imageMixer', options);

		const data = {
			image,
		}

		this.previewMedia(data, 'image');
	}
	
	previewVideo = entity => {
		let entityData = {};

		const { header, footer } = this.state;

		switch(entity) {
			case 'header': entityData = header; break;
			case 'footer': entityData = footer; break;
			default: entityData = {}; break;
		}

		if(isEmpty(entityData) || isEmpty(entityData.mediaId)) return;

		const data = {
			video: entityData,
			videoType: entity,
		}

		this.previewMedia(data, 'video');
	}

	previewImageTour = () => {
		const { imageOverlaySettings, mixer } = this.state;
		if(!imageOverlaySettings.active) return;

		const entranceSelect = this.transitionEntranceSelect.current;
		const exitSelect = this.transitionExitSelect.current;

		const { image, image: { transition: { duration } } } = imageOverlaySettings;
		const mDuration = duration * 1000;
		const startTime = image.startTime - mDuration;
		const endTime = image.startTime + image.playTime + mDuration;
		const { totalPlayTime } = mixer;

		const options = {
			startTime: startTime >= 0 ? startTime : image.startTime,
			endTime: endTime > totalPlayTime ? (totalPlayTime + mDuration) : endTime
		}

		if(entranceSelect) entranceSelect.disable();
		if(exitSelect) exitSelect.disable();

		this.animateImage(image.transition ? image.transition.exit : 'fadeOut', false);
		this.playImageTourPreview(options);
	}

	imageUploadBringFront = imageId => {
		const { imageList } = this.state;
		const image = get(imageList, imageId);
		const maxIndex = max(map(imageList, 'zIndex'));

		image.zIndex = maxIndex + 1;

		this.updateImageListState({ [imageId]: image });
		this.setState({ isUpdated: true });
	}
	
	imageUploadFlipBack = imageId => {
		const { imageList } = this.state;
		const listArr = map(imageList, listItem => {
			if(listItem.imageId === imageId) {
				listItem.zIndex = 1;
			} else {
				listItem.zIndex += 1;
			}

			return listItem;
		});

		const list = mapKeys(listArr, 'imageId');
		this.setState({ imageList: list, isUpdated: true });
	}

	imageUploadAlignToPreviousLeg = imageId => {
		const { imageList, legs } = this.state;
		const image = get(imageList, imageId);
		
		for(let i = (legs.length - 1); i >= 0; i -= 1) {
			const startTime = this.getLegStartTime(i);

			if(image.startTime > startTime) {
				this.takeHistorySnapshot(image.name, History.action.UPDATE_IMAGE_POSITION_PREVIOUS);
				
				image.startTime = startTime;
				image.endTime = (image.startTime + image.playTime);
				
				this.updateImageListState({ [imageId]: image });
				this.setState({ isUpdated: true });
				break;
			}
		}
	}
	
	imageUploadAlignToNextLeg = imageId => {
		const { imageList, mixer, legs } = this.state;
		const image = get(imageList, imageId);

		for(let i = 0; i < legs.length; i += 1) {
			const startTime = this.getLegStartTime(i);

			if(startTime > image.startTime) {
				const total = startTime + image.playTime;
				if(total <= mixer.totalPlayTime) {
					this.takeHistorySnapshot(image.name, History.action.UPDATE_IMAGE_POSITION_NEXT);

					image.startTime = startTime;
					image.endTime = (image.startTime + image.playTime);
					this.updateImageListState({ [imageId]: image });
					this.setState({ isUpdated: true });
				}
				break;
			}
		}
	}

	deleteImageUpload = imageId => {
		const { imageList } = this.state;
		const image = get(imageList, imageId);
		
		this.takeHistorySnapshot(image.name, History.action.DELETE_IMAGE);

		const list = omit(imageList, imageId);
		this.setState({ imageList: list, isUpdated: true });
	}

	deleteAllImageUploads = () => {
		this.takeHistorySnapshot('Images', History.action.DELETE_ALL_IMAGE);

		this.setState({
			imageList: {},
			isUpdated: true
		});
	}

	handleImageUploadToggleLock = imageId => {
		const { imageList } = this.state;
		const image = get(imageList, imageId);
		if(!image) return;

		const action = image.locked ? History.action.UNLOCK_IMAGE : History.action.LOCK_IMAGE;
		this.takeHistorySnapshot(image.name, action);

		if(image.locked) {
			this.unlockImage(image.imageId);
		} else {
			this.lockImage(image.imageId);
		}
	}

	animateImage = (value, reset) => {
		const imageItem = this.imageItemRef.current;
		if(!imageItem) return;

		imageItem.animate(value, reset);
	}

	handleImageSettings = imageId => {
		const { imageList, mediaList, imageOverlaySettings } = this.state;

		const modal = this.imageSettingsModalRef.current;
		const image = get(imageList, imageId);
		const media = get(mediaList, image.mediaId);

		this.updateImageOverlaySettingsState({
			image: {
				...imageOverlaySettings.image,
				...image,
				imageId,
				position: image.position,
				positionX: image.positionX,
				positionY: image.positionY,
				coords: image.coords,
				wRatio: image.wRatio,
			},
			media
		}, modal.show);
	}

	handleLockedImages = legId => {
		const { imageList } = this.state;
		
		const leg = this.getLegsMeta(legId);
		const images = filter(imageList, image => image.legId === legId);
		
		map(images, image => {
			const startTime = leg.startTime + (image.offset || 0);
			
			image.startTime = startTime;
			image.endTime = startTime + image.playTime;
			
			this.updateImageListState({ [image.imageId]: image });
		});
	}

	lockImage = imageId => {
		const { imageList } = this.state;
		const image = get(imageList, imageId);
		if(!image) return;

		const legs = this.getLegsMeta();

		for (let i = 0; i < legs.length; i += 1) {
			const leg = legs[i];

			const imageStartTime = Math.ceil(image.startTime);
			const legStartTime = Math.ceil(leg.startTime);
			const legEndTime = Math.ceil(leg.startTime + leg.playTime);
			
			if((imageStartTime >= legStartTime) && (imageStartTime < legEndTime)) {
				const offset = image.startTime - leg.startTime;
				const imageEndTime = Math.ceil(image.startTime + image.playTime);

				image.locked = true;
				image.offset = offset;
				image.legId = leg.legId;
				image.startTime = leg.startTime + offset;

				if(imageEndTime > Math.floor(leg.endTime)) {
					image.playTime = leg.endTime - image.startTime;
					image.endTime = image.startTime + image.playTime;
				}
				
				this.updateImageListState({ [image.imageId]: image }, () => {
					this.setState({ isUpdated: true });
					const el = this.getImageEl(image.imageId);
					this.unsetInteraction(el);
				});

				break;
			}
		}
	}

	unlockImage = imageId => {
		const { imageList } = this.state;
		const image = get(imageList, imageId);
		if(!image) return;

		image.locked = false;
		image.offset = 0;
		image.legId = null;

		this.updateImageListState({ [imageId]: image }, () => {
			this.setState({ isUpdated: true });
			const el = this.getImageEl(image.imageId);
			this.initImageInteract(el);
		});
	}

	handleCoordInput = (coordId, check = true) => {
		let $input = $(`.toggleImagePosition`);

		if(coordId) {
			$input = $(`.toggleImagePosition[value=${ coordId }]`);
		}
		
		$input.attr('checked', check);
		// $input.parent().trigger('click');
		
		if(check) {
			$input.iCheck('check');
			$input.parent().addClass('checked');
		} else {
			$input.iCheck('uncheck');
			$input.parent().removeClass('checked');
		}

		// $input.iCheck('update');
	}

	handleCoordsToggle = coordId => {
		const { imageCoords } = this.state;
		const coord = get(imageCoords, coordId);

		if(isEmpty(coord)) return;

		const { position } = coord;
		this.handleImagePositionSettings(position.left, position.top);
		this.handleCrosshairPosition(position.left, position.top, true);
	}

	handleImagePosition = (x, y, dragCrosshair = true) => {
		if(dragCrosshair) this.handleCrosshairPosition(x, y);
		
		const position = this.getImagePercentagePosition(x, y);
		const left = parseFloat(position.left);
		const top = parseFloat(position.top);

		this.handleImagePositionSettings(left, top);
	}

	handleImagePositionInputs = (x, y) => {
		const { height, width } = this.alignmentDOM;

		const pX = ((width / 2) / 100) * x;
		const pY = ((height / 2) / 100) * y;

		const posPx = this.getCoordsToPixel(pX, pY);
		const posPr = this.getImagePercentagePosition(posPx.left, posPx.top);

		this.handleImagePositionSettings(posPr.left, posPr.top, () => {
			this.handleCrosshairPosition(posPr.left, posPr.top, true);
		});
	}

	handleImagePositionSettings = (x, y, callback) => {
		const { imageOverlaySettings } = this.state;
		const coords = this.getImageCoords(x, y, imageOverlaySettings.image.coords, true);

		this.updateImageOverlaySettingsState({
			image: {
				...imageOverlaySettings.image,
				coords,
				position: null,

				positionX: x,
				positionY: y,
			},
			percentageCoordsPosition: this.getImagePercentageCoordsPosition(coords.x, coords.y),
		}, () => {
			const imageItemRef = this.imageItemRef.current;
			if(imageItemRef) imageItemRef.calculate();

			if(callback) callback();
		});
	}

	handleCrosshairPosition = (x, y, percentPosition = false) => {
		const parent = this.cntAlignmentCrosshairRef.current;
		const crosshair = parent.querySelector('.alignmentCrosshair');

		let top = y;
		let left = x;

		if(percentPosition) {
			const position = this.getImagePixelPosition(x, y);
			top = position.top;
			left = position.left;
		}

		const width = $(crosshair).innerWidth();
		const height = $(crosshair).innerHeight();

		top -= (height / 2);
		left -= (width / 2);

		crosshair.style.top = `${ top }px`;
		crosshair.style.left = `${ left }px`;

		crosshair.setAttribute('data-x', left);
		crosshair.setAttribute('data-y', top);
	}

	// Music

	openMusicUploader = (data) => {
		const uploader = this.mixerMusicUploaderRef.current;
		uploader.click();

		this.updateUploaderState(this.initialUploaderState, () => {
			this.updateUploaderPlayTime();
		});
		
		uploader.onchange = () => {
			this.updateUploaderState(data);
			uploader.onchange = null;
		}
	}
	
	resizeUpload = musicId => {
		const upload = this.getMusicEl(musicId);
		let timer = null;

		if(timer) {
			clearTimeout(timer);
			timer = null;
		}
		
		upload.classList.add('show-resize');
		timer = setTimeout(() => {
			upload.classList.remove('show-resize');
		}, 3000);
	}
	
	resetUpload = musicId => {
		const { musicList, mixer } = this.state;
		const music = get(musicList, musicId);

		this.takeHistorySnapshot(music.name, History.action.UPDATE_MUSIC_SIZE);

		if(music.originalPlaytime > mixer.totalPlayTime) {
			music.startTime = 0;
			music.playTime = mixer.totalPlayTime;
		} else {
			music.playTime = music.originalPlaytime;
		}

		this.updateMusicListState({ [music.musicId]: music });
		this.setState({ isUpdated: true });
	}

	uploadBringFront = musicId => {
		const { musicList } = this.state;
		const music = get(musicList, musicId);
		const maxIndex = max(map(musicList, 'zIndex'));

		music.zIndex = maxIndex + 1;

		this.updateMusicListState({ [musicId]: music });
		this.setState({ isUpdated: true });
	}
	
	uploadFlipBack = musicId => {
		const { musicList } = this.state;
		const listArr = map(musicList, listItem => {
			if(listItem.musicId === musicId) {
				listItem.zIndex = 1;
			} else {
				listItem.zIndex += 1;
			}

			return listItem;
		});

		const list = mapKeys(listArr, 'musicId');
		this.setState({ musicList: list, isUpdated: true });
	}

	uploadAlignToPreviousLeg = musicId => {
		const { musicList, legs } = this.state;
		const music = get(musicList, musicId);
		
		for(let i = (legs.length - 1); i >= 0; i -= 1) {
			const startTime = this.getLegStartTime(i);

			if(music.startTime > startTime) {
				this.takeHistorySnapshot(music.name, History.action.UPDATE_MUSIC_POSITION_PREVIOUS);

				music.startTime = startTime;
				music.endTime = (music.startTime + music.playTime);
				this.updateMusicListState({ [musicId]: music });
				this.setState({ isUpdated: true });
				break;
			}
		}
	}
	
	uploadAlignToNextLeg = musicId => {
		const { musicList, mixer, legs } = this.state;
		const music = get(musicList, musicId);

		for(let i = 0; i < legs.length; i += 1) {
			const startTime = this.getLegStartTime(i);

			if(startTime > music.startTime) {
				const total = startTime + music.playTime;
				if(total <= mixer.totalPlayTime) {
					this.takeHistorySnapshot(music.name, History.action.UPDATE_MUSIC_POSITION_NEXT);

					music.startTime = startTime;
					music.endTime = (music.startTime + music.playTime);
					this.updateMusicListState({ [musicId]: music });
					this.setState({ isUpdated: true });
				}
				break;
			}
		}
	}

	deleteUpload = musicId => {
		const { musicList } = this.state;
		const music = get(musicList, musicId);
		if(!music) return;

		this.takeHistorySnapshot(music.name, History.action.DELETE_MUSIC);
		
		const list = omit(musicList, musicId);
		this.setState({ musicList: list, isUpdated: true });
	}

	deleteAllUploads = () => {
		this.takeHistorySnapshot('Music', History.action.DELETE_ALL_MUSIC);

		this.setState({
			musicList: {},
			isUpdated: true
		});
	}

	uploadAddClass = (musicId, className) => {
		const upload = this.getMusicEl(musicId);

		if(upload) $(upload).addClass(className);
	}
	
	uploadRemoveClass = (musicId, className) => {
		const upload = this.getMusicEl(musicId);

		if(upload) $(upload).removeClass(className);
	}

	handleMusicUploadToggleLock = musicId => {
		const { musicList } = this.state;
		const music = get(musicList, musicId);
		if(!music) return;

		const action = music.locked ? History.action.UNLOCK_MUSIC : History.action.LOCK_MUSIC;

		this.takeHistorySnapshot(music.name, action);

		if(music.locked) {
			this.unlockMusic(music.musicId);
		} else {
			this.lockMusic(music.musicId);
		}
	}

	handleLockedMusics = legId => {
		const { musicList } = this.state;

		const leg = this.getLegsMeta(legId);
		const musics = filter(musicList, music => music.legId === legId);

		// if(musics.length) {
		// 	this.takeHistorySnapshot('Locked Music Position Updated on Leg Move.', History.action.UPDATE_POSITION);
		// }

		map(musics, music => {
			const startTime = leg.startTime + (music.offset || 0);

			music.startTime = startTime;
			music.endTime = startTime + music.playTime;

			this.updateMusicListState({ [music.musicId]: music });
			this.setState({ isUpdated: true });
		});
	}

	lockMusic = musicId => {
		const { musicList } = this.state;
		const music = get(musicList, musicId);
		if(!music) return;

		const legs = this.getLegsMeta();
		for (let i = 0; i < legs.length; i += 1) {
			const leg = legs[i];

			if((music.startTime >= leg.startTime) && (music.startTime < leg.endTime)) {
				const offset = music.startTime - leg.startTime;
				const musicEndTime = music.startTime + music.playTime;

				music.locked = true;
				music.offset = offset;
				music.legId = leg.legId;
				// music.startTime = leg.startTime + offset;

				if(musicEndTime > leg.endTime) {
					music.playTime = leg.endTime - music.startTime;
					music.endTime = music.startTime + music.playTime;
				}

				this.updateMusicListState({ [music.musicId]: music }, () => {
					this.setState({ isUpdated: true });
					const el = this.getMusicEl(music.musicId);
					this.unsetInteraction(el);
				});

				break;
			}
		}
	}

	unlockMusic = musicId => {
		const { musicList } = this.state;
		const music = get(musicList, musicId);
		if(!music) return;

		music.offset = 0;
		music.legId = null;
		music.locked = false;

		this.updateMusicListState({ [musicId]: music }, () => {
			this.setState({ isUpdated: true });
			const el = this.getMusicEl(music.musicId);
			this.initMusicInteract(el);
		});
	}

	previewMusic = musicId => {
		const { musicList } = this.state;
		const musicItem = get(musicList, musicId);

		const data = {
			music: musicItem
		}

		this.previewMedia(data, 'music');
	}

	// previewMusicMixer = () => {
	// 	const sequence = this.getMusicSequence();
	// }

	// Header
	
	initHeaderUploadVideo = () => {
		const uploader = this.headerVideoUploaderRef.current;
		uploader.click();
	}
	
	deleteHeaderVideo = () => {
		this.takeHistorySnapshot('Header', History.action.DELETE_HEADER_VIDEO, () => {
			this.setState({ isUpdated: true });
			this.updateMixerHeaderState({ mediaId: null });
		});
	}

	handleHeaderVideoInlineRename = () => {
		const { header } = this.state;
		if(!header.mediaId) {
			Notify.error('No video found!');
			return;
		}

		const $target = $(`#videoHeader_${ header.headerId }`);
		const title = header.name;

		const inlineRenameHelper = InlineRenameHelper($target, title, {
			width: 200,
		});

		inlineRenameHelper.init();
		inlineRenameHelper.open();
		
		inlineRenameHelper.onFormSubmit((form, value) => {
			this.takeHistorySnapshot(title, History.action.RENAME_HEADER_VIDEO);

			this.updateMixerHeaderState({ name: value }, () => {
				const $txtTitle = $('.txtTitle', $(form));
				$txtTitle.trigger('blur');
				this.setState({ isUpdated: true });
			});
		});
	}

	// Footer
	
	initFooterUploadVideo = () => {
		const uploader = this.footerVideoUploaderRef.current;
		uploader.click();
	}
	
	deleteFooterVideo = () => {
		this.takeHistorySnapshot('Footer', History.action.DELETE_FOOTER_VIDEO, () => {
			this.setState({ isUpdated: true });
			this.updateMixerFooterState({ mediaId: null });
		});
	}

	handleFooterVideoInlineRename = () => {
		const { footer } = this.state;
		if(!footer.mediaId) {
			Notify.error('No video found!');
			return;
		}

		const $target = $(`#videoFooter_${ footer.footerId }`);
		const title = footer.name;

		const inlineRenameHelper = InlineRenameHelper($target, title, {
			width: 200,
		});

		inlineRenameHelper.init();
		inlineRenameHelper.open();
		
		inlineRenameHelper.onFormSubmit((form, value) => {
			this.takeHistorySnapshot(title, History.action.RENAME_FOOTER_VIDEO);
			
			this.updateMixerFooterState({ name: value }, () => {
				const $txtTitle = $('.txtTitle', $(form));
				$txtTitle.trigger('blur');
				this.setState({ isUpdated: true });
			});
		});
	}

	// Mixer Upload Videos

	mixerUploadVideo = (file, type) => {
		if(!file || !type) return;

		const supportedTypes = ['video/mp4'];
		if(indexOf(supportedTypes, file.type) < 0) {
			Notify.error('Please upload a valid video.');
			return;
		}

		switch(type) {
			case 'header': this.takeHistorySnapshot(file.name, History.action.UPLOAD_HEADER_VIDEO); break;
			case 'footer': this.takeHistorySnapshot(file.name, History.action.UPLOAD_FOOTER_VIDEO); break;
			default: break;
		}

		const formData = new FormData();
		formData.append('file', file);
		formData.set('type', file.type);

		const isValid = this.checkUpload(file);
		if(!isValid) {
			this.setState({ uploading: 0 });
			return;
		}

		this.setState({ uploading: 1 });
		
		this.props.addAttachment(formData, (attachmentStatus, attachmentRes) => {
			const videoId = `video_${ uuid() }`;
			const video = document.createElement('video');
			video.src = attachmentRes.url;
			video.id = videoId;

			video.onloadedmetadata = () => {
				const playTime = Math.ceil(video.duration * 1000);
				const data = {
					playTime,
	
					src: attachmentRes.url,
					name: file.name,
					type: file.type,
					size: file.size
				}
	
				this.props.addMedia(data, (mediaStatus, mediaRes) => {
					this.updateMediaListState({ [mediaRes.mediaId]: mediaRes }, () => {
						const videoData = {
							name: file.name,
							originalName: file.name,
							mediaId: mediaRes.mediaId
						}

						switch(type) {
							case 'header': this.updateMixerHeaderState(videoData); break;
							case 'footer': this.updateMixerFooterState(videoData); break;
							default: break;
						}
					});
	
					Notify.success(`${ file.name } uploaded successfully!`);
					this.setState({ uploading: 0, isUpdated: true });
				});
			}
		}, () => {
			this.setState({ uploading: 0 });
			Notify.error('Please try again.');
		});
	}

	// Handle BG Music Mixer

	handleMusicMixer = () => {
		const { mixer } = this.state;
		if(mixer.active) return false;
		this.openTourMixer();

		return true;
	}

	// Handle Leg List

	handleLegList = () => {
		const sqListRef = this.sqListRef.current;
		const { panel } = this.state;
		const slide = panel.minimize ? 'slideDown' : 'slideUp';

		$(sqListRef).show().velocity(slide, {
			duration: 200,
			complete: () => {
				this.updatePanelState({
					minimize: !panel.minimize
				}, () => {
					this.updateScrollbar();
				});
			}
		});
	}
	
	handleImageList = () => {
		const imageListRef = this.imageListRef.current;
		const { imagePanel } = this.state;
		const slide = imagePanel.minimize ? 'slideDown' : 'slideUp';

		$(imageListRef).show().velocity(slide, {
			duration: 200,
			complete: () => {
				this.updateImagePanelState({
					minimize: !imagePanel.minimize
				}, () => {
					this.forceUpdate(() => {
						this.updateScrollbar();
						this.initMixerInteract();
					});
				});
			}
		});
	}

	handleMusicList = () => {
		const musicListRef = this.musicListRef.current;
		const { musicPanel } = this.state;
		const slide = musicPanel.minimize ? 'slideDown' : 'slideUp';

		$(musicListRef).show().velocity(slide, {
			duration: 200,
			complete: () => {
				this.updateMusicPanelState({
					minimize: !musicPanel.minimize
				}, () => {
					this.forceUpdate(() => {
						this.updateScrollbar();
						this.initMixerInteract();
					});
				});
			}
		});
	}

	// Music

	uploadMusics = files => {
		if(!files.length) return;

		const file = files.shift();
		
		const isValid = this.checkUpload(file);
		if(!isValid) {
			this.setState({ uploading: 0 });
			return;
		}

		const { uploader } = this.state;
		switch(true) {
			case uploader.after: this.takeHistorySnapshot(file.name, History.action.UPLOAD_MUSIC_AFTER); break;
			case uploader.before: this.takeHistorySnapshot(file.name, History.action.UPLOAD_MUSIC_BEFORE); break;
			case uploader.onEnd: this.takeHistorySnapshot(file.name, History.action.UPLOAD_MUSIC_ONEND); break;
			case uploader.onBeginning: this.takeHistorySnapshot(file.name, History.action.UPLOAD_MUSIC_ONBEGINNING); break;
			default: this.takeHistorySnapshot(file.name, History.action.UPLOAD_MUSIC); break;
		}

		this.uploadMusic(file, 0, () => {
			this.uploadMusics(files);
		});
	}

	uploadMusic = (file, startTime = 0, success) => {
		let audio = new Audio();
		let reader = new FileReader();

		const formData = new FormData();

		formData.append('file', file);
		formData.set('type', file.type);

		this.props.addAttachment(formData, (attachmentStatus, attachmentRes) => {
			const { musicList, uploader } = this.state;
			const { duration } = audio;
			
			startTime = uploader.startTime || startTime;

			const durationMs = Math.floor(duration * 1000);
			const total = uploader.playTime;

			if((durationMs <= total) && uploader.onEnd) {
				startTime = total - durationMs;
			}

			startTime = Math.ceil(startTime);

			const totalPlayTime = total - startTime;
			let playTime = durationMs > totalPlayTime ? totalPlayTime : durationMs;
			playTime = Math.ceil(playTime);

			const data = {
				playTime,

				src: attachmentRes.url,
				name: file.name,
				type: file.type,
				size: file.size
			}

			this.props.addMedia(data, (status, result) => {
				const { mediaId } = result;
				
				const musicId = uuid();
				const endTime = startTime + playTime;
				const zIndex = size(musicList) ? max(map(musicList, 'zIndex')) + 1 : 1;

				const music = {
					zIndex,
					mediaId,
					musicId,
					endTime,
					playTime,
					startTime,

					name: file.name,
					originalName: file.name,

					volume: 50,
					originalPlaytime: durationMs,
					background: tinycolor.random().toHexString(),
				}

				const list = {
					[musicId]: music,
					...musicList
				}

				this.updateMediaListState({ [result.mediaId]: result }, () => {
					const { uploading } = this.state;
					let counter = uploading;
					
					this.setState({ musicList: list, uploading: counter -= 1, isUpdated: true }, () => {
						const el = this.getMusicEl(musicId);
						this.initMusicInteract(el);
						this.updateUploaderPlayTime();
						
						Notify.success(`${ file.name } uploaded successfully!`);

						if(!this.state.uploading) this.updateUploaderState(this.initialUploaderState, this.updateUploaderPlayTime);
					});
				});

				file = null;
				audio = null;
				reader = null;

				if(success) success();
			}, (status, errors) => {
				map(errors, error => Notify.error(error.reason));
				this.updateUploaderState(this.initialUploaderState, () => {
					this.setState({ uploading: 0 });
					this.updateUploaderPlayTime();
				});
			});
		});

		reader.onloadend = () => {
			audio.src = reader.result;
		}

		if(file) {
			reader.readAsDataURL(file);
		}
	}

	// Images

	uploadImages = files => {
		if(!files.length) return;

		const file = files.shift();

		const isValid = this.checkUpload(file);
		if(!isValid) {
			this.setState({ uploading: 0 });
			return;
		}
		
		const { uploader } = this.state;
		switch(true) {
			case uploader.after: this.takeHistorySnapshot(file.name, History.action.UPLOAD_IMAGE_AFTER); break;
			case uploader.before: this.takeHistorySnapshot(file.name, History.action.UPLOAD_IMAGE_BEFORE); break;
			case uploader.onEnd: this.takeHistorySnapshot(file.name, History.action.UPLOAD_IMAGE_ONEND); break;
			case uploader.onBeginning: this.takeHistorySnapshot(file.name, History.action.UPLOAD_IMAGE_ONBEGINNING); break;
			default: this.takeHistorySnapshot(file.name, History.action.UPLOAD_IMAGE); break;
		}

		this.uploadImage(file, 0, () => {
			this.uploadImages(files);
		});
	}

	uploadImage = (file, startTime = 0, success) => {
		if(!file) return;

		const formData = new FormData();

		formData.append('file', file);
		formData.set('type', file.type);

		this.props.addAttachment(formData, (attachmentStatus, attachmentRes) => {
			const imageTag = new Image();

			imageTag.addEventListener('load', () => {
				const { uploader } = this.state;

				startTime = uploader.startTime || startTime;

				const durationMs = 5000;
				const total = uploader.playTime;

				if((durationMs <= total) && uploader.onEnd) {
					startTime = total - durationMs;
				}

				startTime = Math.ceil(startTime);

				const totalPlayTime = total - startTime;
				let playTime = durationMs > totalPlayTime ? totalPlayTime : durationMs;
				playTime = Math.ceil(playTime);

				const data = {
					playTime,

					src: attachmentRes.url,
					name: file.name,
					type: file.type,
					size: file.size
				}

				this.props.addMedia(data, (mediaStatus, mediaRes) => {
					const aCenter = this.alignmentDOM.center;

					const { imageList } = this.state;

					const imageId = uuid();
					const coords = this.getImageCoords(aCenter.x, aCenter.y);

					const image = {
						imageId,
						coords,

						mediaId: mediaRes.mediaId,
						position: 'top-left',
						positionX: 50,
						positionY: 50,

						wRatio: 0.2,
						width: imageTag.width,
						height: imageTag.height,

						offset: 0,
						startTime: startTime || 0,
						playTime: mediaRes.playTime,
						// originalPlaytime: mediaRes.playTime,
						zIndex: size(imageList) ? max(map(imageList, 'zIndex')) + 1 : 1,
						
						legId: null,
						locked: false,
						type: file.type,

						theme: tinycolor.random().toHexString(),

						name: file.name,
						originalName: file.name,

						transition: {
							entrance: 'fadeIn',
							exit: 'fadeOut'
						}
					}

					const list = {
						[imageId]: image,
						...imageList,
					}

					this.updateMediaListState({ [mediaRes.mediaId]: mediaRes }, () => {
						const { uploading } = this.state;
						let counter = uploading;

						this.setState({ imageList: list, uploading: counter -= 1, isUpdated: true }, () => {
							const el = this.getImageEl(imageId);
							this.initImageInteract(el);
							this.updateUploaderPlayTime();
							
							Notify.success(`${ file.name } uploaded successfully!`);

							if(success) success();
							if(!this.state.uploading) this.updateUploaderState(this.initialUploaderState, this.updateUploaderPlayTime);
						});
					});
				});
			});

			imageTag.src = attachmentRes.url;
		}, () => {
			this.setState({ uploading: 0 });
			Notify.error('Please try again :(');
			this.updateUploaderPlayTime();
			this.updateUploaderState(this.initialUploaderState, this.updateUploaderPlayTime);
		});
	}

	// Geo Thumbnail

	handleGeoThumbnails = (startPoint, endPoint, el) => {
		const mixer = this.editorMixerRef.current;
		const $mixer = $(mixer);
		const $el = $(el);

		const startTime = this.getMilliseconds(startPoint);
		const endTime = this.getMilliseconds(endPoint);

		const legs = this.getInRangeLegs(startTime, endTime);
		if(isEmpty(legs.startLeg) || isEmpty(legs.endLeg)) return;

		const { startLeg, endLeg } = legs;

		const $startLegEl = $(`.legSlab_${ startLeg.nodeId }`, $(mixer));
		const $endLegEl = $(`.legSlab_${ endLeg.nodeId }`, $(mixer));

		if(!$startLegEl.length || !$endLegEl.length) return;

		const records = this.getInRangeLegRecords(startTime, endTime, startLeg, endLeg);
		if(isEmpty(records.startRecord) || isEmpty(records.endRecord)) return;

		const { startRecord, endRecord } = records;

		const startRecordUrl = Utils.getGeoThumbnailUrl(startRecord.position, startRecord.pov);
		const endRecordUrl = Utils.getGeoThumbnailUrl(endRecord.position, endRecord.pov);

		const $geoThumbnailStart = $('.geoThumbnailStart', $mixer);
		const $geoThumbnailEnd = $('.geoThumbnailEnd', $mixer);

		const thumbnailHeight = $geoThumbnailStart.innerHeight() + 4;
		const thumbnailWidth = $geoThumbnailStart.innerWidth();

		const left = $el.offset().left;
		const elWidth = $el.innerWidth();
		
		const startLegTop = $startLegEl.offset().top - thumbnailHeight;
		const startLegLeft = left - thumbnailWidth;

		const endLegTop = $endLegEl.offset().top - thumbnailHeight;
		const endLegLeft = left + elWidth;

		$geoThumbnailStart.html('Loading...');
		$geoThumbnailStart.css({
			top: startLegTop,
			left: startLegLeft,
		}).show();
		
		$geoThumbnailEnd.html('Loading...');
		$geoThumbnailEnd.css({
			top: endLegTop,
			left: endLegLeft,
		}).show();

		axios.get(startRecordUrl, {
			responseType: 'blob'
		}).then(response => {
			const image = new Image();
			
			image.style.display = 'block';
			image.src = URL.createObjectURL(response.data);

			$geoThumbnailStart.html(image);
		}).catch(() => {
			$geoThumbnailStart.html('Error!');
		});
		
		axios.get(endRecordUrl, {
			responseType: 'blob'
		}).then(response => {
			const image = new Image();
			
			image.style.display = 'block';
			image.src = URL.createObjectURL(response.data);

			$geoThumbnailEnd.html(image);
		}).catch(() => {
			$geoThumbnailEnd.html('Error!');
		});
	}

	destroyGeoThumbnails = () => {
		const mixer = this.editorMixerRef.current;
		const $mixer = $(mixer);
		
		const $geoThumbnailStart = $('.geoThumbnailStart', $mixer);
		const $geoThumbnailEnd = $('.geoThumbnailEnd', $mixer);

		if($geoThumbnailStart.is(':visible')) $geoThumbnailStart.hide().html('Loading...');
		if($geoThumbnailEnd.is(':visible')) $geoThumbnailEnd.hide().html('Loading...');
	}

	// URL

	checkIsExpandedViaUrl = () => {
		const url = new URL(window.location);
		const expanded = url.searchParams.get('expanded');
		const isExpanded = expanded === 'true' || false;
		return isExpanded;
	}

	handlePanelViaUrl = () => {
		const isExpanded = this.checkIsExpandedViaUrl();
		if(isExpanded) this.openTourMixer();
	}

	unsetUrl = () => {
		window.history.pushState({}, document.title, '/mytours');
	}

	// Updaters

	updatePlayerState = (updates, callback) => {
		const { player } = this.state;

		this.setState({
			player: { ...player, ...updates }
		}, callback);
	}

	updateUploaderPlayTime = (playTime, callback) => {
		const { legs } = this.state;
		playTime = playTime || sum(map(legs, 'playTime'));

		this.updateUploaderState({ playTime }, callback);
	}
	
	updateMixerTotalPlayTime = () => {
		const { legs } = this.state;
		const totalPlayTime = sum(map(legs, 'playTime'));

		this.updateMixerState({ totalPlayTime });
	}

	updateLegList = (dLegs, dGroups, callback) => {
		const { legs } = this.state;

		map(dGroups, item => {
			const { groups } = this.state;
			const groupId = item.groupId;
			const group = find(groups, { groupId });

			if(group) {
				const legIds = item.legIds;
				group.legIds = legIds;
				group.position = item.position;

				this.updateGroupsState({ [group.groupId]: group });
			}
		});

		const updatedLegs = map(dLegs, (item, i) => {
			const leg = find(legs, ['nodeId', item.nodeId]);
			if(leg && item.groupId) {
				leg.group = item.group;
			} else {
				leg.group = '';
			}

			leg.position = item.position || i;

			return leg;
		});

		this.setState({ legs: updatedLegs, isUpdated: true }, () => {
			this.handleLockedUploads();
			if(callback) callback();
		});
	}

	updateHistorySnapshotState = (updates, callback) => {
		const { historySnapshot } = this.state;

		this.setState({
			historySnapshot: {
				...historySnapshot,
				...updates
			}
		}, callback);
	}
	
	// Preview Media

	previewMedia = (data, type) => {
		if(isEmpty(data) || isEmpty(type)) {
			UIKit.modal.alert('Invalid/insufficient preview data.');
			return;
		}

		const { mediaList } = this.state;

		const previewData =  {
			...data,
			media: mediaList
		}

		this.props.previewMedia(previewData, type);
	}

	disableMediaPanel = () => {
		this.props.disableMediaPanel();
	}

	// History

	showHistoryPanel = callback => {
		LAST_STATE = this.getStateSnapshotData();

		this.updateHistoryPanelState({ active: true }, () => {
			const $panel = $('[data-history-panel]');

			$panel.velocity('fadeIn', {
				duration: Velocity.DURATION,
				easing: Velocity.EASING,

				complete: callback
			});
		});
	}
	
	hideHistoryPanel = callback => {
		const $panel = $('[data-history-panel]');

		$panel.velocity('fadeOut', {
			duration: Velocity.DURATION,
			easing: Velocity.EASING,

			complete: () => {
				this.updateHistoryPanelState({ active: false }, () => {
					LAST_STATE = null;
					if(callback) callback();
				});
			}
		});
	}

	takeHistorySnapshot = (title, action, callback, cData = null) => {
		const { history } = this.props;
		const selectedItems = filter(history, { selected: true });

		const takeSnapshot = () => {
			const { historySnapshot } = this.state;
			if(!historySnapshot.active || !title || Array.isArray(title) || !action) return;

			let data = cData || this.getStateSnapshotData();

			if(!isEmpty(LAST_STATE) && ((action === History.action.CREATE_HISTORY))) {
				data = LAST_STATE;
			}

			if(historySnapshot.bulk && historySnapshot.snapshotId) {
				const historyItem = find(history, { historyId: historySnapshot.snapshotId });

				if(isEmpty(historyItem)) {
					this.addHistory(data, [title], action, historySnapshot.snapshotId, callback);
				} else {
					const titles = historyItem.subject;
					titles.push(title);
					const updates = {
						subject: titles
					}
					
					this.updateHistory(historySnapshot.snapshotId, updates, callback);
				}
			} else {
				this.addHistory(data, [title], action, null, callback);
			}
		}

		if(selectedItems.length) {
			const selectedHistory = selectedItems[0];
			const indexes = this.getFromToHistoryIndex(selectedHistory.historyId);

			this.removeHistory(indexes.fromIndex, indexes.toIndex, () => {
				this.selectHistory(null, takeSnapshot);
				setTimeout(() => {
					LAST_STATE = this.getStateSnapshotData();
				}, 200);
			});
		} else {
			takeSnapshot();
		}
	}

	captureStartHistory = () => {
		const { history } = this.props;
		const historyStart = find(history, { action: History.action.START });

		if(!historyStart) {
			LAST_STATE = this.getStateSnapshotData();
			this.takeHistorySnapshot('History', History.action.START);
		}
	}

	previewHistory = (data, callback) => {
		const stateData = data || LAST_STATE;
		if(isEmpty(stateData)) return;

		const { groups, imageList, legs, musicList, header, footer } = stateData;
		
		this.setState({
			legs: [],
		}, () => {
			this.setState({
				legs,
				groups,
				
				imageList,
				musicList,
				
				header,
				footer,
			}, () => {
				const { mixer } = this.state;
				
				this.updateMixerTotalPlayTime();

				if(mixer.active) {
					this.initMixer(callback);
				} else {
					this.forceUpdate(callback);
				}
			});
		});
	}

	addHistory = (data, title, action, historyId, callback) => {
		this.props.addHistory(data, [title], action, historyId, callback);
	}

	applyHistory = (historyId, callback) => {
		this.selectHistory(historyId, () => {
			if(callback) callback();
		});
	}
	
	relieveHistory = callback => {
		this.selectHistory(null, () => {
			if(callback) callback();
		});
	}

	selectHistory = (historyId, callback) => {
		this.props.selectHistory(historyId, callback);
	}

	updateHistory = (historyId, updates, callback) => {
		this.props.updateHistory(historyId, updates, callback);
	}

	enableHistory = callback => {
		this.props.enableHistory(callback);
	}
	
	disableHistory = (fromIndex, toIndex, callback) => {
		this.props.disableHistory(fromIndex, toIndex, callback);
	}
	
	removeHistory = (fromIndex, toIndex, callback) => {
		this.props.removeHistory(fromIndex, toIndex, callback);
	}

	resetHistory = () => {
		this.props.resetHistory(() => setTimeout(this.captureStartHistory, 50));
	}

	undoHistory = () => {
		LAST_STATE = this.getStateSnapshotData();
		
		const { history } = this.props;
		
		const sIndex = findIndex(history, { selected: true });
		const index = sIndex >= 0 ? (sIndex - 1) : (history.length - 1);

		const historyItem = history[index];
		if(!historyItem) return;

		this.previewHistory(historyItem.data, () => {
			this.selectHistory(historyItem.historyId);
		});
	}
	
	redoHistory = () => {
		const { history } = this.props;

		const index = findIndex(history, { selected: true });
		if(index < 0) return;

		const historyItem = history[index + 1];
		const data = historyItem ? historyItem.data : LAST_STATE;

		this.previewHistory(data, () => {
			this.selectHistory(historyItem ? historyItem.historyId : null);
		});
	}

	// Misc

	removeItem = nodeId => {
		const { legs } = this.state;
		const node = find(legs, ['nodeId', nodeId]);

		if(node) {
			this.setState({ isUpdated: true });
			remove(legs, node);

			this.setState({ legs }, () => {
				const { imageList, musicList } = this.state;
				const legImages = filter(imageList, image => image.legId === node.objectId);
				const legMusics = filter(musicList, music => music.legId === node.objectId);

				map(legImages, image => {
					this.unlockImage(image.imageId);
				});
				
				map(legMusics, music => {
					this.unlockMusic(music.musicId);
				});

				const tree = this.sqTreeRef.current;
				if(tree) tree.deleteNode(nodeId);

				this.updateMixerTotalPlayTime();
			});
		}
	}

	// State Updaters

	updateMixerState = (updates, callback) => {
		const { mixer } = this.state;

		this.setState({
			mixer: {
				...mixer,
				...updates
			}
		}, callback);
	}

	updateMusicListState = (updates, callback) => {
		const { musicList } = this.state;

		this.setState({
			musicList: {
				...musicList,
				...updates,
			}
		}, callback);
	}
	
	updateImageListState = (updates, callback) => {
		const { imageList } = this.state;

		this.setState({
			imageList: {
				...imageList,
				...updates,
			}
		}, callback);
	}
	
	updateImageOverlaySettingsState = (updates, callback) => {
		const { imageOverlaySettings } = this.state;

		this.setState({
			imageOverlaySettings: {
				...imageOverlaySettings,
				...updates,
			}
		}, callback);
	}

	updateImageSettings = () => {
		const { imageList, imageOverlaySettings } = this.state;
		const image = get(imageList, imageOverlaySettings.image.imageId);
		
		this.takeHistorySnapshot(image.name, History.action.UPDATE_IMAGE_SETTINGS);

		image.position = imageOverlaySettings.image.position;
		image.positionX = imageOverlaySettings.image.positionX;
		image.positionY = imageOverlaySettings.image.positionY;
		
		image.wRatio = imageOverlaySettings.image.wRatio;
		image.coords = imageOverlaySettings.image.coords || {};
		image.transition = imageOverlaySettings.image.transition;
		
		this.updateImageListState({ [image.imageId]: image }, () => {
			const modal = this.imageSettingsModalRef.current;
			modal.hide();

			Notify.success('Image settings updated.');
			this.setState({ isUpdated: true });
		});
	}
	
	updateUploaderState = (updates, callback) => {
		const { uploader } = this.state;

		this.setState({
			uploader: {
				...uploader,
				...updates,
			}
		}, callback);
	}
	
	updateMediaListState = (updates, callback) => {
		const { mediaList } = this.state;

		this.setState({
			mediaList: {
				...mediaList,
				...updates,
			}
		}, callback);
	}
	
	updatePanelState = (updates, callback) => {
		const { panel } = this.state;

		this.setState({
			panel: {
				...panel,
				...updates
			}
		}, callback);
	}

	updateGroupsState = (updates, callback) => {
		const { groups } = this.state;

		this.setState({
			groups: {
				...groups,
				...updates
			}
		}, callback);
	}

	updateGroupFormState = (updates, callback) => {
		const { groupForm } = this.state;

		this.setState({
			groupForm: {
				...groupForm,
				...updates
			}
		}, callback);
	}
	
	updateImagePanelState = (updates, callback) => {
		const { imagePanel } = this.state;

		this.setState({
			imagePanel: {
				...imagePanel,
				...updates
			}
		}, callback);
	}

	updateMusicPanelState = (updates, callback) => {
		const { musicPanel } = this.state;

		this.setState({
			musicPanel: {
				...musicPanel,
				...updates
			}
		}, callback);
	}
	
	updateMusicPlayerState = (updates, callback) => {
		const { musicPlayer } = this.state;

		this.setState({
			musicPlayer: {
				...musicPlayer,
				...updates
			}
		}, () => {
			this.props.updateMusicPlayerState(updates, callback);
		});
	}

	updateMixerHeaderState = (updates, callback) => {
		const { header } = this.state;

		this.setState({
			header: {
				...header,
				...updates
			}
		}, callback);
	}
	
	updateMixerFooterState = (updates, callback) => {
		const { footer } = this.state;

		this.setState({
			footer: {
				...footer,
				...updates
			}
		}, callback);
	}

	updateBgMusicState = (legId, src) => {
		const { legs } = this.state;

		const clonedList = [...legs];
		const legIndex = findIndex(clonedList, ['objectId', legId]);

		if(legIndex >= 0) {
			const leg = clonedList[legIndex];
			leg.bgMusic = !isEmpty(src);

			this.setState({ legs: clonedList });
		}
	}

	updateHistoryPanelState = (updates, callback) => {
		const { historyPanel } = this.state;

		this.setState({
			historyPanel: {
				...historyPanel,
				...updates
			}
		}, callback);
	}

	updateImageCoordsState = (updates, callback) => {
		const { imageCoords } = this.state;

		this.setState({
			imageCoords: {
				...imageCoords,
				...updates
			}
		}, callback);
	}

	updateScrollbar = () => {
		const containers = [];
		containers.push(this.cntEditorRef.current);
		containers.push(this.cntEditorMixerRef.current);

		if(!containers.length) return;
		
		map(containers, cnt => {
			cnt.update();
			cnt.getApi().update();
		});
	}

	watchNodes = () => {
		$(document).off('tree.open_node').on('tree.open_node', (e, nodes, parent, tree) => {
			if(nodes.length) {
				map(nodes, node => {
					if(node && this.props.active && node.li_attr.type === 60) {
						const $node = $(tree).find(`#${ node.id }`);
						this.initNodeDraggable($node);
					}
				});
			}
		});
	}

	stopWatchNodes = () => {
		$(document).off('tree.open_node');
	}

	renderPlayer = () => {
		const { imageOverlaySettings, player } = this.state;

		if(!imageOverlaySettings.active || !player.render) return '';

		return (
			<ToursPlayer
				layout="mini"
				active={ player.active }
				ref={ this.imageTourPreviewPlayerRef }
				updatePlayerState={ this.updatePlayerState }
			/>
		);
	}

	renderGroupLegs = legIds => {
		const { legs } = this.state;
		const groupLegs = filter(legs, leg => legIds.indexOf(leg.objectId) >= 0);
		return map(groupLegs, leg => this.getLegTemplate(leg));
	}

	renderGroup = group => {
		if(!group.legIds.length) return '';
		const options = {
			group: 'nested'
		}
		return (
			<ul tag="ul" options={ options } className="dd-list sq-list nested-sortable sortableList" data-group={ group.name }>
				{ this.renderGroupLegs(group.legIds) }
			</ul>
		);
	}

	renderListItems = () => {
		const { legs } = this.state;

		if(!legs.length) return <li className="d-none"/>;

		const list = this.generateList();
		
		return map(list, (item, i) => {
			if(item.nodeType === 'group') {
				return (
					<li className="dd-item dd-nochildren sq-item sq-group-item" key={ item.groupId } data-group-id={ item.groupId } data-node-type={ item.nodeType } data-ctxtoggle-parent>
						{ this.getGroupTemplate(item, i) }
						{ this.renderGroup(item) }
					</li>
				);
			}

			if(!item.group) return this.getLegTemplate(item, i);

			return '';
		});
	}

	renderList = () => {
		const { panel, groups } = this.state;

		const style = {
			display: panel.minimize ? 'none' : ''
		}

		const options = {
			group: 'nested'
		}

		return (
			<div className="editor-list panel-list p-h-0" data-groups={ groups.length } style={ style } ref={ this.sqListRef }>
				<div className="editor-list-inner list-inner">
					<div className="sequence-list dd sequenceList">
						<ul tag="ul" options={ options } className="dd-list sq-list nested-sortable sortableList base-list" data-group="">
							{ this.renderListItems() }
						</ul>
					</div>
				</div>
			</div>
		);
	}

	renderLegsTree = () => {
		const { legs } = this.state;
		const { active } = this.props;

		if(!active || !legs.length) return '';

		const list = this.generateList();

		return (
			<div className="editor-list-container" ref={ this.sqListRef }>
				<SequencePanelTree
					list={ list }
					legs={ legs }
					ref={ this.sqTreeRef }
					playLeg={ this.playLeg }
					updateLegList={ this.updateLegList }
					onLegCtxClick={ this.onLegCtxClick }
					onGroupCtxClick={ this.onGroupCtxClick }
					takeHistorySnapshot={ this.takeHistorySnapshot }
					updateHistorySnapshotState={ this.updateHistorySnapshotState }/>
			</div>
		);
	}

	renderLegTimelineSlabs = (isListItem = true) => {
		const { legs } = this.state;
		const { active } = this.props;
		const editorMixer = this.editorMixerRef.current;

		if(!legs.length || !active || !editorMixer) return '';

		const list = this.generateList();

		return map(list, (item, i) => {
			const width = this.getPixels(item.playTime);

			const style = {};
			style.width = width;

			const legItem = () => (
				<span key={ item.nodeId } className={ `timeline-slab legSlab_${ item.nodeId }` } data-uk-tooltip title={ item.text } style={ style }>
					<Link to="#ctx" className="upload-ctx uploadCtx" onClick={ e => this.onLegCtxClick(e, item) }>
						<i className="material-icons ctx-icon">more_vert</i>
					</Link>
				</span>
			);

			if(!isListItem && item.nodeType !== 'group') return legItem();

			style.left = this.getPixels(item.startTime);
			
			if(isListItem && item.nodeType === 'group') {
				style.display = !item.legIds.length ? 'none' : '';
				
				return (
					<div className="leg-timeline" data-title={ item.name } key={ item.groupId }>
						<span className="item-slab">
							<span className="timeline-slab timeline-outline" data-uk-tooltip title={ item.name } style={ style }>
								<Link to="#ctx" className="upload-ctx uploadCtx" onClick={ e => this.onGroupCtxClick(e, item) }>
									<i className="material-icons ctx-icon">more_vert</i>
								</Link>
							</span>
						</span>
					</div>
				);
			}

			if(isListItem && item.nodeType !== 'group') {
				return (
					<div className="leg-timeline" key={ item.nodeId }>
						<span className="item-slab">
							{ legItem() }
						</span>
					</div>
				);
			}

			return '';
		});
	}

	renderLegTimelineInline = () => {
		const { panel, mixer } = this.state;

		if(!mixer.active || !panel.minimize) return '';

		return (
			<Fragment>
				{ this.renderLegTimelineSlabs(false) }
			</Fragment>
		);
	}
	
	renderLegTimelineList = () => {
		const { panel, mixer } = this.state;

		if(!mixer.active || panel.minimize) return '';

		return (
			<div className="timeline-list-inner">
				{ this.renderLegTimelineSlabs() }
			</div>
		);
	}

	renderImageListItems = () => {
		const { imageList, mediaList } = this.state;

		if(isEmpty(imageList)) return <li className="d-none"/>;

		return map(imageList, item => {
			const media = get(mediaList, item.mediaId);

			return (
				<li className="dd-item dd-nochildren sq-item sq-image-item sqMediaItem sqImageMediaItem" data-id={ item.imageId } key={ item.imageId } data-ctxtoggle-parent>
					<span className="item-inner">
						{/* <span className="item-handle sq-handle dd-handle sqHandle"><i className="material-icons">&#xE5D2;</i></span> */}
						{/* <span className="item-icon"><i className="material-icons">photo</i></span> */}
						<span className="item-icon">
							<img src={ media.src } className="img-responsive" alt={ item.name }/>
						</span>
						<span className="item-title itemTitle">{ item.name }</span>
						<span className="item-actions">
							<span
								className="atn atn-context atnContext"
								data-uk-tooltip="{pos: top}"
								title="More Options"
								onClick={ e => this.onImageItemCtxClick(e, item) }
								role="button"
								tabIndex="-1">
								<i className="material-icons">&#xE5D4;</i>
							</span>
						</span>
					</span>
				</li>
			);
		});
	}

	renderImageList = () => {
		const { mixer, imagePanel } = this.state;

		if(!mixer.active) return '';

		const style = {
			display: imagePanel.minimize ? 'none' : ''
		}

		return (
			<div className="editor-list panel-list p-h-0" style={ style } ref={ this.imageListRef }>
				<div className="editor-list-inner list-inner">
					<div className="sequence-list dd sequenceList">
						<ul className="dd-list sq-list" data-sortable-list="images">
							{ this.renderImageListItems() }
						</ul>
					</div>
				</div>
			</div>
		);
	}
	
	renderMusicListItems = () => {
		const { musicList } = this.state;

		if(isEmpty(musicList)) return <li className="d-none"/>;

		return map(musicList, item => (
			<li className="dd-item dd-nochildren sq-item sq-music-item sqMediaItem sqMusicMediaItem" data-id={ item.musicId } key={ item.musicId } data-ctxtoggle-parent>
				<span className="item-inner">
					{/* <span className="item-handle sq-handle dd-handle sqHandle"><i className="material-icons">&#xE5D2;</i></span> */}
					<span className="item-icon"><i className="material-icons">music_note</i></span>
					<span className="item-title itemTitle">{ item.name }</span>
					<span className="item-actions">
						<span
							className="atn atn-context atnContext"
							data-uk-tooltip="{pos: top}"
							title="More Options"
							onClick={ e => this.onMusicItemCtxClick(e, item) }
							role="button"
							tabIndex="-1">
							<i className="material-icons">&#xE5D4;</i>
						</span>
					</span>
				</span>
			</li>
		));
	}

	renderMusicList = () => {
		const { mixer, musicPanel } = this.state;

		if(!mixer.active) return '';

		const style = {
			display: musicPanel.minimize ? 'none' : ''
		}

		return (
			<div className="editor-list panel-list p-h-0" style={ style } ref={ this.musicListRef }>
				<div className="editor-list-inner list-inner">
					<div className="sequence-list dd sequenceList">
						<ul className="dd-list sq-list" data-sortable-list="musics">
							{ this.renderMusicListItems() }
						</ul>
					</div>
				</div>
			</div>
		);
	}

	renderMixerMusicUploadLoops = (loopCount, width) => {
		if(!loopCount) return <span className="d-none"/>;
		
		const loops = [];
		for (let i = 0; i < loopCount; i += 1) {
			const styles = { width };
			loops.push(<span key={ uuid() } style={ styles }/>);
		}

		return loops;
	}

	renderImageTimelineSlabs = (inline = true) => {
		const { mixer, imageList } = this.state;
		const { active } = this.props;
		const mixerImageUploads = this.cntMixerImageRef.current;

		if(!active || !mixer.active || !mixerImageUploads) return '';

		const list = orderBy(imageList, 'zIndex', 'desc');

		return map(list, image => {
			let left = 0;

			if(image.startTime) {
				left = this.getPixels(image.startTime);
			}

			const id = `mediaImageUpload_${ image.imageId }`;
			const width = this.getPixels(image.playTime);
			const theme = image.theme || tinycolor.random().toHexString();
			const tcTheme = tinycolor(theme);
			const isDark = tcTheme.isDark();
			// const originalWidth = this.getPixels(image.originalPlaytime);

			const style = {
				left,
				width,
				
				zIndex: image.zIndex,
				backgroundColor: theme,
			}

			const iItem = () => (
				<div
					id={ id }
					data-uk-tooltip
					style={ style }
					title={ image.name }
					key={ image.imageId }
					data-id={ image.imageId }
					data-zindex={ style.zIndex }
					data-locked={ image.locked }
					data-initial-width={ width }
					data-playtime={ image.playTime }
					className={ `media-upload mediaImageUpload ${ image.locked ? 'media-locked' : '' } ${ isDark ? 'media-dark' : 'media-light' }` }>
					<span to="#handle" className="d-none upload-drag-handle uploadDragHandle"><i className="material-icons handle-icon">drag_handle</i></span>
					<Link to="#ctx" className="upload-ctx uploadCtx" onClick={ e => this.onImageItemCtxClick(e, image) }>
						<i className="material-icons ctx-icon">more_vert</i>
					</Link>
					{/* <span className="upload-resize-handle ui-resizable-handle uploadResizeHandle"/> */}
				</div>
			);

			if(inline) return iItem();

			return (
				<div className="timeline-slab" key={ image.imageId }>
					<span className="item-slab">
						{ iItem() }
					</span>
				</div>
			);
		});
	}

	renderImageTimelineInline = () => {
		const { imagePanel, mixer } = this.state;
		if(!mixer.active || !imagePanel.minimize) return '';

		return (
			<Fragment>
				{ this.renderImageTimelineSlabs() }
			</Fragment>
		);
	}

	renderImageTimelineList = () => {
		const { imagePanel, mixer } = this.state;
		if(!mixer.active || imagePanel.minimize) return '';

		return (
			<div className="media-timeline-list">
				<div className="timeline-list-inner">
					{ this.renderImageTimelineSlabs(false) }
				</div>
			</div>
		);
	}

	renderMusicTimelineSlabs = (inline = true) => {
		const { mixer, musicList } = this.state;
		const { active } = this.props;
		const mixerMusicUploads = this.cntMixerMusicRef.current;

		if(!active || !mixer.active || !mixerMusicUploads) return '';

		const list = orderBy(musicList, 'zIndex', 'desc');

		return map(list, music => {
			let left = 0;

			if(music.startTime) {
				left = this.getPixels(music.startTime);
			}

			const id = `mediaMusicUpload_${ music.musicId }`;
			const width = this.getPixels(music.playTime);
			const background = music.background || tinycolor.random().toHexString();
			const tcTheme = tinycolor(background);
			const isDark = tcTheme.isDark();
			const originalWidth = this.getPixels(music.originalPlaytime);

			const style = {
				left,
				width,
				
				zIndex: music.zIndex,
				backgroundColor: background,
			}

			let loopCount = 0;

			if(music.playTime > music.originalPlaytime) {
				loopCount = Math.floor(music.playTime / music.originalPlaytime);
			}

			const mItem = () => (
				<div
					id={ id }
					style={ style }
					data-uk-tooltip
					title={ music.name }
					key={ music.musicId }
					data-id={ music.musicId }
					data-zindex={ style.zIndex }
					data-initial-width={ width }
					data-locked={ music.locked }
					data-playtime={ music.playTime }
					className={ `media-upload mediaMusicUpload ${ music.locked ? 'media-locked' : '' } ${ isDark ? 'media-dark' : 'media-light' }` }>
					<span className={ `upload-loops ${ !loopCount ? 'd-none' : '' }` }>
						{ this.renderMixerMusicUploadLoops(loopCount, originalWidth) }
					</span>
					<span to="#handle" className="d-none upload-drag-handle uploadDragHandle"><i className="material-icons handle-icon">drag_handle</i></span>
					<span className={ `upload-loop-count d-none ${ !loopCount ? 'd-none' : '' }` }>
						<i className="material-icons">loop</i>
						{ loopCount }
					</span>
					<Link to="#ctx" className="upload-ctx uploadCtx" onClick={ e => this.onMusicItemCtxClick(e, music) }>
						<i className="material-icons ctx-icon">more_vert</i>
					</Link>
					{ !music.locked ? <span className="upload-resize-handle ui-resizable-handle uploadResizeHandle"/> : '' }
				</div>
			);

			if(inline) return mItem();

			return (
				<div className="timeline-slab" key={ music.musicId }>
					<span className="item-slab">
						{ mItem() }
					</span>
				</div>
			);
		});
	}

	renderMusicTimelineInline = () => {
		const { musicPanel, mixer } = this.state;

		if(!mixer.active || !musicPanel.minimize) return '';

		return (
			<Fragment>
				{ this.renderMusicTimelineSlabs() }
			</Fragment>
		);
	}

	renderMusicTimelineList = () => {
		const { musicPanel, mixer } = this.state;

		if(!mixer.active || musicPanel.minimize) return '';

		return (
			<div className="media-timeline-list">
				<div className="timeline-list-inner">
					{ this.renderMusicTimelineSlabs(false) }
				</div>
			</div>
		);
	}

	renderMusicPlayer = () => {
		const { musicPlayer } = this.state;

		if(!musicPlayer.active) return '';

		return (
			<div className="mixer-media-player">
				<Link to="#stopmusic" className="btn-close" onClick={ this.onStopMusic }>
					<i className="material-icons">close</i>
				</Link>
				<div className="media-player" ref={ this.musicPlayerRef }/>
			</div>
		);
	}

	renderCustomAlignmentGrids = () => {
		const { imageOverlaySettings: { alignmentBoardActive } } = this.state;
		if(!alignmentBoardActive) return '';

		const grid = this.getAlignmentGrids();
		const grids = [];

		let totalX = 0;
		let totalY = 0;

		for(let i = 0; i < (grid.gridsX + 1); i += 1) {
			const styles = {
				'top': `calc(${ totalX }% - 15px)`
			}

			const item = <div className="custom-alignment-grid grid-x" style={ styles } key={ uuid() }/>;
			grids.push(item);
			totalX += grid.x;
		}
		
		for(let i = 0; i < (grid.gridsY + 1); i += 1) {
			const styles = {
				'left': `calc(${ totalY }% - 15px)`
			}

			const item = <div className="custom-alignment-grid grid-y" style={ styles } key={ uuid() }/>;
			grids.push(item);
			totalY += grid.y;
		}

		// return grids;
		return [];
	}

	renderImageOverlayPositionToggles = () => {
		const { imageOverlaySettings, imageCoords, player } = this.state;

		if(!imageOverlaySettings.active) return '';

		return map(imageCoords, item => {
			const styles = {
				position: 'absolute',
				top: `${ item.position.top }%`,
				left: `${ item.position.left }%`,
				zIndex: 5,
				transform: 'translate(-50%, -50%)',
				pointerEvents: player.active ? 'none' : 'all'
			}

			return (
				<div className="toggle-group toggleGroup" data-id={ item.coordId } key={ item.coordId } style={ styles }>
					<Link to="#" className="crosshair-toggle" onClick={ e => this.onCrosshairToggleClick(e, item) }>
						<span/>
					</Link>
				</div>
			);
		});
	}

	renderImageOverlayStandardAlignment = () => {
		const { imageOverlaySettings } = this.state;

		if(!imageOverlaySettings.active) return '';

		const { width, height } = this.alignmentDOM;

		const styles = {
			width: `${ width }px`,
			height: `${ height }px`,
			overflow: 'visible',
		}

		return (
			<div className="uk-custom-alignment" style={ styles }>
				{ this.renderImageOverlayPositionToggles() }
			</div>
		);
	}

	renderImageOverlayCustomAlignment = () => {
		const { imageOverlaySettings, imageOverlaySettings: { active, percentageCoordsPosition }, player } = this.state;
		const { width, height } = this.alignmentDOM;

		if(!active) return '';

		const styles = {
			width: `${ width }px`,
			height: `${ height }px`,
			cursor: 'grab',
			pointerEvents: 'all'
			// margin: 0
		}

		const groupStyles = {
			width: `${ width }px`,
			height: `${ height }px`,
		}

		const crosshairStyles = {
			opacity: player.active ? 0.4 : 1,
			pointerEvents: player.active ? 'none' : 'all'
		}

		const cntImageStyles = {
			position: 'absolute',
			height: '100%',
			width: '100%',
			zIndex: 11,
			overflow: 'hidden',
		}

		const imageItemStyles = {
			display: 'block',
			opacity: player.active ? 1 : 0.5,
		}

		const graphicPanelStyles = {
			position: 'absolute',
			top: 0,
			left: 0,
			width: '100%',
			height: '100%',
			zIndex: 5,
			opacity: player.active ? 1 : 0,
			boxShadow: 'none',
		}

		return (
			<Fragment>
				<div className="custom-alignment-container uk-position-relative m-b-10" ref={ this.cntAlignmentCrosshairRef }>
					<div className="uk-custom-alignment alignmentBoard uk-position-relative streetview-images-container" role="button" style={ styles } tabIndex="-1" data-image-canvas>
						<span className="alignment-crosshair alignmentCrosshair" style={ crosshairStyles } role="button" tabIndex="-1" onClick={ this.onAlignmentClick }>
							<span className="crosshair-icon"/>
						</span>
						<div className="md-card md-graphic-panel-card md-mini-player" style={ graphicPanelStyles } id="mdGraphicPanelCard">
							{ this.renderPlayer() }
						</div>
						<div className="ui-notouch" style={ cntImageStyles }>
							<ImageItem image={ imageOverlaySettings.image } media={ imageOverlaySettings.media } styles={ imageItemStyles } ref={ this.imageItemRef }/>
						</div>
					</div>
					{/* <div className="uk-custom-alignment-grids alignmentGrids">
						{ this.renderCustomAlignmentGrids() }
					</div> */}
					<div className="uk-custom-alignment-toggle-groups alignmentToggleGroups" style={ groupStyles }>
						{ this.renderImageOverlayPositionToggles() }
					</div>
					<div className="uk-custom-snapboard alignmentSnapBoard">
						<div className="alignment-snap-guide guide-x alignmentSnapGuide alignmentSnapGuideX"/>
						<div className="alignment-snap-guide guide-y alignmentSnapGuide alignmentSnapGuideY"/>
					</div>
					<div className="uk-custom-alignment-ref alignmentBoardRef"/>
				</div>
				<ul className="uk-list uk-grid uk-grid-small m-t-30">
					{/* <li className="uk-text-small text-muted uk-width-1-3">
						<Input
							min={ 1 }
							step={ 1 }
							max={ 10 }
							type="number"
							name="alignmentBoardGrid"
							id="txtAlignmentBoardGrid"
							title="Alignment Grid Size"
							readOnly={ player.active }
							value={ alignmentBoardGrid }
							onChange={ this.onAlignmentBoardGridChange }/>
					</li> */}
					<li className="uk-text-small text-muted uk-width-1-2">
						<Input
							max={ 100 }
							min={ -100 }
							name="xAxis"
							type="number"
							id="txtImageXAxis"
							readOnly={ player.active }
							title="Horizontal (X) Axis"
							value={ percentageCoordsPosition.x }
							onChange={ this.onImageXPercentageCoordsChange }/>
					</li>
					<li className="uk-text-small text-muted uk-width-1-2">
						<Input
							max={ 100 }
							min={ -100 }
							name="yAxis"
							type="number"
							id="txtImageYAxis"
							title="Vertical (Y) Axis"
							readOnly={ player.active }
							value={ percentageCoordsPosition.y }
							onChange={ this.onImageYPercentageCoordsChange }/>
					</li>
				</ul>
			</Fragment>
		);
	}

	renderImageOverlayPreview = () => {
		const { imageOverlaySettings } = this.state;

		if(!imageOverlaySettings.active) return '';

		const { image } = imageOverlaySettings;

		const pxPosition = this.getImagePixelPosition(image.positionX, image.positionY);
		const top = pxPosition.top - 7.5;
		const left = pxPosition.left - 7.5;

		const styles = {
			// opacity: 0.4,
			top: `${ top }px`,
			left: `${ left }px`,
			// background: image.theme
		}

		return (
			<div className="uk-custom-preview streetview-images-container" data-image-canvas>
				<span className="alignment-crosshair alignmentCrosshairClone" style={ styles }>
					<span className="crosshair-icon"/>
				</span>
				<ImageItem image={ imageOverlaySettings.image } media={ imageOverlaySettings.media } ref={ this.imageItemRef }/>
			</div>
		);
	}

	renderImageTransitionSettings = () => {
		const { player, imageOverlaySettings } = this.state;
		if(!imageOverlaySettings.active) return '';

		const { image: { transition } } = imageOverlaySettings;

		return (
			<div className="uk-transition-selects-container">
				<div className="transition-select m-b-30">
					<label htmlFor="transitionIn">Transition In</label>
					<div className="uk-grid uk-grid-small">
						<div className="uk-width-3-4">
							<TransitionSelect id="txtImageTransitionEntrances" name="imageTransitionEntrance" type="entrances" value={ transition.entrance } onChange={ this.onImageEntranceChange } ref={ this.transitionEntranceSelect }/>
						</div>
						<div className="uk-width-1-4">
							<button type="button" className={ `md-btn md-btn-primary text-white ${ player.active ? 'disabled' : '' }` } onClick={ () => this.animateImage(transition.entrance) }>Animate</button>
						</div>
					</div>
				</div>
				<div className="transition-select m-b-30">
					<label htmlFor="transitionOut">Transition Out</label>
					<div className="uk-grid uk-grid-small">
						<div className="uk-width-3-4">
							<TransitionSelect id="txtImageTransitionExits" name="imageTransitionExit" type="exits" value={ transition.exit } onChange={ this.onImageExitChange } ref={ this.transitionExitSelect }/>
						</div>
						<div className="uk-width-1-4">
							<button type="button" className={ `md-btn md-btn-primary text-white ${ player.active ? 'disabled' : '' }` } onClick={ () => this.animateImage(transition.exit) }>Animate</button>
						</div>
					</div>
				</div>
				<div className="transition-duration">
					<IonSlider id="txtTransitionDuration" name="transitionDuration" label="Transition Duration (Seconds)" parentClassName={ player.active ? 'disabled' : '' } value={ transition.duration } min={ 0.1 } max={ 5 } step={ 0.1 } onChange={ this.onImageTransitionDurationChange }/>
				</div>
			</div>
		);
	}

	renderImageOverlaySettings = () => {
		const { player, imageOverlaySettings } = this.state;
		if(!imageOverlaySettings.active) return '';

		return (
			<div className="uk-grid uk-grid-small">
				<div className="uk-width-1-2">
					<div className="modal-group m-b-40">
						{ this.renderImageOverlayCustomAlignment() }
					</div>
					<div className="modal-group">
						{ imageOverlaySettings.active ? <IonSlider id="imageWidthSlider" name="wRatio" label="Image Width (Percentage)" parentClassName={ player.active ? 'disabled' : '' } value={ imageOverlaySettings.image.wRatio } min={ 0 } max={ 1 } step={ 0.01 } onChange={ this.onImageWidthSliderChange }/> : 'Loading...' }
						<p className="uk-text-small uk-text-italic text-muted">Image width will be proportional to width of the tour play window.</p>
					</div>
				</div>
				<div className="uk-width-1-2">
					<div className="modal-group">
						{ this.renderImageTransitionSettings() }
					</div>
				</div>
			</div>
		);
	}

	renderMixerMediaSnaps = () => {
		const data = this.generateMixerMediaSnaps();
		if(!data.length) return '';

		return map(data, item => {
			const style = {
				left: `${ item.position }px`,
			}

			return <span className={ `media-snap mediaSnap ${ item.type }Snap` } key={ item.snapId } data-type={ item.snap } data-id={ item.snapId } style={ style }/>;
		});
	}

	renderHistoryPanel = () => {
		const { historyPanel, mixer } = this.state;
		if(!historyPanel.active) return '';

		const { history } = this.props;

		return (
			<HistoryPanel
				history={ history }
				mixerActive={ mixer.active }
				applyHistory={ this.applyHistory }
				clearHistory={ this.resetHistory }
				hidePanel={ this.hideHistoryPanel }
				removeHistory={ this.removeHistory }
				selectHistory={ this.selectHistory }
				relieveHistory={ this.relieveHistory }
				previewHistory={ this.previewHistory }/>
		);
	}

	renderEditorPanel = () => {
		const { tour, active } = this.props;
		const { loading, isUpdated, panel, mixer, musicPanel, imagePanel } = this.state;

		if(!active) return '';

		return (
			<div className="editor-panel" ref={ this.editorPanelRef }>
				<div className="editor-droppable-indicator">
					<span>Drop Here!</span>
				</div>
				<div className="editor-title panel-title panelTitle"
					role="button"
					tabIndex="-1"
					onClick={ this.onLayersTitleClick }>
					<span
						className="title-icon"
						role="button"
						tabIndex="-1"
						onClick={ this.onTitleCloseClick }>
						<i className="material-icons text-primary">
							{ mixer.active ? 'keyboard_arrow_down' : 'arrow_back' }
						</i>
					</span>
					<h3 className="sequenceTitleText">Layer Editor</h3>
					<ul className={ `sequenceTitleActions always-visible ${ !mixer.active ? 'd-none' : '' }` }>
						<li>
							<Link
								to="#closeEditor"
								className="atn atn-close"
								data-uk-tooltip="{pos: top}"
								title="Close Editor"
								onClick={ this.onCloseMixer }>
								<i className="material-icons">clear</i>
							</Link>
						</li>
						<li>
							<Link
								to="#sqlegs"
								className="atn atn-context atnContext"
								data-uk-tooltip="{pos: top}"
								title="More Options"
								onClick={ this.onLayersTitleCtxClick }>
								<i className="material-icons">&#xE5D4;</i>
							</Link>
						</li>	
					</ul>
				</div>
				<div className="editor-content">
					<div className={ `editor-group ${ !mixer.active ? 'd-none' : '' }` }>
						<div className="editor-title panel-title panelTitle" role="button" tabIndex="-1" data-ctxtoggle-parent onClick={ this.onImageTitleClick }>
							<span className="title-icon">
								<i className="material-icons text-primary">
									{ imagePanel.minimize ? 'keyboard_arrow_right' : 'keyboard_arrow_down' }
								</i>
							</span>
							<h3 className="sequenceTitleText">Image / Animation</h3>
							<ul className="sequenceTitleActions">
								<li>
									<Link
										to="#sqlegs"
										className="atn atn-context atnContext"
										data-uk-tooltip="{pos: top}"
										title="More Options"
										onClick={ this.onImageTitleCtxClick }>
										<i className="material-icons">&#xE5D4;</i>
									</Link>
								</li>
							</ul>
						</div>
						{ this.renderImageList() }
					</div>
					<div className="editor-group">
						<div className="editor-title panel-title panelTitle" role="button" tabIndex="-1" data-ctxtoggle-parent onClick={ this.onTitleClick } ref={ this.panelTitleRef }>
							<span className="title-icon">
								<i className="material-icons text-primary">
									{ panel.minimize ? 'keyboard_arrow_right' : 'keyboard_arrow_down' }
								</i>
							</span>
							<h3 className="sequenceTitleText">{ tour.title }</h3>
							<ul className="sequenceTitleActions">
								<li>
									<Link
										to="#sqleg"
										className="atn atn-add atnPreview"
										data-uk-tooltip="{pos: top}"
										title="Preview Tour"
										onClick={ this.onTourPlay }>
										<i className="material-icons">&#xE038;</i>
									</Link>
								</li>
								<li>
									<Link
										to="#sqleg"
										className="atn atn-context atnContext"
										data-uk-tooltip="{pos: top}"
										title="More Options"
										onClick={ this.onTourCtxClick }>
										<i className="material-icons">&#xE5D4;</i>
									</Link>
								</li>
							</ul>
						</div>
						{ this.renderLegsTree() }
					</div>
					<div className={ `editor-group ${ !mixer.active ? 'd-none' : '' }` }>
						<div className="editor-title panel-title panelTitle" role="button" tabIndex="-1" data-ctxtoggle-parent onClick={ this.onMusicTitleClick }>
							<span className="title-icon">
								<i className="material-icons text-primary">
									{ musicPanel.minimize ? 'keyboard_arrow_right' : 'keyboard_arrow_down' }
								</i>
							</span>
							<h3 className="sequenceTitleText">Background Music</h3>
							<ul className="sequenceTitleActions">
								<li>
									<Link
										to="#sqlegs"
										className="atn atn-context atnContext"
										data-uk-tooltip="{pos: top}"
										title="More Options"
										onClick={ this.onMusicTitleCtxClick }>
										<i className="material-icons">&#xE5D4;</i>
									</Link>
								</li>
							</ul>
						</div>
						{ this.renderMusicList() }
					</div>
				</div>
				<div className="editor-actions panel-actions">
					<ul>
						{/* <li className={ `atn-history atn-undo ${ isEmpty(history) ? 'disabled' : '' }` }>
							<Link to="#sqleg" onClick={ this.onHistoryClick }>History</Link>
						</li> */}
						<li className={ `atn-cancel ${ loading ? 'disabled' : '' }` }>
							<Link to="#sqleg" onClick={ this.onCancel }>Close</Link>
						</li>
						<li className={ `atn-save ${ loading || !isUpdated ? 'disabled' : '' }` }>
							<Link to="#sqleg" onClick={ this.onSave }>Save</Link>
						</li>
					</ul>
				</div>
			</div>
		);
	}

	renderMixerPanelHistoryActions = () => {
		const { history } = this.props;
		const { historyPanel } = this.state;

		// const isHistorySelected = findIndex(history, { selected: true }) >= 0;
		// const isLastHistory = (findIndex(history, { selected: true }) - 1) === 0;

		// if(historyPanel.active) return <div style={{ height: '44px' }}/>;

		return (
			<div className="mixer-history-actions">
				<ul className="list-inline">
					<li className={ `${ (!history.length) ? 'atn-disabled' : '' }` }>
						<Link to="#undo" onClick={ this.onUndoHistoryClick }><i className="material-icons">undo</i> Undo</Link>
					</li>
					<li className={ `${ (!history.length) ? 'atn-disabled' : '' }` }>
						<Link to="#redo" onClick={ this.onRedoHistoryClick }><i className="material-icons">redo</i> Redo</Link>
					</li>
					<li className={ `${ (!history.length || historyPanel.active) ? 'atn-disabled' : '' }` }>
						<Link to="#redo" onClick={ this.onHistoryClick }>
							<i className="material-icons">access_time</i> History
						</Link>
					</li>
				</ul>
			</div>
		);
	}

	renderMixerPanel = () => {
		const { mixer, header, footer } = this.state;
		const { active } = this.props;

		if(!active || !mixer.active) return '';

		const headerVideoTitle = header.mediaId ? header.name : 'No Header Video';
		const footerVideoTitle = footer.mediaId ? footer.name : 'No Footer Video';
		
		return (
			<div className="editor-mixer-outer">
				<Scrollbar className="editor-mixer" ref={ this.cntEditorMixerRef }>
					<div className="mixer-inner" ref={ this.editorMixerRef }>
						<div className="mixer-reference-container">
							<div className="mixer-reference mixerReference" ref={ this.editorMixerWidthRef }/>
						</div>
						<div className="mixer-media-snappings-container">
							{ this.renderMixerMediaSnaps() }
						</div>
						{ this.renderMixerPanelHistoryActions() }
						<div className="mixer-media-timeline image-timeline mixer-media" ref={ this.cntMixerImageRef }>
							<div className="media-uploader image-uploader" onClick={ this.onImageUploaderClick } role="button" tabIndex="-1">
								<div className="uploader-inner">
									<input type="file" className="uploader-input" onChange={ this.onImageUpload } ref={ this.mixerImageUploaderRef } accept="image/*" multiple/>
								</div>
							</div>
							<div className="media-timeline-inline">
								<div className="timeline-inner">
									{ this.renderImageTimelineInline() }
								</div>
							</div>
							<div className="media-timeline-list-outer">
								{ this.renderImageTimelineList() }
							</div>
						</div>
						<div className="mixer-leg-timeline">
							<div className="leg-timeline-inline">
								{ this.renderLegTimelineInline() }
								<div className="editor-video video-header" id={ `videoHeader_${ header.headerId }` }>
									<Link to="#editor" className="atn atn-context" data-uk-tooltip="{pos: top}" title={ headerVideoTitle } onClick={ this.onHeaderCtxClick }>
										<i className="material-icons">more_vert</i>
									</Link>
								</div>
								<div className="editor-video video-footer" id={ `videoFooter_${ footer.footerId }` }>
									<Link to="#editor" className="atn atn-context" data-uk-tooltip="{pos: top}" title={ footerVideoTitle } onClick={ this.onFooterCtxClick }>
										<i className="material-icons">more_vert</i>
									</Link>
								</div>
							</div>
							<div className="leg-timeline-list">
								{ this.renderLegTimelineList() }
							</div>
						</div>
						<div className="mixer-media-timeline music-timeline mixer-media" ref={ this.cntMixerMusicRef }>
							<div className="media-uploader music-uploader" onClick={ this.onMusicUploaderClick } role="button" tabIndex="-1">
								<div className="uploader-inner">
									<input type="file" className="uploader-input" accept="audio/*" onChange={ this.onMusicUpload } ref={ this.mixerMusicUploaderRef } multiple/>
								</div>
							</div>
							<div className="media-timeline-inline">
								<div className="timeline-inner">
									{ this.renderMusicTimelineInline() }
								</div>
							</div>
							<div className="media-timeline-list-outer">
								{ this.renderMusicTimelineList() }
							</div>
						</div>
						<div className="mixer-geo-thumbnails mixerThumbnails">
							<span className="geo-thumbnail geoThumbnailStart"/>
							<span className="geo-thumbnail geoThumbnailEnd"/>
						</div>
						<div className="media-snap-line" ref={ this.snapLineStartRef }/>
						<div className="media-snap-line" ref={ this.snapLineEndRef }/>
					</div>
				</Scrollbar>
				{ this.renderMusicPlayer() }
				<input type="file" className="header-media-uploader uk-invisible" onChange={ e => this.onMixerVideoUpload(e, 'header') } accept="video/mp4" ref={ this.headerVideoUploaderRef }/>
				<input type="file" className="footer-media-uploader uk-invisible" onChange={ e => this.onMixerVideoUpload(e, 'footer') } accept="video/mp4" ref={ this.footerVideoUploaderRef }/>
			</div>
		);
	}

	render() {
		const { mixer, uploading, loading, groupForm, player } = this.state;
		const { tour, active } = this.props;

		const busy = uploading ? true : loading;

		return (
			<Fragment>
				<div
					className={ `tour-sequence-editor tour-panel tour-sequence-panel ${ active ? 'panel-active' : '' } ${ mixer.active ? 'panel-mixer-active' : '' }` }
					id="sequenceEditor"
					data-tour={ tour.tourId }
					data-panel="sequence"
					ref={ this.panelRef }>
					{ busy ? <Preloader center minimal loading={ busy }/> : '' }
					<Scrollbar
						className="editor-inner panel-inner"
						ref={ this.cntEditorRef }>
						{ this.renderHistoryPanel() }
						{ this.renderEditorPanel() }
						{ this.renderMixerPanel() }
					</Scrollbar>
				</div>
				<Modal
					bgClose={ false }
					popup="image-settings"
					keyboardClose={ false }
					ref={ this.imageSettingsModalRef }
					onShow={ this.onImageSettingsModalShow }
					onHide={ this.onImageSettingsModalHide }>
					<form onSubmit={ this.onImageSettingsFormSubmit }>
						<div className="uk-modal-dialog uk-modal-dialog-large">
							<NoAnimationAlert/>
							<div className="uk-modal-header">
								<h3 className="uk-modal-title modalTitle">Image Settings</h3>
							</div>
							<div className="uk-modal-body">
								{ this.renderImageOverlaySettings() }
							</div>
							<div className="uk-modal-footer">
								<Link to="#closeModal" className={ `md-btn md-btn-flat md-btn-flat-default md-btn-wave waves-effect waves-button uk-modal-close ${ player.active ? 'disabled' : '' }` }>
									Cancel
								</Link>
								<Link to="#preview" className={ `md-btn md-btn-flat md-btn-flat-info md-btn-wave waves-effect waves-button` } onClick={ this.onImageTourPreviewClick }>
									{ player.active ? 'Stop Preview' : 'Preview' }
								</Link>
								<button type="submit" className={ `md-btn md-btn-flat md-btn-flat-success md-btn-wave waves-effect waves-button ${ player.active ? 'disabled' : '' }` } onClick={ this.onImageSettingsFormSubmit }>
									Save
								</button>
							</div>
						</div>
					</form>
				</Modal>
				<Modal
					popup="group"
					bgClose={ false }
					keyboardClose={ false }
					ref={ this.groupModalRef }
					onShow={ this.onGroupModalShow }
					onHide={ this.onGroupModalHide }>
					<form onSubmit={ this.onGroupFormSubmit }>
						<div className="uk-modal-dialog">
							<div className="uk-modal-header">
								<h3 className="uk-modal-title modalTitle">Group</h3>
							</div>
							<div className="uk-modal-body">
								<Input
									title="Group Name"
									name="name"
									id="txtGroupName"
									value={ groupForm.name }
									onChange={ this.handleGroupFormInputs }/>
							</div>
							<div className="uk-modal-footer">
								<Link to="#closeModal" className="md-btn md-btn-flat md-btn-flat-default md-btn-wave waves-effect waves-button uk-modal-close">
									Cancel
								</Link>
								<button type="submit" className="md-btn md-btn-flat md-btn-flat-success md-btn-wave waves-effect waves-button" onClick={ this.onGroupFormSubmit }>
									Save
								</button>
							</div>
						</div>
					</form>
				</Modal>
			</Fragment>
		);
	}
}

/* ----------  Prop Types  ---------- */

SequencePanel.defaultProps = {
	tour: {
		tourId: ''
	},

	history: [],

	location: {}
}

SequencePanel.propTypes = {
	active: PropTypes.bool.isRequired,

	getTourMixer: PropTypes.func.isRequired,
	saveTourMixer: PropTypes.func.isRequired,
	// handleLegMusic: PropTypes.func.isRequired,
	
	addHistory: PropTypes.func.isRequired,
	enableHistory: PropTypes.func.isRequired,
	selectHistory: PropTypes.func.isRequired,
	updateHistory: PropTypes.func.isRequired,
	removeHistory: PropTypes.func.isRequired,
	resetHistory: PropTypes.func.isRequired,
	disableHistory: PropTypes.func.isRequired,
	
	addMedia: PropTypes.func.isRequired,
	addAttachment: PropTypes.func.isRequired,

	playTour: PropTypes.func.isRequired,
	hidePanel: PropTypes.func.isRequired,
	handleContext: PropTypes.func.isRequired,
	// updatePlayerState: PropTypes.func.isRequired,
	checkSqPanelIsBusy: PropTypes.func.isRequired,
	checkSqPanelIsUpdated: PropTypes.func.isRequired,
	// handleMusicMixer: PropTypes.func.isRequired,

	previewMedia: PropTypes.func.isRequired,
	disableMediaPanel: PropTypes.func.isRequired,
	
	updateMusicPlayerState: PropTypes.func.isRequired,

	history: PropTypes.arrayOf(PropTypes.object),

	tour: PropTypes.shape({
		tourId: PropTypes.string
	}).isRequired
}

/* ----------  Redux  ---------- */

const mapStateToProps = state => ({
	history: state.sequencePanel.history
});

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		getTourMixer: GetTourMixer,
		saveTourMixer: SaveTourMixer,
		
		addHistory: AddHistory,
		selectHistory: SelectHistory,
		enableHistory: EnableHistory,
		disableHistory: DisableHistory,
		updateHistory: UpdateHistory,
		removeHistory: RemoveHistory,
		resetHistory: ResetHistory
	}, dispatch)
);

/* ----------  Exports  ---------- */

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(SequencePanel);
