/* ----------  Scripts  ---------- */

const RemovePlaybackDetails = () => dispatch => (
	dispatch({
		type: 'TOUR_PLAYBACK_REMOVE_DETAILS',
	})
);

/* ----------  Exports  ---------- */

export default RemovePlaybackDetails;
