/* ----------  Scripts  ---------- */

const RELATED_ACTIVE_TOUR_INITIAL_STATE = {};

const ReducerHomeRelatedActiveTour = (state = RELATED_ACTIVE_TOUR_INITIAL_STATE, action) => {
	if(action.type === 'HOME_GET_RELATED_TOUR') {
		return { ...state, ...action.payload };
	} else if(action.type === 'HOME_REMOVE_RELATED_TOUR') {
		return RELATED_ACTIVE_TOUR_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerHomeRelatedActiveTour;