/* ----------  Scripts  ---------- */

const SPONSORED_LEGS_INITIAL_STATE = [];

const ReducerMarketplaceSponsoredLegs = (state = SPONSORED_LEGS_INITIAL_STATE, action) => {
	if(action.type === 'MARKETPLACE_GET_SPONSORED_LEGS') {
		return [ ...state, ...action.payload.results ];
	} else if(action.type === 'MARKETPLACE_REMOVE_SPONSORED_LEGS') {
		return SPONSORED_LEGS_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMarketplaceSponsoredLegs;