/* ----------  Imports  ---------- */

// Lodash
import { mapKeys, map, max } from 'lodash';

/* ----------  Scripts  ---------- */

const CART_DATA_INITIAL_STATE = {};

const ReducerCartData = (state = CART_DATA_INITIAL_STATE, action) => {
	if(action.type === 'GET_CART') {
		const response = mapKeys(action.payload.results, 'legId');
		return { ...state, ...response };
	} else if(action.type === 'CART_ADD_ITEM') {
		let autoId = max(map(state, 'autoId'));

		const objs = map(action.payload.legs, leg => {
			autoId += 1;

			return {
				autoId,
				legId: leg.legId,
				title: leg.title,
				price: leg.price
			}
		});

		const legs = mapKeys(objs, 'legId');

		return { ...state, ...legs };
	} else if(action.type === 'CART_REMOVE_ITEM') {
		const updatedState = { ...state };

		map(action.payload.legIds, legId => delete updatedState[legId]);

		return updatedState;
	} else if(action.type === 'REMOVE_CART') {
		return CART_DATA_INITIAL_STATE;
	}

	return state;
}

/* ----------  Exports  ---------- */

export default ReducerCartData;