/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { map, startCase, find } from 'lodash';

// Related Tour
import RelatedTour from './RelatedTour';

// Utils
import SlickCarousel from './../../Utils/SlickCarousel';

/* ----------  Scripts  ---------- */

class RelatedTourBlock extends React.Component {
	componentDidMount() {}

	setTourActive = tourId => {
		this.props.setTourActive(tourId);
	}

	changeMapPosition = position => this.props.changeMapPosition(position)

	handleTour = tourId => this.props.handleTour(tourId)

	renderTours = (type = 'groupTours') => {
		const { group } = this.props;
		const tours = group[type];

		if(!tours || !tours.length) return <div className="d-none"/>;

		return map(tours, tour => {
			const primaryCategory = find(tour.categories, ['categoryId', tour.primaryCategory]);
			const isSponsored = type === 'sponsored';
			
			return (
				<RelatedTour
					tour={ tour }
					key={ tour.tourId }
					sponsored={ isSponsored }
					loadTour={ this.loadTour }
					handleTour={ this.handleTour }
					primaryCategory={ primaryCategory }
					setTourActive={ this.setTourActive }
					changeMapPosition={ this.changeMapPosition }/>
			);
		});
	}

	renderSlick = (type = 'groupTours') => {
		const { group } = this.props;
		const tours = group[type]

		if(!tours || !tours.length) return <div className="d-none"/>;

		return (
			<React.Fragment>
				<SlickCarousel 
					className="block-carousel"
					slickClass="block-slick"
					slickControlsClass="block-controls"
					slickType="relatedTour">
					{ this.renderTours(type) }
				</SlickCarousel>
			</React.Fragment>
		);
	}

	render() {
		const { group } = this.props;

		const tours = [];
		map(group.groupTours, tour => tours.push(tour));
		map(group.others, tour => tours.push(tour));
		map(group.sponsored, tour => tours.push(tour));

		if(!tours.length) return '';

		return (
			<div className="content-block" data-group-id={ group.groupId }>
				<div className="block-title">
					<h3>{ startCase(group.groupTitle) }</h3>
				</div>

				{ this.renderSlick('groupTours') }
				{ this.renderSlick('others') }
				{ this.renderSlick('sponsored') }
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

RelatedTourBlock.propTypes = {
	// loadTour: PropTypes.func.isRequired,
	handleTour: PropTypes.func.isRequired,
	setTourActive: PropTypes.func.isRequired,
	changeMapPosition: PropTypes.func.isRequired,
	
	group: PropTypes.shape({
		groupId: PropTypes.string.isRequired,
		groupTitle: PropTypes.string.isRequired,
		groupTours: PropTypes.arrayOf(PropTypes.object).isRequired
	}).isRequired
}

/* ----------  Exports  ---------- */

export default RelatedTourBlock;
