/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { isEqual, map, get } from 'lodash';

// UUID
import uuid from 'uuid/v4';

// Message Components
import Message from './Message';
import MessageForm from './MessageForm';

// Status
import Status from './Status';

// Status Constants
import STATUS from './../../Constants/Status';

// Preloader
import Preloader from './../Common/Preloader';

// Scrollbar
import Scrollbar from './../Utils/Scrollbar';
import ProfilePicture from './../Utils/ProfilePicture';

/* ----------  Scripts  ---------- */

class Conversation extends React.Component {
	constructor(props) {
		super(props);

		this.firstScroll = false;

		this.index = 0;

		this.messagesRef = React.createRef();

		this.initialMeta = {
			total: 0,
			count: 20,
			startIndex: 0,
		}

		this.state = {
			scrollPosition: 0,
			meta: this.initialMeta
		}
	}

	componentDidMount() {
		this.removeMessages();
		this.getMessages();
	}

	componentWillUnmount() {
		this.removeMessages();
	}

	getMessages = callback => {
		const { conversation } = this.props;
		const { meta } = this.state;

		this.props.loadMessages(conversation.conversationId, meta, response => {
			if(callback) callback();

			this.setScrollPosition();
			this.updateMeta({ total: response ? response.total : 0 });
		});
	}

	setScrollPosition = () => {
		const { scrollPosition } = this.state;
		const cntMessages = this.messagesRef.current;
		const position = (cntMessages.scrollHeight - scrollPosition) + 1;

		this.updateScroll(position);
	}

	setScrollToBottom = () => {
		const cntMessages = this.messagesRef.current;
		const position = cntMessages.scrollHeight;

		this.updateScroll(position);
	}

	removeMessages = () => {
		this.updateScroll(0);
		this.props.unloadMessages();

		this.updateMeta(this.initialMeta);
	}

	close = e => {
		e.preventDefault();

		this.props.close();
	}

	handleScroll = () => {
		const { loading } = this.props;

		if(loading) return false;

		const { meta } = this.state;
		const startIndex = meta.startIndex + meta.count;

		if(meta.total >= startIndex) {
			this.updateMeta({ startIndex }, this.getMessages);
		}

		return true;
	}

	sendMessage = (message, callback) => {
		this.setScrollToBottom();
		this.props.sendMessage(this.props.conversation.conversationId, message, callback);
	}

	updateScroll = position => {
		this.setState({ scrollPosition: position });
	}

	updateMeta = (updates, callback) => {
		const { meta } = this.state;

		this.setState({
			meta: {
				...meta,
				...updates
			}
		}, () => callback ? callback() : null);
	}

	renderMessages = () => {

		const { user, messages, messagesData } = this.props;

		if(!messages.length) return <div/>;
		
		return map(messages, item => {
			const message = get(messagesData, item.messageId);

			if(message) {
				const isUser = isEqual(message.contact.contactId, user.profile.userId);
				const type = isUser ? 'user' : 'contact';

				return <Message key={ uuid() } data={ message } type={ type }/>;
			}

			return <div className="d-none" key={ uuid() }/>;
		});
	}

	render() {
		const { scrollPosition } = this.state;
		const { conversation, loading, theme } = this.props;

		return (
			<div className="view flex-column align-items-center justify-content-center d-flex" id="chat-view" data-conversation={ conversation.conversationId }>
				<div className="toolbar row no-gutters align-items-center justify-content-between w-100 px-4">
					<div className="col">
						<div className="row no-gutters align-items-center">
							<div className="chat-contact row no-gutters align-items-center">
								<div className="avatar-wrapper mr-4">
									<ProfilePicture title={ conversation.title } className="avatar" src={ conversation.photo }/>
									<Status type={ conversation.status }/>
								</div>
								<div className="chat-contact-name">
									{ conversation.title }
								</div>
							</div>
						</div>
					</div>
					<button className="btn btn-icon btn-close d-block" type="button" onClick={ this.close }>
						<span className="material-icons">close</span>
					</button>
					<button className="btn btn-icon btn-context d-none" type="button">
						<span className="material-icons">more_vert</span>
					</button>
				</div>
				<Scrollbar className="chat-content col"
					position={ scrollPosition }
					onYScrollStart={ this.handleScroll }>
					<Preloader loading={ loading }/>
					<div className="chat-messages chatMessages" data-theme={ theme } ref={ this.messagesRef }>
						{ this.renderMessages() }
					</div>
				</Scrollbar>
				<div className="chat-footer row align-items-center justify-content-center w-100 p-2 pl-4">
					<MessageForm
						loading={ loading }
						status={ STATUS.type.online }
						blocked={ conversation.blocked }
						sendMessage={ this.sendMessage }/>
				</div>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

Conversation.propTypes = {
	// token: PropTypes.string.isRequired,
	theme: PropTypes.string.isRequired,

	loading: PropTypes.bool.isRequired,

	close: PropTypes.func.isRequired,
	sendMessage: PropTypes.func.isRequired,
	loadMessages: PropTypes.func.isRequired,
	unloadMessages: PropTypes.func.isRequired,

	messages: PropTypes.arrayOf(PropTypes.object).isRequired,
	messagesData: PropTypes.objectOf(PropTypes.object).isRequired,

	conversation: PropTypes.shape({
		conversationId: PropTypes.string.isRequired
	}).isRequired,

	user: PropTypes.shape({
		profile: PropTypes.shape({
			userId: PropTypes.string.isRequired
		}).isRequired
	}).isRequired
}

/* ----------  Exports  ---------- */

export default Conversation;