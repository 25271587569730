/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// jQuery
import $ from 'jquery';

// Slick Carousel
import 'slick-carousel';

// Match Height
import 'jquery-match-height';

/* ----------  Scripts  ---------- */

class SlickCarousel extends React.Component {
	constructor(props) {
		super(props);

		this.slick = React.createRef();
	}

	componentDidMount() {
		this.load(this.slick.current)
	}
	
	componentWillUnmount() {
		this.unload(this.slick.current);
	}

	getOptions = (type, controls) => {
		const typeMarketplace = {
			dots: false,
			arrows: true,
			infinite: false,
			slidesToShow: 5,
			slidesToScroll: 1,
			variableWidth: false,
			appendArrows: controls,
			prevArrow: '<span class="slick-arrow slick-prev slickPrev"><span class="fas fa-angle-left"></span></span>',
			nextArrow: '<span class="slick-arrow slick-next slickNext"><span class="fas fa-angle-right"></span></span>',
		}

		const typeTourDetails = {
			dots: true,
			arrows: false,
			infinite: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			variableWidth: false,
			appendDots: controls,
			adaptiveHeight: true,

			customPaging: () => ( '<span></span>' )
		}

		const typeRelatedTour = {
			dots: false,
			arrows: true,
			infinite: false,
			slidesToShow: 2,
			slidesToScroll: 1,
			appendArrows: controls,
			prevArrow: '<span class="slick-arrow slick-prev slickPrev"><span class="fas fa-angle-left"></span></span>',
			nextArrow: '<span class="slick-arrow slick-next slickNext"><span class="fas fa-angle-right"></span></span>',

			responsive: [
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
			]
		}

		switch(type) {
			case 'marketplace': return typeMarketplace;
			case 'tourDetails': return typeTourDetails;
			case 'relatedTour': return typeRelatedTour;
			default: return null;
		}
	}

	getSlick = node => $('[data-slick]', $(node))

	getSlickControls = node => $('[data-slick-controls]', $(node))

	events = slick => ({
		init: () => {
			const { onInit } = this.props;

			if(onInit) onInit(slick);
		},

		beforeChange: () => {
			const { beforeChange } = this.props;

			if(beforeChange) beforeChange(slick);
		}
	})

	handleTourHeight = () => {
		const node = this.slick.current;
		const mh = node.querySelectorAll('[data-mh]');

		$(mh).matchHeight();
	}

	load = node => {
		const $slick = this.getSlick(node);
		const $slickControls = this.getSlickControls(node);
		const opts = this.getOptions(this.props.slickType, $slickControls);

		$slick.on('init', () => {
			this.events($slick).init();
		});

		$slick.slick(opts);

		$slick.on('beforeChange', () => {
			this.events($slick).beforeChange();
		});
	}

	unload = node => {
		const $slick = this.getSlick(node);

		if($slick.length && $slick.hasClass('slick-initialized')) $slick.slick('unslick');
	}

	refresh = node => {
		const $slick = this.getSlick(node);
		
		if($slick && $slick.length) $slick[0].slick.refresh();
	}

	render() {
		return (
			<div className={ this.props.className } id={ this.props.id } ref={ this.slick }>
				<div className={ this.props.slickClass } data-slick>
					{ this.props.children }
				</div>
				<div className={ `slick-controls ${ this.props.slickControlsClass }` } data-slick-controls/>
			</div>
		);
	}
}

SlickCarousel.defaultProps = {
	onInit: false,
	beforeChange: false,

	id: '',
	className: '',
	slickClass: '',
	slickControlsClass: ''
}

SlickCarousel.propTypes = {
	onInit: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	beforeChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),

	id: PropTypes.string,
	className: PropTypes.string,
	slickClass: PropTypes.string,
	slickControlsClass: PropTypes.string,
	slickType: PropTypes.string.isRequired,

	children: PropTypes.node.isRequired,
}

/* ----------  Exports  ---------- */

export default SlickCarousel;