/* ----------  Imports  ---------- */

const SetLegHovered = data => dispatch => {
	dispatch({
		type: 'MARKETPLACE_SET_LEG_HOVERED',
		payload: data
	})
}

/* ----------  Exports  ---------- */

export default SetLegHovered;