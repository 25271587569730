/* ----------  Imports  ---------- */

// Local Storage
import Storage from './../../../../Services/Storage';

/* ----------  Scripts  ---------- */

const CheckAuth = callback => dispatch => {
	const user = Storage.getUser();
	const token = Storage.getToken();
	const fcmToken = Storage.getFcmToken();

	if(token) {
		dispatch({
			type: 'USER_TOKEN_SYNC',
			payload: token
		});
	} else {
		dispatch({ type: 'USER_TOKEN_REMOVE' });
	}

	if(fcmToken) {
		dispatch({ type: 'USER_FCM_TOKEN_SYNC' });
	} else {
		dispatch({ type: 'USER_FCM_TOKEN_REMOVE' });
	}

	if(user) {
		dispatch({
			type: 'USER_SYNC_PROFILE',
			payload: user
		});
	} else {
		dispatch({ type: 'USER_REMOVE_PROFILE' });
	}

	if(callback) callback();
}

/* ----------  Exports  ---------- */

export default CheckAuth;