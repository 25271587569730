/* ----------  Imports  ---------- */

// Lodash
import { map, mapKeys, get, mapValues, indexOf } from 'lodash';

/* ----------  Scripts  ---------- */

const LEGS_DATA_INITIAL_STATE = {};

const ReducerMarketplaceLegsData = (state = LEGS_DATA_INITIAL_STATE, action) => {
	if(action.type === 'MARKETPLACE_GET_LEGS') {
		const response = mapKeys(action.payload.results, 'legId');
		return { ...state, ...response };
	} else if(action.type === 'MARKETPLACE_SET_LEGS_VISIBLE') {
		const update = mapValues(state, leg => {
			if(indexOf(action.payload, leg.legId) !== -1) return { ...leg, visible: true };

			return { ...leg, visible: false };
		});
		return { ...state, ...update };
	} else if(action.type === 'MARKETPLACE_SET_LEG_FAVORITE') {
		const { legId } = action.payload;
		const leg = get(state, legId);
		const update = { ...leg, favorite: true };

		return { ...state, [legId]: update };
	} else if(action.type === 'MARKETPLACE_SET_LEG_UNFAVORITE') {
		const { legId } = action.payload;
		const leg = get(state, legId);
		const update = { ...leg, favorite: false };
		
		return { ...state, [legId]: update };
	} else if(action.type === 'MARKETPLACE_SET_LEG_ACTIVE') {
		const leg = get(state, action.payload);
		const update = { ...leg, active: true };
	
		return { ...state, [action.payload]: update };
	} else if(action.type === 'MARKETPLACE_SET_LEGS_UNACTIVE') {
		const update = mapValues(state, leg => {
			if(leg.active) return { ...leg, active: false };
			return leg;
		});

		return { ...state, ...update };
	} else if(action.type === 'MARKETPLACE_SET_LEG_HOVERED') {
		const { legId, hovered } = action.payload;
		const leg = get(state, legId);
		const update = { ...leg, hovered };

		return { ...state, [legId]: update };
	} else if(action.type === 'MARKETPLACE_SET_LEGS_UNHOVERED') {
		const update = mapValues(state, leg => {
			if(leg.hovered) return { ...leg, hovered: false };

			return leg;
		});

		return { ...state, ...update };
	} else if((action.type === 'MARKETPLACE_LEG_SUBSCRIBE') || (action.type === 'MARKETPLACE_LEG_UNSUBSCRIBE')) {
		const { legIds, subscribed } = action.payload;

		const legs = map(legIds, legId => {
			const leg = get(state, legId);

			return { ...leg, isSubscribed: subscribed };
		});

		const response = mapKeys(legs, 'legId');

		return { ...state, ...response };
	} else if(action.type === 'MARKETPLACE_REMOVE_LEGS') {
		return LEGS_DATA_INITIAL_STATE;
	}

	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMarketplaceLegsData;