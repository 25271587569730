/* ----------  Scripts  ---------- */

const SetLegsUnActive = () => dispatch => (
	dispatch({
		type: 'MARKETPLACE_SET_LEGS_UNACTIVE'
	})
);

/* ----------  Exports  ---------- */

export default SetLegsUnActive;