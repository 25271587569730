/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { isEmpty } from 'lodash'

// Preloader
import Preloader from './../../Common/Preloader';

// UIkit
import Modal from './../../UIkit/Modal';
import Input from './../../UIkit/Input';
import Alerts from './../../UIkit/Alerts';

// Helpers
import Notify from './../../../Helpers/Notify';
import InputHelper from './../../../Helpers/InputHelper';
import AudioHelper from './../../../Helpers/AudioHelper';

// Utils
import Toggle from './../../Utils/Toggle';

/* ----------  Scripts  ---------- */

class ModalLegMusic extends React.Component {
	constructor(props) {
		super(props);

		this.modal = React.createRef();
		this.modalForm = React.createRef();

		this.state = {
			errors: [],
			busy: false,
			active: false,
		}
	}

	componentDidMount() {}

	// Events

	onShow = () => {
		this.setState({ active: true }, () => {
			const { onShow } = this.props;
			if(onShow) onShow();
			
			this.uploaderBind();
		});
	}
	
	onHide = () => {
		this.setState({ active: false }, () => {
			const { onHide } = this.props;
			if(onHide) onHide();
		});
	}

	onVolumeChange = e => {
		const { name, value } = e.currentTarget;

		this.handleBgMusicInputChange(name, parseFloat(value, 10));
	}

	onOffsetChange = e => {
		const { name, value } = e.currentTarget;
		const offset = value * 1000;

		this.handleBgMusicInputChange(name, parseFloat(offset, 10));
	}
	
	onAudioVolumeChange = e => {
		const { value } = e.currentTarget;
		this.handleAudioInputChange('volume', parseFloat(value, 10));
	}

	onSubmit = e => {
		e.preventDefault();

		this.save();
	}

	onChooseFile = e => {
		e.preventDefault();

		const uploader = this.props.uploader.current;

		uploader.click();
	}

	onLoopChange = (value, checked) => {
		this.handleBgMusicInputChange('loop', checked);
	}

	onRemoveFile = e => {
		e.preventDefault();

		this.removeFile();
	}

	// Modal Events

	show = () => {
		const modal = this.modal.current;
		modal.show();
	}

	hide = () => {
		const modal = this.modal.current;
		modal.hide();
	}

	// Handlers

	handleBgMusicInputChange = (name, value) => {
		const { data: { bgMusic } } = this.props;

		this.updateLegMusicState({
			bgMusic: {
				...bgMusic,
				[name]: value
			}
		});
	}
	
	handleAudioInputChange = (name, value) => {
		const { data: { audio } } = this.props;

		this.updateLegMusicState({
			audio: {
				...audio,
				[name]: value
			}
		});
	}

	handleMusic = (files, callback) => {
		if(!files.length) {
			Notify.error('Please upload a valid music file.');
			return false;
		}

		let file = files[0];
		const isValid = AudioHelper.isValid(file.type);

		if(!isValid) {
			Notify.error('Please upload a valid music file.');
			return false;
		}

		AudioHelper.toBase64(file, response => {
			const { data: { bgMusic } } = this.props;
			
			this.updateLegMusicState({
				bgMusic: {
					...bgMusic,
					src: response,
					name: file.name,
					size: file.size,
				}
			});

			if(callback) callback();

			file = null;
		});

		return true;
	}

	// Removers

	removeFile = () => {
		const { data: { bgMusic } } = this.props;
			
		this.updateLegMusicState({
			bgMusic: {
				...bgMusic,
				src: null,
				name: '',
				size: '',
			}
		});
	}

	// Save

	save = () => {
		const isValid = this.validateForm();

		if(isValid) {
			this.setState({ busy: true });

			this.props.save(() => {
				this.setState({ busy: false }, this.hide);
				Notify.success('Music uploaded successfully!');
			}, () => {
				this.setState({ busy: false }, this.hide);
				Notify.error('Please try again.');
			});
		}
	}

	// Uploader

	uploaderBind = () => {
		const uploader = this.props.uploader.current;

		if(uploader) {
			uploader.onchange = e => {
				const { files } = e.target;

				if(files.length) {
					this.handleMusic(files, () => {
						e.target.value = '';
					});
				}
			};
		}
	}

	// Updaters

	updateLegMusicState = (updates, callback) => {
		const { updateLegMusicState } = this.props;

		if(updateLegMusicState) updateLegMusicState(updates, callback);
	}

	// Validator

	validateForm = () => {
		let isValid = true;

		const errors = [];
		const { data: { bgMusic } } = this.props;

		if(!InputHelper.isEmpty(bgMusic.src)) {
			if(!bgMusic.volume) {
				isValid = false;
				errors.push('Volume too low.');
			}
		}

		this.setState({ errors });

		return isValid;
	}

	// Renderers

	renderUpload = () => {
		const { data: { bgMusic } } = this.props;

		if(isEmpty(bgMusic.src)) {
			return (
				<React.Fragment>
					<p className="uk-text uk-margin-small-bottom">Select Music File:</p>
					<p className="uk-text-muted uk-text-small uk-margin-small-bottom">Accepted formats: <strong>.ogg, .mp3. acc</strong></p>
					<Link to="#upload" className="uk-form-file md-btn" onClick={ this.onChooseFile }>
						choose file
					</Link>
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				<p className="uk-text-muted uk-text-small uk-margin-small-bottom">Selected Music: { bgMusic.name }</p>
				<Link to="#upload" className="uk-form-file md-btn md-btn-danger text-white" onClick={ this.onRemoveFile }>
					Remove File
				</Link>
			</React.Fragment>
		);
	}

	renderForm = () => {
		const { data: { loading, bgMusic, audio } } = this.props;

		if(loading) return <Preloader loading={ loading } minimal center/>;

		const { busy, errors } = this.state;
		const offset = bgMusic.offset / 1000;

		return (
			<form ref={ this.modalForm }>
				<div className="uk-modal-header">
					<div className="header-title">
						<h3 className="uk-modal-title">Upload Music to Leg</h3>
					</div>
				</div>
				<div className="uk-modal-body">
					<div className="uk-grid uk-grid-small m-b-20">
						<div className="uk-width-large-1-2">
							<Input
								max={ 1 }
								min={ 0.1 }
								step={ 0.1 }
								type="number"
								name="volume"
								id="legMusicVolume"
								title="Select Volume"
								value={ bgMusic.volume || 0.1 }
								onChange={ this.onVolumeChange }/>
						</div>
						<div className="uk-width-large-1-2">
							<Input
								min={ 0 }
								max={ 1 }
								step={ 0.1 }
								type="number"
								name="offset"
								id="legMusicOffset"
								value={ offset || 0 }
								title="Select Offset (Seconds)"
								onChange={ this.onOffsetChange }/>
						</div>
					</div>
					<Input
						max={ 1 }
						min={ 0.1 }
						step={ 0.1 }
						type="number"
						name="audioVolume"
						id="legAudioMusicVolume"
						value={ audio.volume || 0.1 }
						title="Select Audio (Narration) Volume"
						onChange={ this.onAudioVolumeChange }/>
					<div className="uk-file-upload">
						{ this.renderUpload() }
					</div>
					<div className="uk-input-group">
						<Toggle
							name="loop"
							value="loop"
							type="checkbox"
							id="legMusicLoop"
							onChange={ this.onLoopChange }
							checked={ bgMusic.loop || false }/>
						<label htmlFor="legMusicLoop" className="inline-label">Loop background music.</label>
					</div>
					<Alerts show={ errors.length > 0 } data={ errors }/>
				</div>
				<div className="uk-modal-footer">
					<div className="footer-preloader m-r-10">
						<Preloader size={ 20 } loading={ busy } relative minimal/>
					</div>
					<Link to="#closeModal" className="md-btn md-btn-flat md-btn-flat-default uk-modal-close">
						Cancel
					</Link>
					<button className={ `md-btn md-btn-flat md-btn-flat-default ${ busy ? 'disabled' : '' }` } onClick={ this.onSubmit }>
						Upload
					</button>
				</div>
			</form>
		);
	}

	render() {
		return (
			<Modal
				popup="uploadBgMusicModal"
				onShow={ this.onShow }
				onHide={ this.onHide }
				ref={ this.modal }
				bgClose={ false }
				keyboardClose={ false }>
				<div className="uk-modal-dialog">
					{ this.renderForm() }
				</div>
			</Modal>
		);
	}
}

/* ----------  Prop Types  ---------- */

ModalLegMusic.defaultProps = {
	onShow: false,
	onHide: false,
	
	updateLegMusicState: false,
	
	data: {
		bgMusic: {},
		loading: false,
	},

	uploader: {},
}

ModalLegMusic.propTypes = {
	data: PropTypes.shape(),
	uploader: PropTypes.shape(),

	onShow: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	onHide: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),

	save: PropTypes.func.isRequired,
	
	updateLegMusicState: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),

}

/* ----------  Exports  ---------- */

export default ModalLegMusic;
