/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// jQuery
import $ from 'jquery';

/* ----------  Scripts  ---------- */

class Input extends React.Component {
	constructor(props) {
		super(props);

		this.input = React.createRef();

		this.state = {
			value: ''
		}
	}

	componentDidMount() {
		this.update();
	}

	onChange = e => {
		const { onChange } = this.props;

		if(onChange) onChange(e);
	}

	onBlur = e => {
		const { onBlur } = this.props;

		if(onBlur) onBlur(e);
	}

	onKeyUp = e => {
		const { onKeyUp } = this.props;

		if(onKeyUp) onKeyUp(e);
	}

	clearInput = e => {
		e.preventDefault();

		const input = this.input.current;

		input.value = '';
	}

	blur = () => {
		const input = this.input.current;

		input.blur();
	}

	focus = () => {
		const input = this.input.current;

		input.focus();
	}

	update = () => {
		const input = this.input.current;

		window.altair_md.update_input($(input));
	}

	renderTitle = () => {
		const { id, title, labelClass } = this.props;

		if(!title) return <div/>;

		return <label htmlFor={ id } className={ labelClass }>{ title }</label>;
	}

	renderInput = () => {
		const { id, title, placeholder, value, name, className, type, textarea, cols, rows, style, disabled, readOnly, min, max, step } = this.props;

		if(textarea) return (
			<textarea
				cols={ cols }
				rows={ rows }
				className={ `md-input ${ className }` }
				name={ name }
				id={ id }
				value={ value }
				ref={ this.input }
				autoComplete="off"
				onBlur={ this.onBlur }
				onChange={ this.onChange }
				onKeyUp={ this.onKeyUp }
				style={ style }
				disabled={ disabled }
				readOnly={ readOnly }
				placeholder={ !title ? placeholder : '' }
				data-md-input/>
		);

		return (
			<input
				type={ type }
				id={ id }
				min={ min }
				max={ max }
				step={ step }
				name={ name }
				value={ value }
				style={ style }
				ref={ this.input }
				autoComplete="off"
				onBlur={ this.onBlur }
				onChange={ this.onChange }
				onKeyUp={ this.onKeyUp }
				disabled={ disabled }
				readOnly={ readOnly }
				className={ `md-input ${ className }` }
				placeholder={ !title ? placeholder : '' }
				data-md-input/>
		);
	}

	renderInputClearer = () => {
		const { clearable, value } = this.props;

		if(!clearable || !value) return <div className="d-none"/>;

		return <Link to="#clear" className="btn-clear d-block" onClick={ this.clearInput }><i className="material-icons">add_circle</i></Link>;
	}

	render() {
		return (
			<div className="md-input-wrapper">
				{ this.renderTitle() }
				{ this.renderInput() }
				{/* this.renderInputClearer() */}
				<span className="md-input-bar"/>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

Input.defaultProps = {
	value: '',
	type: 'text',
	labelClass: '',
	className: '',
	placeholder: '',

	cols: 30,
	rows: 4,
	
	min: 0,
	max: 0,
	step: 0,

	title: false,
	onBlur: false,
	onKeyUp: false,
	onChange: false,
	textarea: false,
	disabled: false,
	readOnly: false,
	clearable: false,

	style: {}
}

Input.propTypes = {
	min: PropTypes.number,
	max: PropTypes.number,
	step: PropTypes.number,

	type: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	className: PropTypes.string,
	labelClass: PropTypes.string,
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	
	textarea: PropTypes.bool,
	disabled: PropTypes.bool,
	readOnly: PropTypes.bool,
	clearable: PropTypes.bool,

	cols: PropTypes.number,
	rows: PropTypes.number,

	title: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	style: PropTypes.shape(),

	onBlur: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	onKeyUp: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
}

/* ----------  Exports  ---------- */

export default Input;
