/* ----------  Imports  ---------- */

// Axios
import axios from 'axios';

// Lodash
// import { map } from 'lodash';

// Global
import Global from './../../../../../Constants/Global';

/* ----------  Scripts  ---------- */

const CopyNodes = (data, success, fail) => dispatch => {
	// const url = `${ Global.DUMMY_API_BASE_URL }/N1Sz29ptr`;
	const url = `${ Global.API_BASE_URL }/trees/copy/nodes`;

	axios.post(url, data).then(response => {
		const { status, result } = response.data;

		dispatch({
			type: 'MYTOURS_TREES_COPY_NODES',
			payload: result
		});

		// const tResult = map(result, (node, i) => {
		// 	const tNode = data[i];
		// 	node.tNodeId = tNode.tNodeId;

		// 	return node;
		// });

		if(success) success(status, result);
	}).catch(error => {
		const { response } = error;

		if(fail) {
			if(response) {
				fail(response.data.status, response.data.error.reasons);
			} else {
				fail(false, []);
			}
		}
	});
};

/* ----------  Exports  ---------- */

export default CopyNodes;