/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Stripe Elements
import { StripeProvider, Elements } from 'react-stripe-elements';

// Checkout Form
import CheckoutForm from './CheckoutForm';

// Constants
import Global from './../../../Constants/Global';

/* ----------  Class  ---------- */

class AddCreditCard extends React.Component {
	componentDidMount() {}

	addCreditCard = (token, success, fail) => this.props.addCreditCard(token, success, fail)

	renderCheckoutForm = () => {
		const { active } = this.props;

		if(!active) return <div className="d-none"/>;

		const { data, countries } = this.props;

		return (
			<CheckoutForm
				data={ data }
				countries={ countries }
				addCreditCard={ this.addCreditCard }/>
		);
	}
	
	render() {
		return (
			<StripeProvider apiKey={ Global.STRIPE_KEY }>
				<Elements>
					{ this.renderCheckoutForm() }
				</Elements>
			</StripeProvider>
		);
	}
}

/* ----------  Prop Types  ---------- */

AddCreditCard.defaultProps = {
	data: {},
	countries: [],
	
	active: false,
}

AddCreditCard.propTypes = {
	active: PropTypes.bool,

	data: PropTypes.shape(),
	
	countries: PropTypes.arrayOf(PropTypes.object),
	
	addCreditCard: PropTypes.func.isRequired,
}

/* ----------  Exports  ---------- */

export default AddCreditCard;
