/* ----------  Add History  ---------- */

const SelectHistory = (historyId, callback) => dispatch => {
	dispatch({
		type: 'SELECT_HISTORY',
		payload: historyId
	});

	if(callback) callback();
}

/* ----------  Exports  ---------- */

export default SelectHistory;
