/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { map } from 'lodash';

// UUID
import uuid from 'uuid/v4';

/* ----------  Media Info  ---------- */

class MediaInfo extends React.Component {
	renderInfoItems = () => {
		const { data } = this.props;

		return map(data, item => {
			const id = uuid();

			return (
				<li key={ id }>
					<span className="item-title">{ item.title }:</span>
					<span className="item-text">{ item.text }</span>
				</li>
			);
		});
	}

	render() {
		return (
			<ul>
				{ this.renderInfoItems() }
			</ul>
		);
	}
}

/* ----------  Prop Types  ---------- */

MediaInfo.propTypes = {
	data: PropTypes.arrayOf(PropTypes.object).isRequired
}

/* ----------  Exports  ---------- */

export default MediaInfo;
