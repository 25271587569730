/* ----------  Scripts  ---------- */

const ACTIVE_TOUR_INITIAL_STATE = {};

const ReducerHomeActiveTour = (state = ACTIVE_TOUR_INITIAL_STATE, action) => {
	if(action.type === 'HOME_GET_TOUR') {
		return { ...state, ...action.payload };
	} else if(action.type === 'HOME_REMOVE_TOUR') {
		return ACTIVE_TOUR_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerHomeActiveTour;