/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Constants
import STATUS from './../../Constants/Status';

/* ----------  Scripts  ---------- */

class Status extends React.Component {
	componentDidMount() {}

	getStatusClass = () => {
		const { type } = this.props;

		switch(parseInt(type, 10)) {
			case STATUS.type.online: return STATUS.class.online;
			case STATUS.type.offline: return STATUS.class.offline;
			case STATUS.type.away: return STATUS.class.away;
			case STATUS.type.dnd: return STATUS.class.dnd;
			case STATUS.type.blocked: return STATUS.class.blocked;
			default: return 'none';
		}
	}

	getStatusIcon = () => {
		const { type } = this.props;

		switch(parseInt(type, 10)) {
			case STATUS.type.online: return STATUS.icon.online;
			case STATUS.type.offline: return STATUS.icon.offline;
			case STATUS.type.away: return STATUS.icon.away;
			case STATUS.type.dnd: return STATUS.icon.dnd;
			case STATUS.type.blocked: return STATUS.icon.blocked;
			default: return 'help';
		}
	}

	render() {
		const { visible } = this.props;

		if(!visible) return <span className="status d-none"/>

		return (
			<span className={ `status status-${ this.getStatusClass() }` }>
				<i className="material-icons status-icon">{ this.getStatusIcon() }</i>
			</span>
		);
	}
}


/* ----------  PropTypes  ---------- */

Status.defaultProps = {
	visible: true
}

Status.propTypes = {
	visible: PropTypes.bool,
	type: PropTypes.number.isRequired,
}

/* ----------  Exports  ---------- */

export default Status;