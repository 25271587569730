/* ----------  Imports  ---------- */

// Events
import EventEmitter from 'events';

/* ----------  Scripts  ---------- */

class Notifications extends EventEmitter {}

const notifications = new Notifications();

notifications.setMaxListeners(0);

/* ----------  Exports  ---------- */

export default notifications;