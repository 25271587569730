/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// jQuery
const $ = window.$;

/* ----------  Class  ---------- */

class IonSlider extends React.Component {
	constructor(props) {
		super(props);

		this.sliderRef = React.createRef();
		this.sliderApi = null;
	}

	componentDidMount() {
		this.init();
	}

	componentWillUnmount() {
		if(this.sliderApi) {
			this.sliderApi.destroy();
			this.sliderApi = null;
		}
	}

	init = () => {
		const { value, min, max, step, onChange, onFinish } = this.props;
		const slider = this.sliderRef.current;
		const $slider = $(slider);

		$slider.ionRangeSlider({
			min,
			max,
			step,

			skin: 'round',
			from: value || 0,
			extra_classes: 'irs-input',
			
			onChange: onChange || null,
			onFinish: onFinish || null,
		});

		this.sliderApi = $slider.data('ionRangeSlider');
	}

	update = (from, to) => {
		this.sliderApi.update({
			from,
			to
		});
	}

	render() {
		const { id, name, label, value, parentClassName, className } = this.props;
		return (
			<div className={ `ion-slider ${ parentClassName }` }>
				{ label ? <label htmlFor={ id }>{ label }</label> : '' }
				<input type="hidden" name={ name } className={ className } value={ value } onChange={ () => {} } ref={ this.sliderRef }/>
			</div>
		)
	}
}

/* ----------  Prop Types  ---------- */

IonSlider.defaultProps = {
	label: '',
	className: '',
	parentClassName: '',

	min: 0,
	step: 1,
	max: 100,

	onFinish: false,
	onChange: false,
}

IonSlider.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	className: PropTypes.string,
	parentClassName: PropTypes.string,
	
	min: PropTypes.number,
	max: PropTypes.number,
	step: PropTypes.number,
	value: PropTypes.number.isRequired,

	onFinish: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
}

/* ----------  Exports  ---------- */

export default IonSlider;
