/* ----------  Enable History  ---------- */

const EnableHistory = callback => dispatch => {
	dispatch({
		type: 'ENABLE_HISTORY',
	});

	if(callback) callback();
}

/* ----------  Exports  ---------- */

export default EnableHistory;
