/* ----------  Imports  ---------- */

// Lodash
// import { map, get } from 'lodash';

/* ----------  Reducer Playback  ---------- */

const TOUR_PLAYBACK_DETAILS_INITIAL_STATE = {
	"music": [],
	"media": {},
	"musicSequence": {},
	"images": [],
	"header": {},
	"footer": {}
};

const ReducerTourPlaybackDetails = (state = TOUR_PLAYBACK_DETAILS_INITIAL_STATE, action) => {
	if(action.type === 'GET_TOUR_PLAYBACK_DETAILS') {
		const { payload } = action;
		return { ...state, ...payload };
	} else if(action.type === 'TOUR_PLAYBACK_REMOVE_DETAILS') {
		return TOUR_PLAYBACK_DETAILS_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerTourPlaybackDetails;
