/* ----------  Imports  ---------- */

// Lodash
import { mapKeys } from 'lodash';

/* ----------  Scripts  ---------- */

const CATEGORIES_INITIAL_STATE = {};

const ReducerCategories = (state = CATEGORIES_INITIAL_STATE, action) => {
	if(action.type === 'GET_CATEGORIES') {
		return mapKeys(action.payload, 'categoryId');
	} else if(action.type === 'REMOVE_CATEGORIES') {
		return CATEGORIES_INITIAL_STATE;
	}

	return state;
}

/* ----------  Exports  ---------- */

export default ReducerCategories;