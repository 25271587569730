/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { map, filter, isEqual, find, get, mapValues, indexOf } from 'lodash';

// UUID
import uuid from 'uuid/v4';

// jQuery
import $ from 'jquery';

// jsTree
import 'jstree';

// Constants
import History from '../../../Constants/History';

/* ----------  Sequence Panel Tree  ---------- */

class SequencePanelTree extends React.Component {
	constructor(props) {
		super(props);

		this.cntTreeRef = React.createRef();
		this.treeRef = React.createRef();

		this.treeApi = null;

		this.prefix = 'sqPanelTree';
	}

	componentDidMount() {
		this.initTree();
	}

	shouldComponentUpdate = (nextProps) => {
		const nextList = nextProps.list;
		const { list } = this.props;

		return !isEqual(list, nextList);
	}

	componentDidUpdate() {
		this.renderNodes();
	}

	getPrefixedId = id => `${ this.prefix }_${ id }`

	getNode = x => {
		if(!this.treeApi) return false;
		return this.treeApi.get_node(x);
	}

	getTemplate = (node, type) => {
		switch (type) {
			case 'group': return this.getGroupTemplate(node);
			case 'leg': return this.getLegTemplate(node);
			default: return node.text;
		}
	}

	getGroupTemplate = node => (`
		<span class="anchor-inner">
			<span class="anchor-icon">
				<i class="material-icons">folder_open</i>
			</span>
			<span class="anchor-title text-truncate">${ node.text }</span>
			<span class="anchor-actions">
				<span
					class="atn atn-context atnContext"
					data-uk-tooltip="{pos: top}"
					title="More Options">
					<i class="material-icons">&#xE5D4;</i>
				</span>
			</span>
		</span>
	`)
	
	getLegTemplate = node => (`
		<span class="anchor-inner">
			<span class="anchor-icon">
				<i class="material-icons">&#xE87B;</i>
			</span>
			<span class="anchor-title text-truncate">${ node.text }</span>
			<span class="anchor-actions">
				<span
					class="atn atn-add atnPlayLeg"
					data-uk-tooltip="{pos: top}"
					title="Play Leg">
					<i class="material-icons">&#xE038;</i>
				</span>
				<span
					class="atn atn-context atnContext"
					data-uk-tooltip="{pos: top}"
					title="More Options">
					<i class="material-icons">&#xE5D4;</i>
				</span>
			</span>
		</span>
	`)

	getNodeData = item => {
		const { list } = this.props;
		
		let parent = '#';
		
		const groups = filter(list, listItem => listItem.nodeType === 'group');
		const group = find(groups, { name: item.group });

		if(group) parent = this.getPrefixedId(group.groupId);
		
		const node = {
			id: this.getPrefixedId(item.listId),
			parent,
			
			text: this.getTemplate(item, item.nodeType),
			position: 'last',
			
			a_attr: {
				class: 'jsTreeAnchor'
			},

			li_attr: {
				text: item.text,
				type: item.nodeType,
				
				'data-id': item.listId,
			},

			state: {
				opened: true,
				disabled: false,
				selected: false,
			}
		}

		if(item.nodeType === 'leg') {
			node.li_attr['data-leg-id'] = item.objectId;
		}

		return node;
	}

	initTree = () => {
		const tree = this.treeRef.current;
		
		$(tree).jstree({
			core : {
				themes: {
					url: false,
					dir: false,
					dots: false,
					icons: false,
					stripes: false,
					variant: false,
					ellipsis: false,
				},

				dblclick_toggle: false,

				check_callback: (operation, node, parent, position, more) => {
					if(operation === 'copy_node') return false;
					if(operation === 'move_node') {
						let isValid = true;
						const isRoot = parent.id === '#';
						const parentAttrs = parent.li_attr || {};
						const nodeAttrs = node.li_attr || {};

						if(more && more.dnd) {
							if(!isRoot && parentAttrs.type !== 'group') isValid = false;
							if(!isRoot && nodeAttrs.type === 'group') isValid = false;
						}

						return isValid;
					}

					return true;
				},
			},

			plugins: ['dnd'],
		});

		this.treeApi = $(tree).jstree(true);

		$(tree).off('ready.jstree').on('ready.jstree', () => {
			this.renderNodes();
			this.handleNodeActions();
		});
		
		$(tree).off('move_node.jstree').on('move_node.jstree', (e, data) => {
			const { node } = data;

			this.takeHistorySnapshot(node.li_attr.text, History.action.MOVE);
			this.handleTree();
		});

		$(document).on('dnd_start.vakata.sqTree', (e, data) => {
			const dndTree = data.data.origin.element;
			if(!$(dndTree).hasClass('sqTree')) return;

			const { data: { nodes } } = data;
			if(nodes.length > 1) this.updateHistorySnapshotState({ bulk: true, snapshotId: uuid() });
		}).on('dnd_stop.vakata.sqTree', (e, data) => {
			const dndTree = data.data.origin.element;
			if(!$(dndTree).hasClass('sqTree')) return;
			
			this.updateHistorySnapshotState({ bulk: false, snapshotId: null });
		});
	}

	// Tree Actions

	moveLeg = (legId, parent, position = 'last') => {
		const id = this.getPrefixedId(legId);
		const node = this.getNode(id);

		this.treeApi.move_node(node, parent, position);
	}
	
	deleteNode = nodeId => {
		const id = this.getPrefixedId(nodeId);
		const node = this.getNode(id);

		if(node) {
			this.takeHistorySnapshot(node.li_attr.text, History.action.DELETE);
			this.treeApi.delete_node(node);
			this.handleTree();
		}
	}

	handleTree = callback => {
		const options = {
			flat: true,
			no_state: true,
		}
		
		const data = this.treeApi.get_json(null, options);

		const treeData = map(data, (node, i) => {
			const attrs = node.li_attr || {};
			const parent = this.getNode(node.parent);
			const parentAttrs = parent ? parent.li_attr : {};
			
			const obj = {
				listId: attrs['data-id'],
				text: attrs.text,
				type: attrs.type,
				position: i,
			}

			if(attrs.type === 'leg') {
				obj.groupId = parentAttrs ? parentAttrs['data-id'] : '';
				obj.group = parentAttrs ? parentAttrs.text : '';
			}

			if(attrs.type === 'group') {
				const group = this.getNode(node.id);
				const childLegNodes = map(group.children, child => this.getNode(child));

				obj.legIds = map(childLegNodes, childNode => childNode.li_attr['data-leg-id']);
			}

			return obj;
		});

		const filteredLegs = filter(treeData, item => item.type === 'leg');
		const filteredGroups = filter(treeData, item => item.type === 'group');
		
		const legs = map(filteredLegs, (leg, i) => ({
			groupId: leg.groupId,
			group: leg.group,

			nodeId: leg.listId,
			text: leg.text,

			position: i,
		}));

		const groups = map(filteredGroups, group => {
			group.groupId = group.listId;
			return group;
		});

		this.props.updateLegList(legs, groups, callback);
	}

	handleNodeActions = () => {
		const tree = this.treeRef.current;

		$(tree).off('click.nodeCtx').on('click.nodeCtx', '.atnContext', e => {
			e.preventDefault();
			e.stopPropagation();

			const { list, onLegCtxClick, onGroupCtxClick } = this.props;

			const $this = $(e.currentTarget);
			const $parent = $this.closest('li');
			const nodeId = $parent.attr('id');
			const node = this.getNode(nodeId);
			
			if(node) {
				const attrs = node.li_attr || {};
				const listItem = get(list, attrs['data-id']);

				if(attrs.type === 'leg') {
					onLegCtxClick(e, listItem);
				} else if(attrs.type === 'group') {
					onGroupCtxClick(e, listItem);
				}
			}
		});
		
		$(tree).off('click.nodePlay').on('click.nodePlay', '.atnPlayLeg', e => {
			e.preventDefault();
			e.stopPropagation();

			const { playLeg } = this.props;

			const $this = $(e.currentTarget);
			const $parent = $this.closest('li');
			const nodeId = $parent.attr('id');
			const node = this.getNode(nodeId);
			
			if(node) {
				const legNodeId = node.li_attr['data-id'];
				playLeg(legNodeId);
			}
		});
	}

	takeHistorySnapshot = (title, action) => {
		this.props.takeHistorySnapshot(title, action);
	}

	renameNode = data => {
		const id = this.getPrefixedId(data.nodeId);
		const treeNode = this.getNode(id);
		
		if(treeNode) {
			treeNode.li_attr.id = id;
			treeNode.li_attr.text = data.text;

			this.updateNode(treeNode);
		}
	}

	updateHistorySnapshotState = (updates, callback) => {
		this.props.updateHistorySnapshotState(updates, callback);
	}

	updateNode = (treeNode, callback) => {
		const node = {
			type: treeNode.li_attr.type,
			text: treeNode.li_attr.text,
		}

		const template = this.getTemplate(node, node.type);
		const rename = this.treeApi.rename_node(treeNode, template);

		if(rename) {
			this.updateHTML(treeNode);
			this.handleTree(() => {
				if(callback) callback(treeNode);
			})
		}
	}

	updateHTML = node => {
		setTimeout(() => {
			const $node = document.getElementById(node.id);
			
			if($node) {
				const $nodeAnchor = $node.querySelector('.jsTreeAnchor');
				const liAttrs = node.li_attr;
				const aAttrs = node.a_attr;
				const excludedAttrs = ['class'];

				mapValues(liAttrs, (value, name) => {
					if($node) {
						if(indexOf(excludedAttrs, name) === -1) {
							$node.setAttribute(name, value);
						}
					}
				});

				mapValues(aAttrs, (value, name) => {
					if($nodeAnchor) {
						if(indexOf(excludedAttrs, name) === -1) {
							$nodeAnchor.setAttribute(name, value);
						}
					}
				});
			}
		}, 100);
	}

	renderNodes = () => {
		const { list } = this.props;

		const data = map(list, item => item);

		map(data, item => {
			const node = this.getNodeData(item);
			const existingNode = this.getNode(node.id);

			if(!existingNode) {
				this.treeApi.create_node(node.parent, node, 'last');
			}
		});
	}

	render() {
		return (
			<div className="editor-list panel-list p-h-0" ref={ this.cntTreeRef }>
				<div className="editor-list-inner list-inner">
					<div className="editor-tree sqTree" ref={ this.treeRef }/>
				</div>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

SequencePanelTree.defaultProps = {
	bulk: false,
	
	list: {},
	legs: [],
}

SequencePanelTree.propTypes = {
	// bulk: PropTypes.bool,

	// legs: PropTypes.arrayOf(PropTypes.object),
	list: PropTypes.objectOf(PropTypes.object),

	playLeg: PropTypes.func.isRequired,
	updateLegList: PropTypes.func.isRequired,
	onLegCtxClick: PropTypes.func.isRequired,
	onGroupCtxClick: PropTypes.func.isRequired,
	takeHistorySnapshot: PropTypes.func.isRequired,
	updateHistorySnapshotState: PropTypes.func.isRequired,
}

/* ----------  Exports  ---------- */

export default SequencePanelTree;
