/* ----------  Imports  ---------- */

// Axios
import axios from 'axios';

// Lodash
import { isEmpty } from 'lodash';

// Global Constants
import Global from './../../../Constants/Global';

/* ----------  Scripts  ---------- */

const GetCategories = (success, fail) => (dispatch, getState) => {
	const state = getState();

	if(isEmpty(state.categories)) {
		const url = `${ Global.API_BASE_URL }/category`;

		axios.get(url).then(response => {
			const { status, result } = response.data;

			dispatch({
				type: 'GET_CATEGORIES',
				payload: result.results
			});

			if(success) success(status);
		}).catch(error => {
			const { response } = error;

			if(fail) {
				if(response) {
					fail(response.data.status, response.data.error.reasons);
				} else {
					fail(false, []);
				}
			}
		});
	}
}

/* ----------  Exports  ---------- */

export default GetCategories;