/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Media Recorder
import Recorder from 'opus-recorder';

// Moment
import moment from 'moment';

// Path
import path from 'path';

/* ----------  Scripts  ---------- */

class LegAudio extends React.Component {
	constructor(props) {
		super(props);

		this.playerRef = React.createRef();

		this.recorder = null;
		this.gumStream = null;

		this.recorderTime = null;

		this.audioBuffers = [];
	}

	componentDidMount() {
		this.loadRecorder();
	}

	loadRecorder = () => {
		this.recorder = new Recorder({
			streamPages: true,
			resampleQuality: 2,
			numberOfChannels: 2,
			bufferLength: 1024 * 8,
			encoderSampleRate: 16000,
			encoderPath: path.resolve(__dirname, '/assets/workers/opus-recorder/', 'encoderWorker.min.js')
		});

		this.recorder.ondataavailable = buffer => {
			// const currentTime = this.recorderTime.diff(moment.utc(), 'millisecond');
			// console.log('Recording: PUSHED AVAILABLE DATA', { currentTime });
			this.audioBuffers.push(buffer);
		};

		this.recorder.loadWorker();
	}

	startRecording = () => {
		this.recorder.start().catch(() => {
			this.recorder = null;
		});
		
		this.recorder.onstart = () => {
			this.recorderTime = moment.utc();
			console.log('Recording: STARTED');
		}
	}

	stopRecording = callback => {
		if(!this.recorder) {
			if(callback) callback();
			return;
		}
		
		this.recorder.onstop = () => {
			console.log('Recording: STOP');
			
			const blob = new Blob(this.audioBuffers, {
				type: 'audio/ogg'
			});
			
			const reader = new FileReader();
			reader.readAsDataURL(blob); 
			reader.onloadend = () => {
				const totalTime = Math.abs(this.recorderTime.diff(moment.utc(), 'millisecond'));
				const audioBase64 = reader.result;
				const audioBlobUrl = URL.createObjectURL(blob);

				const localAudio = new Audio(audioBlobUrl);
				localAudio.addEventListener('loadeddata', () => {
					const data = {
						totalTime,
						url: audioBlobUrl,
						src: audioBase64,
						duration: Math.floor(localAudio.duration * 1000),
					}

					console.log('Recording Converted', data);
	
					if(callback) callback(data);
				});

				this.recorder = null;
				this.gumStream = null;
				this.recorderTime = null;
			}
		}
		
		this.recorder.stop();
	}
	
	render() {
		const { id } = this.props;

		return (
			<audio id={ id } ref={ this.playerRef }>
				<track kind="captions"/>
			</audio>
		);
	}
}

/* ----------  Prop Types  ---------- */

LegAudio.propTypes = {
	id: PropTypes.string.isRequired
}

/* ----------  Exports  ---------- */

export default LegAudio;
