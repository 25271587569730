/* ----------  Imports  ---------- */

// Lodash
import { filter, mapKeys, indexOf } from 'lodash';

// Global
import Global from './../../../Constants/Global';

/* ----------  Scripts  ---------- */

const ReducerCurrencies = (state = {}, action) => {
	if(action.type === 'GET_CURRENCIES') {
		const data = filter(action.payload.results, currency => {
			if(indexOf(Global.VALID_CURRENCIES, currency.currency) !== -1) return currency;
			return null;
		});

		// const currencies = map(data, currency => ({ ...currency, ...Global.CURRENCIES[currency.currency] }));
		const response = mapKeys(data, 'currency');

		return { ...state, ...response };
	}

	return state;
}

/* ----------  Exports  ---------- */

export default ReducerCurrencies;