/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { startCase, isEmpty } from 'lodash';

// UUID
import uuid from 'uuid/v4';

// Slugify
import slugify from 'slugify';

// Tiny Color
import tinycolor from 'tinycolor2';

/* ----------  Scripts  ---------- */

class CategoryLabel extends React.Component {
	isDark = color => {
		const tcTheme = tinycolor(color);
		const isDark = tcTheme.getBrightness() < 180;

		return isDark;
	}

	render() {
		const { category } = this.props;

		if(isEmpty(category)) return <div className="d-none"/>;
		
		const categorySlug = category.name ? slugify(category.name) : '';
		const key = `${ categorySlug } - ${ uuid() }`;
		const theme = category.theme;

		return <span className={ `label label label-${ theme }` } key={ key }>{ startCase(category.name) }</span>;
	}
}

/* ----------  PropTypes  ---------- */

CategoryLabel.propTypes = {
	category: PropTypes.shape().isRequired
}

/* ----------  Exports  ---------- */

export default CategoryLabel;
