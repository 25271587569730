/* ----------  Imports  ---------- */

// Prop Types
// import PropTypes from 'prop-types';

// Google Map
import GoogleMap from './../../Google/GoogleMap';

// Notify
import Notify from './../../../Helpers/Notify';
import MapHelper from './../../../Helpers/MapHelper';

/* ----------  Scripts  ---------- */

class LegMap extends GoogleMap {
	constructor(props) {
		super(props);
	}

	componentWillMount() {
		if(!this.props.center) this.getGeoLocation();
	}

	componentDidMount() {
		this.load(() => {
			console.log('MAP LOADED!');
			const { center } = this.props;
			
			this.handleMap();
			this.setCenter(center);
			this.updateAndSync(center);
		});
	}

	handleMap = () => {
		this.map.addListener('click', e => {
			const { lat, lng } = e.latLng;
			const center = {
				lat: lat(),
				lng: lng()
			}

			MapHelper.checkPanorama(center, () => {
				this.updateAndSync(center);
			}, () => {
				Notify.error('Oops! Streetview not available at this location.');
			});
		});
	}
}

/* ----------  Prop Types  ---------- */

LegMap.propTypes = {}

/* ----------  Exports  ---------- */

export default LegMap;
