/* ----------  Imports  ---------- */

// Lodash
import { map } from 'lodash';

/* ----------  Reducer Playback  ---------- */

const TOUR_PLAYBACK_LEGS_INITIAL_STATE = [];

const ReducerTourPlaybackLegs = (state = TOUR_PLAYBACK_LEGS_INITIAL_STATE, action) => {
	if(action.type === 'GET_TOUR_PLAYBACK_LEGS') {
		const { payload } = action;
		const legs = map(payload, leg => leg);
		return [ ...state, ...legs ];
	} else if(action.type === 'TOUR_PLAYBACK_REMOVE_LEGS') {
		return TOUR_PLAYBACK_LEGS_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerTourPlaybackLegs;
