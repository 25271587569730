/* ----------  Scripts  ---------- */

const TOUR_PROFILE_DETAILS_INITIAL_STATE = {};

const ReducerMyToursTreesTourProfileDetails = (state = TOUR_PROFILE_DETAILS_INITIAL_STATE, action) => {
	if(action.type === 'MYTOURS_TREES_GET_TOUR_PROFILE_DETAILS') {
		return action.payload;
	} else if(action.type === 'MYTOURS_TREES_REMOVE_TOUR_PROFILE_DETAILS') {
		return TOUR_PROFILE_DETAILS_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMyToursTreesTourProfileDetails;