/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Redux
import { connect } from 'react-redux';

// Stripe
import { loadStripe } from '@stripe/stripe-js';

// React Stripe
import { Elements } from '@stripe/react-stripe-js';

// Layouts
import AdminLayout from '../Layouts/AdminLayout';

// Billing Components
import RegistrationCheckout from '../Components/Billing/RegistrationCheckout';

/* ----------  View Registration Billing  ---------- */

const stripePromise = loadStripe('pk_test_JJ1eMdKN0Hp4UFJ6kWXWO4ix00jtXzq5XG');

class ViewRegistrationBilling extends React.Component {
	componentDidMount() {}

	render() {
		return (
			<AdminLayout view="registrationBilling">
				<Elements stripe={ stripePromise }>
					<RegistrationCheckout/>
				</Elements>
			</AdminLayout>
		);
	}
}

/* ----------  Redux Scripts  ---------- */

const mapStateToProps = state => ({
	user: state.user
});

/* ----------  Exports  ---------- */

export default connect(mapStateToProps, null)(ViewRegistrationBilling);
