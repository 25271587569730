/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { map, get } from 'lodash';

// Slick
import SlickCarousel from './../../../Components/Utils/SlickCarousel';

// Preloader
import Preloader from './../../../Components/Common/Preloader';

// Components
import RelatedLegItem from './RelatedLegItem';

/* ----------  Scripts  ---------- */

class RelatedLegSection extends React.Component {
	handleLeg = (data, leg) => this.props.handleLeg(data, leg)
	
	handleContext = (type, leg, target, event) => this.props.handleContext(type, leg, target, event)

	panRelatedLeg = leg => this.props.panRelatedLeg(leg)

	renderSponsorsSlick = () => {
		const { legs, loading, loaded } = this.props;

		if(loading) return <Preloader loading={ loading } minimal center/>

		if(!loaded && !legs.length) return <span className="content-placeholder">Please select any leg.</span>;
		
		if(loaded && !legs.length) return <span className="content-placeholder">No legs found.</span>;

		return (
			<SlickCarousel
				slickType="marketplace"
				slickClass="content-slick"
				slickControlsClass="content-slick-nav">
				{ this.renderSponsorsLegs() }
			</SlickCarousel>
		);
	}

	renderSponsorsLegs = () => {
		const { legs, legsData, type, languages } = this.props;

		return map(legs, leg => {
			const legObj = get(legsData, leg.legId);
			const language = get(languages, leg.language);

			return (
				<RelatedLegItem
					key={ leg.legId }
					leg={ legObj }
					type={ type }
					language={ language }
					handleLeg={ this.handleLeg }
					handleContext={ this.handleContext }
					panRelatedLeg={ this.panRelatedLeg }/>
			);
		});
	}

	render() {
		return (
			<div className="panel-section">
				<div className="section-header">
					<h4>{ this.props.title }</h4>
				</div>
				<div className="section-content load">
					{ this.renderSponsorsSlick() }
				</div>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

RelatedLegSection.defaultProps = {
	legs: [],
	legsData: {}
}

RelatedLegSection.propTypes = {
	handleLeg: PropTypes.func.isRequired,
	handleContext: PropTypes.func.isRequired,
	panRelatedLeg: PropTypes.func.isRequired,

	loaded: PropTypes.bool.isRequired,
	loading: PropTypes.bool.isRequired,

	type: PropTypes.string.isRequired,
	
	languages: PropTypes.objectOf(PropTypes.object).isRequired,

	title: PropTypes.string.isRequired,
	legs: PropTypes.arrayOf(PropTypes.object),
	legsData: PropTypes.objectOf(PropTypes.object)
}

/* ----------  Exports  ---------- */

export default RelatedLegSection;
