/* ----------  Scripts  ---------- */

const UNGROUPED_TOURS_NODES_INITIAL_STATE = [];

const ReducerMyToursTreesUngroupedToursNodes = (state = UNGROUPED_TOURS_NODES_INITIAL_STATE, action) => {
	if(action.type === 'MYTOURS_TREES_GET_UNGROUPED_TOURS_NODES') {
		return action.payload.results;
	} else if(action.type === 'MYTOURS_TREES_REMOVE_UNGROUPED_TOURS_NODES') {
		return UNGROUPED_TOURS_NODES_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMyToursTreesUngroupedToursNodes;