/* ----------  Imports  ---------- */

// Lodash
import { map } from 'lodash';

// jQuery
const $ = window.$;

/* ----------  Scripts  ---------- */

const ToggleHelper = {
	update: toggle => $(toggle).iCheck('update'),

	updateUI: parent => {
		parent = parent || document;

		const toggles = parent.querySelectorAll('[data-md-toggle]');

		if(toggles.length) map(toggles, toggle => ToggleHelper.update(toggle));
	}
}

/* ----------  Exports  ---------- */

export default ToggleHelper;