/* eslint-disable no-use-before-define */

/* ----------  Imports  ---------- */

// React
import React, { useState, useEffect } from 'react';

// React Redux
import { connect } from 'react-redux';

// React Router DOM
// import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Axios
import axios from 'axios';

// Lodash
import { isEmpty } from 'lodash';

// Helpers
import Notify from '../../../Helpers/Notify';
import StripeHelper from '../../../Helpers/StripeHelper';

// Services
import Storage from '../../../Services/Storage';

// Constants
import Global from '../../../Constants/Global';

// Alerts
import Alerts from '../../Fuse/Alerts';
import UIkit from 'uikit';

// jQuery
const $ = window.$;

/* ----------  Modals User Notifications  ---------- */

const ModalsUserNotifications = ({ stripe }) => {
	const [fetchingStripeSessionId, setFetchingStripeSessionId] = useState(false);

	useEffect(() => {
		handleModalEvents();
	}, []);

	const onRetryPaymentClick = e => {
		e.preventDefault();
		retryPayment();
	}
	
	const onForcePaymentClick = e => {
		e.preventDefault();
		retryPayment();
	}

	const onSuccessCloseClick = e => {
		e.preventDefault();
		showLoginForm();
	}

	const handleModalEvents = () => {
		$('.ukModalRSubscription').off('hide.uk.modal').on('hide.uk.modal', () => {
			window.history.pushState({}, document.title, '/');
		});
	}

	const showLoginForm = () => {
		const modal = UIkit.modal('.ukModalRSubscription');
		modal.hide();

		const btnLogin = document.querySelector('.btnAuthLogin');
		btnLogin.click();

		setTimeout(() => {
			const txtLogin = document.querySelector('#txtLoginEmail');
			txtLogin.focus();
		}, 500);
	}

	const retryPayment = async () => {
		setFetchingStripeSessionId(true);

		try {
			const userId = Storage.getUserId();
			if(!stripe || !userId) {
				showLoginForm();
				
				Notify.info('Please login again to complete the registration process.');
				return;
			}

			const response = await axios.post(`${ Global.API_BASE_URL }/users/stripe/session`, { userId });
			const { result } = response.data;
			const { registrationStripeSessionId } = result;
			
			console.log(response);

			if(!registrationStripeSessionId || isEmpty(registrationStripeSessionId)) {
				Notify.danger('Something went wrong. Please try again.');
				return;
			}

			StripeHelper.checkoutViaSessionId(stripe, registrationStripeSessionId);
		} catch (error) {
			Notify.danger('Something went wrong. Please try again.');
		} finally {
			setFetchingStripeSessionId(false);
		}
	}

	const renderRSubscriptionSuccessModal = () => {
		return (
			<div className="uk-modal ukModalRSubscription" id="modalUserNotificationRSubscriptionSuccess">
				<div className="uk-modal-dialog">
					<div className="uk-modal-header">
						<h2 className="text-dark">Registration Payment Successful</h2>
					</div>
					<div className="uk-modal-body">
						<p className="m-0">Thank you for registration. Please login and enjoy touring.</p>
					</div>
					<div className="uk-modal-footer">
						<button className="md-btn md-btn-flat md-btn-flat-default uk-modal-close" onClick={ onSuccessCloseClick }>Close</button>
					</div>
				</div>
			</div>
		)
	}
	
	const renderRSubscriptionFailModal = () => {
		return (
			<div className="uk-modal ukModalRSubscription" id="modalUserNotificationRSubscriptionFail">
				<div className="uk-modal-dialog">
					<div className="uk-modal-header">
						<h2 className="text-dark">Registration Payment Failed</h2>
					</div>
					<div className="uk-modal-body">
						<Alerts data={['Please try again by clicking below button.']} listClassName="list-unstyled" show/>
						{/* <p className="m-b-20">Sorry for the inconvenience.</p> */}
					</div>
					<div className="uk-modal-footer">
						<button className={ `md-btn md-btn-flat md-btn-flat-primary ${ fetchingStripeSessionId ? 'disabled' : '' }` } onClick={ onRetryPaymentClick }>
							{ fetchingStripeSessionId ? 'Please Wait...' : 'Retry Payment' }
						</button>
					</div>
				</div>
			</div>
		)
	}
	
	const renderRSubscriptionForceModal = () => {
		return (
			<div className="uk-modal ukModalRSubscription" id="modalUserNotificationRSubscriptionForce">
				<div className="uk-modal-dialog">
					<div className="uk-modal-header">
						<h2 className="text-dark">Registration Payment Pending</h2>
					</div>
					<div className="uk-modal-body">
						<Alerts data={['Please complete the registration before proceeding further.']} type="info" listClassName="list-unstyled" show/>
						{/* <p className="m-b-20">Sorry for the inconvenience.</p> */}
					</div>
					<div className="uk-modal-footer">
						<button className={ `md-btn md-btn-flat md-btn-flat-primary ${ fetchingStripeSessionId ? 'disabled' : '' }` } onClick={ onForcePaymentClick }>
							{ fetchingStripeSessionId ? 'Please Wait...' : 'Complete Registration' }
						</button>
					</div>
				</div>
			</div>
		)
	}

	return (
		<div className="uk-modals-user-notifications">
			{ renderRSubscriptionSuccessModal() }
			{ renderRSubscriptionFailModal() }
			{ renderRSubscriptionForceModal() }
		</div>
	);
}

/* ----------  Props Validation  ---------- */

ModalsUserNotifications.defaultProps = {
	userProfile: {},
	stripe: {}
}

ModalsUserNotifications.propTypes = {
	// userProfile: PropTypes.shape(),
	stripe: PropTypes.shape(),
}

/* ----------  Redux Scripts  ---------- */

const mapStateToProps = state => ({
	userProfile: state.user.profile
});

/* ----------  Exports  ---------- */

export default connect(mapStateToProps, null)(ModalsUserNotifications);
