/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// React Redux
import { connect } from 'react-redux';

// Redux
import { bindActionCreators } from 'redux';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { debounce } from 'lodash';

// Constants
import Global from './../Constants/Global';

// Layouts
import AdminLayout from './../Layouts/AdminLayout';

// Container
import Messages from './../Containers/Messages/';

// Actions
import GetLanguages from './../Redux/Actions/Languages/GetLanguages';

// Modals
import Modal from './../Components/UIkit/Modal';
import Alerts from './../Components/UIkit/Alerts';
import Input from './../Components/UIkit/Input';

// Helpers
import InputHelper from './../Helpers/InputHelper';

// Preloader
import Preloader from './../Components/Common/Preloader';

/* ----------  Scripts  ---------- */

class ViewMessages extends React.Component  {
	constructor(props) {
		super(props);

		this.messages = React.createRef();
		this.reportModal = React.createRef();

		this.initialReportState = {
			type: '',
			refId: '',
			message: '',
		}

		this.state = {
			sendingReport: false,
			reportErrors: [],
			report: this.initialReportState
		}

		this.requestLanguages = debounce(this.doRequestLanguages, 500);
	}

	componentWillMount() {
		this.getLanguages();

		document.title = `${ Global.APP_NAME } :: Messages`;
	}

	onReportModalShow = () => {
		
	}

	onReportModalHide = () => {
		this.setState({
			report: {
				message: '',
				type: '',
				refId: ''
			}
		});
	}

	getLanguages = () => {
		this.requestLanguages();
	}

	doRequestLanguages = () => {
		this.props.getLanguages();
	}

	handleReport = (type, refId) => {
		const modal = this.reportModal.current;

		this.setState({
			report: {
				message: '',
				type,
				refId
			}
		}, modal.show());
	}

	handleReportMessage = e => {
		this.setState({
			report: {
				...this.state.report,
				message: e.currentTarget.value
			}
		});
	}

	submitReport = e => {
		e.preventDefault();

		const isValid = this.validateReport();
		
		if(isValid) {
			const modal = this.reportModal.current;
			const messages = this.messages.current.wrappedInstance;

			this.setState({ sendingReport: true });

			messages.sendReport(this.state.report, () => {
				this.setState({ sendingReport: false });
				modal.hide();
			});
		}
	}

	validateReport = () => {
		let isValid = true;

		const errors = [];
		const { report: { message } } = this.state;

		if(InputHelper.isEmpty(message)) {
			isValid = false;
			errors.push('Please enter valid reason.');
		}

		this.setState({ reportErrors: errors });

		return isValid;
	}

	render() {
		const { languages } = this.props;
		const { report, sendingReport, reportErrors } = this.state;

		return (
			<AdminLayout view="messages">
				<div className="tpl-fuse">
					<div className="messages-container" id="cntMessages">
						<div className="page-layout carded full-width" id="chat">
							<div className="page-content">
								<Messages 
									languages={ languages }
									handleReport={ this.handleReport }
									ref={ this.messages }/>
							</div>
						</div>
					</div>
				</div>
				<div className="modals-container" data-modals-holder>
					<Modal
						popup="report" 
						onHide={ this.onReportModalHide }
						ref={ this.reportModal }>
						<form>
							<div className="uk-modal-dialog">
								<div className="uk-modal-header">
									<h3 className="uk-modal-title modalTitle">
										Report { report.type === 'conversation' ? 'Conversation' : 'Contact' }
									</h3>
								</div>
								<div className="uk-modal-body">
									<Input
										title="Reason"
										name="message"
										id="txtReportMessage"
										value={ report.message }
										onChange={ this.handleReportMessage }/>

									<Alerts show={ reportErrors.length > 0 } data={ reportErrors }/>
								</div>
								<div className="uk-modal-footer">
									<div className="footer-preloader m-r-10">
										<Preloader size={ 20 } loading={ sendingReport } relative minimal/>
									</div>
									<Link to="#closeReportModal" className={ `md-btn md-btn-flat md-btn-flat-default md-btn-wave waves-effect waves-button uk-modal-close ${ sendingReport ? 'disabled' : '' }` }>
										Cancel
									</Link>
									<button type="submit" className={ `md-btn md-btn-flat md-btn-flat-success md-btn-wave waves-effect waves-button ${ sendingReport ? 'disabled' : '' }` } onClick={ this.submitReport }>
										Send
									</button>
								</div>
							</div>
						</form>
					</Modal>
				</div>
			</AdminLayout>
		);
	}
};

/* ----------  Prop Types  ---------- */

ViewMessages.propTypes = {
	getLanguages: PropTypes.func.isRequired,

	languages: PropTypes.objectOf(PropTypes.object).isRequired,
}

/* ----------  Redux Scripts  ---------- */

const mapStateToProps = state => ({
	languages: state.languages,
});

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		getLanguages: GetLanguages,
	}, dispatch)
);

/* ----------  Exports  ---------- */

export default connect(mapStateToProps, mapDispatchToProps)(ViewMessages);
