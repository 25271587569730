/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Views
import ViewHomepage from './../Views/ViewHomepage';
import ViewMyTours from './../Views/ViewMyTours';
import ViewMessages from './../Views/ViewMessages';
import ViewMarketplace from './../Views/ViewMarketplace';

import ViewProfile from './../Views/ViewProfile';
import ViewProfileEdit from './../Views/ViewProfileEdit';

import ViewMisc from './../Views/ViewMisc';

import ViewRegistrationBilling from './../Views/ViewRegistrationBilling';

// Errors
import Error404 from './../Errors/Error404';

/* ----------  Scripts  ---------- */

class Routes extends React.Component {
	componentDidMount() {}

	renderProfileEdit = () => {
		const { token } = this.props;
		if(!token) return <Redirect to="/home"/>;

		return <ViewProfileEdit/>;
	}

	renderProfile = () => {
		const { token } = this.props;
		if(!token) return <Redirect to="/home"/>;

		return <ViewProfile/>;
	}

	renderMyTours = () => {
		const { token } = this.props;
		if(!token) return <Redirect to="/home"/>;

		return <ViewMyTours/>;
	}

	renderMarketplace = () => {
		const { token } = this.props;
		if(!token) return <Redirect to="/home"/>;

		return <ViewMarketplace/>;
	}
	
	renderRegistrationBilling = () => {
		const { token } = this.props;
		if(!token) return <Redirect to="/home"/>;

		return <ViewRegistrationBilling/>;
	}

	render() {
		return (
			<Router>
				<Switch>
					<Route path="/" component={ ViewHomepage } exact/>
					<Route path="/home" component={ ViewHomepage }/>
					
					<Route path="/profile/edit" component={ this.renderProfileEdit }/>
					<Route path="/profile" component={ this.renderProfile }/>

					<Route path="/mytours" component={ this.renderMyTours }/>
					
					<Route path="/marketplace" component={ this.renderMarketplace }/>
					<Route path="/messages" component={ ViewMessages }/>
					
					<Route path="/misc" component={ ViewMisc }/>
					
					<Route path="/billing/registration" component={ this.renderRegistrationBilling }/>
					
					<Route component={ Error404 }/>
				</Switch>
			</Router>
		);
	}
}

/* ----------  Prop Types  ---------- */

Routes.defaultProps = {
	token: '',
}

Routes.propTypes = {
	token: PropTypes.string,
}

/* ----------  Exports  ---------- */

export default Routes;
