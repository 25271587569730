/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Redux
import { connect } from 'react-redux';

// Redux
import { bindActionCreators } from 'redux';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { debounce, map, get, isEmpty } from 'lodash';

// UIkit
import UIkit from 'uikit';

// moment
import moment from 'moment';

// Preloader
import Preloader from './../../Components/Common/Preloader';

// Utils
import Scrollbar from './../../Components/Utils/Scrollbar';
import ProfilePicture from './../../Components/Utils/ProfilePicture';

// UIKit
import Input from './../../Components/UIkit/Input';

// Helpers
import Notify from './../../Helpers/Notify';

// Actions
import GetConversations from './../../Redux/Actions/Conversation/GetConversations';
import RemoveConversations from './../../Redux/Actions/Conversation/RemoveConversations';

import GetConversationMessages from './../../Redux/Actions/Conversation/Messages/GetConversationMessages';
import RemoveConversationMessages from './../../Redux/Actions/Conversation/Messages/RemoveConversationMessages';
import SendMessage from './../../Redux/Actions/Conversation/Messages/SendMessage';

import GetUnreadConversationsCount from './../../Redux/Actions/Conversation/Read/GetUnreadConversationsCount';
import MarkConversationRead from './../../Redux/Actions/Conversation/Read/MarkConversationRead';
import MarkAllConversationsRead from './../../Redux/Actions/Conversation/Read/MarkAllConversationsRead';

// Notifications
import notifications from './../../EventEmitters/Notifications';

/* ----------  Scripts  ---------- */

class Conversation extends React.Component {
	constructor(props) {
		super(props);

		this.drp = null;
		this.loaded = false;

		this.cntConversationsRef = React.createRef();

		this.messageFormInitialState = {
			conversationId: '',
			message: '',
		}

		this.state = {
			nodeId: '',
			nodeType: 0,
			view: 'BASE',
			loaded: false,
			active: false,
			recipient: null,
			external: false,
			messageForm: this.messageFormInitialState
		}

		this.requestConversations = debounce(this.doRequestConversations, 100);
		this.requestConversationMessages = debounce(this.doRequestConversationMessages, 100);
		
		this.sendMessage = debounce(this.doSendMessage, 100);

		this.requestUnreadCount = debounce(this.doRequestUnreadCount, 100);
		this.markConversationRead = debounce(this.doMarkConversationRead, 100);
		this.markAllConversationsRead = debounce(this.doMarkAllConversationsRead, 100);
	}

	componentWillMount() {
		this.getUnreadCount();
	}

	componentDidMount() {
		this.loaded = true;
		
		this.initNotifications();
		this.initDropdown();
	}

	componentWillUnmount() {
		this.loaded = false;
	}

	onConversationTypeClick = (e, nodeType) => {
		e.preventDefault();

		this.setState({
			nodeType,
		}, this.getConversations);
	}

	onConversationClick = (e, conversationId) => {
		e.preventDefault();

		this.getConversationMessages(conversationId);
	}

	onBackToBase = e => {
		e.preventDefault();

		this.showBaseList();
	}

	onBackToConversations = e => {
		e.preventDefault();

		this.showConversationList();
	}

	onSubmitMessage = e => {
		e.preventDefault();

		this.submitMessage();
	}

	onAllConversationsDismiss = e => {
		e.preventDefault();
		e.stopPropagation();

		this.markAllConversationsRead(this.state.nodeType);
	}

	getUnreadCount = () => {
		this.requestUnreadCount();
	}

	getViewClass = () => {
		const { view } = this.state;

		switch(view) {
			case 'BASE': return 'base';
			case 'CONVERSATION_MESSAGES': return 'conversation-messages';
			case 'CONVERSATIONS': return 'conversations';
			default: return 'list';
		}
	}

	getViewTitle = () => {
		const { nodeType } = this.state;

		switch(nodeType) {
			case 50: return 'Homepage Conversations';
			case 60: return 'Marketplace Conversations';
			default: return 'Conversations';
		}
	}

	getConversations = () => {
		this.requestConversations();
	}

	getConversationMessages = conversationId => {
		this.requestConversationMessages(conversationId);
	}

	getWidth = () => {
		const { view } = this.state;

		switch(view) {
			case 'BASE': return 360;
			case 'CONVERSATION_MESSAGES': return 800;
			case 'CONVERSATIONS': return 500;
			default: return 360;
		}
	}

	doRequestUnreadCount = () => {
		this.props.getUnreadCount();
	}

	doRequestConversations = () => {
		this.removeConversations();

		const { nodeId, nodeType } = this.state;

		const params = {}

		if(nodeId) params.nodeId = nodeId;
		if(nodeType) params.nodeType = nodeType;

		if(this.loaded) this.setState({ loading: true });

		this.props.getConversations(params, () => {
			if(this.loaded) this.setState({ loading: false, view: 'CONVERSATIONS' });
		}, () => {
			if(this.loaded) this.setState({ loading: false, view: 'BASE' });
		});
	}

	doRequestConversationMessages = conversationId => {
		this.removeConversationMessages();

		const { user: { profile }, conversationListData } = this.props;

		const params = {
			conversationId,
			startIndex: -1,
		}

		if(this.loaded) this.setState({ loading: true });

		this.props.getConversationMessages(params, () => {
			const conversation = get(conversationListData, conversationId);

			if(conversation && this.loaded) {
				const recipient = conversation.owner.userId !== profile.userId ? conversation.owner : conversation.user;

				this.updateMessageForm({ conversationId });
				
				if(conversation.unread) this.markConversationRead(conversationId);
				
				this.setState({ loading: false, view: 'CONVERSATION_MESSAGES', recipient });
			} else {
				this.setState({ loading: false, view: 'CONVERSATIONS', recipient: null });
			}
		}, () => {
			if(this.loaded) this.setState({ loading: false, view: 'CONVERSATIONS', recipient: null });
		});
	}

	doMarkConversationRead = conversationId => {
		const data = {
			conversationId,
		}

		this.props.markConversationRead(data, () => {
			this.getUnreadCount();
		});
	}

	doMarkAllConversationsRead = (nodeType) => {
		this.props.markAllConversationsRead({ nodeType }, () => {
			this.getUnreadCount();
		});
	}

	doSendMessage = () => {
		const { messageForm } = this.state;

		this.props.sendMessage(messageForm, this.props.user);
		this.updateMessageForm({ message: '' });
	}

	initNotifications = () => {
		notifications.on('refreshCount', () => {
			this.getUnreadCount();
		});
	}

	initDropdown = () => {
		const notifs = this.cntConversationsRef.current;

		this.drp = UIkit.dropdown(notifs, {
			mode: 'click',
			pos: 'bottom-left',
		});

		this.drp.on({
			'beforeshow.uk.dropdown': () => {
				if(this.loaded) {
					this.setState({ active: true }, () => {
						if(this.state.external) {
							this.getConversations(this.state.nodeType);
						} else {
							this.showBaseList();
						}
					});
				}
			},

			'hide.uk.dropdown': () => {
				if(this.loaded) {
					this.setState({
						nodeId: '',
						nodeType: 0,
						active: false,
						external: false,
					});
				}
			}
		});
	}

	handleMessageTxt = e => {
		const { name, value } = e.currentTarget;

		this.updateMessageForm({ [name]: value });
	}

	removeConversations = () => {
		this.props.removeConversations();
	}

	removeConversationMessages = () => {
		this.props.removeConversationMessages();
	}

	show = (nodeId, nodeType) => {
		if(this.drp && this.loaded) {
			this.setState({
				nodeId,
				nodeType,
				external: !isEmpty(nodeId),
			}, () => this.drp.show());
		}
	}

	hide = () => {
		if(this.drp) this.drp.hide();
	}

	showBaseList = () => {
		if(this.loaded) {
			this.setState({
				nodeId: '',
				view: 'BASE',
				loading: false,
				recipient: null,
			}, this.removeConversations);
		}
	}

	showConversationList = () => {
		if(this.loaded) {
			this.setState({
				loading: false,
				recipient: null,
				view: 'CONVERSATIONS',
				messageForm: this.messageFormInitialState,
			}, this.removeConversationMessages);
		}
	}

	submitMessage = () => {
		const { messageForm: { message } } = this.state;

		if(message) {
			this.sendMessage();
		} else {
			Notify.error('Please enter a valid message.', 5000);
		}
	}

	updateMessageForm = (updates, callback) => {
		const { messageForm } = this.state;

		if(this.loaded) {
			this.setState({
				messageForm: {
					...messageForm,
					...updates
				}
			}, () => {
				if(callback) callback();
			});
		}
	}

	renderConversationMessageItems = () => {
		const { user: { profile }, messageList, messageListData } = this.props;

		if(!messageList.length) return <li className="text-center p-t-15 m-0 uk-text-small"><em className="uk-text-muted">No conversations found!</em></li>;

		return map(messageList, item => {
			const message = get(messageListData, item.messageId);
			const { contact } = message;
			
			const timezone = moment.parseZone(moment().format()).format('Z');
			const mTime = moment(message.time).utcOffset(timezone);

			const userClass = contact.contactId === profile.userId ? 'chat_message_right' : '';

			return (
				<div className={ `chat_message_wrapper ${ userClass }` } key={ message.messageId }>
					<div className="chat_user_avatar">
						<ProfilePicture className="md-user-image md-list-addon-avatar img-circle" title={ contact.name } src={ contact.photo }/>
					</div>
					<ul className="chat_message">
						<li>
							<p>
								{ message.message }
								<span className="chat_message_time">{ mTime.format('DD MMM YY kk:mm') }</span>
							</p>
						</li>
					</ul>
				</div>
			);
		});
	}

	renderConversationItems = () => {
		const { user: { profile }, conversationList, conversationListData } = this.props;

		if(!conversationList.length) return <li className="text-center p-t-15 m-0 uk-text-small"><em className="uk-text-muted">No conversations found!</em></li>;

		return map(conversationList, item => {
			const conversation = get(conversationListData, item.conversationId);

			const recipient = conversation.owner.userId !== profile.userId ? conversation.owner : conversation.user;

			return (
				<li className="m-r-0" key={ conversation.conversationId } role="presentation" tabIndex="-1" onClick={ e => this.onConversationClick(e, conversation.conversationId) }>
					<div className="md-card-dropdown md-list-action-dropdown" aria-haspopup="true" aria-expanded="false">
						<i className="md-icon material-icons">more_vert</i>
						<div className="uk-dropdown uk-dropdown-small uk-dropdown-bottom" aria-hidden="true">
							<ul className="uk-nav">
								<li><Link to="#ctx">Add to chat</Link></li>
								<li><Link to="#ctx" className="uk-text-danger">Remove</Link></li>
							</ul>
						</div>
					</div>
					<div className="md-list-addon-element">
						<span className="element-status element-status-success"/>
						<ProfilePicture className="md-user-image md-list-addon-avatar img-circle" title={ recipient.name } src={ recipient.photo }/>
					</div>
					<div className="md-list-content">
						<div className="d-none md-list-action-placeholder"/>
						<span className={ `md-list-heading ${ conversation.unread ? 'text-primary' : '' }` }>{ recipient.name } <span className={ !conversation.unread ? 'd-none' : '' }>({ conversation.unread })</span></span>
						<span className="uk-text-small uk-text-muted uk-text-truncate">Inquiry: <strong>{ conversation.node.title }</strong></span>
					</div>
				</li>
			);
		});
	}

	renderConversationMessages = () => {
		const { recipient, messageForm } = this.state;

		return (
			<React.Fragment>
				<div className="md-card-toolbar">
					<div className="md-card-toolbar-actions hidden-print">
						<Link to="#back" className="md-icon material-icons" onClick={ this.onBackToConversations }>close</Link>
					</div>
					<h3 className="md-card-toolbar-heading-text large">
						<span className="uk-text-muted">Conversation with</span> { recipient.name }
					</h3>
				</div>
				<div className="md-card-content padding-reset">
					<div className="chat_box_wrapper">
						<div className="chat_box touchscroll chat_box_colors_b">
							{ this.renderConversationMessageItems() }
						</div>
						<form className="chat_submit_box" onSubmit={ this.onSubmitMessage }>
							<div className="uk-input-group">
								<Input
									name="message"
									id="txtConversationMessage"
									label="Send Message"
									value={ messageForm.message }
									onChange={ this.handleMessageTxt }/>

								<span className="uk-input-group-addon">
									<button type="submit" className={ `md-fab md-fab-primary md-fab-small ${ !messageForm.message ? 'disabled' : '' }` }>
										<i className="material-icons md-24">send</i>
									</button>
								</span>
							</div>
						</form>
					</div>
				</div>
			</React.Fragment>
		);
	}

	renderConversations = () => {
		const title = this.getViewTitle();

		return (
			<React.Fragment>
				<div className="md-card-toolbar">
					<div className="md-card-toolbar-actions">
						<Link to="#all" className="md-icon material-icons md-card-toggle" onClick={ this.onAllConversationsDismiss }>done_all</Link>
					</div>
					<div className="md-card-toolbar-actions pull-left m-r-10">
						<Link to="#back" className="md-icon material-icons md-card-close" onClick={ this.onBackToBase }>arrow_back</Link>
					</div>
					<h3 className="md-card-toolbar-heading-text">{ title }</h3>
				</div>
				<Scrollbar className="md-card-content">
					<div className="md-card-content-inner">
						<div className="content-list notification-list">
							<ul className="md-list md-list-addon md-list-outside m-0">
								{ this.renderConversationItems() }
							</ul>
						</div>
					</div>
				</Scrollbar>
			</React.Fragment>
		);
	}

	renderBaseView = () => {
		const { unreadCount } = this.props;

		return (
			<Scrollbar className="md-card-content">
				<div className="md-card-content-inner">
					<div className="content-list base-list">
						<ul className="md-list md-list-addon m-0">
							<li className="m-r-0">
								<Link to="#legs" className="d-block" onClick={ e => this.onConversationTypeClick(e, 50) }>
									<div className="md-list-addon-element">
										<i className="md-list-addon-icon material-icons m-0">map</i>
									</div>
									<div className="md-list-content">
										<h4 className="md-list-heading text-dark">Tour Conversation ({ unreadCount.tours })</h4>
										<span className="uk-text-small uk-text-muted">Your tour conversation updates.</span>
									</div>
								</Link>
							</li>
							<li className="m-r-0">
								<Link to="#legs" className="d-block" onClick={ e => this.onConversationTypeClick(e, 60) }>
									<div className="md-list-addon-element">
										<i className="md-list-addon-icon material-icons m-0">local_activity</i>
									</div>
									<div className="md-list-content">
										<h4 className="md-list-heading text-dark">Leg Conversation ({ unreadCount.legs })</h4>
										<span className="uk-text-small uk-text-muted">Your leg conversation updates.</span>
									</div>
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</Scrollbar>
		);
	}

	renderContent = () => {
		const { loading } = this.state;
		const { view } = this.state;

		if(loading) return <Preloader loading={ loading } center minimal/>;

		if(view === 'CONVERSATIONS') {
			return this.renderConversations();
		} else if(view === 'CONVERSATION_MESSAGES') {
			return this.renderConversationMessages();
		}

		return this.renderBaseView();
	}

	render() {
		const { unreadCount } = this.props;
		const view = this.getViewClass();
		const width = this.getWidth();

		return (
			<li className="messages" ref={ this.cntConversationsRef }>
				<Link to="#div" className="user_action_icon" id="btnConversationsNotifs">
					<i className="material-icons md-24 md-light">messages</i>
					<span className={ `uk-badge bg-warning ${ !unreadCount.total ? 'd-none' : '' }` }>{ unreadCount.total }</span>
				</Link>
				<div className="uk-dropdown uk-dropdown-minimal uk-dropdown-conversations uk-dropdown-xlarge" id="messagesNotifs" style={ { width, minWidth: width } }>
					<div className={ `md-card md-card-${ view }` }>
						{ this.renderContent() }
					</div>
				</div>
			</li>
		);
	}
}

/* ----------  Prop Types  ---------- */

Conversation.defaultProps = {
	unreadCount: {
		total: 0,
		tours: 0,
		legs: 0
	},

	user: {
		profile: {
			userId: ''
		}
	}
}

Conversation.propTypes = {
	getConversations: PropTypes.func.isRequired,
	removeConversations: PropTypes.func.isRequired,

	getConversationMessages: PropTypes.func.isRequired,
	removeConversationMessages: PropTypes.func.isRequired,
	
	sendMessage: PropTypes.func.isRequired,
	
	getUnreadCount: PropTypes.func.isRequired,
	markConversationRead: PropTypes.func.isRequired,
	markAllConversationsRead: PropTypes.func.isRequired,

	unreadCount: PropTypes.shape({}),
	
	user: PropTypes.shape({}),

	conversationList: PropTypes.arrayOf(PropTypes.object).isRequired,
	conversationListData: PropTypes.objectOf(PropTypes.object).isRequired,

	messageList: PropTypes.arrayOf(PropTypes.object).isRequired,
	messageListData: PropTypes.objectOf(PropTypes.object).isRequired,
}

/* ----------  Redux Scripts  ---------- */

const mapStateToProps = state => ({
	user: state.user,

	conversationList: state.conversation.list,
	conversationListData: state.conversation.listData,

	messageList: state.conversation.messages.list,
	messageListData: state.conversation.messages.listData,
	
	unreadCount: state.conversation.unread,
});

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		getConversations: GetConversations,
		removeConversations: RemoveConversations,

		getConversationMessages: GetConversationMessages,
		removeConversationMessages: RemoveConversationMessages,
		
		sendMessage: SendMessage,
		
		getUnreadCount: GetUnreadConversationsCount,
		markConversationRead: MarkConversationRead,
		markAllConversationsRead: MarkAllConversationsRead,
	}, dispatch)
);

/* ----------  Exports  ---------- */

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Conversation);
