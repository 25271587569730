/* ----------  Imports  ---------- */

// React
import React from 'react';

// PropTypes
import PropTypes from 'prop-types';

// Lodash
import { map } from 'lodash';

/* ----------  Scripts  ---------- */

class Alerts extends React.Component {
	componentDidMount() {}

	renderList = () => {
		const { data } = this.props;

		return map(data, error => <li key={ Math.random() }>{ error }</li>);
	}

	render() {
		const { show, data, type } = this.props;

		if(!show || !data.length) return '';

		return (
			<div className={ `uk-alert uk-alert-${ type }` }>
				<ul className="uk-list uk-list-bullet uk-list-light">
					{ this.renderList() }
				</ul>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

Alerts.defaultProps = {
	data: [],
	
	show: false,

	type: 'danger',
}

Alerts.propTypes = {
	show: PropTypes.bool,
	
	type: PropTypes.string,

	data: PropTypes.arrayOf(PropTypes.string),
}

/* ----------  Exports  ---------- */

export default Alerts;
