/* ----------  Imports  ---------- */

// Axios
import axios from 'axios';

// UUID
import uuid from 'uuid/v4';

// Globals
import Global from './../../../../../Constants/Global';

/* ----------  Scripts  ---------- */

const SendConversationMessage = (data, user, success, fail) => dispatch => {
	// const url = `${ Global.DUMMY_API_BASE_URL }/N1FDmYJvH`;
	const url = `${ Global.API_BASE_URL }/messages/send`;

	axios.post(url, data).then(response => {
		const { status } = response.data;

		if(success) success(status);
	}).catch(error => {
		const { response } = error;

		if(fail) {
			if(response) {
				fail(response.data.status, response.data.error.reasons);
			} else {
				fail(false, []);
			}
		}
	});

	dispatch({
		type: 'MESSAGES_SEND_CONVERSATION_MESSAGE',
		payload: {
			messageId: uuid(),
			data,
			user
		},
	});
}

/* ----------  Exports  ---------- */

export default SendConversationMessage;