/* ----------  Imports  ---------- */

// React
import React, { Fragment } from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { get, map, isEmpty, isEqual, filter, indexOf, keys, find, max, differenceBy, last } from 'lodash';

// moment
import moment from 'moment';

// UUID
import uuid from 'uuid/v4';

// Screenfull
import Screenfull from 'screenfull';

// Howler
import { Howl } from 'howler';

// Tiny Color
import tinycolor from 'tinycolor2';

// Tippy
import tippy, { followCursor  } from 'tippy.js';
import 'tippy.js/dist/tippy.css';

// Axios
import axios from 'axios';

// Global Contants
import Global from './../../Constants/Global';

// Streetview
import PlaybackStreetview from './PlaybackStreetview';

// Map
import PlaybackMap from './PlaybackMap';

// Players
import LegAudio from './Audio';

// Player Components
import ImageItem from '../Player/ImageItem';

// Media Player Components
import MediaInfo from '../TourMediaPlayer/MediaInfo';

// Slider
import Slider from './../Utils/Slider';

// Progress
import Progress from './../Utils/Progress';

// Timer
// import Timer from './../Utils/Timer';
import Scrollbar from '../Utils/Scrollbar';
import ResizablePanel from './../Utils/ResizablePanel';

// Helpers
import Utils from '../../Helpers/Utils';
import NoAnimationAlert from '../Utils/NoAnimationAlert';
// import AudioHelper from './../../Helpers/AudioHelper';

// jQuery
const $ = window.$;

/* ----------  Scripts  ---------- */

let LAST_MUSIC_PLAYTIME;

let LAST_RECORD = {};
let TOTAL_RECORDS = 0;
let LAST_PLAY_TIME = null;

const NEW_AUDIO = {
	src: null,
	volume: 1,
	duration: 0,
}

const NEW_RECORDING = {
	records: [],
	playTime: 0,
	frequency: 100,
	
	final: {
		position: {},
		pov: {},
	},

	initial: {
		position: {},
		pov: {},
	},
}

class LegPlayer extends React.Component {
	constructor(props) {
		super(props);

		this.google = window.google.maps;

		this.isLoaded = false;
		this.isPlayInitialized = false;

		this.mapApi = null;
		this.streetviewApi = null;
		
		this.playTimer = null;
		this.audioPlayer = null;
		this.musicTimer = null;
		this.musicPlayer = null;
		this.musicLoaded = false;

		this.audioId = null;
		this.musicId = null;

		this.tooltip = null;

		this.recordingStartTime = null;
		
		this.progressInterval = null;

		this.mapRef = React.createRef();
		this.timerRef = React.createRef();
		this.sliderRef = React.createRef();
		this.stvCursorRef = React.createRef();
		this.streetviewRef = React.createRef();
		
		this.cntMap = React.createRef();
		this.cntPlayer = React.createRef();
		this.cntStreetviewPano = React.createRef();
		this.cntLegOverlaysRef = React.createRef();

		this.audioRef = React.createRef();
		this.music = React.createRef();
		
		this.musicVolumeRef = React.createRef();
		this.audioVolumeRef = React.createRef();
		this.globalVolumeSliderRef = React.createRef();

		this.legProgressComponentsScrollbarRef = React.createRef();
		this.legProgressComponentsRef = React.createRef();

		this.globalTime = 0;
		this.globalRecordingTime = 0;
		
		this.lastMusicVolume = 0;
		this.lastAudioVolume = 0;
		this.lastGlobalVolume = 0;

		this.recordingTimer = null;
		this.recordingMoment = null;

		this.howlMusicPlayers = {};
		this.howlAudioPlayers = {};
		// this.musicPlayer = null;

		this.captionsTimer = null;

		this.initialMusicState = {
			time: 0,
			musicId: null,
			loaded: false,
			paused: false,
			playing: false,
			activeIndex: -1,
			musicIndex: -1,
			sequenceIndex: null,
		}
		
		this.initialImageState = {
			imageId: null,
			activeIndex: -1,
			imageIndex: -1,
		}

		this.initialTimelineState = {
			width: -1,
			zoomScale: 300,
			initialWidth: -1,
		}

		const { progress } = props.tour;

		this.state = {
			type: props.type,
			recordType: 'INITIAL',
			playbackFrequency: 100,
			audioRecord: this.props.audioDevice,
			
			lastPlayedMusic: -1,
			lastPlayedImage: -1,
			
			visibleImages: [],

			mouse: {
				top: 0,
				left: 0,
				absTop: 0,
				absLeft: 0,
				isVisible: false,
			},

			player: {
				isSaving: false,
				isPaused: false,
				isStopped: false,
				isPlaying: false,
				isRecording: false,
				initialized: false,
				initializedOnce: false,
				activeLegIndex: progress && progress.legIndex ? progress.legIndex : 0,
				activeRecordKey: progress && progress.recordKey ? progress.recordKey : 0,
				activeRecordIndex: progress && progress.recordIndex ? progress.recordIndex : 0,
			},

			progress: {
				active: false,
				saving: false,
			},

			slider: {
				active: false,
				value: 0,
			},

			frames: {
				total: 0,
				current: 0
			},

			stvStreetview: {
				fullscreen: false
			},

			stvMap: {
				active: false,
				minimize: true,
			},

			image: this.initialImageState,
			music: this.initialMusicState,

			geoThumbnail: {
				url: '',
				loading: false,
			},

			timeline: this.initialTimelineState,

			captions: []
		}

		this.recordingDefaults();
	}

	componentDidMount() {
		this.isLoaded = true;

		this.handleBody();
		this.handleKeyboard();
		this.watchFullscreen();
		this.bindGoogleComponents();
		this.updateInitialPosition();

		this.loadAudio();
		this.loadMusic();

		$(document).trigger('legplayer.mounted', [this.state.type]);

		console.log('MOUNTED: LEG PLAYER');

		setTimeout(() => {
			this.props.getRemainingTourPlaybackLegs(() => {
				this.loadAudio();
			});
		}, 2000);
	}

	componentWillUnmount() {
		this.resetZoomedTimeline();
		this.resetRecordProgress(null);
		this.clearPlayer();

		this.reset();
		this.unbindEvents();
		this.resetToDefaults();

		this.isLoaded = false;
		this.isPlayInitialized = false;

		$(document).trigger('legplayer.unmounted', [this.state.type]);

		console.log('UNMOUNTED: LEG PLAYER');
	}

	// Actions

	onClose = e => {
		e.preventDefault();

		this.props.hidePanel();
		this.resetRecordProgress(null);
	}

	onMapShow = e => {
		e.preventDefault();

		this.showStvMap();
	}

	onMapHide = e => {
		e.preventDefault();

		this.hideStvMap();
	}

	onZoomIn = e => {
		e.preventDefault();

		const zoom = this.getZoom();

		this.setZoom(zoom + 0.3);
	}

	onZoomOut = e => {
		e.preventDefault();

		const zoom = this.getZoom();

		this.setZoom(zoom - 0.3);
	}

	onExtStreetview = e => {
		e.preventDefault();

		const pov = this.getPov();
		const pano = this.getPano();

		const url = `https://www.google.com/maps/@?api=1&map_action=pano&pano=${ pano }&heading=${ pov.heading }&pitch=${ pov.pitch }&fov=${ pov.zoom }`;
		window.open(url, '_blank');

		this.pause();
	}

	onExtMap = e => {
		e.preventDefault();

		const position = this.getPosition();

		const url = `https://www.google.com/maps/search/?api=1&query=${ position.lat },${ position.lng }`;
		window.open(url, '_blank');

		this.pause();
	}

	onMapExpand = e => {
		e.preventDefault();

		this.expandMap();
	}

	onMapContract = e => {
		e.preventDefault();

		this.contractMap();
	}

	onFullscreen = e => {
		e.preventDefault();

		const tourplayer = document.querySelector('#tourplayer');

		Screenfull.toggle(tourplayer);
	}

	onMusicNavigate = (e, dir) => {
		e.preventDefault();

		const { lastPlayedMusic, music: { activeIndex } } = this.state;

		let index = -1;
		switch(dir) {
			case 'prev':
				index = activeIndex === lastPlayedMusic ? lastPlayedMusic - 1 : lastPlayedMusic;
				break;
			case 'next': index = lastPlayedMusic + 1; break;
			default: break;
		}

		if(index >= 0) this.handleMusicNavigate(index);
	}
	
	onImageNavigate = (e, dir) => {
		e.preventDefault();

		const { lastPlayedImage, image: { activeIndex } } = this.state;

		let index = -1;
		switch(dir) {
			case 'prev':
				index = activeIndex === lastPlayedImage ? lastPlayedImage - 1 : lastPlayedImage;
				break;
			case 'next': index = lastPlayedImage + 1; break;
			default: break;
		}

		if(index >= 0) this.handleImageNavigate(index);
	}
	
	onLegNavigate = (e, dir) => {
		e.preventDefault();

		const { player } = this.state;

		let index = -1;
		switch(dir) {
			case 'prev': index = player.activeLegIndex - 1; break;
			case 'next': index = player.activeLegIndex + 1; break;
			default: break;
		}

		if(index >= 0) this.handleNavigate(index);
	}

	// Playback

	onRecord = e => {
		e.preventDefault();

		this.startRecording();
	}

	onRecordStop = e => {
		e.preventDefault();

		this.stopRecording();
	}

	onToggleAudioRecord = e => {
		e.preventDefault();

		const { audioRecord } = this.state;

		this.setState({
			audioRecord: !audioRecord
		});
	}

	onPlay = e => {
		e.preventDefault();

		this.startPlay();
	}

	onPause = e => {
		e.preventDefault();

		this.pause();
	}

	onStop = e => {
		e.preventDefault();

		this.stop();
	}

	onReplay = e => {
		e.preventDefault();

		this.replay();
	}

	onMouseTouch = e => {
		e.preventDefault();

		const { mouse } = this.state;

		this.updateMouseState({ isVisible: !mouse.isVisible });
	}

	onToursPlayerLoad = () => {
		const { totalFrames } = this.props;
		this.updateFrames({ total: totalFrames });
	}

	onGlobalVolumeChange = (e, ui) => {
		this.changeGlobalVolume(ui.value);
	}

	// Timeline Zooming

	onReCenterTimeline = e => {
		e.preventDefault();
		this.reCenterTimeline();
	}

	onZoomInTimeline = e => {
		e.preventDefault();
		this.zoomInTimeline();
	}
	
	onZoomOutTimeline = e => {
		e.preventDefault();
		this.zoomOutTimeline();
	}

	getPosition = () => this.streetviewApi.getPosition()
	
	getPano = () => this.streetviewApi.getPano()

	getPov = () => this.streetviewApi.getPov()
	
	getZoom = () => this.streetviewApi.getZoom()

	getSkippedRecords = legIndex => {
		let skippedRecords = 0;
		const { markers } = this.props

		for(let i = 0; i < legIndex; i += 1) {
			const marker = markers[i];

			if(marker.index < legIndex) {
				skippedRecords += marker.records;
			}
		}

		return skippedRecords;
	}

	getMsppx = () => {
		const { playTime } = this.props;

		const ref = this.legProgressComponentsRef.current;
		if(!ref) return 50;

		const { width } = ref.getBoundingClientRect();
		return Utils.getMsppx(playTime, width);
	}

	getPixels = milliseconds => {
		const msppx = this.getMsppx();
		return Math.floor(milliseconds / msppx.toFixed(2));
	}
	
	getMilliseconds = pixels => {
		const msppx = this.getMsppx();
		return Math.floor(pixels * msppx.toFixed(2));
	}

	getActiveRecordKey = (recordIndex, legIndex = this.props.activeLegIndex) => {
		const { legs } = this.props;
		const leg = legs[legIndex];
		const { recording: { records } } = leg;
		const recordKeys = keys(records);

		let recordKey = recordIndex;
		map(recordKeys, key => {
			const i = parseInt(key, 10);
			if(i <= recordIndex) {
				recordKey = i;
			}
		});

		return recordKey;
	}

	getRecordMetaViaIndex = (sliderIndex, time) => {
		const { markers } = this.props;
		const { playbackFrequency } = this.state;

		let recordIndex = sliderIndex;

		if(time) {
			recordIndex = time / playbackFrequency;
		}

		let index = 0;
		let records = 0;

		for(let i = 0; i < markers.length; i += 1) {
			records += markers[i].records;

			if(records > recordIndex) {
				index = i;
				break;
			}
		}

		const skippedRecords = this.getSkippedRecords(index);
		recordIndex -= skippedRecords;

		return { recordIndex, index };
	}
	
	setPosition = (position, callback) => {
		if(isEmpty(position)) return false;

		let center = position;

		if(position && position.length && Array.isArray(position)) {
			center = {
				lat: position[0],
				lng: position[1],
			}
		}

		if(this.isLoaded && this.streetviewApi) this.streetviewApi.setPosition(center, callback);

		return true;
	}

	setPov = (pov, callback) => {
		if(this.isLoaded && this.streetviewApi) this.streetviewApi.setPov(pov, callback);
	}

	setZoom = zoom => {
		if(this.isLoaded && this.streetviewApi) this.streetviewApi.setZoom(zoom);
	}

	setStreetviewOptions = options => {
		if(this.isLoaded && this.streetviewApi) this.streetviewApi.setOptions(options);
	}

	isMixer = () => {
		const { type } = this.state;
		return (type === 'mixer') || (type === 'imageMixer') || (type === 'musicMixer');
	}
	
	isMediaMixer = () => {
		const { type } = this.state;
		return (type === 'imageMixer') || (type === 'musicMixer');
	}

	bindGoogleComponents = () => {
		if(this.isMediaMixer()) return;

		this.mapApi = this.mapRef.current;
		this.streetviewApi = this.streetviewRef.current;

		if(this.mapApi && this.streetviewApi) this.streetviewApi.bindMap(this.mapApi.map);
	}

	handleGeoThumbnail = () => {
		const progressRef = document.querySelector('[data-progress-components]');
		if(!progressRef || this.tooltip) return;

		const slider = progressRef.querySelector('#stvProgressSlider');

		let mouseX = 0;
		let timer = null;
		let mouseTimer = null;

		const { frames } = this.state;
		
		const handleToolTip = () => {
			const { geoThumbnail } = this.state;
			
			if(!geoThumbnail.url) return;

			axios.get(geoThumbnail.url, {
				responseType: 'blob'
			}).then(response => {
				const image = new Image();
				
				image.height = 70;
				image.width = 100;
				image.style.display = 'block';
				image.src = URL.createObjectURL(response.data);
				// image.src = response;

				this.tooltip.setContent(image);

				this.updateGeoThumbnailState({ url: '' });
			}).catch(() => {
				this.tooltip.setContent('Error!');
			});
		}

		this.tooltip = tippy(slider, {
			arrow: true,
			maxWidth: 100,
			offset: '0, 10',
			// appendTo: progressRef.parentNode,
			content: 'Loading...',
			plugins: [ followCursor ],
			followCursor: 'horizontal',
			flipOnUpdate: true,

			onHidden: () => {
				this.tooltip.setContent('Loading...');
			}
		});

		$(slider).off('mousemove.tooltip').on('mousemove.tooltip', e => {
			if(mouseTimer) window.clearTimeout(mouseTimer);

			this.tooltip.setContent('Loading...');

			mouseTimer = window.setTimeout(() => {
				$(slider).trigger('mousemoveend', [e]);
			}, 100);
		});

		$(slider).off('mousemoveend.tooltip').on('mousemoveend.tooltip', (e, de) => {
			if(timer) clearTimeout(timer);
			
			timer = setTimeout(() => {
				const { clientX } = de;
	
				mouseX = clientX || mouseX;
				const rect = slider.getBoundingClientRect();
				const left = mouseX - rect.left;

				const value = (100 / rect.width) * left;
				const sliderIndex = parseInt((frames.total / 100) * value, 10);
				const meta = this.getRecordMetaViaIndex(sliderIndex);
				const recordIndex = this.getActiveRecordKey(meta.recordIndex, meta.index);
				const url = this.props.getGeoThumbnailUrl(meta.index, recordIndex);
				
				this.updateGeoThumbnailState({ url: url || '' }, handleToolTip);
			}, 1000);
		});
		
		$(slider).off('mouseout.tooltip').on('mouseout.tooltip', () => {
			if(timer) clearTimeout(timer);
			if(mouseTimer) clearTimeout(mouseTimer);

			this.updateGeoThumbnailState({ url: '' });

			slider.removeEventListener('mouseout', () => {});
		});
	}

	handleProgressComponents = () => {
		const progressRef = document.querySelector('[data-progress-components]');
		if(!progressRef) return;
		
		const rect = progressRef.getBoundingClientRect();
		this.updateTimelineState({ initialWidth: rect.width });
	}

	handleBody = () => {
		const googleComponents = document.querySelectorAll('.googleComponent');

		map(googleComponents, el => {
			el.addEventListener('click', () => {
				if(this.state.player.isPlaying) this.pause();
			}, true);

			el.addEventListener('mousewheel', () => {
				if(this.state.player.isPlaying) this.pause();
			}, true);

			el.addEventListener('DOMMouseScroll', () => {
				if(this.state.player.isPlaying) this.pause();
			}, true);
			
			el.addEventListener('mousedown', () => {
				if(this.state.player.isPlaying) this.pause();
			}, true);
		});
	}

	handleKeyboard = () => {
		$('body').off('keyup').on('keyup', e => {
			const { type, player } = this.state;
			const { audioDevice, isCursor } = this.props;

			const activeElement = document.activeElement;
			const inputs = ['input', 'select', 'button', 'textarea'];
			const hasInputFocus = activeElement && indexOf(inputs, activeElement.tagName.toLowerCase()) >= 0;

			if(hasInputFocus) return;

			if(e.keyCode === 32) {
				e.preventDefault();
				
				if(type === 'playback') {
					if(!player.isPlaying) {
						this.startPlay();
					} else {
						this.pause();
					}
				}
			}
			
			if(e.keyCode === 65) {
				if((type === 'record') && audioDevice) {
					const { audioRecord } = this.state;
					this.setState({
						audioRecord: !audioRecord
					});
				}
			}
			
			if(e.keyCode === 67) {
				if((type === 'record') || isCursor) {
					const { mouse } = this.state;
					this.updateMouseState({ isVisible: !mouse.isVisible });
				}
			}
		});
	}

	handleNavigate = index => {
		const { totalLegs, fromIndex, toIndex } = this.props;

		if((fromIndex !== -1) && (toIndex !== -1)) {
			if((index < fromIndex) || (index > toIndex)) return;
		}

		if((index >= 0) && (index < totalLegs)) {
			const skippedRecords = this.getSkippedRecords(index);

			this.reset(() => {
				this.startPlay(0, index);
				this.updateFrames({ current: skippedRecords });
			}, true);
		}
	}
	
	handleMusicNavigate = index => {
		const { playbackFrequency } = this.state;

		const { musicSequence } = this.props;
		const playTimes = keys(musicSequence);

		if((index >= 0) && (index < playTimes.length)) {
			const playTime = playTimes[index];
			const frame = Math.ceil(playTime / playbackFrequency);

			this.seekPlayer(frame);
		}
	}
	
	handleImageNavigate = index => {
		const { playbackFrequency } = this.state;

		const { images } = this.props;

		if((index >= 0) && (index < images.length)) {
			const image = images[index];
			const frame = Math.ceil(image.startTime / playbackFrequency);

			this.seekPlayer(frame);
		}
	}

	handleMarker = (e, marker) => {
		e.preventDefault();

		const skippedRecords = this.getSkippedRecords(marker.index);

		this.pause();
		this.reset(() => {
			this.startPlay(0, marker.index);
			this.updateFrames({ current: skippedRecords });
		}, true);
	}

	// Map Resize

	initResizable = () => {
		const cntPlayer = this.cntPlayer.current;
		if(cntPlayer) cntPlayer.load();
	}

	clearPlayer = (soft = false, callback) => {
		this.globalTime = 0;

		this.hideStvCursor();
		// this.updateInitialPosition();
		this.resetRecordProgress(null);
		
		this.stopAudio();
		this.stopImage();
		this.stopCaptions();
		
		this.resetMusic();
		
		this.setState({ lastPlayedMusic: -1 }, () => {
			if(!soft) this.updateFrames({ current: 0 }, this.props.onLegStop);
			LAST_PLAY_TIME = null;
			if(callback) callback();
		});
	}

	destroyResizable = () => {
		const cntPlayer = this.cntPlayer.current;
		if(cntPlayer) cntPlayer.destroy();
	}

	expandMap = callback => {
		const cntMap = this.cntMap.current;
		const { stvMap } = this.state;

		$(cntMap).velocity({
			left: 0,
			bottom: 0,
			width: '100%',

			complete: () => {
				this.setState({
					stvMap: {
						...stvMap,
						minimize: false
					}
				});
			}
		}, 300, () => {
			this.resizeStreetview();
			setTimeout(this.initResizable, 500);
			if(callback) callback();
		});
	}

	contractMap = callback => {
		const cntMap = this.cntMap.current;
		const { stvMap } = this.state;

		$(cntMap).velocity({
			left: 15,
			bottom: 20,
			width: 300,
			height: 180,

			complete: () => {
				this.setState({
					stvMap: {
						...stvMap,
						minimize: true
					}
				});
			}
		}, 300, () => {
			this.destroyResizable();
			this.resizeStreetview();
			if(callback) callback();
		});
	}

	resizeStreetview = () => {
		const { stvMap } = this.state;
		const cntStreetviewPano = this.cntStreetviewPano.current;

		if(!stvMap.minimize) {
			const cntMap = this.cntMap.current;
			const cntPlayer = document.querySelector('#tourplayer');

			const playerHeight = cntPlayer.clientHeight;
			const mapHeight = cntMap.clientHeight;
			const streetviewHeight = playerHeight - mapHeight;

			$(cntStreetviewPano).velocity({
				height: streetviewHeight
			}, 300);
		} else {
			$(cntStreetviewPano).velocity({
				height: '100%'
			}, 300);
		}
	}

	// Playback

	play = (index = this.state.player.activeRecordIndex) => {
		const { leg } = this.props;

		if(!this.isLoaded) return false;

		if(!isEmpty(leg)) {
			const { audio, recording: { totalRecords, records, frequency } } = leg;
			const { playbackFrequency } = this.state;
			const { activeLegIndex, endTime } = this.props;

			const recordsViaAudio = (!isEmpty(audio) && audio.duration) ? Math.floor(audio.duration / 100) : 0;

			const audioTime = this.audioPlayer ? this.audioPlayer.seek() : -1;
			const framesViaAudio = Number.isFinite(audioTime) && audioTime > 0 ? (audioTime * 1000) : 0;
			const frameViaAudio = Math.ceil(framesViaAudio / playbackFrequency);

			// const i = index;
			const i = frameViaAudio >= index ? frameViaAudio : index;
			const iIndex = Math.ceil(playbackFrequency / frequency);
			const length = recordsViaAudio || totalRecords || records.length;

			// console.log({ i, index, frameViaAudio });

			const previousFrames = this.getSkippedRecords(activeLegIndex);
			const currentFrame = previousFrames + i;
			const time = currentFrame * playbackFrequency;

			if((endTime >= 0) && (time > endTime)) {
				this.stop();
				this.props.hidePanel();

				return false;
			}

			if(i < length) {
				const record = records[i];
				const playTime = record ? record.playTime : playbackFrequency;
				const recordPlayTime = playTime || playbackFrequency;

				// console.log(i, record);

				if(record) {
					if(!this.isMediaMixer()) {
						this.setPosition(record.position);
						this.setPov(record.pov);
						
						// console.log(i, record);
						
						this.updateMouse(record.mouse);

						if(this.audioPlayer) this.audioPlayer.mute(!!record.mute);
					}

					this.updatePlayer({ activeRecordKey: i });
				}

				this.playMusic(currentFrame);
				this.loadImage(currentFrame);

				this.updatePlayer({ activeRecordIndex: i });
				this.updateFrames({ current: currentFrame }, this.reCenterTimeline);

				$(document).trigger('legplayer.playing', [time]);

				let finalPlayTime = recordPlayTime;

				const lastPlayTime = LAST_PLAY_TIME ? Math.abs(LAST_PLAY_TIME.diff(moment.utc(), 'millisecond')) : 0;
				const lagDiff = lastPlayTime - finalPlayTime;
				let lagIndex = iIndex;

				if((lagDiff > 0) && i) {
					if(lagDiff >= recordPlayTime) {
						lagIndex = Math.ceil(lagDiff / recordPlayTime);
					} else {
						finalPlayTime += lagDiff;
					}
				}

				// console.log({ lastPlayTime, lagDiff, lagIndex, finalPlayTime });

				this.globalTime += finalPlayTime;
				
				this.playTimer = setTimeout(() => {
					if(this.state.player.isPlaying) {
						this.play(i + lagIndex);
						LAST_PLAY_TIME = moment.utc();
						// console.log('TIMEOUT');
					}
				}, finalPlayTime);
			} else {
				this.stopAudio();
				// this.stopMusic();
				this.startPlay(0, this.state.player.activeLegIndex + 1);
				// this.props.onLegComplete(this.state.player.activeRecordIndex);
			}
		}

		return true;
	}

	// Audio

	loadAudio = () => {
		const { legs, nodeType } = this.props;

		map(legs, leg => {
			const { audio } = leg;
			if(!audio.duration || !audio.url) return;

			const url = audio.url;
			const audioFormat = last(url.split('.'));
			const howlKey = `howl_${ leg.legId }`;

			const isExisting = this.howlAudioPlayers[howlKey];
			if(isExisting) return;
			
			const volume = audio.volume >= 0 ? (audio.volume / 100) : 1;

			const howlPlayer = new Howl({
				volume: (nodeType === 60) ? 1 : volume,

				html5: false,
				loop: false,
				buffer: false,
				preload: true,
				autoplay: false,
				
				format: [audioFormat],

				src: [url],

				onloaderror: (e, error) => {
					console.log(e, error);
				},

				onload: () => {
					console.log(`LEG AUDIO LOADED: ${ leg.legId }`);
					this.lastAudioVolume = howlPlayer ? howlPlayer.volume() * 100 : 0;

					this.updateAudioVolumeSlider(audio.volume);
					this.changeGlobalVolume();
				}
			});

			this.howlAudioPlayers[howlKey] = howlPlayer;
		});
	}

	playAudio = () => {
		const { type, leg } = this.props;
		if(this.isMediaMixer() || !leg || (type === 'record')) return false;

		const { audio } = leg;
		const audioPlayer = this.howlAudioPlayers[`howl_${ leg.legId }`];
		if(!audioPlayer) return true;

		if(this.audioPlayer) {
			this.audioId = this.audioPlayer.play();
			return true;
		}
			
		this.audioPlayer = audioPlayer;
		this.audioPlayer.play();

		this.audioPlayer.once('play', () => {
			console.log('on play');
			this.updateAudioVolumeSlider(audio.volume);
			const { player, playbackFrequency } = this.state;
			const time = (player.activeRecordIndex * playbackFrequency) / 1000;
			
			if(this.audioPlayer) this.audioPlayer.seek(time);
		});
		
		this.audioPlayer.once('end', () => {
			console.log('on end');
			if(this.audioPlayer) {
				this.audioPlayer.stop();
				// this.audioPlayer.unload();
				this.audioPlayer = null;
			}
		});

		return true;
	}

	pauseAudio = () => {
		if(this.audioPlayer) this.audioPlayer.pause();
	}

	stopAudio = () => {
		if(this.audioPlayer) {
			this.audioPlayer.stop();
			// this.audioPlayer.unload();
			this.audioPlayer = null;
		}
	}

	// Music

	loadMusic = () => {
		const { type } = this.state;
		if((type === 'imageMixer') || (type === 'mini')) return;

		const { musicSequence, music } = this.props;
		if(!musicSequence) return;

		this.howlMusicPlayers = {};
		map(musicSequence, (item, id) => {
			const musicItem = find(music, { musicId: item.musicId });

			this.howlMusicPlayers[`howl_${ id }`] = new Howl({
				loop: true,
				html5: true,
				preload: true,
				
				format: ['mp3', 'ogg'],

				src: [
					`https://street-tour-development.appspot.com/v1/legs/music/convert/${ musicItem.mediaId }?filetype=mp3`,
					`https://street-tour-development.appspot.com/v1/legs/music/convert/${ musicItem.mediaId }?filetype=ogg`
				],

				volume: musicItem.volume / 100,

				onload: () => {
					console.log('LOADED:', `HOWL_${ id }`);
				}
			});
		});
	}

	playMusic = frame => {
		const { type } = this.state;
		if((type === 'imageMixer') || (type === 'mini')) return;

		const { musicSequence, music } = this.props;
		if(!musicSequence) return;

		const { playbackFrequency } = this.state;
		const time = frame * playbackFrequency;
		const playTimes = keys(musicSequence);

		for(let i = 0; i < playTimes.length; i += 1) {
			const playTime = parseInt(playTimes[i], 10);
			const musicItem = get(musicSequence, playTime);
			const endTime = musicItem.startTime + musicItem.playTime;
	
			if((time >= playTime) && (time < endTime) && !isEqual(playTime, LAST_MUSIC_PLAYTIME)) {
				if(this.musicPlayer && this.musicPlayer.playing()) this.stopMusic();

				const item = find(music, { musicId: musicItem.musicId });
				// const mediaItem = get(media, musicItem.mediaId);
				const timeout = endTime - time;
				
				this.musicPlayer = this.howlMusicPlayers[`howl_${ playTime }`];

				if(this.musicPlayer) {
					const isPlaying = this.musicPlayer.playing();
					if(!isPlaying) {
						this.updateMusicState({ sequenceIndex: playTime, loaded: true, musicId: musicItem.musicId, musicIndex: i, activeIndex: i });

						this.changeGlobalVolume();
						this.setState({ lastPlayedMusic: i });
						this.updateMusicVolumeSlider(item.volume);

						const diff = (time - musicItem.startTime) + musicItem.offset;
						if(diff > 0) {
							let seekTime = diff % item.originalPlaytime;
							seekTime /= 1000;

							this.musicPlayer.seek(seekTime);
						}

						this.musicPlayer.play();
					}

					this.musicPlayer.on('play', () => {
						this.updateMusicVolumeSlider(item.volume);
						this.updateMusicState({ playing: true });
					});

					this.musicPlayer.on('pause', () => {
						// this.setState({ lastPlayedMusic: this.state.lastPlayedMusic - 1 });
						this.updateMusicState({ playing: false, paused: true, activeIndex: -1 });
					});
				}

				LAST_MUSIC_PLAYTIME = playTime;

				this.musicTimer = setTimeout(() => {
					this.stopMusic();
				}, timeout);

				break;
			}
		}
	}

	pauseMusic = () => {
		// console.log('Pause:');
		if(this.musicTimer) clearTimeout(this.musicTimer);
		if(this.musicPlayer) {
			this.musicPlayer.pause();
			LAST_MUSIC_PLAYTIME = null;
		}
	}

	stopMusic = () => {
		// console.log('Stop:');
		if(this.musicTimer) clearTimeout(this.musicTimer);
		if(this.musicPlayer) {
			this.musicPlayer.off('play');
			this.musicPlayer.stop();
			
			LAST_MUSIC_PLAYTIME = null;
			this.musicPlayer = null;
		}
	}

	resetMusic = () => {
		this.stopMusic();
		this.updateMusicState(this.initialMusicState);
	}

	// Images

	loadImage = frame => {
		const { type } = this.state;
		if(type === 'mini') return;

		const { images } = this.props;
		const { playbackFrequency, visibleImages } = this.state;
		const time = frame * playbackFrequency;

		const vImages = [];

		const currentImage = {
			index: -1,
			imageId: null,
			zIndex: 0,
			transition: {
				entrance: 'fadeIn',
				exit: 'fadeOut',
			}
		}

		const lastEndTime = max(map(images, image => parseFloat(image.startTime + image.playTime)));

		if(time > lastEndTime) {
			this.setState({
				visibleImages: [],
			}, () => {
				this.updateImageState(this.initialImageState);
			});
		}

		for (let i = 0; i < images.length; i += 1) {
			const image = images[i];
			const endTime = image.startTime + image.playTime;

			if((time >= image.startTime) && (time < endTime)) {
				if(image.zIndex > currentImage.zIndex) {
					currentImage.index = i;
					currentImage.imageId = image.imageId;
					currentImage.zIndex = image.zIndex;
					currentImage.transition = image.transition;

					vImages.push(image);
				}
			}
		}

		if(currentImage.imageId) {
			this.setState({ lastPlayedImage: currentImage.index });
			this.updateImageState({
				imageId: currentImage.imageId,
				imageIndex: currentImage.index,
				activeIndex: currentImage.index
			});
		}

		if(!isEqual(visibleImages, vImages)) {
			this.setState({ visibleImages: vImages }, () => {
				const currentImages = differenceBy(vImages, visibleImages, 'imageId');
				const previousImages = differenceBy(visibleImages, vImages, 'imageId');

				map(currentImages, image => {
					const $cntImage = $(`.image_${ image.imageId }`);
					if($cntImage.length && !$cntImage.is(':visible')) {
						$cntImage.show();
						$('img', $cntImage).removeClass().addClass(`${ image.transition ? image.transition.entrance : 'fadeIn' } animated`);
					}
				});
				
				map(previousImages, image => {
					const $cntImage = $(`.image_${ image.imageId }`);
					if($cntImage.length && $cntImage.is(':visible')) {
						const $img = $('img', $cntImage);

						$img.removeClass().addClass(`${ image.transition ? image.transition.exit : 'fadeOut' } animated`);
						$img[0].addEventListener('animationend', () => {
							$cntImage.hide();
							$img[0].removeEventListener('animationend', () => {});
						});
					}
				});
			});
		}
	}

	stopImage = () => {
		this.setState({
			lastPlayedImage: -1,
			visibleImages: []
		}, () => {
			this.updateImageState(this.initialImageState);
		});
	}

	// Captions

	playCaptions = () => {
		const { leg } = this.props;
		if(!leg.audio || !leg.audio.speech) {
			this.stopCaptions();
			return;
		}

		const { player } = this.state;
		const { activeRecordIndex } = player;
		const captions = [];

		const playTime = 10000;

		const startTime = activeRecordIndex * 100;
		const endTime = startTime + playTime;

		map(leg.audio.speech.results, result => {
			map(result.alternatives, alternative => {
				if(alternative.transcript && alternative.confidence) {
					map(alternative.words, word => {
						const wordStartTime = parseFloat(word.startTime) * 1000;
						const wordEndTime = parseFloat(word.endTime) * 1000;

						if((wordStartTime >= startTime) && (wordEndTime < endTime)) {
							captions.push(word);
						}
					});
				}
			});
		});

		this.setState({ captions });

		this.captionsTimer = setTimeout(() => {
			this.playCaptions();
		}, playTime);
	}
	
	stopCaptions = () => {
		if(this.captionsTimer) {
			clearInterval(this.captionsTimer);
			this.captionsTimer = null;
		}

		this.setState({ captions: [] });
	}

	// Geo Player

	beginPlay = (index = 0, legIndex = 0) => {
		if(this.playTimer) clearTimeout(this.playTimer);

		const { player } = this.state;

		const activeRecordKey = player.activeRecordKey || this.getActiveRecordKey(index);
		this.updatePlayer({
			activeLegIndex: legIndex,
			activeRecordIndex: index,
			activeRecordKey: activeRecordKey || 0,
		}, () => {
			this.play(index);
			this.playAudio();
			this.recordProgress();

			this.playCaptions();

			const { leg: { recording: { records } } } = this.props;
			const record = records[activeRecordKey];

			if(record) {
				this.setPosition(record.position);
				this.setPov(record.pov);
				this.updateMouse(record.mouse);
			}
		});
	}

	startPlay = (rIndex = null, lIndex, forced, callback) => {
		// console.log('Global Play Time:', this.globalTime);

		this.updatePlayer({
			isPlaying: true,
			isPaused: false,
			initialized: true,
		}, () => {
			const { player } = this.state;
			const { layout, totalLegs } = this.props;
			// const { activeLegIndex } = this.props;

			if(player.initialized && !this.isPlayInitialized) {
				this.lastGlobalVolume = 50;
				this.isPlayInitialized = true;
				
				if(!this.isMediaMixer()) setTimeout(this.handleGeoThumbnail, 100);
				
				this.handleProgressComponents();
				this.updatePlayer({ initializedOnce: true });

				this.props.onLegPlayInitialized();
			}

			const recordIndex = rIndex !== null ? rIndex : player.activeRecordIndex;
			const legIndex = lIndex || player.activeLegIndex;

			if(legIndex < totalLegs) {
				this.props.startPlay(legIndex, index => {
					const skippedRecords = this.getSkippedRecords(index) + recordIndex;

					if(layout === 'homepage') {
						this.progressInterval = setInterval(this.recordProgress, 3000);
					}
					
					this.props.onLegStart(index, recordIndex);
					this.beginPlay(recordIndex, index);

					if(!this.globalPlayTime) {
						this.globalPlayTime = moment.utc();
					}
	
					if(forced) this.updateFrames({ current: skippedRecords });
					if(callback) callback();
				});
			} else {
				// const diff = Math.abs(this.globalPlayTime.diff(moment.utc(), 'millisecond'));
				// console.log('Global Play Time:', this.globalTime, diff, this.globalPlayTime);

				this.props.onAllLegsComplete();
				this.resetRecordProgress({
					legIndex: 0,
					recordKey: 0,
					recordIndex: 0,
				});

				setTimeout(() => {
					LAST_PLAY_TIME = null;
				}, 100);
			}
		});
	}

	pause = () => {
		this.updatePlayer({
			isPaused: true,
			isPlaying: false
		}, () => {
			LAST_PLAY_TIME = null;

			console.log('PAUSED');

			this.pauseAudio();
			this.pauseMusic();
			
			this.stopImage();
			this.stopCaptions();
		});

		this.props.onLegPause(this.state.player.activeRecordIndex);
		this.resetRecordProgress(null);
		
	}

	stop = () => {
		this.resetZoomedTimeline();
		this.reset(this.updateInitialPosition);
	}

	replay = () => {
		this.props.replay();
	}

	reset = (callback, soft = false) => {
		if(this.playTimer) clearTimeout(this.playTimer);
		
		this.lastMusicVolume = 0;
		this.lastAudioVolume = 0;
		this.lastGlobalVolume = 0;
		
		this.updatePlayer({
			isPaused: false,
			isPlaying: false,
			activeLegIndex: 0,
			activeRecordKey: 0,
			initialized: false,
			activeRecordIndex: 0,
		}, () => {
			this.clearPlayer(soft, callback);
			this.globalPlayTime = null;
		});
	}

	recordProgress = (progress) => {
		const { layout, token } = this.props;

		if(this.isLoaded && layout === 'homepage') {
			const { player } = this.state;
			
			progress = progress || {
				legIndex: player.activeLegIndex,
				recordKey: player.activeRecordKey,
				recordIndex: player.activeRecordIndex
			};
	
			if(token) this.props.setProgress(progress);
		}
	}

	resetRecordProgress = (progress = null) => {
		const { layout } = this.props;

		if(this.progressInterval && layout === 'homepage') {
			this.recordProgress(progress);
			clearInterval(this.progressInterval);
			this.progressInterval = null;
		}
	}

	resetToDefaults = () => {
		this.globalTime = 0;

		this.audioId = null;
		this.audioPlayer = null;

		this.musicId = null;
		this.musicPlayer = null;

		this.recordingDefaults();
	}

	startRecording = () => {
		this.recordingDefaults();

		this.recordingStartTime = moment.utc();
		this.updatePlayer({
			initialized: true,
			isRecording: true
		}, () => {
			this.updateRecording({
				initial: {
					position: this.getPosition(),
					pov: this.getPov(),
				}
			});
			
			this.updateProgress(true);
			this.watchRecording();

			this.startAudioRecording();
		});
	}
	
	stopRecording = () => {
		// this.record();
		this.updatePlayer({
			isRecording: false,
			isSaving: true,
		}, () => {
			this.stopAudioRecording(data => {
				// const diffTime = Math.abs(this.recordingStartTime.diff(moment.utc(), 'milliseconds'));
				const playTime = this.globalRecordingTime;

				// console.log(diffTime, TOTAL_RECORDS, this.globalRecordingTime);

				data = {
					...NEW_AUDIO,
					...data
				}

				this.updateAudioRecording({
					src: data.src || null,
					volume: 50,
					duration: data.duration || 0
				});

				this.updateRecording({
					playTime,
					records: this.records,
					totalRecords: TOTAL_RECORDS,
					final: {
						position: this.getPosition(),
						pov: this.getPov(),
					}
				});

				this.updateProgress(true, true);
				this.saveRecord();
			});
		});
	}

	startAudioRecording = () => {
		const audio = this.audioRef.current;
		audio.startRecording();
	}

	stopAudioRecording = callback => {
		const audio = this.audioRef.current;
		audio.stopRecording(data => {
			if(callback) callback(data);
		});
	}

	saveRecord = () => {
		const data = {
			audio: this.audioRecording,
			recording: this.recording,
		}
		this.props.saveRecord(data);
	}

	sliderOnStart = () => {
		this.pause();
	}

	sliderOnStop = (e, ui) => {
		const { value } = ui;
		const { frames } = this.state;

		const sliderIndex = parseInt((frames.total / 100) * value, 10);
		this.seekPlayer(sliderIndex);
	}

	seekPlayer = (sliderIndex, time = 0) => {
		const meta = this.getRecordMetaViaIndex(sliderIndex, time);

		this.reset(() => {
			this.startPlay(meta.recordIndex, meta.index);
		}, true);
	}

	// Global Volume

	seekGlobalVolumeSlider = value => {
		const slider = this.globalVolumeSliderRef.current;
		if(!slider) return;
		if(value <= 100) slider.setValue(value);
	}

	changeGlobalVolume = () => {
		const slider = this.globalVolumeSliderRef.current;
		if(!slider) return;

		const base = 50 / 100;
		const volumeBoost = slider.getValue() / base;
		const getBoost = val => ((val / base) / 100) * volumeBoost;

		const { leg } = this.props;

		if(leg && leg.audio && leg.audio.duration) {
			const audioVolume = getBoost(leg.audio.volume);
			if(this.audioPlayer) this.audioPlayer.volume(audioVolume / 100);
		}

		const { music: { musicId } } = this.state;
		if(musicId) {
			const { music } = this.props;
			const musicItem = find(music, { musicId });

			if(musicItem) {
				const musicVolume = getBoost(musicItem.volume);
				if(this.musicPlayer) this.musicPlayer.volume(musicVolume / 100);
			}
		}
	}

	musicVolumeSliderOnStart = () => {}

	musicVolumeSliderOnSlide = (e, ui) => {
		const { value } = ui;
		if(this.musicPlayer) this.musicPlayer.volume(value / 100);
	}
	
	musicVolumeSliderOnStop = (e, ui) => {
		const { value } = ui;
		this.updateMusicVolume(value);
	}
	
	audioVolumeSliderOnStart = () => {
		
	}

	audioVolumeSliderOnSlide = (e, ui) => {
		const { value } = ui;
		if(this.audioPlayer) this.audioPlayer.volume(value / 100);
	}
	
	audioVolumeSliderOnStop = (e, ui) => {
		const { value } = ui;
		this.updateAudioVolume(value);
	}

	showStvCursor = () => {
		const cursor = this.stvCursorRef.current;
		if(cursor) cursor.style.display = 'block';
	}

	hideStvCursor = () => {
		const cursor = this.stvCursorRef.current;
		if(cursor) cursor.style.display = 'none';
	}

	showStvMap = () => {
		const cntMap = this.cntMap.current;
		const { stvMap } = this.state;

		this.setState({
			stvMap: {
				...stvMap,
				active: true,
			}
		}, () => $(cntMap).show('fast'));
	}

	hideStvMap = () => {
		const cntMap = this.cntMap.current;
		const { stvMap } = this.state;

		this.setState({
			stvMap: {
				...stvMap,
				active: false,
			}
		}, () => $(cntMap).hide('fast'));
	}

	recordingDefaults = () => {
		LAST_RECORD = {};
		TOTAL_RECORDS = 0;

		if(this.recordingTimer) clearInterval(this.recordingTimer);

		this.records = {};
		this.recordingTimer = null;
		this.globalRecordingTime = 0;
		this.recordingStartTime = null;
		this.audioRecording = NEW_AUDIO;
		this.recording = NEW_RECORDING;
	}

	record = (playTime = this.recording.frequency, currentTime) => {
		const { mouse, audioRecord } = this.state;

		let duplicate = false;
		
		const pov = this.getPov();
		const position = this.getPosition();

		const frameViaTime = Math.floor(currentTime / 100);

		const record = {
			music: false,
			mute: !audioRecord,
			audio: audioRecord,

			pov,
			mouse,
			position,
			playTime,
		}

		if(!isEmpty(LAST_RECORD)) {
			const mouseLR = LAST_RECORD.mouse;

			const dataR = {
				pov,
				position,

				isMouse: mouse.isVisible,
			}

			const dataLR = {
				pov: LAST_RECORD.pov,
				position: LAST_RECORD.position,
				
				isMouse: mouseLR.isVisible,
			}

			if(mouse.isVisible) {
				dataR.mouse = mouse;
				dataLR.mouse = mouseLR;
			}

			duplicate = isEqual(dataLR, dataR);
		}

		if(!duplicate) {
			this.globalRecordingTime += playTime;
			this.records[TOTAL_RECORDS] = record;
		} else {
			this.globalRecordingTime += this.recording.frequency;
		}
		
		LAST_RECORD = record;
		// TOTAL_RECORDS = +1;
		TOTAL_RECORDS = frameViaTime;
		
		return record;
	}

	runSlider = () => {
		const { frames } = this.state;
		const position = (frames.current / frames.total) * 100;

		this.updateSlider(position);
	}

	// Timeline Zooming

	resetZoomedTimeline = () => {
		this.updateTimelineState(this.initialTimelineState);
	}

	reCenterTimeline = () => {
		const cnt = this.legProgressComponentsRef.current;
		if(!cnt) return;

		const slider = this.sliderRef.current;
		if(!slider) return;

		const scrollbar = this.legProgressComponentsScrollbarRef.current;
		if(!scrollbar) return;

		const $parent = $(cnt).closest('[data-progress-components]');
		if(!$parent.length) return;

		const value = slider.getValue();
		const rect = cnt.getBoundingClientRect();
		const position = ((rect.width / 100) * value) - ($parent.innerWidth() / 2);
		
		// https://jsfiddle.net/azizk_araneux/35jxkq1t/

		if(position > 0) scrollbar.scrollLeft(position);
	}
	
	zoomInTimeline = () => {
		const cnt = this.legProgressComponentsRef.current;
		if(!cnt) return;

		const { timeline } = this.state;
		
		const rect = cnt.getBoundingClientRect();
		const zoomedWidth = rect.width + timeline.zoomScale;

		this.updateTimelineState({ width: zoomedWidth }, () => {
			this.forceUpdate(this.reCenterTimeline);
		});
	}
	
	zoomOutTimeline = () => {
		const cnt = this.legProgressComponentsRef.current;
		if(!cnt) return;
		
		const parent = cnt.closest('[data-progress-components]');

		const rect = cnt.getBoundingClientRect();
		const parentRect = parent.getBoundingClientRect();

		const { timeline } = this.state;
		const zoomedWidth = rect.width - timeline.zoomScale;
		if(zoomedWidth >= parentRect.width) {
			this.updateTimelineState({ width: zoomedWidth }, () => {
				this.forceUpdate(this.reCenterTimeline);
			});
		}
	}

	updateMusicVolume = value => {
		const { music, type } = this.state;

		if(!music.musicId) return false;

		if(this.musicPlayer) {
			this.musicPlayer.volume(value / 100);
			if(type === 'mixer') this.props.updateMusicVolume(music.musicId, value);
		}
		
		return value;
	}
	
	updateMusicVolumeSlider = volume => {
		const musicVolumeRef = this.musicVolumeRef.current;
		if(musicVolumeRef) musicVolumeRef.setValue(volume);
	}
	
	updateAudioVolume = value => {
		const { player, type } = this.state;

		if(this.audioPlayer) {
			this.audioPlayer.volume(value ? value / 100 : 0);
			if(type === 'mixer') {
				console.log('UPDATE AUDIO VOLUME');
				this.props.updateAudioVolume(player.activeLegIndex, value);
			}
		}
		
		return value;
	}

	updateAudioVolumeSlider = volume => {
		const audioVolumeRef = this.audioVolumeRef.current;
		if(audioVolumeRef) audioVolumeRef.setValue(volume);
	}

	updatePlayer = (updates, callback) => {
		const { player } = this.state;
		
		if(this.isLoaded) {
			this.setState({
				player: {
					...player,
					...updates
				}
			}, callback);
		}
	}

	updateInitialPosition = () => {
		const { initialPosition } = this.props;

		if(!isEmpty(initialPosition.position)) this.setPosition(initialPosition.position || initialPosition);
		if(!isEmpty(initialPosition.pov)) this.setPov(initialPosition.pov || null);
	}

	updateCenter = () => {}

	updatePov = () => {}
	
	updateFrames = (updates, callback) => {
		const { frames } = this.state;
		if(this.isLoaded) {
			this.setState({
				frames: {
					...frames,
					...updates
				}
			}, () => {
				this.runSlider();
				if(callback) callback();
			});
		}
	}
	
	updateMusicState = (updates, callback) => {
		if(this.isLoaded) {
			const { music } = this.state;
			this.setState({
				music: {
					...music,
					...updates
				}
			}, callback);
		}
	}
	
	updateImageState = (updates, callback) => {
		if(this.isLoaded) {
			const { image } = this.state;
			this.setState({
				image: {
					...image,
					...updates
				}
			}, callback);
		}
	}

	updateMouseState = updates => {
		const { mouse } = this.state;

		if(this.isLoaded) {
			this.setState({
				mouse: {
					...mouse,
					...updates
				}
			});
		}
	}

	updateGeoThumbnailState = (updates, callback) => {
		const { geoThumbnail } = this.state;
		
		if(this.isLoaded) {
			this.setState({
				geoThumbnail: {
					...geoThumbnail,
					...updates
				}
			}, callback);
		}
	}

	updateTimelineState = (updates, callback) => {
		const { timeline } = this.state;

		if(this.isLoaded) {
			this.setState({
				timeline: {
					...timeline,
					...updates
				}
			}, callback);
		}
	}

	updateSlider = value => {
		const slider = this.sliderRef.current;
		if(slider) slider.setValue(value);
	}

	updateProgress = (active, saving = false) => {
		if(this.isLoaded) {
			this.setState({
				progress: {
					active,
					saving
				}
			});
		}
	}

	updateMouse = m => {
		const { player, mouse } = this.state;
		const cursor = this.stvCursorRef.current;

		if(cursor && player.isPlaying) {
			if(m.isVisible && mouse.isVisible) {
				this.showStvCursor();

				cursor.style.top = `${ m.top }%`;
				cursor.style.left = `${ m.left }%`;
			} else {
				this.hideStvCursor();
			}
		} else {
			this.hideStvCursor();
		}
	}

	updateAudioRecording = updates => {
		this.audioRecording = { ...this.audioRecording, ...updates };
	}

	updateRecording = updates => {
		this.recording = { ...this.recording, ...updates };
	}

	unbindEvents = () => {
		$('body').off('keyup.player');

		const googleComponents = document.querySelectorAll('.googleComponent');

		map(googleComponents, el => {
			el.removeEventListener('click', () => {});
			el.removeEventListener('mousewheel', () => {});
			el.removeEventListener('DOMMouseScroll', () => {});
		});
	}

	watchRecording = () => {
		// this.record();

		let LAST_TIME = 0;
		this.recordingTimer = setInterval(() => {
			const { player } = this.state;

			if(!player.isRecording) {
				clearInterval(this.recordingTimer);
				return;
			}

			const time = Math.abs(this.recordingStartTime.diff(moment.utc(), 'milliseconds'));
			const diffTime = time - LAST_TIME;

			this.record(diffTime, time);

			LAST_TIME = time;
		}, this.recording.frequency);
	}

	watchFullscreen = () => {
		let isFullscreen = false;
		const tourplayer = document.querySelector('#tourplayer');

		if(Screenfull.enabled) {
			Screenfull.on('change', () => {
				if(Screenfull.element && (Screenfull.element === tourplayer)) {
					isFullscreen = Screenfull.isFullscreen;
				} else {
					isFullscreen = false;
				}

				this.setState({
					stvStreetview: {
						fullscreen: isFullscreen,
					}
				}, () => {
					this.contractMap();
					this.resizeStreetview();
				});
			});
		}
	}

	renderImageItems = () => {
		const { images, media } = this.props;
		const { visibleImages } = this.state;
		if(!images || !images.length) return '';

		return map(images, (image, i) => {
			const mediaItem = get(media, image.mediaId);
			if(!mediaItem) return '';
			const visibleImage = find(visibleImages, { imageId: image.imageId });

			return (
				<ImageItem
					index={ i }
					image={ image }
					media={ mediaItem }
					key={ image.imageId }
					className={ `mediaImage image_${ image.imageId }` }
					imgClassName={ `${ visibleImage ? `${ image.transition ? image.transition.entrance : 'fadeIn' } animated` : '' }` }
				/>
			);
		});
	}

	renderImages = () => {
		const { fromIndex } = this.props;
		const { player, type } = this.state;

		if(player.isPaused || (fromIndex >= 0) || (type === 'mini')) return '';

		return (
			<div className="streetview-images-container">
				{ this.renderImageItems() }
			</div>
		);
	}

	renderGlobalVolumeControls = () => {
		const { type } = this.state;
		if(this.isMixer() || (type === 'record')) return '';
		
		return (
			<div className="leg-progress-components global-volume-controls active">
				<Slider active orientation="vertical" className="leg-progress progress-solid" value={ 50 } ref={ this.globalVolumeSliderRef } onSlide={ this.onGlobalVolumeChange }/>
			</div>
		);
	}

	renderMouseControl = () => {
		const { isCursor } = this.props;
		const { type, mouse } = this.state;
		// const isVisible = player.isRecording || player.isPlaying;

		if(this.isMediaMixer()) return '';
		if(type !== 'record' && !isCursor) return '';

		return (
			<li className={ `${ !mouse.isVisible ? 'inactive' : '' }` } data-uk-tooltip="{'pos': 'top'}" title="Toggle Cursor (C)">
				<Link to="#graphicPanel" className="bg-info text-white atn-icon-sm" onClick={ this.onMouseTouch } data-atn>
					<i className="fas fa-mouse-pointer"/>
				</Link>
			</li>
		);
	}

	renderRecordingControls = () => {
		const { audioDevice } = this.props;
		const { player, audioRecord } = this.state;

		return (
			<ul>
				<li className={ !player.isRecording ? 'd-block' : 'd-none' } data-uk-tooltip="{'pos': 'top'}" title="Start Recording">
					<Link to="#graphicPanel" className="bg-primary text-white" onClick={ this.onRecord } data-atn>
						<i className="material-icons">mic</i>
					</Link>
				</li>
				<li className={ player.isRecording ? 'd-block' : 'd-none' } data-uk-tooltip="{'pos': 'top'}" title="Stop Recording">
					<Link to="#graphicPanel" className="bg-danger text-white" onClick={ this.onRecordStop } data-atn>
						<i className="material-icons">mic_off</i>
					</Link>
				</li>
				{ this.renderMouseControl() }
				<li className={ `${ !audioRecord ? 'inactive' : '' } ${ !audioDevice ? 'd-none' : '' }` } data-uk-tooltip="{'pos': 'top'}" title={ audioRecord ? 'Mute Audio (A)' : 'Unmute Audio (A)' }>
					<Link to="#graphicPanel" className="bg-info text-white" onClick={ this.onToggleAudioRecord } data-atn>
						<i className="material-icons">headset_mic</i>
					</Link>
				</li>
			</ul>
		);
	}

	renderPlaybackControls = () => {
		const { nodeType, fromIndex } = this.props;
		const { player, type } = this.state;

		const canNavigate = !this.isMediaMixer();
		const isMusicMixer = (type === 'mixer' || type === 'musicMixer');
		const isImageMixer = (type === 'mixer' || type === 'imageMixer');

		const isOnlyLegs = fromIndex >= 0;

		return (
			<ul>
				<li className={ canNavigate && player.initialized ? 'd-block' : 'd-none' } data-uk-tooltip="{'pos': 'top'}" title="Replay">
					<Link to="#graphicPanel" className="atn-sm btnReset" onClick={ this.onReplay } data-atn>
						<i className="material-icons">&#xE5D5;</i>
					</Link>
				</li>
				<li className={ (isImageMixer && !isOnlyLegs && (player.isPlaying || player.isPaused)) ? 'd-block' : 'd-none' } data-uk-tooltip="{'pos': 'top'}" title="Previous Image">
					<Link to="#graphicPanel" className="atn-sm btnNavigate btnNavigatePrev" onClick={ e => this.onImageNavigate(e, 'prev') } data-atn>
						<i className="material-icons">
							{ type === 'imageMixer' ? 'skip_previous' : 'photo' }
						</i>
					</Link>
				</li>
				<li className={ (isMusicMixer && (player.isPlaying || player.isPaused)) ? 'd-block' : 'd-none' } data-uk-tooltip="{'pos': 'top'}" title="Previous Music">
					<Link to="#graphicPanel" className="atn-sm btnNavigate btnNavigatePrev" onClick={ e => this.onMusicNavigate(e, 'prev') } data-atn>
						<i className="material-icons">
							{ type === 'musicMixer' ? 'skip_previous' : 'music_note' }
						</i>
					</Link>
				</li>
				<li className={ (canNavigate && (player.isPlaying || player.isPaused) && (nodeType === 50)) ? 'd-block' : 'd-none' } data-uk-tooltip="{'pos': 'top'}" title="Previous Leg">
					<Link to="#graphicPanel" className="atn-sm btnNavigate btnNavigatePrev" onClick={ e => this.onLegNavigate(e, 'prev') } data-atn>
						<i className="material-icons">skip_previous</i>
					</Link>
				</li>
				<li className={ (!player.isPlaying || player.isPaused) ? 'd-block' : 'd-none' } data-uk-tooltip="{'pos': 'top'}" title="Play">
					<Link to="#graphicPanel" className="btn-play btnPlay" onClick={ this.onPlay } data-atn>
						<i className="material-icons">play_arrow</i>
					</Link>
				</li>
				<li className={ (player.isPlaying && !player.isPaused) ? 'd-block' : 'd-none' } data-uk-tooltip="{'pos': 'top'}" title="Pause">
					<Link to="#graphicPanel" className="btn-pause btnPause bg-primary text-white" onClick={ this.onPause } data-atn>
						<i className="material-icons">pause</i>
					</Link>
				</li>
				<li className={ (player.isPlaying || player.isPaused) ? 'd-block' : 'd-none' } data-uk-tooltip="{'pos': 'top'}" title="Stop">
					<Link to="#graphicPanel" className="btn-stop btnStop bg-danger text-white" onClick={ this.onStop } data-atn>
						<i className="material-icons">stop</i>
					</Link>
				</li>
				<li className={ (canNavigate && (player.isPlaying || player.isPaused) && (nodeType === 50)) ? 'd-block' : 'd-none' } data-uk-tooltip="{'pos': 'top'}" title="Next Leg">
					<Link to="#graphicPanel" className="atn-sm btnNavigate btnNavigateNext" onClick={ e => this.onLegNavigate(e, 'next') } data-atn>
						<i className="material-icons">skip_next</i>
					</Link>
				</li>
				<li className={ (isMusicMixer && (player.isPlaying || player.isPaused)) ? 'd-block' : 'd-none' } data-uk-tooltip="{'pos': 'top'}" title="Next Music">
					<Link to="#graphicPanel" className="atn-sm btnNavigate btnNavigateNext" onClick={ e => this.onMusicNavigate(e, 'next') } data-atn>
						<i className="material-icons">
							{ type === 'musicMixer' ? 'skip_next' : 'music_note' }
						</i>
					</Link>
				</li>
				<li className={ (isImageMixer && !isOnlyLegs && (player.isPlaying || player.isPaused)) ? 'd-block' : 'd-none' } data-uk-tooltip="{'pos': 'top'}" title="Next Image">
					<Link to="#graphicPanel" className="atn-sm btnNavigate btnNavigatePrev" onClick={ e => this.onImageNavigate(e, 'next') } data-atn>
						<i className="material-icons">
							{ type === 'imageMixer' ? 'skip_next' : 'photo' }
						</i>
					</Link>
				</li>
				{ this.renderMouseControl() }
			</ul>
		);
	}
	
	renderMiniPlaybackControls = () => {
		const { player } = this.state;

		return (
			<ul>
				<li className={ (!player.isPlaying || player.isPaused) ? 'd-block' : 'd-none' } data-uk-tooltip="{'pos': 'top'}" title="Play">
					<Link to="#graphicPanel" className="btn-play btnPlay bg-white text-primary" onClick={ this.onPlay } data-atn>
						<i className="material-icons">play_arrow</i>
					</Link>
				</li>
				<li className={ (player.isPlaying && !player.isPaused) ? 'd-block' : 'd-none' } data-uk-tooltip="{'pos': 'top'}" title="Pause">
					<Link to="#graphicPanel" className="btn-pause btnPause bg-white text-primary" onClick={ this.onPause } data-atn>
						<i className="material-icons">pause</i>
					</Link>
				</li>
				<li className={ (player.isPlaying || player.isPaused) ? 'd-block' : 'd-none' } data-uk-tooltip="{'pos': 'top'}" title="Stop">
					<Link to="#graphicPanel" className="btn-stop btnStop bg-white text-danger" onClick={ this.onStop } data-atn>
						<i className="material-icons">stop</i>
					</Link>
				</li>
			</ul>
		);
	}

	renderPlayerControls = () => {
		const { player, type } = this.state;

		if(player.isSaving) return <div/>;

		switch(type) {
			case 'mini': return this.renderMiniPlaybackControls();
			case 'mixer': return this.renderPlaybackControls();
			case 'musicMixer': return this.renderPlaybackControls();
			case 'imageMixer': return this.renderPlaybackControls();
			case 'playback': return this.renderPlaybackControls();
			case 'record': return this.renderRecordingControls();
			default: return <div/>;
		}
	}

	renderProgress = () => {
		const { player, progress, type } = this.state;
		if((type !== 'record') || !player.initialized) return '';
		return <Progress active={ progress.active } saving={ progress.saving }/>;
	}

	renderSlider = () => {
		const { player, type, timeline } = this.state;
		if((type === 'record') || !player.initializedOnce) return '';
		return <Slider active={ player.initialized } id="stvProgressSlider" className="leg-progress" ref={ this.sliderRef } onStart={ this.sliderOnStart } onStop={ this.sliderOnStop } width={ timeline.width }/>;
	}

	renderMusicVolumeSlider = () => {
		const { player } = this.state;
		const isValidType = this.isMixer();
		if(!isValidType || !player.initialized) return '';
		return <Slider active={ player.initialized } step={ 1 } className="volume-slider" ref={ this.musicVolumeRef } onStart={ this.musicVolumeSliderOnStart } onSlide={ this.musicVolumeSliderOnSlide } onStop={ this.musicVolumeSliderOnStop }/>;
	}
	
	renderAudioVolumeSlider = () => {
		const { player } = this.state;
		const isValidType = this.isMixer();
		if(!isValidType || !player.initialized) return '';
		return <Slider active={ player.initialized } step={ 1 } className="volume-slider" ref={ this.audioVolumeRef } onStart={ this.audioVolumeSliderOnStart } onSlide={ this.audioVolumeSliderOnSlide } onStop={ this.audioVolumeSliderOnStop }/>;
	}

	renderMusicVolumeControl = () => {
		const { player } = this.state;
		const isValidType = this.isMixer();
		if(!isValidType || !player.initialized) return '';

		const { music } = this.state;

		return (
			<div className={ `music-control volume-control ${ !music.musicId ? 'disabled' : '' }` }>
				<span className="control-title">Music</span>
				<div className="control-slider">
					{ this.renderMusicVolumeSlider() }
				</div>
			</div>
		);
	}
	
	renderAudioVolumeControl = () => {
		const { player } = this.state;
		const isValidType = this.isMixer();
		if(!isValidType || !player.initialized) return '';

		const { leg: { audio } } = this.props;
		const isAudioNull = !audio || isEmpty(audio) || !audio.duration || (audio === 'N/A');
 
		return (
			<div className={ `audio-control volume-control ${ isAudioNull ? 'disabled' : '' }` }>
				<span className="control-title">Audio</span>
				<div className="control-slider">
					{ this.renderAudioVolumeSlider() }
				</div>
			</div>
		);
	}

	renderVolumeControls = () => {
		const { type } = this.state;
		if(type !== 'mixer') return '';

		return (
			<div className="leg-volume-controls">
				{ this.renderMusicVolumeControl() }
				{ this.renderAudioVolumeControl() }
			</div>
		);
	}

	renderZoomControls = () => {
		const { player, type } = this.state;
		if(!player.initialized || type !== 'mixer') return '';

		return (
			<div className="leg-zoom-controls">
				<ul className="list-inline">
					<li data-uk-tooltip="{'pos': 'top'}" title="Re-center Timeline"><Link to="#center" onClick={ this.onReCenterTimeline }><i className="material-icons">center_focus_strong</i></Link></li>
					<li data-uk-tooltip="{'pos': 'top'}" title="Zoom In Timeline"><Link to="#zoomIn" onClick={ this.onZoomInTimeline }><i className="material-icons">zoom_in</i></Link></li>
					<li data-uk-tooltip="{'pos': 'top'}" title="Zoom Out Timeline"><Link to="#zoomOut" onClick={ this.onZoomOutTimeline }><i className="material-icons">zoom_out</i></Link></li>
				</ul>
			</div>
		);
	}

	renderImagesTimeline = () => {
		const { fromIndex } = this.props;
		const { player, type } = this.state;

		if(type === 'musicMixer') return '';
		if(!this.isMixer() || !player.initialized || (fromIndex >= 0)) return '';

		return (
			<div className={ `leg-progress-media-blocks` }>
				{ this.renderImageTimelineItems() }
			</div>
		);
	}

	renderImageTimelineItems = () => {
		const { images } = this.props;

		return map(images, item => {
			let left = 0;
			if(item.startTime) {
				left = this.getPixels(item.startTime);
			}

			const imageId = item.imageId || uuid();
			const id = `imageBlock_${ imageId }`;
			const width = this.getPixels(item.playTime);
			const background = item.theme || tinycolor.random().toHexString();
			const tcTheme = tinycolor(background);
			const isDark = tcTheme.isDark();

			const style = {
				left,
				width,
				background,

				zIndex: item.zIndex,
			}

			return (
				<div
					id={ id }
					style={ style }
					key={ imageId }
					data-id={ imageId }
					data-zindex={ style.zIndex }
					data-initial-width={ width }
					data-playtime={ item.playTime }
					className={ `media-block ${ isDark ? 'block-dark' : 'block-light' }` }/>
			);
		});
	}
	
	renderMusicTimeline = () => {
		const { player, type } = this.state;

		if(type === 'imageMixer') return '';
		if(!this.isMixer() || !player.initialized) return '';

		const { music } = this.state;
		return (
			<div className={ `leg-progress-media-blocks ${ !music.musicId ? 'disabled' : '' }` }>
				{ this.renderMusicTimelineItems() }
			</div>
		);
	}

	renderMusicTimelineItems = () => {
		const { music } = this.props;

		return map(music, item => {
			let left = 0;
			if(item.startTime) {
				left = this.getPixels(item.startTime);
			}

			const musicId = item.musicId || uuid();
			const id = `musicBlock_${ musicId }`;
			const width = this.getPixels(item.playTime);
			const background = item.background || tinycolor.random().toHexString();
			const tcTheme = tinycolor(background);
			const isDark = tcTheme.isDark();

			const style = {
				left,
				width,
				background,

				zIndex: item.zIndex,
			}

			return (
				<div
					id={ id }
					style={ style }
					key={ musicId }
					data-id={ musicId }
					data-zindex={ style.zIndex }
					data-initial-width={ width }
					data-playtime={ item.playTime }
					className={ `media-block ${ isDark ? 'block-dark' : 'block-light' }` }/>
			);
		});
	}

	renderStvCursor = () => {
		const { player } = this.state;
		if(!player.isPlaying) return <span className="d-none"/>;
		return <span className="streetview-cursor" ref={ this.stvCursorRef }/>;
	}

	renderLegMarkerItems = () => {
		const { markers } = this.props;
		if(!markers.length) return <span className="d-none"/>;

		return map(markers, marker => {
			if(!marker.index) return <span key={ uuid() } className="d-none"/>;

			const style = {
				left: `${ marker.position }%`
			}

			return <span key={ uuid() } style={ style } onClick={ e => this.handleMarker(e, marker) } tabIndex="-1" role="button"/>;
		});
	}

	renderLegMarkers = () => {
		const { player, type } = this.state;
		if(((type === 'record') || this.isMediaMixer()) || !player.initialized) return '';

		return (
			<div className="leg-markers">
				{ this.renderLegMarkerItems() }
			</div>
		);
	}

	renderLegOverlayItems = () => {
		const { markers, fromIndex, toIndex } = this.props;
		if(!markers.length) return <span className="d-none"/>;

		return map(markers, (marker, i) => {
			if((i < fromIndex) || (i > toIndex)) {
				const nextMarker = markers[i + 1];
				const left = marker.position;
				const right = nextMarker ? (100 - nextMarker.position) : 0;
	
				const style = {
					left: `${ left }%`,
					right: `${ right }%`
				}
				
				return <span key={ uuid() } style={ style }/>;
			}

			return <span key={ uuid() } className="d-none"/>;
		});
	}
	
	renderLegOverlays = () => {
		const { player, type, timeline } = this.state;
		const { fromIndex, toIndex } = this.props;

		if((type === 'record') || !player.initialized || ((fromIndex === -1) && (toIndex === -1))) return '';

		const styles = {};
		if(timeline.width > 0) {
			styles.width = `${ timeline.width }px`;
		}

		return (
			<div className="leg-overlays" style={ styles } ref={ this.cntLegOverlaysRef }>
				{ this.renderLegOverlayItems() }
			</div>
		);
	}

	renderStreetview = (id, className) => {
		const { layout } = this.props;
		const { center, pov, player } = this.state;
		let rotateControlOptions = {};

		if(layout === 'homepage') {
			rotateControlOptions = {
				position: this.google.ControlPosition.RIGHT_TOP
			}
		}

		return (
			<PlaybackStreetview 
				className={ className }
				id={ id }
				isPlaying={ player.isPlaying }
				isRecording={ player.isRecording }
				center={ center }
				pov={ pov }
				updateCenter={ this.updateCenter }
				updatePov={ this.updatePov }
				updateMouseState={ this.updateMouseState }
				ref={ this.streetviewRef }
				map={ this.mapRef }
				zoomControl={ layout === 'default' }
				rotateControlOptions={ rotateControlOptions }/>
		);
	}

	renderMap = (id, className) => {
		const { layout } = this.props;
		const { center } = this.state;

		if(this.isMediaMixer()) return '';

		return (
			<PlaybackMap 
				className={ className }
				id={ id }
				center={ center }
				streetViewControl
				updateCenter={ this.updateCenter }
				ref={ this.mapRef }
				streetview={ this.streetviewRef }
				zoomControl={ layout === 'default' }/>
		);
	}

	renderCaptions = () => {
		if(this.isMediaMixer()) return '';

		const { captions } = this.state;
		if(!captions.length) return '';

		const words = map(captions, caption => caption.word);

		return (
			<div className="leg-captions-container">
				<span>{ words.join(' ') }</span>
			</div>
		);
	}

	renderHierarchy = () => {
		const { hierarchy } = this.props;
		if(!hierarchy.length) return <li>N/A</li>;

		const folders = filter(hierarchy, item => item.nodeType === 20);
		return map(folders, item => <li key={ uuid() }>{ item.title }</li>);
	}

	renderDefaultStreetviewContent = () => {
		const { type } = this.state;

		if(type === 'musicMixer') return '';
		if(type === 'imageMixer') return this.renderImages();

		return (
			<Fragment>
				{ this.renderStreetview('streetview', 'streetview') }
				{ this.renderStvCursor() }
				{ this.renderImages() }
			</Fragment>
		);
	}

	renderLegDetails = () => {
		const { leg, tour, nodeType } = this.props;

		return (
			<ul>
				<li className={ nodeType !== 50 ? 'd-none' : '' }>
					<span className="item-title">Tour:</span>
					<span className="item-text">{ !isEmpty(tour) && tour.title ? tour.title : '--' }</span>
				</li>
				<li>
					<span className="item-title">Leg:</span>
					<span className="item-text">{ !isEmpty(leg) && leg.title ? leg.title : '--' }</span>
				</li>
				<li>
					<span className="item-title">Path:</span>
					<span className="item-text">
						<ul className="list-inline list-hierarchy">
							{ this.renderHierarchy() }
						</ul>
					</span>
				</li>
			</ul>
		);
	}
	
	renderMusicDetails = () => {
		const { music: { musicId, sequenceIndex } } = this.state;
		if(!musicId || (sequenceIndex < 0)) return '';

		const musicSequence = get(this.props.musicSequence, sequenceIndex);
		if(!musicSequence) return '';
		
		const music = find(this.props.music, { musicId });
		if(!music) return '';

		const media = get(this.props.media, music.mediaId);
		if(!media) return '';

		const musicData = [{
			title: 'Name',
			text: media.name
		},{
			title: 'Total Playtime',
			text: Utils.getReadableDuration(music.originalPlaytime)
		},{
			title: 'Segment Playtime',
			text: Utils.getReadableDuration(musicSequence.playTime)
		}];

		return <MediaInfo data={ musicData }/>;
	}
	
	renderImageDetails = () => {
		const { image: { imageId } } = this.state;
		if(!imageId) return '';

		const image = find(this.props.images, { imageId });
		if(!image) return '';

		const media = get(this.props.media, image.mediaId);
		if(!media) return '';

		const musicData = [{
			title: 'Name',
			text: media.name
		},{
			title: 'Playtime',
			text: Utils.getReadableDuration(image.playTime)
		}];

		return <MediaInfo data={ musicData }/>;
	}

	renderDetails = () => {
		const { type } = this.state;

		switch (type) {
			case 'musicMixer': return this.renderMusicDetails();
			case 'imageMixer': return this.renderImageDetails();
			default: return this.renderLegDetails();
		}
	}

	renderMiniLayout = () => {
		const styles = {
			display: 'none'
		}
		
		return (
			<div className="leg-player-container" style={ styles } data-player="leg">
				<div className="google-streetview-container cntGoogleStreetview" id="cntTourStreetView">
					<div className="streetview-inner">
						{ this.renderDefaultStreetviewContent() }
					</div>
				</div>
				<div className="leg-content-container" id="cntLegContent">
					<div className="leg-content-inner">
						<div className="leg-actions-details">
							<div className="leg-actions">
								<div className="leg-controls legControls">
									{ this.renderPlayerControls() }
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderDefaultLayout = () => {
		const { player, type, timeline } = this.state;

		const styles = {
			display: 'none'
		}

		const mixerClass = this.isMixer() ? 'mixer' : type;

		const parentStyles = {};
		if(timeline.initialWidth > 0) {
			parentStyles.width = `${ timeline.initialWidth }px`;
		} else {
			parentStyles.width = '';
		}

		if(this.isMixer()) {
			parentStyles.overflow = 'hidden';
		}

		const componentStyles = {};
		if(timeline.width > 0) {
			componentStyles.width = `${ timeline.width }px`;
		} else {
			componentStyles.width = '';
		}
		
		return (
			<div className="leg-player-container" style={ styles } data-player="leg">
				<div className="google-streetview-container cntGoogleStreetview" id="cntTourStreetView">
					<div className="streetview-inner">
						{ this.renderDefaultStreetviewContent() }
						{ this.renderCaptions() }
					</div>
				</div>
				<NoAnimationAlert spacing={ false }/>
				<div className="leg-audio-container">
					<LegAudio id={ `audio_${ uuid() }` } ref={ this.audioRef }/>
				</div>
				<div className="leg-content-container" id="cntLegContent">
					<div className="leg-content-inner">
						<div className="leg-actions-details">
							<div className="leg-actions">
								{ this.renderGlobalVolumeControls() }
								<div className={ `leg-controls legControls ${ this.isMixer() ? 'mixer-controls' : '' }` }>
									{ this.renderPlayerControls() }
								</div>
								<div className={ `leg-sliders-controls ${ mixerClass }-siders-controls` }>
									{ this.renderVolumeControls() }
									<div className={ `leg-progress-components ${ mixerClass }-components ${ player.initialized ? 'active' : '' }` } style={ parentStyles } data-progress-components>
										<Scrollbar className="components-overflow-handler" ref={ this.legProgressComponentsScrollbarRef } disableAxisY noPs={ type !== 'mixer' }>
											<div className="components-content" style={ componentStyles } ref={ this.legProgressComponentsRef }>
												{ this.renderSlider() }
												{ this.renderProgress() }
												{ this.renderImagesTimeline() }
												{ this.renderMusicTimeline() }
												{ this.renderLegMarkers() }
												{ this.renderLegOverlays() }
											</div>
										</Scrollbar>
									</div>
									{ this.renderZoomControls() }
								</div>
							</div>
							<div className="leg-details legDetails">
								{ this.renderDetails() }
							</div>
						</div>
						<div className="leg-map legMap">
							{ this.renderMap('graphicPanelMap', 'map-canvas') }
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderHomepageLayout = () => {
		const { active, isCursor } = this.props;
		const { player, stvMap, type, stvStreetview, mouse } = this.state;

		return (
			<ResizablePanel
				className={ `streetview ${ active ? 'active' : 'inactive' }` }
				id="tourplayer"
				containmentHeight={ 200 }
				containmentTop="65%"
				player="leg"
				ref={ this.cntPlayer }>
				<div className="streetview-pano cntGoogleStreetview" id="streetviewPano" data-rpane ref={ this.cntStreetviewPano }>
					{ this.renderStreetview('streetviewPanoCanvas', 'streetview-pano-canvas') }
					{ this.renderCaptions() }
					<div className={ `leg-progress-components ${ type }-components ${ player.initialized ? 'active' : '' }` } data-progress-components>
						{ this.renderSlider() }
						{ this.renderLegMarkers() }
					</div>
					{ this.renderImages() }
					<div className="controls-container">
						<div className="streetview-pano-controls" id="streetviewPanoControls">
							<ul className="primary-controls">
								<li className={ player.initialized ? 'd-block' : 'd-none' }>
									<Link to="#link" className="_msdc atn-reply atnReset" onClick={ this.onReplay } data-atn>
										<img src={ `${ Global.ASSETS_BASE_URL }/icons/streetview/repeat.svg` } className="img-responsive" alt=""/>
									</Link>
								</li>
								<li className={ (player.isPlaying || player.isPaused) ? 'd-block' : 'd-none' }>
									<Link to="#link" className="_msdc atn-prev atnPrev" onClick={ e => this.onLegNavigate(e, 'prev') } data-atn>
										<img src={ `${ Global.ASSETS_BASE_URL }/icons/streetview/prev.svg` } className="img-responsive" alt=""/>
									</Link>
								</li>
								<li className={ (!player.isPlaying || player.isPaused) ? 'd-block' : 'd-none' }>
									<Link to="#link" className="_msdc atn-play atnPlay lg" onClick={ this.onPlay } data-atn>
										<img src={ `${ Global.ASSETS_BASE_URL }/icons/streetview/play.svg` } className="img-responsive" alt=""/>
									</Link>
								</li>
								<li className={ (player.isPlaying && !player.isPaused) ? 'd-block' : 'd-none' }>
									<Link to="#link" className="_msdc atn-play atnPlay lg" onClick={ this.onPause } data-atn>
										<img src={ `${ Global.ASSETS_BASE_URL }/icons/streetview/pause.svg` } className="img-responsive" alt=""/>
									</Link>
								</li>
								<li className={ (player.isPlaying || player.isPaused) ? 'd-block' : 'd-none' }>
									<Link to="#link" className="_msdc atn-next atnNext" onClick={ e => this.onLegNavigate(e, 'next') } data-atn>
										<img src={ `${ Global.ASSETS_BASE_URL }/icons/streetview/next.svg` } className="img-responsive" alt=""/>
									</Link>
								</li>
							</ul>
							<ul className="secondary-controls">
								<li>
									<Link to="#link" className="_msdc atn-zoom atnZoom" onClick={ this.onZoomOut } data-atn>
										<i className="material-icons">&#xE15D;</i>
									</Link>
								</li>
								<li>
									<Link to="#link" className="_msdc atn-zoom atnZoom" onClick={ this.onZoomIn } data-atn>
										<i className="material-icons">&#xE148;</i>
									</Link>
								</li>
								<li>
									<Link to="#link" className="_msdc atn-url atnMapUrl" onClick={ this.onExtMap }>
										<i className="material-icons">&#xE569;</i>
									</Link>
								</li>
								<li>
									<Link to="#link" className="_msdc atn-url atnStreetUrl" onClick={ this.onExtStreetview }>
										<i className="material-icons">&#xE56E;</i>
									</Link>
								</li>
								<li className={ `${ !isCursor ? 'd-none' : '' } ${ !mouse.isVisible ? 'inactive' : '' }` }>
									<Link to="#link" className="_msdc atn-mouse atnMouseUrl" onClick={ this.onMouseTouch } data-atn>
										<i className="fas fa-mouse-pointer"/>
									</Link>
								</li>
								<li>
									<Link to="#link" className="_msdc atn-fullscreen atnFullscreen" onClick={ this.onFullscreen }>
										<img src={ `${ Global.ASSETS_BASE_URL }/icons/streetview/${ stvStreetview.fullscreen ? 'fullscreen-exit' : 'fullscreen'  }.svg` } className="img-responsive" alt=""/>
									</Link>
								</li>
								<li>
									<Link to="#link" className="_msdc atn-close atnClose" onClick={ this.onClose }>
										<img src={ `${ Global.ASSETS_BASE_URL }/icons/streetview/close.svg` } className="img-responsive" alt=""/>
									</Link>
								</li>
							</ul>
						</div>
						<div className={ `streetview-map-expander ${ stvMap.active ? 'd-none' : 'd-block' }` } id="streetMapExpander">
							<Link to="#link" className="_msdc btnExpandStreetMap" onClick={ this.onMapShow }>
								<i className="material-icons">&#xE55F;</i>
							</Link>
						</div>
					</div>
					{ this.renderStvCursor() }
				</div>
				<div className={ `streetview-map ${ stvMap.minimize ? 'minimize' : '' }` } id="streetviewMap" data-rpane ref={ this.cntMap }>
					<div className="streetview-map-actions">
						<ul className="list-inline-float">
							<li className={ `item-shrink ${ stvMap.minimize ? 'd-block' : 'd-none' }` }>
								<Link to="#link" className="btnShrinkStreetMap _msdc" onClick={ this.onMapHide }>
									<i className="material-icons">&#xE5C4;</i>
								</Link>
							</li>
							<li className={ `item-resize m-0 ${ stvMap.minimize ? 'd-block' : 'd-none' }` }>
								<Link to="#link" className="btnResizeStreetMap _msdc" onClick={ this.onMapExpand }>
									<i className="material-icons icon-expand">&#xE5D0;</i>
								</Link>
							</li>
							<li className={ `item-resize m-0 ${ !stvMap.minimize ? 'd-block' : 'd-none' }` }>
								<Link to="#link" className="btnResizeStreetMap _msdc" onClick={ this.onMapContract }>
									<i className="material-icons icon-contract">&#xE5D1;</i>
								</Link>
							</li>
						</ul>
					</div>
					{ this.renderMap('streetviewMapCanvas', 'streetview-map-canvas') }
				</div>
			</ResizablePanel>
		);
	}

	render() {
		const { layout } = this.props;

		switch(layout) {
			case 'mini': return this.renderMiniLayout();
			case 'default': return this.renderDefaultLayout();
			case 'homepage': return this.renderHomepageLayout();
			default: return '';
		}
	}
}

/* ----------  Prop Types  ---------- */

LegPlayer.defaultProps = {
	fromIndex: -1,
	toIndex: -1,
	
	startTime: -1,
	endTime: -1,

	playTime: 0,
	nodeType: 0,
	totalFrames: 0,
	activeLegIndex: 0,
	
	markers: [],
	position: [],

	token: '',

	leg: {},
	
	isCursor: false,
	
	tour: {},

	audioDevice: false,
	
	legs: [],
	media: {},
	music: {},
	images: [],
	hierarchy: [],
	musicSequence: {},
}

LegPlayer.propTypes = {
	// play: PropTypes.func.isRequired,
	replay: PropTypes.func.isRequired,
	startPlay: PropTypes.func.isRequired,
	saveRecord: PropTypes.func.isRequired,
	hidePanel: PropTypes.func.isRequired,
	
	onLegStop: PropTypes.func.isRequired,
	onLegStart: PropTypes.func.isRequired,
	onLegPause: PropTypes.func.isRequired,
	// onLegComplete: PropTypes.func.isRequired,
	onAllLegsComplete: PropTypes.func.isRequired,
	onLegPlayInitialized: PropTypes.func.isRequired,
	
	getGeoThumbnailUrl: PropTypes.func.isRequired,
	
	getRemainingTourPlaybackLegs: PropTypes.func.isRequired,
	
	setProgress: PropTypes.func.isRequired,
	
	updateMusicVolume: PropTypes.func.isRequired,
	updateAudioVolume: PropTypes.func.isRequired,

	token: PropTypes.string,
	type: PropTypes.string.isRequired,
	layout: PropTypes.string.isRequired,

	isCursor: PropTypes.bool,
	audioDevice: PropTypes.bool,
	active: PropTypes.bool.isRequired,

	// startTime: PropTypes.number,
	endTime: PropTypes.number,
	
	fromIndex: PropTypes.number,
	toIndex: PropTypes.number,

	playTime: PropTypes.number,
	nodeType: PropTypes.number,
	totalFrames: PropTypes.number,
	totalLegs: PropTypes.number.isRequired,
	activeLegIndex: PropTypes.number,

	markers: PropTypes.arrayOf(PropTypes.object),
	hierarchy: PropTypes.arrayOf(PropTypes.object),
	// position: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
	
	initialPosition: PropTypes.shape().isRequired,

	leg: PropTypes.shape(),
	tour: PropTypes.shape(),
	
	legs: PropTypes.arrayOf(PropTypes.object),
	music: PropTypes.arrayOf(PropTypes.object),
	media: PropTypes.objectOf(PropTypes.object),
	images: PropTypes.arrayOf(PropTypes.object),
	musicSequence: PropTypes.objectOf(PropTypes.object),
}

/* ----------  Exports  ---------- */

export default LegPlayer;
