/* ----------  Imports  ---------- */

// Local Storage
import Storage from './../../../Services/Storage';

/* ----------  Scripts  ---------- */

const USER_FCM_TOKEN_INITIAL_STATE = null;

const ReducerUserFCMToken = (state = USER_FCM_TOKEN_INITIAL_STATE, action) => {
	if(action.type === 'USER_FCM_TOKEN_UPDATE') {
		const { payload } = action;
		const token = Storage.getFcmToken();

		return payload || token;
	} else if(action.type === 'USER_FCM_TOKEN_SYNC') {
		return Storage.getFcmToken();
	} else if(action.type === 'USER_FCM_TOKEN_REMOVE') {
		Storage.removeFcmToken();
		return USER_FCM_TOKEN_INITIAL_STATE;
	}

	return state;
}

/* ----------  Exports  ---------- */

export default ReducerUserFCMToken;