/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { get } from 'lodash';
import { connect } from 'react-redux';

// moment
// import moment from 'moment';

// Constants
// import Global from './../../../Constants/Global';

/* ----------  Scripts  ---------- */

class LegItem extends React.Component {
	componentDidMount() {}

	onClick = e => {
		e.preventDefault();

		const { leg } = this.props;

		if(!leg.active) this.props.handleLeg(leg.legId);
	}

	onMouseEnter = () => {
		this.props.setLegHovered(this.props.leg.legId, 'leg');
	}

	onMouseLeave = () => {
		this.props.setLegsUnHovered(this.props.leg.legId);
	}

	handleContext = e => {
		e.preventDefault();
		e.stopPropagation();

		const { leg } = this.props;

		this.props.handleContext('legOptions', leg, e.currentTarget);
	}

	render() {
		const { leg, categories } = this.props;
		// const duration = moment.duration(leg.duration);
		const category = get(categories, leg.primaryCategory);
		const { licensing } = leg;
		const price = licensing ? licensing.price : 'N/A';
		const currency = licensing ? licensing.currency : '';

		return (
			<div
				className={ `market-item item-category-${ category ? category.theme : 'default' } mapResultItem ${ leg.active ? 'active' : '' } ${ leg.hovered ? 'state-hover' : '' }` }
				data-category={ category }
				data-leg={ leg.legId }
				onClick={ this.onClick }
				onMouseEnter={ this.onMouseEnter }
				onMouseLeave={ this.onMouseLeave }
				tabIndex="-1"
				role="button">
				<div className="item-inner">
					<div className="item-options">
						<Link to="#marker" className="atnItemContext" onClick={ this.handleContext }>
							<i className="material-icons">more_vert</i>
						</Link>
					</div>
					<div className="item-content">
						<div className="item-title m-b-5">
							<h5>{ leg.title }</h5>
						</div>
						<div className="item-meta">
							<div className="item-price m-b-5">
								<p><strong>{ currency } { price }</strong></p>
							</div>
							<div className="item-user">
								<p>{ leg.user ? leg.user.name : 'Anonymous' }</p>
							</div>
						</div>
						<div className="item-description itemDescription">
							<p>{ leg.description || 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita, natus!' }</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

LegItem.defaultProps = {
	leg: {},
	language: {},
	categories: [],
}

LegItem.propTypes = {
	handleLeg: PropTypes.func.isRequired,
	handleContext: PropTypes.func.isRequired,
	setLegHovered: PropTypes.func.isRequired,
	setLegsUnHovered: PropTypes.func.isRequired,

	leg: PropTypes.shape(),
	categories: PropTypes.objectOf(PropTypes.object),
}

/* ----------  Redux Scripts  ---------- */

const mapStateToProps = state => ({
	currencies: state.currencies
});

/* ----------  Exports  ---------- */

export default connect(mapStateToProps, null)(LegItem);
