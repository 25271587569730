/* ----------  Imports  ---------- */

// Helpers
import ConfigHelper from '../../../../Helpers/ConfigHelper';

/* ----------  Scripts  ---------- */

const SetAppConfigs = callback => dispatch => {
	const appConfigs = ConfigHelper.getAppConfigs();

	dispatch({
		type: 'SET_APP_CONFIGS',
		payload: appConfigs,
	});

	if(callback) callback();
};

/* ----------  Exports  ---------- */

export default SetAppConfigs;
