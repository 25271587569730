/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types 
import PropTypes from 'prop-types';

/* ----------  Scripts  ---------- */

const ConversationPlaceholder = ({ name }) => (
	<div className="view flex-column align-items-center justify-content-center d-flex" id="start-view">
		<div className="big-circle md-elevation-4 row align-items-center justify-content-center no-gutters">
			<i className="material-icons big-circle-icon">message</i>
		</div>
		<span className="app-title h3 my-3">Welcome, { name }</span>
		<span className="text-muted h6">Select contact to start the chat!</span>
	</div>
);

/* ----------  PropTypes  ---------- */

ConversationPlaceholder.defaultProps = {
	name: 'Guide'
}

ConversationPlaceholder.propTypes = {
	name: PropTypes.string
}

/* ----------  Exports  ---------- */

export default ConversationPlaceholder;