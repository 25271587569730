/* ----------  Scripts  ---------- */

const RemoveActionPanelNodes = () => dispatch => (
	dispatch({
		type: 'MYTOURS_TREES_REMOVE_ACTION_PANEL_NODES',
	})
);

/* ----------  Exports  ---------- */

export default RemoveActionPanelNodes;