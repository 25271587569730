/* ----------  Imports  ---------- */

// Lodash
import { isEmpty, isEqual } from 'lodash';

/* ----------  Scripts  ---------- */

const InputHelper = {
	isNumber: value => {
		const pattern = /^\d+$/;
		return pattern.test(value);
	},

	isEmpty: value => isEmpty(value),
	
	isEqual: (value, compare) => isEqual(value, compare),

	isFloat: value => {
		const pattern = /^-?\d*(\.\d+)?$/;
		return pattern.test(value);
	},

	isPlain: value => {
		const pattern = /^[a-zA-Z][a-zA-Z0-9]*$/;
		return pattern.test(value);
	},

	equalTo: (value, match = -1) => value === match,

	min: (value, min) => value.length >= min,

	max: (value, max) => value.length <= max,

	isEmail: value => {
		const pattern = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/
		return pattern.test(value);
	},
	
	isUsername: value => {
		const pattern = /^[a-zA-Z0-9]+(?:[._-][A-Za-z0-9]+)*$/
		return pattern.test(value);
	}
}

/* ----------  Exports  ---------- */

export default InputHelper;
