/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// moment
import moment from 'moment';

// Status
import Status from './Status';

// Status Constants
import STATUS from './../../Constants/Status';

// Utils
import ProfilePicture from './../../Components/Utils/ProfilePicture';

/* ----------  Scripts  ---------- */

class ConversationListItem extends React.Component {
	componentDidMount() {}

	handleContext = e => {
		e.preventDefault();
		e.stopPropagation();

		const { data, handleContext } = this.props;

		const props = {
			refId: data.conversationId,
			block: data.blocked
		}

		handleContext('conversation', props, e.currentTarget);
	}

	handleItem = e => {
		e.preventDefault();
		e.stopPropagation();

		const { handleItem, data } = this.props;

		handleItem(data.conversationId);
	}

	renderUnreadCount = () => {
		const { data: { unread } } = this.props;

		if(!unread) return <div className="d-none"/>;

		return (
			<div className="contact-unread-badge">
				<span className="bg-secondary text-auto unread-message-count">{ unread }</span>
			</div>
		);
	}

	renderTime = () => {
		const { data } = this.props;
		const { time } = data.lastMessage;

		if(!time) return <span className="d-none"/>;

		const timezone = moment.parseZone(moment().format()).format('Z');
		const mTime = moment(data.lastMessage.time).utcOffset(timezone);

		return (
			<div className="contact-message-time">
				<span>{ mTime.format('hh:mm:ss A') }</span> { mTime.format('DD MMM YY') }
			</div>
		);
	}

	render() {
		const { data } = this.props;

		return (
			<Link 
				to={ `#conversation_${ data.conversationId }` }
				className={ `contact ripple flex-wrap flex-sm-nowrap row p-4 no-gutters align-items-center ${ data.unread ? 'unread' : '' }` }
				data-ctxtoggle-parent
				data-conversation-item
				data-conversation-item-id={ data.conversationId }
				onClick={ this.handleItem }>
				<div className="contact-context">
					<span className="atn-context atnContext btnChatContext" data-ctx tabIndex="-1" role="button" onClick={ this.handleContext }>
						<i className="material-icons">more_vert</i>
					</span>
				</div>
				<div className="col-auto contact-avatar avatar-wrapper">
					<ProfilePicture title={ data.title } className="avatar" src={ data.photo }/>
					<Status type={ data.status }/>
					<Status visible={ data.blocked } type={ STATUS.type.blocked }/>
				</div>
				<div className="col contact-info px-4">
					<span className="name h6">{ data.title }</span>
					<p className="last-message text-truncate text-muted">{ data.lastMessage.user }: { data.lastMessage.text }</p>
				</div>
				{ this.renderUnreadCount() }
				{ this.renderTime() }
			</Link>
		);
	}
}

/* ----------  Prop Types  ---------- */

ConversationListItem.propTypes = {
	handleItem: PropTypes.func.isRequired,
	handleContext: PropTypes.func.isRequired,

	data: PropTypes.shape({
		title: PropTypes.string.isRequired,
		lastMessage: PropTypes.shape({
			user: PropTypes.string.isRequired,
			text: PropTypes.string.isRequired,
			time: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
		}).isRequired,
	}).isRequired
}

/* ----------  Exports  ---------- */

export default ConversationListItem;