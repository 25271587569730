/* ----------  Scripts  ---------- */

const LEG_LICENSE_INITIAL_STATE = {};

const ReducerMyToursTreesLegsNodeLicense = (state = LEG_LICENSE_INITIAL_STATE, action) => {
	if (action.type === 'MYTOURS_TREES_GET_LEG_LICENSE') {
		return action.payload.result;
	} else if (action.type === 'MYTOURS_TREES_SET_LEG_LICENSE') {
		return LEG_LICENSE_INITIAL_STATE;
	} else if (action.type === 'MYTOURS_TREES_REMOVE_LEG_LICENSE') {
		return LEG_LICENSE_INITIAL_STATE;
	}

	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMyToursTreesLegsNodeLicense;