/* ----------  Imports  ---------- */

// Lodash
import { mapKeys, maxBy, values } from 'lodash';

// New Message
import NewMessage from './../../../../../Helpers/NewMessage';

/* ----------  Scripts  ---------- */

const CONVERSATION_MESSAGES_DATA_INITIAL_STATE = {};

const ReducerMessagesConversationMessagesData = (state = CONVERSATION_MESSAGES_DATA_INITIAL_STATE, action) => {
	if(action.type === 'MESSAGES_GET_CONVERSATION_MESSAGES') {
		const response = mapKeys(action.payload.results, 'messageId');
		return { ...state, ...response };
	} else if(action.type === 'MESSAGES_SEND_CONVERSATION_MESSAGE') {
		const { data, user, messageId } = action.payload;
		const lastMessage = maxBy(values(state), 'autoId');
		const message = NewMessage.create(messageId, data, user, lastMessage);
		const newState = { ...state, [messageId]: message };

		return newState;
	} else if(action.type === 'MESSAGES_REMOVE_CONVERSATION_MESSAGES') {
		return CONVERSATION_MESSAGES_DATA_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMessagesConversationMessagesData;