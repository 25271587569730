/* ----------  Imports  ---------- */

// Axios
import axios from 'axios';

// Globals
import Global from './../../../../Constants/Global';

/* ----------  Scripts  ---------- */

const SetContactBlock = (data, callback) => dispatch => {
	// const url = `${ Global.DUMMY_API_BASE_URL }/4J2ZhH9oS`;
	const url = `${ Global.API_BASE_URL }/messages/contact/block`;

	axios.post(url, data).then(() => {
		if(callback) callback();
	});
	
	dispatch({
		type: 'MESSAGES_SET_CONTACT_BLOCK',
		payload: data.contactId
	});
}

/* ----------  Exports  ---------- */

export default SetContactBlock;