/* ----------  Scripts  ---------- */

const UpdateMusicVolume = (data, callback) => dispatch => {
	dispatch({
		type: 'UPDATE_MUSIC_VOLUME',
		payload: data
	});

	if(callback) callback();
};

/* ----------  Exports  ---------- */

export default UpdateMusicVolume;
