/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
// import { map } from 'lodash';

// Gantt
// import Gantt from 'frappe-gantt';

// Modal
import Modal from '../../UIkit/Modal';

// Common
import Preloader from '../../Common/Preloader';

/* ----------  Scripts  ---------- */

class ModalBgMusicMixer extends React.Component {
	constructor(props) {
		super(props);

		this.modalRef = React.createRef();
		this.chartRef = React.createRef();
	}

	onShow = () => {
		this.props.onShow();
	}
	
	onHide = () => {
		this.props.onHide();
	}

	onSubmit = e => {
		e.preventDefault();

		console.log('hello');
	}

	show = () => {
		const modal = this.modalRef.current;
		modal.show();
	}

	hide = () => {
		const modal = this.modalRef.current;
		modal.hide();
	}

	renderForm = () => {
		const { loading, busy, data } = this.props;

		if(loading) return <Preloader loading center minimal/>;

		return (
			<form onSubmit={ this.onSubmit } data-id={ data.tourId }>
				<div className="uk-modal-header">
					<h3 className="uk-modal-title">Tour Music Editor</h3>
				</div>
				<div className="uk-modal-body">
					<h3>{ data.title }</h3>
				</div>
				<div className="uk-modal-footer">
					<div className="footer-preloader m-r-10">
						<Preloader size={ 20 } loading={ busy } relative minimal/>
					</div>
					<Link to="#closeModal" className="md-btn md-btn-flat md-btn-flat-default uk-modal-close">
						Cancel
					</Link>
					<button className={ `md-btn md-btn-flat md-btn-flat-default ${ busy ? 'disabled' : '' }` } onClick={ this.onSubmit }>
						Save
					</button>
				</div>
			</form>
		);
	}

	render() {
		return (
			<Modal
				bgClose={ false }
				popup="musicEditor" 
				onShow={ this.onShow }
				onHide={ this.onHide }
				keyboardClose={ false }
				ref={ this.modalRef }>
				<div className="uk-modal-dialog uk-modal-dialog-large">
					{ this.renderForm() }
				</div>
			</Modal>
		);
	}
}

/* ----------  Prop Types  ---------- */

ModalBgMusicMixer.defaultProps = {
	busy: false,
	loading: true,
	onShow: false,
	onHide: false,

	data: {}
}

ModalBgMusicMixer.propTypes = {
	busy: PropTypes.bool,
	loading: PropTypes.bool,

	data: PropTypes.shape(),

	onShow: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	onHide: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
}

/* ----------  Exports  ---------- */

export default ModalBgMusicMixer;
