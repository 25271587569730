/* ----------  Scripts  ---------- */

const SetLegActive = legId => dispatch => (
	dispatch({
		type: 'MARKETPLACE_SET_LEG_ACTIVE',
		payload: legId
	})
);

/* ----------  Exports  ---------- */

export default SetLegActive;