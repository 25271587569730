/* ----------  Imports  ---------- */

// Axios
import axios from 'axios';

// Globals
import Global from './../../../../../Constants/Global';

/* ----------  Scripts  ---------- */

const GetConversationMessages = (data, success, fail) => dispatch => {
	// const url = `${ Global.DUMMY_API_BASE_URL }/EyItbYJwH`;
	const url = `${ Global.API_BASE_URL }/messages`;

	axios.get(url, {
		params: data
	}).then(response => {
		const { result } = response.data;

		dispatch({
			type: 'MESSAGES_GET_CONVERSATION_MESSAGES',
			payload: result
		});

		if(success) success(result.meta);
	}).catch(error => {
		const { response } = error;

		if(fail) {
			if(response) {
				fail(response.data.status, response.data.error.reasons);
			} else {
				fail(false, []);
			}
		}
	});
}

/* ----------  Exports  ---------- */

export default GetConversationMessages;