/* ----------  Scripts  ---------- */

const FAVORITE_LEGS_INITIAL_STATE = [];

const ReducerMarketplaceFavoriteLegs = (state = FAVORITE_LEGS_INITIAL_STATE, action) => {
	if(action.type === 'MARKETPLACE_GET_FAVORITE_LEGS') {
		return [ ...state, ...action.payload.results ];
	} else if(action.type === 'MARKETPLACE_REMOVE_FAVORITE_LEGS') {
		return FAVORITE_LEGS_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMarketplaceFavoriteLegs;