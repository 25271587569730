/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Redux
import { connect } from 'react-redux';

// Redux
import { bindActionCreators } from 'redux';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { map, get, debounce } from 'lodash';

// UUID
import uuid from 'uuid/v4';

// UIkit
import UIkit from 'uikit';

// Context Menus
import ContextMenu from './../../Components/ContextMenu/';

// Components
import Conversation from './../../Components/Messages/Conversation';
import ContactsLoader from './../../Components/Messages/ContactsLoader';
import ContactListItem from './../../Components/Messages/ContactListItem';
import ContactSearchForm from './../../Components/Messages/ContactSearchForm';
import ConversationListItem from './../../Components/Messages/ConversationListItem';
import ConversationPlaceholder from './../../Components/Messages/ConversationPlaceholder';

// User Actions
import GetUserStatus from './../../Redux/Actions/User/GetUserStatus';
import SetUserStatus from './../../Redux/Actions/User/SetUserStatus';
import GetUserProfile from './../../Redux/Actions/User/GetUserProfile';

// Conversations List Actions
import AddConversationItem from './../../Redux/Actions/Messages/ConversationsList/AddConversationItem';
import SetConversationRead from './../../Redux/Actions/Messages/ConversationsList/SetConversationRead';
import SetConversationBlock from './../../Redux/Actions/Messages/ConversationsList/SetConversationBlock';
import GetConversationsList from './../../Redux/Actions/Messages/ConversationsList/GetConversationsList';
import ReportConversationItem from './../../Redux/Actions/Messages/ConversationsList/ReportConversationItem';
import SetConversationUnblock from './../../Redux/Actions/Messages/ConversationsList/SetConversationUnblock';
import RemoveConversationItem from './../../Redux/Actions/Messages/ConversationsList/RemoveConversationItem';
import RemoveConversationsList from './../../Redux/Actions/Messages/ConversationsList/RemoveConversationsList';

// Contacts List Actions
import GetContactsList from './../../Redux/Actions/Messages/ContactsList/GetContactsList';
import SetContactBlock from './../../Redux/Actions/Messages/ContactsList/SetContactBlock';
import RemoveContactItem from './../../Redux/Actions/Messages/ContactsList/RemoveContactItem';
import ReportContactItem from './../../Redux/Actions/Messages/ContactsList/ReportContactItem';
import SetContactUnblock from './../../Redux/Actions/Messages/ContactsList/SetContactUnblock';
import RemoveContactsList from './../../Redux/Actions/Messages/ContactsList/RemoveContactsList';

// Conversation Theme Actions
import GetConversationTheme from './../../Redux/Actions/Messages/Conversation/GetConversationTheme';
import SetConversationTheme from './../../Redux/Actions/Messages/Conversation/SetConversationTheme';

// Conversation Messages Actions
import GetConversationMessages from './../../Redux/Actions/Messages/Conversation/ConversationMessages/GetConversationMessages';
import SendConversationMessage from './../../Redux/Actions/Messages/Conversation/ConversationMessages/SendConversationMessage';
import RemoveConversationMessages from './../../Redux/Actions/Messages/Conversation/ConversationMessages/RemoveConversationMessages';

// Status
import Status from './../../Components/Messages/Status';

// Status Constants
import STATUS from './../../Constants/Status';

// Preloader
import Preloader from './../../Components/Common/Preloader';

// Utils
import FullHeight from './../../Components/Utils/FullHeight';
import Scrollbar from './../../Components/Utils/Scrollbar';
import ProfilePicture from './../../Components/Utils/ProfilePicture';

// Helpers
import Ctx from './../../Helpers/Ctx';
import Notify from './../../Helpers/Notify';

/* ----------  Scripts  ---------- */

class Messages extends React.Component {
	constructor(props) {
		super(props);

		this.listScrollbar = React.createRef();
		this.contactListRef = React.createRef();

		this.isLoaded = false;
		
		this.state = {
			conversation: {
				active: false,
				conversationId: ''
			},

			conversationMessages: {
				loading: true
			},

			conversationsList: {
				loading: true,
				adding: false,
			},

			contactsList: {
				loading: true,
			},

			filters: {
				term: ''
			},

			meta: {
				startIndex: 0,
				count: 10,
				total: 0,
			},

			ctx: {
				userOptions: {
					refId: '',
					status: '',
					theme: ''
				},

				conversation: {
					refId: '',
					block: false,
				},

				contact: {
					refId: '',
					block: false,
				},
			}
		}

		this.requestUserStatus = debounce(this.doRequestUserStatus, 200);
		this.requestUserProfile = debounce(this.doRequestUserProfile, 200);
		this.requestContactsList = debounce(this.doRequestContactsList, 2000);
		this.requestConversationTheme = debounce(this.doRequestConversationTheme, 500);
		this.requestConversationsList = debounce(this.doRequestConversationsList, 2000);
		this.requestConversationMessages = debounce(this.doRequestConversationMessages, 2000);
	}

	componentDidMount() {
		this.isLoaded = true;

		this.getUserStatus();
		this.getContactsList();
		this.getConversationTheme();
		this.getConversationsList();

		Ctx.init();
	}

	componentWillUnmount() {
		this.isLoaded = false;

		this.removeContactsList();
		this.removeConversationsList();
	}

	getContactsList = () => {
		this.setState({
			contactsList: {
				loading: true
			}
		}, this.requestContactsList);
	}

	getConversationsList = () => {
		this.removeConversationsList();

		this.setState({
			conversationsList: {
				loading: true
			}
		}, this.requestConversationsList);
	}

	getConversationTheme = () => {
		this.requestConversationTheme();
	}

	getUserProfile = () => {
		this.requestUserProfile();
	}

	getUserStatus = () => {
		this.requestUserStatus();
	}

	getConversationMessages = (conversationId, meta, success, fail) => {
		this.setState({
			conversationMessages: {
				loading: true
			}
		}, () => this.requestConversationMessages(conversationId, meta, success, fail));
	}

	setUserStatus = status => {
		const data = {
			status
		}

		this.props.setUserStatus(data);
	}

	setConversationBlock = data => {
		this.props.setConversationBlock(data);
	}

	setConversationUnblock = data => {
		this.props.setConversationUnblock(data);
	}

	setContactBlock = data => {
		this.props.setContactBlock(data);
	}

	setContactUnblock = data => {
		this.props.setContactUnblock(data);
	}

	setConversationTheme = theme => {
		const data = {
			theme
		}

		this.props.setConversationTheme(data);
	}

	doRequestUserProfile = () => {
		this.props.getUserProfile();
	}

	doRequestUserStatus = () => {
		this.props.getUserStatus();
	}

	doRequestConversationMessages = (conversationId, meta, success, fail) => {
		const data = {
			startIndex: meta.startIndex,
			count: meta.count,
			conversationId
		}

		this.props.getConversationMessages(data, response => {
			this.setState({
				conversationMessages: {
					loading: false,
				}
			});

			if(success) success(response);
		}, () => {
			this.setState({
				conversationMessages: {
					loading: false,
				}
			}, this.closeConversation);

			Notify.error('Oops! Something went wrong.');

			if(fail) fail();
		});
	}

	doRequestConversationTheme = () => {
		this.props.getConversationTheme();
	}

	doRequestContactsList = () => {
		const { filters, meta } = this.state;
		const data = {
			...filters,
			startIndex: meta.startIndex,
			count: meta.count
		}

		this.props.getContactsList(data, (status, response) => {
			this.setState({
				contactsList: {
					loading: false,
				}
			});

			this.updateMeta({
				total: response ? response.total : 0
			});
		}, () => {
			this.setState({
				contactsList: {
					loading: false,
				}
			});
		});
	}

	doRequestConversationsList = () => {
		const { filters } = this.state;
		const data = {
			...filters,
			startIndex: 0,
			count: 999
		}

		this.props.getConversationsList(data, () => {
			this.setState({
				conversationsList: {
					loading: false,
				}
			});
		}, () => {
			this.setState({
				conversationsList: {
					loading: false,
				}
			});
		});
	}

	addConversationItem = contactIds => {
		const data = {
			contactIds,
		}

		this.updateScroll(0);

		this.setState({
			conversationsList: {
				adding: true
			}
		}, () => {
			this.props.addConversationItem(data, (status, conversationId) => {
				this.setState({
					conversationsList: {
						adding: false
					}
				});

				this.startConversation(conversationId);
			});
		});
	}

	ctxChangeStatus = (e, status) => {
		e.preventDefault();

		this.setUserStatus(status);
		Ctx.close();
	}

	ctxSetTheme = (e, theme) => {
		e.preventDefault();

		this.setConversationTheme(theme);
		Ctx.close();
	}

	ctxChangeTheme = (e, userOptions) => {
		e.preventDefault();

		this.handleContext('themes', userOptions, e.currentTarget);
	}
	
	ctxConversationReport = e => {
		e.preventDefault();

		const conversationId = Ctx.getRefId('conversation');
		this.props.handleReport('conversation', conversationId);
		
		Ctx.close();
	}
	
	ctxConversationBlock = (e, block) => {
		e.preventDefault();

		const conversationId = Ctx.getRefId('conversation');

		const data = {
			conversationId
		}

		if(block) {
			this.setConversationUnblock(data);
		} else {
			this.setConversationBlock(data);
		}

		Ctx.close();
	}
	
	ctxConversationRemove = (e) => {
		e.preventDefault();

		const conversationId = Ctx.getRefId('conversation');

		this.removeConversationItem(conversationId);

		Ctx.close();
	}

	ctxContactReport = e => {
		e.preventDefault();

		const contactId = Ctx.getRefId('contact');
		this.props.handleReport('contact', contactId);
		Ctx.close();
	}
	
	ctxContactBlock = (e, block) => {
		e.preventDefault();

		const contactId = Ctx.getRefId('contact');

		const data = {
			contactId
		}

		if(block) {
			this.setContactUnblock(data);
		} else {
			this.setContactBlock(data);
		}

		Ctx.close();
	}
	
	ctxContactRemove = (e) => {
		e.preventDefault();

		const contactId = Ctx.getRefId('contact');

		this.removeContactItem(contactId);

		Ctx.close();
	}

	closeConversation = callback => {
		this.setState({
			conversation: {
				active: false,
				conversationId: ''
			}
		}, () => callback ? callback() : null);
	}

	removeConversationItem = conversationId => {
		const data = {
			conversationId
		}

		UIkit.modal.confirm(`Are you sure you want to delete this conversation?`, () => {
			this.props.removeConversationItem(data);
			Notify.success(`Conversation deleted successfully!`);
		});
	}

	removeContactItem = contactId => {
		const data = {
			contactId
		}

		UIkit.modal.confirm(`Are you sure you want to delete this contact?`, () => {
			this.props.removeContactItem(data);
			Notify.success(`Contact deleted successfully!`);
		});

	}

	handleContactItem = contactIds => {
		this.addConversationItem(contactIds);
	}

	handleConversationItem = conversationId => {
		this.startConversation(conversationId);
	}

	handleScroll = () => {
		const { meta, loading } = this.state;
		const contactList = this.contactListRef.current;
		const listHeight = contactList.scrollHeight;

		if(loading || (listHeight < 100)) return false;

		const startIndex = meta.startIndex + meta.count;

		if(meta.total > startIndex) {
			this.updateMeta({ startIndex }, this.getContactsList);
		}

		return true;
	}

	handleContext = (type, props, target, event) => {
		this.updateCtxContent(type, props, () => Ctx.open(type, target, event));
	}

	sendMessage = (conversationId, message, callback) => {
		const data = {
			conversationId,
			message
		}

		this.props.sendConversationMessage(data, this.props.user, () => {
			if(callback) callback();
		});
	}

	sendReport = (report, callback) => {
		if(report.type === 'conversation') {
			this.sendConversationReport(report, callback);
		} else if(report.type === 'contact') {
			this.sendContactReport(report, callback);
		}
	}

	sendConversationReport = (report, callback) => {
		const data = {
			message: report.message,
			conversationId: report.refId
		}

		this.props.reportConversationItem(data, callback);
	}

	sendContactReport = (report, callback) => {
		const data = {
			message: report.message,
			contactId: report.refId
		}

		this.props.reportContactItem(data, callback);
	}

	startConversation = conversationId => {
		const data = {
			conversationId
		}

		this.closeConversation(() => {
			this.props.setConversationRead(data);
			this.setState({
				conversation: {
					active: true,
					conversationId
				}
			});
		});
	}

	updateSearch = term => {
		const { filters, meta } = this.state;
		
		this.setState({
			filters: {
				...filters,
				term
			},
			meta: {
				...meta,
				startIndex: 0,
			}
		}, () => {
			this.updateScroll(0);

			this.closeConversation();
			this.removeContactsList();

			this.getContactsList();
			this.getConversationsList();
		});
	}

	updateMeta = (updates, callback) => {
		const { meta } = this.state;

		this.setState({
			meta: {
				...meta,
				...updates
			}
		}, () => callback ? callback() : null);
	}

	updateScroll = position => {
		const scrollbar = this.listScrollbar.current;
		scrollbar.update(position);
	}

	updateCtxContent = (type, props, callback) => {
		const { ctx } = this.state;
		
		this.setState({
			ctx: {
				...ctx,
				[type]: {
					...props
				}
			}
		}, callback ? callback() : null);
	}

	removeContactsList = () => {
		this.props.removeContactsList();
	}

	removeConversationsList = () => {
		this.props.removeConversationsList();
	}

	removeConversationMessages = () => {
		this.props.removeConversationMessages();
	}

	renderConversationsList = () => {
		const { conversationsList, conversationsListData } = this.props;
		const { loading } = this.state.conversationsList;

		if(loading) return <div/>;

		if(!loading && !conversationsList.length) return <span className="no-records d-block uk-text-small font-italic text-muted p-h-10">No active conversations found.</span>;

		return map(conversationsList, listItem => {
			const item = get(conversationsListData, listItem.conversationId);

			return (
				<ConversationListItem
					key={ uuid() }
					data={ item }
					handleContext={ this.handleContext }
					handleItem={ this.handleConversationItem }/>
			);
		});
	}

	renderContactsList = () => {
		const { contactsList, contactsListData } = this.props;
		const { loading } = this.state.contactsList;

		if(!loading && !contactsList.length) return <span className="no-records d-block uk-text-small font-italic text-muted p-h-10">No contacts found.</span>;

		return map(contactsList, listItem => {
			const item = get(contactsListData, listItem.contactId);

			return (
				<ContactListItem
					key={ uuid() }
					data={ item }
					handleItem={ this.handleContactItem }
					handleContext={ this.handleContext }/>
			);
		});
	}

	renderConversation = () => {
		const { conversation, conversationMessages } = this.state;
		const { user, conversationsListData, theme, messages, messagesData } = this.props;
		const conversationObj = get(conversationsListData, conversation.conversationId);

		if(!conversation.active || !conversationObj) return <ConversationPlaceholder name={ user.profile.name }/>;

		return (
			<Conversation
				user={ user }
				close={ this.closeConversation }
				conversation={ conversationObj }
				messages={ messages }
				theme={ theme }
				messagesData={ messagesData }
				sendMessage={ this.sendMessage }
				loading={ conversationMessages.loading }
				loadMessages={ this.getConversationMessages }
				unloadMessages={ this.removeConversationMessages }/>
		);
	}

	renderUserOptionsCtx = () => {
		const { userOptions } = this.state.ctx;

		return (
			<ContextMenu type="userOptions" refId={ userOptions.refId }>
				<li className={ `${ userOptions.status === STATUS.type.online ? 'd-none' : 'd-block' }` }>
					<Link to="#ctx" onClick={ e => this.ctxChangeStatus(e, STATUS.type.online) }>
						<i className="item-icon material-icons">{ STATUS.icon.online }</i> 
						{ STATUS.text.online }
					</Link>
				</li>
				<li className={ `${ userOptions.status === STATUS.type.offline ? 'd-none' : 'd-block' }` }>
					<Link to="#ctx" onClick={ e => this.ctxChangeStatus(e, STATUS.type.offline) }>
						<i className="item-icon material-icons">{ STATUS.icon.offline }</i> 
						{ STATUS.text.offline }
					</Link>
				</li>
				<li className={ `${ userOptions.status === STATUS.type.away ? 'd-none' : 'd-block' }` }>
					<Link to="#ctx" onClick={ e => this.ctxChangeStatus(e, STATUS.type.away) }>
						<i className="item-icon material-icons">{ STATUS.icon.away }</i> 
						{ STATUS.text.away }
					</Link>
				</li>
				<li className={ `${ userOptions.status === STATUS.type.dnd ? 'd-none' : 'd-block' }` }>
					<Link to="#ctx" onClick={ e => this.ctxChangeStatus(e, STATUS.type.dnd) }>
						<i className="item-icon material-icons">{ STATUS.icon.dnd }</i> 
						{ STATUS.text.dnd }
					</Link>
				</li>
				<li className="divider"/>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxChangeTheme(e, userOptions) }>
						<i className="item-icon material-icons">color_lens</i>
						Conversation Theme
					</Link>
					<ContextMenu type="themes" refId={ userOptions.refId } sub>
						<li>
							<Link to="#ctx" data-theme="gray-green" onClick={ e => this.ctxSetTheme(e, 'gray-green') }>
								<i className={ `item-icon material-icons text-success ${ userOptions.theme === 'gray-green' ? 'd-block' : 'd-none' }` }>check_circle</i>
								Gray/Green
							</Link>
						</li>
						<li>
							<Link to="#ctx" data-theme="blue-darkblue" onClick={ e => this.ctxSetTheme(e, 'blue-darkblue') }>
								<i className={ `item-icon material-icons text-success ${ userOptions.theme === 'blue-darkblue' ? 'd-block' : 'd-none' }` }>check_circle</i>
								Blue/Dark Blue
							</Link>
						</li>
						<li>
							<Link to="#ctx" data-theme="lightgray-orange" onClick={ e => this.ctxSetTheme(e, 'lightgray-orange') }>
								<i className={ `item-icon material-icons text-success ${ userOptions.theme === 'lightgray-orange' ? 'd-block' : 'd-none' }` }>check_circle</i>
								Light Gray/Orange
							</Link>
						</li>
						<li>
							<Link to="#ctx" data-theme="deepgray-lightpurple" onClick={ e => this.ctxSetTheme(e, 'deepgray-lightpurple') }>
								<i className={ `item-icon material-icons text-success ${ userOptions.theme === 'deepgray-lightpurple' ? 'd-block' : 'd-none' }` }>check_circle</i>
								Deep Gray/Light Purple
							</Link>
						</li>
					</ContextMenu>
				</li>
			</ContextMenu>
		);
	}

	renderConversationCtx = () => {
		const { conversation } = this.state.ctx;

		return (
			<ContextMenu type="conversation" refId={ conversation.refId }>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxConversationReport(e) }>
						<i className="item-icon material-icons">report</i>
						Report
					</Link>
				</li>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxConversationBlock(e, conversation.block) }>
						<i className="item-icon material-icons">block</i>
						{ conversation.block ? 'Unblock' : 'Block' }
					</Link>
				</li>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxConversationRemove(e) }>
						<i className="item-icon material-icons">delete</i>
						Delete
					</Link>
				</li>
			</ContextMenu>
		);
	}

	renderContactCtx = () => {
		const { contact } = this.state.ctx;

		return (
			<ContextMenu type="contact" refId={ contact.refId }>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxContactReport(e) }>
						<i className="item-icon material-icons">report</i>
						Report
					</Link>
				</li>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxContactBlock(e, contact.block) }>
						<i className="item-icon material-icons">block</i>
						{ contact.block ? 'Unblock' : 'Block' }
					</Link>
				</li>
				<li>
					<Link to="#ctx" onClick={ e => this.ctxContactRemove(e) }>
						<i className="item-icon material-icons">delete</i>
						Delete
					</Link>
				</li>
			</ContextMenu>
		);
	}

	render() {
		const { conversationsList, contactsList } = this.state;
		const { user, theme } = this.props;
		const userCtxOptions = {
			refId: user.profile.userId,
			status: user.status,
			theme,
		}

		return (
			<div className="page-content-card row no-gutters">
				<FullHeight className="left-sidebar">
					<div className="views" id="chat-left-sidebar-views">
						<div className="view d-flex flex-column no-gutters" id="chats-view" data-view="chat">
							<div className="toolbar">
								<div className="toolbar-top row no-gutters align-items-center px-4" data-ctxtoggle-parent>
									<div className="avatar-wrapper">
										<Link to="/profile">
											<ProfilePicture title={ user.profile.name } className="avatar" src={ user.profile.photo }/>
											<Status type={ user.status }/>
										</Link>
									</div>
									<button className="btn btn-icon btn-context btnUserContext" type="button" onClick={ e => this.handleContext('userOptions', userCtxOptions, e.currentTarget) }>
										<span className="material-icons">more_vert</span>
									</button>
								</div>
								<div className="toolbar-bottom row align-items-center no-gutters px-4">
									<ContactSearchForm updateSearch={ this.updateSearch }/>
								</div>
							</div>
							<Scrollbar
								className="content col messageListContent"
								onYScrollEnd={ this.handleScroll }
								ref={ this.listScrollbar }>
								<div className="content-section">
									<div className="section-title">
										<h4>Chats</h4>
									</div>
									<div className="section-content chat-list messageList">
										<Preloader loading={ conversationsList.loading || conversationsList.adding } center/>
										{ this.renderConversationsList() }
									</div>
								</div>
								<div className="content-section">
									<div className="section-title">
										<h4>Contacts</h4>
									</div>
									<div className="section-content contact-list messageList contactList" ref={ this.contactListRef }>
										{ this.renderContactsList() }
									</div>
								</div>
							</Scrollbar>
							<ContactsLoader loading={ contactsList.loading }/>
						</div>
					</div>
				</FullHeight>
				<div className="content col chat-content-view">
					<FullHeight className="views" id="chat-content-views">
						{ this.renderConversation() }
					</FullHeight>
				</div>
				<div className="contexts-container">
					{ this.renderUserOptionsCtx() }
					{ this.renderConversationCtx() }
					{ this.renderContactCtx() }
				</div>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

Messages.defaultProps = {
	user: {
		status: STATUS.type.away,
		profile: {
			userId: ''
		}
	}
}

Messages.propTypes = {
	handleReport: PropTypes.func.isRequired,

	getUserStatus: PropTypes.func.isRequired,
	setUserStatus: PropTypes.func.isRequired,
	getUserProfile: PropTypes.func.isRequired,
	getContactsList: PropTypes.func.isRequired,
	setContactBlock: PropTypes.func.isRequired,
	reportContactItem: PropTypes.func.isRequired,
	setContactUnblock: PropTypes.func.isRequired,
	removeContactItem: PropTypes.func.isRequired,
	removeContactsList: PropTypes.func.isRequired,
	addConversationItem: PropTypes.func.isRequired,
	setConversationRead: PropTypes.func.isRequired,
	setConversationBlock: PropTypes.func.isRequired,
	getConversationsList: PropTypes.func.isRequired,
	getConversationTheme: PropTypes.func.isRequired,
	setConversationTheme: PropTypes.func.isRequired,
	reportConversationItem: PropTypes.func.isRequired,
	setConversationUnblock: PropTypes.func.isRequired,
	removeConversationItem: PropTypes.func.isRequired,
	removeConversationsList: PropTypes.func.isRequired,
	getConversationMessages: PropTypes.func.isRequired,
	sendConversationMessage: PropTypes.func.isRequired,
	removeConversationMessages: PropTypes.func.isRequired,

	theme: PropTypes.string.isRequired,

	messages: PropTypes.arrayOf(PropTypes.object).isRequired,
	contactsList: PropTypes.arrayOf(PropTypes.object).isRequired,
	conversationsList: PropTypes.arrayOf(PropTypes.object).isRequired,
	
	messagesData: PropTypes.objectOf(PropTypes.object).isRequired,
	contactsListData: PropTypes.objectOf(PropTypes.object).isRequired,
	conversationsListData: PropTypes.objectOf(PropTypes.object).isRequired,

	user: PropTypes.shape({
		status: PropTypes.number,
		profile: PropTypes.shape({
			userId: PropTypes.string
		})
	})
}

/* ----------  Redux  ---------- */

const mapStateToProps =  state => ({
	user: state.user,

	conversationsList: state.messages.conversationsList.list,
	conversationsListData: state.messages.conversationsList.listData,

	contactsList: state.messages.contactsList.list,
	contactsListData: state.messages.contactsList.listData,

	theme: state.messages.conversation.theme,

	messages: state.messages.conversation.conversationMessages.messages,
	messagesData: state.messages.conversation.conversationMessages.messagesData,
});

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		getUserStatus: GetUserStatus,
		setUserStatus: SetUserStatus,
		getUserProfile: GetUserProfile,

		setConversationRead: SetConversationRead,
		addConversationItem: AddConversationItem,
		getConversationsList: GetConversationsList,
		setConversationBlock: SetConversationBlock,
		reportConversationItem: ReportConversationItem,
		removeConversationItem: RemoveConversationItem,
		setConversationUnblock: SetConversationUnblock,
		removeConversationsList: RemoveConversationsList,

		getContactsList: GetContactsList,
		setContactBlock: SetContactBlock,
		removeContactItem: RemoveContactItem,
		reportContactItem: ReportContactItem,
		setContactUnblock: SetContactUnblock,
		removeContactsList: RemoveContactsList,

		getConversationTheme: GetConversationTheme,
		setConversationTheme: SetConversationTheme,

		getConversationMessages: GetConversationMessages,
		sendConversationMessage: SendConversationMessage,
		removeConversationMessages: RemoveConversationMessages,
	}, dispatch)
);

/* ----------  Exports  ---------- */

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Messages);
