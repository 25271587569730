/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Helpers
import InputUI from './../../Helpers/Fuse/InputUI';

/* ----------  Imports  ---------- */

class Input extends React.Component {
	constructor(props) {
		super(props);

		this.input = React.createRef();
	}

	componentDidMount() {
		InputUI.initialize(this.input.current);
	}

	onChange = e => {
		const { onChange } = this.props;

		if(onChange) onChange(e);
	}

	render() {
		const { label, id, groupClass, name, type, value, readOnly } = this.props;

		return (
			<div className={ `form-group formGroup ${ groupClass }` }>
				<input
					type={ type }
					className="form-control"
					name={ name }
					value={ value }
					id={ id }
					autoComplete="off"
					onChange={ this.onChange }
					ref={ this.input }
					readOnly={ readOnly }/>
				<label className={ !label ? 'd-none' : '' } htmlFor={ id }>{ label }</label>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

Input.defaultProps = {
	value: '',
	type: 'text',
	groupClass: '',
	label: '',

	readOnly: false,

	onChange: false,
}

Input.propTypes = {
	type: PropTypes.string,
	label: PropTypes.string,
	groupClass: PropTypes.string,
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,

	readOnly: PropTypes.bool,
	
	onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),

	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

/* ----------  Exports  ---------- */

export default Input;
