/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Forms
import LoginForm from './LoginForm';
import ForgotPasswordForm from './ResetPassword/ForgotPasswordForm';
import VerifyCodeForm from './ResetPassword/VerifyCodeForm';
import ResetPasswordForm from './ResetPassword/ResetPasswordForm';

/* ----------  Auth Login  ---------- */

class AuthLogin extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			type: 'LOGIN',
			email: '',
			code: '',
		}
	}

	componentDidMount() {}

	changeForm = type => this.setState({ type })

	updateAuthState = (updates, callback) => {
		this.setState({
			...this.state,
			...updates
		}, callback);
	}

	renderForm = () => {
		const { type } = this.state;

		switch (type) {
			case 'LOGIN': return <LoginForm changeForm={ this.changeForm } openRegisterForm={ this.props.openRegisterForm } stripe={ this.props.stripe }/>;
			case 'FORGOT_PASSWORD': return <ForgotPasswordForm changeForm={ this.changeForm } updateAuthState={ this.updateAuthState }/>;
			case 'VERIFY_CODE': return <VerifyCodeForm changeForm={ this.changeForm } data={ this.state } updateAuthState={ this.updateAuthState }/>;
			case 'RESET_PASSWORD': return <ResetPasswordForm changeForm={ this.changeForm } data={ this.state } updateAuthState={ this.updateAuthState }/>;
			default: return <LoginForm changeForm={ this.changeForm } openRegisterForm={ this.props.openRegisterForm }/>;
		}
	}

	render() {
		return (
			<div className="tpl-fuse">
				<div className="auth-login">
					{ this.renderForm() }
				</div>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

AuthLogin.defaultProps = {
	stripe: {}
}

AuthLogin.propTypes = {
	openRegisterForm: PropTypes.func.isRequired,
	stripe: PropTypes.shape()
}

/* ----------  Exports  ---------- */
export default AuthLogin;
