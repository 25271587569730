/* ----------  Imports  ---------- */

// jQuery
import $ from 'jquery';

// Lodash
import { map } from 'lodash';

/* ----------  Imports  ---------- */

const InputUI = {
	update: parent => {
		parent = parent || document;

		const inputs = parent.querySelectorAll('[data-md-input]');

		if(inputs.length) {
			map(inputs, input => window.altair_md.update_input($(input)));
		}
	}
}

/* ----------  Exports  ---------- */

export default InputUI;