/* ----------  Scripts  ---------- */

const RemovePlaybackLegs = () => dispatch => (
	dispatch({
		type: 'TOUR_PLAYBACK_REMOVE_LEGS',
	})
);

/* ----------  Exports  ---------- */

export default RemovePlaybackLegs;
