/* ----------  Imports  ---------- */

// Lodash
import { map } from 'lodash';

// jQuery
// import $ from 'jquery';
const $ = window.$;

/* ----------  Scripts  ---------- */

const Ctx = {
	init: () => {
		$('body').off('click.ctx').on('click.ctx', e => {
			const $ctx = $(e.target).closest('[data-ctx-menu]');

			if(!$ctx.length) Ctx.reset();
		});

		$('body').off('keyup.ctx').on('keyup.ctx', e => {
			if(e.keyCode === 27) Ctx.reset();
		});

		Ctx.handleScroll();
		Ctx.handleNativeContext();
	},

	getPosition: (ctx, target, event) => {
		const dataOptions = $(ctx).attr('data-options');

		let targetX = 0;
		let targetY = 0;
		let targetHeight = 0;
		let targetWidth = 0;

		if(event) {
			targetX = event.pageX;
			targetY = event.pageY;
		} else if(target) {
			const targetRect = target.getBoundingClientRect();

			targetX = targetRect.x;
			targetY = targetRect.y;
			targetHeight = targetRect.height;
			targetWidth = targetRect.width;
		}

		const defaultOptions = {
			offset: 0,
			offsetTarget: false,
			center: true,
		}
		
		const options = dataOptions ? JSON.parse(dataOptions) : defaultOptions;

		const windowHeight = $(window).innerHeight();
		
		const ctxWidth = $(ctx).innerWidth();
		const ctxHeight = $(ctx).innerHeight();
		
		let ctxLeft = targetX;
		let ctxTop = targetY + options.offset;
		const ctxBottom = targetY + ctxHeight;

		if(ctxBottom > windowHeight) {
			ctxTop = (targetY + targetHeight) - ctxHeight;
		}

		if(options.offsetTarget) {
			ctxTop += targetHeight;
		}

		if(options.center) ctxLeft -= ((ctxWidth / 2) - (targetWidth / 2));

		return {
			top: `${ ctxTop }px`,
			left: `${ ctxLeft }px`,
		};
	},

	getRefId: type => {
		const ctx = Ctx.getCtx(type);
		return ctx.getAttribute('data-ref-id');
	},

	getCtx: type => document.querySelector(`[data-ctx-menu=${ type }]`),

	open: (type, target, event) => {
		if(!target && !event) return;
		
		const ctx = Ctx.getCtx(type);
		const $ctx = $(ctx);
		const isChild = $ctx.attr('data-sub-ctx') === 'true' || false;
		const $ctxParent = $(target).closest('[data-ctxtoggle-parent]');
		
		setTimeout(() => {
			const position = Ctx.getPosition(ctx, target, event);

			if (!isChild) {
				Ctx.close();
				$ctx.css({
					top: position.top,
					left: position.left,
				});
			}

			$ctx.show();
			$ctxParent.addClass('ctx-active');
		}, 50);
	},

	close: () => {
		Ctx.reset();
	},

	reset: () => {
		const $ctxs = $('[data-ctx-menu]');

		$ctxs.hide();
		$('[data-ctxtoggle-parent]').removeClass('ctx-active');
	},

	handleScroll: () => {
		const PS = document.querySelectorAll('[data-ps]');

		map(PS, scrollbar => {
			scrollbar.addEventListener('ps-scroll-y', () => {
				const parent = scrollbar.closest('[data-ctx-menu]');
				if(!parent) Ctx.close();
			});
		});
	},

	handleNativeContext: () => {
		document.body.addEventListener('contextmenu', () => {
			Ctx.close();
		});
	}
}

/* ----------  Exports  ---------- */

export default Ctx;
