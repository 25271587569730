/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// React Stripe
import { Elements as StripeElements, ElementsConsumer } from '@stripe/react-stripe-js';

// Lodash
// import { isEmpty } from 'lodash';

// Containers
import Logout from './../../../Containers/Home/Auth/Logout';
// import Languages from './../../../Containers/Home/Languages';
import AuthLogin from '../../../Containers/Home/Auth/AuthLogin';
import RegisterForm from './../../../Containers/Home/Auth/RegisterForm';
import StripeHelper from '../../../Helpers/StripeHelper';

// Utils
// import Scrollbar from './../../Utils/Scrollbar';

// jQuery
const $ = window.$;

/* ----------  Scripts  ---------- */

const EASING_SWIFT_OUT = [ 0.35,0,0.25,1 ];

const STRIPE_PROMISE = StripeHelper.loadPromise();

class HomeSidebarNavigation extends React.Component {
	constructor(props) {
		super(props);

		this.isLoaded = false;

		this.state = {
			activeDropdown: null
		}
	}

	componentDidMount() {
		this.isLoaded = true;
	}

	componentWillUnmount() {
		this.isLoaded = false;
	}

	onItemClick = e => {
		e.preventDefault();

		const item = e.currentTarget;
		const target = item.getAttribute('data-dropdown-target');

		if(target) {
			this.handleTarget(target);
		}
	}

	openLoginForm = () => {
		this.handleTarget('#fuseFormLogin');
	}

	openRegisterForm = () => {
		this.handleTarget('#fuseFormRegister');
	}

	handleTarget = target => {
		const $target = $(target);
			
		if(!$target.is(':visible')) {
			this.hideAllTargets();
			this.showTarget(target);
		} else {
			this.hideTarget(target);
		}
	}

	showTarget = target => {
		if(this.isLoaded) {
			this.setState({ activeDropdown: target }, () => {
				$(target).show().velocity('slideDown', {
					duration: 200,
					easing: EASING_SWIFT_OUT
				});
			});
		}
	}

	hideTarget = target => {
		if(this.isLoaded) {
			this.setState({ activeDropdown: null }, () => {
				$(target).hide().velocity('slideUp', {
					duration: 200,
					easing: EASING_SWIFT_OUT
				});
			});
		}
	}

	hideAllTargets = () => {
		if(this.isLoaded) {
			this.setState({ activeDropdown: null }, () => {
				const $dropdowns = $('.atnDropdown');
				$dropdowns.hide();
			});
		}
	}

	renderUserDashboardItem = () => {
		const { user } = this.props;
		const token = user.auth.token;

		if(!token) return <li className="d-none"/>;

		return (
			<li className="item-auth auth-user">
				<Link to="/mytours" data-uk-tooltip="{pos:'bottom'}" title="Dashboard">
					<span className="fas fa-th"/>
				</Link>
			</li>
		);
	}

	renderUserItem = () => {
		const { user } = this.props;
		const token = user.auth.token;

		if(!token) return <li className="d-none"/>;

		return (
			<li className="item-auth auth-user">
				<Link to="/profile" data-uk-tooltip="{pos:'bottom'}" title="My Profile">
					<span className="fas fa-user"/>
				</Link>
			</li>
		);
	}

	renderLogoutItem = () => {
		const { user } = this.props;
		const token = user.auth.token;

		if(!token) return <li className="d-none"/>;

		return (
			<li className="item-auth auth-logout">
				<Logout/>
			</li>
		);
	}

	renderLoginItem = () => {
		const { user } = this.props;
		const token = user.auth.token;

		if(token) return <li className="d-none"/>;

		return (
			<li className="item-auth auth-login">
				<Link to="#sidebar" className="btnAuthLogin" data-uk-tooltip="{pos:'bottom'}" data-dropdown-target="#fuseFormLogin" title="Login" onClick={ this.onItemClick }>
					<span className="fas fa-sign-in-alt"/>
				</Link>
				<div className="atn-dropdown dropdown-auth dropdown-auth-login atnDropdown" id="fuseFormLogin">
					<ElementsConsumer>
						{({ stripe }) => <AuthLogin openRegisterForm={ this.openRegisterForm } stripe={ stripe }/>}
					</ElementsConsumer>
				</div>
			</li>
		);
	}

	renderRegisterItem = () => {
		const { user } = this.props;
		const token = user.auth.token;

		if(token) return <li className="d-none"/>;

		return (
			<li className="item-auth auth-register">
				<Link to="#sidebar" data-uk-tooltip="{pos:'bottom'}" data-dropdown-target="#fuseFormRegister" title="Register" onClick={ this.onItemClick }>
					<span className="fas fa-user-plus"/>
				</Link>
				<div className="atn-dropdown dropdown-auth dropdown-auth-register atnDropdown" id="fuseFormRegister">
					<ElementsConsumer>
						{({ stripe }) => <RegisterForm openLoginForm={ this.openLoginForm } stripe={ stripe }/>}
					</ElementsConsumer>
				</div>
			</li>
		);
	}

	render() {
		return (
			<StripeElements stripe={ STRIPE_PROMISE }>
				<ul className="list-unstyled">
					{ this.renderUserDashboardItem() }
					{ this.renderUserItem() }
					{ this.renderLoginItem() }
					{ this.renderRegisterItem() }
					
					{/* <li className="user-lang">
						<Link to="#sidebar" data-uk-tooltip="{pos:'bottom'}" data-dropdown-target="#fuseGlobalLanguage" title="Languages" onClick={ this.onItemClick }>
							<span className="fas fa-globe"/>
						</Link>
						<div className="atn-dropdown dropdown-lang atnDropdown" id="fuseGlobalLanguage">
							<Scrollbar className="dropdown-inner">
								<Languages/>
							</Scrollbar>
						</div>
					</li>
					<li className="user-share">
						<Link to="#sidebar" data-uk-tooltip="{pos:'bottom'}" title="Share" onClick={ this.onItemClick }>
							<span className="fas fa-share-alt"/>
						</Link>
					</li> */}

					{ this.renderLogoutItem() }
				</ul>
			</StripeElements>
		);
	}
}

/* ----------  Prop Types  ---------- */

HomeSidebarNavigation.propTypes = {
	user: PropTypes.shape().isRequired
}

/* ----------  Exports  ---------- */

export default HomeSidebarNavigation;
