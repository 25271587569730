/* ----------  Imports  ---------- */

// Lodash
import { mapKeys } from 'lodash';

/* ----------  Scripts  ---------- */

const ReducerCountries = (state = {}, action) => {
	if(action.type === 'GET_COUNTRIES') {
		const response = mapKeys(action.payload, 'code');

		return { ...state, ...response };
	}

	return state;
}

/* ----------  Exports  ---------- */

export default ReducerCountries;