/* ----------  Scripts  ---------- */

const GROUPS_INITIAL_STATE = [];

const ReducerMyToursTreesGroups = (state = GROUPS_INITIAL_STATE, action) => {
	if(action.type === 'MYTOURS_TREES_GET_GROUPS') {
		return action.payload.results;
	} else if(action.type === 'MYTOURS_TREES_REMOVE_GROUPS') {
		return GROUPS_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMyToursTreesGroups;