/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { map, find, pull } from 'lodash';

// UUID
import uuid from 'uuid/v4';

// Common
import Preloader from './../../Common/Preloader';

// Utils
import Selectize from './../../Utils/Selectize';
import TourRepeater from './../../Utils/TourRepeater';

// Language Dropdown
import LanguageDropdown from './../../../Containers/Common/LanguageDropdown';

// UIkit
import Tabs from './../../UIkit/Tabs';
import Modal from './../../UIkit/Modal';
import Input from './../../UIkit/Input';
import Alerts from './../../UIkit/Alerts';

// Helpers
import Notify from './../../../Helpers/Notify';
import InputUI from './../../../Helpers/InputUI';
import InputHelper from './../../../Helpers/InputHelper';
import ToggleHelper from './../../../Helpers/ToggleHelper';

// Select Categories
import SelectCategories from './../Categories/SelectCategories';

/* ----------  Scripts  ---------- */

class ModalTour extends React.Component {
	constructor(props) {
		super(props);

		this.modalRef = React.createRef();
		this.formRef = React.createRef();
		
		this.tourTagsRef = React.createRef();
		this.tourCategoriesRef = React.createRef();

		this.profilePictureRepeaterRef = React.createRef();
		this.relatedVideoRepeaterRef = React.createRef();

		this.initialFormState = {
			title: '',
			about: '',
			photo: '',
			images: {
				links: [],
				attachments: []
			},
			videos: [],
			tags: [],
			categoryIds: [],
			language: 'en',
			primaryCategoryId: null,
		}

		this.initialState = {
			tourId: '',
			type: 'create',
			parentNodeId: '',
			parentNodeType: 0,
			title: 'New Tour',

			active: false,
			saving: false,
			formErrors: [],
			uploaderBusy: false,
			form: this.initialFormState,
		}

		this.state = this.initialState
	}

	componentDidMount() {}

	onShow = () => {
		this.setState({ active: true }, () => {
			const form = this.formRef.current;
			InputUI.update(form);
			
			this.handleUpload();
		});
	}

	onHide = () => {
		const { onHide } = this.props;

		if(onHide) onHide();

		this.setState(this.initialState);
	}

	onChooseFile = e => {
		e.preventDefault();

		const uploader = this.props.uploader.current;

		uploader.click();
	}

	onSetProfilePhoto = (e, attachmentId) => {
		e.preventDefault();
		
		this.setProfilePhoto(attachmentId);
	}

	onUnsetProfilePhoto = (e, attachmentId) => {
		e.preventDefault();

		this.unsetProfilePhoto(attachmentId);
	}

	onRemoveAttachment = (e, attachmentId) => {
		e.preventDefault();

		this.removeAttachment(attachmentId);
	}

	onSave = e => {
		e.preventDefault();

		this.save();
	}

	onSaveAndEdit = e => {
		e.preventDefault();

		this.save(true);
	}

	getAttachment = attachmentId => {
		const { form } = this.state;

		return find(form.images.attachments, ['attachmentId', attachmentId]);
	}

	setPrimaryCategory = categoryId => {
		this.updateForm({ primaryCategoryId: categoryId });
	}

	setData = data => {
		const state = {
			title: data.title,
			about: data.about,
			photo: data.photo,
			images: data.images,
			videos: data.videos,
			tags: data.tags,
			categoryIds: map(data.categories, category => category.categoryId),
			language: data.language,
			primaryCategoryId: data.primaryCategory ? data.primaryCategory.categoryId : '',
		}

		this.updateForm(state, () => {
			const { form: { videos, images, tags, categoryIds, primaryCategoryId } } = this.state;

			const form = this.formRef.current;
			const tourTagsRef = this.tourTagsRef.current;
			const tourCategoriesRef = this.tourCategoriesRef.current;
			const relatedVideoRepeaterRef = this.relatedVideoRepeaterRef.current;
			const profilePictureRepeaterRef = this.profilePictureRepeaterRef.current;

			this.setState({
				tourId: data.tourId,
				title: data.title,
				type: 'update',
			});

			relatedVideoRepeaterRef.setData(videos);
			profilePictureRepeaterRef.setData(images.links);

			map(tags, tag => tourTagsRef.addTag(tag));

			tourTagsRef.setValue(tags);
			tourCategoriesRef.setValue(categoryIds);
			tourCategoriesRef.setSelected(primaryCategoryId);

			InputUI.update(form);
		});
	}

	setProfilePhoto = attachmentId => {
		const attachment = this.getAttachment(attachmentId);

		if(!attachment) return false;

		this.updatePhoto(attachment.url);

		return true;
	}

	addAttachment = attachment => {
		const { form: { images } } = this.state;

		this.updateForm({
			images: {
				...images,
				attachments: [ ...images.attachments, attachment ]
			}
		})
	}

	show = data => {
		const modal = this.modalRef.current;

		this.setState({
			parentNodeId: data.parentNodeId,
			parentNodeType: data.parentNodeType,
		}, modal.show);
	}

	hide = () => {
		const modal = this.modalRef.current;
		modal.hide();
	}

	handleForm = e => {
		const { name, value } = e.currentTarget;

		this.updateForm({ [name]: value });
	}

	handleLanguage = (name, value) => {
		this.updateForm({ language: value });
	}

	handleCategories = (name, value) => {
		this.updateForm({ categoryIds: value }, () => {
			const { form } = this.state;
			const tourCategoriesRef = this.tourCategoriesRef.current;

			if(form.categoryIds.length === 1) {
				const id = form.categoryIds[0];

				this.updateForm({ primaryCategoryId: id });

				tourCategoriesRef.setSelected(id);
			}
		});
	}

	handleTags = (name, value) => {
		this.updateForm({ tags: value });
	}

	handleUpload = () => {
		const uploader = this.props.uploader.current;

		if(uploader) {
			uploader.onchange = e => {
				const { files } = e.target;

				if(files.length) {
					let file = files[0];

					this.uploadPhoto(file, () => {
						file = null;
						e.target.value = '';
					});
				}
			};
		}
	}

	removeAttachment = attachmentId => {
		const attachment = this.getAttachment(attachmentId);

		if(!attachment) return false;

		const { form } = this.state;
		const attachments = [ ...form.images.attachments ];

		pull(attachments, attachment);

		this.updateForm({
			images: {
				...form.images,
				attachments
			}
		}, () => {
			if(form.photo === attachment.url) {
				this.updatePhoto('');
			}
		});

		return true;
	}

	save = (openSequencePanel = false) => {
		const isValid = this.validateForm();

		if(isValid) {
			const { parentNodeId, parentNodeType, form, tourId, type } = this.state;

			const modal = this.modalRef.current;
			
			const profilePictureRepeaterRef = this.profilePictureRepeaterRef.current;
			const profilePictures = profilePictureRepeaterRef.getData();

			const relatedVideoRepeaterRef = this.relatedVideoRepeaterRef.current;
			const relatedVideos = relatedVideoRepeaterRef.getData();

			const links = map(profilePictures, 'value');
			const attachments = form.images.attachments;
			
			const videos = map(relatedVideos, 'value');

			const data = {
				...form,
				parentNodeId,
				parentNodeType,
				images: {
					links,
					attachments,
				},
				videos,
			}

			if(tourId) {
				data.tourId = tourId;
			} else {
				data.tTourId = uuid();
			}

			this.setState({ saving: true });
			
			this.props.saveTour(data, type, response => {
				this.setState({ saving: false });
				modal.hide();

				if(openSequencePanel) {
					const tour = {
						nodeId: response.nodeId,
						tourId: response.tourId,
						title: response.title,
						parentNodeId: response.parentNodeId,
					}

					this.props.handleSequencePanel(tour);
				}
			}, reasons => {
				const errors = map(reasons, reason => reason.message);

				this.setState({
					saving: false,
					formErrors: errors
				});
			});
		}
	}

	uploadPhoto = (photo, callback) => {
		const formData = new FormData();

		formData.append('file', photo);
		formData.set('type', photo.type);

		this.setState({ uploaderBusy: true });

		this.props.addAttachment(formData, (status, response) => {
			this.setState({ uploaderBusy: false });
			this.addAttachment(response);
			if(callback) callback();
		}, () => {
			this.setState({ uploaderBusy: false });
			Notify.error('Please try again :(');
			if(callback) callback();
		});
	}

	updateForm = (updates, callback) => {
		const { form } = this.state;

		this.setState({
			form: {
				...form,
				...updates
			}
		}, () => {
			if(callback) callback();
		});
	}

	unsetProfilePhoto = attachmentId => {
		const attachment = this.getAttachment(attachmentId);

		if(!attachment) return false;

		this.updatePhoto('');

		return true;
	}

	updatePhoto = photo => {
		this.updateForm({ photo }, () => {
			const form = this.formRef.current;

			ToggleHelper.updateUI(form);
		});
	}

	validateForm = () => {
		let isValid = true;
		
		const errors = [];
		const { form: { title, primaryCategoryId, categoryIds } } = this.state;

		if(InputHelper.isEmpty(title) && !InputHelper.isPlain(title)) {
			isValid = false;
			errors.push('Please enter a valid title.')
		}

		if(InputHelper.isEmpty(primaryCategoryId)) {
			isValid = false;
			errors.push('Please select a primary category.');
		}

		if(!categoryIds.length) {
			isValid = false;
			errors.push('Please select at least one category.');
		}

		this.setState({
			formErrors: errors,
		});

		return isValid;
	}

	renderProfilePictureRepeater = () => {
		const { active, form: { photo } } = this.state;

		if(!active) return <div className="d-none"/>;

		return (
			<TourRepeater
				photo={ photo }
				template="profilePicture"
				name="tourProfilePicture"
				updatePhoto={ this.updatePhoto }
				title="Enter online picture hyperlink"
				ref={ this.profilePictureRepeaterRef }/>
		);
	}

	renderGallery = () => {
		const { form: { images } } = this.state;

		if(!images.attachments.length) return <div className="md-gallery-container d-none"/>;

		return (
			<div className="md-gallery-container">
				<ul className="md-gallery-list">
					{ this.renderGalleryItems() }
				</ul>
			</div>
		);
	}

	renderGalleryItems = () => {
		const { form: { images, photo } } = this.state;

		if(!images.attachments.length) return <li className="d-none"/>;

		return map(images.attachments, attachment => {
			const { attachmentId, url } = attachment;
			const isPhoto = photo === url;

			return (
				<li className="md-gallery-item" key={ attachmentId }>
					<div className="md-gallery-item-inner">
						<Link to="#gallery" className={ `btn-set ${ isPhoto ? 'd-none' : 'd-block' }` } onClick={ e => this.onSetProfilePhoto(e, attachmentId) }>Set as cover photo</Link>
						<Link to="#gallery" className={ `btn-set ${ !isPhoto ? 'd-none' : 'd-block' }` } onClick={ e => this.onUnsetProfilePhoto(e, attachmentId) }>Remove cover photo</Link>
						<Link to="#gallery" className="btn-remove" onClick={ e => this.onRemoveAttachment(e, attachmentId) }><i className="material-icons">&#xE147;</i></Link>
						<img src={ url } className="img-responsive gallery-img" alt=""/>
					</div>
				</li>
			);
		});
	}

	renderTourRelatedVideoRepeater = () => {
		const { active, form: { photo } } = this.state;

		if(!active) return <div className="d-none"/>;

		return (
			<TourRepeater
				photo={ photo }
				template="default"
				name="tourVideo"
				title="Enter online video hyperlink"
				ref={ this.relatedVideoRepeaterRef }/>
		);
	}

	renderTourCategories = () => {
		const { active, form } = this.state;
		const { categories } = this.props;

		if(!active) return <div className="d-none"/>;

		return (
			<SelectCategories 
				categories={ categories }
				maxItems={ 10 }
				name="categoryIds"
				id="tourCategories"
				setPrimaryCategory={ this.setPrimaryCategory }
				value={ form.categoryIds }
				onChange={ this.handleCategories }
				ref={ this.tourCategoriesRef }/>
		);
	}

	renderTourTags = () => {
		const { active, form } = this.state;

		if(!active) return <div className="d-none"/>;

		return (
			<Selectize 
				tags
				multiple
				id="tourTags"
				name="tags"
				value={ form.tags }
				onChange={ this.handleTags }
				ref={ this.tourTagsRef }/>
		);
	}

	renderModal = () => {
		const { loading } = this.props;
		const { title, form, formErrors, saving, uploaderBusy } = this.state;

		if(loading) return <Preloader loading={ loading } center minimal/>;

		return (
			<form ref={ this.formRef }>
				<div className="uk-modal-header">
					<div className="header-title">
						<h3 className="uk-modal-title">{ title }</h3>
					</div>
					<div className="header-action">
						<div className="md-select-wrapper md-language-wrapper">
							<LanguageDropdown id="tourLanguageDropdown" name="language" value={ form.language } onChange={ this.handleLanguage }/>
						</div>
					</div>
				</div>
				<div className="uk-modal-body">
					<Input
						title="Tour Name"
						name="title"
						id="txtNewTourName"
						value={ form.title }
						clearable
						onChange={ this.handleForm }/>
					<Input
						textarea
						title="About"
						name="about"
						id="txtNewTourAbout"
						value={ form.about }
						onChange={ this.handleForm }/>

					<div className="md-tour-section m-t-20">
						<div className="section-title m-b-15">
							<h3 className="heading_a text-dark">Tour Related Pictures</h3>
						</div>
						<div className="section-content">
							<Tabs className="uk-grid" connect="#tourGalleryTabs">
								<li className="uk-active uk-width-1-2"><Link to="#mdl"><span className="fas fa-images fa-2x"/></Link></li>
								<li className="uk-width-1-2"><Link to="#mdl"><span className="fas fa-cloud-upload-alt fa-2x"/></Link></li>
							</Tabs>
							<ul id="tourGalleryTabs" className="uk-switcher uk-margin">
								<li aria-hidden="false" className="uk-active p-t-10">
									<div className="content-title m-b-15">
										<h5>Profile Picture</h5>
									</div>
									<div className="content-list">
										{ this.renderProfilePictureRepeater() }
									</div>
								</li>
								<li aria-hidden="true" className="p-t-10">
									<div className={ `uk-file-upload form-group-upload ${ uploaderBusy ? 'd-none' : '' }` }>
										<p className="uk-text-muted uk-text-small uk-margin-small-bottom">Upload Tour Photos</p>
										<Link to="#uploader" className="md-btn btn-upload" onClick={ this.onChooseFile }>
											choose file
										</Link>
									</div>
									<div className={ `uk-file-upload form-group-upload ${ !uploaderBusy ? 'd-none' : '' }` }>
										<Preloader loading={ uploaderBusy } center/>
									</div>
									{ this.renderGallery() }
								</li>
							</ul>
						</div>
					</div>
					<div className="md-tour-section m-t-20">
						<div className="section-title m-b-15">
							<h3 className="heading_a text-dark">Tour Related Video</h3>
						</div>
						<div className="section-content">
							{ this.renderTourRelatedVideoRepeater() }
						</div>
					</div>
					<div className="md-tour-section m-t-20">
						<div className="section-title m-b-15">
							<h3 className="heading_a text-dark">Categories</h3>
						</div>
						<div className="section-content">
							{ this.renderTourCategories() }
						</div>
					</div>
					<div className="md-tour-section m-t-20">
						<div className="section-title m-b-15">
							<h3 className="heading_a text-dark">Tags</h3>
						</div>
						<div className="section-content">
							{ this.renderTourTags() }
						</div>
					</div>

					<Alerts show={ formErrors.length > 0 } data={ formErrors }/>
				</div>
				<div className="uk-modal-footer">
					<div className="footer-preloader m-r-10">
						<Preloader size={ 20 } loading={ saving } relative minimal/>
					</div>
					<Link to="#closeModal" className={ `md-btn md-btn-flat md-btn-flat-default md-btn-wave waves-effect waves-button uk-modal-close ${ saving ? 'disabled' : '' }` }>
						Cancel
					</Link>
					<button type="submit" className={ `md-btn md-btn-flat md-btn-flat-success md-btn-wave waves-effect waves-button ${ saving ? 'disabled' : '' }` } onClick={ this.onSave }>
						Save
					</button>
					<Link to="#save" className={ `md-btn md-btn-flat md-btn-flat-success md-btn-wave waves-effect waves-button ${ saving ? 'disabled' : '' }` } onClick={ this.onSaveAndEdit }>
						Save & Edit Tour
					</Link>
				</div>
			</form>
		);
	}

	render() {
		const style = {
			minHeight: 200
		}

		return (
			<Modal
				popup="tour"
				onShow={ this.onShow }
				onHide={ this.onHide }
				ref={ this.modalRef }>
				<div className="uk-modal-dialog uk-modal-dialog-large" style={ style }>
					{ this.renderModal() }
				</div>
			</Modal>
		);
	}
}

/* ----------  Prop Types  ---------- */

ModalTour.defaultProps = {
	onHide: false,
	loading: false,
}

ModalTour.propTypes = {
	loading: PropTypes.bool,

	saveTour: PropTypes.func.isRequired,
	addAttachment: PropTypes.func.isRequired,
	handleSequencePanel: PropTypes.func.isRequired,
	
	onHide: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	
	uploader: PropTypes.shape().isRequired,

	categories: PropTypes.objectOf(PropTypes.object).isRequired,
}

/* ----------  Exports  ---------- */

export default ModalTour;
