/* ----------  Scripts  ---------- */

const CONVERSATION_THEME_INITIAL_STATE = "";

const ReducerMessagesConversationTheme = (state = CONVERSATION_THEME_INITIAL_STATE, action) => {
	if((action.type === 'MESSAGES_GET_CONVERSATION_THEME') || (action.type === 'MESSAGES_SET_CONVERSATION_THEME')) {
		return action.payload.theme;
	} else if(action.type === 'MESSAGES_REMOVE_CONVERSATION_THEME') {
		return CONVERSATION_THEME_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMessagesConversationTheme;