/* ----------  Imports  ---------- */

// Utils
import Utils from "./../../../../../Helpers/Utils";

/* ----------  Scripts  ---------- */

const LEGS_NODES_INITIAL_STATE = [];

const ReducerMyToursTreesLegsNodes = (state = LEGS_NODES_INITIAL_STATE, action) => {
	if(action.type === 'MYTOURS_TREES_GET_LEGS_NODES') {
		// return [...state, ...action.payload.results];
		return action.payload.results;
	} else if(action.type === 'MYTOURS_TREES_LEGS_CREATE_NODE') {
		const unsortedState = [ ...state ];
		const response = [ ...unsortedState, action.payload ];
		const sortedState = Utils.getSortedTree(response);

		return sortedState;
	} else if(action.type === 'MYTOURS_TREES_REMOVE_LEGS_NODES') {
		return LEGS_NODES_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMyToursTreesLegsNodes;
