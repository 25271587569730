/* ----------  Imports  ---------- */

// Lodash
import { remove, find, uniq, map } from 'lodash';

/* ----------  Scripts  ---------- */

const USER_DEBIT_CARDS_INITIAL_STATE = [];

const ReducerDebitCards = (state = USER_DEBIT_CARDS_INITIAL_STATE, action) => {
	if(action.type === 'USER_GET_DEBIT_CARDS') {
		const cards = uniq([ ...state, ...action.payload ]);
		return cards;
	} else if(action.type === 'USER_REMOVE_DEBIT_CARD') {
		const cards = [ ...state ];

		const card = find(cards, ['cardId', action.payload]);
		remove(cards, card);

		return cards;
	} else if(action.type === 'USER_SET_DEFAULT_DEBIT_CARD') {
		const cards = [ ...state ];

		map(cards, card => {
			card.default = card.cardId === action.payload;		
		})

		return cards;
	} else if(action.type === 'USER_REMOVE_DEBIT_CARDS') {
		return USER_DEBIT_CARDS_INITIAL_STATE;
	}

	return state;
}

/* ----------  Exports  ---------- */

export default ReducerDebitCards;
