/* ----------  Imports  ---------- */

// Lodash
import { find, remove, first } from 'lodash';

// Local Storage
import Storage from './../../../Services/Storage';

/* ----------  Scripts  ---------- */

const USER_PROFILE_INITIAL_STATE = {};

const ReducerUserProfile = (state = USER_PROFILE_INITIAL_STATE, action) => {
	if(action.type === 'USER_GET_PROFILE') {
		const userProfile = action.payload;
		Storage.setUser(userProfile);

		return { ...state, ...userProfile };
	} else if(action.type === 'USER_REMOVE_CREDIT_CARD') {
		const profile = { ...state };
		const { payments } = profile;

		const card = find(payments, ['cardId', action.payload]);
		remove(payments, card);

		const update = { ...profile, payments };
		const user = { ...state, ...update };
		Storage.setUser(user);

		return user;
	} else if(action.type === 'USER_SYNC_PROFILE') {
		const user = action.payload;
		Storage.setUser(user);
		return user;
	} else if(action.type === 'USER_ENABLE_SUBSCRIPTION') {
		return { ...state, featured: true };
	} else if(action.type === 'USER_DISABLE_SUBSCRIPTION') {
		return { ...state, featured: false };
	} else if((action.type === 'USER_CREATE_ACCOUNT_INFO') || (action.type === 'USER_UPDATE_ACCOUNT_INFO')) {
		return { ...state, is_attach_connected_account: true };
	} else if(action.type === 'USER_REMOVE_PROFILE') {
		Storage.removeUser();
		return USER_PROFILE_INITIAL_STATE;
	}

	return state;
}

/* ----------  Exports  ---------- */

export default ReducerUserProfile;
