/* ----------  Imports  ---------- */

// Lodash
import { mapKeys } from 'lodash';

/* ----------  Scripts  ---------- */

const UNGROUPED_TOURS_NODES_DATA_INITIAL_STATE = {};

const ReducerMyToursTreesUngroupedToursNodesData = (state = UNGROUPED_TOURS_NODES_DATA_INITIAL_STATE, action) => {
	if(action.type === 'MYTOURS_TREES_GET_UNGROUPED_TOURS_NODES') {
		const response = mapKeys(action.payload.results, 'nodeId')
		return response;
	} else if(action.type === 'MYTOURS_TREES_REMOVE_UNGROUPED_TOURS_NODES') {
		return UNGROUPED_TOURS_NODES_DATA_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMyToursTreesUngroupedToursNodesData;