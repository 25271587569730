/* ----------  Scripts  ---------- */

const SetToursUnHovered = () => dispatch => {
	dispatch({
		type: 'HOME_SET_TOURS_UNHOVERED'
	})
};

/* ----------  Exports  ---------- */

export default SetToursUnHovered;