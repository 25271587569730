/* ----------  Imports  ---------- */

// Lodash
import { map, filter, mapKeys, indexOf } from 'lodash';

// Global
import Global from './../../../Constants/Global';

/* ----------  Scripts  ---------- */

const ReducerLanguages = (state = {}, action) => {
	if(action.type === 'GET_LANGUAGES') {
		const data = filter(action.payload.results, lang => {
			if(indexOf(Global.VALID_LANGUAGES, lang.code) !== -1) return lang;
			return null;
		});

		const languages = map(data, lang => ({ ...lang, ...Global.LANGUAGES[lang.code] }));
		const response = mapKeys(languages, 'code');

		return { ...state, ...response };
	}

	return state;
}

/* ----------  Exports  ---------- */

export default ReducerLanguages;