/* ----------  Imports  ---------- */

// Axios
import axios from 'axios';

// Global
import Global from './../../../Constants/Global';

/* ----------  Scripts  ---------- */

const GetUserProfile = (success, fail) => dispatch => {
	const url = `${ Global.API_BASE_URL }/users`;

	axios.get(url).then(response => {
		const { status, result } = response.data;

		console.log(result);

		dispatch({
			type: 'USER_GET_PROFILE',
			payload: result
		});
		
		if(success) success(status);
	}).catch(error => {
		const { response } = error;

		dispatch({ type: 'USER_TOKEN_REMOVE' });
		dispatch({ type: 'USER_REMOVE_PROFILE' });

		if(fail) {
			if(response) {
				fail(response.data.status, response.data.error.reasons);
			} else {
				fail(false, []);
			}
		}
	});
};

/* ----------  Exports  ---------- */

export default GetUserProfile;
