/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
// import { Link } from 'react-router-dom';

// Constants
import Global from './../Constants/Global';

// Layouts
import AdminLayout from './../Layouts/AdminLayout';

// Profile View
import ProfileView from './../Containers/Profile/ProfileView';

// Preloader
// import Preloader from './../Components/Common/Preloader';

/* ----------  Scripts  ---------- */

class ViewProfile extends React.Component  {
	constructor(props) {
		super(props);

		this.state = {}
	}

	componentWillMount() {
		document.title = `${ Global.APP_NAME } :: Profile`;
	}

	render() {
		return (
			<AdminLayout view="profile">
				<div className="uk-grid" data-uk-grid-margin="" data-uk-grid-match="" id="user_profile">
					<div className="uk-width-large-7-10">
						<ProfileView/>
					</div>
					{/* <div className="uk-width-large-3-10 hidden-print">
						<div className="md-card">
							<div className="md-card-content">
								<h3 className="heading_c uk-margin-bottom">Alerts</h3>
								<ul className="md-list md-list-addon">
									<li>
										<div className="md-list-addon-element">
											<i className="md-list-addon-icon material-icons uk-text-warning">&#xE8B2;</i>
										</div>
										<div className="md-list-content">
											<span className="md-list-heading">Voluptatem occaecati.</span> <span className="uk-text-small uk-text-muted uk-text-truncate">Voluptas quae voluptatem ipsam modi dolores voluptate.</span>
										</div>
									</li>
									<li>
										<div className="md-list-addon-element">
											<i className="md-list-addon-icon material-icons uk-text-success">&#xE88F;</i>
										</div>
										<div className="md-list-content">
											<span className="md-list-heading">Itaque perferendis.</span> <span className="uk-text-small uk-text-muted uk-text-truncate">Asperiores corporis maxime est omnis deserunt quidem dolor.</span>
										</div>
									</li>
									<li>
										<div className="md-list-addon-element">
											<i className="md-list-addon-icon material-icons uk-text-danger">&#xE001;</i>
										</div>
										<div className="md-list-content">
											<span className="md-list-heading">Repellat ut aut.</span> <span className="uk-text-small uk-text-muted uk-text-truncate">Alias excepturi voluptatem itaque illo magni.</span>
										</div>
									</li>
								</ul>
							</div>
						</div>
					</div> */}
				</div>
			</AdminLayout>
		);
	}
};

/* ----------  Exports  ---------- */

export default ViewProfile;
