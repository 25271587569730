/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { map, isEmpty, capitalize, find, last, filter, findIndex } from 'lodash';

// Moment
import moment from 'moment';

// UIkit
// import UIKit from 'uikit';

// jQuery
import $ from 'jquery';

// Constants
import History from '../../../Constants/History';

// Utils
import Scrollbar from '../../Utils/Scrollbar';

/* ----------  History Panel  ---------- */

class HistoryPanel extends React.Component {
	constructor(props) {
		super(props);

		this.panelRef = React.createRef();
	}

	componentDidMount() {
		this.handleActions();
	}

	onApply = e => {
		e.preventDefault();
		this.applyHistory();
	}

	onClose = e => {
		e.preventDefault();

		this.hidePanel();
		this.props.previewHistory();
	}

	onClear = e => {
		e.preventDefault();

		this.props.clearHistory();
	}

	applyHistory = historyId => {
		if(!historyId) return;

		this.previewHistory(historyId);
		this.props.applyHistory(historyId);
	}
	
	relieveHistory = (historyId) => {
		if(!historyId) return;

		this.previewHistory(historyId);
		this.props.relieveHistory();
	}
	
	selectHistory = (historyId) => {
		this.props.selectHistory(historyId);
	}

	hidePanel = () => {
		this.props.hidePanel();
	}

	handleActions = () => {
		let timer = null;
		const panelRef = this.panelRef.current;

		$(panelRef).off('click.applyHistory').on('click.applyHistory', '.ddHistoryItem', e => {
			const { history } = this.props;
			if(timer) clearTimeout(timer);

			const el = e.currentTarget;
			const historyId = el.getAttribute('data-id');

			const selectedHistory = find(history, { historyId });
			const lastHistory = last(history);

			if(!selectedHistory.selected) {
				this.applyHistory(historyId);
			} else {
				this.relieveHistory(lastHistory.historyId);
			}
		});

		$(panelRef).off('mouseenter.previewHistory').on('mouseenter.previewHistory', '.ddHistoryItem', e => {
			if(timer) clearTimeout(timer);

			const el = e.currentTarget;
			const historyId = el.getAttribute('data-id');
			this.previewHistory(historyId);
		});
		
		$(panelRef).off('mouseleave.previewHistory').on('mouseleave.previewHistory', '.ddHistoryItem', () => {
			const { history } = this.props;
			const selectedHistory = filter(history, item => item.selected);
			if(selectedHistory.length) {
				if(timer) clearTimeout(timer);
				this.previewHistory(selectedHistory[0].historyId);
				return;
			}

			timer = setTimeout(() => {
				this.previewHistory(null);
			}, 100);
		});
	}

	previewHistory = historyId => {
		const { history } = this.props;
		const itemIndex = findIndex(history, { historyId });
		const i = itemIndex >= 0 ? itemIndex + 1 : null;

		const item = history[i] ?  history[i] : null;
		const data = !isEmpty(item) ? item.data : null;

		this.props.previewHistory(data);
	}

	renderListItems = () => {
		const { history } = this.props;

		if(isEmpty(history)) return <li className="dd-item dd-nochildren p-10 sq-item text-center uk-text-italic uk-text-muted">No history recorded.</li>;

		return map(history, item => {
			const titleString = History.getTitle(item.action);
			const title = titleString.replace('$1', item.subject);

			const time = moment.unix(item.time);
			const duration = moment(time).fromNow();

			return (
				<li className={ `dd-item dd-nochildren sq-item ddHistoryItem ${ item.selected ? 'selected' : '' } ${ item.disabled ? 'disabled' : '' }` } data-id={ item.historyId } key={ item.historyId }>
					<span className="item-inner" style={{ alignItems: 'normal' }}>
						<span className="item-icon"><i className="material-icons">access_time</i></span>
						<span className="item-title">
							<span className="title-text uk-text-bold text-truncate" style={{ fontWeight: 'bold' }}>{ title }</span>
							{/* <span className="title-text uk-text-muted text-truncate">Action: { action }</span> */}
							<span className="title-text uk-text-muted">Time: { capitalize(duration) }</span>
							<span className="title-text uk-text-muted uk-text-small text-truncate">{ item.historyId }</span>
						</span>
					</span>
				</li>
			);
		});
	}

	render() {
		const { history, mixerActive } = this.props;

		return (
			<div className={ `editor-history-panel ${ mixerActive ? 'mixer-active' : '' }` } style={{ display: 'none' }} data-history-panel ref={ this.panelRef }>
				<div className="history-panel-inner">
					<div className="history-panel-content">
						<div className="history-panel-title panel-title">
							<span className="title-icon">
								<i className="material-icons text-primary">timelapse</i>
							</span>
							<h3 className="sequenceTitleText">History Panel</h3>
						</div>
						<Scrollbar className="history-panel-list">
							<ul className="dd-list sq-list">
								{ this.renderListItems() }
							</ul>
						</Scrollbar>
						<div className="history-panel-actions panel-actions">
							<ul className="list-inline">
								<li className={ `atn-cancel ${ isEmpty(history) ? 'disabled' : '' }` }>
									<Link to="#sqleg" onClick={ this.onClear }>Clear</Link>
								</li>
								<li className={ `atn-cancel` }>
									<Link to="#sqleg" onClick={ this.onClose }>Close</Link>
								</li>
								{/* <li className={ `atn-save ${ isEmpty(historyId) ? 'disabled' : '' }` }>
									<Link to="#sqleg" onClick={ this.onApply }>Apply</Link>
								</li> */}
							</ul>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

HistoryPanel.defaultProps = {
	mixerActive: false,
}

HistoryPanel.propTypes = {
	mixerActive: PropTypes.bool,

	history: PropTypes.arrayOf(PropTypes.object).isRequired,

	hidePanel: PropTypes.func.isRequired,
	applyHistory: PropTypes.func.isRequired,
	relieveHistory: PropTypes.func.isRequired,
	// removeHistory: PropTypes.func.isRequired,
	clearHistory: PropTypes.func.isRequired,
	selectHistory: PropTypes.func.isRequired,
	previewHistory: PropTypes.func.isRequired,
}

/* ----------  Exports  ---------- */

export default HistoryPanel;
