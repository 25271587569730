/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { map } from 'lodash';

/* ----------  Scripts  ---------- */

class MoreControls extends React.Component {
	constructor(props) {
		super(props);

		this.controls = React.createRef();

		this.state = {
			active: false
		}
	}

	componentDidMount() {}

	onControlsClick = e => {
		e.preventDefault();

		const controls = e.currentTarget;
		const isVisible = controls.classList.contains('show');

		if(!isVisible) {
			this.hideAllControls();
			this.showControls(controls);
		} else {
			this.hideControls(controls);
		}
	}

	onSubscribe = e => {
		e.preventDefault();
		e.stopPropagation();

		this.updateSubscribe();
	}

	onFavorite = e => {
		e.preventDefault();
		e.stopPropagation();

		this.updateFavorite();
	}

	setFavorite = () => {
		const { tourId, setFavorite } = this.props;

		if(setFavorite) setFavorite(tourId);
	}

	setUnfavorite = () => {
		const { tourId, setUnfavorite } = this.props;

		if(setUnfavorite) setUnfavorite(tourId);
	}

	hideControls = el => {
		el.classList.remove('show');
	}

	hideAllControls = () => {
		const controls = document.querySelectorAll('[data-controls-more]');

		if(controls.length) map(controls, control => control.classList.remove('show'));
	}
	
	showControls = el => {
		el.classList.add('show');
	}
	
	showAllControls = () => {
		const controls = document.querySelectorAll('[data-controls-more]');

		if(controls.length) map(controls, control => control.classList.add('show'));
	}

	subscribe = () => {
		const { tourId, subscribe } = this.props;

		if(subscribe) subscribe(tourId);
	}

	unsubscribe = () => {
		const { tourId, unsubscribe } = this.props;

		if(unsubscribe) unsubscribe(tourId);
	}

	updateSubscribe = () => {
		const { isSubscribed } = this.props;

		if(!isSubscribed) {
			this.subscribe();
		} else {
			this.unsubscribe();
		}

		this.hideAllControls();
	}

	updateFavorite = () => {
		const { favorite } = this.props;

		if(!favorite) {
			this.setFavorite();
		} else {
			this.setUnfavorite();
		}

		this.hideAllControls();
	}

	render() {
		const { isSubscribed, favorite, userId, authUserId } = this.props;

		return (
			<div
				data-controls-more
				className="controls-more"
				onClick={ this.onControlsClick }
				role="button"
				tabIndex="0"
				ref={ this.controls }
				data-favorite={ favorite }
				data-subscribed={ isSubscribed }>
				<ul>
					<li>
						<Link to="#tourDetails" onClick={ this.onFavorite }>
							{ favorite ? 'Remove from favorites' : 'Add to favorites' }
						</Link>
					</li>
					<li className={ authUserId === userId ? 'disabled' : '' }>
						<Link to="#tourDetails" onClick={ this.onSubscribe }>
							{ isSubscribed ? 'Unsubscribe' : 'Subscribe' }
						</Link>
					</li>
				</ul>
			</div>
		);
	}
}

/* ----------  PropTypes  ---------- */

MoreControls.defaultProps = {
	authUserId: '',
	
	favorite: false,
	isSubscribed: false,
}

MoreControls.propTypes = {
	favorite: PropTypes.bool,
	isSubscribed: PropTypes.bool,
	
	authUserId: PropTypes.string,
	tourId: PropTypes.string.isRequired,
	userId: PropTypes.string.isRequired,
	
	subscribe: PropTypes.func.isRequired,
	unsubscribe: PropTypes.func.isRequired,
	
	setFavorite: PropTypes.func.isRequired,
	setUnfavorite: PropTypes.func.isRequired,
}

/* ----------  Exports  ---------- */

export default MoreControls;
