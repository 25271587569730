const Transitions = {
	ENTRANCES: {
		bounceIn: true,
		bounceInDown: true,
		bounceInLeft: true,
		bounceInRight: true,
		bounceInUp: true,

		lightSpeedIn: true,

		fadeIn: true,
		fadeInDown: true,
		fadeInDownBig: true,
		fadeInLeft: true,
		fadeInLeftBig: true,
		fadeInRight: true,
		fadeInRightBig: true,
		fadeInUp: true,
		fadeInUpBig: true,

		rotateIn: true,
		rotateInDownLeft: true,
		rotateInDownRight: true,
		rotateInUpLeft: true,
		rotateInUpRight: true,
		
		rollIn: true,

		zoomIn: true,
		zoomInDown: true,
		zoomInLeft: true,
		zoomInRight: true,
		zoomInUp: true,

		slideInDown: true,
		slideInLeft: true,
		slideInRight: true,
		slideInUp: true		
	},

	EXITS: {
		bounceOut: true,
		bounceOutDown: true,
		bounceOutLeft: true,
		bounceOutRight: true,
		bounceOutUp: true,

		fadeOut: true,
		fadeOutDown: true,
		fadeOutDownBig: true,
		fadeOutLeft: true,
		fadeOutLeftBig: true,
		fadeOutRight: true,
		fadeOutRightBig: true,
		fadeOutUp: true,
		fadeOutUpBig: true,

		lightSpeedOut: true,

		rotateOut: true,
		rotateOutDownLeft: true,
		rotateOutDownRight: true,
		rotateOutUpLeft: true,
		rotateOutUpRight: true,

		rollOut: true,

		zoomOut: true,
		zoomOutDown: true,
		zoomOutLeft: true,
		zoomOutRight: true,
		zoomOutUp: true,

		slideOutDown: true,
		slideOutLeft: true,
		slideOutRight: true,
		slideOutUp: true
	}
}

/* ----------  Exports  ---------- */

export default Transitions;
