/* ----------  Imports  ---------- */

// Axios
import axios from 'axios';

// Global
import Global from './../../../Constants/Global';

/* ----------  Scripts  ---------- */

const SetTourRating = (data, success, fail) => dispatch => {
	const url = `${ Global.API_BASE_URL }/rating/tour`;

	axios.post(url, data).then(response => {
		const { status, result } = response.data;
		
		dispatch({
			type: 'HOME_SET_TOUR_RATING',
			payload: result
		});

		if(success) success(status, result);
	}).catch(error => {
		const { response } = error;

		if (fail) {
			if (response) {
				fail(response.data.status, response.data.error.reasons);
			} else {
				fail(false, []);
			}
		}
	});
};

/* ----------  Exports  ---------- */

export default SetTourRating;