/* ----------  Imports  ---------- */

// Lodash
import { map, get } from 'lodash';

/* ----------  Scripts  ---------- */

const TOURSPANEL_INITIAL_STATE = {
	legs: [],
	music: [],
	media: {},
	header: {},
	footer: {},
	musicSequence: {},
};

const ReducerToursPanel = (state = TOURSPANEL_INITIAL_STATE, action) => {
	if(action.type === 'TOURSPANEL_GET_LEGS') {
		const response = {};
		response.legs = map(action.legIds, legId => get(action.payload.results, legId));
		return { ...state, ...response };
	} else if(action.type === 'TOURSPANEL_GET_MIXER') {
		return { ...state, ...action.payload };
	} else if(action.type === 'UPDATE_MUSIC_VOLUME') {
		const mixer = { ...state };

		const music = map(mixer.music, item => {
			if(item.musicId === action.payload.musicId) {
				item.volume = action.payload.volume;
			}

			return item;
		});

		return { ...mixer, music };
	} else if(action.type === 'UPDATE_AUDIO_VOLUME') {
		const mixer = { ...state };

		const legs = map(mixer.legs, leg => {
			if(leg.legId === action.payload.legId) {
				leg.audio = leg.audio || {};
				leg.audio.volume = action.payload.volume;
			}
			
			return leg;
		});

		return { ...mixer, legs };
	} else if(action.type === 'TOURSPANEL_REMOVE_DATA') {
		return TOURSPANEL_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerToursPanel;
