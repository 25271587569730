/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Components
import AdminHeader from './../Components/Layouts/Admin/AdminHeader';
import AdminFooter from './../Components/Layouts/Admin/AdminFooter';
import AdminNavigation from './../Components/Layouts/Admin/AdminNavigation';

/* ----------  Scripts  ---------- */

class AdminLayout extends React.Component {
	constructor(props) {
		super(props);

		this.headerRef = React.createRef();
	}

	componentDidMount() {
		document.querySelector('#appStylesheet').href = `${ process.env.PUBLIC_URL }/assets/css/admin.css`;
		document.querySelector('body').classList.add('view-admin', 'body-admin', 'sidebar-minimized', `page-${ this.props.view }`, 'media-step-xl');

		this.initVendors();
	}

	componentWillUnmount() {
		document.querySelector('body').classList.remove('view-admin', 'body-admin', 'sidebar-minimized', `page-${ this.props.view }`, 'media-step-xl');
	}

	initVendors = () => {
		window.initVariables.init();
		window.altair_md.init();
		// window.altair_forms.init();
		window.altair_main_sidebar.init();
	}

	showConversations = (nodeId, nodeType) => {
		const headerRef = this.headerRef.current;

		if(headerRef) headerRef.showConversations(nodeId, nodeType);
	}

	render() {
		return (
			<div className={ `view view-${ this.props.view }` }>
				<AdminHeader ref={ this.headerRef }/>
				<AdminNavigation/>

				<div id="page_content">
					<div id="page_content_inner">
						{ this.props.children }
					</div>
				</div>

				<AdminFooter/>
				<div className="uk-notify" data-uk-notify-holder/>
			</div>
		);
	}
}

AdminLayout.propTypes = {
	view: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired
}

/* ----------  Exports  ---------- */

export default AdminLayout;
