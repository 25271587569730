/* eslint-disable no-underscore-dangle */
/* eslint-disable no-use-before-define */

/* ----------  Imports  ---------- */

// jQuery
import $ from 'jquery';

// Lodash
import { isEmpty } from 'lodash';

// UUID
import uuid from 'uuid';

// Helpers
import Notify from './Notify';

/* ----------  Inline Rename Helper  ---------- */

const InlineRenameHelper = ($target, title, opts) => {
	if(!$target.length) {
		Notify.error('Target not found!');
		return false;
	}

	const nodeRect = $target[0].getBoundingClientRect();
	
	let $cntInlineRename = null;
	let $form = null;
	let $txtTitle = null;
	
	const options = {
		width: 300,
		offset: {
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			...opts.offset
		},
		...opts
	}

	const _handleEvents = () => {
		$txtTitle.off('focus.inlineRename').on('focus.inlineRename', () => {
			$txtTitle.trigger('select');
		});
		
		$txtTitle.off('keydown.inlineRename').on('keydown.inlineRename', e => {
			const key = e.key.toLowerCase();
			const isEscape = (key === 'escape') || (key === 'esc');
			
			if(isEscape) close();
		});

		$txtTitle.off('blur.inlineRename').on('blur.inlineRename', () => {
			$txtTitle.val('');

			$txtTitle.off('keydown.inlineRename');
			$txtTitle.off('focus.inlineRename');
			$txtTitle.off('blur.inlineRename');

			_detach();
		});
	}

	const _render = () => {
		const cntId = `inlineRename_${ uuid.v4() }`;

		const $cnt = $(`<div class="jstree-renamers-container cntInlineRename" id="${ cntId }" style={{ display: 'none' }}>
			<div class="tree-renamers-inner">
				<form class="formRename">
					<input type="text" class="form-control txtTitle"/>
				</form>
			</div>
		</div>`);

		$cnt.appendTo($('body'));

		$cntInlineRename = $(`#${ cntId }`);
		$cntInlineRename.css({ width: options.width });
		
		$form = $('.formRename', $cntInlineRename);
		$txtTitle = $('.txtTitle', $form);

		_handleEvents();
	}

	const _detach = () => {
		const $cnt = $('.cntInlineRename');
		$cnt.detach();
	}

	const onFormSubmit = callback => {
		$form.off('submit.inlineRename').on('submit.inlineRename', e => {
			e.preventDefault();

			const text = $txtTitle.val().trim();
			if(!text || isEmpty(text)) {
				Notify.error('Title cannot be blank.');
				return;
			}

			if(callback) callback($form[0], text);
		});
	}

	const init = callback => {
		_render();
		if(callback) callback();
	}

	const open = callback => {
		close();
		_render();

		$cntInlineRename.css({
			top: nodeRect.top,
			left: nodeRect.left + options.offset.left,
		}).show();

		$txtTitle.val(title);
		$txtTitle.trigger('focus');

		if(callback) callback();
	}

	const close = callback => {
		$txtTitle.trigger('blur');
		if(callback) callback();
	}

	return { onFormSubmit, init, open, close };
}

/* ----------  Exports  ---------- */

export default InlineRenameHelper;
