/* ----------  Reset History  ---------- */

const ResetHistory = callback => dispatch => {
	dispatch({
		type: 'RESET_HISTORY',
	});

	if(callback) callback();
}

/* ----------  Exports  ---------- */

export default ResetHistory;
