/* ----------  Imports  ---------- */

// Lodash
import { mapKeys } from 'lodash';

/* ----------  Scripts  ---------- */

const SPONSORED_LEGS_DATA_INITIAL_STATE = {};

const ReducerMarketplaceSponsoredLegsData = (state = SPONSORED_LEGS_DATA_INITIAL_STATE, action) => {
	if(action.type === 'MARKETPLACE_GET_SPONSORED_LEGS') {
		const response = mapKeys(action.payload.results, 'legId');
		return { ...state, ...response };
	} else if(action.type === 'MARKETPLACE_REMOVE_SPONSORED_LEGS') {
		return SPONSORED_LEGS_DATA_INITIAL_STATE;
	}

	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMarketplaceSponsoredLegsData;