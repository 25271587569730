/* ----------  Imports  ---------- */

// React
import React, { useEffect } from 'react';

// React Redux
import { connect } from 'react-redux';

// React Stripe
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';

// cs_test_a1fbr6A3dyYu5sxVtw9UBk4ktWzrQqiL2siYsbGyqffHPTgL67gY2pyYGq

/* ----------  Registration Checkout  ---------- */

const RegistrationCheckout = () => {
	const stripe = useStripe();
	const elements = useElements();

	const handlePayment = async () => {
		console.log(stripe, elements);

		if(!stripe || !elements) {
			return;
		}

		// const cardElement = elements.getElement(CardElement);

		// When the customer clicks on the button, redirect them to Checkout.
		const result = await stripe.redirectToCheckout({
			sessionId: 'cs_test_a1fbr6A3dyYu5sxVtw9UBk4ktWzrQqiL2siYsbGyqffHPTgL67gY2pyYGq',
		});

		console.log(result);

		// Use your card Element with other Stripe.js APIs
		// const {error, paymentMethod} = await stripe.createPaymentMethod({
		// 	type: 'card',
		// 	card: cardElement,
		// });

		// if (error) {
		// 	console.log('[error]', error);
		// } else {
		// 	console.log('[PaymentMethod]', paymentMethod);
		// }
	}

	const onSubmit = e => {
		e.preventDefault();
		handlePayment();
	}

	useEffect(() => {
		console.log('Hello!', stripe);
	}, []);

	return (
		<div>
			<CardElement/>
			<button className={ `md-btn md-btn-flat md-btn-flat-default ${ !stripe ? 'disabled' : '' }` } onClick={ onSubmit }>
				Pay Registration Fees ($5.00)
			</button>
		</div>
	);
}

 /* ----------  Redux Scripts  ---------- */

const mapStateToProps = state => ({
	user: state.user
});

/* ----------  Exports  ---------- */

export default connect(mapStateToProps, null)(RegistrationCheckout);
