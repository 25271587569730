/* ----------  Imports  ---------- */

// Lodash
import { startsWith } from 'lodash'

/* ----------  Audio Player  ---------- */

const AudioHelper = {
	getPermission: (success, fail) => {
		navigator.mediaDevices.getUserMedia({
			audio: true,
		}).then(stream => {
			if(success) success(stream);
		}).catch(err => {
			if(fail) fail(err);
		});
	},

	toBase64: (blob, callback) => {
		const reader = new window.FileReader();
		reader.readAsDataURL(blob);
		reader.onloadend = () => {
			if(callback) callback(reader.result);
		}
	},

	toBlob: (base64, contentType) => {
		const sliceSize = 1024;
		const byteCharacters = atob(base64);
		const bytesLength = byteCharacters.length;
		const slicesCount = Math.ceil(bytesLength / sliceSize);
		const byteArrays = new Array(slicesCount);

		for(let sliceIndex = 0; sliceIndex < slicesCount; sliceIndex += 1) {
			const begin = sliceIndex * sliceSize;
			const end = Math.min(begin + sliceSize, bytesLength);

			const bytes = new Array(end - begin);
			for(let offset = begin, i = 0; offset < end; i += 1, offset += 1) {
				bytes[i] = byteCharacters[offset].charCodeAt(0);
			}
		
			byteArrays[sliceIndex] = new Uint8Array(bytes);
		}

		return new Blob(byteArrays, { type: contentType });
	},

	checkBase64: (string) => {
		try {
			return btoa(atob(string)) === string;
		} catch (err) {
			return false;
		}
	},

	toUrl: blob => URL.createObjectURL(blob),

	isValid: fileType => startsWith(fileType, 'audio')
}

/* ----------  Exports  ---------- */

export default AudioHelper
