/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Utils
import Scrollbar from '../Utils/Scrollbar';

/* ----------  Scripts  ---------- */

const ContextMenuWrapper = props => {
	if(props.overflow) {
		return (
			<Scrollbar className="menu-inner">
				{ props.children }
			</Scrollbar>
		);
	}

	return (
		<div className="menu-inner">
			{ props.children }
		</div>
	);
}

class ContextMenu extends React.Component {
	componentDidMount() {}

	render() {
		const { type, refId, children, sub, className, options } = this.props;

		const styles = {
			display: 'none',
		}

		return (
			<div
				className={ `md-context-menu ${ className }` }
				data-ctx-menu={ type }
				data-sub-ctx={ sub }
				data-ref-id={ refId }
				data-options={ JSON.stringify(options) }
				style={ styles }>
				<ContextMenuWrapper>
					<ul className="menu-list list-unstyled">
						{ children }
					</ul>
				</ContextMenuWrapper>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

ContextMenuWrapper.defaultProps = {
	overflow: false
}

ContextMenuWrapper.propTypes = {
	overflow: PropTypes.bool,

	children: PropTypes.node.isRequired
}

ContextMenu.defaultProps = {
	refId: '',
	className: '',

	sub: false,
	
	options: {
		center: false,
		offsetTarget: false,
		offset: 0
	},
}

ContextMenu.propTypes = {
	sub: PropTypes.bool,
	options: PropTypes.shape({
		center: PropTypes.bool,
		offsetTarget: PropTypes.bool,
		offset: PropTypes.number
	}),
	
	refId: PropTypes.string,
	className: PropTypes.string,
	type: PropTypes.string.isRequired,

	children: PropTypes.node.isRequired,
}

/* ----------  Exports  ---------- */

export default ContextMenu;
