/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { map, get, omit, isEmpty, size, filter, mapKeys, isEqual } from 'lodash';

// UUID
import uuid from 'uuid/v4';

// UIkit
import Input from './../UIkit/Input';

// Utils
import Toggle from './../Utils/Toggle';

// Helpers
import ToggleHelper from './../../Helpers/ToggleHelper';

/* ----------  Scripts  ---------- */

class TourRepeater extends React.Component {
	constructor(props) {
		super(props);

		this.repeaterRef = React.createRef();

		this.state = {
			data: {}
		}
	}

	componentDidMount() {
		if(isEmpty(this.state.data)) this.addItem();
	}

	onAddItem = e => {
		e.preventDefault();

		this.addItem();
	}

	onRemoveItem = (e, itemId) => {
		e.preventDefault();

		this.removeItem(itemId);
	}

	onInputChange = (e, itemId) => {
		const { data } = this.state;
		const item = get(data, itemId);
		const { value } = e.currentTarget;

		const newItem = {
			...item, value
		}

		this.setState({
			data: {
				...data, [itemId]: newItem
			}
		}, this.updateToggleUI);
	}

	onRadioChecked = value => {
		const { data } = this.state;
		const { updatePhoto } = this.props;

		const item = get(data, value);

		if(updatePhoto) updatePhoto(item.value);
	}

	getData = () => filter(this.state.data, 'value')
	
	setData = (data) => {
		if(data.length) {
			const { photo } = this.props;
			const objs = map(data, item => this.prepareData(item, isEqual(item, photo)));
			const objsData = mapKeys(objs, 'itemId');

			this.setState({
				data: objsData
			}, this.updateToggleUI);
		}
	}

	addItem = () => {
		const { data } = this.state;

		const item = this.prepareData();

		this.setState({
			data: {
				...data, [item.itemId]: item
			}
		}, this.updateToggleUI);
	}

	prepareData = (value = '') => {
		const itemId = uuid();
		return {
			itemId,
			value,
		}
	}

	removeItem = itemId => {
		const { data } = this.state;
		
		if(size(data) > 1) {
			const updatedData = omit(data, itemId);

			this.setState({ data: updatedData });
		}
	}

	updateToggleUI = () => {
		const repeater = this.repeaterRef.current;

		ToggleHelper.updateUI(repeater);
	}

	renderTemplate = item => {
		const { template } = this.props;

		switch(template) {
			case 'default': return this.renderDefaultTemplate(item);
			case 'profilePicture': return this.renderProfilePictureTemplate(item);
			default: return this.renderDefaultTemplate(item);
		}
	}

	renderDefaultTemplate = item => {
		const { name, title } = this.props;

		return (
			<Input
				title={ title }
				name={ name }
				id={ `${ name }_${ item.itemId }` }
				value={ item.value }
				onChange={ e => this.onInputChange(e, item.itemId) }/>
		);
	}

	renderProfilePictureTemplate = item => {
		const { name, title, photo } = this.props;
		const checked = photo === item.value;

		return (
			<div className="uk-input-group">
				<span className="uk-input-group-addon">
					<Toggle
						type="radio"
						name={ `radio_${ name }` }
						value={ item.itemId }
						checked={ checked }
						disabled={ isEmpty(item.value) }
						onChecked={ this.onRadioChecked }/>
				</span>
				<Input
					title={ title }
					name={ name }
					id={ `${ name }_${ item.itemId }` }
					value={ item.value }
					onChange={ e => this.onInputChange(e, item.itemId) }/>
			</div>
		);
	}

	renderRemoveAction = itemId => {
		const { data } = this.state;

		if(size(data) <= 1) return <span className="d-none"/>;

		return (
			<Link to="#addIem" className="atn atn-remove" onClick={ e => this.onRemoveItem(e, itemId) } data-repeater-remove>
				<i className="material-icons">&#xE15C;</i>
			</Link>
		);
	}

	renderItems = () => {
		const { data } = this.state;

		if(isEmpty(data)) return <li className="d-none"/>;

		return map(data, item => (
			<li className="md-repeater-item" key={ item.itemId }>
				<div className="md-repeater-item-inner">
					<div className="md-repeater-item-content">
						{ this.renderTemplate(item) }
					</div>
					<div className="md-repeater-item-actions">
						{ this.renderRemoveAction(item.itemId) }
						<Link to="#removeIem" className="atn atn-add" onClick={ this.onAddItem } data-repeater-add>
							<i className="material-icons">&#xE147;</i>
						</Link>
					</div>
				</div>
			</li>
		));
	}

	render() {
		return (
			<ul className="md-repeater" ref={ this.repeaterRef } data-repeater>
				{ this.renderItems() }
			</ul>
		);
	}
}

/* ----------  Prop Types  ---------- */

TourRepeater.defaultProps = {
	title: '',
	template: 'default',

	updatePhoto: false,
}

TourRepeater.propTypes = {
	title: PropTypes.string,
	template: PropTypes.string,
	name: PropTypes.string.isRequired,
	photo: PropTypes.string.isRequired,

	updatePhoto: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
}

/* ----------  Exports  ---------- */

export default TourRepeater;