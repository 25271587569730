/* ----------  Scripts  ---------- */

const USER_SETTINGS_INITIAL_STATE = {};

const ReducerUserSettings = (state = USER_SETTINGS_INITIAL_STATE, action) => {
	if (action.type === 'USER_GET_SETTINGS') {
		const userProfile = action.payload;
		return { ...state, ...userProfile };
	} else if (action.type === 'USER_SET_SETTING') {
		const setting = action.payload;
		return { ...state, ...setting };
	} else if (action.type === 'USER_REMOVE_SETTINGS') {
		return USER_SETTINGS_INITIAL_STATE;
	}

	return state;
}

/* ----------  Exports  ---------- */

export default ReducerUserSettings;
