/* ----------  Scripts  ---------- */

const COLLECTIONS_INITIAL_STATE = [];

const ReducerMyToursTreesCollections = (state = COLLECTIONS_INITIAL_STATE, action) => {
	if(action.type === 'MYTOURS_TREES_GET_COLLECTIONS') {
		return [ ...state, ...action.payload.results ];
	} else if(action.type === 'MYTOURS_TREES_REMOVE_COLLECTIONS') {
		return COLLECTIONS_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMyToursTreesCollections;