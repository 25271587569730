/* ----------  Scripts  ---------- */

const GOOGLE = window.google.maps;

const MapHelper = {
	checkPanorama: (center, success, error) => {
		const streetviewService = new GOOGLE.StreetViewService();

		// streetviewService.getPanorama({ location: center, radius: 50 }, (data, status) => {
		// 	if((status === 'OK') && success) {
		// 		success();
		// 	} else if(error) {
		// 		error();
		// 	}
		// });

		const latLng = new GOOGLE.LatLng(center);
		streetviewService.getPanoramaByLocation(latLng, 100, (data, status) => {
			if((status === GOOGLE.StreetViewStatus.OK) && success) {
				success();
			} else if(error) {
				error();
			}
		});
	},

	getLocationFromLatLng: (latLng, callback) => {
		const geocoder = new GOOGLE.Geocoder();

		geocoder.geocode({'location': latLng}, (results, status) => {
			if((status === 'OK') && results.length) if(callback) callback(results[0].formatted_address);
		});
	}
}

/* ----------  Exports  ---------- */

export default MapHelper;
