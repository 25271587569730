/* ----------  Imports  ---------- */

// React
import React, { Fragment } from 'react';

// React Redux
import { connect } from 'react-redux';

// Redux
import { bindActionCreators } from 'redux';

// React Router DOM
import { withRouter } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { debounce } from 'lodash';

// Constants
import Global from './../Constants/Global';

// Home Layout
import HomeLayout from './../Layouts/HomeLayout';

// Search
import Search from './../Components/Marketplace/Common/Search';

// Home Containers
import Home from './../Containers/Home/';

// Actions
import GetLanguages from './../Redux/Actions/Languages/GetLanguages';
import GetCountries from './../Redux/Actions/Countries/GetCountries';
import GetCategories from './../Redux/Actions/Categories/GetCategories';

// Modals
import ModalTerms from '../Components/Home/Modals/ModalTerms';

/* ----------  Scripts  ---------- */

class ViewHomepage extends React.Component  {
	constructor(props) {
		super(props);

		this.home = React.createRef();

		this.requestLanguages = debounce(this.doRequestLanguages, 100);
		this.requestCountries = debounce(this.doRequestCountries, 100);
		this.requestCategories = debounce(this.doRequestCategories, 100);
	}

	componentWillMount() {
		this.getLanguages();
		this.getCountries();
		this.getCategories();
		
		document.title = `${ Global.APP_NAME } :: Home`;
	}

	getLanguages = () => {
		this.requestLanguages();
	}
	
	getCategories = () => {
		this.requestCategories();
	}
	
	getCountries = () => {
		this.requestCountries();
	}

	doRequestLanguages = () => {
		this.props.getLanguages();
	}
	
	doRequestCategories = () => {
		this.props.getCategories();
	}
	
	doRequestCountries = () => {
		this.props.getCountries();
	}

	submitForm = filters => {
		const home = this.home.current.wrappedInstance;

		home.getTours(filters);
	}

	render() {
		const { user, location, languages, categories } = this.props;

		return (
			<Fragment>
				<HomeLayout
					ref={ this.homeLayout }
					search={ <Search languages={ languages } categories={ categories } price={ false } submitForm={ this.submitForm }/> }
					user={ user }>
					
					<div className="app-page-content" id="page-content">
						<Home languages={ languages } categories={ categories } location={ location } ref={ this.home }/>
					</div>
				</HomeLayout>
				<ModalTerms/>
			</Fragment>
		);
	}
};

/* ----------  Prop Types  ---------- */

ViewHomepage.defaultProps = {
	location: {}
}

ViewHomepage.propTypes = {
	user: PropTypes.shape().isRequired,
	
	getLanguages: PropTypes.func.isRequired,
	getCountries: PropTypes.func.isRequired,
	getCategories: PropTypes.func.isRequired,

	location: PropTypes.shape(),

	languages: PropTypes.objectOf(PropTypes.object).isRequired,
	categories: PropTypes.objectOf(PropTypes.object).isRequired,
}

/* ----------  Redux Scripts  ---------- */

const mapStateToProps = state => ({
	user: state.user,
	languages: state.languages,
	categories: state.categories,
});

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		getLanguages: GetLanguages,
		getCountries: GetCountries,
		getCategories: GetCategories,
	}, dispatch)
);

/* ----------  Exports  ---------- */

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewHomepage));
