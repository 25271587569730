/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import { PropTypes } from 'prop-types'

// Lodash
import { map, startCase } from 'lodash';

// Selectize
import Selectize from './../../Utils/Selectize';

/* ----------  Scripts  ---------- */

class SelectCategories extends React.Component {
	constructor(props) {
		super(props);

		this.select = React.createRef();

		this.state = {
			primaryCategoryId: null,
		}
	}

	componentDidMount() {}

	getCategoriesOptions = () => {
		const { categories } = this.props;

		if (categories) {
			const data = map(categories, category => ({
				label: startCase(category.name),
				value: category.categoryId,
			}));

			return data;
		}

		return [];
	}

	setPrimary = categoryId => {
		const { setPrimaryCategory } = this.props;
		
		this.setState({ primaryCategoryId: categoryId }, () => {
			if(setPrimaryCategory) setPrimaryCategory(categoryId);
		});
	}

	setValue = value => {
		const select = this.select.current;
		select.setValue(value);
	}

	setSelected = value => {
		const select = this.select.current;
		select.setSelected(value);
		this.setPrimary(value)
	}

	unsetPrimary = categoryId => {
		const { setPrimaryCategory } = this.props;

		if(categoryId === this.state.primaryCategoryId) {
			this.setState({ primaryCategoryId: null }, () => {
				if(setPrimaryCategory) {
					setPrimaryCategory(null);
				}
			});
		}
	}

	render() {
		const data = this.getCategoriesOptions();
		const { maxItems, value, id, name, onChange } = this.props;

		return (
			<Selectize
				multiple
				id={ id }
				type="toggle"
				data={ data }
				name={ name }
				value={ value }
				maxItems={ maxItems }
				onChange={ onChange }
				setPrimary={ this.setPrimary }
				unsetPrimary={ this.unsetPrimary }
				ref={ this.select }/>
		);
	}
}

/* ----------  Prop Types  ---------- */

SelectCategories.defaultProps = {
	maxItems: 3,

	setPrimaryCategory: false,
}

SelectCategories.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	
	maxItems: PropTypes.number,
	
	onChange: PropTypes.func.isRequired,
	
	value: PropTypes.arrayOf(PropTypes.string).isRequired,
	categories: PropTypes.objectOf(PropTypes.object).isRequired,

	setPrimaryCategory: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
}

/* ----------  Exports  ---------- */

export default SelectCategories;