/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Redux
import { connect } from 'react-redux';

// Redux
import { bindActionCreators } from 'redux';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { debounce } from 'lodash';

// Tree
import DataTree from './../../../Components/MyTours/Tree/DataTree';

// Preloader
import Preloader from './../../../Components/Common/Preloader';

// Tree Actions
import GetNodes from './../../../Redux/Actions/MyTours/Trees/ToursTree/GetNodes';
import RemoveNodes from './../../../Redux/Actions/MyTours/Trees/ToursTree/RemoveNodes';

// Action Panel Actions
import GetActionPanelNodes from './../../../Redux/Actions/MyTours/Trees/ToursTree/GetActionPanelNodes';
import RemoveActionPanelNodes from './../../../Redux/Actions/MyTours/Trees/ToursTree/RemoveActionPanelNodes';

// Tour Details
import GetTourProfileDetails from './../../../Redux/Actions/MyTours/Trees/ToursTree/GetTourProfileDetails';
import RemoveTourProfileDetails from './../../../Redux/Actions/MyTours/Trees/ToursTree/RemoveTourProfileDetails';

// Node Details
import GetNodeDetails from './../../../Redux/Actions/MyTours/Trees/GetNodeDetails';
import RemoveNodeDetails from './../../../Redux/Actions/MyTours/Trees/RemoveNodeDetails';

// Util Actions
import CopyNodes from './../../../Redux/Actions/MyTours/Trees/Utils/CopyNodes';
import MoveNodes from './../../../Redux/Actions/MyTours/Trees/Utils/MoveNodes';
import DeleteNode from './../../../Redux/Actions/MyTours/Trees/Utils/DeleteNode';
import RenameNode from './../../../Redux/Actions/MyTours/Trees/Utils/RenameNode';

// Create Actions
import CreateTour from './../../../Redux/Actions/MyTours/Trees/Utils/CreateTour';
import UpdateTour from './../../../Redux/Actions/MyTours/Trees/Utils/UpdateTour';
import CreateFolder from './../../../Redux/Actions/MyTours/Trees/Utils/CreateFolder';

// Hyperlink Actions
import GetHyperlink from './../../../Redux/Actions/MyTours/Trees/Hyperlink/GetHyperlink';
import RemoveHyperlink from './../../../Redux/Actions/MyTours/Trees/Hyperlink/RemoveHyperlink';
import SendHyperlink from './../../../Redux/Actions/MyTours/Trees/Hyperlink/SendHyperlink';

// Components
import NodesTree from './../../../Components/MyTours/Tree/NodesTree';

/* ----------  Scripts  ---------- */

class ToursTree extends DataTree {
	constructor(props) {
		super(props);

		this.treeRef = React.createRef();

		this.state = {
			loading: true,

			sequencePanel: {
				active: false,
				tour: {}
			}
		}

		this.requestTourProfileDetails = debounce(this.doRequestTourProfileDetails, 100);

		this.requestCreateTour = debounce(this.doCreateTour, 100);
		this.requestUpdateTour = debounce(this.doUpdateTour, 100);

		this.requestHyperlink = debounce(this.doGetHyperlink, 100);
		this.requestSendHyperlink = debounce(this.doSendHyperlink, 100);
	}

	getTourProfileDetails = (params, success, fail) => {
		this.requestTourProfileDetails(params, success, fail);
	}

	getHyperlink = (tourId, success, fail) => {
		this.removeHyperlink();
		this.requestHyperlink(tourId, success, fail);
	}

	doRequestTourProfileDetails = (params, success, fail) => {
		this.props.getTourProfileDetails(params, status => {
			const { profileDetails } = this.props;

			if(success) success(status, profileDetails);
		}, (status, reasons) => {
			if(fail) fail(status, reasons);
		});
	}

	doCreateTour = (data, success, fail) => {
		this.props.createTour(data, success, fail);
	}

	doUpdateTour = (data, success, fail) => {
		this.props.updateTour(data, success, fail);
	}

	doGetHyperlink = (tourId, success, fail) => {
		this.props.getHyperlink({ tourId }, (status, response) => {
			if(success) success(response);
		}, () => {
			if(fail) fail();
		});
	}

	doSendHyperlink = (form, success, fail) => {
		this.props.sendHyperlink(form, success, fail);
	}

	createTour = (opts, success, fail) => {
		const { baseNodeType } = this.props;

		const data = {
			baseNodeType,
			...opts
		}

		this.requestCreateTour(data, (status, response) => {
			this.updateTourNode(data, response);

			if(success) success(response);
		}, (status, reasons) => {
			if(fail) fail(reasons);
		});
	}

	updateTour = (data, success, fail) => {
		this.requestUpdateTour(data, (status, response) => {
			this.updateTourNode(data, response);

			if(success) success(response);
		}, (status, reasons) => {
			if(fail) fail(reasons);
		});
	}

	handleSequencePanel = tour => this.props.handleSequencePanel(tour)

	handleTour = data => this.props.handleTour(data)

	showSequencePanel = () => {
		this.updateSequencePanelState({ active: true });
	}

	hideSequencePanel = () => {
		this.updateSequencePanelState({ active: false });
	}

	sendHyperlink = (form, success, fail) => {
		this.requestSendHyperlink(form, success, fail);
	}

	updateTourNode = (data, response) => {
		const tree = this.treeRef.current;

		if(!data.tourId) {
			tree.createNode(response);
		} else {
			const renameNodeData = {
				nodeId: response.nodeId,
				nodeType: response.nodeType,
				title: response.title,
			}

			tree.renameNode(renameNodeData, true);
		}
	}

	removeHyperlink = () => {
		this.props.removeHyperlink();
	}

	removeTourProfileDetails = () => {
		this.props.removeTourProfileDetails();
	}

	renderTree = () => {
		const { loading, sequencePanel } = this.state;
		const { nodes, nodesData, urlTourId } = this.props;

		if(loading) return <Preloader loading={ this.state.loading } center/>;

		// if(!loading && !nodes.length) return <span className="no-records d-block uk-text-small text-center font-italic text-muted p-15 m-0">No nodes found.</span>;

		return (
			<NodesTree
				type="tours"
				rootDraggable
				id="toursTree"
				nodes={ nodes }
				prefix="mytours_"
				play={ this.play }
				allowed={ ['tours'] }
				className="tours-tree"
				urlTourId={ urlTourId }
				nodesData={ nodesData }
				getNodes={ this.requestNodes }
				handleTree={ this.handleTree }
				renameNode={ this.renameNode }
				handleTour={ this.handleTour }
				removeNodes={ this.removeNodes }
				showPreloader={ this.showPreloader }
				hidePreloader={ this.hidePreloader }
				handleContext={ this.handleContext }
				isDraggable={ !sequencePanel.active }
				publishToursToGroup={ this.requestPublishToursToGroup }
				handleSequencePanel={ this.handleSequencePanel }
				ref={ this.treeRef }/>
		);
	}

	render() {
		return (
			<div className="tour-list md-tree-container">
				{ this.renderTree() }
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

ToursTree.defaultProps = {
	nodes: [],
	nodesData: {},
	
	profileDetails: {},
}

ToursTree.propTypes = {
	getTourProfileDetails: PropTypes.func.isRequired,
	removeTourProfileDetails: PropTypes.func.isRequired,
	
	getHyperlink: PropTypes.func.isRequired,
	sendHyperlink: PropTypes.func.isRequired,
	removeHyperlink: PropTypes.func.isRequired,

	createTour: PropTypes.func.isRequired,
	updateTour: PropTypes.func.isRequired,
	
	handleTour: PropTypes.func.isRequired,
	handleSequencePanel: PropTypes.func.isRequired,

	nodes: PropTypes.arrayOf(PropTypes.object),
	nodesData: PropTypes.objectOf(PropTypes.object),

	profileDetails: PropTypes.shape(),
	
	urlTourId: PropTypes.string.isRequired,
}

/* ----------  Redux Scripts  ---------- */

const mapStateToProps = state => ({
	nodes: state.mytours.trees.tours.nodes,
	nodesData: state.mytours.trees.tours.nodesData,
	nodeDetails: state.mytours.trees.nodeDetails,
	
	profileDetails: state.mytours.trees.tours.profileDetails,

	hyperlink: state.mytours.trees.hyperlink,
});

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		getNodes: GetNodes,
		removeNodes: RemoveNodes,

		getNodeDetails: GetNodeDetails,
		removeNodeDetails: RemoveNodeDetails,

		getActionPanelNodes: GetActionPanelNodes,
		removeActionPanelNodes: RemoveActionPanelNodes,
		
		getTourProfileDetails: GetTourProfileDetails,
		removeTourProfileDetails: RemoveTourProfileDetails,

		getHyperlink: GetHyperlink,
		sendHyperlink: SendHyperlink,
		removeHyperlink: RemoveHyperlink,

		createTour: CreateTour,
		createFolder: CreateFolder,
		updateTour: UpdateTour,
		
		copyNodes: CopyNodes,
		moveNodes: MoveNodes,
		deleteNode: DeleteNode,
		renameNode: RenameNode,
	}, dispatch)
);

/* ----------  Exports  ---------- */

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(ToursTree);
