/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// UUID
import uuid from 'uuid/v4';

// jQuery
const $ = window.$;

/* ----------  Scripts  ---------- */

class Slider extends React.Component {
	constructor(props) {
		super(props);

		this.api = null;

		this.slider = React.createRef();
	}

	componentDidMount() {
		this.load();
	}

	shouldComponentUpdate(nextProps) {
		return nextProps.active;
	}

	getValue = () => {
		const uislider = this.slider.current;
		return $(uislider).slider('value');
	}

	setValue = value => {
		const uislider = this.slider.current;
		$(uislider).slider('value', value);
	}

	setOption = (option, value) => {
		const uislider = this.slider.current;
		$(uislider).slider('option', option, value);
	}

	load = () => {
		const uislider = this.slider.current;
		const { min, max, step, value, orientation, onCreate, onStart, onSlide, onStop, onChange } = this.props;

		$(uislider).slider({
			step,
			orientation,

			value,
			range: 'min',
			min: min || 0,
			max: max || 100,

			create: (e, ui) => {
				if(onCreate) onCreate(e, ui);
			},

			start: (e, ui) => {
				if(onStart) onStart(e, ui);
			},

			slide: (e, ui) => {
				if(onSlide) onSlide(e, ui);
			},

			stop: (e, ui) => {
				if(onStop) onStop(e, ui);
			},

			change: (e, ui) => {
				if(onChange) onChange(e, ui);
			}
		});
	}

	render() {
		const { id, active, className, value, width } = this.props;

		const style = {
			width: width > 0 ? `${ width }px` : ''
		}

		return (
			<div className={ `ui-progress progressing ${ className } ${ active ? 'd-block' : 'd-none' }` } style={ style } id={ id } value={ value } ref={ this.slider }>
				<span className="ui-slider-handle"/>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

Slider.defaultProps = {
	value: 0,

	step: 0.1,
	min: 0,
	max: 100,
	width: -1,

	className: '',
	id: `uislider_${ uuid() }`,
	orientation: 'horizontal',
	
	active: false,
	disabled: false,

	onCreate: false,
	onStart: false,
	onSlide: false,
	onStop: false,
	onChange: false,
}

Slider.propTypes = {
	active: PropTypes.bool,

	step: PropTypes.number,
	min: PropTypes.number,
	max: PropTypes.number,
	value: PropTypes.number,
	width: PropTypes.number,
	// disabled: PropTypes.bool,
	id: PropTypes.string,
	className: PropTypes.string,
	orientation: PropTypes.string,

	onCreate: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	onStart: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	onSlide: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	onStop: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
}

/* ----------  Exports  ---------- */

export default Slider;
