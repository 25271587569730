/* ----------  Imports  ---------- */

// Lodash
import { mapKeys, get, omit } from 'lodash';

/* ----------  Scripts  ---------- */

const CONVERSATIONS_LIST_DATA_INITIAL_STATE = {};

const ReducerMessagesConversationsListData = (state = CONVERSATIONS_LIST_DATA_INITIAL_STATE, action) => {
	if(action.type === 'MESSAGES_GET_CONVERSATIONS_LIST') {
		const response = mapKeys(action.payload.results, 'conversationId');
		return { ...state, ...response };
	} else if(action.type === 'MESSAGES_SET_CONVERSATION_READ') {
		const { conversationId } = action.payload;
		const conversation = get(state, conversationId);
		const update = { ...conversation, unread: 0 };

		return { ...state, [conversationId]: update };
	} else if(action.type === 'MESSAGES_ADD_CONVERSATION_ITEM') {
		return { ...state, [action.payload.conversationId]: action.payload };
	} else if(action.type === 'MESSAGES_REMOVE_CONVERSATION_ITEM') {
		const conversation = get(state, action.payload);
		const updatedState = { ...state };
		omit(updatedState, conversation);

		return updatedState;
	} else if(action.type === 'MESSAGES_SET_CONVERSATION_BLOCK') {
		const conversation = get(state, action.payload);
		const update = { ...conversation, blocked: true };

		return { ...state, [action.payload]: update };
	} else if(action.type === 'MESSAGES_SET_CONVERSATION_UNBLOCK') {
		const conversation = get(state, action.payload);
		const update = { ...conversation, blocked: false };

		return { ...state, [action.payload]: update };
	} else if(action.type === 'MESSAGES_REMOVE_CONVERSATIONS_LIST') {
		return CONVERSATIONS_LIST_DATA_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMessagesConversationsListData;