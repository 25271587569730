/* ----------  Scripts  ---------- */

const SetLegsVisible = (legIds, success) => dispatch => {
	dispatch({
		type: 'MARKETPLACE_SET_LEGS_VISIBLE',
		payload: legIds
	});

	if(success) success();
};

/* ----------  Exports  ---------- */

export default SetLegsVisible;