/* ----------  Scripts  ---------- */

const RemoveNodes = () => dispatch => (
	dispatch({
		type: 'MYTOURS_TREES_REMOVE_GROUPED_TOURS_NODES',
	})
);

/* ----------  Exports  ---------- */

export default RemoveNodes;