/* ----------  Scripts  ---------- */

const RemoveNodeDetails = () => dispatch => (
	dispatch({
		type: 'MYTOURS_TREES_REMOVE_NODE_DETAILS',
	})
);

/* ----------  Exports  ---------- */

export default RemoveNodeDetails;
