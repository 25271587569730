/* ----------  Imports  ---------- */

// Axios
import axios from 'axios';

// Constants
import Global from './../../../Constants/Global';

/* ----------  Scripts  ---------- */

const GetTours = (params, success, fail) => dispatch => {
	// const url = `${ Global.DUMMY_API_BASE_URL }/EJBly6nLr`;
	const url = `${ Global.API_BASE_URL }/tours/search`;

	axios.get(url, { params }).then(response => {
		const { status, result } = response.data;

		dispatch({
			type: 'HOME_GET_TOURS',
			payload: result
		});

		if(success) success(status, result.meta);
	}).catch(error => {
		const { response } = error;

		console.log(error);

		if(fail) {
			if(response) {
				fail(response.data.status, response.data.error.reasons);
			} else {
				fail(false, []);
			}
		}
	});
}

/* ----------  Exports  ---------- */

export default GetTours;
