/* ----------  Imports  ---------- */

// Lodash
import { mapKeys, mapValues, get } from 'lodash';

/* ----------  Scripts  ---------- */

const SUBSCRIPTIONS_DATA_INITIAL_STATE = {};

const ReducerSubscriptionsData = (state = SUBSCRIPTIONS_DATA_INITIAL_STATE, action) => {
	if(action.type === 'GET_SUBSCRIPTIONS') {
		return mapKeys(action.payload.results, 'subscriptionId');
	} else if(action.type === 'MARK_SUBSCRIPTION_READ') {
		const subscription = get(state, action.payload);
		const update = { ...subscription, unread: 0 };

		return {...state, [action.payload]: update };
	} else if(action.type === 'MARK_ALL_SUBSCRIPTIONS_READ') {
		const update = mapValues(state, subscription => {
			if(subscription.unread) return { ...subscription, unread: 0 };
			return subscription;
		});

		return { ...state, ...update };
	} else if(action.type === 'REMOVE_SUBSCRIPTIONS') {
		return SUBSCRIPTIONS_DATA_INITIAL_STATE;
	}

	return state;
}

/* ----------  Exports  ---------- */

export default ReducerSubscriptionsData;