/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { isEmpty } from 'lodash';

// jQuery
const $ = window.$;

/* ----------  ImageItem  ---------- */

class ImageItem extends React.Component {
	constructor(props) {
		super(props);

		this.cntImageRef = React.createRef();
		this.imageRef = React.createRef();

		this.state = {
			x: 0,
			y: 0,
			
			top: 0,
			left: 0,
			
			width: 0,
			height: 0,

			visible: true,
		}
	}

	componentDidMount() {
		setTimeout(this.calculate, 100);
	}

	getPosition = (x, y) => {
		const cntImageRef = this.cntImageRef.current;
		
		if(!cntImageRef) {
			return {
				top: 0,
				left: 0,
			};
		}

		const cntCanvas = document.querySelector('.cntGoogleStreetview') || cntImageRef.closest('[data-image-canvas]');

		const stvWidth = cntCanvas.clientWidth / 2;
		const stvHeight = cntCanvas.clientHeight / 2;

		let top = 0;
		let left = 0;

		if(x >= 0) {
			left = stvWidth + x;
		} else {
			left = stvWidth - Math.abs(x);
		}

		if(top >= 0) {
			top = stvHeight - y;
		} else {
			top = stvWidth + Math.abs(y);
		}

		return { top, left };
	}

	show = value => {
		const image = this.imageRef.current;
		if(!image) return;

		const { image: { transition } } = this.props;
		image.classList.add('animated', value || transition.entrance);
	}
	
	hide = value => {
		const image = this.imageRef.current;
		if(!image) return;

		const { image: { transition } } = this.props;
		image.classList.add('animated', value || transition.exit);
	}

	animate = (value, reset = true) => {
		const image = this.imageRef.current;
		if(!image) return;
		
		$(image).removeClass().addClass(`${ value } animated`).one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', () => {
			if(reset) $(image).removeClass();
		});
	}

	calculate = () => {
		const cntImageRef = this.cntImageRef.current;
		if(!cntImageRef) return;

		const { image } = this.props;
		const { height, width, coords, wRatio } = image;

		if(isEmpty(coords)) {
			this.setState({ visible: false });
			return;
		};

		const cntCanvas = document.querySelector('.cntGoogleStreetview') || cntImageRef.closest('[data-image-canvas]');
		
		const stvWidth = cntCanvas.clientWidth;
		const stvHeight = cntCanvas.clientHeight;

		const ratio = wRatio || 0;

		const imgWidth = stvWidth * ratio;
		const imgHeight = (height / width) * stvWidth * ratio;
		
		let stvY = 0;
		const stvX = (coords.x * (1 - ratio) * stvWidth) / coords.parent.width;
		
		const heightCheck = stvHeight >= (stvWidth * (height / width) * ratio);
		if(heightCheck) {
			stvY = coords.y * ((stvHeight - stvWidth * (height / width) * ratio)) / coords.parent.height;
		}

		const position = this.getPosition(stvX, stvY);

		this.setState({
			visible: true,

			x: stvX,
			y: stvY,

			top: position.top,
			left: position.left,

			width: imgWidth,
			height: imgHeight,
		});
	}

	render() {
		const { visible } = this.state;
		if(!visible) return '';
		
		const classList = [];

		const { top, left, width, height } = this.state;
		const { media, image, index, className, imgClassName } = this.props;
		
		const styles = {
			position: 'absolute',
			zIndex: image.zIndex || index,
			top: `${ top }px`,
			left: `${ left }px`,
			width: `${ width }px`,
			height: `${ height }px`,

			...this.props.styles
		}

		const imageStyles = {
			display: 'block',
			height: 'auto',
			width: '100%',

			animationDuration: `${ image.transition.duration }s`,

			...this.props.imageStyles
		}

		// if((positionX === 50) && (positionY === 50)) {
		// 	classList.push('image-center');
		// } else if(positionY === 50) {
		// 	classList.push('image-v-center');
		// } else if(positionX === 50) {
		// 	classList.push('image-h-center');
		// }

		return (
			<span className={ `stv-image ${ classList } ${ className }` } style={ styles } key={ image.imageId } ref={ this.cntImageRef }>
				<img src={ media.src } className={ `img-responsive ${ imgClassName }` } style={ imageStyles } alt={ image.name } ref={ this.imageRef }/>
			</span>
		);
	}
}

/* ----------  Prop Types  ---------- */

ImageItem.defaultProps = {
	index: 1,
	update: false,
	
	className: '',
	imgClassName: '',
	
	styles: {},
	imageStyles: {},
}

ImageItem.propTypes = {
	// update: PropTypes.bool,
	index: PropTypes.number,

	className: PropTypes.string,
	imgClassName: PropTypes.string,
	
	styles: PropTypes.shape(),
	imageStyles: PropTypes.shape(),
	media: PropTypes.shape().isRequired,
	image: PropTypes.shape().isRequired,
}

/* ----------  Exports  ---------- */

export default ImageItem;
