/* ----------  Scripts  ---------- */

const SUBSCRIPTIONS_INITIAL_STATE = [];

const ReducerSubscriptions = (state = SUBSCRIPTIONS_INITIAL_STATE, action) => {
	if(action.type === 'GET_SUBSCRIPTIONS') {
		return action.payload.results;
	} else if(action.type === 'REMOVE_SUBSCRIPTIONS') {
		return SUBSCRIPTIONS_INITIAL_STATE;
	}

	return state;
}

/* ----------  Exports  ---------- */

export default ReducerSubscriptions;