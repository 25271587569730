/* ----------  Imports  ---------- */

// Lodash
import { mapKeys } from 'lodash';

/* ----------  Scripts  ---------- */

const GROUPED_TOURS_NODES_DATA_INITIAL_STATE = {};

const ReducerMyToursTreesGroupedToursNodesData = (state = GROUPED_TOURS_NODES_DATA_INITIAL_STATE, action) => {
	if(action.type === 'MYTOURS_TREES_GET_GROUPED_TOURS_NODES') {
		const response = mapKeys(action.payload.results, 'nodeId');
		return response;
	} else if(action.type === 'MYTOURS_TREES_REMOVE_GROUPED_TOURS_NODES') {
		return GROUPED_TOURS_NODES_DATA_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMyToursTreesGroupedToursNodesData;