/* ----------  Imports  ---------- */

// Lodash
import { sortBy, values } from 'lodash';

// Moment
import moment from 'moment';

// Constants
import Global from '../Constants/Global';

/* ----------  Scripts  ---------- */

const Utils = {
	getSortedTree: state => sortBy(state, ['parentNodeId', 'nodeType']),
	getRandomColorHex: () => Math.floor(Math.random() * 16777215).toString(16),
	getRandomColor: () => `#${ Utils.getRandomColorHex() }`,
	
	getRandomInt: (min = 0, max = 1) => {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min;
	},
	
	getMsppx: (time, width) => {
		if(!time || !width) return 50;
		const msppx = (time / width).toFixed(5);
		return parseFloat(msppx);
		// return Math.floor(msppx);
	},

	getReadableDuration: duration => {
		const time = moment.duration(duration);
		const timeHours = time.hours();
		const timeMinutes = time.minutes();
		const timeSeconds = time.seconds();

		const timeHoursSuffix = timeHours && (timeHours > 1) ? 'hours' : 'hour';

		const timeHoursString = timeHours ? `${ timeHours } ${ timeHoursSuffix }` : '';
		const timeMinutesString = timeMinutes ? `${ timeMinutes } minutes` : '';
		const timeSecondsString = timeSeconds ? `${ timeSeconds } seconds` : '';

		return `${ timeHoursString } ${ timeMinutesString } ${ timeSecondsString }`;
	},

	getGeoThumbnailUrl: (position, pov, size = '100x70') => `https://maps.googleapis.com/maps/api/streetview?size=${ size }&location=${ values(position) }&fov=90&heading=${ pov.heading }&pitch=${ pov.pitch }&key=${ Global.GOOGLE_MAPS_API }`
}

/* ----------  Exports  ---------- */

export default Utils;
