/* ----------  Scripts  ---------- */

const RemoveConversationsList = () => dispatch => (
	dispatch({
		type: 'MESSAGES_REMOVE_CONVERSATIONS_LIST',
	})
);

/* ----------  Exports  ---------- */

export default RemoveConversationsList;