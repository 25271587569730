/* ----------  Imports  ---------- */

// React
import React, { Fragment } from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { get, isEmpty } from 'lodash';

// Constants
import Velocity from '../../Constants/Velocity';

// Preloader
import Preloader from '../Common/Preloader';

// Players
import MusicPlayer from './MusicPlayer';
import LegVideoPlayer from '../ToursPanel/LegVideoPlayer';

// Player Components
import MediaInfo from './MediaInfo';
import ImageItem from '../Player/ImageItem';

// jQuery
const $ = window.$;

/* ----------  Media Player  ---------- */

class TourMediaPlayer extends React.Component {
	constructor(props) {
		super(props);

		this.initialDataState = {
			media: {},
			music: {},
			image: {},
			video: {},
			videoType: 'header',
		}

		this.state = {
			type: null,
			active: false,
			data: this.initialDataState,
		}
	}

	componentDidMount() {}

	onClose = e => {
		e.preventDefault();
		this.disablePlayer();
	}

	enablePlayer = () => {
		const $player = $('[data-media-player]');

		this.updatePlayerState({ active: true, busy: true });
		this.setState({ active: true }, () => {
			$player.velocity('fadeIn', {
				duration: Velocity.DURATION,
				easing: Velocity.EASING,
			});
		});
	}

	disablePlayer = () => {
		const $player = $('[data-media-player]');

		if(!$player.is(':visible')) return;

		$player.velocity('fadeOut', {
			duration: Velocity.DURATION,
			easing: Velocity.EASING,

			complete: () => {
				this.resetPlayer();
			}
		});
	}
	
	preview = (data, type) => {
		this.setState({ data: { ...data }, type }, this.enablePlayer);
	}
	
	resetPlayer = callback => {
		this.setState({ active: false, data: this.initialDataState, type: null }, () => {
			this.updatePlayerState({ type: 'default', active: false, busy: false }, callback);
		});
	}

	updatePlayerState = (updates, callback) => {
		this.props.updatePlayerState(updates, callback);
	}

	// Image

	renderImage = () => {
		const { data: { image, media } } = this.state;

		const mediaItem = get(media, image.mediaId);
		if(!mediaItem) return '';

		return (
			<div className="streetview-images-container">
				<ImageItem media={ mediaItem } image={ image } key={ image.imageId }/>
			</div>
		);
	}

	// Media

	renderMediaContents = () => {
		const { type, active } = this.state;
		if(!active) return '';
		
		switch (type) {
			case 'image': return this.renderImage();
			default: return <p>Oops! Try Again</p>;
		}
	}

	// Details

	renderImageDetails = () => {
		const { data } = this.state;

		if(isEmpty(data.image) || isEmpty(data.media)) return '';

		const image = data.image;
		const media = get(data.media, image.mediaId);

		if(!media) return '';

		const { coords } = image;

		if(isEmpty(coords) || isEmpty(coords.parent)) return;

		const { width, height } = coords.parent;

		let positionX = ((100 / (width / 2)) * coords.x);
		positionX = Math.round(positionX * 100) / 100;
		let positionY = ((100 / (height / 2)) * coords.y);
		positionY = Math.round(positionY * 100) / 100;

		const imageData = [{
			title: 'Name',
			text: media.name
		},{
			title: 'Position (X)',
			text: `${ positionX }%`
		},{
			title: 'Position (Y)',
			text: `${ positionY }%`
		},{
			title: 'Width Ratio',
			text: `${ image.wRatio }`
		}];

		return <MediaInfo data={ imageData }/>;
	}

	renderDetails = () => {
		const { type, active } = this.state;
		if(!active) return '';

		switch (type) {
			case 'image': return this.renderImageDetails();
			default: return <p>Oops! Try Again</p>;
		}
	}

	// Players

	renderVideoPlayer = () => {
		const { data } = this.state;
		const media = get(data.media, data.video.mediaId);

		return (
			<LegVideoPlayer
				waves
				layout="default"
				src={ media.src }
				type={ media.type }
				playerType={ data.videoType }
				hidePanel={ this.disablePlayer }/>
		);
	}
	
	renderMusicPlayer = () => {
		const { data: { music, media } } = this.state;

		return (
			<MusicPlayer
				music={ music }
				media={ media }/>
		);
	}

	renderDefaultPlayer = () => {
		const { type } = this.state;

		return (
			<div className={ `leg-player-container ${ type }-player` } data-player-type={ type }>
				<div className="google-streetview-container cntGoogleStreetview">
					<div className="streetview-inner">
						{ this.renderMediaContents() }
					</div>
				</div>
				<div className="leg-content-container">
					<div className="leg-content-inner">
						<div className="leg-actions-details">
							<div className="leg-actions">
								<div className="leg-controls legControls">
									{/* { this.renderPlayerControls() } */}
								</div>
								<div className="leg-sliders-controls">
									<div className="leg-volume-controls"/>
									<div className="leg-progress-components"/>
								</div>
							</div>
							<div className="leg-details legDetails">
								{ this.renderDetails() }
							</div>
						</div>
						<div className="leg-map legMap"/>
					</div>
				</div>
			</div>
		);
	}

	renderPlayers = () => {
		const { type } = this.state;

		switch(type) {
			case 'video': return this.renderVideoPlayer();
			case 'music': return this.renderMusicPlayer();
			default: return this.renderDefaultPlayer();
		}
	}
	
	render() {
		const { active } = this.state;

		const styles = {
			display: 'none',
		}

		return (
			<Fragment>
				<Preloader loading={ !active } center/>
				<div className="md-card-content p-0" style={ styles } data-media-player>
					<Link to="#graphicPanel" className="btn-close btnClose" onClick={ this.onClose }>
						<i className="material-icons">close</i> Close
					</Link>
					{ this.renderPlayers() }
				</div>
			</Fragment>
		)
	}
}

/* ----------  Prop Types  ---------- */

TourMediaPlayer.propTypes = {
	updatePlayerState: PropTypes.func.isRequired
}

/* ----------  Exports  ---------- */

export default TourMediaPlayer;
