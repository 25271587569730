/* ----------  Imports  ---------- */

// Axios
import axios from 'axios';

// Globals
import Global from './../../../../Constants/Global';

/* ----------  Scripts  ---------- */

const SetContactUnblock = (data, callback) => dispatch => {
	// const url = `${ Global.DUMMY_API_BASE_URL }/VyCEhHcsS`;
	const url = `${ Global.API_BASE_URL }/messages/contact/unblock`;

	axios.post(url, data).then(() => {
		if(callback) callback();
	});
	
	dispatch({
		type: 'MESSAGES_SET_CONTACT_UNBLOCK',
		payload: data.contactId
	});
}

/* ----------  Exports  ---------- */

export default SetContactUnblock;