/* ----------  Imports  ---------- */

// jQuery
import $ from 'jquery';

/* ----------  Imports  ---------- */

const InputUI = {
	initialize: input => {
		const $input = $(input);

		$input.off('blur.input').on('blur.input', () => InputUI.onInputBlur($input));
		$input.off('focus.input').on('focus.input', () => InputUI.onInputFocus($input));
	},

	onInputBlur: input => {
		InputUI.toggleHasValueClass(input);
		InputUI.removeFocusGroupClass(input);
	},

	onInputFocus: input => {
		InputUI.addHasValueInputClass(input);
		InputUI.addFocusGroupClass(input);
	},

	toggleHasValueClass: input => {
		if(input.val().length !== 0) {
			InputUI.addHasValueInputClass(input);
		} else {
			InputUI.removeHasValueInputClass(input);
		}
	},

	addHasValueInputClass: input => {
		input.addClass('md-has-value');
	},

	removeHasValueInputClass: input => {
		input.removeClass('md-has-value');
	},

	addFocusGroupClass: input => {
		const $parent = input.closest('.formGroup');

		if(!$parent.length) return false;
		$parent.addClass('md-focus');

		return true;
	},

	removeFocusGroupClass: input => {
		const $parent = input.closest('.formGroup');

		if(!$parent.length) return false;
		$parent.removeClass('md-focus');

		return true;
	},
}

/* ----------  Exports  ---------- */

export default InputUI;