/* eslint-disable */

/* ----------  Imports  ---------- */

// Lodash
import { omit } from 'lodash'

/* ----------  Scripts  ---------- */

const LS = window.localStorage;
const Storage = {
	getItem: key => LS.getItem(key),
	setItem: (key, value) => LS.setItem(key, value),
	removeItem: key => LS.removeItem(key),

	// lijohe6796@bcpfm.com

	getUserId: () => Storage.getItem('userId'),
	setUserId: userId => Storage.setItem('userId', userId),
	removeUserId: () => Storage.removeItem('userId'),
	
	getUserToken: () => Storage.getItem('userToken'),
	setUserToken: userToken => Storage.setItem('userToken', userToken),
	removeUserToken: () => Storage.removeItem('userToken'),

	getToken: () => Storage.getItem('token'),
	setToken: token => Storage.setItem('token', token),
	removeToken: () => Storage.removeItem('token'),

	getUser: () => {
		const user = Storage.getItem('user');
		if(user) return JSON.parse(user);

		return null;
	},

	setUser: user => Storage.setItem('user', JSON.stringify(user)),
	removeUser: () => Storage.removeItem('user'),

	getFcmToken: () => Storage.getItem('fcmToken'),
	setFcmToken: token => Storage.setItem('fcmToken', token),
	removeFcmToken: () => Storage.removeItem('fcmToken'),

	getTree: () => {
		const tree = Storage.getItem('tree');
		if(tree) return JSON.parse(tree);

		return {};
	},

	setTreeItem: item => {
		const tree = Storage.getTree();
		Storage.setItem('tree', JSON.stringify({ ...tree, [item.nodeId]: item }));
	},

	removeTree: () => Storage.removeItem('tree'),

	removeTreeItem: nodeId => {
		const tree = Storage.getTree();
		Storage.setItem('tree', JSON.stringify(omit(tree, nodeId)));
	}
}

/* ----------  Exports  ---------- */

export default Storage;
