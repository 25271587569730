/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { map, keys, startCase } from 'lodash';

// Constants
import Transitions from './../../Constants/Transitions';

// Utils
import Selectize from './Selectize';

/* ----------  Transition Select  ---------- */

class TransitionSelect extends React.Component {
	constructor(props) {
		super(props);

		this.select = React.createRef();

		this.state = {
			data: []
		}
	}

	componentDidMount() {
		this.load();
	}

	enable = () => {
		const select = this.select.current;
		if(!select) return;
		select.enable();
	}

	disable = () => {
		const select = this.select.current;
		if(!select) return;
		select.disable();
	}

	load = () => {
		const { type } = this.props;
		let transitions = [];

		switch(type) {
			case 'entrances': transitions = keys(Transitions.ENTRANCES); break;
			case 'exits': transitions = keys(Transitions.EXITS); break;
			default: break;
		}

		if(transitions.length) {
			const data = map(transitions, transition => {
				const item = {
					label: startCase(transition),
					value: transition
				}

				return item;
			});

			this.setState({ data });
		}
	}

	render() {
		const { data } = this.state;
		const { id, name, value, onChange } = this.props;

		if(!data.length) return '';
		return <Selectize name={ name } id={ id } data={ data } value={ value } onChange={ onChange } ref={ this.select }/>;
	}
}

/* ----------  Prop Types  ---------- */

TransitionSelect.defaultProps = {
	value: '',
	type: 'entrances',
	name: `transition`,
	id: `txtTransition`,

	onChange: false,
}

TransitionSelect.propTypes = {
	type: PropTypes.string,
	value: PropTypes.string,

	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,

	onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
}

/* ----------  Exports  ---------- */

export default TransitionSelect;
