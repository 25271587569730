/* ----------  Imports  ---------- */

// Lodash
import { mapKeys } from 'lodash';

/* ----------  Scripts  ---------- */

const SUBSCRIPTION_PLANS_DATA_INITIAL_STATE = {};

const ReducerSubsciptionPlans = (state = SUBSCRIPTION_PLANS_DATA_INITIAL_STATE, action) => {
	if(action.type === 'GET_SUBSCRIPTION_PLANS') {
		return mapKeys(action.payload, 'planId');
	} else if(action.type === 'REMOVE_SUBSCRIPTION_PLANS') {
		return SUBSCRIPTION_PLANS_DATA_INITIAL_STATE;
	}

	return state;
}

/* ----------  Exports  ---------- */

export default ReducerSubsciptionPlans;
