/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Redux
import { connect } from 'react-redux';

// Redux
import { bindActionCreators } from 'redux';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { debounce, map, get, slice, find, findIndex, filter, join, isEqual } from 'lodash';

// jQuery
import $ from 'jquery';

// Home Tour Components
import Tour from './../../Components/Home/Tour';
import TourDetails from './../../Components/Home/TourDetails';

// Tours Player
import ToursPlayer from './../ToursPanel/ToursPlayer';

// Home Related Tour Components
import RelatedTours from './../../Components/Home/Related/RelatedTours';
// import RelatedTourDetails from './../../Components/Home/Related/RelatedTourDetails';

// Preloader
import Preloader from './../../Components/Common/Preloader';

// Home Tour Actions
import GetTour from './../../Redux/Actions/Home/GetTour';
import RemoveTour from './../../Redux/Actions/Home/RemoveTour';

import GetTours from './../../Redux/Actions/Home/GetTours';
import RemoveTours from './../../Redux/Actions/Home/RemoveTours';

import SetTourActive from './../../Redux/Actions/Home/SetTourActive';
import SetToursUnActive from './../../Redux/Actions/Home/SetToursUnActive';
import SetTourHovered from './../../Redux/Actions/Home/SetTourHovered';
import SetToursUnHovered from './../../Redux/Actions/Home/SetToursUnHovered';

import SetToursVisible from './../../Redux/Actions/Home/SetToursVisible';

import SubscribeTour from './../../Redux/Actions/Home/SubscribeTour';
import UnsubscribeTour from './../../Redux/Actions/Home/UnsubscribeTour';

import SetTourFavorite from './../../Redux/Actions/Home/SetTourFavorite';
import SetTourUnfavorite from './../../Redux/Actions/Home/SetTourUnfavorite';

import SetProgress from './../../Redux/Actions/Home/SetProgress';

// Home Related Tour Actions
import GetRelatedTour from './../../Redux/Actions/Home/Related/GetRelatedTour';
import RemoveRelatedTour from './../../Redux/Actions/Home/Related/RemoveRelatedTour';

import GetRelatedTours from './../../Redux/Actions/Home/Related/GetRelatedTours';
import RemoveRelatedTours from './../../Redux/Actions/Home/Related/RemoveRelatedTours';

// Tour Rating
import GetTourRating from './../../Redux/Actions/Home/GetTourRating';
import SetTourRating from './../../Redux/Actions/Home/SetTourRating';

// Conversation
import StartConversation from './../../Redux/Actions/Conversation/StartConversation';

// Map
import TourMap from './../../Components/Tour/TourMap';

// Utils
import Scrollbar from './../../Components/Utils/Scrollbar';
// import FullHeight from './../../Components/Utils/FullHeight';

// Helpers
import Notify from './../../Helpers/Notify';

// Pagination
import Pagination from './../../Components/Pagination/';

/* ----------  Scripts  ---------- */

class Home extends React.Component {
	constructor(props) {
		super(props);

		this.map = React.createRef();
		this.mapResults = React.createRef();
		this.toursPanelRef = React.createRef();
		this.resultScrollbar = React.createRef();

		this.initialFiltersState = {
			tags: [],
			title: '',
			legId: '',
			userId: '',
			radius: 2000,
			position: [],
			languages: [],
			categoryIds: [],
			price: [0, 100],
		}

		this.conversationFormInitialState = {
			nodeId: '',
			nodeType: 50,
			message: '',
			origin: 'HOMEPAGE',
		}

		this.state = {
			loading: true,
			activeTourId: '',
			tourDetails: false,
			relatedTours: false,
			playerActive: false,
			activeRelatedTourId: '',
			relatedTourDetails: false,

			startingConversation: false,
			conversationForm: this.conversationFormInitialState,

			googleMap: {
				zoom: 14,
				center: {
					lat: 40.785196,
					lng: -73.969454
				}
			},

			filters: this.initialFiltersState,
 
			meta: {
				page: 0,
				total: 0,
				pages: 0,
				pageSize: 10,
				count: 99,
				startIndex: -1,
			}
		}

		this.requestTour = debounce(this.doRequestTour, 100);
		this.requestTours = debounce(this.doRequestTours, 100);

		this.requestRelatedTour = debounce(this.doRequestRelatedTour, 100);
		this.requestRelatedTours = debounce(this.doRequestRelatedTours, 100);

		this.subscribeTour = debounce(this.doSubscribeTour, 100);
		this.unsubscribeTour = debounce(this.doUnsubscribeTour, 100);

		this.setFavorite= debounce(this.doSetTourFavorite, 100);
		this.setUnfavorite= debounce(this.doSetTourUnfavorite, 100);

		this.getTourRating = debounce(this.doGetTourRating, 100);
		this.setTourRating = debounce(this.doSetTourRating, 100);
		
		this.setProgress = debounce(this.doSetProgress, 100);

		this.startConversation = debounce(this.doStartConversation, 100);
	}

	componentWillMount() {
		// document.title = 'Loading...';
	}

	componentDidMount() {
		this.init();
	}

	componentWillReceiveProps(nextProps) {
		const { location } = this.props;
		const isLocationEqual = isEqual(nextProps.location.search, location.search);

		if(!isLocationEqual) {
			this.init(nextProps.location);
		}
	}
	
	componentWillUnmount() {
		this.removeTour();
		this.removeTours();
		this.removeRelatedTours();
	}

	getTours = (filters, success, fail) => {
		this.setState({
			loading: true,
		});

		this.updateFilters(filters, () => this.requestTours(success, fail));
	}

	getVisibleTours = () => {
		const visibleTours = [];
		const { tours, toursData } = this.props;

		if(!tours || !toursData) return visibleTours;

		map(toursData, item => {
			if(item.visible) {
				const tour = find(tours, ['tourId', item.tourId]);

				visibleTours.push(tour);
			}
		});

		return visibleTours;
	}

	setTourActive = tourId => {
		this.removeActiveTours();
		this.props.setTourActive({
			tourId,
			active: true
		})
	}

	setToursVisible = tourIds => {
		this.props.setToursVisible(tourIds, () => {
			const { meta } = this.state;

			this.updateMeta({
				total: tourIds.length,
				pages: Math.ceil(tourIds.length / meta.pageSize)
			});
		});
	}

	setTourHovered = (tourId, type) => {
		this.setToursUnHovered();
		this.props.setTourHovered({
			tourId,
			hovered: true
		});

		if(type === 'marker') this.goTo(tourId);
	}

	setToursUnHovered = () => {
		this.props.setToursUnHovered();
	}

	doRequestTour = tourId => {
		const params = {
			tourId
		}

		this.removeTour();
		this.props.getTour(params, () => {}, () => {
			this.loadListing();
			Notify.error('Details could not be loaded. Please try again.');
		});
	}

	doRequestTours = (success, fail) => {
		this.removeTours();

		const { filters: { userId, legId, position, title, tags, radius, languages, categoryIds, location } } = this.state;
		const { meta: { count, startIndex } } = this.state;

		const params = {
			count,
			radius,
			startIndex,
		}

		if(title) params.title = title;
		if(userId) params.userId = userId;
		if(legId) params.legId = legId;
		if(location) params.location = location;

		if(tags && tags.length) params.tags = join(tags);
		if(position && position.length) params.position = join(position);
		if(languages && languages.length) params.languages = join(languages);
		if(categoryIds && categoryIds.length) params.categoryIds = join(categoryIds);

		this.props.getTours(params, () => {
			this.setState({ loading: false });

			const gmap = this.map.current;

			if(gmap) gmap.renderMarkers();

			this.reset();
			this.watchUrlForTour();

			if(position && position.length) this.changeMapPosition(position);
			
			this.updateFilters(this.initialFiltersState, success);
		}, (status, reasons) => {
			this.setState({ loading: false });
			
			map(reasons, reason => Notify.error(reason.message));

			if(fail) fail(reasons);
		});
	}

	doRequestRelatedTour = tourId => {
		const params = {
			tourId
		}

		this.removeRelatedTour();
		this.props.getRelatedTour(params, () => {}, () => {
			this.loadListing();
			Notify.error('Details could not be loaded. Please try again.');
		});
	}

	doRequestRelatedTours = (tourId, success, fail) => {
		const data = {
			tourId,
			startIndex: 0,
			count: 99,
		}

		this.removeRelatedTours(tourId);
		this.props.getRelatedTours(data, success, fail);
	}

	doSubscribeTour = tourId => {
		const data = {
			tourId,
		}

		this.props.subscribeTour(data, () => {
			Notify.success('Subscribed successfully!');
		}, () => {
			Notify.error('Something went wrong.');
		});
	}

	doUnsubscribeTour = tourId => {
		const data = {
			tourId,
		}

		this.props.unsubscribeTour(data, () => {
			Notify.success('Unsubscribed successfully!');
		}, () => {
			Notify.error('Something went wrong.');
		});
	}

	doSetTourFavorite = tourId => {
		const data = {
			tourId,
		}

		this.props.setFavorite(data, () => {
			Notify.success('Tour added to favorite successfully!');
		}, () => {
			Notify.error('Something went wrong.');
		});
	}

	doSetTourUnfavorite = tourId => {
		const data = {
			tourId,
		}

		this.props.setUnfavorite(data, () => {
			Notify.success('Tour removed from favorite successfully!');
		}, () => {
			Notify.error('Something went wrong.');
		});
	}

	doGetTourRating = (tourId, success, fail) => {
		const params = {
			tourId,
		}

		this.props.getTourRating(params, (status, result) => {
			if(success) success(result);
		}, (status, reasons) => {
			if(fail) fail(reasons);
		});
	}

	doSetTourRating = (data, success, fail) => {
		this.props.setTourRating(data, (status, result) => {
			if(success) success(result);
		}, (status, reasons) => {
			if(fail) fail(reasons);
		});
	}

	doSetProgress = (data, success, fail) => {
		this.props.setProgress(data, success, fail);
	}

	doStartConversation = () => {
		const { conversationForm } = this.state;

		this.setState({ startingConversation: true });

		this.props.startConversation(conversationForm, () => {
			Notify.success('Message sent.');
			
			this.setState({
				startingConversation: false,
				conversationForm: this.conversationFormInitialState
			});
		}, (status, reasons) => {
			map(reasons, reason => {
				Notify.error(reason.message);
			});
			
			this.setState({ startingConversation: false });
		});
	}

	changePage = page => {
		const { meta } = this.state;

		if((page === meta.pages) || (page < 0) || (page > meta.pages)) return false;
		
		this.scrollListing(0);
		this.updateMeta({
			page
		});

		return true;
	}

	changeMapPosition = position => {
		const gmap = this.map.current;

		const center = {
			lat: position[0],
			lng: position[1],
		}
		
		gmap.setZoom(16);
		gmap.setCenter(center);
	}

	enableToursPanel = callback => {
		this.disableToursPanel();
		
		this.setState({ playerActive: true }, () => {
			if(callback) callback();
		});
	}

	disableToursPanel = () => {
		this.setState({ playerActive: false }, () => {
			const toursPanel = this.toursPanelRef.current ? this.toursPanelRef.current.wrappedInstance : false;
			if(toursPanel) toursPanel.disablePanel();
		});
	}

	goTo = tourId => {
		const { meta } = this.state;
		const visibleTours = this.getVisibleTours();
		const tourIndex = findIndex(visibleTours, ['tourId', tourId]);

		if(tourIndex !== -1) {
			const page = Math.floor(tourIndex / meta.pageSize);

			// this.scrollListing(100);
			this.changePage(page);

			const $listing = $(this.mapResults.current);
			const $tour = $(`[data-tour=${ tourId }]`, $listing);

			if($tour.length) {
				const offset = $tour.innerHeight() * 1.5;
				const position = $tour.position().top
				const top = position > offset ? (position - offset) : 0;
				
				this.scrollListing(top);
			}
		}
	}

	mapIdle = center => {
		const position = [center.lat, center.lng];

		this.updateMeta({ page: 0 }, () => {
			const activeTour = filter(this.props.toursData, { active: true });

			this.updateFilters({ position });

			if(activeTour.length) this.goTo(activeTour[0].tourId);
		});
	}

	handlePageSize = e => {
		const { meta } = this.state;
		const pageSize = parseInt(e.currentTarget.value, 10);

		this.scrollListing(0);
		this.updateMeta({
			pageSize,
			page: 0,
			pages: meta.total / pageSize
		});
	}

	handleTour = tourId => {
		this.setState({
			activeTourId: tourId,
			tourDetails: true
		}, () => {
			this.removeTour();
			this.loadDetails(tourId);
			this.setTourActive(tourId);
			this.playTour(tourId);
		});
	}

	handleRelatedTour = tourId => {
		this.setState({
			activeRelatedTourId: tourId,
			relatedTourDetails: true
		}, () => {
			this.removeRelatedTour();
			this.loadRelatedDetails(tourId);
		});
	}

	hideRelatedTours = () => {
		this.disableToursPanel();
		this.setState({ relatedTours: false });
	}

	hideRelatedTourDetails = () => {
		this.disableToursPanel();

		this.setState({
			relatedTourDetails: false,
			activeRelatedTourId: ''
		});
	}

	init = (location = this.props.location) => {
		const params = new URLSearchParams(location.search);
		const userId = params.get('userId');
		const legId = params.get('legId');

		this.updateFilters({ userId, legId }, () => {
			this.getTours();
		});
	}

	loadDetails = tourId => {
		this.requestTour(tourId);
	}

	loadRelatedDetails = tourId => {
		this.disableToursPanel();
		this.requestRelatedTour(tourId);
	}

	loadListing = () => {
		this.disableToursPanel();
		this.setState({
			tourDetails: false
		});
	}

	loadRelatedTours = (success, fail) => {
		this.requestRelatedTours(this.state.activeTourId, success, fail);
	}

	playTour = tourId => {
		this.enableToursPanel(() => {
			const toursPanel = this.toursPanelRef.current ? this.toursPanelRef.current.wrappedInstance : false;
	
			if(toursPanel) toursPanel.initializePlay(tourId, null, 50, null, false);
		});
	}

	showRelatedTours = tourId => {
		this.setState({
			activeTourId: tourId,
			relatedTours: true
		});
	}

	showRelatedTour = tourId => {
		this.setState({
			activeRelatedTourId: tourId,
			relatedTourDetails: true
		});
	}

	scrollListing = position => {
		const resultScrollbar = this.resultScrollbar.current;
		resultScrollbar.update(position);
	}

	updateConversationFormState = (updates, callback) => {
		const { conversationForm } = this.state;

		this.setState({
			conversationForm: {
				...conversationForm,
				...updates,
			}
		}, () => {
			if(callback) callback();
		});
	}

	updateFilters = (updates, callback) => {
		const { filters } = this.state;

		this.setState({
			filters: {
				...filters,
				...updates
			}
		}, () => {
			if(callback) callback();
		});
	}

	updateMeta = (opts, callback) => {
		const { meta } = this.state;

		this.setState({
			meta: {
				...meta,
				...opts
			}
		}, () => callback ? callback() : null);
	}

	watchUrlForTour = () => {
		const url = new URL(window.location.href);
		const tourId = url.searchParams.get('tourId');

		if(tourId) {
			this.handleTour(tourId);
		}
	}

	reset = () => {
		this.loadListing();

		this.hideRelatedTours();
		this.hideRelatedTourDetails();

		this.removeTour();
		this.removeActiveTours();
		this.removeRelatedTour();
		this.removeRelatedTours();
	}

	removeActiveTours = () => {
		this.props.setToursUnActive();
	}

	removeTour = () => {
		this.props.removeTour();
	}

	removeTours = () => {
		this.props.removeTours();
	}

	removeRelatedTour = () => {
		this.props.removeRelatedTour();
	}

	removeRelatedTours = () => {
		this.props.removeRelatedTours();
	}

	renderTourItems = () => {
		if(this.state.loading) return <Preloader loading/>;

		const { meta } = this.state;
		const { tours, toursData, user } = this.props;

		if(!tours || !toursData) return <span className="no-records d-block text-center p-15"><em>No tours found.</em></span>;

		const startIndex = meta.page * meta.pageSize;
		const endIndex = startIndex + meta.pageSize;
		const visibleTours = this.getVisibleTours();
		const tourItems = slice(visibleTours, startIndex, endIndex);
		const authUserId = user && user.profile ? user.profile.userId : null;

		if(!tourItems.length) return <span className="no-records d-block text-center p-15"><em>No tours found.</em></span>;

		return map(tourItems, item => {
			const tour = get(toursData, item.tourId);

			if(!tour.visible) return <div key={ tour.tourId } className="d-none"/>;

			const primaryCategory = find(tour.categories, ['categoryId', tour.primaryCategory]);

			return (
				<Tour
					tour={ tour }
					key={ tour.tourId }
					index={ tour.autoId }
					authUserId={ authUserId }
					handleTour={ this.handleTour }
					subscribe={ this.subscribeTour }
					setFavorite={ this.setFavorite }
					primaryCategory={ primaryCategory }
					setUnfavorite={ this.setUnfavorite }
					unsubscribe={ this.unsubscribeTour }
					setTourHovered={ this.setTourHovered }
					changeMapPosition={ this.changeMapPosition }
					setToursUnHovered={ this.setToursUnHovered }/>
			);
		});
	}

	renderRelatedTours = () => {
		const { relatedTours } = this.props;
		
		if(!this.state.relatedTours) return <Preloader loading/>;

		return (
			<RelatedTours
				tourGroups={ relatedTours }
				load={ this.loadRelatedTours }
				hide={ this.hideRelatedTours }
				showTour={ this.showRelatedTour }
				setTourActive={ this.setTourActive }
				handleTour={ this.handleRelatedTour }
				changeMapPosition={ this.changeMapPosition }/>
		);
	}

	renderRelatedTourDetails = () => {
		const { relatedActiveTour, languages, user } = this.props;
		const { conversationForm, startingConversation } = this.state;
		
		const language = get(languages, relatedActiveTour.language);
		const authUserId = user && user.profile ? user.profile.userId : null;

		if(!this.state.relatedTourDetails) return <Preloader loading/>;

		return (
			<TourDetails
				related
				language={ language }
				authUserId={ authUserId }
				tour={ relatedActiveTour }
				playTour={ this.playTour }
				load={this.loadRelatedDetails}
				subscribe={ this.subscribeTour }
				getRating={ this.getTourRating }
				setRating={ this.setTourRating }
				setFavorite={ this.setFavorite }
				setTourActive={this.setTourActive}
				setUnfavorite={ this.setUnfavorite }
				hide={ this.hideRelatedTourDetails }
				unsubscribe={ this.unsubscribeTour }
				conversationForm={ conversationForm }
				showRelated={ this.showRelatedTours }
				changeMapPosition={ this.changeMapPosition }
				startConversation={ this.startConversation }
				startingConversation={ startingConversation }
				updateConversationFormState={ this.updateConversationFormState }/>
		);
	}

	renderTourDetails = () => {
		const { activeTour, languages, user } = this.props;
		const { conversationForm, startingConversation } = this.state;
		
		const language = get(languages, activeTour.language);
		const authUserId = user && user.profile ? user.profile.userId : null;

		if(!this.state.tourDetails) return <Preloader loading/>;

		return (
			<TourDetails
				tour={ activeTour }
				language={ language }
				authUserId={ authUserId }
				hide={ this.loadListing }
				playTour={ this.playTour }
				getRating={ this.getTourRating }
				setRating={ this.setTourRating }
				setFavorite={ this.setFavorite }
				subscribe={ this.subscribeTour }
				setUnfavorite={ this.setUnfavorite }
				unsubscribe={ this.unsubscribeTour }
				conversationForm={ conversationForm }
				showRelated={ this.showRelatedTours }
				startConversation={ this.startConversation }
				startingConversation={ startingConversation }
				updateConversationFormState={ this.updateConversationFormState }/>
		);
	}

	renderTourPlayer = () => {
		const { playerActive } = this.state;

		if(!playerActive) return <div className="d-none"/>;
		
		const styles = {
			display: 'none'
		}

		return (
			<div className="streetview-players-container active" style={ styles } data-graphic-panel>
				<ToursPlayer
					layout="homepage"
					ref={ this.toursPanelRef }
					setProgress={ this.setProgress }/>
			</div>
		);
	}

	render() {
		const { tourDetails, relatedTours, relatedTourDetails, meta, googleMap } = this.state;
		const activeTourClass = tourDetails ? 'show-detail' : '';
		const relatedToursClass = relatedTours ? 'show-related' : '';
		const relatedTourClass = relatedTourDetails ? 'show-related-detail' : '';

		return (
			<div className="hero-section full-screen has-map has-sidebar scrollSection" data-section-title="Hero Map">
				<div className="map-wrapper" id="mapWrapper">
					{ this.renderTourPlayer() }

					<TourMap 
						type="default"
						className="map"
						id="homepageMap"
						reset={ this.reset }
						zoom={ googleMap.zoom }
						mapIdle={ this.mapIdle }
						playTour={ this.playTour }
						streetViewControl={ false }
						center={ googleMap.center }
						data={ this.props.toursData }
						handleTour={ this.handleTour }
						setTourActive={ this.setTourActive }
						showRelated={ this.showRelatedTours }
						setToursVisible={ this.setToursVisible }
						setTourHovered={ this.setTourHovered }
						setToursUnHovered={ this.setToursUnHovered }
						ref={ this.map }/>
				</div>
				<div className={ `results-wrapper ${ activeTourClass } ${ relatedToursClass } ${ relatedTourClass }` } id="mapResults" ref={ this.mapResults }>
					<div className="sidebar-detail main-sidebar-detail">
						{ this.renderTourDetails() }
					</div>
					<div className="results">
						<div className="section-btn-close">
							<Link to="#results" className="btnCloseResults">&times;</Link>
						</div>
						<div className="section-title">
							<h2>Search Results <span className="results-number">{ meta.total }</span></h2>
						</div>
						<Scrollbar className="results-content" ref={ this.resultScrollbar }>
							{ this.renderTourItems() }
						</Scrollbar>
						<div className="results-footer resultsFooter text-center">
							<div className="results-length">
								<label htmlFor="mapResultsLength">Tours per page</label>
								<select className="form-control" id="mapResultsLength" value={ meta.pageSize } onChange={ this.handlePageSize }>
									<option value="5">5</option>
									<option value="10">10</option>
									<option value="20">20</option>
									<option value="30">30</option>
								</select>
							</div>
							<div className="results-pagination p-l-10 p-r-10">
								<Pagination
									id="marketplacePagination"
									changePage={ this.changePage }
									page={ meta.page }
									pages={ meta.pages }/>
							</div>
						</div>
					</div>
					<div className="related-results">
						{ this.renderRelatedTours() }
					</div>
					<div className="sidebar-detail related-result-detail">
						<div className="sidebar-wrapper">
							{ this.renderRelatedTourDetails() }
						</div>
					</div>
				</div>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

Home.defaultProps = {
	activeTour: {},
	
	relatedTours: {},
	relatedActiveTour: {},

	user: {},
	
	location: {},

	toursData: {},
}

Home.propTypes = {
	getTour: PropTypes.func.isRequired,
	removeTour: PropTypes.func.isRequired,
	
	getTours: PropTypes.func.isRequired,
	removeTours: PropTypes.func.isRequired,
	
	setTourActive: PropTypes.func.isRequired,
	setToursUnActive: PropTypes.func.isRequired,
	
	setTourHovered: PropTypes.func.isRequired,
	setToursUnHovered: PropTypes.func.isRequired,
	
	setToursVisible: PropTypes.func.isRequired,
	
	getRelatedTour: PropTypes.func.isRequired,
	removeRelatedTour: PropTypes.func.isRequired,
	
	getRelatedTours: PropTypes.func.isRequired,
	removeRelatedTours: PropTypes.func.isRequired,

	subscribeTour: PropTypes.func.isRequired,
	unsubscribeTour: PropTypes.func.isRequired,

	setFavorite: PropTypes.func.isRequired,
	setUnfavorite: PropTypes.func.isRequired,

	getTourRating: PropTypes.func.isRequired,
	setTourRating: PropTypes.func.isRequired,
	
	setProgress: PropTypes.func.isRequired,
	
	startConversation: PropTypes.func.isRequired,

	relatedTours: PropTypes.shape(),
	
	tours: PropTypes.arrayOf(PropTypes.object).isRequired,
	
	toursData: PropTypes.objectOf(PropTypes.object),
	languages: PropTypes.objectOf(PropTypes.object).isRequired,
	
	user: PropTypes.shape(),

	activeTour: PropTypes.shape({
		tourId: PropTypes.string
	}),

	relatedActiveTour: PropTypes.shape({
		tourId: PropTypes.string
	}),

	location: PropTypes.shape(),
}

/* ----------  Redux Scripts  ---------- */

const mapStateToProps = state => ({
	tours: state.home.tours,
	toursData: state.home.toursData,
	activeTour: state.home.activeTour,
	
	relatedTours: state.home.relatedTours,
	relatedActiveTour: state.home.relatedActiveTour,
	
	user: state.user,
});


const mapDispatchToProps = dispatch => (
	bindActionCreators({
		getTour: GetTour,
		removeTour: RemoveTour,
	
		getTours: GetTours,
		removeTours: RemoveTours,
	
		setTourActive: SetTourActive,
		setToursUnActive: SetToursUnActive,
	
		setTourHovered: SetTourHovered,
		setToursUnHovered: SetToursUnHovered,

		setToursVisible: SetToursVisible,
		
		getRelatedTour: GetRelatedTour,
		removeRelatedTour: RemoveRelatedTour,
	
		getRelatedTours: GetRelatedTours,
		removeRelatedTours: RemoveRelatedTours,

		subscribeTour: SubscribeTour,
		unsubscribeTour: UnsubscribeTour,

		setFavorite: SetTourFavorite,
		setUnfavorite: SetTourUnfavorite,

		getTourRating: GetTourRating,
		setTourRating: SetTourRating,
		
		setProgress: SetProgress,

		startConversation: StartConversation,
	}, dispatch)
);

/* ----------  Exports  ---------- */

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Home);
