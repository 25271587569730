/* ----------  Imports  ---------- */

// Prop Types
import PropTypes from "prop-types";

// jQuery
import $ from 'jquery';

// jsTree
import 'jstree';

// Tree
import Tree from './Tree';

// Helpers
import Notify from "./../../../Helpers/Notify";

/* ----------  Scripts  ---------- */

class ActionPanelTree extends Tree {
	constructor(props) {
		super(props);

		this.state = {
			loaded: true
		}
	}

	componentDidMount() {
		this.load();
	}

	checkIsLoaded = () => this.state.loaded

	load = () => {
		const tree = this.tree.current;

		$(tree).jstree({
			core: {
				dblclick_toggle : false,
				check_callback: true
			},
		});

		$(tree).on('ready.jstree', () => {
			this.api = $(tree).jstree(true);

			this.renderNodes();
			this.handleTreeActions();
		});
	}

	handleTreeActions = () => {
		const tree = this.tree.current;

		$(tree).off('click.node').on('click.node', '.jsTreeAnchor', e => {
			e.preventDefault();

			const $node = $(e.currentTarget);
			const $tree = $(tree);
			const $parent = $node.closest('li');
			const $nodes = $('li', $tree).not($parent);
			const nodeId = $parent.data('id');
			const inLimit = this.checkLimit($parent.attr('id'));

			if(!inLimit) {
				Notify.error('Tree limit exceeding. Please select another node.');
				
				return false;
			}

			if(!$parent.hasClass('jstree-node-selected')) {
				this.handleNode(nodeId);

				$nodes.removeClass('jstree-node-selected');
				$parent.addClass('jstree-node-selected');
			} else {
				this.handleNode(null);

				$parent.removeClass('jstree-node-selected');
			}

			return true;
		});
	}

	handleNode = nodeId => {
		const id = this.getPrefixedId(nodeId);
		const node = id ? this.getNode(id): null;

		this.props.handleNode(nodeId, node);
	}
}

/* ----------  Prop Types  ---------- */

ActionPanelTree.propTypes = {
	handleNode: PropTypes.func.isRequired
}

/* ----------  Exports  ---------- */

export default ActionPanelTree;
