/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Holder
import Holder from 'holderjs';

/* ----------  Scripts  ---------- */

class ProfilePicture extends React.Component {
	componentDidMount() {
		this.loadHolder();
	}

	loadHolder = () => {
		const { src } = this.props;

		if(!src) {
			Holder.run({
				themes: {
					stour: {
						size: 30,
						fg: '#fff',
						bg: '#1976d2',
						font: 'Montserrat',
						fontweight: 'normal'
					}
				},
			});
		}
	}

	render() {
		const { title, src, className } = this.props;

		const text = title ? title.charAt(0) : 'ST';
		const placeholder = `holder.js/100x100?theme=stour&auto=yes&text=${ text }`;

		if(!src) return <img alt={ title } className={ `img-holderjs ${ className }` } data-src={ placeholder }/>;

		return (
			<img alt={ title } className={ className } src={ src }/>
		);
	}
}

/* ----------  Prop Types  ---------- */

ProfilePicture.defaultProps = {
	className: '',
	title: 'Street Tour',
	src: '',
}

ProfilePicture.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	src: PropTypes.string,
}

/* ----------  Exports  ---------- */

export default ProfilePicture;
