/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// jQuery
// import $ from 'jquery';

// jQuery
const $ = window.$;

/* ----------  Scripts  ---------- */

class ResizablePanel extends React.Component {
	constructor(props) {
		super(props);

		this.handleRef = React.createRef();
		this.resizableRef = React.createRef();
		this.containmentRef = React.createRef();
		
		this.draggable = null;
		
		this.panes = [];

		this.state = {
			active: false
		}
	}

	setHeight = (el, height) => {
		el.style.height = `${ height }px`;
	}

	setHandlePosition = () => {
		const handle = this.handleRef.current;
		
		if(handle) {
			handle.style.top = `${ this.panes[0].clientHeight }px`;
		}
	}

	setPanes = () => {
		const resizable = this.resizableRef.current;

		if(resizable) {
			this.panes = resizable.querySelectorAll('[data-rpane]');
		} else {
			this.panes = [];
		}
	}

	resetHeights = () => {
		this.panes[0].style.height = '';
		this.panes[1].style.height = '';
	}

	load = () => {
		const handle = this.handleRef.current;
		
		this.setPanes();
		
		let firstPaneHeight = 0;
		let secondPaneHeight = 0;

		if(handle) {
			this.draggable = $(handle).draggable({
				containment: this.containmentRef.current,

				create: () => {
					handle.classList.add('resizer-active');

					this.setState({ active: true });

					this.setHandlePosition();
				},

				start: () => {
					this.setPanes();

					document.body.classList.add('dragging');

					firstPaneHeight = this.panes[0].clientHeight;
					secondPaneHeight = this.panes[1].clientHeight;
				},

				drag: (e, ui) => {

					const firstHeight = parseInt(ui.position.top, 10);
					const secondHeight = (firstPaneHeight + secondPaneHeight) - firstHeight;

					this.setHeight(this.panes[0], firstHeight);
					this.setHeight(this.panes[1], secondHeight);
				},

				stop: () => {
					document.body.classList.remove('dragging');
				}
			});
		}
	}

	destroy = () => {
		const handle = this.handleRef.current;

		if(handle && this.state.active) {
			this.resetHeights();
			$(handle).draggable('destroy');
		}

		this.setState({ active: false });
	}

	reload = () => {
		this.setPanes();
		this.resetHeights();
		this.setHandlePosition();
	}

	render() {
		const { active } = this.state;
		const { className, children, id, containmentHeight, containmentTop, player } = this.props;

		return (
			<div className={ className } id={ id } ref={ this.resizableRef } data-player={ player || null }>
				<div className={ `panel-resizer resizer ${ active ? 'd-block' : 'd-none' }` } ref={ this.handleRef }>
					<span className="resize-handle resizeHandle">
						<span className="fas fa-bars"/>
					</span>
				</div>
				<div className={ `panel-containment ${ active ? 'd-block' : 'd-none' }` } ref={ this.containmentRef } style={{ height: containmentHeight, top: containmentTop }}/>
				{ children }
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

ResizablePanel.defaultProps = {
	className: '',
	containmentHeight: 300,
	containmentTop: '50%',
	player: null,
}

ResizablePanel.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string.isRequired,
	
	player: PropTypes.string,
	containmentTop: PropTypes.string,
	containmentHeight: PropTypes.number,
	
	children: PropTypes.node.isRequired,
}

/* ----------  Exports  ---------- */

export default ResizablePanel;
