/* ----------  Scripts  ---------- */

const RemoveCreditCards = () => dispatch => {
	dispatch({
		type: 'USER_REMOVE_CREDIT_CARDS',
	});
};

/* ----------  Exports  ---------- */

export default RemoveCreditCards;
