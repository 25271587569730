/* ----------  Scripts  ---------- */

const Velocity = {
	DURATION: 300,
	EASING: [0.35, 0, 0.25, 1],	
}

/* ----------  Exports  ---------- */

export default Velocity;
