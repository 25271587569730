/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { get } from 'lodash';

// Wave Surfer
import WaveSurfer from 'wavesurfer.js';

// Helpers
import Utils from '../../Helpers/Utils';

// Player Components
import MediaInfo from './MediaInfo';

// Utils
import Slider from '../Utils/Slider';

/* ----------  Music Player  ---------- */

class MusicPlayer extends React.Component {
	constructor(props) {
		super(props);

		this.wave = null;
		this.wave = null;
		this.waveTimer = null;

		this.sliderRef = React.createRef();
		this.waveSurferRef = React.createRef();

		this.state = {
			duration: 0,
			paused: true,
			loaded: false,
			playing: false,
		}
	}

	componentDidMount() {
		this.load();
	}

	componentWillUnmount() {
		this.stop();
	}

	// Event Binders

	onPlay = e => {
		e.preventDefault();
		this.play();
	}
	
	onPause = e => {
		e.preventDefault();
		this.pause();
	}

	onSliderStart = () => {
		this.pause();
	}

	onSliderStop = (e, ui) => {
		this.seekPlayer(ui.value);
	}

	// Wave

	load = () => {
		const { media, music } = this.props;
		const musicMedia = get(media, music.mediaId);

		this.createWave();
		this.loadWave(musicMedia.src);
	}

	createWave = () => {
		const wave = WaveSurfer.create({
			container: this.waveSurferRef.current,

			barWidth: 2,
			barHeight: 1,
			barGap: null,

			waveColor: '#444444',
			progressColor: '#1976d2',
		});

		wave.on('ready', () => {
			this.setState({ loaded: true, duration: wave.getDuration() }, () => {
				if(this.sliderActions().setOption) {
					this.sliderActions().setOption('max', wave.getDuration());
				}
			});
		});

		wave.on('play', () => {
			this.setState({ playing: true, paused: false });
		});
		
		wave.on('pause', () => {
			this.setState({ playing: false, paused: true });
		});
		
		wave.on('finish', () => {
			this.setState({ playing: false, paused: true }, this.stop);
		});

		wave.on('audioprocess', () => {
			this.runSlider(this.wave.getCurrentTime());
		})

		this.wave = wave;
	}

	loadWave = src => {
		if(this.wave) {
			this.wave.load(src);
		}
	}

	play = () => {
		if(this.wave) this.wave.play();
	}
	
	pause = () => {
		if(this.wave) this.wave.pause();
	}
	
	stop = () => {
		if(this.wave) {
			this.wave.stop();
			this.sliderActions().setValue(0);
		}
	}

	seekPlayer = value => {
		this.wave.setCurrentTime(value);
		this.play();
	}

	// Slider

	sliderActions = () => {
		const slider = this.sliderRef.current;

		return {
			getValue: () => {
				if(slider) return slider.getValue();
				return 0;
			},

			setValue: value => {
				if(slider) slider.setValue(value);
			},

			setOption: (option, value) => {
				if(slider) slider.setOption(option, value);
			},
		};
	}

	runSlider = value => {
		if(this.sliderActions().setValue) this.sliderActions().setValue(value);
	}

	renderSlider = () => {
		const { loaded, duration } = this.state;
		if(!loaded || !duration) return '';
		return <Slider active={ loaded } className="leg-progress" ref={ this.sliderRef } onStart={ this.onSliderStart } onStop={ this.onSliderStop }/>
	}

	renderDetails = () => {
		const { loaded, duration } = this.state;
		if(!loaded) return '';

		const { music, media } = this.props;
		const mediaItem = get(media, music.mediaId);

		if(!mediaItem) return '';

		const musicData = [{
			title: 'Name',
			text: mediaItem.name
		},{
			title: 'Duration',
			text: Utils.getReadableDuration(duration * 1000),
		}]

		return <MediaInfo data={ musicData }/>;
	}

	render() {
		const { loaded, playing, paused } = this.state;

		return (
			<div className={ `leg-player-container leg-music-player` } data-player="music">
				<div className="google-streetview-container">
					<div className="streetview-inner">
						<div className="audio-visualizer" ref={ this.waveSurferRef }/>
					</div>
				</div>
				<div className="leg-content-container" id="cntLegContent">
					<div className="leg-content-inner">
						<div className="leg-actions-details">
							<div className="leg-actions">
								<div className="leg-controls legControls">
									<ul>
										<li className={ (!playing || paused) ? 'd-block' : 'd-none' } data-uk-tooltip="{'pos': 'top'}" title="Play">
											<Link to="#graphicPanel" className="btn-play btnPlay" onClick={ this.onPlay } data-atn>
												<i className="material-icons">&#xE037;</i>
											</Link>
										</li>
										<li className={ (playing || !paused) ? 'd-block' : 'd-none' } data-uk-tooltip="{'pos': 'top'}" title="Pause">
											<Link to="#graphicPanel" className="btn-pause btnPause bg-primary text-white" onClick={ this.onPause } data-atn>
												<i className="material-icons">pause</i>
											</Link>
										</li>
									</ul>
								</div>
								<div className="leg-sliders-controls">
									<div className="leg-volume-controls"/>
									<div className={ `leg-progress-components ${ loaded ? 'active' : '' }` }>
										{ this.renderSlider() }
									</div>
								</div>
							</div>
							<div className="leg-details legDetails">
								{ this.renderDetails() }
							</div>
						</div>
						<div className="leg-map legMap"/>
					</div>
				</div>
			</div>
		)
	}
}

/* ----------  Prop Types  ---------- */

MusicPlayer.propTypes = {
	music: PropTypes.shape().isRequired,
	media: PropTypes.objectOf(PropTypes.object).isRequired,
}

/* ----------  Exports  ---------- */

export default MusicPlayer;
