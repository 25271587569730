/* ----------  Imports  ---------- */

// Lodash
import { maxBy } from 'lodash';

// New Message
import NewMessage from './../../../../Helpers/NewMessage';

/* ----------  Scripts  ---------- */

const CONVERSATION_MESSAGES_INITIAL_STATE = [];

const ReducerConversationMessages = (state = CONVERSATION_MESSAGES_INITIAL_STATE, action) => {
	if(action.type === 'GET_CONVERSATION_MESSAGES') {
		return [ ...state, ...action.payload.results ];
	} else if(action.type === 'SEND_CONVERSATION_MESSAGE') {
		const { data, user, messageId } = action.payload;
		const lastMessage = maxBy(state, 'autoId');
		const message = NewMessage.create(messageId, data, user, lastMessage);
		const updatedState = [ ...state, message ];

		return updatedState;
	} else if(action.type === 'REMOVE_CONVERSATION_MESSAGES') {
		return CONVERSATION_MESSAGES_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerConversationMessages;