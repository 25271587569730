/* ----------  Add History  ---------- */

const UpdateHistory = (historyId, updates, callback) => dispatch => {
	dispatch({
		type: 'UPDATE_HISTORY',
		payload: { historyId, updates }
	});

	if(callback) callback();
}

/* ----------  Exports  ---------- */

export default UpdateHistory;
