/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

/* ----------  Scripts  ---------- */

class Progress extends React.Component {
	componentDidMount() {}

	render() {
		const { active, saving } = this.props;

		return (
			<div className={ `leg-progress infinite-progress slider ${ active ? 'active' : '' } ${ saving ? 'saving' : '' }` }>
				<div className="line"/>
				<div className="subline inc"/>
				<div className="subline dec"/>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

Progress.propTypes = {
	active: PropTypes.bool.isRequired,
	saving: PropTypes.bool.isRequired
}

/* ----------  Exports  ---------- */

export default Progress;