/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

/* ----------  Scripts  ---------- */

class GoogleMap extends React.Component {
	constructor(props) {
		super(props);

		this.map = null;
		this.streetviewService = null;
		
		this.defaultMarker = null;

		this.mapCanvas = React.createRef();
		this.google = window.google.maps;

		this.loaded = false;
	}

	getCenter = (flat = true) => {
		const center = this.map.getCenter();

		if(flat) {
			return {
				lat: center.lat(),
				lng: center.lng(),
			}
		}
		
		return center;
	}

	getGeoLocation = (callback) => {
		if (navigator.geolocation) {
			console.log('FETCHING USER GEO');
			navigator.geolocation.getCurrentPosition(response => {
				const { coords: { latitude, longitude } } = response;

				const center = {
					lat: latitude,
					lng: longitude
				}

				this.setCenter(center);
				this.updateAndSync(center);

				if(callback) callback(center);
			});

			return;
		}

		if(callback) callback(null);
	}

	getZoom = () => this.map.getZoom()

	setCenter = center => {
		const position = this.makeCenter(center.lat, center.lng)
		this.map.panTo(position);
	}
	
	setZoom = zoom => this.map.setZoom(zoom)

	setDefaultMarker = center => {
		this.defaultMarker = new this.google.Marker({
			position: center,
			map: this.map
		});
	}

	makeCenter = (lat, lng) => new this.google.LatLng(lat, lng)

	load = callback => {
		const { zoom, center, streetViewControl, zoomControl } = this.props;
		const mapCanvas = this.mapCanvas.current;
		// const mapStyles = [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#c6c6c6"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#dde6e8"},{"visibility":"on"}]}];
		const mapStyles = [];

		this.streetviewService = new this.google.StreetViewService();

		this.map = new this.google.Map(mapCanvas, {
			zoom,
			zoomControl,
			streetViewControl,

			styles: mapStyles,
			mapTypeId: 'roadmap',
			mapTypeControl: false,
			fullscreenControl: false,
			gestureHandling: 'greedy',
			center: center ? this.makeCenter(center.lat, center.lng) : null,
		});

		this.google.event.addListenerOnce(this.map, 'idle', () => {
			if(callback) callback(this.map);
		});
	}

	updateAndSync = center => {
		const { streetview, updatePosition } = this.props;

		if(!streetview || !updatePosition) return;

		const streetviewApi = streetview.current;
		const pov = streetviewApi ? streetviewApi.getPov() : null;

		updatePosition(center, pov, () => {
			if(streetviewApi) streetviewApi.setPosition(center);
		});
	}

	validateLatLng = (lat, lng) => {
		let latValid = false;
		let lngValid = false;

		if((typeof lat === 'number') && (lat >= -90) && (lat <= 90)) {
			latValid = true;
		}

		if((typeof lng === 'number') && (lng >= -180) && (lng <= 180)) {
			lngValid = true;
		}

		return {
			lat: latValid,
			lng: lngValid
		};
	}

	render() {
		const { id, className } = this.props;
		return <div className={ `google-map googleMap googleComponent ${ className }` } id={ id } ref={ this.mapCanvas }/>;
	}
}

/* ----------  Prop Types  ---------- */

GoogleMap.defaultProps = {
	zoom: 14,
	className: '',
	type: 'default',
	zoomControl: true,
	streetViewControl: false,

	center: null
}

GoogleMap.propTypes = {
	zoom: PropTypes.number,
	
	zoomControl: PropTypes.bool,
	streetViewControl: PropTypes.bool,

	className: PropTypes.string,
	id: PropTypes.string.isRequired,

	center: PropTypes.shape({
		lat: PropTypes.number,
		lng: PropTypes.number,
	}),
}

/* ----------  Exports  ---------- */

export default GoogleMap;
