/* ----------  Imports  ---------- */

// Lodash
import { find, remove } from 'lodash';

/* ----------  Scripts  ---------- */

const CONTACTS_LIST_INITIAL_STATE = [];

const ReducerMessagesContactsList = (state = CONTACTS_LIST_INITIAL_STATE, action) => {
	if(action.type === 'MESSAGES_GET_CONTACTS_LIST') {
		return [ ...state, ...action.payload.results ];
	} else if(action.type === 'MESSAGES_REMOVE_CONTACT_ITEM') {
		const contact = find(state, ['contactId', action.payload]);
		const updatedState = [ ...state ];
		remove(updatedState, contact);

		return updatedState;
	} else if(action.type === 'MESSAGES_REMOVE_CONTACTS_LIST') {
		return CONTACTS_LIST_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMessagesContactsList;