/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
// import { map } from 'lodash';

// Disqus
import Disqus from 'disqus-react';

// Constants
import Global from './../../../Constants/Global';

// Modal
import Modal from './../../UIkit/Modal';

/* ----------  Scripts  ---------- */

class ModalComments extends React.Component {
	constructor(props) {
		super(props);

		this.modal = React.createRef();

		this.state = {
			loaded: false
		}
	}

	componentDidMount() {}

	onShow = () => {
		this.props.onShow();

		this.setState({ loaded: true });
	}
	
	onHide = () => {
		this.props.onHide();

		this.setState({ loaded: false });
	}

	show = () => {
		const modal = this.modal.current;

		modal.show();
	}

	hide = () => {
		const modal = this.modal.current;

		modal.hide();
	}

	renderComments = () => {
		const { loaded } = this.state;

		if(!loaded) return <div>Loading...</div>;
		
		const { id } = this.props;
		const disqusShortname = 'tour-guide';
		const disqusConfig = {
			url: `${ Global.APP_URL }/mytours/#${ id }`,
			identifier: id,
		}

		return (
			<div className="disqus-thread">
				<Disqus.DiscussionEmbed shortname={ disqusShortname } config={ disqusConfig }/>
			</div>
		);
	}

	render() {
		const { id } = this.props;

		return (
			<Modal
				popup="nodeDetails" 
				onShow={ this.onShow }
				onHide={ this.onHide }
				data-node-id={ id }
				ref={ this.modal }>
				<div className="uk-modal-dialog">
					<div className="uk-modal-header">
						<h3 className="uk-modal-title">Tour Comments:</h3>
					</div>
					<div className="uk-modal-body">
						<div className="uk-modal-list">
							{ this.renderComments() }
						</div>
					</div>
					<div className="uk-modal-footer">
						<Link to="#closeModal" className="md-btn md-btn-flat md-btn-flat-default md-btn-wave waves-effect waves-button uk-modal-close">
							Close
						</Link>
					</div>
				</div>
			</Modal>
		);
	}
}

/* ----------  Prop Types  ---------- */

ModalComments.propTypes = {
	onShow: PropTypes.func.isRequired,
	onHide: PropTypes.func.isRequired,

	id: PropTypes.string.isRequired,
}

/* ----------  Exports  ---------- */

export default ModalComments;