/* ----------  Scripts  ---------- */

const RemoveContactsList = () => dispatch => (
	dispatch({
		type: 'MESSAGES_REMOVE_CONTACTS_LIST',
	})
);

/* ----------  Exports  ---------- */

export default RemoveContactsList;