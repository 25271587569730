/* ----------  Imports  ---------- */

// Lodash
// import { mapKeys } from 'lodash';

/* ----------  Scripts  ---------- */

const HYPERLINK_INITIAL_STATE = {};

const ReducerMyToursTreesHyperlink = (state = HYPERLINK_INITIAL_STATE, action) => {
	if(action.type === 'MYTOURS_TREES_GET_HYPERLINK') {
		return { ...state, ...action.payload };
	} else if(action.type === 'MYTOURS_TREES_REMOVE_HYPERLINK') {
		return HYPERLINK_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMyToursTreesHyperlink;