/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { map, isEmpty } from 'lodash';

// Disqus
import Disqus from 'disqus-react';

// Lity
import Lity from 'lity';

// jQuery
import $ from 'jquery';

// UUID
import uuid from 'uuid/v4';

// Tour Components
import MoreControls from './MoreControls';

// Category Label
import CategoryLabel from './../Categories/CategoryLabel';

// Contants
import Global from './../../Constants/Global';

// Preloader
import Preloader from './../Common/Preloader';

// Bootstrap
import Tab from './../Bootstrap/Tab';

// Utils
import Scrollbar from './../Utils/Scrollbar';
import SlickCarousel from './../Utils/SlickCarousel';

// Helpers
import Notify from './../../Helpers/Notify';

/* ----------  Scripts  ---------- */

class TourDetails extends React.Component {
	constructor(props) {
		super(props);

		this.imageSlick = React.createRef();
		this.youtubeSlick = React.createRef();

		this.mainTabs = React.createRef();
		this.mainTabContents = React.createRef();

		this.initialRatingFormState = {
			rating: 0,
			comment: '',
		}

		this.state = {
			rateBusy: false,
			ratingDisabled: true,
			ratingTabVisited: false,
			ratingForm: this.initialRatingFormState,

			youtubeLoaded: false,
		}
	}

	componentDidMount() {}

	onBack = e => {
		e.preventDefault();

		this.props.hide();
		this.reset();
	}

	onRelated = e => {
		e.preventDefault();

		this.showRelated();
	}

	onPlayTour = e => {
		e.preventDefault();

		const { tour } = this.props;

		this.playTour(tour.tourId);
	}

	onImgLoad = () => {
		const node = this.imageSlick.current;
		const slick = this.getSlick(node);

		if(slick.length) node.refresh(slick);
	}

	onRatingTabClick = () => {
		this.updateRating();
	}

	onMessageChange = e => {
		const { name, value } = e.currentTarget;

		this.props.updateConversationFormState({ [name]: value });
	}

	onStartConversation = e => {
		e.preventDefault();

		const { tour: { nodeId } } = this.props;

		this.props.updateConversationFormState({ nodeId }, this.props.startConversation);
	}

	getSlick = ref => {
		if(ref) return $(ref.slick.current);

		return [];
	}

	getImages = () => {
		const { tour: { images } } = this.props;

		const data = [];

		map(images.links, link => data.push(link));
		
		map(images.attachments, attachment => data.push(attachment.url));

		return data;
	}

	getRating = () => {
		const { tour } = this.props;

		this.setState({ rateBusy: true, ratingDisabled: true });

		this.props.getRating(tour.tourId, result => {
			const { comment, rating } = result;
			
			this.setState({ rateBusy: false, ratingDisabled: rating.userRating > 0 });

			this.updateRatingFormState({
				comment,
				rating: rating.userRating || 1,
			});
		}, () => {
			this.setState({ rateBusy: false });
		});
	}

	setRating = () => {
		const { tour } = this.props;
		const { ratingForm } = this.state;

		const data = {
			tourId: tour.tourId,
			rating: ratingForm.rating,
			comment: ratingForm.comment,
		}

		this.setState({ rateBusy: true, ratingDisabled: true });

		this.props.setRating(data, result => {
			this.setState({ rateBusy: false, ratingDisabled: true });
			this.updateRatingFormState({
				comment: result.comment,
				rating: result.rating.userRating,
			});

			Notify.success('Thank you for rating! :)');
		}, reasons => {
			this.setState({ rateBusy: false, ratingDisabled: false });
			map(reasons, reason => Notify.error(reason.message, 5000, 'bottom-right'));
		});
	}

	handleTabChange = (tab, target) => {
		switch(target) {
			case 'tabPictures': this.handleImageSlick(); break;
			case 'tabVideos': this.handleYoutubeSlick(); break;
			default: return false;
		}

		return true;
	}

	handleImageSlick = () => {
		const node = this.imageSlick.current;
		const slick = this.getSlick(node);

		if(slick.length) {
			node.refresh(slick);
			this.handleYoutubePlayer();
		}
	}

	handleYoutubeSlick = () => {
		const node = this.youtubeSlick.current;
		const slick = this.getSlick(node);
		
		if(slick.length && !this.state.youtubeLoaded) {
			this.setState({
				youtubeLoaded: true
			}, () => {
				node.refresh(slick);
			});
		}
	}

	handleYoutubePlayer = () => {
		const node = this.youtubeSlick.current;
		const slick = this.getSlick(node);

		if(!slick.length) return false;

		let command = {};
		
		const currentSlide = slick.find('.slick-current');
		const slideType = currentSlide ? currentSlide.attr('class').split(' ')[1] : '';
		const player = currentSlide.find('iframe').get(0);

		if(slideType === 'vimeo') {
			command = {
				'method': 'pause',
				'value': 'true'
			};
		} else {
			command = {
				'event': 'command',
				'func': 'pauseVideo'
			};
		}

		if(player !== undefined) {
			player.contentWindow.postMessage(JSON.stringify(command), '*');
		}

		return true;
	}

	handleMarker = e => {
		e.preventDefault();

		const { tour: { tourId, position } } = this.props;

		if(tourId) this.props.setTourActive(tourId);
		if(position.length) this.props.changeMapPosition(position);
	}

	handleRatingFormTxt = e => {
		const { name, value } = e.currentTarget;

		this.updateRatingFormState({ [name]: value });
	}

	playTour = (id, type) => {
		const { playTour } = this.props;

		if(playTour) playTour(id, type);
	}

	openLightbox = (e, image) => {
		e.preventDefault();

		Lity(image);
	}

	showRelated = () => {
		const { showRelated } = this.props;

		if(showRelated) showRelated(this.props.tour.tourId);
	}

	submitRating = e => {
		e.preventDefault();

		this.setRating();
	}

	updateRating = () => {
		const { ratingTabVisited } = this.state;

		if(!ratingTabVisited) {
			this.setState({ ratingTabVisited: true }, this.getRating);
		}
	}

	updateRatingFormState = (updates, callback) => {
		const { ratingForm } = this.state;

		this.setState({
			ratingForm: {
				...ratingForm,
				...updates
			}
		}, () => {
			if(callback) callback();
		});
	}

	reset = () => {
		this.setState({
			rateBusy: false,
			ratingDisabled: true,
			ratingTabVisited: false,
			ratingForm: this.initialRatingFormState,
		});
	}

	renderSecondaryAction = () => {
		const { related } = this.props;

		if(related) return <Link to="#tourDetails" className="atn-marker d-block" onClick={ this.handleMarker }><span className="global-marker"/></Link>;

		return (
			<Link to="#tourDetails" className="related atn-btn atn-related" onClick={ this.onRelated }>
				<span className="atn-inner">
					<span className="atn-icon"><span className="fas fa-link"/></span>
					<span className="atn-text">Related</span>
				</span>
			</Link>
		);
	}

	renderImageGallery = () => {
		const { tourId } = this.props.tour;
		const images = this.getImages();

		return map(images, (image, i) => (
			<div className="item-slick" key={ `${ tourId }_${ i }` }>
				<img src={ image } onLoad={ this.onImgLoad } className="img-responsive img-fullwidth" alt=""/>
				<Link to="#gallery" className="btn-lightbox btnLightbox galleryItem" target="_blank" onClick={ e => this.openLightbox(e, image) }>
					<i className="material-icons">zoom_out_map</i>
				</Link>
			</div>
		));
	}

	renderImageSlick = () => {
		const images = this.getImages();

		if(!images.length) {
			return (
				<div className="images-placeholder text-center p-10">
					<span className="text-danger">No images provided.</span>
				</div>
			);
		}

		return (
			<SlickCarousel className="gallery-carousel" slickType="tourDetails" data-lightbox-gallery ref={ this.imageSlick }>
				{ this.renderImageGallery() }
			</SlickCarousel>
		);
	}

	renderVideoGallery = () => {
		const { tourId, videos } = this.props.tour;

		return map(videos, (url, i) => {
			const parse = new URL(url);
			const videoId = parse.searchParams.get('v');

			return (
				<div className="item-slick" key={ `${ tourId }_video_${ i }` }>
					<div className="video-player">
						<iframe src={ `https://www.youtube.com/embed/${ videoId }?mode=opaque&rel=0&autohide=1&showinfo=0&wmode=transparent&enablejsapi=1` } title="Youtube Video" className="ytPlayer" data-id="ytPlayer_1" allowFullScreen/>
					</div>
				</div>
			);
		});
	}

	renderVideoSlick = () => {
		const { videos } = this.props.tour;

		if(!videos.length) {
			return (
				<div className="videos-placeholder text-center p-10">
					<span className="text-danger">No videos provided.</span>
				</div>
			);
		}

		return (
			<SlickCarousel className="gallery-carousel youtube-carousel youtube-slick" slickType="tourDetails" onBeforeChange={ this.handleYoutubePlayer } ref={ this.youtubeSlick }>
				{ this.renderVideoGallery() }
			</SlickCarousel>
		);
	}

	renderWebsitesList = () => {
		const { tourId, websites } = this.props.tour;

		if(!websites.length) return <p>N/A</p>;

		return map(websites, (website, i) => {
			const url = website.replace(/^(https?:|)\/\//, '//');

			return (
				<Link key={ `${ tourId }_${ i }` } to={ url } className="list-group-item" target="_blank">
					<span className="fas fa-globe fa-fw m-r-5"/> { website }
				</Link>
			);
		});
	}

	renderTagsList = () => {
		const { tourId, tags } = this.props.tour;

		if(!tags.length) return <p>N/A</p>;

		return map(tags, (tag, i) => <li key={ `${ tourId }_${ i }` }>{ tag }</li>);
	}

	renderComments = () => {
		const { tourId, title } = this.props.tour;
		const disqusShortname = 'tour-guide';
		const disqusConfig = {
			url: `${ window.location.origin }/tour/#${ tourId }`,
			identifier: tourId,
			title,
		}
		
		return (
			<div className="disqus-thread">
				{/* <p className="text-center"><em className="text-dark">Coming Soon</em></p> */}
				{/* <Disqus.CommentCount shortname={ disqusShortname } config={ disqusConfig }>
					<h3>Comments</h3>
				</Disqus.CommentCount> */}
				<Disqus.DiscussionEmbed shortname={ disqusShortname } config={ disqusConfig }/>
			</div>
		);
	}

	renderCategories = () => {
		const { categories } = this.props.tour;

		if(!categories.length) return <div className="d-none"/>;

		return map(categories, category => <CategoryLabel key={ uuid() } category={ category }/>);
	}

	renderRatingTabContent = () => {
		const { rateBusy, ratingDisabled, ratingForm } = this.state;

		return (
			<form className="rating-form" onSubmit={ this.submitRating }>
				<div className="form-group">
					<label htmlFor="txtUserRating">Rating:</label>
					<input type="number" className="form-control" id="txtUserRating" name="rating" min={ 1 } max={ 5 } value={ ratingForm.rating } onChange={ this.handleRatingFormTxt } disabled={ ratingDisabled }/>
				</div>
				<div className="form-group">
					<label htmlFor="txtRatingComment">Comment:</label>
					<textarea type="text" name="comment" className="form-control" id="txtRatingComment" value={ ratingForm.comment } placeholder="Enter Comment" onChange={ this.handleRatingFormTxt } disabled={ ratingDisabled }/>
				</div>
				<div className="form-action">
					<button className={ `btn btn-primary ${ rateBusy ? 'disabled' : '' } ${ ratingDisabled ? 'd-none' : '' }` }>
						<i className="material-icons">send</i> Submit Rating
					</button>
					<Preloader loading={ rateBusy } size={ 20 } relative minimal center/>
				</div>
			</form>
		);
	}

	render() {
		const { tour, language, authUserId } = this.props;
		const { conversationForm, startingConversation } = this.props;

		if(isEmpty(tour)) return <Preloader loading center/>;

		return (
			<div className="sidebar-wrapper">
				<div className={ `sidebar-content category-${ tour.category }` } data-id={ tour.tourId }>
					<div className="actions-container">
						<Link to="#tourDetails" className="back atn-btn atn-back" onClick={ this.onBack }>
							<span className="atn-inner">
								<span className="atn-icon"><span className="fas fa-chevron-left"/></span>
								<span className="atn-text">Search Results</span>
							</span>
						</Link>
						{ this.renderSecondaryAction() }
					</div>
					<Scrollbar className="content-sections">
						<div className="section-header">
							<div className="section-title">
								<h2>{ tour.title }</h2>
							</div>
							<div className="section-user">
								<h4>{ tour.user.name }</h4>
							</div>
							<div className="section-meta">
								<div className="section-lang">
									<p>{ language.name }</p>
								</div>
								<div className="section-tag">
									{ this.renderCategories() }
								</div>
							</div>
							<div className="section-atn-play">
								<Link to="#tourDetails" className="atn-tour atnTour" onClick={ this.onPlayTour }>
									<img src={ `${ Global.APP_URL }/assets/icons/map/flag-map-marker.svg` } className="img-responsive" alt=""/>
								</Link>
							</div>
							<MoreControls
								tourId={ tour.tourId }
								authUserId={ authUserId }
								userId={ tour.user ? tour.user.userId : '' }
								favorite={ tour.favorite }
								isSubscribed={ tour.isSubscribed }
								subscribe={ this.props.subscribe }
								unsubscribe={ this.props.unsubscribe }
								setFavorite={ this.props.setFavorite }
								setUnfavorite={ this.props.setUnfavorite }/>
						</div>
						<div className="sidebar-main app-tabs-container m-b-20">
							<ul className="nav nav-tabs nav-tabs-justified" ref={ this.mainTabs }>
								<Tab target="tabPictures" className="tab-about active" ripple="#ffdd835" tooltip="Videos" onClick={ this.handleTabChange }>
									<span className="item-icon fas fa-2x fa-images"/>
								</Tab>
								<Tab target="tabVideos" className={ `tab-tag` } ripple="#ffdd835" tooltip="Videos" onClick={ this.handleTabChange }>
									<span className="item-icon fas fa-2x fa-video"/>
								</Tab>
							</ul>
							<div className="tab-content" ref={ this.mainTabContents }>
								<div className="tab-pane tabPane active" id="tabPictures">
									<div className="gallery-wrapper">
										{ this.renderImageSlick() }
									</div>
								</div>
								<div className="tab-pane tabPane" id="tabVideos">
									<div className="gallery-wrapper yt-video-gallery">
										{ this.renderVideoSlick() }
									</div>
								</div>
							</div>
						</div>
						<div className="sidebar-content-container app-tabs-container">
							<div className="sidebar-tabs-container">
								<div className="tab-togglers tab-togglers-fixed">
									<ul className="nav nav-tabs nav-tabs-fixed">
										<Tab target="tabAbout" className="tab-about active" ripple="#000" tooltip="About">
											<span className="item-icon fas fa-info-circle"/>
										</Tab>
										<Tab target="tabWebsite" className="tab-link" ripple="#03a9f4" tooltip="Website">
											<span className="item-icon fas fa-globe"/>
										</Tab>
										<Tab target="tabComments" className="tab-comments" ripple="#03a9f4" tooltip="Comments">
											<span className="item-icon fas fa-comment"/>
										</Tab>
										<Tab target="tabChats" className="tab-chats" ripple="#ff9800" tooltip="Chats">
											<span className="item-icon fas fa-comments"/>
										</Tab>
										<Tab target="tabTag" className="tab-tag" ripple="#fdd835" tooltip="Features">
											<span className="item-icon fas fa-tags"/>
										</Tab>
										<Tab target="tabRating" className="tab-rating" ripple="#fdd835" tooltip="Features" onClick={ this.onRatingTabClick }>
											<span className="item-icon far fa-smile-beam"/>
										</Tab>
									</ul>
								</div>
								<div className="tab-content">
									<div className="tab-pane fade active in" id="tabAbout">
										<h3>About</h3>
										<div className="read-more">
											<p>{ tour.about || 'N/A' }</p>
										</div>
									</div>
									<div className="tab-pane fade" id="tabWebsite">
										<h3>Websites</h3>
										<div className="list-group m-0">
											{ this.renderWebsitesList() }
										</div>
									</div>
									<div className="tab-pane fade" id="tabComments">
										{ this.renderComments() }
									</div>
									<div className="tab-pane fade" id="tabChats">
										<form className="global-form form inputs-underline messageForm" onSubmit={ this.onStartConversation }>
											<div className="form-group hasValidation">
												<label htmlFor="txtChatMessage" className="form-label no-margin">Message Text <span className="text-danger">*</span></label>
												<textarea className="form-control" id="txtChatMessage" value={ conversationForm.message } name="message" onChange={ this.onMessageChange } disabled={  startingConversation }/>
											</div>
											<div className="form-atn clearfix">
												<button className={ `md-fab md-fab-success md-fab-wave-light waves-effect waves-button waves-light pull-right ${ isEmpty(conversationForm.message) || startingConversation ? 'disabled' : '' }` }>
													<i className="material-icons">&#xE163;</i>
												</button>
											</div>
										</form>
									</div>
									<div className="tab-pane fade" id="tabTag">
										<h3>Features</h3>
										<ul className="tags">
											{ this.renderTagsList() }
										</ul>
									</div>
									<div className="tab-pane fade" id="tabRating">
										{ this.renderRatingTabContent() }
									</div>
								</div>
							</div>
						</div>
					</Scrollbar>
				</div>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

TourDetails.defaultProps = {
	hide: false,
	playTour: false,
	showRelated: false,
	setTourActive: false,
	changeMapPosition: false,
	
	related: false,
	startingConversation: false,

	authUserId: '',

	tour: {},
	language: {},
	conversationForm: {},
}

TourDetails.propTypes = {
	hide: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	playTour: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	showRelated: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	setTourActive: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	changeMapPosition: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),

	subscribe: PropTypes.func.isRequired,
	unsubscribe: PropTypes.func.isRequired,
	
	startConversation: PropTypes.func.isRequired,
	updateConversationFormState: PropTypes.func.isRequired,

	setFavorite: PropTypes.func.isRequired,
	setUnfavorite: PropTypes.func.isRequired,

	getRating: PropTypes.func.isRequired,
	setRating: PropTypes.func.isRequired,

	authUserId: PropTypes.string,
	
	related: PropTypes.bool,
	startingConversation: PropTypes.bool,

	tour: PropTypes.shape(),
	language: PropTypes.shape(),
	conversationForm: PropTypes.shape(),
}

/* ----------  Exports  ---------- */

export default TourDetails;
