/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

/* ----------  Scripts  ---------- */

class ContactSearchForm extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			term: ''
		}
	}

	componentDidMount() {}

	onChange = e => {
		const val = e.currentTarget.value;

		this.setState({
			term: val
		}, () => this.props.updateSearch(this.state.term));
	}

	render() {
		return (
			<div className="search-wrapper md-elevation-1 row no-gutters align-items-center w-100 p-2">
				<i className="material-icons search-icon">search</i>
				<input className="col pl-2 search-input txtSearch" placeholder="Search or start new chat" type="text" value={ this.state.term } onChange={ this.onChange }/>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

ContactSearchForm.propTypes = {
	updateSearch: PropTypes.func.isRequired
}

/* ----------  Exports  ---------- */

export default ContactSearchForm;