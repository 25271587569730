/* ----------  Imports  ---------- */

// Lodash
import { mapKeys } from 'lodash';

/* ----------  Scripts  ---------- */

const TOURS_NODES_DATA_INITIAL_STATE = {};

const ReducerMyToursTreesToursNodesData = (state = TOURS_NODES_DATA_INITIAL_STATE, action) => {
	if(action.type === 'MYTOURS_TREES_GET_TOURS_NODES') {
		const response = mapKeys(action.payload.results, 'nodeId');
		return response;
	} else if(action.type === 'MYTOURS_TREES_REMOVE_TOURS_NODES') {
		return TOURS_NODES_DATA_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMyToursTreesToursNodesData;