/* ----------  Imports  ---------- */

// jQuery
import $ from 'jquery';

// UIkit
import UIkit from 'uikit';

/* ----------  Scripts  ---------- */

const Notify = {
	init: (message, status, timeout, position) => {
		$('[data-uk-notify-holder]').empty();

		UIkit.notify({
			message,
			status: status || 'info',
			timeout: timeout || '2000',
			pos: position || 'bottom-center'
		});
	},

	primary: (message, timeout, position) => {
		Notify.init(message, 'primary', timeout, position);
	},

	info: (message, timeout, position) => {
		Notify.init(message, 'info', timeout, position);
	},

	success: (message, timeout, position) => {
		Notify.init(message, 'success', timeout, position);
	},

	danger: (message, timeout, position) => {
		Notify.init(message, 'danger', timeout, position);
	},

	error: (message, timeout, position) => {
		Notify.init(message, 'danger', timeout, position);
	},

	getIcon: (type) => Notify.icons[type],

	icons: {
		primary: '<i class="material-icons text-white m-r-5">&#xE86C;</i>',
		info: '<i class="material-icons text-white m-r-5">&#xE88E;</i>',
		success: '<i class="material-icons text-white m-r-5">&#xE86C;</i>',
		danger: '<i class="material-icons text-white m-r-5">&#xE000;</i>'
	}
}

/* ----------  Exports  ---------- */

export default Notify;