/* ----------  Imports  ---------- */

// Axios
import axios from 'axios';

// UUID
import uuid from 'uuid/v4';

// Global
import Global from './../../../../Constants/Global';

/* ----------  Scripts  ---------- */

const SendMessage = (data, user, success, fail) => dispatch => {
	const url = `${ Global.API_BASE_URL }/conversation/message/send`;

	axios.post(url, data).then(response => {
		const { status } = response.data;

		if(success) success(status);
	}).catch(error => {
		const { response } = error;

		if(fail) {
			if(response) {
				fail(response.data.status, response.data.error.reasons);
			} else {
				fail(false, []);
			}
		}
	});

	dispatch({
		type: 'SEND_CONVERSATION_MESSAGE',
		payload: {
			messageId: uuid(),
			data,
			user
		},
	});
};

/* ----------  Exports  ---------- */

export default SendMessage;