/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Languages
import LanguageDropdown from './../../../Containers/Common/LanguageDropdown';

// Containers
import Cart from './../../../Containers/Common/Cart';
import Conversation from './../../../Containers/Common/Conversation';
import Subscriptions from './../../../Containers/Common/Subscriptions';
import UserDropdown from './../../../Containers/Admin/Auth/UserDropdown';

/* ----------  Scripts  ---------- */

class AdminHeader extends React.Component {
	constructor(props) {
		super(props);

		this.conversationsRef = React.createRef();
	}

	showConversations = (nodeId, nodeType) => {
		const conversationsRef = this.conversationsRef.current.wrappedInstance;

		if(conversationsRef) conversationsRef.show(nodeId, nodeType);
	}

	render() {
		return (
			<header id="header_main" data-fullheight-offset="true">
				<div className="header_main_content">
					<nav className="uk-navbar">
						<Link to="#div" id="sidebar_main_toggle" className="sSwitch sSwitch_left btn-sidebar-toggle">
							<span className="sSwitchIcon"/>
						</Link>
						<Link to="#div" id="sidebar_secondary_toggle" className="sSwitch sSwitch_right sidebar_secondary_check">
							<span className="sSwitchIcon"/>
						</Link>
						<div className="uk-navbar-flip">
							<ul className="uk-navbar-nav user_actions">
								<li><Link to="#div" id="main_search_btn" className="user_action_icon hide"><i className="material-icons md-24 md-light">&#xE8B6;</i></Link></li>
								<li>
									<Link to="#div" id="full_screen_toggle" className="user_action_icon uk-visible-large btn-toggle-fullscreen">
										<i className="material-icons md-24 md-light icon-static">&#xE5D0;</i>
										<i className="material-icons md-24 md-light icon-active">&#xE5D1;</i>
									</Link>
								</li>
								<Subscriptions/>
								<Conversation ref={ this.conversationsRef }/>
								<Cart/>
								<li className="language-changer">
									<LanguageDropdown id="globalLanguageDropdown" name="language" value="en"/>
								</li>
								<li data-uk-dropdown="{mode:'click',pos:'bottom-right'}">
									<UserDropdown/>
								</li>
							</ul>
						</div>
					</nav>
				</div>
			</header>
		);
	}
}

/* ----------  Exports  ---------- */

export default AdminHeader;
