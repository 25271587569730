/* ----------  Imports  ---------- */

// Axios
import axios from 'axios';

// Global
import Global from './../../../../../Constants/Global';

/* ----------  Scripts  ---------- */

const GetTourProfileDetails = (params, success, fail, doAction = true) => dispatch => {
	// const url = `${ Global.DUMMY_API_BASE_URL }/EySQsPeg8`;
	const url = `${ Global.API_BASE_URL }/tours/profile`;

	axios.get(url, { params }).then(response => {
		const { status, result } = response.data;
		
		if(doAction) {
			dispatch({
				type: 'MYTOURS_TREES_GET_TOUR_PROFILE_DETAILS',
				payload: result
			});
		}
		
		if(success) success(status, result);
	}).catch(error => {
		const { response } = error;

		if(fail) {
			if(response) {
				fail(response.data.status, response.data.error.reasons);
			} else {
				fail(false, []);
			}
		}
	});
};

/* ----------  Exports  ---------- */

export default GetTourProfileDetails;
