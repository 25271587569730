/* ----------  Imports  ---------- */

// Firebase
import firebase from 'firebase/app';
import 'firebase/messaging';

// Notifications
import notifications from '../EventEmitters/Notifications';

// Storage
import Storage from './Storage';

/* ----------  Scripts  ---------- */

const FirebaseService = {
	getToken: async (refresh) => {
		console.log('Firebase Service: GET TOKEN');

		const messaging = firebase.messaging();
		await messaging.requestPermission();

		if(refresh) await FirebaseService.deleteToken();

		const token = await messaging.getToken();

		Storage.setFcmToken(token);

		console.log('TOKEN: ', token);

		return token;
	},

	getPermissions: async (success, fail, always) => {
		try {
			console.log('Firebase Service: GET PERMISSIONS');
			const token = await FirebaseService.getToken();

			if(success) success(token);
			if(always) always(token);
			return token;
		} catch(err) {
			Storage.removeFcmToken();
			// throw err;
			if(fail) fail();
			if(always) always(null);
			return null;
		}
	},

	deleteToken: async () => {
		console.log('Firebase Service: DELETE TOKEN');

		const messaging = firebase.messaging();
		await messaging.requestPermission();

		const oldToken = Storage.getFcmToken();

		console.log('OLD: ', oldToken);

		if(oldToken) await messaging.deleteToken({ token: oldToken });
	},

	load: () => {
		console.log('Firebase Service: LOAD');

		if(firebase.apps && firebase.apps.length) return;

		const config = window.firebaseConfig;
		if(!config) return;

		try {
			firebase.initializeApp({
				messagingSenderId: config.messagingSenderId
			});
	
			const messaging = firebase.messaging();
	
			navigator.serviceWorker.register('/firebase-messaging-sw.js').then((registration) => {
				messaging.useServiceWorker(registration);
				
				messaging.onMessage(payload => {
					console.log('Message received. ', payload);
					notifications.emit('refreshCount', payload);
				});
	
				messaging.onTokenRefresh(async () => {
					const token = await FirebaseService.getToken();
					console.log('TOKEN UPDATED', token);
				});
			});
		} catch(err) {
			console.log('Firebase Error:', err.message);
		}
	},
}

/* ----------  Exports  ---------- */

export default FirebaseService;
