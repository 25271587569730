/* ----------  Imports  ---------- */

// Prop Types
import PropTypes from 'prop-types';

// Google Streetview
import GoogleStreetview from './../../Google/GoogleStreetview';

/* ----------  Scripts  ---------- */

class LegStreetview extends GoogleStreetview {
	componentDidMount() {
		this.load(() => {
			this.update();
			this.handleActions();
		});
	}

	handleActions = () => {
		this.streetview.addListener('position_changed', () => {
			this.update();
		});

		this.streetview.addListener('pov_changed', () => {
			this.update();
		});
	}

	update = () => {
		const center = this.getPosition();
		const pov = this.getPov();

		this.props.updatePosition(center, pov, () => {
			const { map } = this.props;
			const mapApi = map.current;
			
			if(mapApi) mapApi.setCenter(center);
		});
	}
}

/* ----------  Prop Types  ---------- */

LegStreetview.propTypes = {
	map: PropTypes.shape().isRequired,

	updatePosition: PropTypes.func.isRequired,
}

/* ----------  Exports  ---------- */

export default LegStreetview;
