/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types'

/* ----------  Scripts  ---------- */

class StarRating extends React.Component {
	componentDidMount() {}

	renderRating = () => {
		const ratings = [];
		const { rating: { average }, totalRatings } = this.props;
		const rates = Math.floor(average);
		const noRating = Math.abs(rates - totalRatings);

		for(let i = 0; i < rates; i += 1) {
			const key = `ratedStar_${ i }`;
			ratings.push(<li className="rate" key={ key }><span className="fas fa-star"/></li>);
		}

		for(let i = 0; i < noRating; i += 1) {
			const key = `unRatedStar_o_${ i }`;
			ratings.push(<li className="rate" key={ key }><span className="far fa-star"/></li>);
		}

		return ratings;
	}

	render() {
		const { rating } = this.props;

		return (
			<ul className="list-unstyled rating-list">
				{ this.renderRating() }
				<li className="rate-count">({ rating.total })</li>
			</ul>
		);
	}
}

/* ----------  Prop Types  ---------- */

StarRating.defaultProps = {
	totalRatings: 5,
	rating: {
		total: 0,
		average: 0,
		userRating: 0,
	}
}

StarRating.propTypes = {
	totalRatings: PropTypes.number,
	rating: PropTypes.shape({}),
}

/* ----------  Exports  ---------- */

export default StarRating;