/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Lodash
import { isEqual, map, startCase, merge } from 'lodash';

// Constants
import Global from './../Constants/Global';

// Layouts
import AdminLayout from './../Layouts/AdminLayout';

// Preloader
import Preloader from './../Components/Common/Preloader';

// Profile Edit
import ProfileEdit from './../Containers/Profile/ProfileEdit';

// Add Card
import AddDebitCard from './../Components/Profile/DebitCard/AddDebitCard';
import AddCreditCard from './../Components/Profile/CreditCard/AddCreditCard';

// UIkit Components
import Modal from './../Components/UIkit/Modal';
import Input from './../Components/UIkit/Input';
import Alerts from './../Components/UIkit/Alerts';

// Helpers
import Notify from '../Helpers/Notify';
import InputUI from './../Helpers/InputUI';
import InputHelper from './../Helpers/InputHelper';

/* ----------  Scripts  ---------- */

class ViewProfileEdit extends React.Component  {
	constructor(props) {
		super(props);

		this.passwordModal = React.createRef();
		this.passwordFormRef = React.createRef();
		
		this.addCreditCardModal = React.createRef();
		this.addCreditCardFormRef = React.createRef();
		
		this.addDebitCardModalRef = React.createRef();
		
		this.addAccountInfoModalRef = React.createRef();
		
		this.cntProfileEditRef = React.createRef();

		this.initialPasswordFormState = {
			oldPassword: '',
			password: '',
			passwordConfirmation: ''
		}

		this.staticAccountInfoFormFields = [{
			label: 'First Name',
			field: 'first_name'
		},{
			label: 'Last Name',
			field: 'last_name'
		},{
			label: 'Address (Line 1)',
			field: 'line1'
		},{
			label: 'Address (Line 2)',
			field: 'line2'
		},{
			label: 'Postal Code',
			field: 'postal_code'
		},{
			label: 'State',
			field: 'state'
		},{
			label: 'Phone',
			field: 'phone'
		},{
			label: 'City',
			field: 'city'
		}];

		this.initialAccountInfoFormState = {
			country: 'US'
		}

		this.state = {
			isValid: false,

			fields: {
				name: '',
				slogan: '',
				photo: '',
				about: '',
				email: '',
				phone: '',
				country: '',
				address: '',
				phoneAlt: '',

				social: {
					twitter: '',
					facebook: '',
					linkedin: '',
					googlePlus: '',
				},

				languages: {
					primary: '',
					secondary: []
				}
			},

			debitCardForm: {},
			addingDebitCard: false,
			debitCardFormFields: [],
			debitCardFormErrors: [],
			addingDebitCardForm: true,
			debitCardModalActive: false,

			accountInfoFormErrors: [],
			updatingAccountInfo: false,
			addingAccountInfoForm: true,
			accountInfoModalActive: false,
			accountInfoForm: this.initialAccountInfoFormState,
			accountInfoFormFields: this.staticAccountInfoFormFields,

			passwordFormErrors: [],
			updatingPassword: false,
			isPasswordFormValid: false,
			passwordForm: this.initialPasswordFormState,

			addingCreditCardForm: true,
			creditCardModalActive: false,
			addCreditCardFormErrors: [],
		}
	}

	componentWillMount() {
		document.title = `${ Global.APP_NAME } :: Profile Edit`;
	}

	onPasswordModalHide = () => {
		this.setState({
			passwordFormErrors: [],
			isPasswordFormValid: false,
			passwordForm: this.initialPasswordFormState,
		});
	}

	onAddCreditCardModalShow = () => {
		this.setState({ creditCardModalActive: true });
		
		setTimeout(() => {
			this.setState({ addingCreditCardForm: false });
		}, 1000);
	}

	onAddCreditCardModalHide = () => {
		this.setState({
			addingCreditCardForm: true,
			creditCardModalActive: false,
			addCreditCardFormErrors: [],
		});
	}
	
	onAddAccountInfoModalShow = () => {
		this.setState({
			addingAccountInfoForm: true,
			accountInfoModalActive: true,
		});
	}
	
	onAddAccountInfoModalHide = () => {
		this.setState({
			accountInfoFormErrors: [],
			updatingAccountInfo: false,
			accountInfoModalActive: false,
			accountInfoForm: this.initialAccountInfoFormState,
			accountInfoFormFields: this.staticAccountInfoFormFields,
		});
	}

	onAddDebitCardModalShow = () => {
		this.setState({
			addingDebitCardForm: true,
			debitCardModalActive: true,
		});
		
		setTimeout(() => {
			this.setState({ addingDebitCardForm: false });
		}, 1000);
	}
	
	onAddDebitCardModalHide = () => {
		this.setState({
			debitCardForm: {},
			debitCardFormErrors: [],
			debitCardModalActive: false,
		});
	}

	onAccountInfoInputChange = e => {
		const { name, value } = e.currentTarget;
		this.updateAccountInfoFormState({ [name]: value });
	}

	onUpdateAccountInfo = e => {
		e.preventDefault();
		this.updateAccountInfo();
	}

	getCountriesOptions = () => {
		if(!this.cntProfileEditRef.current) return [];
		
		const cntProfileEdit = this.cntProfileEditRef.current.wrappedInstance;
		return cntProfileEdit.getCountriesOptions();
	}
	
	getCurrencyOptions = () => {
		if(!this.cntProfileEditRef.current) return [];
		
		const cntProfileEdit = this.cntProfileEditRef.current.wrappedInstance;
		return cntProfileEdit.getCurrencyOptions();
	}

	addCreditCard = (token, success, fail) => {
		const modal = this.addCreditCardModal.current;
		const cntProfileEdit = this.cntProfileEditRef.current.wrappedInstance;

		cntProfileEdit.addCreditCard({ token }, () => {
			modal.hide();
			if(success) success();
		}, reasons => {
			const errors = map(reasons, reason => reason.message);
			if(fail) fail(errors);
		});
	}
	
	addDebitCard = (token, success, fail) => {
		const modal = this.addDebitCardModalRef.current;
		const cntProfileEdit = this.cntProfileEditRef.current.wrappedInstance;

		cntProfileEdit.addDebitCard({ token }, () => {
			modal.hide();
			if(success) success();
		}, reasons => {
			const errors = map(reasons, reason => reason.message);
			if(fail) fail(errors);
		});
	}

	handlePasswordModal = () => {
		const modal = this.passwordModal.current;
		const form = this.passwordFormRef.current;

		InputUI.update(form);

		modal.show();
	}

	handleAddCreditCard = () => {
		const modal = this.addCreditCardModal.current;
		modal.show();
	}

	handleAddDebitCard = () => {
		const modal = this.addDebitCardModalRef.current;
		modal.show();
	}

	handleAddAccountInfo = () => {
		if(!this.cntProfileEditRef.current) return false;

		const cntProfileEdit = this.cntProfileEditRef.current.wrappedInstance;
		const modal = this.addAccountInfoModalRef.current;
		modal.show();

		this.setState({ addingAccountInfoForm: true });

		cntProfileEdit.getAccountInfoFields((status, result) => {
			const { fields, accountInfoForm, accountInfoFormFields } = this.state;

			const formFields = [...accountInfoFormFields, ...result];
			const form = {...accountInfoForm};
			
			cntProfileEdit.getAccountInfo((aiStatus, aiResult) => {
				const info = aiResult ? merge(aiResult, aiResult.address, aiResult.dob) : {};

				map(formFields, item => {
					form[item.field] = info[item.field] || fields[item.field] || '';
				});
				
				this.setState({
					accountInfoForm: form,
					addingAccountInfoForm: false,
					accountInfoFormFields: formFields,
				});
			});
		});

		return true;
	}

	handlePasswordChange = e => {
		const { passwordForm } = this.state;
		const { name, value } = e.currentTarget;

		this.setState({
			passwordForm: {
				...passwordForm,
				[name]: value
			}
		});
	}

	saveProfile = e => {
		e.preventDefault();

		const cntProfileEdit = this.cntProfileEditRef.current.wrappedInstance;
		cntProfileEdit.updateProfile();
	}

	updateFormState = (updates, callback) => {
		const { fields } = this.state;

		this.setState({
			fields: {
				...fields,
				...updates,
			}
		}, () => {
			if(callback) callback();
		});
	}

	updateAccountInfoFormState = (updates, callback) => {
		const { accountInfoForm } = this.state;

		this.setState({
			accountInfoForm: {
				...accountInfoForm,
				...updates
			}
		}, () => {
			if(callback) callback();
		});
	}

	updateAccountInfo = () => {
		const isValid = this.validateAccountInfoForm();

		if(isValid) {
			const { accountInfoForm } = this.state;
			const cntProfileEdit = this.cntProfileEditRef.current.wrappedInstance;
			const modal = this.addAccountInfoModalRef.current;

			this.setState({ updatingAccountInfo: true });
			
			cntProfileEdit.saveAccountInfo(accountInfoForm, () => {
				modal.hide();
				Notify.success('Account info updated successfully!');
				this.setState({ updatingAccountInfo: false });
			}, (status, reasons) => {
				const errors = map(reasons, reason => reason.message);

				this.setState({
					updatingAccountInfo: false,
					accountInfoFormErrors: errors
				});
			});
		}
	}

	updatePassword = e => {
		e.preventDefault();

		const isValid = this.validatePasswordForm();

		if(isValid) {
			const { passwordForm } = this.state;
			const modal = this.passwordModal.current;
			const cntProfileEdit = this.cntProfileEditRef.current.wrappedInstance;

			this.setState({ updatingPassword: true });

			cntProfileEdit.updatePassword(passwordForm, () => {
				this.setState({ updatingPassword: false });
				modal.hide();
			}, reasons => {
				const errors = map(reasons, reason => reason.message);

				this.setState({
					updatingPassword: false,
					passwordFormErrors: errors,
					isPasswordFormValid: false,
				});
			});
		}
	}

	validatePasswordForm = () => {
		const errors = [];
		let isValid = false;
		const { passwordForm: { oldPassword, password, passwordConfirmation } } = this.state;

		if(InputHelper.isEmpty(oldPassword) || InputHelper.isEmpty(password) || InputHelper.isEmpty(passwordConfirmation)) {
			isValid = false;
			errors.push('Passwords cannot be blank.');
		} else if(password.length < 6) {
			isValid = false;
			errors.push('Password should be at least 6 characters long.');
		} else if(!isEqual(password, passwordConfirmation)) {
			isValid = false;
			errors.push('Password do not match.');
		} else if(isEqual(oldPassword, password, passwordConfirmation)) {
			isValid = false;
			errors.push('New password cannot be equal to existing password.');
		} else {
			isValid = true;
		}

		this.setState({
			passwordFormErrors: errors,
			isPasswordFormValid: isValid,
		});

		return isValid;
	}

	validateAccountInfoForm = () => {
		const errors = [];
		let isValid = true;
		const { accountInfoForm } = this.state;

		map(accountInfoForm, (value, key) => {
			if(!value) {
				isValid = false;
				errors.push(`${ startCase(key) } cannot be blank.`);
			}
		});

		this.setState({ accountInfoFormErrors: errors });

		return isValid;
	}

	renderCreditCardForm = () => {
		const { fields, addingCreditCardForm, creditCardModalActive } = this.state;

		if(addingCreditCardForm) return <div className="uk-modal-dialog"><Preloader loading center/></div>;

		return (
			<AddCreditCard
				data={ fields }
				active={ creditCardModalActive }
				addCreditCard={ this.addCreditCard }
				countries={ this.getCountriesOptions() }/>
		);
	}

	renderAddAccountInfoFormItems = () => {
		const { accountInfoModalActive, accountInfoFormFields, accountInfoForm } = this.state;

		if(!accountInfoModalActive) return <div className="d-none"/>;

		return map(accountInfoFormFields, item => {
			const { label, field } = item;

			const id = `txt_dc_${ field }`;
			const value = accountInfoForm[field];

			return (
				<div className="uk-width-1-2" key={ id }>
					<div className="md-form-group">
						<label htmlFor={ id }>{ label }</label>
						<input type="text" name={ field } id={ id } value={ value } placeholder={ label } onChange={ this.onAccountInfoInputChange }/>
					</div>
				</div>
			);
		});
	}
	
	renderAccountInfoForm = () => {
		const { addingAccountInfoForm, accountInfoFormErrors, updatingAccountInfo } = this.state;

		if(addingAccountInfoForm) return <Preloader loading center/>;

		return (
			<form className="stripe-form" onSubmit={ this.onUpdateAccountInfo } ref={ this.formRef }>
				<div className="uk-modal-header">
					<h3 className="uk-modal-title">Update Account Info</h3>
				</div>
				<div className="uk-modal-body">
					<div className="uk-grid">
						{ this.renderAddAccountInfoFormItems() }
					</div>
					<Alerts show={ accountInfoFormErrors.length > 0 } data={ accountInfoFormErrors }/>
				</div>
				<div className="uk-modal-footer">
					<div className="footer-preloader m-r-10">
						<Preloader size={ 20 } loading={ updatingAccountInfo } relative minimal/>
					</div>
					<Link to="#modal" className="md-btn md-btn-flat md-btn-flat-default md-btn-wave waves-effect waves-button uk-modal-close" disabled={ updatingAccountInfo }>Cancel</Link>
					<button type="submit" className="md-btn md-btn-flat md-btn-flat-success uk-position-relative" disabled={ updatingAccountInfo } onClick={ this.onUpdateAccountInfo }>
						Save
					</button>
				</div>
			</form>
		);
	}

	renderDebitCardForm = () => {
		const { fields, addingDebitCardForm, debitCardModalActive, debitCardForm, debitCardFormFields } = this.state;

		if(addingDebitCardForm) return <Preloader loading center/>;

		return (
			<AddDebitCard
				data={ fields }
				form={ debitCardForm }
				fields={ debitCardFormFields }
				active={ debitCardModalActive }
				addDebitCard={ this.addDebitCard }
				currencies={ this.getCurrencyOptions() }
				countries={ this.getCountriesOptions() }/>
		);
	}

	render() {
		const { fields, isValid } = this.state;
		const { passwordForm, updatingPassword, passwordFormErrors } = this.state;

		return (
			<AdminLayout view="profile-edit">
				<form encType="multipart/form-data" action="" className="uk-form-stacked user-edit-form" id="user_edit_form" name="user_edit_form" onSubmit={ this.saveProfile } noValidate>
					<div className="uk-grid" data-uk-grid-margin="">
						<div className="uk-width-large-7-10">
							<ProfileEdit 
								data={ fields }
								isValid={ isValid }
								ref={ this.cntProfileEditRef }
								updateFormState={ this.updateFormState }
								handleAddDebitCard={ this.handleAddDebitCard }
								handleAddCreditCard={ this.handleAddCreditCard }
								handlePasswordModal={ this.handlePasswordModal }
								handleAddAccountInfo={ this.handleAddAccountInfo }/>
						</div>
						{/* <div className="uk-width-large-3-10 hidden-print">
							<div className="md-card">
								<div className="md-card-content">
									<h3 className="heading_c uk-margin-bottom">Alerts</h3>
									<ul className="md-list md-list-addon">
										<li>
											<div className="md-list-addon-element">
												<i className="md-list-addon-icon material-icons uk-text-warning">&#xE8B2;</i>
											</div>
											<div className="md-list-content">
												<span className="md-list-heading">Voluptatem occaecati.</span> <span className="uk-text-small uk-text-muted uk-text-truncate">Voluptas quae voluptatem ipsam modi dolores voluptate.</span>
											</div>
										</li>
										<li>
											<div className="md-list-addon-element">
												<i className="md-list-addon-icon material-icons uk-text-success">&#xE88F;</i>
											</div>
											<div className="md-list-content">
												<span className="md-list-heading">Itaque perferendis.</span> <span className="uk-text-small uk-text-muted uk-text-truncate">Asperiores corporis maxime est omnis deserunt quidem dolor.</span>
											</div>
										</li>
										<li>
											<div className="md-list-addon-element">
												<i className="md-list-addon-icon material-icons uk-text-danger">&#xE001;</i>
											</div>
											<div className="md-list-content">
												<span className="md-list-heading">Repellat ut aut.</span> <span className="uk-text-small uk-text-muted uk-text-truncate">Alias excepturi voluptatem itaque illo magni.</span>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div> */}
					</div>
				</form>
				<div className="modals-container" data-modals-holder>
					<Modal
						popup="password" 
						bgclose={ false }
						keyboardClose={ false }
						onHide={ this.onPasswordModalHide }
						ref={ this.passwordModal }>
						<form ref={ this.passwordFormRef }>
							<div className="uk-modal-dialog">
								<div className="uk-modal-header">
									<h3 className="uk-modal-title">Change Password</h3>
								</div>
								<div className="uk-modal-body">
									<Input
										title="Old Password"
										type="password"
										className="md-input"
										value={ passwordForm.oldPassword }
										name="oldPassword"
										id="txtOldPassword"
										onChange={ this.handlePasswordChange }/>
									<Input
										title="New Password"
										type="password"
										className="md-input"
										value={ passwordForm.password }
										name="password"
										id="txtPassword"
										onChange={ this.handlePasswordChange }/>
									<Input
										title="Re-enter Password"
										type="password"
										className="md-input"
										value={ passwordForm.passwordConfirmation }
										name="passwordConfirmation"
										id="txtPasswordConfirmation"
										onChange={ this.handlePasswordChange }/>
									
									<Alerts show={ passwordFormErrors.length > 0 } data={ passwordFormErrors }/>
								</div>
								<div className="uk-modal-footer">
									<div className="footer-preloader m-r-10">
										<Preloader size={ 20 } loading={ updatingPassword } relative minimal/>
									</div>
									<Link to="#modal" className="md-btn md-btn-flat md-btn-flat-default md-btn-wave waves-effect waves-button uk-modal-close" disabled={ updatingPassword }>Cancel</Link>
									<button type="submit" className="md-btn md-btn-flat md-btn-flat-success uk-position-relative" disabled={ updatingPassword || false } onClick={ this.updatePassword }>
										Save
									</button>
								</div>
							</div>
						</form>
					</Modal>

					<Modal
						bgclose={ false }
						popup="addCreditCard" 
						keyboardClose={ false }
						onShow={ this.onAddCreditCardModalShow }
						onHide={ this.onAddCreditCardModalHide }
						ref={ this.addCreditCardModal }>
						{ this.renderCreditCardForm() }
					</Modal>

					<Modal
						bgclose={ false }
						popup="addAccountInfo" 
						keyboardClose={ false }
						ref={ this.addAccountInfoModalRef }
						onShow={ this.onAddAccountInfoModalShow }
						onHide={ this.onAddAccountInfoModalHide }>
						<div className="uk-modal-dialog">
							{ this.renderAccountInfoForm() }
						</div>
					</Modal>

					<Modal
						bgclose={ false }
						popup="addDebitCard" 
						keyboardClose={ false }
						ref={ this.addDebitCardModalRef }
						onShow={ this.onAddDebitCardModalShow }
						onHide={ this.onAddDebitCardModalHide }>
						<div className="uk-modal-dialog">
							{ this.renderDebitCardForm() }
						</div>
					</Modal>
				</div>
			</AdminLayout>
		);
	}
};

/* ----------  Exports  ---------- */

export default ViewProfileEdit;
