/* ----------  Imports  ---------- */

// Local Storage
import Storage from './../../../Services/Storage';

/* ----------  Scripts  ---------- */

const USER_TOKEN_INITIAL_STATE = '';

const ReducerUserToken = (state = USER_TOKEN_INITIAL_STATE, action) => {
	if(action.type === 'USER_TOKEN_UPDATE') {
		const token = action.payload;
		Storage.setToken(token);
		return token;
	} else if(action.type === 'USER_TOKEN_SYNC') {
		const token = action.payload;
		Storage.setToken(token);
		return token;
	} else if(action.type === 'USER_TOKEN_REMOVE') {
		Storage.removeToken();
		return USER_TOKEN_INITIAL_STATE;
	}

	return state;
}

/* ----------  Exports  ---------- */

export default ReducerUserToken;