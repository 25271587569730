/* ----------  Imports  ---------- */

// React
import React, { Fragment } from 'react';

// React Redux
import { connect } from 'react-redux';

// Redux
import { bindActionCreators } from 'redux';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { debounce, map, filter, isEmpty, get, orderBy, first, indexOf, sum } from 'lodash';

// UUID
import uuid from 'uuid/v4';

// Constants
import Utils from '../../Helpers/Utils';
import Velocity from '../../Constants/Velocity';

// Preloader
import Preloader from './../../Components/Common/Preloader';

// Helpers
import Notify from './../../Helpers/Notify';

// Player Actions
// import GetLegs from './../../Redux/Actions/ToursPanel/GetLegs';
import RemovePanelData from './../../Redux/Actions/ToursPanel/RemovePanelData';
import GetTourMixer from '../../Redux/Actions/MyTours/Trees/TourMixer/GetTourMixer';
import SaveTourMixer from '../../Redux/Actions/MyTours/Trees/TourMixer/SaveTourMixer';
import UpdateMusicVolume from '../../Redux/Actions/MyTours/Trees/TourMixer/UpdateMusicVolume';
import UpdateAudioVolume from '../../Redux/Actions/MyTours/Trees/TourMixer/UpdateAudioVolume';

// Tour Playback Actions
import GetLegStatus from '../../Redux/Actions/TourPlayback/GetLegStatus';
import GetTourSummary from '../../Redux/Actions/TourPlayback/GetTourSummary';
import GetPlaybackLegs from '../../Redux/Actions/TourPlayback/GetPlaybackLegs';
import GetPlaybackDetails from '../../Redux/Actions/TourPlayback/GetPlaybackDetails';
import RemoveTourSummary from '../../Redux/Actions/TourPlayback/RemoveTourSummary';
import RemovePlaybackDetails from '../../Redux/Actions/TourPlayback/RemovePlaybackDetails';
import RemovePlaybackLegs from '../../Redux/Actions/TourPlayback/RemovePlaybackLegs';

// Tour Panel Components
import LegPlayer from './../../Components/ToursPanel/LegPlayer';
import LegVideoPlayer from '../../Components/ToursPanel/LegVideoPlayer';

// jQuery
const $ = window.$;

/* ----------  Scripts  ---------- */

class ToursPlayer extends React.Component {
	constructor(props) {
		super(props);

		this.isLoaded = false;

		this.legPlayerRef = React.createRef();
		this.legVideoPlayerRef = React.createRef();
		
		this.startTime = null;
		this.endTime = null;

		this.playbackFrequency = 100;

		this.playbackDetailsState = {
			legIds: [],
			legs: {},
			tour: {},
			music: [],
			media: {},
			musicSequence: {},
			images: [],
			header: {},
			footer: {}
		}

		this.initialDataState = {
			leg: {},
			index: 0,
			tour: {},
			tId: null,
			playTime: 0,
			progress: {},
			hierarchy: [],
			isAudio: false,
			totalFrames: 0,
			isCursor: false,
		}

		this.initialVideoPlayerState = {
			mediaId: null,
			active: false,
			loaded: false,
			paused: false,
			playing: false,
			autoplay: false,
			lAutoplay: false,
			type: 'header',
		}
		
		this.initialLegPlayerState = {
			active: false,
			loaded: false,
		}

		this.initialState = {
			pov: {},
			legIds: [],
			markers: [],
			position: [],
			tourId: null,
			nodeType: null,

			fromIndex: -1,
			toIndex: -1,

			startTime: -1,
			endTime: -1,
			
			imageFromIndex: -1,
			imageToIndex: -1,
			
			musicFromIndex: -1,
			musicToIndex: -1,

			active: false,
			loaded: false,
			type: 'playback',
			autoplay: false,
			playerLoaded: false,
			data: this.initialDataState,

			legPlayer: this.initialLegPlayerState,
			videoPlayer: this.initialVideoPlayerState,
		}

		this.state = this.initialState;
		
		// this.requestLegs = debounce(this.doRequestLegs, 100);
		this.requestTourSummary = debounce(this.doRequestTourSummary, 100);
		this.requestPlaybackDetails = debounce(this.doRequestPlaybackDetails, 100);
		this.requestPlaybackLegs = debounce(this.doRequestPlaybackLegs, 100);
		
		this.getTourMixer = debounce(this.doRequestTourMixer, 100);
		this.saveTourMixer = debounce(this.doSaveTourMixer, 100);
	}

	onClose = e => {
		e.preventDefault();
		e.stopPropagation();

		this.disablePanel();
	}

	onLegStart = legIndex => {
		const legPlayer = this.legPlayerRef.current;
		const { fromIndex, toIndex } = this.state;

		if(fromIndex < 0) {
			this.updatePlayerState({ busy: true });
			return;
		}

		if(legPlayer && ((legIndex < fromIndex) || (legIndex > toIndex))) {
			legPlayer.reset();
			this.disablePanel();
			this.updatePlayerState({ busy: false });
		} else {
			this.updatePlayerState({ busy: true });
		}
	}

	onLegPause = () => {
		this.updatePlayerState({ busy: true });
	}

	onLegComplete = () => {}
	
	onAllLegsComplete = () => {
		const { layout } = this.props;
		const { tourId, fromIndex, data } = this.state;

		if(layout === 'homepage') {
			this.updateDataState({
				tour: {
					...data.tour,
					progress: {},
				}
			});
		}

		if(this.isMediaMixer() || (fromIndex >= 0)) {
			this.disablePanel();
			return;
		}

		if(tourId) {
			const { footer } = this.playerData();

			if(!isEmpty(footer) && footer.mediaId) {
				this.hideLegPlayer(() => {
					const actions = this.videoPlayerActions();
					if(actions.play) actions.play();
				});
			} else {
				this.stop();
			}
		} else {
			this.reset();
		}
	}

	onLegStop = () => {
		this.stop();
	}

	onLegPlayInitialized = () => {
		const { footer } = this.playerData();
		if(!isEmpty(footer) && footer.mediaId) {
			this.loadFooterVideo(false);
		}
	}

	getPlayerData = (tourId, legIds) => {
		const { type, nodeType } = this.state;

		this.removeToursPanelData();
		this.removeTourPlaybackData();

		if(nodeType === 50) {
			if(type === 'playback') this.getPlaybackDetails(tourId);
			this.getTourSummary(tourId);
		} else if(nodeType === 60) {
			const legId = legIds;
			this.props.getLegStatus({ legId }, (status, result) => {
				const legStatus = result.status.toString().toLowerCase();
				const isCompleted = legStatus === 'completed';

				if(!isCompleted) {
					this.disablePanel(true, () => Notify.error('Your leg is not yet ready.'));
					return;
				}

				this.getPlaybackLegs(legId, tourId, () => {
					this.loadLegPlayer(false);
				});
			}, () => {
				this.disablePanel(true, () => Notify.error('Your leg is not yet ready.'));
			});
		}
	}

	getTourSummary = tourId => {
		this.requestTourSummary(tourId);
	}
	
	getPlaybackDetails = (tourId, success, fail) => {
		this.requestPlaybackDetails(tourId, success, fail);
	}
	
	getPlaybackLegs = (legIds, tourId, success, fail) => {
		this.requestPlaybackLegs(legIds, tourId, success, fail);
	}

	getInitialPlaybackLegs = (success, fail) => {
		const { summary: { legIds, legs, tourId } } = this.playerData();
		const totalFrames = this.getTotalFrames();

		let ids = legIds;
		
		const minRequiredFrames = 200;
		let frames = 0;

		if((legs.length > 1) && (totalFrames > minRequiredFrames)) {
			ids = [];
			for(let i = 0; i < legs.length; i += 1) {
				if(frames <= minRequiredFrames) {
					ids.push(legs[i].legId);
				} else {
					break;
				}

				frames += legs[i].frames;
			}
		}

		this.getPlaybackLegs(ids, tourId, success, fail);
	}

	getRemainingTourPlaybackLegs = (success, fail) => {
		const { type } = this.state;
		const { summary, legs } = this.playerData();

		if((type === 'mixer') || isEmpty(summary)) return;

		if(legs.length !== summary.legIds.length) {
			const existingLegIds = legs.map((leg) => leg.legId);
			const legIds = [];
			
			map(summary.legIds, legId => {
				if(indexOf(existingLegIds, legId) < 0) {
					legIds.push(legId);
				}
			});

			this.getPlaybackLegs(legIds, summary.tourId, success, fail);
		}
	}

	getTotalDuration = () => {
		const { summary } = this.playerData();
		return summary.totalFrames * this.playbackFrequency;
	}

	getLegFrames = leg => {
		const legFrames = leg.recording.totalRecords;
				
		let framesViaAudio = 0;
		if(!isEmpty(leg.audio) && !isEmpty(leg.audio.duration)) {
			framesViaAudio = Math.flloor(leg.audio.duration / 100);
		}

		return framesViaAudio > legFrames ? framesViaAudio : legFrames;
	}

	getTotalFrames = () => {
		const { nodeType } = this.state;
		if(nodeType === 60) {
			const { legs } = this.playerData();
			return sum(map(legs, leg => this.getLegFrames(leg)));
		}

		const { summary } = this.playerData();
		return summary.totalFrames;
	}

	getInitialPosition = () => {
		const { position, pov, type, data: { tour } } = this.state;
		
		if(type === 'record') {
			return {
				position,
				pov
			}
		}
		
		const { legs } = this.playerData();
		const leg = first(legs);
		if(!leg) return {};

		const { recording } = leg;
		const initial = recording.initial || recording.initialPosition;

		if(tour.progress && !isEmpty(tour.progress)) {
			const { progress } = tour;

			const legIndex = progress.legIndex || 0;
			const recordKey = progress.recordKey || 0;

			const activeLeg = legs[legIndex];

			if(isEmpty(activeLeg)) return initial;

			const record = activeLeg.recording.records[recordKey];

			if(!isEmpty(record)) {
				return {
					position: record.position,
					pov: record.pov
				}
			}
			
			return initial;
		}

		return initial;
	}

	getMarkers = () => {
		const markers = [];
		const { legsMeta } = this.playerData();
		const { data } = this.state;
		const legs = legsMeta;

		if(legs.length) {
			let total = 0;

			map(legs, (leg, i) => {
				const frames = leg.frames;
				const playTime = leg.frames * this.playbackFrequency;

				const marker = {
					index: i,
					records: frames,
					legId: leg.legId,
					active: i === data.index,
					playTime,
					position: i === 0 ? 0 : (total * 100) / data.playTime,
				}

				markers.push(marker);
				total += playTime;
			});
		}

		return markers;
	}

	getGeoThumbnailUrl = (legIndex, recordIndex) => {
		const { legs } = this.playerData();
		const leg = legs[legIndex];
		if(!leg) return false;

		const { recording: { records } } = leg;
		const record = records[recordIndex];
		if(!record) return false;

		return Utils.getGeoThumbnailUrl(record.position, record.pov);
	}

	setProgress = progress => {
		const { tourId } = this.state;
		const { setProgress } = this.props;

		const data = {
			tourId,
			progress,
		}

		if(setProgress) setProgress(data);
	}

	// doRequestLegs = (legIds, tourId) => {
	// 	if(!this.isLoaded) return false;

	// 	const ids = Array.isArray(legIds) ? join(legIds) : legIds;

	// 	const data = {
	// 		legIds: ids
	// 	}

	// 	if(tourId) data.tourId = tourId;

	// 	this.props.getLegs(data, () => {
	// 		if(this.isLoaded) {
	// 			this.startTime = null;
	// 			this.endTime = null;

	// 			this.loadLegPlayer(false);
	// 		}
	// 	}, () => {
	// 		if(this.isLoaded) {
	// 			Notify.error('Something went wrong. Please try again :(');
	// 			this.disablePanel();
	// 		}
	// 	});

	// 	return true;
	// }

	doRequestTourSummary = (tourId, success, fail) => {
		const params = {
			tourId
		}
		
		const { nodeType, type } = this.state;

		this.props.getTourSummary(params, (status, response) => {
			const { layout } = this.props;
			const { hierarchy, title, progress, legIds } = response;

			if(!legIds.length) {
				Notify.error('Please add some legs first!');
				this.disablePanel();
				return;
			}

			this.setState({ tourId });

			this.updateDataState({
				hierarchy: nodeType === 50 ? hierarchy : [],
				tour: {
					title,
					progress: layout === 'homepage' ? progress : null
				},
			}, () => {
				if(type === 'playback') {
					this.getInitialPlaybackLegs(() => {
						this.playTour();
						if(success) success(response);
					});
				} else {
					this.getTourMixer(tourId, success);
				}
			});
		}, fail);
	}
	
	doRequestPlaybackDetails = (tourId, success, fail) => {
		const params = {
			tourId
		}

		this.props.getPlaybackDetails(params, (status, response) => {
			if(success) success(response);
		}, fail);
	}
	
	doRequestPlaybackLegs = (legIds, tourId, success, fail) => {
		const params = {
			tourId,
			legIds: Array.isArray(legIds) ? legIds.join(',') : legIds
		}

		this.props.getPlaybackLegs(params, (status, response) => {
			if(success) success(response);
		}, fail);
	}

	doRequestTourMixer = (tourId, success, fail) => {
		const params = {
			tourId,
			details: true
		}

		this.props.getTourMixer(params, () => {
			const { legs } = this.playerData();

			if(this.isLoaded) {
				if(legs.length) {
					this.playTour();
				} else {
					Notify.error('Please add some legs first.');
					this.disablePanel();
				}
			}

			if(success) success();
		}, () => {
			if(this.isLoaded) {
				Notify.error('Something went wrong. Please try again :(');
				this.disablePanel();
			}

			if(fail) fail();
		}, 'TOURSPANEL_GET_MIXER');
	}

	doSaveTourMixer = () => {
		const { legs, music, musicSequence, images, header, footer } = this.playerData();
		const { tourId } = this.state;

		const updatedLegs = map(legs, leg => {
			const obj = {
				legId: leg.legId,
				audio: leg.audio,
			}

			if(leg.group) obj.group = leg.group;
			return obj;
		});

		const data = {
			tourId,
			
			music,
			images,
			header,
			footer,
			musicSequence,

			legs: updatedLegs,
			legIds: map(legs, 'legId'),
		}

		this.props.saveTourMixer(data);
	}

	checkCursors = legIndex => {
		let cursors = [];
		const { legs } = this.playerData();
		const leg = legs[legIndex];
		if(!leg) return false;

		const { recording: { records } } = leg;
		cursors = [...cursors, ...filter(records, record => record.mouse.isVisible)];

		return cursors.length > 0;
	}

	clearTimer = () => {}

	enablePanel = (hardReset = true) => {
		const $panel = $('[data-graphic-panel]').first();

		if($panel.is(':visible')) {
			this.disablePanel(hardReset);
		}
		
		this.showOverlay();

		this.isLoaded = true;
		if(this.isLoaded) {
			this.setState({ active: true }, () => {
				this.updatePlayerState({ type: 'default', active: true, busy: true });

				$panel.velocity('fadeIn', {
					duration: Velocity.DURATION,
					easing: Velocity.EASING,
					
					complete: () => {
						setTimeout(() => {
							if($panel.is(':visible')) this.setState({ playerLoaded: true });
						}, 3000);
					}
				});
			});
		}
	}

	disablePanel = (hardReset = true, callback) => {
		const $panel = $('[data-graphic-panel]');

		if(!$panel.is(':visible')) return;

		this.hideOverlay();

		$panel.velocity('fadeOut', {
			duration: Velocity.DURATION,
			easing: Velocity.EASING,

			complete: () => {
				if(this.isLoaded) {
					if(hardReset) {
						this.setState(this.initialState);
					} else {
						this.setState({
							loaded: false,
							active: false,
						});
					}
				}
				
				this.removeToursPanelData();
				this.clearTimer();
				this.updatePlayerState({
					active: false,
					busy: false,
					render: false,
				});
				
				this.isLoaded = false;

				if(callback) callback();
			}
		});
	}

	initializePlay = (tourId, legIds, nodeType, srcType, autoplay = true, fromIndex = -1, toIndex = -1, type = 'playback', options = {}) => {		
		toIndex = toIndex > 0 ? toIndex : fromIndex;

		if(!tourId && (!legIds || !legIds.length)) {
			this.disablePanel();
			Notify.error('Invalid data found.');
			return false;
		}

		nodeType = srcType || nodeType;

		this.setState({
			type,
			fromIndex,
			toIndex,
			nodeType,

			startTime: options.startTime >= 0 ? options.startTime : -1,
			endTime: options.endTime || -1,
			
			imageFromIndex: options.imageFromIndex || -1,
			imageToIndex: options.imageToIndex || options.imageFromIndex || -1,
			
			musicFromIndex: options.musicFromIndex || -1,
			musicToIndex: options.musicToIndex || options.musicFromIndex || -1,

			autoplay: autoplay || false,
		}, () => {
			this.enablePanel(false);
			this.getPlayerData(tourId, legIds);
		});

		return true;
	}

	initializeRecord = data => {
		this.enablePanel(false);
		
		if(this.isLoaded) {
			const { position, pov, title, hierarchy } = data;

			this.setState({
				pov,
				position,

				loaded: true,
				nodeType: 60,
				type: 'record',
			}, () => {
				this.showLegPlayer();
				this.updateDataState({
					hierarchy,
					leg: {
						title,
					}
				});
			});
		}

		this.updatePlayerState({ busy: true });
	}

	isMediaMixer = () => {
		const { type } = this.state;
		return (type === 'imageMixer') || (type === 'musicMixer');
	}

	play = (legIndex, recordIndex = 0) => {
		const legPlayer = this.legPlayerRef.current;
		if(legPlayer) {
			legPlayer.startPlay(recordIndex, legIndex, true, () => {
				const { startTime } = this.state;
				if(legPlayer && (startTime >= 0)) legPlayer.seekPlayer(0, startTime);
			});
		}
	}

	playTour = () => {
		const { header } = this.playerData();
		const { autoplay, fromIndex, toIndex, startTime } = this.state;

		if(this.isMediaMixer() || (startTime >= 0) || (isEmpty(header) || isEmpty(header.mediaId))) {
			this.loadLegPlayer(autoplay);
			return;
		}

		if((fromIndex >= 0) && (toIndex >= 0)) {
			this.loadLegPlayer(autoplay);
		} else {
			this.loadHeaderVideo(autoplay);
		}
	}

	load = (mediaId = null, vAutoplay = false, lAutoplay = true, playLeg = true) =>  {
		if(!this.isLoaded) return;
		const { header } = this.playerData();

		this.setState({ loaded: true }, () => {
			if(mediaId) {
				this.loadVideoPlayer(mediaId, vAutoplay, lAutoplay);
			} else if(playLeg) {
				lAutoplay = !isEmpty(header);
				this.loadLegPlayer(lAutoplay); 
			} else {
				this.stop();
			}
		});
	}

	loadHeaderVideo = (autoplay = false) => {
		this.updateVideoPlayerState({ type: 'header' }, () => {
			const { header } = this.playerData();
			this.load(header.mediaId, autoplay);
		});
	}
	
	loadFooterVideo = (autoplay = true) => {
		this.updateVideoPlayerState({ type: 'footer' }, () => {
			const { footer } = this.playerData();
			this.load(footer.mediaId, autoplay, false, false);
		});
	}

	loadVideoPlayer = (mediaId, autoplay = false, lAutoplay = true) => {
		if(!mediaId) {
			this.stop();
			return;
		}

		const { media } = this.playerData();
		const mediaItem = get(media, mediaId);

		if(!mediaItem) {
			this.hideVideoPlayer(() => this.loadLegPlayer(false));
			return;
		}

		this.showVideoPlayer(null, { mediaId, autoplay, lAutoplay });
	}

	loadLegPlayer = (immediate = true, callback) => {
		this.updateVideoPlayerState({ active: false });
		this.showLegPlayer(() => {
			this.prepareData(0, () => {
				const { fromIndex } = this.state;
				const legPlayer = this.legPlayerRef.current;

				const legIndex = fromIndex >= 0 ? fromIndex : 0;
				const recordIndex = 0;
	
				if(legPlayer) legPlayer.onToursPlayerLoad();
				if(immediate) this.play(legIndex, recordIndex);

				if(callback) callback();
			}, 'LOAD');
		});
	}

	startPlay = (index, callback) => this.prepareData(index, callback, 'START')

	pause = () => {
		// this.clearTimer();
	}

	prepareData = (index = 0, callback, via = 'INITIAL') => {
		if(!this.isLoaded) return false;

		const { nodeType } = this.state;

		const { legs } = this.playerData();
		
		if(legs.length) {
			const leg = legs[index];
			const isCursor = this.checkCursors(index);
			const totalFrames = this.getTotalFrames(leg);
			const totalDuration = totalFrames * this.playbackFrequency;

			const data = {
				tId: uuid(),
				leg,
				index,
				isCursor,
				totalFrames,
				playTime: totalDuration,
			}

			if(nodeType === 60 ) {
				data.hierarchy = leg.hierarchy;
			}

			this.setState({
				loaded: true,
				position: leg.position,
			}, () => {
				this.updateDataState(data, () => {
					console.log('PREPARE DATA', { index, data });
					if(callback) callback(index, via);
				});
			});
		}

		return true;
	}

	replay = () => {
		const { fromIndex } = this.state;
		const { header } = this.playerData();

		if((fromIndex >= 0) || isEmpty(header)) {
			this.reset(true, this.loadLegPlayer);
		} else {
			this.loadHeaderVideo(true);
		}
	}

	reset = (soft = false, callback = null) => {
		const legPlayer = this.legPlayerRef.current;
		if(!legPlayer) return;

		legPlayer.reset(() => {
			if(callback) {
				callback();
			} else {
				legPlayer.updateInitialPosition();
				this.startPlay(0);
			}
		}, soft);
	}

	stop = () => {
		const { fromIndex } = this.state;

		if(this.isMediaMixer() || (fromIndex >= 0)) {
			this.reset(true);
			return;
		}

		const { header } = this.playerData();
		if(header && header.mediaId) {
			this.hideLegPlayer(() => {
				this.updateVideoPlayerState(this.initialVideoPlayerState, this.loadHeaderVideo);
			});
		} else {
			this.loadLegPlayer(false, () => {
				this.reset(true);
			});
			// this.reset(true);
		}
	}

	removeToursPanelData = () => {
		this.props.removePanelData();
	}
	
	removeTourSummary = () => {
		this.props.removeTourSummary();
	}
	
	removePlaybackDetails = () => {
		this.props.removePlaybackDetails();
	}
	
	removePlaybackLegs = () => {
		this.props.removePlaybackLegs();
	}

	removeTourPlaybackData = () => {
		this.removeTourSummary();
		this.removePlaybackDetails();
		this.removePlaybackLegs();
	}

	saveRecord = (data, status = true) => {
		this.updatePlayerState({ busy: true });

		const { updateLegDetails, saveLeg } = this.props;

		if(updateLegDetails && saveLeg) {
			updateLegDetails({
				audio: data.audio,
				recording: data.recording,
			}, leg => {
				console.log(leg);
				if(status && leg) saveLeg();
			});
		}
	}

	showLegPlayer = callback => {
		this.setState({ loaded: true });
		this.updateLegPlayerState({ loaded: true }, () => {
			const $player = $('[data-player="leg"]');

			if($player.is(':visible')) {
				this.updateLegPlayerState({ active: true }, callback);
				return;
			}

			$player.velocity({
				opacity: 1,
			}, {
				display: 'flex',
				duration: Velocity.DURATION,
				easing: Velocity.EASING,

				complete: () => {
					this.updateLegPlayerState({ active: true }, callback);
				}
			});
		});
	}

	hideLegPlayer = callback => {
		const $player = $('[data-player="leg"]');

		if(!$player.is(':visible')) {
			this.updateLegPlayerState({ active: false, loaded: false }, callback);
			return;
		}

		$player.velocity('fadeOut', {
			duration: Velocity.DURATION,
			easing: Velocity.EASING,

			complete: () => {
				this.updateLegPlayerState({ active: false, loaded: false }, callback);
			}
		});
	}

	showVideoPlayer = (callback, state = {}) => {
		this.updateVideoPlayerState({ active: true, ...state }, () => {
			const $player = $('[data-player="video"]');
			
			if($player.is(':visible')) {
				if(callback) callback();
				return;
			}

			$player.velocity('fadeIn', {
				duration: Velocity.DURATION,
				easing: Velocity.EASING,

				complete: () => {
					if(callback) callback();
				}
			})
		});
	}

	hideVideoPlayer = (callback, state = {}) => {
		const $player = $('[data-player="video"]');

		if(!$player.is(':visible')) {
			this.updateVideoPlayerState({ active: false, ...state }, callback);
			return;
		}

		$player.velocity('fadeOut', {
			duration: Velocity.DURATION,
			easing: Velocity.EASING,

			complete: () => {
				this.updateVideoPlayerState({ active: false, ...state }, callback);
			}
		})
	}

	videoPlayerActions = () => {
		const player = this.legVideoPlayerRef.current;
		
		return {
			play: () => {
				if(player) player.play();
			},

			pause: () => {
				if(player) player.pause();
			},
		}
	}

	videoPlayerCallbacks = {
		onReady: () => {
			this.updateVideoPlayerState({ loaded: true }, () => {
				const { videoPlayer } = this.state;

				if(videoPlayer.type === 'header') this.loadLegPlayer(false);
				if(videoPlayer.autoplay) this.videoPlayerActions().play();
			});
		},

		onPlay: () => {
			this.updateVideoPlayerState({ playing: true, paused: false });
		},
		
		onPause: () => {
			this.updateVideoPlayerState({ playing: false, paused: true });
		},
		
		onEnded: () => {
			const { videoPlayer } = this.state;
			this.hideVideoPlayer(() => {
				if(videoPlayer.lAutoplay) {
					this.play(0);
				} else {
					this.stop();
				}
			}, this.initialVideoPlayerState);
		}
	}

	showOverlay = () => {
		const { showOverlay } = this.props;
		if(showOverlay) showOverlay();
	}
	
	hideOverlay = () => {
		const { hideOverlay } = this.props;
		if(hideOverlay) hideOverlay();
	}

	playerData = () => {
		let legs = [];
		let music = [];
		let media = {};
		let musicSequence = {};
		let images = [];
		let header = {};
		let footer = {};
		
		const { type } = this.state;
		const { tourPlayback, toursPanel } = this.props;
		
		const summary = tourPlayback.summary;

		switch(type) {
			case 'playback':
				legs = tourPlayback.legs;
				music = orderBy(tourPlayback.details.music, 'startTime', 'asc');
				media = tourPlayback.details.media;
				musicSequence = tourPlayback.details.musicSequence;
				images = orderBy(tourPlayback.details.images, 'startTime', 'asc');
				header = tourPlayback.details.header;
				footer = tourPlayback.details.footer;
				break;
			default:
				legs = toursPanel.legs;
				music = orderBy(toursPanel.music, 'startTime', 'asc');
				media = toursPanel.media;
				musicSequence = toursPanel.musicSequence;
				images = orderBy(toursPanel.images, 'startTime', 'asc');
				header = toursPanel.header;
				footer = toursPanel.footer;
				break;
		}

		const legsMeta = [];

		if(!isEmpty(summary) && !isEmpty(summary.legs)) {
			map(summary.legs, leg => {
				const item = {
					legId: leg.legId,
					frames: leg.frames,
				};

				legsMeta.push(item);
			});
		} else {
			map(legs, leg => {
				const item = {
					legId: leg.legId,
					frames: this.getLegFrames(leg),
				};

				legsMeta.push(item);
			});
		}

		return { summary, legs, music, media, musicSequence, images, header, footer, legsMeta };
	}

	updateMusicVolume = (musicId, volume) => {
		const data = {
			musicId,
			volume
		}

		this.props.updateMusicVolume(data, this.saveTourMixer);
	}
	
	updateAudioVolume = (legIndex, volume) => {
		const { legs } = this.playerData();
		const leg = legs[legIndex];

		const data = {
			legId: leg ? leg.legId : null,
			volume
		}

		if(leg) this.props.updateAudioVolume(data, this.saveTourMixer);
	}

	updatePlayerState = updates => {
		const { updatePlayerState } = this.props;
		if(updatePlayerState) updatePlayerState(updates);
	}

	updateDataState = (updates, callback) => {
		const { data } = this.state;

		this.setState({
			data: {
				...data,
				...updates,
			}
		}, () => {
			if(callback) callback();
		});
	}
	
	updateLegPlayerState = (updates, callback) => {
		const { legPlayer } = this.state;

		this.setState({
			legPlayer: {
				...legPlayer,
				...updates,
			}
		}, callback);
	}

	updateVideoPlayerState = (updates, callback) => {
		const { videoPlayer } = this.state;

		this.setState({
			videoPlayer: {
				...videoPlayer,
				...updates,
			}
		}, callback);
	}

	renderVideoPlayer = () => {
		const { videoPlayer, type } = this.state;
		if(!videoPlayer.mediaId) return '';

		const { layout } = this.props;
		const { media } = this.playerData();
		const mediaItem = get(media, videoPlayer.mediaId);
		const duration = media.playTime / 1000;

		return (
			<LegVideoPlayer
				layout={ layout }
				src={ mediaItem.src }
				playbackType={ type }
				duration={ duration }
				type={ mediaItem.type }
				playerType={ videoPlayer.type }
				hidePanel={ this.disablePanel }
				ref={ this.legVideoPlayerRef }
				callbacks={ this.videoPlayerCallbacks }/>
		);
	}

	renderLegPlayer = () => {
		const { layout, audioDevice, token } = this.props;
		const { legs, media, music, images, musicSequence } = this.playerData();
		const { nodeType, type, position, active, data, fromIndex, toIndex, legPlayer, startTime, endTime } = this.state;

		if(!legPlayer.loaded) return '';

		return (
			<LegPlayer
				type={ type }
				token={ token }
				active={ active }
				layout={ layout }
				nodeType={ nodeType }
				
				legs={ legs }
				leg={ data.leg }
				tour={ data.tour }
				
				pause={ this.pause }
				replay={ this.replay }
				
				media={ media }
				music={ music }
				images={ images }
				
				position={ position }
				onLoad={ this.onLoad }
				
				startTime={ startTime }
				endTime={ endTime }
				
				fromIndex={ fromIndex }
				toIndex={ toIndex }

				totalLegs={ legs.length }
				isCursor={ data.isCursor }
				activeLegIndex={ data.index }

				playTime={ data.playTime }
				totalFrames={ this.getTotalFrames() }

				audioDevice={ audioDevice }
				startPlay={ this.startPlay }
				hierarchy={ data.hierarchy }
				markers={ this.getMarkers() }
				saveRecord={ this.saveRecord }
				hidePanel={ this.disablePanel }
				setProgress={ this.setProgress }
				musicSequence={ musicSequence }
				
				onLegStop={ this.onLegStop }
				onLegStart={ this.onLegStart }
				onLegPause={ this.onLegPause }
				onLegComplete={ this.onLegComplete }
				onAllLegsComplete={ this.onAllLegsComplete }
				
				updateMusicVolume={ this.updateMusicVolume }
				updateAudioVolume={ this.updateAudioVolume }
				
				initialPosition={ this.getInitialPosition() }
				getGeoThumbnailUrl={ this.getGeoThumbnailUrl }
				onLegPlayInitialized={ this.onLegPlayInitialized }
				
				getRemainingTourPlaybackLegs={ this.getRemainingTourPlaybackLegs }

				ref={ this.legPlayerRef }
			/>
		);
	}

	renderPlayer = () => {
		const { active, loaded } = this.state;
		
		if(!active || !loaded) return <Preloader loading={ active } center/>;

		return (
			<Fragment>
				{ this.renderVideoPlayer() }
				{ this.renderLegPlayer() }
				{/* { this.renderVideoPlayer('footer') } */}
			</Fragment>
		);
	}

	renderMiniLayout = () => {
		const { active, playerLoaded } = this.state;
		const loading = active && !playerLoaded;

		const styles = {
			display: 'none'
		}

		return (
			<Fragment>
				<Preloader className="bg-white" loading={ loading } center/>
				<div className="md-card-content p-0" style={ styles } data-graphic-panel>
					<Link to="#graphicPanel" className="btn-close btnClose" onClick={ this.onClose }>
						<i className="material-icons">close</i>
						<span className="btn-text">Close</span>
					</Link>
					{ this.renderPlayer() }
				</div>
			</Fragment>
		);
	}

	renderDefaultLayout = () => {
		const { active, playerLoaded } = this.state;
		const loading = active && !playerLoaded;

		const styles = {
			display: 'none'
		}

		return (
			<Fragment>
				<Preloader className="bg-white" loading={ loading } center/>
				<div className="md-card-content p-0" style={ styles } data-graphic-panel>
					<Link to="#graphicPanel" className="btn-close btnClose" onClick={ this.onClose }>
						<i className="material-icons">close</i>
						<span className="btn-text">Close</span>
					</Link>
					{ this.renderPlayer() }
				</div>
			</Fragment>
		);
	}

	renderHomepageLayout = () => {
		const { loaded, active } = this.state;

		if(!active || !loaded) {
			return (
				<div className={ `streetview stv-preloader ${ active ? 'active' : 'inactive' }` } id="streetview">
					<Preloader loading={ active } center/>
				</div>
			);
		}

		return this.renderPlayer();
	}
	
	render() {
		const { layout } = this.props;

		switch(layout) {
			case 'mini': return this.renderMiniLayout();
			case 'default': return this.renderDefaultLayout();
			case 'homepage': return this.renderHomepageLayout();
			default: return <div className="d-none"/>;
		}
	}
}

/* ----------  Prop Types  ---------- */

ToursPlayer.defaultProps = {
	// legs: [],
	// legsMusic: [],
	// legsMedia: {},
	// legsImages: [],
	// legsMusicSequence: {},
	// legsHeader: {
	// 	headerId: uuid(),
	//	mediaId: '5d888a08015606000804de7c'
	// },
	// legsFooter: {},

	tourPlayback: {
		legs: [],
		summary: {},
		details: {},
	},

	toursPanel: {},

	saveLeg: false,
	
	updateLegDetails: false,
	updatePlayerState: false,
	
	token: '',
	layout: 'default',
	
	audioDevice: false,

	showOverlay: false,
	hideOverlay: false,
	
	setProgress: false,
}

ToursPlayer.propTypes = {
	// legs: PropTypes.arrayOf(PropTypes.object),
	// legsHeader: PropTypes.shape(),
	// legsFooter: PropTypes.shape(),
	// legsMusic: PropTypes.arrayOf(PropTypes.object),
	// legsMedia: PropTypes.objectOf(PropTypes.object),
	// legsImages: PropTypes.arrayOf(PropTypes.object),
	// legsMusicSequence: PropTypes.objectOf(PropTypes.object),

	tourPlayback: PropTypes.shape({
		legs: PropTypes.arrayOf(PropTypes.object),
		summary: PropTypes.shape(),
		details: PropTypes.shape(),
	}),
	
	toursPanel: PropTypes.shape(),

	// getLegs: PropTypes.func.isRequired,
	removePanelData: PropTypes.func.isRequired,
	
	getTourSummary: PropTypes.func.isRequired,
	getPlaybackDetails: PropTypes.func.isRequired,
	getPlaybackLegs: PropTypes.func.isRequired,
	removeTourSummary: PropTypes.func.isRequired,
	removePlaybackDetails: PropTypes.func.isRequired,
	removePlaybackLegs: PropTypes.func.isRequired,
	
	getLegStatus: PropTypes.func.isRequired,
	
	getTourMixer: PropTypes.func.isRequired,
	saveTourMixer: PropTypes.func.isRequired,
	
	updateMusicVolume: PropTypes.func.isRequired,
	updateAudioVolume: PropTypes.func.isRequired,
	
	token: PropTypes.string,
	layout: PropTypes.string,
	
	audioDevice: PropTypes.bool,

	saveLeg: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	updateLegDetails: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	updatePlayerState: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	
	showOverlay: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	hideOverlay: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	
	setProgress: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
}

/* ----------  Redux Scripts  ---------- */

const mapStateToProps = state => ({
	tourPlayback: {
		legs: state.tourPlayback.legs,
		details: state.tourPlayback.details,
		summary: state.tourPlayback.summary,
	},
	
	toursPanel: state.toursPanel,

	token: state.user.auth.token,
	// legs: state.toursPanel.legs,
	// legsMedia: state.toursPanel.media,
	// legsHeader: state.toursPanel.header,
	// legsFooter: state.toursPanel.footer,
	// legsMusicSequence: state.toursPanel.musicSequence,
	// legsMusic: orderBy(state.toursPanel.music, 'startTime', 'asc'),
	// legsImages: orderBy(state.toursPanel.images, 'startTime', 'asc'),
});

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		// getLegs: GetLegs,
		removePanelData: RemovePanelData,

		getTourSummary: GetTourSummary,
		getPlaybackDetails: GetPlaybackDetails,
		getPlaybackLegs: GetPlaybackLegs,

		getLegStatus: GetLegStatus,
		
		removeTourSummary: RemoveTourSummary,
		removePlaybackDetails: RemovePlaybackDetails,
		removePlaybackLegs: RemovePlaybackLegs,

		getTourMixer: GetTourMixer,
		saveTourMixer: SaveTourMixer,

		updateMusicVolume: UpdateMusicVolume,
		updateAudioVolume: UpdateAudioVolume
	}, dispatch)
);

/* ----------  Exports  ---------- */

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(ToursPlayer);
