/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
// import { isEmpty } from 'lodash';

// Toggle
import Toggle from './../../Utils/Toggle';

/* ----------  Scripts  ---------- */

class FilterItem extends React.Component {
	constructor(props) {
		super(props);

		this.itemRef = React.createRef();
	}

	onClick = e => {
		const { onClick } = this.props;

		if(onClick) onClick(e);
	}

	onChange = (value, checked) => {
		const { onChange } = this.props;

		if(onChange) onChange(value, checked);
	}

	onChecked = checked => {
		const { onChecked } = this.props;

		if(onChecked) onChecked(checked);
	}

	renderIcon = () => {
		const { icon, iconClass } = this.props;

		if(!icon) return <span className="d-none"/>;

		return (
			<span className="item-icon">
				<i className={ `material-icons ${ iconClass }` }>{ icon }</i>
			</span>
		);
	}

	renderToggle = () => {
		const { id, toggle } = this.props;

		if(!toggle) return <span className="d-none"/>;

		return (
			<span className="item-toggle">
				<Toggle
					id={ id }
					type={ toggle.type }
					name={ toggle.name }
					value={ toggle.value }
					checked={ toggle.checked }
					onChange={ this.onChange }
					onChecked={ this.onChecked }
					indeterminate={ toggle.indeterminate || false }/>
			</span>
		);
	}

	render() {
		const { label, id, divider, licensed } = this.props;

		return (
			<div className="filter-item-outer">
				<div className={ `filter-item ${ licensed ? 'filter-item-licensed' : '' }` } ref={ this.itemRef }>
					<label htmlFor={ id } onClick={ this.onClick } role="presentation" tabIndex="-1">
						{ this.renderIcon() }
						{ this.renderToggle() }
						<span className="item-text">{ label }</span>
					</label>
				</div>
				<div className={ `item-divider ${ divider ? 'd-block' : 'd-none' }` }/>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

FilterItem.defaultProps = {
	onClick: false,
	onChange: false,
	onChecked: false,
	
	icon: '',
	iconClass: '',
	
	divider: false,
	licensed: false,
}

FilterItem.propTypes = {
	id: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	
	divider: PropTypes.bool,
	licensed: PropTypes.bool,
	
	icon: PropTypes.string,
	iconClass: PropTypes.string,

	toggle: PropTypes.oneOfType([PropTypes.bool, PropTypes.shape()]).isRequired,
	
	onClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	onChecked: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
}

/* ----------  Exports  ---------- */

export default FilterItem;