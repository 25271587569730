/* ----------  Imports  ---------- */

// Lodash
import { mapKeys, filter } from 'lodash';

/* ----------  Scripts  ---------- */

const TOURS_FILTERS_NODES_DATA_INITIAL_STATE = {};

const ReducerMyToursTreesToursFilterNodesData = (state = TOURS_FILTERS_NODES_DATA_INITIAL_STATE, action) => {
	if(action.type === 'MYTOURS_TREES_FILTERS_GET_TOURS_NODES') {
		const folders = filter(action.payload.results, ['nodeType', 20]);
		const response = mapKeys(folders, 'nodeId');
		
		return { ...state, ...response };
	} else if(action.type === 'MYTOURS_TREES_FILTERS_REMOVE_TOURS_NODES') {
		return TOURS_FILTERS_NODES_DATA_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMyToursTreesToursFilterNodesData;