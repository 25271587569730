/* ----------  Scripts  ---------- */

const RemoveTourProfileDetails = () => dispatch => (
	dispatch({
		type: 'MYTOURS_TREES_REMOVE_TOUR_PROFILE_DETAILS',
	})
);

/* ----------  Exports  ---------- */

export default RemoveTourProfileDetails;