/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { get, join, filter } from 'lodash';

// Preloader
import Preloader from './../../Common/Preloader';

// Utils
import Scrollbar from '../../Utils/Scrollbar';

// Tree
import ActionPanelTree from './../Tree/ActionPanelTree';

// iCheck
// import Toggle from './../../Utils/Toggle';

/* ----------  Scripts  ---------- */

class ActionPanel extends React.Component {
	constructor(props) {
		super(props);

		this.treeRef = React.createRef();

		this.state = {
			tree: '',
			action: '',
			nodeType: 0,
			nodeId: null,
			destTree: '',
			loading: true,
			parentNode: '',
			parentNodeId: '',
			parentNodeType: 0,
		}
	}

	componentWillMount() {
		this.getNodes();
	}

	getNodes = () => {
		const { tree, getNodes, excludeNodeTypes } = this.props;

		if(getNodes) {
			const params = {
				recursive: true,
				excludeNodeTypes: join(excludeNodeTypes, ','),
			};

			this.setState({ loading: true });

			getNodes(tree, params, () => {
				this.setState({ loading: false });
			}, () => {
				this.setState({ loading: false });
			});
		}
	}

	getValidNodes = () => {
		const { nodes, nodeId } = this.props;
		const validNodes = filter(nodes, node => nodeId !== node.nodeId && nodeId !== node.parentNodeId);

		return validNodes;
	}

	getPanelTitle = () => {
		const { type } = this.props;

		switch(type) {
			case 'copy': return 'Copy To:';
			case 'move': return 'Move To:';
			case 'publish': return 'Publish To:';
			default: return 'Copy To:';
		}
	}

	handleNode = (id, parentNode) => {
		const { tree, type, nodeId, nodeType, nodesData } = this.props;
		const node = get(nodesData, id);

		this.setState({
			tree,
			nodeId,
			nodeType,
			parentNode,

			parentNodeId: node ? node.nodeId : null,
			parentNodeType: node ? node.nodeType : null,
			action: type,
		});
	}

	hidePanel = e => {
		if(e) {
			e.preventDefault();
			e.stopPropagation();
		}

		this.props.hidePanel();
	}

	savePanel = e => {
		e.preventDefault();
		e.stopPropagation();

		this.save();
	}

	save = () => {
		const { moveNode, copyNode, publishNodes } = this.props;
		const { nodeId, nodeType, parentNodeId, parentNodeType, tree, action, parentNode } = this.state;

		if(!nodeId) {
			this.hidePanel();
			return false;
		}

		const data = {
			nodeId,
			nodeType,
			parentNodeId,
			parentNodeType,
			position: 0
		}

		if(action === 'move') {
			if(moveNode) moveNode(data, tree, parentNode);
		} else if(action === 'copy') {
			if(copyNode) copyNode(data, tree, parentNode);
		} else if(action === 'publish') {
			if(publishNodes) publishNodes(data, tree, parentNode);
		}
		
		this.hidePanel();

		return true;
	}

	removeNodes = () => {
		this.props.removeNodes();
	}

	renderTree = () => {
		const { active, allowed } = this.props;
		const { loading } = this.state;

		if(!active || loading) return <Preloader loading={ loading } center minimal/>;

		const { nodesData } = this.props;

		const validNodes = this.getValidNodes();

		return (
			<ActionPanelTree
				id="legsTree"
				type="actionPanel"
				allowed={ allowed }
				nodes={ validNodes }
				ref={ this.treeRef }
				prefix="actionPanel_"
				nodesData={ nodesData }
				className="action-panel-tree"
				handleNode={ this.handleNode }
				removeNodes={ this.removeNodes }/>
		);
	}

	render() {
		const { parentNodeId, loading } = this.state;
		const { refId, tree, active, type } = this.props;

		return (
			<div className={ `tour-panel tour-action-panel panel-type-${ type } ${ active ? 'panel-active' : '' }` } id={ `${ tree }_actionPanel` } data-ref-id={ refId } data-panel data-panel-tree={ tree }>
				<div className="panel-inner">
					<div className="panel-title">
						<h3 className="panelTitle">
							{ this.getPanelTitle() }
						</h3>
					</div>
					<div className="panel-list uk-position-relative panelList p-h-0">
						<Scrollbar className="list-inner">
							{ this.renderTree() }
						</Scrollbar>
					</div>
					<div className="panel-actions">
						<ul>
							<li className={ `atn-cancel ${ loading ? 'disabled' : '' }` }><Link to="#atp" className="btnClose" onClick={ this.hidePanel }>Close</Link></li>
							<li className={ `atn-save ${ !parentNodeId || loading ? 'disabled' : '' }` }><Link to="#atp" className="btnSave" onClick={ this.savePanel }>Save</Link></li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

ActionPanel.defaultProps = {
	getNodes: false,
	moveNode: false,
	copyNode: false,
	publishNodes: false,
	excludeNodeTypes: [],

	nodes: [],
	nodesData: {},
	
	allowed: [],
}

ActionPanel.propTypes = {
	hidePanel: PropTypes.func.isRequired,
	removeNodes: PropTypes.func.isRequired,

	nodes: PropTypes.arrayOf(PropTypes.object),
	nodesData: PropTypes.objectOf(PropTypes.object),
	
	getNodes: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	moveNode: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	copyNode: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	publishNodes: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),

	excludeNodeTypes: PropTypes.arrayOf(PropTypes.number),
	
	// src: PropTypes.shape().isRequired,
	
	refId: PropTypes.string.isRequired,
	tree: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
	nodeId: PropTypes.string.isRequired,
	nodeType: PropTypes.number.isRequired,
	
	allowed: PropTypes.arrayOf(PropTypes.string),
	
	active: PropTypes.bool.isRequired,
}

/* ----------  Exports  ---------- */

export default ActionPanel;
