/* ----------  Imports  ---------- */

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { map, indexOf, filter, isEmpty } from 'lodash';

// jQuery
import $ from 'jquery';

// jsTree
import 'jstree';

// Tree
import Tree from './Tree';

// Components
// import Ctx from './../../../Helpers/Ctx';

// Helpers
import Notify from './../../../Helpers/Notify';

// Notifications
import notifications from './../../../EventEmitters/Notifications';

// Storage
import Storage from './../../../Services/Storage';
import InlineRenameHelper from '../../../Helpers/InlineRenameHelper';

// Window jQuery
const jQuery = window.$;

/* ----------  Scripts  ---------- */

class NodesTree extends Tree {
	constructor(props) {
		super(props);

		this.state = {
			nodes: [],
			action: '',
			loaded: false,
			publish: false,
			removeOriginal: false
		}
	}

	componentDidMount() {
		this.load();
		this.initNotifications();
	}

	getTourIds = node => {
		const tourIds = [];

		if((node.li_attr.type === 50) && node.li_attr.objectid) {
			tourIds.push(node.li_attr.objectid);
		}

		return tourIds;
	}

	getTitles = (parentNodeId, node) => {
		const titles = [];
		const parentNode = this.getNode(parentNodeId);

		if(parentNodeId) {
			const { children } = parentNode;

			map(children, child => {
				const childNode = this.getNode(child);

				if(node.id === childNode.id) return;
				
				if(childNode && (node.li_attr.type === childNode.li_attr.type)) {
					titles.push(childNode.li_attr.text);
				}
			});
		}

		return titles;
	}

	checkIsLoaded = () => this.state.loaded

	handleTreeActions = () => {
		const { handleContext } = this.props;
		const $tree = $(`#${ this.props.id }`);
		const parent = el => $(el).closest('li');

		$tree.off('click.publish').on('click.publish', '[data-publish]', e => {
			e.preventDefault();
			e.stopPropagation();

			const $atn = $(e.currentTarget);
			const $parent = parent($atn);
			const node = this.getNode($parent);
			const tourIds = this.getTourIds(node);
			const { publish } = this.props;
			const isPublished = node.li_attr.published || false;

			this.updateBusyNode(node.id);

			publish({ tourIds, publish: !isPublished }, () => {
				this.updateBusyNode(node.id, true);
				map(tourIds, tourId => {
					const $node = $(`[objectid=${ tourId }]`, $tree);
					const $anchor = $node.find('>a');

					const nextStatus = !isPublished;

					this.getNode($node).li_attr.published = nextStatus;

					if(nextStatus) {
						$('> [data-publish]', $anchor).addClass('published');
					} else {
						$('> [data-publish]', $anchor).removeClass('published');
					}
				});
			}, () => {
				this.updateBusyNode(node.id, true);
			});
		});

		$tree.off('contextmenu.ctx').on('contextmenu.ctx', 'li', e => {
			e.preventDefault();
			e.stopPropagation();

			const $item = $(e.currentTarget);
			const $atn = $('[data-context]', $item);
			const ctxType = $atn.attr('data-context');
			const props = this.getCtxProps($atn);

			if(handleContext && ctxType) handleContext(ctxType, props, null, e);
		});

		$tree.off('click.context').on('click.context', '[data-context]', e => {
			e.preventDefault();
			e.stopPropagation();

			const $atn = $(e.currentTarget);
			const ctxType = $atn.attr('data-context');
			const props = this.getCtxProps($atn);

			if(handleContext && ctxType) handleContext(ctxType, props, e.currentTarget);
		});

		$tree.off('dblclick.play').on('dblclick.play', '.jsTreeAnchor', e => {
			e.preventDefault();
			e.stopPropagation();

			const $node = $(e.currentTarget);
			const $parent = parent($node);
			
			const node = this.getNode($parent);
			// const nodeType = $parent.attr('type');

			this.handleInlineRename($node, node);

			// if(parseInt(nodeType, 10) === 60) {
			// 	this.playTour($node);
			// } else if(parseInt(nodeType, 10) === 50) {
			// 	const { handleSequencePanel } = this.props;

			// 	const tour = {
			// 		refId: node.id,
			// 		title: node.li_attr.text,
			// 		parentNodeId: node.parent,
			// 		tourId: node.li_attr.objectid,
			// 		nodeId: node.li_attr['data-id'],
			// 	}

			// 	if(handleSequencePanel) handleSequencePanel(tour, true);
			// } else if(parseInt(nodeType, 10) === 20) {
			// 	this.handleInlineRename($node, node);
			// }
		});

		$tree.off('click.newCollection').on('click.newCollection', '[data-new-collection]', e => {
			e.preventDefault();

			this.newCollection();
		});

		$tree.off('click.newFolder').on('click.newFolder', '[data-new-folder]', e => {
			e.preventDefault();

			const $node = $(e.currentTarget);

			this.newFolder($node);
		});

		$tree.off('click.newGroup').on('click.newGroup', '[data-new-group]', e => {
			e.preventDefault();

			this.newGroup();
		});

		$tree.off('click.newLeg').on('click.newLeg', '[data-new-leg]', e => {
			e.preventDefault();

			const $node = $(e.currentTarget);

			this.newLeg($node);
		});

		$tree.off('click.newTour').on('click.newTour', '[data-new-tour]', e => {
			e.preventDefault();

			const $node = $(e.currentTarget);

			this.newTour($node);
		});

		$tree.off('click.play').on('click.play', '[data-play]', e => {
			e.preventDefault();

			const $node = $(e.currentTarget);

			this.playTour($node);
		});
	}

	handleInlineRename = ($target, node) => {
		const inlineRenameHelper = InlineRenameHelper($target, node.li_attr.text, {
			offset: {
				left: 55,
			}
		});

		inlineRenameHelper.init();
		inlineRenameHelper.open();
		
		inlineRenameHelper.onFormSubmit((form, value) => {
			const $form = $(form);
			const $txtTitle = $('.txtTitle', $form);

			const { renameNode } = this.props;

			if(!renameNode) {
				Notify.error('Cannot complete this action.');
				return;
			}

			const data = {
				nodeId: node.li_attr['data-id'],
				nodeType: node.li_attr.type,
				title: value,
			}

			$txtTitle.prop('readOnly', true);

			this.props.renameNode(data, () => {
				$txtTitle.prop('readOnly', false);
				$txtTitle.trigger('blur');
			}, () => {
				$txtTitle.prop('readOnly', false);
				$txtTitle.trigger('focus');
				Notify.error('Cannot complete this action.');
			});
		});
	}

	initNotifications = () => {
		notifications.on('conversationMarkedRead', nodeId => {
			const tree = this.tree.current;
			
			if(tree) {
				const id = this.getPrefixedId(nodeId);
				const node = tree.querySelector(`#${ id }`);

				if(node) node.classList.remove('jstree-has-notif');
			}
		});
	}

	load = () => {
		const { type } = this.props;

		const tree = this.tree.current;
		const publishTrees = ['groupedTours', 'ungroupedTours'];
		const validOperations = ['move_node', 'copy_node'];
		
		const invalidParentTypes = [50, 60];

		$(tree).jstree({
			core: {
				dblclick_toggle : false,
				check_callback: (operation, node, parent, position, more) => {
					if(indexOf(validOperations, operation) !== -1) {
						if(more && more.dnd) this.handleTreeMarker(node.id, parent.id, position);

						let isValid = true;
						
						const nodeTree = this.getTree(node.id);
						const nodeAttrs = node.li_attr;
						const parentAttrs = parent.li_attr;
						const childTitles = this.getTitles(parent, node);
						const inLimit = this.checkLimit(parent);

						if(isEmpty(nodeTree)) return false;

						// if(more.dnd && more.pos !== 'i') isValid = false;

						if((operation === 'copy_node') && (nodeAttrs.licensed || nodeAttrs.ispurchased)) isValid = false;
						
						if((nodeAttrs.type === 20) && !inLimit) isValid = false;

						if(indexOf(childTitles, nodeAttrs.text) >= 0) isValid = false;
						
						if(indexOf(nodeTree.allowed, type) === -1) isValid = false;
						if((indexOf(publishTrees, type) !== -1) && (nodeAttrs.type === 20)) isValid = false;
						if(parentAttrs && (indexOf(invalidParentTypes, parentAttrs.type) > -1)) isValid = false;

						if((nodeAttrs && (nodeAttrs.type === 10)) && (parentAttrs && (parentAttrs.type === 10))) isValid = false;
						if((nodeAttrs && (nodeAttrs.type === 30)) && (parentAttrs && (parentAttrs.type === 30))) isValid = false;
						if((nodeAttrs && (nodeAttrs.type === 30)) && (parentAttrs && (parentAttrs.type === 40))) isValid = false;
						if((nodeAttrs && (nodeAttrs.type === 40)) && (parentAttrs && (parentAttrs.type === 30))) isValid = false;

						if(more && !more.dnd && !isValid) Notify.error('Action not allowed.');

						return isValid;
					}

					return true;
				}
			},

			dnd: {
				is_draggable: nodes => {
					let draggable = true;
					const { rootDraggable } = this.props;

					const panels = document.querySelectorAll('[data-panel]');

					if(nodes.length && !rootDraggable) {
						map(nodes, node => {
							if(node.li_attr) draggable = !node.li_attr.root;
						});
					}

					if(panels.length) {
						draggable = false;
					}

					return draggable;
				}
			},

			plugins: ['dnd']
		});

		$(tree).on('ready.jstree', () => {
			this.api = $(tree).jstree(true);

			this.handleTreeActions();
			this.renderNodes();

			this.openSavedNodes(null);
		});

		$(tree).off('create_node.jstree').on('create_node.jstree', (e, data) => {
			const { node } = data;
			const parentNode = this.getNode(node.parent);
			
			jQuery(document).trigger('tree.create_node', [node, tree]);

			setTimeout(() => {
				this.expandNode(parentNode.id);
			}, 200);
		});

		$(tree).off('after_open.jstree').on('after_open.jstree', (e, data) => {
			const { node } = data;
			const children = map(node.children_d, child => this.getNode(child));

			const opts = {
				tree: type,
				action: 'open',
				nodeId: node.id,
				parentNodeId: node.parent,
			}

			Storage.setTreeItem(opts);

			jQuery(document).trigger('tree.open_node', [children, node, tree]);
		});

		$(tree).off('after_close.jstree').on('after_close.jstree', (e, data) => {
			const { node } = data;

			Storage.removeTreeItem(node.id);

			this.removeTreeNodes(node.children_d, node.id);
		});

		$(tree).off('move_node.jstree').on('move_node.jstree', (e, data) => {
			const parentNode = this.getNode(data.parent);
			const nodes = ($(tree).data('nodes') || 1) - 1;

			this.setState({
				action: 'move'
			}, () => {
				this.setTree(data.node.id);
				this.createNodeData('move', data.node.id, data.node, parentNode, data.position, () => {
					if(!nodes) this.handleTree();
				}, nodes);
			});

			$(tree).data('nodes', nodes);
		});

		$(tree).off('copy_node.jstree').on('copy_node.jstree', (e, data) => {
			const parentNode = this.getNode(data.parent);
			const nodes = ($(tree).data('nodes') || 1) - 1;

			this.setState({
				action: 'copy'
			}, () => {
				this.handleReplication(data.node.id, data.node, parentNode, data.position, nodes);
			});

			$(tree).data('nodes', nodes);
		});

		$(tree).off('rename_node.jstree').on('rename_node.jstree', (e, data) => {
			const { node } = data;

			this.api.refresh_node(node);
		});

		$(tree).off('click.node').on('click.node', '[data-expander]', e => {
			const $this = $(e.currentTarget);
			const $node = $this.closest('li');
			const nodeId = $node.attr('id');

			const node = this.getNode(nodeId);
			
			if(node) {
				const { state } = node;

				if(!state.opened && ((node.li_attr.type === 10) || (node.li_attr.type === 20))) {
					this.openNode(nodeId);
				} else {
					this.contractNode(nodeId);
				}
			}
		});
	}

	openNode = nodeId => {
		const node = this.getNode(nodeId);

		if(!node) return false;

		const { state } = node;

		const params = {
			parentNodeId: node.li_attr['data-id'],
			parentNodeType: node.li_attr.type
		}

		const { type } = this.props;
		const validTypes = ['legs', 'tours'];

		if(!state.opened && ((node.li_attr.type === 10) || (node.li_attr.type === 20))) {
			this.getNodes(params, node.id, meta => {
				if(meta && meta.total) {
					setTimeout(() => {
						this.expandNode(node.id);
					}, 300);
				} else {
					Storage.removeTreeItem(node.id);
				}

				if(indexOf(validTypes, type) >= 0) this.openSavedNodes();
			});
		}

		return true;
	}

	openSavedNodes = () => {
		const nodes = Storage.getTree();
		
		if(!nodes || isEmpty(nodes)) return false;

		map(nodes, node => this.openNode(node.nodeId));

		return true;
	}

	newCollection = () => {
		const { handleNewCollection } = this.props;

		if(handleNewCollection) handleNewCollection();
	}

	newFolder = node => {
		const { handleNewFolder } = this.props;
		const $parent = node.closest('li');
		const parentNode = this.getNode($parent);
		const tree = this.getTree(parentNode.id).type;

		const data = {
			parentNodeId: parentNode.id,
			parentNodeType: parentNode.li_attr.type,
			licensed: parentNode.li_attr.licensed,
			tree,
		}

		if(handleNewFolder) handleNewFolder(data);
	}

	newGroup = () => {
		const { handleNewGroup } = this.props;

		if(handleNewGroup) handleNewGroup();
	}

	newLeg = node => {
		const { handleNewLegAddress } = this.props;
		const $parent = node.closest('li');
		const parentNode = this.getNode($parent);
		const tree = this.getTree(parentNode.id).type;

		const data = {
			parentNodeId: parentNode.id,
			parentNodeType: parentNode.li_attr.type,
			licensed: parentNode.li_attr.licensed,
			tree,
		}

		if(handleNewLegAddress) handleNewLegAddress(data);
	}

	newTour = node => {
		const { handleTour } = this.props;
		const $parent = node.closest('li');
		const parentNode = this.getNode($parent);
		const tree = this.getTree(parentNode.id).type;

		const data = {
			parentNodeId: parentNode.id,
			parentNodeType: parentNode.li_attr.type,
			tree,
		}

		if(handleTour) handleTour(data);
	}

	playTour = atn => {
		const $parent = atn.closest('li');
		const node = this.getNode($parent);

		const { playTour } = this.props;
		const id = node.li_attr.objectid;
		const type = node.li_attr.type;

		if (playTour) {
			if(type === 50) {
				playTour(id, null, type);
			} else if(type === 60) {
				playTour(null, id, type);
			}
		}
	}

	publishToursToGroup = (ids, parent) => {
		const tourIds = map(ids, id => {
			const node = this.getNode(id);

			return node.li_attr.objectid
		});

		const data = {
			tourIds,
			groupId: parent,
		}

		const { publishToursToGroup } = this.props;

		if(publishToursToGroup) {
			publishToursToGroup(data);
		}
	}

	togglePublishState = (tourIds, published) => {
		const tours = document.querySelectorAll('[type="50"]');

		if(tours.length) {
			map(tours, tour => {
				const nodeIcon = tour.querySelector('.nodeIcon');

				if(published) {
					nodeIcon.classList.add('published');
				} else {
					nodeIcon.classList.remove('published');
				}
			});
		}
	}

	update = (params, parent) => {
		let node = false;
		let api = this.api;

		if(params.tNodeId) {
			node = this.getNode(params.tNodeId);
		} else {
			const parentTree = this.getTree(parent).tree;
			api = $(parentTree).jstree(true);

			const parentNode = api.get_node(parent);
			
			const nodeId = filter(parentNode.children, child => {
				const nodeObj = api.get_node(child);
				
				return nodeObj.li_attr.objectid === params.refTourId;
			});

			node = api.get_node(nodeId);
		}

		if(node) {
			const objectId = params.objectId || params.tourId || node.li_attr.objectid;
			const tree = this.getTree(node.id);
			const nodeId = this.getPrefixedId(params.nodeId);

			node.li_attr['data-id'] = params.nodeId;
			node.li_attr['data-parent'] = params.parentNodeId;
			
			node.li_attr.objectid = objectId;
			node.li_attr.tree = tree.type;
			node.li_attr.text = params.title || node.li_attr.text;
			
			node.a_attr.id = `${ nodeId }_anchor`;

			api.set_id(node, nodeId);

			this.updateNode(node);
		}
	}

	updateNodeLicense = (nodeId, license) => {
		const id = this.getPrefixedId(nodeId);
		const treeNode = this.getNode(id);

		if(treeNode) {
			treeNode.li_attr.licensed = license;

			this.updateNode(treeNode, () => {
				Notify.success('Leg license updated successfully!');
			});
		}
	}

	updateNodeNotifUI = () => {
		const { nodes } = this.props;
		const validNodes = filter(nodes, node => node.nodeType === 50 || node.nodeType === 60);

		map(validNodes, node => {
			const tree = this.tree.current;

			const id = this.getPrefixedId(node.nodeId);
			const el = tree.querySelector(`#${ id }`);

			if(el) {
				if(node.unread) {
					el.classList.add('jstree-has-notif');
				} else {
					el.classList.remove('jstree-has-notif');
				}
			}
		});
	}
}

/* ----------  Prop Types  ---------- */

NodesTree.defaultProps = {
	play: false,
	publish: false,
	unPublish: false,
	publishToursToGroup: false,

	rootDraggable: false,
	handleContext: false,
	handleSequencePanel: false,
	
	handleTour: false,
	handleNewGroup: false,
	handleNewFolder: false,
	handleNewLegAddress: false,
	handleNewCollection: false,
	
	renameNode: false,

	urlTourId: '',

	prefix: '',
}

NodesTree.propTypes = {
	rootDraggable: PropTypes.bool,
	
	play: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	publish: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	publishToursToGroup: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	
	handleContext: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	handleSequencePanel: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	
	handleTour: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	handleNewGroup: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	handleNewFolder: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	handleNewLegAddress: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	handleNewCollection: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	
	renameNode: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),

	handleTree: PropTypes.func.isRequired,
}

/* ----------  Exports  ---------- */

export default NodesTree;
