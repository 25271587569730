/* ----------  Imports  ---------- */

// Ion Slider
import 'ion-rangeslider/js/ion.rangeSlider';

// Selectize
import 'selectize';

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { map, isEmpty, startCase } from 'lodash';

// jQuery
import $ from 'jquery';

// Utils
import Toggle from './../../Utils/Toggle';
import Selectize from './../../Utils/Selectize';

// Window jQuery
const jQuery = window.$;

/* ----------  Scripts  ---------- */

class Search extends React.Component {
	constructor(props) {
		super(props);

		this.isLoaded = false;

		this.closeTimer = null;
		this.txtPriceData = null;

		this.places = window.google.maps.places;

		this.txtSearch = React.createRef();
		this.txtPrice = React.createRef();
		this.txtLanguages = React.createRef();
		this.searchFilters = React.createRef();
		
		this.tagsSelect = React.createRef();
		this.categoryIdsSelect = React.createRef();

		this.initialFilters = {
			position: [],
			radius: 2000,
			title: '',
			location: '',
			type: 'byLocation',
			price: [],
			languages: [],
			categoryIds: [],
			tags: [],
		}

		this.state = {
			active: false,
			filters: this.initialFilters
		}
	}

	componentDidMount() {
		this.isLoaded = true;

		this.closeHandler();
	}

	componentWillUnmount() {
		this.isLoaded = false;
	}

	getLanguageOptions = () => {
		const data = [];
		const { languages } = this.props;

		if(languages && !isEmpty(languages)) {
			map(languages, language => {
				const option = {
					label: language.name,
					value: language.code
				}

				data.push(option);
			});
		}

		return data;
	}

	getCategoriesOptions = () => {
		const { categories } = this.props;

		if(categories) {
			const data = map(categories, category => ({
				label: startCase(category.name),
				value: category.categoryId
			}));

			return data;
		}

		return [];
	}

	autocomplete = action => {
		const txtSearch = this.txtSearch.current;

		if(action === 'init') {
			const autocomplete = new this.places.Autocomplete(txtSearch);

			autocomplete.addListener('place_changed', () => {
				const place = autocomplete.getPlace();
				const location = place.formatted_address;
				const position = place.geometry ? [place.geometry.location.lat(), place.geometry.location.lng()] : [];

				this.updateFilters({ location, position });
			});
		} else if(action === 'destroy') {
			window.google.maps.event.trigger(window.autocomplete, 'remove', true);
			$('.pac-container').detach();
		}
	}

	initPriceRange = () => {
		const $txt = jQuery('[data-slider]');

		if(!$txt.length) return false;

		const { prices } = this.props;
		const { filters } = this.state;

		$txt.ionRangeSlider({
			skin: 'round',
			type: 'double',
			min: prices.min,
			max: prices.max,
			extra_classes: 'irs-input',
			
			onFinish: this.updateTxtPrice
		});

		this.txtPriceData = $txt.data('ionRangeSlider');

		this.txtPriceData.update({
			from: filters.price[0] || prices.min,
			to: filters.price[1] || prices.max,
		});

		return true;
	}

	closeHandler = () => {
		// $('body').off('click.search').on('click.search', e => {
		// 	const $search = $(e.target).closest('#cntSearch');

		// 	if(!$search.length) {
		// 		this.reset();
		// 		this.closeSearch();
		// 	}
		// });

		$('body').off('keyup.search').on('keyup.search', e => {
			if(e.keyCode === 27) {
				this.reset();
				this.closeSearch();
			}
		});
	}

	handleFilters = e => {
		e.preventDefault();
		
		if(this.closeTimer) clearTimeout(this.closeTimer);
	}

	handleSearch = e => {
		switch(e.type) {
			case 'focus': this.openSearch(); break;
			// case 'blur': this.closeSearch(); break;
			default: this.openSearch(); break;
		}
	}

	handleText = e => {
		let field = 'title';
		let notField = 'location';

		const { filters } = this.state;
		const text = e.currentTarget.value;
		
		if(filters.type === 'byLocation') {
			field = 'location';
			notField = 'title';
		}

		this.updateFilters({
			[field]: text,
			[notField]: '',
		});
	}

	handleLocationSearch = () => {
		this.updateFilters({
			position: [],
			title: '',
			location: '',
			type: 'byLocation'
		}, () => {
			this.autocomplete('init');
		});
	}

	handleContentSearch = () => {
		this.updateFilters({
			position: [],
			title: '',
			location: '',
			type: 'byContent'
		}, () => {
			this.autocomplete('destroy');
		});
	}

	openSearch = () => {
		if(this.isLoaded) {
			this.setState({ active: true }, () => {
				document.body.classList.add('search-open');
			});
		}
	}

	closeSearch = () => {
		this.closeTimer = setTimeout(() => {
			if(this.isLoaded) {
				this.setState({ active: false }, () => {
					this.reset();
					document.body.classList.remove('search-open');
				});
			}
		}, 200);
	}

	dismissFilters = e => {
		e.preventDefault();
		e.stopPropagation();

		this.reset();
		this.closeSearch();
	}

	submitForm = e => {
		e.preventDefault();

		const { filters } = this.state;

		const params = {
			tags: filters.tags,
			price: filters.price,
			title: filters.title,
			radius: filters.radius,
			location: filters.location,
			position: filters.position,
			languages: filters.languages,
			categoryIds: filters.categoryIds,
		}

		this.props.submitForm(params);
		this.closeSearch();
	}

	updateCategoryIds = (name, value) => {
		this.updateFilters({
			categoryIds: value
		});
	}

	updateLanguages = (name, value) => {
		this.updateFilters({
			languages: value
		});
	}

	updateTags = (name, value) => {
		this.updateFilters({
			tags: value
		});
	}

	updateTxtPrice = () => {
		const { result } = this.txtPriceData;

		this.updateFilters({
			price: [result.from, result.to]
		});
	}

	updateFilters = (updates, callback) => {
		const { filters } = this.state;

		if(this.isLoaded) {
			this.setState({
				filters: {
					...filters,
					...updates
				}
			}, () => {
				if(callback) callback();
			});
		}
	}

	reset = () => {
		const initialFilters = this.initialFilters;

		this.updateFilters({ ...initialFilters });
	}

	resetPriceRange = () => {
		if(this.txtPriceData) this.txtPriceData.destroy();
	}

	resetType = () => {
		const filters = this.searchFilters.current;
		const filterType = filters.querySelectorAll('.filterType');

		if(filterType.length) {
			map(filterType, type => {
				jQuery(type).iCheck('update');
			})
		}
	}

	renderLanguageSelect = () => {
		const { active, filters } = this.state;
		const langsData = this.getLanguageOptions();

		if(!active) return <div className="d-none"/>;

		return (
			<Selectize
				multiple
				maxItems={ 2 }
				name="languages"
				id="searchTourLanguages"
				data={ langsData }
				value={ filters.languages }
				onChange={ this.updateLanguages }
				ref={ this.languagesSelect }/>
		);
	}

	renderCategorySelect = () => {
		const { active, filters } = this.state;

		if(!active) return <div className="d-none"/>;

		const data = this.getCategoriesOptions();

		return (
			<Selectize 
				multiple
				maxItems={ 3 }
				data={ data }
				id="searchTourCategories"
				name="categoryIds"
				value={ filters.categoryIds }
				onChange={ this.updateCategoryIds }
				ref={ this.categoryIdsSelect }/>
		);
	}

	renderTagsSelect = () => {
		const { active, filters } = this.state;

		if(!active) return <div className="d-none"/>;

		return (
			<Selectize 
				tags
				multiple
				id="searchTourTags"
				name="tags"
				value={ filters.tags }
				onChange={ this.updateTags }
				ref={ this.tagsSelect }/>
		);
	}

	renderPriceFilter = () => {
		const { price } = this.props;

		if(!price) return <div className="d-none"/>;

		return (
			<div className="filter-row price-row">
				<label htmlFor="txtPrice">Price</label>
				<input type="text" className="filterPrice txtPrice txtSlider" ref={ this.txtPrice } data-slider/>
			</div>
		);
	}

	render() {
		const { active, filters } = this.state;
		const searchFilters = this.searchFilters.current;

		if(active) {
			this.initPriceRange();
			$(searchFilters).slideDown('fast', () => {
				if(filters.type === 'byLocation') {
					this.autocomplete('init');
				} else {
					this.autocomplete('destroy');
				}
			});
		} else {
			$(searchFilters).slideUp('fast', () => {
				this.resetType();
				this.resetPriceRange();
			});
		}

		return (
			<div className={ `global-search-container ${ (filters.title || filters.location) ? 'valid' : '' }` } id="cntSearch">
				<form action="#" onSubmit={ this.submitForm }>
					<div className="search-inner">
						<div className="search-form">
							<input
								type="text"
								className="form-control txtSearch"
								placeholder="Search"
								value={ filters.type === 'byLocation' ? filters.location : filters.title }
								onFocus={ this.handleSearch }
								onBlur={ this.handleSearch }
								onChange={ this.handleText }
								ref={ this.txtSearch }/>
							<button className="btn-submit">
								<span className="btn-icon">
									<i className="material-icons">search</i>
								</span>
							</button>
						</div>
						<div className="search-filter searchFilter" ref={ this.searchFilters }>
							<div className="filter-inner">
								<div className="filter-row toggle-row">
									<div className="icheck-inline">
										<Toggle
											type="radio"
											name="filterType"
											id="byLocation"
											value="byLocation"
											className="filterType"
											checked={ filters.type === 'byLocation' || false }
											onChecked={ this.handleLocationSearch }/>
										<label htmlFor="byLocation" className="inline-label">By Location</label>
									</div>
									<div className="icheck-inline">
										<Toggle
											type="radio"
											name="filterType"
											id="byContent"
											value="byContent"
											className="filterType"
											checked={ filters.type === 'byContent' || false }
											onChecked={ this.handleContentSearch }/>
										<label htmlFor="byContent" className="inline-label">By Content</label>
									</div>
								</div>
								{ this.renderPriceFilter() }
								<div className="filter-row lang-row">
									<label htmlFor="txtLanguages">Narration Languages</label>
									{ this.renderLanguageSelect() }
								</div>
								<div className="filter-row category-row">
									<label htmlFor="txtCategories">Categories</label>
									{ this.renderCategorySelect() }
								</div>
								<div className="filter-row tags-row">
									<label htmlFor="txtTags">Tags</label>
									{ this.renderTagsSelect() }
								</div>
								<div className="filter-atn">
									<Link to="#nav" className="btnClose" onClick={ this.dismissFilters }>
										<span className="fas fa-times"/> Close
									</Link>
								</div>
							</div>
						</div>
					</div>
				</form>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

Search.defaultProps = {
	price: true,
	languages: false,
	categories: false,

	prices: {
		min: 0,
		max: 100,
	},
}

Search.propTypes = {
	price: PropTypes.bool,
	
	prices: PropTypes.shape({
		min: PropTypes.number,
		max: PropTypes.number,
	}),

	submitForm: PropTypes.func.isRequired,

	languages: PropTypes.oneOfType([PropTypes.bool, PropTypes.objectOf(PropTypes.object)]),
	categories: PropTypes.oneOfType([PropTypes.bool, PropTypes.objectOf(PropTypes.object)]),
}

/* ----------  Exports  ---------- */

export default Search;
