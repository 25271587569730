/* ----------  Scripts  ---------- */

const RELATED_TOURS_INITIAL_STATE = {};

const ReducerHomeRelatedTours = (state = RELATED_TOURS_INITIAL_STATE, action) => {
	if(action.type === 'HOME_GET_RELATED_TOURS') {
		return { ...state, ...action.payload.results };
	} else if(action.type === 'HOME_REMOVE_RELATED_TOURS') {
		return RELATED_TOURS_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerHomeRelatedTours;