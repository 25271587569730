/* ----------  Imports  ---------- */

/* ----------  Add History  ---------- */

const RemoveHistory = (fromIndex, toIndex, callback) => dispatch => {
	dispatch({
		type: 'REMOVE_HISTORY',
		payload: {
			fromIndex,
			toIndex: toIndex >= 0 ? toIndex : fromIndex
		}
	});

	if(callback) callback();
}

/* ----------  Exports  ---------- */

export default RemoveHistory;
