/* ----------  Imports  ---------- */

// Axios
import axios from "axios";

// Globals
import Global from './../../../Constants/Global';

/* ----------  Scripts  ---------- */

const Checkout = (success, fail) => dispatch => {
    const url = `${ Global.API_BASE_URL }/cart/checkout`;

    axios.post(url).then(response => {
        const { status } = response.data;

        dispatch({
            type: 'CART_CHECKOUT',
        });

        if (success) success(status);
    }).catch(error => {
        const { response } = error;

        if (fail) {
            if (response) {
                fail(response.data.status, response.data.error.reasons);
            } else {
                fail(false, []);
            }
        }
    });
}

/* ----------  Exports  ---------- */

export default Checkout;