/* ----------  History  ---------- */

const History = Object.freeze({
	type: {
		LEG: 'Leg',
		GROUP: 'Group',
		MUSIC: 'Music',
		IMAGE: 'Image',
	},

	action: {
		START: 1000,

		SORT: 10,
		SORT_LEG: 11,
		SORT_GROUP: 12,
		SORT_MUSIC: 13,
		SORT_IMAGE: 14,

		MOVE: 20,
		MOVE_LEG: 21,
		MOVE_GROUP: 22,
		MOVE_MUSIC: 23,
		MOVE_IMAGE: 24,
		
		CREATE: 30,
		CREATE_LEG: 31,
		CREATE_GROUP: 32,
		CREATE_MUSIC: 33,
		CREATE_IMAGE: 34,
		CREATE_HISTORY: 35,
		
		DELETE: 40,
		DELETE_LEG: 41,
		DELETE_GROUP: 42,
		DELETE_MUSIC: 43,
		DELETE_ALL_MUSIC: 43.1,
		DELETE_IMAGE: 44,
		DELETE_ALL_IMAGE: 44.1,
		DELETE_HEADER_VIDEO: 45,
		DELETE_FOOTER_VIDEO: 46,
		
		RESIZE: 50,
		RESIZE_LEG: 51,
		RESIZE_GROUP: 52,
		RESIZE_MUSIC: 53,
		RESIZE_IMAGE: 54,
		
		UPLOAD: 60,
		UPLOAD_MUSIC: 61,
		UPLOAD_MUSIC_BEFORE: 61.1,
		UPLOAD_MUSIC_AFTER: 61.2,
		UPLOAD_MUSIC_ONBEGINNING: 61.3,
		UPLOAD_MUSIC_ONEND: 61.4,
		UPLOAD_IMAGE: 62,
		UPLOAD_IMAGE_BEFORE: 62.1,
		UPLOAD_IMAGE_AFTER: 62.2,
		UPLOAD_IMAGE_ONBEGINNING: 62.3,
		UPLOAD_IMAGE_ONEND: 62.4,
		UPLOAD_HEADER_VIDEO: 63,
		UPLOAD_FOOTER_VIDEO: 64,
		
		UPDATE: 70,
		UPDATE_LEG: 71,
		UPDATE_LEG_TITLE: 71.1,
		UPDATE_GROUP: 72,
		UPDATE_GROUP_TITLE: 72.1,
		UPDATE_MUSIC: 73,
		UPDATE_MUSIC_COLOR: 73.1,
		UPDATE_MUSIC_POSITION: 73.2,
		UPDATE_MUSIC_POSITION_PREVIOUS: 73.3,
		UPDATE_MUSIC_POSITION_NEXT: 73.4,
		UPDATE_MUSIC_SIZE: 73.5,
		UPDATE_IMAGE: 74,
		UPDATE_IMAGE_COLOR: 74.1,
		UPDATE_IMAGE_POSITION: 74.2,
		UPDATE_IMAGE_POSITION_PREVIOUS: 74.3,
		UPDATE_IMAGE_POSITION_NEXT: 74.4,
		UPDATE_IMAGE_SETTINGS: 74.5,
		UPDATE_IMAGE_SIZE: 74.6,

		LOCK: 80,
		LOCK_IMAGE: 81,
		LOCK_MUSIC: 82,
		
		UNLOCK: 90,
		UNLOCK_IMAGE: 91,
		UNLOCK_MUSIC: 92,

		RENAME: 100,
		RENAME_IMAGE: 100.1,
		RENAME_MUSIC: 100.2,
		RENAME_GROUP: 100.3,
		RENAME_LEG: 100.4,
		RENAME_HEADER_VIDEO: 100.5,
		RENAME_FOOTER_VIDEO: 100.6,
	},

	getTitle: atn => {
		const { action } = History;

		switch(atn) {
			case action.START: return 'Start';

			case action.SORT: return '$1 Sorted';
			case action.SORT_LEG: return '$1 Sorted';
			case action.SORT_GROUP: return '$1 Sorted';
			case action.SORT_MUSIC: return '$1 Sorted';
			case action.SORT_IMAGE: return '$1 Sorted';

			case action.MOVE: return '$1 Moved';
			case action.MOVE_LEG: return '$1 Moved';
			case action.MOVE_GROUP: return '$1 Moved';
			case action.MOVE_MUSIC: return '$1 Moved';
			case action.MOVE_IMAGE: return '$1 Moved';
			
			case action.CREATE: return '$1 Created';
			case action.CREATE_LEG: return '$1 Created';
			case action.CREATE_GROUP: return '$1 Created';
			case action.CREATE_MUSIC: return '$1 Created';
			case action.CREATE_IMAGE: return '$1 Created';
			case action.CREATE_HISTORY: return '$1 Created';
			
			case action.DELETE: return '$1 Deleted';
			case action.DELETE_LEG: return '$1 Deleted';
			case action.DELETE_GROUP: return '$1 Deleted';
			case action.DELETE_MUSIC: return '$1 Deleted';
			case action.DELETE_ALL_MUSIC: return '$1 Deleted';
			case action.DELETE_IMAGE: return '$1 Deleted';
			case action.DELETE_ALL_IMAGE: return '$1 Deleted';
			case action.DELETE_HEADER_VIDEO: return '$1 Deleted';
			case action.DELETE_FOOTER_VIDEO: return '$1 Deleted';
			
			case action.RESIZE: return '$1 Resized';
			case action.RESIZE_LEG: return '$1 Resized';
			case action.RESIZE_GROUP: return '$1 Resized';
			case action.RESIZE_MUSIC: return '$1 Resized';
			case action.RESIZE_IMAGE: return '$1 Resized';
			
			case action.UPLOAD: return '$1 Uploaded';
			case action.UPLOAD_MUSIC: return '$1 Uploaded';
			case action.UPLOAD_MUSIC_BEFORE: return '$1 Uploaded';
			case action.UPLOAD_MUSIC_AFTER: return '$1 Uploaded';
			case action.UPLOAD_MUSIC_ONBEGINNING: return '$1 Uploaded';
			case action.UPLOAD_MUSIC_ONEND: return '$1 Uploaded';
			case action.UPLOAD_IMAGE: return '$1 Uploaded';
			case action.UPLOAD_IMAGE_BEFORE: return '$1 Uploaded';
			case action.UPLOAD_IMAGE_AFTER: return '$1 Uploaded';
			case action.UPLOAD_IMAGE_ONBEGINNING: return '$1 Uploaded';
			case action.UPLOAD_IMAGE_ONEND: return '$1 Uploaded';
			case action.UPLOAD_HEADER_VIDEO: return '$1 Uploaded';
			case action.UPLOAD_FOOTER_VIDEO: return '$1 Uploaded';
			
			case action.UPDATE: return '$1 Updated';
			case action.UPDATE_LEG: return '$1 Updated';
			case action.UPDATE_LEG_TITLE: return '$1 Title Updated';
			case action.UPDATE_GROUP: return '$1 Updated';
			case action.UPDATE_GROUP_TITLE: return '$1 Title Updated';
			case action.UPDATE_MUSIC: return '$1 Updated';
			case action.UPDATE_MUSIC_COLOR: return '$1 Color Updated';
			case action.UPDATE_MUSIC_POSITION: return '$1 Position Updated';
			case action.UPDATE_MUSIC_SIZE: return '$1 Size Updated';
			case action.UPDATE_IMAGE: return '$1 Updated';
			case action.UPDATE_IMAGE_COLOR: return '$1 Color Updated';
			case action.UPDATE_IMAGE_POSITION: return '$1 Positon Updated';
			case action.UPDATE_IMAGE_SETTINGS: return '$1 Settings Updated';
			case action.UPDATE_IMAGE_SIZE: return '$1 Size Updated';

			case action.LOCK: return '$1 Locked';
			case action.LOCK_IMAGE: return '$1 Locked';
			case action.LOCK_MUSIC: return '$1 Locked';
			
			case action.UNLOCK: return '$1 Unlocked';
			case action.UNLOCK_IMAGE: return '$1 Unlocked';
			case action.UNLOCK_MUSIC: return '$1 Unlocked';
			
			case action.RENAME: return '$1 Renamed';
			case action.RENAME_IMAGE: return '$1 Renamed';
			case action.RENAME_MUSIC: return '$1 Renamed';
			case action.RENAME_GROUP: return '$1 Renamed';
			case action.RENAME_LEG: return '$1 Renamed';
			case action.RENAME_HEADER_VIDEO: return '$1 Renamed';
			case action.RENAME_FOOTER_VIDEO: return '$1 Renamed';

			default: return 'Unidentified History.';
		}
	}
});

/* ----------  Exports  ---------- */

export default History;
