/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { map, isEmpty } from 'lodash';

// Preloader
import Preloader from './../../Common/Preloader';

// Related Tours Components;
import RelatedTourBlock from './RelatedTourBlock';

// Utils
import Scrollbar from './../../Utils/Scrollbar';

/* ----------  Scripts  ---------- */

class RelatedTours extends React.Component {
	state = {
		loading: true,
	}

	componentDidMount() {
		this.load();
	}

	onClose = e => {
		e.preventDefault();

		this.props.hide();
	}

	setTourActive = tourId => {
		this.props.setTourActive(tourId);
	}

	changeMapPosition = position => {
		this.props.changeMapPosition(position);
	}

	load = () => {
		this.props.load(() => {
			this.setState({ loading: false });
		}, () => {
			this.setState({ loading: false });
		});
	}

	handleTour = tourId => this.props.handleTour(tourId)

	renderTourBlocks = () => {
		const { tourGroups } = this.props;

		console.log(tourGroups);

		if(this.state.loading) return <Preloader loading center minimal/>;

		if(isEmpty(tourGroups)) return <span className="no-records d-block text-center p-15"><em>No tours found.</em></span>;

		return map(tourGroups, group => (
			<RelatedTourBlock 
				key={ group.groupId }
				group={ group }
				loadTour={ this.loadTour }
				handleTour={ this.handleTour }
				setTourActive={ this.setTourActive }
				changeMapPosition={ this.changeMapPosition }/>
		));
	}

	render() {
		return (
			<div className="related-tours-content">
				<div className="section-title">
					<h2>Related Results</h2>
					<Link to="#related" className="btn-close" onClick={ this.onClose }>&times;</Link>
				</div>
				<Scrollbar className="related-results-content">
					{ this.renderTourBlocks() }
				</Scrollbar>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

RelatedTours.propTypes = {
	hide: PropTypes.func.isRequired,
	load: PropTypes.func.isRequired,
	// showTour: PropTypes.func.isRequired,
	handleTour: PropTypes.func.isRequired,
	setTourActive: PropTypes.func.isRequired,
	changeMapPosition: PropTypes.func.isRequired,

	// tourGroups: PropTypes.arrayOf(PropTypes.object).isRequired
	tourGroups: PropTypes.shape().isRequired
}

/* ----------  Exports  ---------- */

export default RelatedTours;
