/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Redux
import { connect } from 'react-redux';

// Redux
import { bindActionCreators } from 'redux';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { map } from 'lodash';

// Noty
import Noty from 'noty';

// Actions
import VerifyCode from '../../../../Redux/Actions/User/Auth/ResetPassword/VerifyCode';

// Fuse Components
import Input from '../../../../Components/Fuse/Input';
import Alerts from '../../../../Components/Fuse/Alerts';

// Preloader
import Preloader from '../../../../Components/Common/Preloader';

// Helpers
import InputHelper from '../../../../Helpers/InputHelper';

/* ----------  Scripts  ---------- */

class VerifyCodeForm extends React.Component {
	constructor(props) {
		super(props);

		this.isLoaded = false;

		this.form = React.createRef();
		this.rememberCheckboxRef = React.createRef();

		this.initialFormState = {
			code: '',
			email: this.props.data.email
		}

		this.prefilledFormState = {
			code: 'azizk.araneux@gmail.com',
		}

		this.state = {
			busy: false,
			formErrors: [],
			form: this.initialFormState,
		}
	}

	componentDidMount() {
		this.isLoaded = true;
	}

	componentWillUnmount() {
		this.isLoaded = false;
	}

	onCancel = e => {
		e.preventDefault();
		this.props.changeForm('LOGIN');
	}

	onChange = e => {
		const { currentTarget: { name, value } } = e;
		this.updateForm({ [name]: value });
	}
	
	onSubmit = e => {
		e.preventDefault();

		const isValid = this.validateForm();

		if(isValid) this.submitForm();
	}

	submitForm = () => {
		const { form } = this.state;

		const data = {
			...form,
		}

		if(this.isLoaded) this.setState({ busy: true });

		this.props.verifyCode(data, (status, result) => {
			console.log({ status, result });
			
			const noty = new Noty({
				theme: 'nest',
				type: 'info',
				text: 'Code verified successfully. Please proceed to reset your password.',
				timeout: 5000,
			});
	
			noty.show();

			this.props.updateAuthState({ email: data.email, code: data.code }, () => {
				this.setState({ busy: false }, () => {
					this.props.changeForm('RESET_PASSWORD');
				});
			});
		}, (status, errors) => {
			const errorMessages = map(errors, error => error.message);
			this.setState({ formErrors: errorMessages, busy: false });
		});
	}

	updateForm = (updates, callback) => {
		if(this.isLoaded) {
			const { form } = this.state;
			
			this.setState({
				form: {
					...form,
					...updates
				}
			}, () => {
				if(callback) callback();
			});
		}
	}

	validateForm = () => {
		let isValid = true;

		const errors = [];
		const { form: { code } } = this.state;

		if(InputHelper.isEmpty(code)) {
			isValid = false;
			errors.push('Please enter a valid code.');
		}

		if(this.isLoaded) this.setState({ formErrors: errors });

		return isValid;
	}

	render() {
		const { form, formErrors, busy } = this.state;

		return (
			<div className="form-wrapper p-8">
				<div className="title mt-4 mb-8">Verify Code</div>
				<form onSubmit={ this.onSubmit } noValidate>
					<Alerts show={ formErrors.length > 0 } data={ formErrors }/>
					<Input
						id="txtVerifyCodeFormCode"
						// label="Verification Code"
						name="code"
						type="code"
						value={ form.code }
						onChange={ this.onChange }/>

					<button type="submit" className="submit-button btn btn-block btn-secondary my-4 mx-auto" disabled={ busy } aria-label="LOG IN">
						<Preloader loading={ busy } size={ 15 } color="#ffffff" minimal center/>
						{ !busy ? 'Verify Code' : '' }
					</button>
				</form>
				<div className="login d-flex flex-column align-items-center justify-content-center mt-8 mx-auto">
					<span className="text mr-sm-2">Want to login?</span>
					<Link to="#auth" className="text link text-secondary" onClick={ this.onCancel }>Login</Link>
				</div>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

VerifyCodeForm.defaultProps = {
	data: {
		email: ''
	}
}

VerifyCodeForm.propTypes = {
	data: PropTypes.shape(),

	changeForm: PropTypes.func.isRequired,
	updateAuthState: PropTypes.func.isRequired,
	
	verifyCode: PropTypes.func.isRequired,
}

/* ----------  Redux Scripts  ---------- */

// const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		verifyCode: VerifyCode
	}, dispatch)
);

/* ----------  Exports  ---------- */

export default connect(null, mapDispatchToProps)(VerifyCodeForm);
