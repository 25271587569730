/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Redux
import { connect } from 'react-redux';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { isEmpty, map } from 'lodash';

// Selectize
import Selectize from './../../Components/Utils/Selectize';

/* ----------  Scripts  ---------- */

class LanguageDropdown extends React.Component {
	constructor(props) {
		super(props);

		this.select = React.createRef();
	}

	getLanguagesOptions = () => {
		const { languages } = this.props;

		if(!isEmpty(languages)) {
			const data = map(languages, language => ({
				label: language.name,
				value: language.code,
				flag: language.flag || language.code
			}));

			return data;
		}

		return [];
	}

	render() {
		const { id, value, languages, name, onChange } = this.props;

		if(isEmpty(languages)) return <div className="d-none"/>;

		const data = this.getLanguagesOptions();

		return (
			<Selectize
				id={ id }
				data={ data }
				name={ name }
				value={ value }
				type={ 'language' }
				onChange={ onChange }
				dropdownParent={ null }
				ref={ this.select }/>
		);
	}
}

/* ----------  Redux Scripts  ---------- */

const mapStateToProps = state => ({
	languages: state.languages,
});

/* ----------  Prop Types  ---------- */

LanguageDropdown.defaultProps = {
	onChange: false
}

LanguageDropdown.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	
	languages: PropTypes.objectOf(PropTypes.object).isRequired,
	
	onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
}

/* ----------  Exports  ---------- */

export default connect(mapStateToProps)(LanguageDropdown);