/* ----------  Imports  ---------- */

// Constants
import Global from '../Constants/Global';

// Lodash
import { first } from 'lodash';

/* ----------  Config Helper  ---------- */

const ConfigHelper = {
	getAppConfigs: () => {
		const { hostname, origin } = window.location;

		const configs = {
			appHostname: hostname.toLowerCase(),
			appName: 'STREETTOUR',
			appLogo: `https://web-street-tour-development.appspot.com/assets/images/logos/main-streettour-logo.png`,
			appBaseURL: origin,
			appTagline: 'You tour the world',
			// appStripeKey: 'pk_test_Hf5eU0yffo4Lj6ppJCzz5rym00mJ8624Ko',
			appStripeKey: 'pk_live_so9Dy9Eaot2tjHrfsDKLQECB00bKhtDWZa',
			appContactEmail: 'info@streettour.co',
			appContactPhone: '+11010101010',
		}

		const host = first(hostname.toLowerCase().split('.')) || '';

		switch(host) {
			case 'streettour':
				configs.appName = 'STREETTOUR';
				configs.appLogo = `${ configs.appBaseURL }/assets/images/logos/main-streettour-logo.png`;
				// configs.appBaseURL = 'https://streettour.co';
				configs.appStripeKey = 'pk_live_so9Dy9Eaot2tjHrfsDKLQECB00bKhtDWZa';
				configs.appContactEmail = 'info@streettour.co';
				configs.appContactPhone = '+11010101010';
				break;
			case 'streetour':
				configs.appName = 'STREETOUR';
				configs.appLogo = `${ configs.appBaseURL }/assets/images/logos/main-streetour-logo.png`;
				// configs.appBaseURL = 'https://streetour.co';
				configs.appStripeKey = 'pk_live_so9Dy9Eaot2tjHrfsDKLQECB00bKhtDWZa';
				configs.appContactEmail = 'info@streetour.co';
				configs.appContactPhone = '+11010101010';
				break;
			default: break;
		}

		return configs;
	}
}

/* ----------  Exports  ---------- */

export default ConfigHelper;
