/* ----------  Imports  ---------- */

// Helpers
import ConfigHelper from "../Helpers/ConfigHelper";

/* ----------  Scripts  ---------- */

const APP_CONFIGS = ConfigHelper.getAppConfigs() || {};

const Global = {
	APP_NAME: APP_CONFIGS.appName || 'Street Tour',
	APP_LOCAL_URL: 'http://localhost:3000',
	GOOGLE_MAPS_API: 'AIzaSyByD4-la4w4PAF2Bj95aEBHPOzKj9wcv0M',
	// STRIPE_KEY: 'rk_test_24DeAZ2ZnDOZywfTIu5XnOik00sIsQZYzf',
	STRIPE_KEY: 'pk_test_Hf5eU0yffo4Lj6ppJCzz5rym00mJ8624Ko',
	APP_URL: APP_CONFIGS.appBaseURL,
	API_BASE_URL: 'https://street-tour-development.appspot.com/v1',
	APP_BASE_URL: 'https://web-street-tour-development.appspot.com',
	DUMMY_API_BASE_URL: 'https://next.json-generator.com/api/json/get',
	VALID_CURRENCIES: ['USD', 'AED', 'AUD', 'CAD', 'EUR', 'GBP', 'INR'],
	ASSETS_BASE_URL: `${ APP_CONFIGS.appBaseURL }/assets`,
	VALID_LANGUAGES: ['en', 'zh', 'ja', 'es', 'de', 'ru', 'fr', 'ar', 'hi', 'pt'],

	LANGUAGES: {
		ar: {
			code: 'ar',
			flag: 'sa',
		},
		hi: {
			code: 'hi',
			flag: 'in',
		},
		zh: {
			code: 'zh',
			flag: 'cn',
		},
		en: {
			code: 'en',
			flag: 'gb',
		},
		ja: {
			code: 'ja',
			flag: 'jp',
		},
		es: {
			code: 'es',
			flag: 'es',
			name: 'Spanish'
		}
	},
}

/* ----------  Exports  ---------- */

export default Global;
