/* ----------  Imports  ---------- */

const SetTourHovered = data => dispatch => {
	dispatch({
		type: 'HOME_SET_TOUR_HOVERED',
		payload: data
	})
}

/* ----------  Exports  ---------- */

export default SetTourHovered;