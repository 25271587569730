/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { map } from 'lodash';

/* ----------  Scripts  ---------- */

class FullHeight extends React.Component {
	constructor(props) {
		super(props);

		this.cnt = React.createRef();
	}

	componentDidMount() {
		setTimeout(this.calculateHeight, 300);
	}

	calculateHeight = () => {
		const node = this.cnt.current;
		const wHeight = window.innerHeight;
		const nodeOffset = document.querySelectorAll('[data-fullheight-offset]');

		let totalOffset = 0;

		if(nodeOffset.length) {
			map(nodeOffset, item => {
				totalOffset += item.clientHeight;
			});
		}

		node.style.height = `${wHeight - totalOffset}px`;
	}

	render() {
		return (
			<div className={ this.props.className } id={ this.props.id } ref={ this.cnt }>
				{ this.props.children }
			</div>
		);
	}
}

FullHeight.defaultProps = {
	id: '',
	className: ''
}

FullHeight.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	children: PropTypes.node.isRequired
}

/* ----------  Exports  ---------- */

export default FullHeight;
