/* ----------  Imports  ---------- */

// Axios
import axios from 'axios';

// Global
import Global from './../../../../../Constants/Global';

/* ----------  Scripts  ---------- */

const RemoveLegLicense = (data, success, fail) => dispatch => {
	// const url = `${ Global.DUMMY_API_BASE_URL }/4J4OjEEcH`;
	const url = `${ Global.API_BASE_URL }/trees/leg/license`;

	axios.delete(url, { data }).then(response => {
		const { status } = response.data;

		dispatch({
			type: 'MYTOURS_TREES_REMOVE_LEG_LICENSE',
			payload: data
		});
		
		if(success) success(status);
	}).catch(error => {
		const { response } = error;

		if(fail) {
			if(response) {
				fail(response.data.status, response.data.error.reasons);
			} else {
				fail(false, []);
			}
		}
	});
};

/* ----------  Exports  ---------- */

export default RemoveLegLicense;