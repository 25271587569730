/* ----------  Imports  ---------- */

// React
import React from 'react';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { debounce, map, join, omit } from 'lodash';

// Helpers
import Notify from './../../../Helpers/Notify';

/* ----------  Scripts  ---------- */

class DataTree extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			loading: true
		}

		this.requestNodes = debounce(this.doRequestNodes, 100);
		this.requestActionPanelNodes = debounce(this.doRequestActionPanelNodes, 100);

		this.requestNodesDetails = debounce(this.doRequestNodeDetails, 100);

		this.requestCopyNodes = debounce(this.doCopyNodes, 100);
		this.requestMoveNodes = debounce(this.doMoveNodes, 100);
		this.requestDeleteNode = debounce(this.doDeleteNode, 100);
		this.requestRenameNode = debounce(this.doRenameNode, 100);

		this.requestCreateFolder = debounce(this.doCreateFolder, 100);
		
		this.requestMoveNodeUp = debounce(this.doMoveNodeUp, 100);
		this.requestMoveNodeDown = debounce(this.doMoveNodeDown, 100);
	}

	componentWillMount() {
		this.getNodes();
	}

	// Getters

	getNodes = (params, success, fail) => {
		this.showPreloader();
		this.requestNodes(params, success, fail);
	}

	getActionPanelNodes = (params, success, fail) => {
		this.requestActionPanelNodes(params, success, fail);
	}

	getData = () => {
		const treeRef = this.treeRef.current;
	
		return treeRef.getData();
	}

	getNodeDetails = (data, success, fail) => {
		const reqData = {
			nodeId: data.id,
			nodeType: data.type,
		}

		if(data.baseNodeType) reqData.baseNodeType = data.baseNodeType;
		if(data.title) reqData.title = data.title;

		this.requestNodesDetails(reqData, success, fail);
	}

	// Request

	doRequestNodes = (data, success, fail) => {
		const { getNodes, baseNodeType } = this.props;

		if(getNodes) {
			let params = {
				baseNodeType,
				recursive: false,
				parentNodeId: '#',
				// parentNodeTypes: join(rootNodeTypes, ','),
				parentNodeTypes: 0,

				...data,
			}

			if(params.parentNodeType) {
				params = omit(params, 'parentNodeTypes');
			}

			getNodes(params, (status, meta) => {
				this.hidePreloader();
				if(success) success(meta);
			}, () => {
				this.hidePreloader();
				if(fail) fail();
			});
		}
	}

	doRequestActionPanelNodes = (data, success, fail) => {
		this.removeActionPanelNodes();

		const { getActionPanelNodes, rootNodeTypes, baseNodeType } = this.props;

		if(getActionPanelNodes) {
			const params = {
				baseNodeType,
				recursive: false,
				parentNodeId: '#',
				parentNodeTypes: join(rootNodeTypes, ','),

				...data,
			}

			getActionPanelNodes(params, () => {
				if(success) success();
			}, () => {
				if(fail) fail();
			});
		}
	}

	doCreateFolder = (data, success, fail) => {
		const { createFolder } = this.props;

		if(createFolder) createFolder(data, success, fail);
	}

	doCopyNodes = (nodes, tree, success, fail) => {
		const { copyNodes } = this.props;

		if(copyNodes) {
			const treeRef = this.treeRef.current;

			copyNodes(nodes, (status, response) => {
				if(response.length) map(response, obj => treeRef.update(obj));
				if(success) success();
			}, (status, reasons) => {
				map(reasons, reason => Notify.error(reason.message));

				if(nodes.length) map(nodes, node => treeRef.removeNode(node.tNodeId));
				if(fail) fail();
			});
		}
	}

	doMoveNodes = (nodes, tree, success, fail) => {
		const { moveNodes } = this.props;
		const treeRef = this.treeRef.current;

		if(moveNodes) moveNodes(nodes, () => {
			if(tree === 'publishTree') {
				Notify.success('Tree saved successfully!');
			}
		}, (status, reasons, result) => {
			if(result && result.length) {
				map(result, obj => {
					const node = {
						nodeId: obj.nodeId,
						nodeType: obj.nodeType,
						parentNodeId: obj.parentNodeId,
						parentNodeType: obj.parentNodeType,
					}

					treeRef.moveNode(node, tree);
				});
			}

			map(reasons, reason => Notify.error(reason.message));

			if(fail) fail();
		});
	}

	doDeleteNode = (nodes, tree, callback) => {
		const { deleteNode } = this.props;

		if(deleteNode) {
			const data = nodes[0];
			deleteNode(data, callback);
		}
	}

	doRenameNode = (data, success, fail) => {
		const { renameNode } = this.props;

		if(renameNode) renameNode(data, success, fail);
	}

	doRequestNodeDetails = (data, success, fail) => {
		const { getNodeDetails } = this.props;

		if(getNodeDetails) {
			this.removeNodeDetails();
			
			getNodeDetails(data, () => {
				const { nodeDetails } = this.props;

				if(success) success(nodeDetails.details);
			}, () => {
				if(fail) fail();
			});
		}
	}

	doMoveNodeUp = (data, success, fail) => {
		const { moveNodeUp } = this.props;
		if(moveNodeUp) moveNodeUp(data, success, fail);
	}

	doMoveNodeDown = (data, success, fail) => {
		const { moveNodeDown } = this.props;
		if(moveNodeDown) moveNodeDown(data, success, fail);
	}

	// Utils

	copyNode = (data, srcTree, parentNode) => {
		const treeRef = this.treeRef.current;
		treeRef.copyNode(data, srcTree, parentNode);
	}

	moveNode = (data, srcTree, parentNode) => {
		const treeRef = this.treeRef.current;
		treeRef.moveNode(data, srcTree, parentNode);
	}

	deleteNode = node => {
		const treeRef = this.treeRef.current;
		treeRef.deleteNode(node);
	}

	publishNodesToGroup = (node, srcTree, destTree, parentNode) => {
		const treeRef = this.treeRef.current;
		treeRef.publishNodesToGroup(node, srcTree, destTree, parentNode);
	}

	renameNode = (opts, success, fail) => {
		const treeRef = this.treeRef.current;
		const { baseNodeType } = this.props;

		const data = {
			baseNodeType,
			...opts
		}

		this.requestRenameNode(data, () => {
			treeRef.renameNode(data);
			
			if(success) success();
		}, (status, reasons) => {
			if(fail) fail(reasons);
		});
	}

	sortNodes = (params, root) => {
		const treeRef = this.treeRef.current;

		if(!root && treeRef) {
			const nodeId = treeRef.getPrefixedId(params.parentNodeId);
			treeRef.getNodes(params, nodeId);
		} else {
			this.getNodes(params);
		}
	}

	createFolder = (opts, success, fail) => {
		const treeRef = this.treeRef.current;
		const { baseNodeType } = this.props;

		const data = {
			baseNodeType,
			...opts
		}

		this.requestCreateFolder(data, (status, response) => {
			treeRef.createNode(response);

			if(success) success();
		}, (status, reasons) => {
			if(fail) fail(reasons);
		});
	}

	// Player

	playTour = (tourId, legIds, type, srcType, autoplay, ftIndex) => {
		const { playTour } = this.props;

		if(playTour) playTour(tourId, legIds, type, srcType, autoplay, ftIndex);
	}

	// Preloader

	showPreloader = () => {
		this.setState({ loading: true });
	}

	hidePreloader = () => {
		this.setState({ loading: false });
	}

	// Handlers

	handleContext = (type, props, target, event) => {
		const { handleContext } = this.props;

		if(handleContext) handleContext(type, props, target, event);
	}

	handleNewFolder = data => {
		const { handleNewFolder } = this.props;

		if(handleNewFolder) handleNewFolder(data);
	}

	handleTree = (action, nodes, tree, callback) => {
		switch(action) {
			case 'copy': this.requestCopyNodes(nodes, tree, callback); break;
			case 'move': this.requestMoveNodes(nodes, tree, callback); break;
			case 'delete': this.requestDeleteNode(nodes, tree, callback); break;
			default: break;
		}
	}

	// Removers

	removeNodes = () => {
		const { removeNodes } = this.props;
		if(removeNodes) removeNodes();
	}

	removeActionPanelNodes = () => {
		const { removeActionPanelNodes } = this.props;
		if(removeActionPanelNodes) removeActionPanelNodes();
	}

	removeNodeDetails = () => {
		const { removeNodeDetails } = this.props;
		if(removeNodeDetails) removeNodeDetails();
	}
}

/* ----------  Prop Types  ---------- */

DataTree.defaultProps = {
	getNodes: false,
	removeNodes: false,

	getActionPanelNodes: false,
	removeActionPanelNodes: false,

	copyNodes: false,
	moveNodes: false,
	deleteNode: false,
	renameNode: false,

	getNodeDetails: false,
	removeNodeDetails: false,
	
	createFolder: false,
	
	filterTypes: 0,

	playTour: false,

	handleContext: false,
	handleNewFolder: false,
	
	moveNodeUp: false,
	moveNodeDown: false,

	nodeDetails: {},
}

DataTree.propTypes = {
	getNodes: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	removeNodes: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	
	getActionPanelNodes: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	removeActionPanelNodes: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),

	copyNodes: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	moveNodes: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	deleteNode: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	renameNode: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),

	getNodeDetails: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	removeNodeDetails: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	
	createFolder: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),

	playTour: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	
	handleContext: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	handleNewFolder: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	
	moveNodeUp: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	moveNodeDown: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),

	nodeDetails: PropTypes.shape(),
	
	rootNodeTypes: PropTypes.arrayOf(PropTypes.number).isRequired,
	
	baseNodeType: PropTypes.number.isRequired,
}

/* ----------  Exports  ---------- */

export default DataTree;
