/* ----------  Imports  ---------- */

const SetTourActive = data => dispatch => {
	dispatch({
		type: 'HOME_SET_TOUR_ACTIVE',
		payload: data
	})
}

/* ----------  Exports  ---------- */

export default SetTourActive;