/* ----------  Imports  ---------- */

// Lodash
import { map, remove, maxBy, find } from 'lodash';

/* ----------  Scripts  ---------- */

const CART_INITIAL_STATE = [];

const ReducerCart = (state = CART_INITIAL_STATE, action) => {
	if(action.type === 'GET_CART') {
		return [ ...state, ...action.payload.results ];
	} else if(action.type === 'CART_ADD_ITEM') {
		const lastCartItem = maxBy(state, 'autoId');
		let autoId = lastCartItem ? lastCartItem.autoId : 0;

		const legs = map(action.payload.legs, leg => {
			autoId += 1;

			return {
				autoId,
				legId: leg.legId,
				title: leg.title,
				price: leg.price
			}
		});

		return [ ...state, ...legs ];
	} else if(action.type === 'CART_REMOVE_ITEM') {
		const updatedState = [ ...state ];

		map(action.payload.legIds, legId => {
			const leg = find(updatedState, ['legId', legId]);

			remove(updatedState, leg);
		});

		return updatedState;
	} else if(action.type === 'REMOVE_CART') {
		return CART_INITIAL_STATE;
	}

	return state;
}

/* ----------  Exports  ---------- */

export default ReducerCart;