/* ----------  Scripts  ---------- */

const RemoveDebitCards = () => dispatch => {
	dispatch({
		type: 'USER_REMOVE_DEBIT_CARDS'
	});
};

/* ----------  Exports  ---------- */

export default RemoveDebitCards;
