/* ----------  Imports  ---------- */

// React
import React from 'react';

// Lodash
import { isEmpty } from 'lodash';

// Media Recorder
// import Recorder from 'opus-recorder';

// Path
// import path from 'path';

/* ----------  View Misc  ---------- */

class ViewMisc extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isRecording: false,
			audioSrc: '',
			hasPermission: false,

			count: 0
		}

		this.recorder = null;
		
		this.gumStream = null;

		this.audioBuffers = [];

		this.audioWorker = null;
	}

	componentDidMount() {
		document.querySelector('body').classList.add('p-0');

		// this.loadRecorder();
		this.initWorker();
	}

	onStartRecording = e => {
		e.preventDefault();
		this.startRecording();
	}
	
	onStopRecording = e => {
		e.preventDefault();
		this.stopRecording();
	}
	
	onStartCount = e => {
		e.preventDefault();
		this.startCount();
	}
	
	onClearCount = e => {
		e.preventDefault();
		this.clearCount();
	}
	
	onStopCount = e => {
		e.preventDefault();
		this.stopCount();
	}

	initWorker = () => {
		this.audioWorker = new Worker('/workers/audio.worker.js', {
			type: 'module',
		});
		
		this.audioWorker.addEventListener('message', e => {
			const { data } = e;
			
			switch(data.action) {
				case 'SW_COUNTING': 
				case 'SW_CLEAR': 
				this.setState({
					count: data.count
				});
				break;
				default: break;
			}
		});
		
		console.log(this.audioWorker);

		this.audioWorker.postMessage({
			action: 'AUDIO_WORKER_LOAD'
		});
	}

	startCount = () => {
		if(!this.audioWorker) this.initWorker();

		this.audioWorker.postMessage({
			action: 'START_COUNT'
		});
	}
	
	clearCount = () => {
		if(!this.audioWorker) return;

		this.audioWorker.postMessage({
			action: 'CLEAR_COUNT'
		});
	}

	stopCount = () => {
		if(!this.audioWorker) return;

		this.audioWorker.terminate();
		this.audioWorker = undefined;
	}

	loadRecorder = () => {
		// this.recorder = new Recorder({
		// 	streamPages: true,
		// 	numberOfChannels: 2,
		// 	encoderPath: path.resolve(__dirname, '/assets/workers/opus-recorder/', 'encoderWorker.min.js')
		// });

		// this.recorder.ondataavailable = buffer => {
		// 	console.log('Recording: PUSHED AVAILABLE DATA');
		// 	this.audioBuffers.push(buffer);
		// };

		// this.recorder.loadWorker();
	}

	startRecording = () => {
		this.recorder.start().catch(() => {
			this.setState({
				isRecording: true,
				audioSrc: '',
				hasPermission: false,
			});
		});
		
		this.recorder.onstart = () => {
			console.log('Recording: START');
			this.setState({
				isRecording: true,
				audioSrc: '',
				hasPermission: true,
			});
		}
	}
	
	stopRecording = () => {
		if(this.state.hasPermission) {
			this.recorder.onstop = () => {
				console.log('Recording: STOP');
				
				const blob = new Blob(this.audioBuffers, {
					type: 'audio/ogg'
				});
	
				this.setState({
					isRecording: false,
					audioSrc: URL.createObjectURL(blob),
				});
			};
			
			this.recorder.stop();
		} else {
			this.setState({
				isRecording: false,
				audioSrc: '',
				hasPermission: false,
			});
		}
	}

	renderActions = () => {
		const { isRecording } = this.state;

		return (
			<div className="actions-container">
				<div className="container m-b-20">
					<button type="button" className={ `btn btn-primary btn-lg m-r-10 ${ isRecording ? 'disabled' : '' }` } onClick={ this.onStartRecording }>Start Recording</button>
					<button type="button" className={ `btn btn-primary btn-lg ${ !isRecording ? 'disabled' : '' }` } onClick={ this.onStopRecording }>Stop Recording</button>
				</div>
				<div className="container">
					<button type="button" className={ `btn btn-primary btn-lg m-r-10` } onClick={ this.onStartCount }>Start Counting</button>
					<button type="button" className={ `btn btn-primary btn-lg m-r-10` } onClick={ this.onStopCount }>Stop Counting</button>
					<button type="button" className={ `btn btn-primary btn-lg` } onClick={ this.onClearCount }>Clear Count</button>
				</div>
			</div>
		);
	}

	renderPlayer = () => {
		const { audioSrc } = this.state;

		if(isEmpty(audioSrc)) return '';

		return (
			<div className="player-container m-t-20">
				<div className="container">
					<audio controls style={{ height: '54px' }}>
						<source src={ audioSrc }/>
						<track kind="captions"/>
					</audio>
				</div>
			</div>
		);
	}

	render() {
		return (
			<div className="misc-container p-v-20">
				{ this.renderActions() }
				{ this.renderPlayer() }
				<div className="container m-t-15">
					<h3>Current Count: { this.state.count }</h3>
				</div>
			</div>
		);
	}
}

/* ----------  Exports  ---------- */

export default ViewMisc;
