/* ----------  Scripts  ---------- */

const RemoveOtherLegs = () => dispatch => (
	dispatch({
		type: 'MARKETPLACE_REMOVE_OTHER_LEGS',
	})
);

/* ----------  Exports  ---------- */

export default RemoveOtherLegs;