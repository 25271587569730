/* ----------  Imports  ---------- */

// Axios
import axios from 'axios';

// Lodash
import { indexOf } from 'lodash';

// Globals
import Global from './../Constants/Global';

// Storage
import Storage from './Storage';

/* ----------  Scripts  ---------- */

const Axios = {
	intercept: () => {
		axios.interceptors.request.use(config => {
			const { url, method } = config;
			const urlSegments = url.split('/');
			
			let hasFile = false;

			if((method === 'post') && (indexOf(urlSegments, 'attachment') !== -1)) {
				hasFile = true;
			}

			const headers = Axios.getHeaders(hasFile);

			if(url.startsWith(Global.API_BASE_URL)) config.headers = headers;

			return config;
		}, error => Promise.reject(error));
	},

	getHeaders: (hasFile) => {
		const authorization = {};
		const token = Storage.getToken();

		if(token) authorization.Authorization = `JWT ${ token }`;

		const type = hasFile ? 'multipart/form-data' : 'application/json';

		const headers = {
			...authorization,
			'X-Custom-VisitId': '308c05af-b932-40a4-b7d4-bb55ec94392b',
			'Content-Type': type,
		}

		return headers;
	}
}

/* ----------  Exports  ---------- */

export default Axios;