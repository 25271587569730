/* ----------  Imports  ---------- */

// UIKit
import UIkit from "uikit";

// Stripe JS
import { loadStripe } from "@stripe/stripe-js";

// Helpers
import ConfigHelper from "./ConfigHelper";

/* ----------  Stripe Helper  ---------- */

const StripeHelper = {
	loadPromise: () => loadStripe(ConfigHelper.getAppConfigs().appStripeKey),
	checkoutViaSessionId: async (stripe, sessionId) => {
		console.log('Stripe Helper:', sessionId);
		await stripe.redirectToCheckout({ sessionId })
	},

	handleSubscriptionCallback: () => {
		const pageURL = window.location;
		const url = new URL(pageURL);

		const rsubscription = url.searchParams.get('rsubscription') || null;
		if(!rsubscription) return;

		if(rsubscription && rsubscription === 'fail') {
			const failModal = UIkit.modal('#modalUserNotificationRSubscriptionFail', {
				center: true,
				bgclose: false,
				keyboard: false,
			});

			failModal.show();

			return;
		}

		const successModal = UIkit.modal('#modalUserNotificationRSubscriptionSuccess', {
			center: true,
		});

		successModal.show();
	},

	forceUserRegistrationPayment: () => {
		const modal = UIkit.modal('#modalUserNotificationRSubscriptionForce', {
			center: true,
			bgclose: false,
			keyboard: false,
		});

		modal.show();
	}
}

/* ----------  Exports  ---------- */

export default StripeHelper;
