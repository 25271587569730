/* ----------  Imports  ---------- */

// React
import React, { Fragment } from 'react';

// React Redux
import { connect } from 'react-redux';

// Redux
import { bindActionCreators } from 'redux';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { isEqual, debounce } from 'lodash';

// React Stripe
import { Elements, ElementsConsumer } from '@stripe/react-stripe-js';

// Constants
import Global from './Constants/Global';

// Routes
import Routes from './Routes';

// Auth
import CheckAuth from './Redux/Actions/User/Auth/CheckAuth';

// FCM Token
import UpdateFCMToken from './Redux/Actions/User/UpdateFCMToken';

// Config Actions
import SetAppConfigs from './Redux/Actions/Configs/App/SetAppConfigs';

// Services
import Axios from './Services/Axios';
import Storage from './Services/Storage';
import FirebaseService from './Services/FirebaseService';

// Helpers
import StripeHelper from './Helpers/StripeHelper';

// Modals Notification
import ModalsUserNotifications from './Components/Alerts/Modals/ModalsUserNotifications';

/* ----------  Scripts  ---------- */

const STRIPE_PROMISE = StripeHelper.loadPromise();

class App extends React.Component  {
	componentWillMount() {
		console.log('APP WILL MOUNT');

		// this.handleHTTPSRedirect();

		this.configureHostSettings();

		document.title = Global.APP_NAME;
		
		this.checkAuth(() => {
			Axios.intercept();
		});

		this.requestUpdateFcmToken = debounce(this.doRequestUpdateFcmToken, 100);
	}

	componentDidMount() {
		console.log('APP MOUNTED');

		document.querySelector('#appRoot').classList.add('app-loaded');

		this.initFirebase();
		this.handleUserSubscription();
	}

	doRequestUpdateFcmToken = (data, success, fail) => {
		this.props.updateFcmToken(data, success, fail);
	}

	checkAuth = callback => {
		this.props.checkAuth(callback);
	}

	configureHostSettings = () => {
		this.props.setAppConfigs();
	}

	handleHTTPSRedirect = () => {
		const { protocol, host, href } = window.location;
		const isLocalhost = host.startsWith('localhost') || host.startsWith('127.0.0.1');

		if(isLocalhost) return;
		if(protocol === 'https:') return;

		window.location.replace(`https:${ href.substring(location.protocol.length) }`);
	}

	handleUserSubscription = () => {
		setTimeout(() => {
			const { userProfile } = this.props;
			if(userProfile && userProfile.registrationStripeSessionId) {
				const pageURL = window.location;
				const url = new URL(pageURL);
				const rsubscription = url.searchParams.get('rsubscription') || null;
	
				if(!rsubscription) StripeHelper.forceUserRegistrationPayment();
			}
		}, 1000);

		StripeHelper.handleSubscriptionCallback();
	}

	updateFcmToken = token => {
		const data = {
			fcmToken: token
		}

		this.requestUpdateFcmToken(data);
	}

	initFirebase = () => {
		FirebaseService.load();
		FirebaseService.getPermissions(null, null, response => {
			if(!response) return false;

			const { token, fcmToken } = this.props;

			console.log({ response, token, fcmToken });

			if(token) {
				const isTokenEqual = isEqual(response, fcmToken);

				if(!isTokenEqual) {
					console.log('UPDATE FCM');
					this.updateFcmToken(response);
				}
			}
			
			this.checkAuth();

			return true;
		});
	}

	render() {
		const { token } = this.props;
		const sToken = Storage.getToken();

		const authToken = sToken || token || null;

		return (
			<Fragment>
				<Routes token={ authToken }/>
				<Elements stripe={ STRIPE_PROMISE }>
					<div className="uk-modals-notifications">
						<ElementsConsumer>
							{({ stripe }) => <ModalsUserNotifications stripe={ stripe }/>}
						</ElementsConsumer>
					</div>
				</Elements>
			</Fragment>
		);
	}
};

/* ----------  Prop Types  ---------- */

App.defaultProps = {
	token: '',
	fcmToken: '',
	userProfile: {}
}

App.propTypes = {
	token: PropTypes.string,
	fcmToken: PropTypes.string,

	checkAuth: PropTypes.func.isRequired,
	updateFcmToken: PropTypes.func.isRequired,
	
	setAppConfigs: PropTypes.func.isRequired,

	userProfile: PropTypes.shape(),
}

/* ----------  Redux Scripts  ---------- */

const mapStateToProps = state => ({
	token: state.user.auth.token,
	fcmToken: state.user.fcmToken,
	userProfile: state.user.profile,
	user: state.user,
});

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		checkAuth: CheckAuth,
		updateFcmToken: UpdateFCMToken,
		
		setAppConfigs: SetAppConfigs,
	}, dispatch)
);

/* ----------  Exports  ---------- */

export default connect(mapStateToProps, mapDispatchToProps)(App);
