/* ----------  Imports  ---------- */

// Axios
import axios from 'axios';

// Lodash
import { get } from 'lodash';

// Global
import Global from './../../../../Constants/Global';

// Notifications
import notifications from './../../../../EventEmitters/Notifications';

/* ----------  Scripts  ---------- */

const MarkConversationRead = (data, success, fail) => (dispatch, getState) => {
	const url = `${ Global.API_BASE_URL }/conversation/read`;

	axios.post(url, data).then(response => {
		const { status } = response.data;

		const list = getState().conversation.listData;
		const conversation = get(list, data.conversationId);

		notifications.emit('conversationMarkedRead', conversation.node.nodeId);

		if(success) success(status);
	}).catch(error => {
		const { response } = error;

		if(fail) {
			if(response) {
				fail(response.data.status, response.data.error.reasons);
			} else {
				fail(false, []);
			}
		}

		dispatch({
			type: 'MARK_CONVERSATION_UNREAD',
			payload: data.conversationId
		});
	});

	dispatch({
		type: 'MARK_CONVERSATION_READ',
		payload: data.conversationId
	});
};

/* ----------  Exports  ---------- */

export default MarkConversationRead;