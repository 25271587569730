/* ----------  Imports  ---------- */

// Lodash
import { map, mapKeys, get } from 'lodash';

/* ----------  Scripts  ---------- */

const CONVERSATIONS_DATA_INITIAL_STATE = {};

const ReducerConversationsData = (state = CONVERSATIONS_DATA_INITIAL_STATE, action) => {
	if(action.type === 'GET_CONVERSATIONS') {
		return mapKeys(action.payload.results, 'conversationId');
	} else if(action.type === 'MARK_CONVERSATION_READ') {
		const conversation = get(state, action.payload);
		const update = { ...conversation, unread: 0 };

		return { ...state, [action.payload]: update };
	} else if(action.type === 'MARK_ALL_CONVERSATIONS_READ') {
		const clonedState = { ...state };
		const conversations = map(clonedState, conversation => ({ ...conversation, unread: 0 }));
		const updates = mapKeys(conversations, 'conversationId');

		return { ...state, ...updates };
	} else if(action.type === 'REMOVE_CONVERSATIONS') {
		return CONVERSATIONS_DATA_INITIAL_STATE;
	}

	return state;
}

/* ----------  Exports  ---------- */

export default ReducerConversationsData;