/* ----------  Imports  ---------- */

// Lodash
import { map, mapKeys, mapValues, get, indexOf } from 'lodash';

/* ----------  Scripts  ---------- */

const TOURS_DATA_INITIAL_STATE = {};

const ReducerHomeToursData = (state = TOURS_DATA_INITIAL_STATE, action) => {
	if(action.type === 'HOME_GET_TOURS') {
		const response = mapKeys(action.payload.results, 'tourId');
		return { ...state, ...response };
	} else if(action.type === 'HOME_SET_TOURS_VISIBLE') {
		const update = mapValues(state, tour => {
			if(indexOf(action.payload, tour.tourId) !== -1) return { ...tour, visible: true };

			return { ...tour, visible: false };
		});

		return { ...state, ...update };
	} else if(action.type === 'HOME_SET_TOUR_ACTIVE') {
		const { tourId, active } = action.payload;
		const tour = get(state, tourId);
		const update = { ...tour, active };

		return { ...state, [tourId]: update };
	} else if(action.type === 'HOME_SET_TOURS_UNACTIVE') {
		const update = mapValues(state, tour => {
			if(tour.active) return { ...tour, active: false };

			return tour;
		});

		return { ...state, ...update };
	} else if(action.type === 'HOME_SET_TOUR_HOVERED') {
		const { tourId, hovered } = action.payload;
		const tour = get(state, tourId);
		const update = { ...tour, hovered };

		return { ...state, [tourId]: update };
	} else if(action.type === 'HOME_SET_TOURS_UNHOVERED') {
		const update = mapValues(state, tour => {
			if(tour.hovered) return { ...tour, hovered: false };

			return tour;
		});

		return { ...state, ...update };
	} else if(action.type === 'HOME_SET_TOUR_FAVORITE') {
		const { tourId } = action.payload;
		const tour = get(state, tourId);
		const update = { ...tour, favorite: true };

		return { ...state, [tourId]: update };
	} else if(action.type === 'HOME_SET_TOUR_UNFAVORITE') {
		const { tourId } = action.payload;
		const tour = get(state, tourId);
		const update = { ...tour, favorite: false };
		
		return { ...state, [tourId]: update };
	} else if((action.type === 'HOME_TOUR_SUBSCRIBE') || (action.type === 'HOME_TOUR_UNSUBSCRIBE')) {
		const { tourIds, subscribed } = action.payload;

		const tours = map(tourIds, tourId => {
			const tour = get(state, tourId);

			return { ...tour, isSubscribed: subscribed };
		});

		const response = mapKeys(tours, 'tourId');

		return { ...state, ...response };
	} else if(action.type === 'HOME_REMOVE_TOURS') {
		return TOURS_DATA_INITIAL_STATE;
	}

	return state;
}

/* ----------  Exports  ---------- */

export default ReducerHomeToursData;