/* ----------  Scripts  ---------- */

const OTHER_LEGS_INITIAL_STATE = [];

const ReducerMarketplaceOtherLegs = (state = OTHER_LEGS_INITIAL_STATE, action) => {
	if(action.type === 'MARKETPLACE_GET_OTHER_LEGS') {
		return [ ...state, ...action.payload.results ];
	} else if(action.type === 'MARKETPLACE_REMOVE_OTHER_LEGS') {
		return OTHER_LEGS_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMarketplaceOtherLegs;