/* ----------  Imports  ---------- */

// Prop Types
import PropTypes from "prop-types";

// Lodash
import { filter } from 'lodash';

// jQuery
import $ from 'jquery';

// jsTree
import 'jstree';

// Tree
import Tree from './Tree';

/* ----------  Scripts  ---------- */

class PublishTree extends Tree {
	constructor(props) {
		super(props);

		this.state = {
			nodes: [],
			action: '',
			loaded: false,
			dirMove: false,
		}
	}

	componentDidMount() {
		this.load();
	}

	checkIsLoaded = () => this.state.loaded

	load = () => {
		const tree = this.tree.current;

		$(tree).jstree({
			core: {
				dblclick_toggle : false,
				check_callback: (operation, node, parent, position, more) => {
					if(operation === 'copy_node') return false;
					if(operation === 'move_node') {
						let isValid = true;

						if(more) {
							if(more.dnd && more.pos !== 'i') isValid = false;
							if(more.dnd && node.li_attr.type !== 50) isValid = false;
						}

						return isValid;
					}

					return true;
				}
			},

			dnd: {
				is_draggable: nodes => {
					const node = nodes[0];
					if(node.li_attr && node.li_attr.type !== 50) return false;
					return true;
				}
			},

			plugins: ['dnd'],
		});

		$(tree).on('ready.jstree', () => {
			this.api = $(tree).jstree(true);

			this.renderNodes();
			this.handleTreeActions();
		});

		$(tree).off('move_node.jstree').on('move_node.jstree', (e, data) => {
			const { position, node, parent } = data;
			const parentNode = this.getNode(parent);

			if(parent !== data.old_parent) {
				this.setState({
					action: 'move'
				}, () => {
					this.setTree(data.node.id);
					this.createNodeData('move', node.id, node, parentNode, position, this.handleTree);
				});
			}
		});
	}

	handleTreeActions = () => {
		const tree = this.tree.current;

		$(tree).off('click.node').on('click.node', '.jsTreeAnchor', e => {
			e.preventDefault();

			const { tourId } = this.props;

			if(tourId) {
				const $node = $(e.currentTarget);
				const $tree = $(tree);
				const $parent = $node.closest('li');
				const $nodes = $('li', $tree).not($parent);
				const nodeId = $parent.data('id');
				const type = parseInt($parent.attr('type'), 10);
	
				if(type !== 50) {
					if(!$parent.hasClass('jstree-node-selected')) {
						this.handleNode(nodeId);
	
						$nodes.removeClass('jstree-node-selected');
						$parent.addClass('jstree-node-selected');
					} else {
						this.handleNode(null);
	
						$parent.removeClass('jstree-node-selected');
					}
				}
			}
		});

		$(tree).off('click.ctx').on('click.ctx', '.atn-context', e => {
			e.preventDefault();
			e.stopPropagation();

			const $atn = $(e.currentTarget);
			const ctx = $atn.data('context');
			const props = this.getCtxProps($atn);
			const node = this.getNode(props.refId);
			
			if(node && (node.li_attr.type === 50)) {
				const data = this.getData();
				const groups = filter(data, item => (item.li_attr.type !== 50) && ((node.id !== item.id) && (node.id !== item.parent) && (node.parent !== item.id)));

				props.groups = groups;
			}

			this.handleContext(ctx, props, e.currentTarget);
		});
	}

	handleNode = nodeId => {
		this.props.handleNode(nodeId);
	}

	handleContext = (type, props, target, event) => this.props.handleContext(type, props, target, event)

	moveNodeDir = (nodeId, position, callback) => {
		console.log(nodeId, position);
		const node = this.getNode(nodeId);
		this.api.move_node(node, node.parent, position, callback);
	}

	unpublishTour = nodeId => {
		this.api.delete_node(nodeId);
	}

	updateSelected = groupId => {
		if(groupId) {
			const tree = this.tree.current;
			const id = this.getPrefixedId(groupId);
			const node = tree.querySelector(`#${ id }`);

			node.classList.add('jstree-node-selected');
		}
	}
}

/* ----------  Prop Types  ---------- */

PublishTree.defaultProps = {
	tourId: ''
}

PublishTree.propTypes = {
	handleNode: PropTypes.func.isRequired,
	handleContext: PropTypes.func.isRequired,
	
	tourId: PropTypes.string,
}

/* ----------  Exports  ---------- */

export default PublishTree;
