/* ----------  Scripts  ---------- */

const LEGS_LICENSED_NODES_INITIAL_STATE = [];

const ReducerMyToursTreesLegsLicensedNodes = (state = LEGS_LICENSED_NODES_INITIAL_STATE, action) => {
	if(action.type === 'MYTOURS_TREES_GET_LEGS_LICENSED_NODES') {
		return action.payload.results;
	} else if(action.type === 'MYTOURS_TREES_REMOVE_LEGS_LICENSED_NODES') {
		return LEGS_LICENSED_NODES_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMyToursTreesLegsLicensedNodes;