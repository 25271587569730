/* ----------  Scripts  ---------- */

const RemoveTours = () => dispatch => (
	dispatch({
		type: 'HOME_REMOVE_TOURS',
	})
);

/* ----------  Exports  ---------- */

export default RemoveTours;