/* ----------  Imports  ---------- */

// Axios
import axios from 'axios';

// Global
import Global from './../../../Constants/Global';

/* ----------  Scripts  ---------- */

const SetLegFavorite = (data, success, fail) => dispatch => {
	// const url = `${ Global.DUMMY_API_BASE_URL }/Ny9V-gpnH`;
	const url = `${ Global.API_BASE_URL }/favorite/leg`;

	axios.post(url, { legId: data.legId }).then(response => {
		const { status } = response.data;
		if(success) success(status);
	}).catch(error => {
		const { response } = error;

		if (fail) {
			if (response) {
				fail(response.data.status, response.data.error.reasons);
			} else {
				fail(false, []);
			}
		}
		
		dispatch({
			type: 'MARKETPLACE_SET_LEG_UNFAVORITE',
			payload: data
		});
	});
	
	dispatch({
		type: 'MARKETPLACE_SET_LEG_FAVORITE',
		payload: data
	});
};

/* ----------  Exports  ---------- */

export default SetLegFavorite;