/* ----------  Imports  ---------- */

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { map, get, isEmpty, isEqual, find, remove } from 'lodash';

// Slugify
import slugify from 'slugify';

// jQuery
import $ from 'jquery';

// Google Map
import GoogleMap from './../Google/GoogleMap';

// Global
import Global from './../../Constants/Global';

// Helpers
import Ctx from './../../Helpers/Ctx';

/* ----------  Scripts  ---------- */

class TourMap extends GoogleMap {
	constructor(props) {
		super(props);

		this.bounds  = null;

		this.markers = [];
		this.markerClusterer = null;

		this.markersLoaded = false;

		this.lastDeleted = '';

		this.state = {
			loaded: false
		}
	}

	componentDidMount() {
		this.loaded = true;

		this.load(() => {
			this.renderClusterers();
			
			this.handleMapEvents();
			this.handleMarkerEvents();
			this.handleMarkerActions();
			
			this.setState({ loaded: true });
		});
	}

	componentWillMount() {
		this.getGeoLocation();
	}

	componentWillUnmount() {
		this.loaded = false;

		this.removeClusterers();
		this.removeMarkers();
	}

	componentDidUpdate(prevProps) {
		const { data, mapOptions } = this.props;
		const isDataEqual = isEqual(prevProps.data, data);
		const isMapOptionsEqual = isEqual(prevProps.mapOptions, mapOptions);

		if(this.state.loaded) {
			if(!isEmpty(data)) {
				if(!this.markersLoaded) {
					this.markersLoaded = true;
					// this.renderMarkers();
				}

				if(!isDataEqual) {
					this.setVisibleMarkers();
				}

				if(!isDataEqual || !isMapOptionsEqual) {
					this.checkMarkers(prevProps);
				}
			}
		}
	}

	getId = marker => marker.data('tour')

	getMap = () => $(`#${ this.props.id }`)

	getCategoryClass = tour => {
		const { primaryCategory, categories } = tour;
		if(isEmpty(primaryCategory)) return 'default';

		const category = find(categories, ['categoryId', primaryCategory]);
		if(isEmpty(category)) return 'default';

		return category.name ? slugify(category.name) : '';
	}

	getMarkerContent = tour => {
		switch(this.props.type) {
			case 'default': return this.getDefaultMarkerContent(tour);
			case 'marketplace': return this.getMarketplaceDefaultMarkerContent(tour);
			case 'marketplaceLicensed': return this.getMarketplaceLicensedMarkerContent(tour);
			case 'marketplaceLicensing': return this.getMarketplaceLicensingMarkerContent(tour);
			default: return this.getDefaultMarkerContent(tour);
		}
	}

	getMarkerClusterer = (markers = []) => {
		const MarkerClusterer = window.MarkerClusterer;

		const clusterStyles = [{
			url: `${ Global.ASSETS_BASE_URL }/images/themes/location/cluster.png`,
			height: 36,
			width: 36
		}];

		return new MarkerClusterer(this.map, markers, { styles: clusterStyles, maxZoom: 14, ignoreHidden: true });
	}

	getDefaultMarkerContent = tour => (`
		<div class="marker marker-${ this.getCategoryClass(tour) }" data-tour="${ tour.tourId }" data-index="${ tour.autoId }" data-marker style="z-index: 99;">
			<div class="title">${ tour.title }</div>
			<div class="marker-wrapper">
				<div class="tag ${ tour.featured ? 'd-block' : 'd-none' }"><i class="fas fa-check"></i></div>
				<div class="pin">
					<div class="image" style="background-image: url('${ tour.markerImage }');"></div>
				</div>
				<div class="marker-subscription">
					<span class="material-icons">subscriptions</span>
				</div>
				<div class="lang-flag">
					<span class="flag-icon flag-icon-squared flag-icon-${ this.getMarkerFlag(tour.language) }"></span>
				</div>
				<div class="marker-actions">
					<ul class="list-unstyled">
						<li><a href="#" data-playback><img src="${ Global.ASSETS_BASE_URL }/icons/map/flag-map-marker.svg" class="img-responsive" alt=""/></a></li>
						<li><a href="#" data-related><img src="${ Global.ASSETS_BASE_URL }/icons/map/link.svg" class="img-responsive" alt=""/></a></li>
						<li><a href="#" data-share><img src="${ Global.ASSETS_BASE_URL }/icons/map/share.svg" class="img-responsive" alt=""/></a></li>
					</ul>
				</div>
			</div>
		</div>
	`)

	getMarketplaceDefaultMarkerContent = tour => (`
		<div class="marker marker-marketplace marker-${ this.getCategoryClass(tour) }" data-tour="${ tour.tourId }" data-index="${ tour.autoId }" data-marker style="z-index: 99;">
			<div class="title">${ tour.title }</div>
			<div class="marker-wrapper">
				<div class="tag ${ tour.featured ? 'd-block' : 'd-none' }"><i class="fas fa-check"></i></div>
				<div class="pin">
					<div class="image" style="background-image: url('${ tour.markerImage }');"></div>
				</div>
				<div class="lang-flag">
					<span class="flag-icon flag-icon-squared flag-icon-${ this.getMarkerFlag(tour.language) }"></span>
				</div>
				<div class="marker-actions">
					<ul class="list-unstyled">
						<li><a href="#" data-playback><img src="${ Global.ASSETS_BASE_URL }/icons/map/flag-map-marker.svg" class="img-responsive" alt=""/></a></li>
						<li><a href="#" data-share><img src="${ Global.ASSETS_BASE_URL }/icons/map/share.svg" class="img-responsive" alt=""/></a></li>
					</ul>
				</div>
			</div>
		</div>
	`)

	getMarketplaceLicensedMarkerContent = tour => (`
		<div class="marker marker-${ this.getCategoryClass(tour) }" data-tour="${ tour.tourId }" data-index="${ tour.autoId }" data-marker style="z-index: 99;">
			<div class="title">${ tour.title }</div>
			<div class="marker-wrapper">
				<div class="tag ${ tour.featured ? 'd-block' : 'd-none' }"><i class="fas fa-check"></i></div>
				<div class="pin">
					<div class="image" style="background-image: url('${ tour.markerImage }');"></div>
				</div>
				<div class="lang-flag">
					<span class="flag-icon flag-icon-squared flag-icon-${ this.getMarkerFlag(tour.language) }"></span>
				</div>
			</div>
		</div>
	`)

	getMarketplaceLicensingMarkerContent = tour => (`
		<div class="marker marker-licensing marker-${ this.getCategoryClass(tour) }" data-tour="${ tour.tourId }" data-index="${ tour.autoId }" data-marker style="z-index: 99;">
			<div class="title">${ tour.title }</div>
			<div class="marker-wrapper">
				<div class="tag ${ tour.featured ? 'd-block' : 'd-none' }"><i class="fas fa-check"></i></div>
				<div class="pin">
					<div class="image" style="background-image: url('${ tour.markerImage }');"></div>
				</div>
				<span class="atn-publish" data-publish>
					<i class="material-icons">publish</i>
				</span>
				<div class="marker-price" data-price>
					<span>$${ tour.price }</span>
				</div>
				<div class="lang-flag">
					<span class="flag-icon flag-icon-squared flag-icon-${ this.getMarkerFlag(tour.language) }"></span>
				</div>
				<div class="marker-actions">
					<ul class="list-unstyled">
						<li><a href="#" data-playback><img src="${ Global.ASSETS_BASE_URL }/icons/map/flag-map-marker.svg" class="img-responsive" alt=""/></a></li>
					</ul>
				</div>
			</div>
		</div>
	`)

	getMarkerFlag = lang => {
		switch(lang) {
			case 'en': return 'us';
			default: return lang;
		}
	}

	getVisibleMarkers = () => {
		const visibleMarkers = [];
		const mapBounds = this.map.getBounds();

		if(mapBounds && this.markers.length) {
			map(this.markers, marker => {
				const position = marker.getPosition();
				const isVisible = mapBounds.contains(position);

				if(isVisible) {
					marker.setVisible(true);
					visibleMarkers.push(marker.tourId);
				} else {
					marker.setVisible(false);
				}
			});
		}

		return visibleMarkers;
	}

	setTourActive = tourId => {
		this.props.setTourActive(tourId);
	}

	setVisibleMarkers = () => {
		const visibleMarkers = this.getVisibleMarkers();
		this.props.setToursVisible(visibleMarkers);
	}

	checkMarkers = () => {
		const { data } = this.props;
		map(data, tour => this.checkMarker(tour));
	}

	checkMarker = tour => {
		const { mapOptions }  = this.props;

		const $map = this.getMap();
		const $marker = $(`[data-tour="${ tour.tourId }"]`, $map);

		if(tour.active) {
			$marker.addClass('active').css('zIndex', 111);
		} else {
			$marker.removeClass('active').css('zIndex', 99);
		}

		if(tour.hovered) {
			$marker.addClass('hover-state').css('zIndex', 112);
		} else {
			$marker.removeClass('hover-state').css('zIndex', 99);
		}

		if(tour.isSubscribed) {
			$marker.addClass('marker-subscribed');
		} else {
			$marker.removeClass('marker-subscribed');
		}

		if(tour.published) {
			$marker.addClass('marker-published');
		} else {
			$marker.removeClass('marker-published');
		}

		if(mapOptions) {
			if(mapOptions.priceTag) {
				$('[data-price]', $marker).show();
			} else {
				$('[data-price]', $marker).hide();
			}

			if(mapOptions.publishToggle) {
				$('[data-publish]', $marker).show();
			} else {
				$('[data-publish]', $marker).hide();
			}
		}
	}

	handleAutoZoom = () => {
		if(this.markers.length) {
			this.bounds  = new this.google.LatLngBounds();

			map(this.markers, marker => {
				const lat = !Number.isNaN(marker.getPosition().lat()) ? parseFloat(marker.getPosition().lat()) : null;
				const lng = !Number.isNaN(marker.getPosition().lng()) ? parseFloat(marker.getPosition().lng()) : null;
				if(!lat || !lng) return;

				this.bounds.extend(marker.getPosition());
			});

			this.map.fitBounds(this.bounds);
		}
	}

	handleMarkerEvents = () => {
		const $map = this.getMap();

		$map.off('click.marker').on('click.marker', '[data-marker]', e => {
			e.preventDefault();
			e.stopPropagation();

			const $marker = $(e.currentTarget);
			const tourId = this.getId($marker);

			this.props.handleTour(tourId);
		});

		$map.off('mouseenter.marker').on('mouseenter.marker', '[data-marker]', e => {
			const $marker = $(e.currentTarget);
			const tourId = this.getId($marker);

			this.props.setTourHovered(tourId, 'marker');
		});

		$map.off('mouseleave.marker').on('mouseleave.marker', '[data-marker]', () => {
			this.props.setToursUnHovered();
		});
	}

	handleMarkerActions = () => {
		const $map = this.getMap();
		const getMarker = atn => atn.closest('[data-marker]');

		$map.off('click.playback').on('click.playback', '[data-playback]', e => {
			e.preventDefault();
			e.stopPropagation();

			const $atn = $(e.currentTarget);
			const $marker = getMarker($atn);
			const tourId = this.getId($marker);

			this.playTour(tourId);
		});

		$map.off('click.related').on('click.related', '[data-related]', e => {
			e.preventDefault();
			e.stopPropagation();

			const { showRelated } = this.props;

			const $atn = $(e.currentTarget);
			const $marker = getMarker($atn);
			const tourId = this.getId($marker);

			this.setTourActive(tourId);
			
			if(showRelated) showRelated(tourId);
		});

		$map.off('click.share').on('click.share', '[data-share]', e => {
			e.preventDefault();
			e.stopPropagation();

			// const $atn = $(e.currentTarget);
			// const $marker = getMarker($atn);
			// const tourId = this.getId($marker);
		});

		$map.off('click.publish').on('click.publish', '[data-publish]', e => {
			e.preventDefault();
			e.stopPropagation();

			const { data, publish } = this.props;

			const $atn = $(e.currentTarget);
			const $marker = getMarker($atn);
			const tourId = this.getId($marker);
			const tour = get(data, tourId);

			if(publish && tour) publish([tourId], !tour.published);
		});
	}

	handleMapEvents = () => {
		const { reset, mapIdle } = this.props;

		this.google.event.addListener(this.map, 'click', e => {
			e.stop();
			e.cancelBubble = true;
			
			if (e.stopPropagation) {
				e.stopPropagation();
			}
			
			if (e.preventDefault) {
				e.preventDefault(); 
			} else {
				e.returnValue = false;  
			}

			Ctx.close();

			reset();
		});

		this.map.addListener('idle', () => {
			Ctx.close();

			this.setVisibleMarkers();

			if(mapIdle) mapIdle(this.getCenter());
		});
	}

	playTour = tourId => {
		const { playTour } = this.props;

		if(playTour) {
			playTour(tourId);
			this.setTourActive(tourId);
		}
	}

	reset = () => {
		this.props.reset();
	}

	removeMarker = tourId => {
		const marker = find(this.markers, ['tourId', tourId]);

		if(marker) {
			marker.setMap(null);
			remove(this.markers, marker);
		}
	}

	removeMarkers = () => {
		if(this.markers.length) {
			map(this.markers, marker => {
				marker.setMap(null);
			});

			this.markers = [];
		}
	}

	removeClusterers = () => {
		if(this.markerClusterer && this.markerClusterer.getMarkers().length) {
			this.markerClusterer.clearMarkers();
		}
	}

	renderClusterers = () => {
		this.markerClusterer = this.getMarkerClusterer(this.markers);
	}

	renderMarkers = () => {
		this.removeMarkers();
		this.removeClusterers();

		const { data } = this.props;
		const RichMarker = window.RichMarker;

		if(!this.markerClusterer) {
			this.markerClusterer = this.getMarkerClusterer();
		}

		map(data, tour => {
			if(isEmpty(tour.position)) return;

			const lat = tour.position[0];
			const lng = tour.position[1];
			if(!lat || !lng) return;

			const marker = new RichMarker({
				position: new this.google.LatLng(lat, lng),
				map: this.map,
				flat: true,
				tourId: tour.tourId,
				draggable: false,
				content: this.getMarkerContent(tour),
			});

			this.markers.push(marker);
			if(this.markerClusterer) this.markerClusterer.addMarker(marker);

			setTimeout(() => {
				this.checkMarker(tour);
			}, 300);
		});

		this.handleAutoZoom();
	}
}

/* ----------  Prop Types  ---------- */

TourMap.defaultProps = {
	type: 'default',
	
	mapIdle: false,
	publish: false,
	playTour: false,
	mapOptions: false,
	showRelated: false,

	data: {},
}

TourMap.propTypes = {
	type: PropTypes.string.isRequired,
	
	reset: PropTypes.func.isRequired,
	handleTour: PropTypes.func.isRequired,
	setTourActive: PropTypes.func.isRequired,
	setTourHovered: PropTypes.func.isRequired,
	setToursVisible: PropTypes.func.isRequired,
	setToursUnHovered: PropTypes.func.isRequired,
	
	mapIdle: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	publish: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	playTour: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	showRelated: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	
	mapOptions: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
	
	data: PropTypes.objectOf(PropTypes.object),
}

/* ----------  Exports  ---------- */

export default TourMap;
