/* ----------  Scripts  ---------- */

const RemoveHyperlink = () => dispatch => (
	dispatch({
		type: 'MYTOURS_TREES_REMOVE_HYPERLINK',
	})
);

/* ----------  Exports  ---------- */

export default RemoveHyperlink;