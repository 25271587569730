/* ----------  Imports  ---------- */

// Lodash
import { map, sum } from 'lodash';

/* ----------  Reducer Playback  ---------- */

const TOUR_PLAYBACK_SUMMARY_INITIAL_STATE = {};

const ReducerTourPlaybackSummary = (state = TOUR_PLAYBACK_SUMMARY_INITIAL_STATE, action) => {
	if(action.type === 'GET_TOUR_PLAYBACK_SUMMARY') {
		const { payload } = action;
		const summary = payload;
		const summaryLegs = map(summary.legs, leg => {
			let frames = leg.totalFrames;
			const framesViaAudio = Math.floor(leg.audioDuration / 100);
			if(framesViaAudio > frames) frames = framesViaAudio;

			leg.frames = frames;

			return leg;
		});

		const totalFrames = map(summaryLegs, leg => leg.frames);

		summary.totalFrames = sum(totalFrames);
		summary.legs = summaryLegs;

		return { ...state, ...summary };
	} else if(action.type === 'TOUR_PLAYBACK_REMOVE_SUMMARY') {
		return TOUR_PLAYBACK_SUMMARY_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerTourPlaybackSummary;
