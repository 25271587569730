/* ----------  Scripts  ---------- */

const USER_STATUS_INITIAL_STATE = 30;

const ReducerUserStatus = (state = USER_STATUS_INITIAL_STATE, action) => {
	if((action.type === 'USER_GET_STATUS') || (action.type === 'USER_SET_STATUS')) {
		return action.payload;
	} else if(action.type === 'USER_REMOVE_STATUS') {
		return USER_STATUS_INITIAL_STATE;
	}

	return state;
}

/* ----------  Exports  ---------- */

export default ReducerUserStatus;