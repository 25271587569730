/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

/* ----------  Scripts  ---------- */

class PaginateItem extends React.Component {
	componentDidMount() {}

	onClick = e => {
		e.preventDefault();

		const { onClick } = this.props;

		if(onClick) onClick(e);
	}

	render() {
		const { active, index } = this.props;

		return (
			<li className={ `page-item ${ active ? 'active' : '' }` }>
				<Link to="#page" onClick={ this.onClick }>{ index + 1 }</Link>
			</li>
		);
	}
}

/* ----------  PropTypes  ---------- */

PaginateItem.defaultProps = {
	index: 0,
	active: false,
	onClick: false,
}

PaginateItem.propTypes = {
	active: PropTypes.bool,
	
	index: PropTypes.number,

	onClick: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])
}

/* ----------  Exports  ---------- */

export default PaginateItem;