/* ----------  Imports  ---------- */

// Axios
import axios from 'axios';

// Global
import Global from './../../../../../Constants/Global';

/* ----------  Scripts  ---------- */

const MoveNodeUp = (data, success, fail) => dispatch => {
	const url = `${ Global.API_BASE_URL }/trees/move/up`;

	axios.post(url, data).then(response => {
		const { status, result } = response.data;

		dispatch({
			type: 'MYTOURS_TREES_MOVE_NODE_UP',
			payload: result
		});
		
		if(success) success(status);
	}).catch(error => {
		const { response } = error;

		if(fail) {
			if(response) {
				fail(response.data.status, response.data.error.reasons);
			} else {
				fail(false, []);
			}
		}
	});
};

/* ----------  Exports  ---------- */

export default MoveNodeUp;