/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Redux
import { connect } from 'react-redux';

// Redux
import { bindActionCreators } from 'redux';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { debounce, map, filter, get } from 'lodash';

// UIkit
import UIkit from 'uikit';

// jQuery
// import $ from 'jquery';

// Preloader
import Preloader from './../../Components/Common/Preloader';

// Utils
import Scrollbar from './../../Components/Utils/Scrollbar';
import ProfilePicture from './../../Components/Utils/ProfilePicture';

// Actions
import GetSubscriptions from './../../Redux/Actions/Subscriptions/GetSubscriptions';
import RemoveSubscriptions from './../../Redux/Actions/Subscriptions/RemoveSubscriptions';
import MarkSubscriptionRead from './../../Redux/Actions/Subscriptions/MarkSubscriptionRead';
import MarkAllSubscriptionsRead from './../../Redux/Actions/Subscriptions/MarkAllSubscriptionsRead';
import GetUnreadSubscriptionsCount from './../../Redux/Actions/Subscriptions/GetUnreadSubscriptionsCount';

// Notifications
import notifications from './../../EventEmitters/Notifications';

/* ----------  Scripts  ---------- */

class Subscriptions extends React.Component {
	constructor(props) {
		super(props);

		this.loaded = false;

		this.cntSubscriptionsRef = React.createRef();
		this.subscriptionsLegsNotifsRef = React.createRef();

		this.state = {
			type: 0,
			title: '',
			active: false,
			listing: false,
			loading: false,
		}

		this.requestSubscriptions = debounce(this.doRequestSubscriptions, 100);
		
		this.requestUnreadCount = debounce(this.doRequestUnreadCount, 100);
		
		this.markSubscriptionRead = debounce(this.doMarkSubscriptionRead, 100);
		this.markAllSubscriptionsRead = debounce(this.doMarkAllSubscriptionsRead, 100);
	}

	componentWillMount() {
		this.getUnreadCount();
	}

	componentDidMount() {
		this.loaded = true;

		this.initNotifications();
		this.initDropdown();
	}

	componentWillUnmount() {
		this.loaded = false;
	}

	onBack = e => {
		e.preventDefault();

		this.hideSubscriptionsList();
	}

	onSubscriptionsClick = e => {
		e.preventDefault();
	}

	onAllSubscriptionsDismiss = e => {
		e.preventDefault();
		e.stopPropagation();

		this.markAllSubscriptionsRead();
	}

	onSubscriptionDismiss = (e, subscriptionId) => {
		e.preventDefault();
		e.stopPropagation();

		this.markSubscriptionRead(subscriptionId);
	}

	onSubscriptionsTypeClick = (e, type) => {
		e.preventDefault();

		this.getSubscriptions(type);
	}

	onSubscriptionItemClick = (e, subscriptionId) => {
		this.markSubscriptionRead(subscriptionId);
	}

	getSubscriptions = nodeType => {
		this.requestSubscriptions(nodeType);
	}

	getUnreadCount = () => {
		this.requestUnreadCount();
	}

	getSubscriberLink = userId => {
		const { type } = this.state;
		let slug = 'home';

		switch(type) {
			case 50: slug = 'home'; break;
			case 60: slug = 'marketplace'; break;
			default: slug = '404'; break;
		}

		return `/${ slug }?userId=${ userId }`;
	}

	doRequestUnreadCount = () => {
		this.props.getUnreadCount();
	}

	doRequestSubscriptions = nodeType => {
		this.removeSubscriptions();

		const params = {
			term: '',
			nodeType
		}

		const title = nodeType === 60 ? 'Legs' : 'Tours';

		if(this.loaded) {
			this.setState({
				title,
				loading: true,
				listing: false,
				type: nodeType,
			});
		}

		this.props.getSubscriptions(params, () => {
			this.showSubscriptionsList();
		}, () => {
			this.hideSubscriptionsList();
		});
	}

	doMarkSubscriptionRead = subscriptionId => {
		const data = {
			subscriptionId
		}

		this.props.markSubscriptionRead(data, () => {
			this.getUnreadCount();
		});
	}

	doMarkAllSubscriptionsRead = () => {
		this.props.markAllSubscriptionsRead(() => {
			this.getUnreadCount();
		});
	}

	initDropdown = () => {
		const notifs = this.cntSubscriptionsRef.current;

		const drp = UIkit.dropdown(notifs, {
			mode: 'hover',
			pos: 'bottom-left',
		});

		drp.on({
			'beforeshow.uk.dropdown': () => {
				if(this.loaded) this.setState({ active: true });
				this.hideSubscriptionsList();
			},

			'hide.uk.dropdown': () => {
				if(this.loaded) this.setState({ active: false });
			}
		});
	}

	initNotifications = () => {
		notifications.on('refreshCount', () => {
			this.getUnreadCount();
		});
	}

	hideSubscriptionsList = () => {
		if(this.loaded) {
			this.setState({
				loading: false,
				listing: false,
			}, this.removeSubscriptions);
		}
	}

	showSubscriptionsList = () => {
		if(this.loaded) {
			this.setState({
				loading: false,
				listing: true,
			});
		}
	}

	removeSubscriptions = () => {
		this.props.removeSubscriptions();
	}

	renderNotificationListItems = () => {
		const { list, listData } = this.props;
		const unreadList = filter(listData, 'unread');

		if(!list.length || !unreadList.length) return <li className="text-center p-t-10 m-0 uk-text-small"><em className="uk-text-muted">Everything caughtup!</em></li>;

		return map(list, item => {
			const subscription = get(listData, item.subscriptionId);
			const link = this.getSubscriberLink(subscription.userId);

			if(!subscription.unread) return <li className="d-none" key={ subscription.subscriptionId }/>;

			return (
				<li className="m-r-0" key={ subscription.subscriptionId }>
					<div className="md-list-addon-element">
						<ProfilePicture className="md-subscription-image md-list-addon-avatar img-circle" title={ subscription.name } src={ subscription.photo }/>
					</div>
					<div className="md-list-content">
						<Link to={ link } className="md-list-heading text-dark" onClick={ e => this.onSubscriptionItemClick(e, subscription.subscriptionId) }>{ subscription.name } ({ subscription.unread })</Link>
						<span className="uk-text-small uk-text-muted">{ subscription.meta.subscribers } Subscribers</span>
					</div>
					<Link to="#close" className="md-list-addon-element md-atn-remove" onClick={ e => this.onSubscriptionDismiss(e, subscription.subscriptionId) }>
						<i className="md-list-addon-icon material-icons uk-text-muted">close</i>
					</Link>
				</li>
			);
		});
	}

	renderNotificationList = () => {
		const { list } = this.props;
		const { title } = this.state;

		return (
			<React.Fragment>
				<div className="md-card-toolbar">
					<div className="md-card-toolbar-actions">
						<Link to="#all" className="md-icon material-icons md-card-toggle" onClick={ this.onAllSubscriptionsDismiss }>done_all</Link>
					</div>
					<div className="md-card-toolbar-actions pull-left m-r-10">
						<Link to="#back" className="md-icon material-icons md-card-close" onClick={ this.onBack }>arrow_back</Link>
					</div>
					<h3 className="md-card-toolbar-heading-text">{ title } Subscriptions List</h3>
				</div>
				<Scrollbar className="md-card-content">
					<div className="md-card-content-inner">
						<div className="content-list notification-list" data-items={ list.length }>
							<ul className="md-list md-list-addon">
								{ this.renderNotificationListItems() }
							</ul>
						</div>
					</div>
				</Scrollbar>
			</React.Fragment>
		);
	}

	renderBaseList = () => {
		const { unreadCount } = this.props;

		return (
			<Scrollbar className="md-card-content">
				<div className="md-card-content-inner">
					<div className="content-list base-list">
						<ul className="md-list md-list-addon m-0">
							<li className="m-r-0">
								<Link to="#legs" className="d-block" onClick={ e => this.onSubscriptionsTypeClick(e, 50) }>
									<div className="md-list-addon-element">
										<i className="md-list-addon-icon material-icons m-0">map</i>
									</div>
									<div className="md-list-content">
										<span className="md-list-heading text-dark">Tour Subscription ({ unreadCount.tours })</span>
										<span className="uk-text-small uk-text-muted">Your tour subscriptions updates.</span>
									</div>
								</Link>
							</li>
							<li className="m-r-0">
								<Link to="#legs" className="d-block" onClick={ e => this.onSubscriptionsTypeClick(e, 60) }>
									<div className="md-list-addon-element">
										<i className="md-list-addon-icon material-icons m-0">local_activity</i>
									</div>
									<div className="md-list-content">
										<span className="md-list-heading text-dark">Leg Subscription ({ unreadCount.legs })</span>
										<span className="uk-text-small uk-text-muted">Your leg subscriptions updates.</span>
									</div>
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</Scrollbar>
		);
	}

	renderContent = () => {
		const { loading, listing } = this.state;

		if(loading) return <Preloader loading={ loading } center minimal/>;

		if(listing) return this.renderNotificationList();

		return this.renderBaseList();
	}

	render() {
		const { unreadCount } = this.props;

		return (
			<li className="subscriptions" ref={ this.cntSubscriptionsRef }>
				<Link to="#div" className="user_action_icon" id="btnSubscriptionsNotifs">
					<i className="material-icons md-24 md-light">subscriptions</i>
					<span className={ `uk-badge bg-warning ${ !unreadCount.total ? 'd-none' : '' }` }>{ unreadCount.total }</span>
				</Link>
				<div className="uk-dropdown uk-dropdown-minimal uk-dropdown-subscriptions uk-dropdown-xlarge" id="sbscriptionsNotifs">
					<div className="md-card">
						{ this.renderContent() }
					</div>
				</div>
			</li>
		);
	}
}

/* ----------  Prop Types  ---------- */

Subscriptions.propTypes = {
	getUnreadCount: PropTypes.func.isRequired,
	
	getSubscriptions: PropTypes.func.isRequired,
	removeSubscriptions: PropTypes.func.isRequired,
	
	markSubscriptionRead: PropTypes.func.isRequired,
	markAllSubscriptionsRead: PropTypes.func.isRequired,
	
	unreadCount: PropTypes.shape().isRequired,
	
	list: PropTypes.arrayOf(PropTypes.object).isRequired,
	listData: PropTypes.objectOf(PropTypes.object).isRequired,
}

/* ----------  Redux Scripts  ---------- */

const mapStateToProps = state => ({
	list: state.subscriptions.list,
	listData: state.subscriptions.listData,

	unreadCount: state.subscriptions.unreadCount,
});

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		getUnreadCount: GetUnreadSubscriptionsCount,

		getSubscriptions: GetSubscriptions,
		removeSubscriptions: RemoveSubscriptions,
		
		markSubscriptionRead: MarkSubscriptionRead,
		markAllSubscriptionsRead: MarkAllSubscriptionsRead,
	}, dispatch)
);

/* ----------  Exports  ---------- */

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);