/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Redux
import { connect } from 'react-redux';

// Redux
import { bindActionCreators } from 'redux';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { debounce, map, isEmpty, get, first } from 'lodash';

// jQuery
// import $ from 'jquery';

// Constants
// import Global from './../../Constants/Global';

// Preloader
import Preloader from './../../Components/Common/Preloader';

// Components
import Input from './../../Components/UIkit/Input';

// Components
import Card from './../../Components/Profile/Card';

// Utils
import Selectize from './../../Components/Utils/Selectize';
import ProfilePicture from './../../Components/Utils/ProfilePicture';

// Languages Actions
import GetLanguages from './../../Redux/Actions/Languages/GetLanguages';

// User Actions
import GetUserProfile from './../../Redux/Actions/User/GetUserProfile';
import UpdateUserProfile from './../../Redux/Actions/User/UpdateUserProfile';
import UpdateUserPassword from './../../Redux/Actions/User/UpdateUserPassword';

// Account Info
import GetAccountInfo from '../../Redux/Actions/User/AccountInfo/GetAccountInfo';
import UpdateAccountInfo from '../../Redux/Actions/User/AccountInfo/UpdateAccountInfo';
import CreateAccountInfo from './../../Redux/Actions/User/AccountInfo/CreateAccountInfo';
import GetAccountInfoFields from '../../Redux/Actions/User/AccountInfo/GetAccountInfoFields';

// Credit Card Actions
import GetCreditCards from './../../Redux/Actions/User/CreditCard/GetCreditCards';
import AddCreditCard from './../../Redux/Actions/User/CreditCard/AddCreditCard';
import RemoveCreditCard from './../../Redux/Actions/User/CreditCard/RemoveCreditCard';
import RemoveCreditCards from './../../Redux/Actions/User/CreditCard/RemoveCreditCards';
import SetDefaultCreditCard from '../../Redux/Actions/User/CreditCard/SetDefaultCreditCard';

// Debit Card Actions
import AddDebitCard from './../../Redux/Actions/User/DebitCard/AddDebitCard';
import GetDebitCards from './../../Redux/Actions/User/DebitCard/GetDebitCards';
import RemoveDebitCard from '../../Redux/Actions/User/DebitCard/RemoveDebitCard';
import RemoveDebitCards from './../../Redux/Actions/User/DebitCard/RemoveDebitCards';
import SetDefaultDebitCard from '../../Redux/Actions/User/DebitCard/SetDefaultDebitCard';

// Subscription Actions
import GetSubscriptionPlans from './../../Redux/Actions/User/Subscription/GetSubscriptionPlans';
import RemoveSubscriptionPlans from './../../Redux/Actions/User/Subscription/RemoveSubscriptionPlans';
import SubscribePlan from './../../Redux/Actions/User/Subscription/SubscribePlan';
import CancelSubscription from './../../Redux/Actions/User/Subscription/CancelSubscription';

// Attachments
import AddAttachment from './../../Redux/Actions/Attachment/AddAttachment';

// Countries Actions
import GetCountries from './../../Redux/Actions/Countries/GetCountries';

// Currencies Actions
import GetCurrencies from './../../Redux/Actions/Currencies/GetCurrencies';

// UIKit
import Alerts from './../../Components/UIkit/Alerts';

// Helpers
import Notify from './../../Helpers/Notify';
import InputUI from './../../Helpers/InputUI';
import InputHelper from './../../Helpers/InputHelper';

/* ----------  Scripts  ---------- */

class ProfileEdit extends React.Component {
	constructor(props) {
		super(props);

		this.cntProfileEditRef = React.createRef();
		this.subscriptionModalRef = React.createRef();

		this.state = {
			loading: false,
			touched: false,
			formErrors: [],
			uploading: false,
			paymentsLoading: false,
			debitCardsLoading: false,

			subscriptionPlan: null,
			subscriptionErrors: [],
			subscriptionBusy: false,
			subscriptionPlansLoading: false,
		}

		this.requestProfile = debounce(this.doRequestProfile, 100);
		this.requestCountries = debounce(this.doRequestCountries, 100);
		this.requestCurrencies = debounce(this.doRequestCurrencies, 100);
		this.requestCreditCards = debounce(this.doRequestCreditCards, 100);
		this.requestDebitCards = debounce(this.doRequestDebitCards, 100);
		
		this.getSubscriptionPlans = debounce(this.doRequestSubscriptionPlans, 100);
		this.requestSubscribePlan = debounce(this.doRequestSubscribePlan, 100);
		this.requestCancelSubscription = debounce(this.doRequestCancelSubscription, 100);
		
		this.getAccountInfoFields = debounce(this.doRequestAccountInfoFields, 100);
		this.getAccountInfo = debounce(this.doRequestAccountInfo, 100);
		this.saveAccountInfo = debounce(this.doSaveAccountInfo, 100);
		
		this.addCreditCard = debounce(this.doAddCreditCard, 100);
		this.addDebitCard = debounce(this.doAddDebitCard, 100);
	}

	componentWillMount() {
		this.getCountries();
		this.getLanguages();
		this.getCurrencies();

		this.getProfile();
		this.getSubscriptionPlans();
		this.getDebitCards();
		this.getCreditCards();
	}

	onSubscribe = e => {
		e.preventDefault();

		this.subscribePlan();
	}

	onUnsubscribe = e => {
		e.preventDefault();

		this.cancelSubscription();
	}

	getCountries = () => {
		this.requestCountries();
	}

	getCountriesData = () => this.props.countries

	getProfile = () => {
		this.setState({
			loading: true,
		}, this.requestProfile);
	}

	getCreditCards = () => {
		this.setState({
			paymentsLoading: true,
		}, this.requestCreditCards);
	}
	
	getDebitCards = () => {
		this.setState({
			debitCardsLoading: true,
		}, this.requestDebitCards);
	}

	getLanguages = () => {
		this.props.getLanguages();
	}

	getCurrencies = () => {
		this.requestCurrencies();
	}

	getSubcriptionPlansOptions = () => {
		const { subscriptionPlans } = this.props;

		const data = map(subscriptionPlans, plan => {
			const help = `${ plan.currency } / ${ plan.interval }`
			const option = {
				help,

				label: plan.name,
				value: plan.planId,
			}

			return option;
		});

		return data;
	}

	getLanguageOptions = () => {
		const data = [];
		const { languages } = this.props;

		if(!isEmpty(languages)) {
			map(languages, language => {
				const option = {
					label: language.name,
					value: language.code
				}

				data.push(option);
			});
		}

		return data;
	}

	getCurrencyOptions = () => {
		const data = [];
		const { currencies } = this.props;

		if(!isEmpty(currencies)) {
			map(currencies, currency => {
				const option = {
					label: currency.currency,
					help: currency.country,
					value: currency.currency,
				}

				data.push(option);
			});
		}

		return data;
	}

	getCountriesOptions = () => {
		let data = [];
		const { countries } = this.props;

		if(!isEmpty(countries)) {
			data = map(countries, country => ({
				label: country.name,
				value: country.code
			}))
		}

		return data;
	}

	setDefaultCreditCard = cardId => {
		this.setState({ paymentsLoading: true });
		this.props.setDefaultCreditCard({ cardId }, () => {
			this.setState({ paymentsLoading: false });
			Notify.success('Payment set as default!');
		}, () => {
			this.setState({ paymentsLoading: false });
			Notify.error('Please try again.');
		});
	}

	setDefaultDebitCard = cardId => {
		this.setState({ debitCardsLoading: true });
		this.props.setDefaultDebitCard({ cardId }, () => {
			this.setState({ debitCardsLoading: false });
			Notify.success('Debit card set as default!');
		}, () => {
			this.setState({ debitCardsLoading: false });
			Notify.error('Please try again.');
		});
	}

	doRequestProfile = () => {
		this.props.getUserProfile(() => {
			const { user: { profile } } = this.props;

			const activePlan = profile.activePlan && profile.activePlan.length ? first(profile.activePlan) : null;

			this.setState({
				subscriptionPlan: activePlan ? activePlan.planId : null,
			});
			
			const updates = {
				userId: profile.userId,
				name: profile.name,
				photo: profile.photo,
				slogan: profile.slogan,
				about: profile.about,
				email: profile.email,
				phone: profile.phone,
				address: profile.address,
				country: profile.country,
				currency: profile.currency,
				phoneAlt: profile.phoneAlt,

				social: {
					twitter: profile.social.twitter,
					facebook: profile.social.facebook,
					linkedin: profile.social.linkedin,
					googlePlus: profile.social.googlePlus,
				},

				languages: {
					primary: profile.languages.primary,
					secondary: profile.languages.secondary
				}
			}

			this.setState({ loading: false });
			this.updateFormState(updates, this.updateInputsUI);
		});
	}

	doRequestSubscriptionPlans = () => {
		this.removeSubscriptionPlans();

		this.setState({ subscriptionPlansLoading: true });
		this.props.getSubscriptionPlans(() => {
			this.setState({ subscriptionPlansLoading: false });
		});
	}

	doRequestCreditCards = () => {
		this.removeCreditCards();
		this.props.getCreditCards(() => {
			this.setState({ paymentsLoading: false });
		});
	}
	
	doRequestDebitCards = () => {
		this.removeDebitCards();
		this.props.getDebitCards(() => {
			this.setState({ debitCardsLoading: false });
		});
	}

	doRequestAccountInfoFields = (success, fail) => {
		const { data } = this.props;

		const params = {
			country: data.country
		}

		this.props.getAccountInfoFields(params, success, fail);
	}
	
	doRequestAccountInfo = (success, fail) => {
		this.props.getAccountInfo(success, fail);
	}
	
	doSaveAccountInfo = (data, success, fail) => {
		const { user: { profile } } = this.props;

		if(profile.is_attach_connected_account) {
			this.props.updateAccountInfo(data, success, fail);
		} else {
			this.props.createAccountInfo(data, success, fail);
		}
	}

	doRequestCountries = () => {
		this.props.getCountries();
	}

	doRequestCurrencies = () => {
		this.props.getCurrencies();
	}

	doRequestSubscribePlan = () => {
		this.setState({ subscriptionBusy: true });

		const { subscriptionPlan } = this.state;
		const data = {
			plan_id: subscriptionPlan
		}

		this.props.subscribePlan(data, () => {
			this.setState({ subscriptionBusy: false });
			Notify.success('Voila! now you\'re our elite user. Thank You :)');
		}, () => {
			this.setState({ subscriptionBusy: false });
			Notify.error('Please try again.');
		});
	}

	doRequestCancelSubscription = () => {
		this.setState({ subscriptionBusy: true });

		const { subscriptionPlan } = this.state;
		const data = {
			plan_id: subscriptionPlan
		}

		this.props.cancelSubscription(data, () => {
			this.setState({
				subscriptionBusy: false,
				subscriptionPlan: null,
			});
			Notify.info('Sorry to see you go! :(');
		}, () => {
			this.setState({ subscriptionBusy: false });
			Notify.error('Please try again.');
		});
	}

	doAddCreditCard = (data, success, fail) => {
		this.props.addCreditCard(data, status => {
			if(status) {
				Notify.success('Card added successfully!');
				this.getCreditCards();
			} else {
				Notify.error('Oops! Please try again.');
			}
			
			if(success) success();
		}, (status, reasons) => {
			if(fail) fail(reasons);
		});
	}
	
	doAddDebitCard = (data, success, fail) => {
		this.props.addDebitCard(data, status => {
			if(status) {
				Notify.success('Card added successfully!');
				this.getDebitCards();
			} else {
				Notify.error('Oops! Please try again.');
			}
			
			if(success) success();
		}, (status, reasons) => {
			if(fail) fail(reasons);
		});
	}

	subscribePlan = () => {
		this.requestSubscribePlan();
	}

	cancelSubscription = () => {
		this.requestCancelSubscription();
	}

	handlePhotoUpload = e => {
		const { files } = e.target;

		if(!files.length) return false;

		const file = files[0];
		const formData = new FormData();

		formData.append('file', file);
		formData.set('type', file.type);

		this.setState({ uploading: true });

		this.props.addAttachment(formData, (status, response) => {
			this.setState({ uploading: false });
			this.updateFormState({ photo: response.url });
		}, () => {
			this.setState({ uploading: false });
			Notify.success('Please try again :(');
		});

		return true;
	}

	handleInput = e => {
		const { name, value } = e.currentTarget;
		this.updateInput(name, value);
	}

	handleSubscriptionPlan = (name, value) => {
		this.setState({ subscriptionPlan: value });
	}

	handleSocialInput = e => {
		const { name, value } = e.currentTarget;
		this.updateSocialInput(name, value);
	}

	handleAddCreditCard = e => {
		e.preventDefault();
		this.props.handleAddCreditCard();
	}
	
	handleAddAccountInfo = e => {
		e.preventDefault();
		this.props.handleAddAccountInfo();
	}
	
	handleAddDebitCard = e => {
		e.preventDefault();
		this.props.handleAddDebitCard();
	}

	handlePasswordModal = e => {
		e.preventDefault();
		this.props.handlePasswordModal();
	}

	handleLanguages = (name, value) => {
	 	this.updateLanguages(name, value);
	}

	handleCurrency = (name, value) => {
	 	this.updateFormState({ currency: value });
	}
	
	handleCountry = (name, value) => {
	 	this.updateFormState({ country: value });
	}

	handleSubscription = e => {
		e.preventDefault();

		const modal = this.subscriptionModalRef.current;

		if(modal) modal.show();
	}

	updateInputsUI = () => {
		const cntProfileEdit = this.cntProfileEditRef.current;

		InputUI.update(cntProfileEdit);
	}

	updateInput = (name, value) => {
		const updates = {
			[name]: value
		}

		this.updateFormState(updates);
	}

	updateSocialInput = (name, value) => {
		const { data } = this.props;

		const updates = {
			social: {
				...data.social,
				[name]: value
			}
		}

		this.updateFormState(updates);
	}

	updateLanguages = (name, value) => {
		const { data } = this.props;

		const updates = {
			languages: {
				...data.languages,
				[name]: value
			}
		}

		this.updateFormState(updates);
	}

	updateFormState = (updates, callback) => {
		this.props.updateFormState(updates, callback);
	}

	updatePassword = (passwords, success, fail) => {
		const data = {
			...passwords
		}

		this.props.updateUserPassword(data, status => {
			if(status) {
				Notify.success('Password changed successfully!');
			} else {
				Notify.error('Oops! Please try again.');
			}

			if(success) success();
		}, (status, reasons) => {
			if(fail) fail(reasons);
		});
	}

	updateProfile = () => {
		const { loading } = this.state;
		const isValid = this.validateProfile();

		if(loading || !isValid) return false;

		const { data } = this.props;

		this.setState({ loading: true });

		this.props.updateUserProfile(data, status => {
			this.setState({ loading: false });

			if(status) {
				Notify.success('Profile updated successfully!');
			} else {
				Notify.error('Oops! Please try again.');
			}
		}, (status, reasons) => {
			const errors = map(reasons, reason => reason.message);

			Notify.error('Oops! Please try again.');
			this.setState({
				loading: false,
				formErrors: errors
			});
		});

		return true;
	}

	updateBtnState = update => {
		this.setState({ touched: !update });
	}

	removeCreditCard = cardId => {
		this.setState({ paymentsLoading: true });

		this.props.removeCreditCard(cardId, status => {
			this.setState({ paymentsLoading: false });

			if(status) {
				Notify.success('Payment method deleted!');
			} else {
				Notify.error('Oops! Try again.');
			}
		});
	}

	removeDebitCard = cardId => {
		this.setState({ paymentsLoading: true });

		this.props.removeDebitCard(cardId, status => {
			this.setState({ paymentsLoading: false });

			if(status) {
				Notify.success('Payment method deleted!');
			} else {
				Notify.error('Oops! Try again.');
			}
		});
	}

	removeSubscriptionPlans = () => {
		this.props.removeSubscriptionPlans();
	}

	removeCreditCards = () => {
		this.props.removeCreditCards();
	}
	
	removeDebitCards = () => {
		this.props.removeDebitCards();
	}

	validateProfile = () => {
		let isValid = true;

		const errors = [];
		const { data: { name, email, languages } } = this.props;

		if(InputHelper.isEmpty(name)) {
			isValid = false;
			errors.push('Name should consist atleast 3 characters.');
		}

		if(InputHelper.isEmpty(email) || !InputHelper.isEmail(email)) {
			isValid = false;
			errors.push('Please enter a valid email.');
		}

		if(InputHelper.isEmpty(languages.primary)) {
			isValid = false;
			errors.push('Please select primary language.');
		}

		if(!languages.secondary.length) {
			isValid = false;
			errors.push('Please select atlease 1 secondary language.');
		}

		this.setState({
			formErrors: errors
		});

		return isValid;
	}

	renderSubscriptionPlansSelect = () => {
		const { data: { userId } } = this.props;
		const { user: { profile: { featured } } } = this.props;
		const { subscriptionPlan } = this.state;
		const subscriptionPlans = this.getSubcriptionPlansOptions();

		if(isEmpty(userId) || !subscriptionPlans.length) return <div className="d-none"/>;

		return <Selectize name="subscriptionPlan" id="txtSubscriptionPlans" data={ subscriptionPlans } value={ subscriptionPlan || '' } disabled={ featured } onChange={ this.handleSubscriptionPlan }/>;
	}

	renderPrimaryLanguageSelect = () => {
		const { data: { userId, languages } } = this.props;
		const langsData = this.getLanguageOptions();

		if(isEmpty(userId) || !langsData.length) return <div className="d-none"/>;

		return <Selectize name="primary" id="txtPrimaryLanguage" data={ langsData } value={ languages ? languages.primary : '' } onChange={ this.handleLanguages }/>;
	}

	renderSecondaryLanguageSelect = () => {
		const { data: { userId, languages } } = this.props;
		const langsData = this.getLanguageOptions();

		if(isEmpty(userId) || !langsData.length) return <div className="d-none"/>;

		return <Selectize name="secondary" id="txtSecondaryLanguage" data={ langsData } value={ languages ? languages.secondary : [] } onChange={ this.handleLanguages } multiple maxItems={ 3 }/>;
	}

	renderPrimaryCurrencySelect = () => {
		const { data: { userId, currency } } = this.props;
		const currencyData = this.getCurrencyOptions();

		if(isEmpty(userId) || !currencyData.length) return <div className="d-none"/>;

		return <Selectize name="currency" id="txtPrimaryCurrency" data={ currencyData } value={ currency } onChange={ this.handleCurrency }/>;
	}

	renderCreditCards = () => {
		const { user: { creditCards } } = this.props;

		return map(creditCards, card => (
			<div className="uk-width-medium-1-2" key={ card.cardId }>
				<Card
					card={ card }
					setDefault={ this.setDefaultCreditCard }
					removeCard={ this.removeCreditCard }/>
			</div>
		));
	}
	
	renderDebitCards = () => {
		const { user: { profile, debitCards } } = this.props;

		if(!profile.is_attach_connected_account) return <div className="d-none"/>;

		return map(debitCards, card => (
			<div className="uk-width-medium-1-2" key={ card.cardId }>
				<Card
					card={ card }
					removeCard={ this.removeDebitCard }
					setDefault={ this.setDefaultDebitCard }/>
			</div>
		));
	}

	renderSubscriptionContent = () => {
		const { user: { profile: { featured } }, subscriptionPlans } = this.props;
		const { subscriptionBusy, subscriptionPlan } = this.state;

		const plan = get(subscriptionPlans, subscriptionPlan);

		if(featured) {
			return (
				<React.Fragment>
					<div className="help-block m-t-15">
						<p className="text-muted">
							<i className="material-icons m-r-5">check_circle</i>
							Active Plan: { plan ? `${ plan.name } (${ plan.currency } / ${ plan.interval })` : 'N/A' }
						</p>
					</div>
					<Link to="#password" className={ `md-btn md-btn-large md-btn-danger text-white ${ subscriptionBusy ? 'disabled' : '' }` } onClick={ this.onUnsubscribe }>
						<i className="fas fa-ban m-r-5"/> Unsubscribe
					</Link>
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				<div className="plan-selectize m-b-10">
					{ this.renderSubscriptionPlansSelect() }
				</div>
				<Link to="#password" className={ `md-btn md-btn-large md-btn-info text-white ${ subscriptionBusy ? 'disabled' : '' }` } onClick={ this.onSubscribe }>
					<i className="fas fa-award m-r-5"/> Subscribe
				</Link>
			</React.Fragment>
		);
	}

	renderCountrySelect = () => {
		const countries = this.getCountriesOptions();
		const { data: { userId, country } } = this.props;

		if(isEmpty(userId) || !countries.length) return <Input title="Country" name="countryPlaceholder" id="txtCountryPlaceholder" disabled/>;
		
		return (
			<Selectize
				name="country"
				id="txtCountries"
				data={ countries }
				value={ country || '' }
				placeholder="Select Country"
				onChange={ this.handleCountry }/>
		);
	}

	render() {
		const { data, user } = this.props;
		const { touched, loading, paymentsLoading, debitCardsLoading, formErrors, uploading } = this.state;

		return (
			<div className="md-card" ref={ this.cntProfileEditRef }>
				<div className="user_heading bg-primary" data-uk-sticky="{ top: 48, media: 960 }">
					<div className="user_heading_avatar fileinput fileinput-new" data-provides="fileinput">
						<div className="fileinput-new thumbnail uk-position-relative">
							<ProfilePicture title={ user.profile.name } className="img-circle" src={ data.photo }/>
							<Preloader loading={ uploading } center size={ 25 }/>
						</div>
						<div className={ `user_avatar_controls ${ uploading ? 'd-none' : '' }` }>
							<span className="btn-file">
								<span className="fileinput-new">
									<i className="material-icons">&#xE2C6;</i>
								</span>
								<input id="user_edit_avatar_control" name="user_edit_avatar_control" type="file" accept="image/*" onChange={ this.handlePhotoUpload }/>
							</span>
						</div>
					</div>
					<div className="user_heading_content">
						<h2 className="heading_b">
							<span className="uk-text-truncate" id="user_edit_uname">{ user.profile.name }</span>
							<span className="sub-heading" id="user_edit_position">{ user.profile.slogan }</span>
						</h2>
					</div>
					<div className="md-fab-wrapper">
						<button to="#save" className={ `md-fab md-fab-medium md-fab-accent ${ touched ? 'touched' : '' }` } disabled={ loading }>
							<Preloader color="#1976d2" minimal center loading={ loading }/>
							<i className={ `btn-icon material-icons ${ loading ? 'd-none' : 'd-block' }` }>&#xE161;</i>
						</button>
					</div>
					<Alerts show={ formErrors.length > 0 } data={ formErrors }/>
				</div>
				<div className="user_content">
					<div className="edit-content">
						<div className="uk-margin-top">
							<h3 className="full_width_in_card heading_c text-dark">General Info</h3>
							<div className="uk-grid" data-uk-grid-margin="">
								<div className="uk-width-medium-1-2">
									<Input title="User Name" name="name" id="txtName" value={ data.name } onChange={ this.handleInput }/>
								</div>
								<div className="uk-width-medium-1-2">
									<Input title="Slogan" name="slogan" id="txtSlogan" value={ data.slogan } onChange={ this.handleInput }/>
								</div>
							</div>
							<div className="uk-grid">
								<div className="uk-width-1-1">
									<Input title="About" name="about" id="txtAbout" value={ data.about } onChange={ this.handleInput } textarea/>
								</div>
							</div>
							<h3 className="full_width_in_card heading_c text-dark">Security</h3>
							<div className="uk-grid">
								<div className="uk-width-1-1">
									<label className="d-block m-b-5" htmlFor="txtPasswordBtn">Password</label>
									<p className="uk-text-muted uk-text-small m-b-10 d-none">Last changed: <strong>5th April, 2017</strong></p>
									<Link to="#password" className="md-btn md-btn-large md-btn-primary text-white" onClick={ this.handlePasswordModal }>
										<i className="material-icons m-r-5">&#xE897;</i> Change Password
									</Link>
								</div>
							</div>
							<h3 className="full_width_in_card heading_c text-dark">Subscription</h3>
							<div className="uk-grid">
								<div className="uk-width-1-1">
									<label className="d-block m-b-5" htmlFor="txtPasswordBtn">Want more subscribers?</label>
									{ this.renderSubscriptionContent() }
								</div>
							</div>
							<h3 className="full_width_in_card heading_c text-dark">Languages</h3>
							<div className="uk-grid" data-uk-grid-margin="">
								<div className="uk-width-1-1">
									<label htmlFor="txtPrimaryLanguage">Primary Language</label>
									{ this.renderPrimaryLanguageSelect() }
								</div>
							</div>
							<div className="uk-grid" data-uk-grid-margin="">
								<div className="uk-width-1-1">
									<div className="uk-width-1-1">
										<label htmlFor="txtSecondaryLanguage">Secondary Language</label>
										{ this.renderSecondaryLanguageSelect() }
									</div>
								</div>
							</div>
							<div className="help-block m-t-15">
								<p className="uk-text-small text-muted">
									<i className="material-icons m-r-5">info</i>
									Select max 3 secondary languages.
								</p>
							</div>
							<h3 className="full_width_in_card heading_c text-dark">Currency</h3>
							<div className="uk-grid" data-uk-grid-margin="">
								<div className="uk-width-1-1">
									<label htmlFor="txtPrimaryLanguage">Primary Currency</label>
									{ this.renderPrimaryCurrencySelect() }
								</div>
							</div>
							<h3 className="full_width_in_card heading_c text-dark">Contact Info</h3>
							<div className="uk-grid">
								<div className="uk-width-1-2">
									<div className="uk-input-group uk-grid-margin">
										<span className="uk-input-group-addon">
											<i className="md-list-addon-icon material-icons">&#xE158;</i>
										</span>
										<Input type="email" title="Email" name="email" id="txtEmail" value={ data.email } onChange={ this.handleInput }/>
									</div>
									<div className="uk-input-group uk-grid-margin">
										<span className="uk-input-group-addon">
											<i className="md-list-addon-icon material-icons">&#xE0CD;</i>
										</span>
										<Input title="Phone" name="phone" id="txtPhone" value={ data.phone } onChange={ this.handleInput }/>
									</div>
									<div className="uk-input-group uk-grid-margin">
										<span className="uk-input-group-addon">
											<i className="md-list-addon-icon material-icons">&#xE0CD;</i>
										</span>
										<Input title="Phone Alt." name="phoneAlt" id="txtPhoneAlt" value={ data.phoneAlt } onChange={ this.handleInput }/>
									</div>
								</div>
								<div className="uk-width-1-2">
									<div className="uk-input-group uk-grid-margin">
										<span className="uk-input-group-addon">
											<i className="md-list-addon-icon material-icons">&#xE88A;</i>
										</span>
										<Input title="Address" name="address" id="txtAddress" value={ data.address } onChange={ this.handleInput }/>
									</div>
									<div className="uk-input-group uk-grid-margin">
										<span className="uk-input-group-addon">
											<i className="md-list-addon-icon material-icons">place</i>
										</span>
										{ this.renderCountrySelect() }
									</div>
								</div>
							</div>
							<h3 className="full_width_in_card heading_c text-dark">Social</h3>
							<div className="uk-grid">
								<div className="uk-width-1-1">
									<div className="uk-grid uk-grid-width-1-1 uk-grid-width-large-1-2" data-uk-grid-margin>
										<div>
											<div className="uk-input-group">
												<span className="uk-input-group-addon">
													<i className="md-list-addon-icon uk-icon-facebook-official"/>
												</span>
												<Input title="Facebook" name="facebook" id="txtFacebook" value={ data.social.facebook } onChange={ this.handleSocialInput }/>
											</div>
										</div>
										<div>
											<div className="uk-input-group">
												<span className="uk-input-group-addon">
													<i className="md-list-addon-icon uk-icon-twitter"/>
												</span>
												<Input title="Twitter" name="twitter" id="txtTwitter" value={ data.social.twitter } onChange={ this.handleSocialInput }/>
											</div>
										</div>
										<div>
											<div className="uk-input-group">
												<span className="uk-input-group-addon">
													<i className="md-list-addon-icon uk-icon-linkedin"/>
												</span>
												<Input title="Linkedin" name="linkedin" id="txtLinkedin" value={ data.social.linkedin } onChange={ this.handleSocialInput }/>
											</div>
										</div>
										<div>
											<div className="uk-input-group">
												<span className="uk-input-group-addon">
													<i className="md-list-addon-icon uk-icon-google-plus"/>
												</span>
												<Input title="Google Plus" name="googlePlus" id="txtGooglePlus" value={ data.social.googlePlus } onChange={ this.handleSocialInput }/>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="help-block m-t-15">
								<p className="uk-text-small text-muted">
									<i className="material-icons m-r-5">info</i>
									Please enter/paste full profile url/link.
								</p>
							</div>
							<h3 className="full_width_in_card heading_c text-dark">Payment Methods</h3>
							<div className="uk-grid uk-grid-small uk-position-relative" data-uk-grid-margin>
								<Preloader center loading={ paymentsLoading }/>
								{ this.renderCreditCards() }
								<div className="uk-width-medium-1-2">
									<div className="payment-method-card add-card" onClick={ this.handleAddCreditCard } tabIndex="-1" role="button">
										<div className="card-inner">
											<span className="card-content mhContent">
												<span className="card-icon">
													<i className="material-icons">&#xE870;</i>
												</span>
												<span className="card-text">Add Credit Card</span>
											</span>
										</div>
									</div>
								</div>
							</div>
							<h3 className="full_width_in_card heading_c text-dark">Debit Cards</h3>
							<div className="uk-grid uk-grid-small uk-position-relative" data-uk-grid-margin>
								<Preloader center loading={ debitCardsLoading }/>
								{ this.renderDebitCards() }
								<div className="uk-width-medium-1-2">
									<div className="payment-method-card add-card" onClick={ this.handleAddAccountInfo } tabIndex="-1" role="button">
										<div className="card-inner">
											<span className="card-content mhContent">
												<span className="card-icon">
													<i className="material-icons">account_balance</i>
												</span>
												<span className="card-text">Update Account Info</span>
											</span>
										</div>
									</div>
								</div>
								<div className="uk-width-medium-1-2">
									<div className="payment-method-card add-card" onClick={ this.handleAddDebitCard } tabIndex="-1" role="button">
										<div className="card-inner">
											<span className="card-content mhContent">
												<span className="card-icon">
													<i className="material-icons">credit_card</i>
												</span>
												<span className="card-text">Add Debit Card</span>
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

ProfileEdit.defaultProps = {
	user: {}
}

ProfileEdit.propTypes = {
	getCountries: PropTypes.func.isRequired,
	getLanguages: PropTypes.func.isRequired,
	getCurrencies: PropTypes.func.isRequired,
	
	getUserProfile: PropTypes.func.isRequired,
	
	getCreditCards: PropTypes.func.isRequired,
	addCreditCard: PropTypes.func.isRequired,
	removeCreditCard: PropTypes.func.isRequired,
	removeCreditCards: PropTypes.func.isRequired,
	setDefaultCreditCard: PropTypes.func.isRequired,

	getAccountInfo: PropTypes.func.isRequired,
	updateAccountInfo: PropTypes.func.isRequired,
	createAccountInfo: PropTypes.func.isRequired,
	getAccountInfoFields: PropTypes.func.isRequired,

	addDebitCard: PropTypes.func.isRequired,
	getDebitCards: PropTypes.func.isRequired,
	removeDebitCard: PropTypes.func.isRequired,
	removeDebitCards: PropTypes.func.isRequired,
	setDefaultDebitCard: PropTypes.func.isRequired,

	addAttachment: PropTypes.func.isRequired,

	updateUserProfile: PropTypes.func.isRequired,
	updateUserPassword: PropTypes.func.isRequired,

	updateFormState: PropTypes.func.isRequired,
	
	handleAddDebitCard: PropTypes.func.isRequired,
	handlePasswordModal: PropTypes.func.isRequired,
	handleAddCreditCard: PropTypes.func.isRequired,
	handleAddAccountInfo: PropTypes.func.isRequired,
	
	getSubscriptionPlans: PropTypes.func.isRequired,
	subscribePlan: PropTypes.func.isRequired,
	cancelSubscription: PropTypes.func.isRequired,
	removeSubscriptionPlans: PropTypes.func.isRequired,

	languages: PropTypes.objectOf(PropTypes.object).isRequired,
	countries: PropTypes.objectOf(PropTypes.object).isRequired,
	currencies: PropTypes.objectOf(PropTypes.object).isRequired,
	
	data: PropTypes.shape({
		name: PropTypes.string.isRequired
	}).isRequired,
	
	user: PropTypes.shape(),

	subscriptionPlans: PropTypes.objectOf(PropTypes.object).isRequired,
}

/* ----------  Redux  ---------- */

const mapStateToProps = state => ({
	languages: state.languages,
	countries: state.countries,
	currencies: state.currencies,
	
	user: state.user,
	subscriptionPlans: state.user.subscriptionPlans,
})

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		getUserProfile: GetUserProfile,
		updateUserProfile: UpdateUserProfile,
		updateUserPassword: UpdateUserPassword,
		
		getCreditCards: GetCreditCards,
		addCreditCard: AddCreditCard,
		removeCreditCard: RemoveCreditCard,
		removeCreditCards: RemoveCreditCards,
		setDefaultCreditCard: SetDefaultCreditCard,
		
		getAccountInfo: GetAccountInfo,
		updateAccountInfo: UpdateAccountInfo,
		createAccountInfo: CreateAccountInfo,
		getAccountInfoFields: GetAccountInfoFields,
		
		addDebitCard: AddDebitCard,
		getDebitCards: GetDebitCards,
		removeDebitCard: RemoveDebitCard,
		removeDebitCards: RemoveDebitCards,
		setDefaultDebitCard: SetDefaultDebitCard,
		
		getLanguages: GetLanguages,

		getCountries: GetCountries,

		addAttachment: AddAttachment,
		
		getCurrencies: GetCurrencies,
		
		subscribePlan: SubscribePlan,
		cancelSubscription: CancelSubscription,
		getSubscriptionPlans: GetSubscriptionPlans,
		removeSubscriptionPlans: RemoveSubscriptionPlans,
	}, dispatch)
)

/* ----------  Exports  ---------- */

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(ProfileEdit);
