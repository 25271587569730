/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Redux
import { connect } from 'react-redux';

// Redux
import { bindActionCreators } from 'redux';

// React Router DOM
import { withRouter, Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Utils
import ProfilePicture from './../../../Components/Utils/ProfilePicture';

// Actions
import LogoutUser from './../../../Redux/Actions/User/Auth/LogoutUser';

/* ----------  Scripts  ---------- */

class UserDropdown extends React.Component {
	componentDidMount() {}

	onLogout = e => {
		e.preventDefault();

		const { history } = this.props;

		this.logout();
		history.push('/home');
	}

	logout = (success, fail) => {
		this.props.logoutUser(success, fail);
	}

	render() {
		const { user } = this.props;

		return (
			<div>
				<Link to="#div" className="user_action_image">
					<ProfilePicture title={ user.profile.name } className="md-user-image" src={ user.profile.photo }/>
				</Link>
				<div className="uk-dropdown uk-dropdown-small">
					<ul className="uk-nav">
						<li>
							<Link to="/profile">
								<i className="material-icons m-r-5">account_circle</i>
								My profile
							</Link>
						</li>
						<li>
							<Link to="/profile/edit">
								<i className="material-icons m-r-5">create</i>
								Edit Profile
							</Link>
						</li>
						<li className="uk-nav-divider"/>
						<li>
							<Link to="#div" className="text-danger" onClick={ this.onLogout }>
								<i className="material-icons m-r-5 text-danger">exit_to_app</i>
								Logout
							</Link>
						</li>
					</ul>
				</div>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

UserDropdown.propTypes = {
	logoutUser: PropTypes.func.isRequired,
	
	user: PropTypes.shape().isRequired,
	history: PropTypes.shape().isRequired,
}

/* ----------  Redux Scripts  ---------- */

const mapStateToProps = state => ({
	user: state.user
});

const mapDispatchToProps = dispatch => (
	bindActionCreators({
		logoutUser: LogoutUser,
	}, dispatch)
);

/* ----------  Exports  ---------- */

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDropdown));
