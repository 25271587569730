/* ----------  Scripts  ---------- */

const RemoveNodes = () => dispatch => (
	dispatch({
		type: 'MYTOURS_TREES_REMOVE_TOURS_NODES',
	})
);

/* ----------  Exports  ---------- */

export default RemoveNodes;