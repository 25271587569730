/* ----------  Imports  ---------- */

// React
import React from 'react';

// PropTypes
import PropTypes from 'prop-types';

// Lodash
import { map } from 'lodash';

/* ----------  Scripts  ---------- */

class Alerts extends React.Component {
	componentDidMount() {}

	renderList = () => {
		const { data } = this.props;
		const styles = {
			fontSize: '14px'
		}

		return map(data, (error, i) => <li className={ i < (data.length - 1) ? 'mb-2' : '' } key={ Math.random() } style={ styles }>{ error }</li>);
	}

	render() {
		const { show, data, type, listClassName } = this.props;

		if(!show || !data.length) return <div className="uk-alert d-none"/>;

		return (
			<div className={ `alert alert-${ type }` }>
				<ul className={ `m-0 ${ listClassName }` }>
					{ this.renderList() }
				</ul>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

Alerts.defaultProps = {
	data: [],
	
	show: false,

	type: 'danger',
	listClassName: '',
}

Alerts.propTypes = {
	show: PropTypes.bool,
	
	type: PropTypes.string,
	listClassName: PropTypes.string,

	data: PropTypes.arrayOf(PropTypes.string),
}

/* ----------  Exports  ---------- */

export default Alerts;
