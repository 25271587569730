/* ----------  Scripts  ---------- */

const TOURS_META_INITIAL_STATE = {};

const ReducerHomeToursData = (state = TOURS_META_INITIAL_STATE, action) => {
	if(action.type === 'HOME_GET_TOURS') {
		return { ...state, ...action.payload.meta };
	} else if(action.type === 'HOME_REMOVE_TOURS') {
		return TOURS_META_INITIAL_STATE;
	}

	return state;
}

/* ----------  Exports  ---------- */

export default ReducerHomeToursData;