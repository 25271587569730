/* ----------  Imports  ---------- */

// Lodash
import { mapKeys, get, omit } from 'lodash';

/* ----------  Scripts  ---------- */

const CONTACTS_LIST_DATA_INITIAL_STATE = {};

const ReducerMessagesContactsListData = (state = CONTACTS_LIST_DATA_INITIAL_STATE, action) => {
	if(action.type === 'MESSAGES_GET_CONTACTS_LIST') {
		const response = mapKeys(action.payload.results, 'contactId');
		return { ...state, ...response };
	}else if(action.type === 'MESSAGES_REMOVE_CONTACT_ITEM') {
		const contact = get(state, action.payload);
		const updatedState = { ...state };
		omit(updatedState, contact);

		return updatedState;
	} else if(action.type === 'MESSAGES_SET_CONTACT_BLOCK') {
		const contact = get(state, action.payload);
		const update = { ...contact, blocked: true };

		return { ...state, [action.payload]: update };
	} else if(action.type === 'MESSAGES_SET_CONTACT_UNBLOCK') {
		const contact = get(state, action.payload);
		const update = { ...contact, blocked: false };

		return { ...state, [action.payload]: update };
	} else if(action.type === 'MESSAGES_REMOVE_CONTACTS_LIST') {
		return CONTACTS_LIST_DATA_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMessagesContactsListData;