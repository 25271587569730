/* ----------  Imports  ---------- */

// Axios
import axios from 'axios';

// Global
import Global from './../../../Constants/Global';

/* ----------  Scripts  ---------- */

const SetUserStatus = (data, success, fail) => dispatch => {
	const url = `${ Global.API_BASE_URL }/users/status`;

	axios.put(url, data).then(response => {
		const { status } = response.data;

		if(success) success(status);
	}).catch(error => {
		const { response } = error;

		if(fail) {
			if(response) {
				fail(response.data.status, response.data.error.reasons);
			} else {
				fail(false, []);
			}
		}
	});

	dispatch({
		type: 'USER_SET_STATUS',
		payload: data.status
	});
};

/* ----------  Exports  ---------- */

export default SetUserStatus;