/* ----------  Scripts  ---------- */

const SetLegsUnHovered = () => dispatch => {
	dispatch({
		type: 'MARKETPLACE_SET_LEGS_UNHOVERED'
	})
};

/* ----------  Exports  ---------- */

export default SetLegsUnHovered;