/* ----------  Scripts  ---------- */

const RemoveSubscriptions = () => dispatch => (
	dispatch({
		type: 'REMOVE_SUBSCRIPTIONS',
	})
);

/* ----------  Exports  ---------- */

export default RemoveSubscriptions;