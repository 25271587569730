/* ----------  Scripts  ---------- */

const RemoveTourSummary = () => dispatch => (
	dispatch({
		type: 'TOUR_PLAYBACK_REMOVE_SUMMARY',
	})
);

/* ----------  Exports  ---------- */

export default RemoveTourSummary;
