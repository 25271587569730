/* ----------  Imports  ---------- */

// Lodash
import { maxBy } from 'lodash';

// New Message
import NewMessage from './../../../../../Helpers/NewMessage';

/* ----------  Scripts  ---------- */

const CONVERSATION_MESSAGES_INITIAL_STATE = [];

const ReducerMessagesConversationMessages = (state = CONVERSATION_MESSAGES_INITIAL_STATE, action) => {
	if(action.type === 'MESSAGES_GET_CONVERSATION_MESSAGES') {
		return [ ...state, ...action.payload.results ];
	} else if(action.type === 'MESSAGES_SEND_CONVERSATION_MESSAGE') {
		const { data, user, messageId } = action.payload;
		const lastMessage = maxBy(state, 'autoId');
		const message = NewMessage.create(messageId, data, user, lastMessage);
		const newState = [ ...state, message ];

		return newState;
	} else if(action.type === 'MESSAGES_REMOVE_CONVERSATION_MESSAGES') {
		return CONVERSATION_MESSAGES_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMessagesConversationMessages;