/* ----------  Imports  ---------- */

// Prop Types
import PropTypes from 'prop-types';

// Google Streetview
import GoogleStreetview from './../Google/GoogleStreetview';

/* ----------  Scripts  ---------- */

class PlaybackStreetview extends GoogleStreetview {
	componentDidMount() {
		this.load();
		this.handleActions();
	}

	onMouseMove = e => {
		const { updateMouseState, isRecording } = this.props;
		
		if(updateMouseState && isRecording) {
			const rect = e.currentTarget.getBoundingClientRect();
			const absTop = e.clientY - rect.top;
			const absLeft = e.clientX - rect.left;
			const top = (100 / rect.height) * absTop;
			const left = (100 / rect.width) * absLeft;

			const mouse = { top, left, absTop, absLeft };
			
			updateMouseState(mouse);
		}
	}

	handleActions = () => {
		this.streetview.addListener('position_changed', () => {
			const { map } = this.props;
			const mapApi = map.current;
			const center = this.getPosition();

			if(mapApi) mapApi.setCenter(center);
		});
	}
}

/* ----------  Prop Types  ---------- */

PlaybackStreetview.propTypes = {
	map: PropTypes.shape().isRequired,
	updateMouseState: PropTypes.func.isRequired,
}

/* ----------  Exports  ---------- */

export default PlaybackStreetview;