/* ----------  Imports  ---------- */

// Lodash
import { mapKeys } from 'lodash';

/* ----------  Scripts  ---------- */

const LEGS_NODES_DATA_INITIAL_STATE = {};

const ReducerMyToursTreesLegsNodesData = (state = LEGS_NODES_DATA_INITIAL_STATE, action) => {
	if(action.type === 'MYTOURS_TREES_GET_LEGS_NODES') {
		// const response = mapKeys(action.payload.results, 'nodeId');
		// return { ...state, ...response };
		return mapKeys(action.payload.results, 'nodeId');
	} else if(action.type === 'MYTOURS_TREES_LEGS_CREATE_NODE') {
		const updatedState = { ...state, [action.payload.nodeId]: action.payload };
		const response = mapKeys(updatedState, 'nodeId');
		
		return response;
	} else if(action.type === 'MYTOURS_TREES_REMOVE_LEGS_NODES') {
		return LEGS_NODES_DATA_INITIAL_STATE;
	}
	
	return state;
};

/* ----------  Exports  ---------- */

export default ReducerMyToursTreesLegsNodesData;
