/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from 'react-router-dom';

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { isEmpty } from 'lodash';

// Utils
import StarRating from './../../Utils/StarRating';

// Category Label
import CategoryLabel from './../../Categories/CategoryLabel';

/* ----------  Scripts  ---------- */

class RelatedTour extends React.Component {
	componentDidMount() {}

	getBackgroundImage = thumbnail => ({
		backgroundImage: `url(${ thumbnail })`
	})

	handleTour = e => {
		e.preventDefault();

		const { tour } = this.props;

		this.props.handleTour(tour.tourId);
	}

	handleMarker = e => {
		e.preventDefault();

		const { tourId, position } = this.props.tour;

		if(position.length) {
			this.props.changeMapPosition(position);
			if(tourId) this.props.setTourActive(tourId);
		}
	}

	renderCategory = () => {
		const { primaryCategory } = this.props;
		if(isEmpty(primaryCategory)) return <div className="d-none"/>;

		return <CategoryLabel category={ primaryCategory }/>;
	}

	renderSponsorLabel = () => {
		const { sponsored } = this.props;
		if(!sponsored) return <div className="d-none"/>;

		return (
			<span className="item-special-label">Sponsored</span>
		);
	}

	render() {
		const { tour } = this.props;

		return (
			<div className="rel-item" data-tour-id={ tour.tourId }>
				<div className="item-inner">
					{ this.renderSponsorLabel() }
					<div className="item-clickable">
						<Link to="#related" className="atnRelatedDetails" data-location={ tour.position } data-id={ tour.autoId } onClick={ this.handleTour }>
							<div className="item-media">
								<div className="item-image" style={ this.getBackgroundImage(tour.thumbnail) }/>
							</div>
							<div className="item-content">
								<div className="item-title">
									<h4>{ tour.title }</h4>
								</div>
								<div className="item-label">
									{ this.renderCategory() }
								</div>
							</div>
						</Link>
					</div>
					<div className="item-footer">
						<div className="item-meta">
							<div className="item-ratings ratings">
								<StarRating rating={ tour.rating } ratingCount={ tour.ratingCount }/>
							</div>
							<div className="item-marker">
								<Link to="#extMarker" className="global-marker" onClick={ this.handleMarker }/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

RelatedTour.defaultProps = {
	sponsored: false,
	primaryCategory: {},
}

RelatedTour.propTypes = {
	handleTour: PropTypes.func.isRequired,
	setTourActive: PropTypes.func.isRequired,
	changeMapPosition: PropTypes.func.isRequired,
	
	sponsored: PropTypes.bool,
	
	primaryCategory: PropTypes.shape(),
	
	tour: PropTypes.shape({
		tourId: PropTypes.string.isRequired,
		position: PropTypes.arrayOf(PropTypes.number).isRequired,
	}).isRequired
}

/* ----------  Exports  ---------- */

export default RelatedTour;
