/* ----------  Imports  ---------- */

// React
import React from 'react';

// React Router DOM
import { Link } from "react-router-dom";

// Prop Types
import PropTypes from 'prop-types';

// Lodash
import { map, isEmpty, indexOf, join, pull } from 'lodash';

// Filters
import FilterItem from './../../../Components/MyTours/Filters/FilterItem';
import FilterSearch from './../../../Components/MyTours/Filters/FilterSearch';
import FilterDropdown from './../../../Components/MyTours/Filters/FilterDropdown';

// Helpers
import ToggleHelper from './../../../Helpers/ToggleHelper';

/* ----------  Scripts  ---------- */

class ToursFilter extends React.Component {
	constructor(props) {
		super(props);

		this.filters = React.createRef();
		this.filterDropdownRef = React.createRef();

		this.state = {
			term: '',
			typeData: [],
			types: ['ALL'],
			loading: false,
			messageData: [],
			messages: ['ALL'],
		}
	}

	componentDidMount() {
		this.getTypeFiltersData(() => {
			const { types } = this.state;

			this.handleFilters('types', types, 'ALL', true, () => {
				this.getTypeFiltersData(this.updateToggleUI);
			});
		});
		
		this.getMessageFiltersData(() => {
			const { messages } = this.state;

			this.handleFilters('messages', messages, 'ALL', true, () => {
				this.getMessageFiltersData(this.updateToggleUI);
			});
		});
	}

	onNewFolder = e => {
		e.preventDefault();

		this.newFolder();
	}

	onTypeChange = (value, checked) => {
		const { types } = this.state;
		
		this.handleFilters('types', types, value, checked, () => {
			this.filterNodes();
			this.getTypeFiltersData(this.updateToggleUI);
		});
	}

	onMessageChange = (value, checked) => {
		const { messages } = this.state;

		this.handleFilters('messages', messages, value, checked, () => {
			this.filterNodes();
			this.getMessageFiltersData(this.updateToggleUI);
		});
	}

	onShowPublishTreePanel = e => {
		e.preventDefault();

		this.props.showPublishTreePanel();
	}

	getTypeFiltersData = callback => {
		const { types } = this.state;

		const data = [{
			id: 'toursTypeAll',
			label: 'All',
			toggle: {
				type: 'checkbox',
				name: 'toursFilterByType',
				value: 'ALL',
				checked: indexOf(types, 'ALL') >= 0,
			}
		},{
			id: 'toursTypeUnpublished',
			label: 'Unpublished',
			toggle: {
				type: 'checkbox',
				name: 'toursFilterByType',
				value: 'UNPUBLISHED',
				checked: indexOf(types, 'UNPUBLISHED') >= 0,
			}
		},{
			id: 'toursTypePublished',
			label: 'Published',
			toggle: {
				type: 'checkbox',
				name: 'toursFilterByType',
				value: 'PUBLISHED',
				checked: indexOf(types, 'PUBLISHED') >= 0,
			}
		}];

		this.setState({ typeData: data }, () => {
			if(callback) callback();
		});
	}

	getMessageFiltersData = callback => {
		const { messages } = this.state;

		const data = [{
			id: 'toursMessageAll',
			label: 'All',
			toggle: {
				type: 'checkbox',
				name: 'toursFilterByMessage',
				value: 'ALL',
				checked: indexOf(messages, 'ALL') >= 0,
			}
		},{
			id: 'toursMessageHas',
			label: 'Having Message',
			toggle: {
				type: 'checkbox',
				name: 'toursFilterByMessage',
				value: 'HAS_MESSAGES',
				checked: indexOf(messages, 'HAS_MESSAGES') >= 0,
			}
		},{
			id: 'toursMessageUnread',
			label: 'Having Unread Message',
			toggle: {
				type: 'checkbox',
				name: 'toursFilterByMessage',
				value: 'UNREAD',
				checked: indexOf(messages, 'UNREAD') >= 0,
			}
		}];

		this.setState({ messageData: data }, () => {
			if(callback) callback();
		});
	}

	getTypes = () => {
		const { typeData } = this.state;
		return map(typeData, item => item.toggle.value);
	}

	getMessages = () => {
		const { messageData } = this.state;
		return map(messageData, item => item.toggle.value);
	}

	closeDropdown = () => {
		const dropdown = this.filterDropdownRef.current;
		dropdown.reset();
	}

	filterNodes = () => {
		const { trees, filterNodes } = this.props;
		const { types, messages, term } = this.state;

		let filterTypes = join(types, ',');
		let filterMessages = join(messages, ',');

		if(indexOf(types, 'ALL') >= 0) filterTypes = 'ALL';
		if(indexOf(messages, 'ALL') >= 0) filterMessages = 'ALL';

		const filters = {
			parentNodeTypes: 0,
			filterByType: filterTypes,
			filterByMessage: filterMessages,
			recursive: !isEmpty(term) || indexOf(types, 'ALL') === -1 || indexOf(messages, 'ALL') === -1 || false,
		}

		if(term) filters.term = term;

		filterNodes(filters, trees);
	}

	handleFilters = (filterName, filters, value, checked, callback) => {
		let values = [...filters];
		let allValues = [];

		switch (filterName) {
			case 'types': allValues = this.getTypes(); break;
			case 'messages': allValues = this.getMessages(); break;
			default: allValues = []; break;
		}

		if(checked) {
			if(indexOf(values, 'ALL') >= 0) pull(values, 'ALL');
			values.push(value);
		} else {
			pull(values, value);
			pull(values, 'ALL');
		}

		if(!values.length || (checked && value === 'ALL')) values = allValues;

		this.setState({
			[filterName]: values
		}, () => {
			this.closeDropdown();
			if(callback) callback();
		});
	}

	newFolder = () => {
		const params = {
			tree: 'tours',
			licensed: false,
			parentNodeId: '#',
			parentNodeType: 20,
		}

		this.props.newFolder(params);
		this.closeDropdown();
	}

	search = () => {
		this.filterNodes();
	}

	updateToggleUI = () => {
		const filters = this.filters.current;

		setTimeout(ToggleHelper.updateUI, 300, filters);
	}

	updateTerm = term => {
		this.setState({ term });
	}

	updateFilterState = (updates, callback) => {
		this.setState({ ...updates }, () => {
			if(callback) callback();
		});
	}

	renderTypeFilterItems = () => {
		const { typeData } = this.state;

		if(!typeData.length) return <div className="d-none"/>;

		return map(typeData, type => (
			<FilterItem
				id={ type.id }
				key={ type.id }
				label={ type.label }
				toggle={ type.toggle }
				divider={ type.divider || false }
				onChange={ this.onTypeChange }/>
		));
	}

	renderMessageFilterItems = () => {
		const { messageData } = this.state;

		if(!messageData.length) return <div className="d-none"/>;

		return map(messageData, message => (
			<FilterItem
				id={ message.id }
				key={ message.id }
				label={ message.label }
				toggle={ message.toggle }
				onChange={ this.onMessageChange }
				divider={ message.divider || false }/>
		));
	}

	render() {
		const { loading, term } = this.state;

		return (
			<div className={ `header-contents ${ loading ? 'disabled' : '' }` } ref={ this.filters }>
				<div className="header-action">
					<Link to="#showPublish" onClick={ this.onShowPublishTreePanel }>
						<i className="material-icons">publish</i>
					</Link>
				</div>
				<div className="header-search">
					<FilterSearch
						term={ term }
						loading={ loading }
						search={ this.search }
						placeholder="Search My Tours"
						updateTerm={ this.updateTerm }/>
				</div>
				<div className="header-dropdown">
					<FilterDropdown
						title="My Tours"
						id="toursFilter"
						loading={ loading }
						ref={ this.filterDropdownRef }>
						<div className="default-filters filter-list">
							<FilterItem
								id="toursFilterNewCollection"
								label="Add New Folder"
								toggle={ false }
								onClick={ this.onNewFolder }/>
							<div className="item-divider"/>
						</div>
						<div className="type-filters filter-list">
							{ this.renderTypeFilterItems() }
						</div>
						<div className="item-divider"/>
						<div className="message-filters filter-list">
							{ this.renderMessageFilterItems() }
						</div>
					</FilterDropdown>
				</div>
			</div>
		);
	}
}

/* ----------  Prop Types  ---------- */

ToursFilter.defaultProps = {
	nodes: [],
	nodesData: [],
}

ToursFilter.propTypes = {
	trees: PropTypes.arrayOf(PropTypes.string).isRequired,

	newFolder: PropTypes.func.isRequired,
	filterNodes: PropTypes.func.isRequired,
	showPublishTreePanel: PropTypes.func.isRequired,
}

/* ----------  Exports  ---------- */

export default ToursFilter;
