/* ----------  Imports  ---------- */

// Moment
import moment from 'moment';

// UUID
import uuid from 'uuid/v4'

/* ----------  Add History  ---------- */

const AddHistory = (data, subject, action, historyId, callback) => dispatch => {
	const time = moment().unix();
	const history = {
		historyId: historyId || uuid(),
		subject,
		action,
		data,
		time,
		selected: false,
		disabled: false,
	}

	// console.log(history);

	dispatch({
		type: 'ADD_HISTORY',
		payload: history
	});

	if(callback) callback();
}

/* ----------  Exports  ---------- */

export default AddHistory;
