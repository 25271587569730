/* ----------  Scripts  ---------- */

const RemovePanelData = () => dispatch => (
	dispatch({
		type: 'TOURSPANEL_REMOVE_DATA',
	})
);

/* ----------  Exports  ---------- */

export default RemovePanelData;
